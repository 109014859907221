import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Col, Row, Card } from "reactstrap";
import { StyledCard, DefaultPage, KebabMenu, CommonHelper } from "../../../Base/CommonUIComponents";
import { getAPI } from "../../../Base/API";
import { handleNotification } from "../../../Base/Notification";
import { DateRangePicker } from "react-dates";
import moment from 'moment';
import { PreviewTemplateModal, ResendReport } from "../Modals";
import { Pagination, TotalResults } from "../../../Base/PaginationComponents";
import { CheckAuthorization } from "../../../Base/Authorization";


class MonthlyReportRuns extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            resendModal: false,
            previewModal: false,
            pageSize: 10,
            pageIndex: 0,
            runs: [],
            startDate: null,
            endDate: null,
            totalItems: 0,
            reportsToCompare: []
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;

        this.getReportRuns();
    }

    getReportRuns = () => {
        this.setState({ block: true });
        const { pageIndex, pageSize, startDate, endDate } = this.state;

        var params = '';
        if (startDate) params += `&startDate=${moment(startDate).format("YYYY-MM-DD")}`;
        if (endDate) params += `&endDate=${moment(endDate).format("YYYY-MM-DD")}`;

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                data.response && data.response.forEach(report => {
                    report.result = JSON.parse(report.result);
                })

                this.setState({ runs: data.response, totalItems: data.totalItems, block: false, errorMessage: [] });
            }
            else this.setState({ runs: [], totalItems: 0, block: false, errorMessage: [] });
        }, `/api/gms/DataQuality/v1/DataQualityReportRuns?pageSize=${pageSize}&pageIndex=${pageIndex}` + params);
    }

    exportReportCsv = (runId) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    const file = data.response[0];

                    const blob = new Blob([file.csv], { type: 'text/csv' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = file.name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                }

                this.setState({ block: false, errorMessage: [] });
            }
            else this.setState({ runs: [], totalItems: 0, block: false, errorMessage: [] });
        }, `api/gms/DataQuality/v1/DataQualityReportRun/${runId}/csv`);
    }

    getExtraFields = (runId) => {
        var options =  [
            { text: "generic.Preview", function: _ => this.toggleModal("previewModal", runId), icon: "icon-icon-preview mr-2" },
            { text: "MonthlyReportRuns.Export", function: _ => this.exportReportCsv(runId), icon: "fas fa-file-csv mr-2 pl-1" }
        ]

        if (CheckAuthorization("monthlyReportRuns:resend")) {
            options.push(
                { text: "MonthlyReportRuns.Resend", function: _ => this.toggleModal("resendModal", runId), icon: "far fa-paper-plane mr-2" }
            )
        }

        return options;
    }

    doSearch = () => {
        this.setState({
            pageIndex: 0
        }, () => this.getReportRuns())
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getReportRuns());
    }

    toggleModal = (modalName, selectedRun) => {
        this.setState(prevState => ({
            [modalName]: !prevState[modalName],
            selectedRun
        }))
    }

    selectToCompare = (runId) => {
        const { reportsToCompare } = this.state;
        const index = reportsToCompare.findIndex(el => el === runId);

        if (index !== -1) {
            reportsToCompare.splice(index, 1);
        }
        else {
            if (reportsToCompare.length === 2) {
                reportsToCompare.shift();
            }

            reportsToCompare.push(runId);
        }


        this.setState({ reportsToCompare });
    }


    render() {
        const { runs, startDate, endDate, previewModal, resendModal, pageIndex, pageSize, totalItems, reportsToCompare } = this.state;


        return (
            <StyledCard block={this.state.block} error={this.state.error} icon="fas fa-calendar-day" title={"MonthlyReportRuns.Title"}>

                {previewModal &&
                    <PreviewTemplateModal
                        isOpen={previewModal}
                        toggle={_ => this.toggleModal("previewModal", null)}
                        selectedRun={this.state.selectedRun}
                    />
                }

                {resendModal &&
                    <ResendReport
                        isOpen={resendModal}
                        toggle={_ => this.toggleModal("resendModal", null)}
                        selectedRun={this.state.selectedRun}
                    />
                }

                <Row className="my-4">
                    <Col sm={3}>
                        <DateRangePicker
                            startDate={startDate}
                            startDateId="your_unique_start_date_id"
                            endDate={endDate}
                            endDateId="your_unique_end_date_id"
                            isOutsideRange={day => day > moment()}
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col className="text-right py-1">
                        <CommonHelper help={<FormattedMessage id="MonthlyReports.Help" />} id={'MonthlyReports'} lang={this.props.intl.locale} />
                        <Button className="btn btn-sm btn-host mr-2" onClick={() => window.open(`/MonthlyReportComparison?reports=${JSON.stringify(reportsToCompare)}`)} disabled={reportsToCompare.length !== 2}>
                            <i className="fas fa-exchange-alt" />
                        </Button>

                        <Button className="btn btn-sm btn-host " onClick={this.doSearch}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>
                {runs?.length > 0 ?
                    <div>
                        <Row>
                            {runs.map((run, key) =>
                                <Col className="mb-3 col-6" key={key}>
                                    <Card className={"border-0 shadow py-3" + (reportsToCompare.includes(run.id) ? " bg-host-light" : "")} body>
                                        <Row>
                                            <Col>
                                                <b> <i className="fas fa-file-invoice mr-2" /> <FormattedMessage id="MonthlyReportRuns.ReportTitle" values={{ date: moment(run.beginDate).format("MMMM YYYY") }} /> </b>
                                            </Col>


                                            <Col className="col-3 pl-4">
                                                <span className={"float-left pointer " + (reportsToCompare.includes(run.id) ? " color-host" : "")} style={{ fontSize: '11px', textDecoration: 'underline' }} onClick={() => this.selectToCompare(run.id)} >
                                                    <i className="fas fa-exchange-alt mr-1 ml-2"></i> <FormattedMessage id="MonthlyReportRuns.Compare" />
                                                </span>

                                                <span className="float-right">
                                                    <KebabMenu
                                                        extraFields={this.getExtraFields(run.id)}
                                                    />
                                                </span>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col className="col-3 text-center border-right ">
                                                <div className="title-sm"> Enviado a </div>
                                                <span style={{ fontSize: '12px' }}>
                                                    <div> {moment(run.createdAt).format("YYYY-MM-DD")} </div>
                                                    <div> {moment(run.createdAt).format("HH:mm")} </div>
                                                </span>
                                            </Col>
                                            <Col className="col-9">
                                                <Row className="pl-5">
                                                    <Col>
                                                        <div className="title-sm"> Linked </div>
                                                        <span> {run.result?.LinkedUsers} </span>
                                                    </Col>
                                                    <Col >
                                                        <div className="title-sm"> Masters </div>
                                                        <span> {run.result?.MasterUsers} </span>
                                                    </Col>
                                                    <Col>
                                                        <div className="title-sm"> <FormattedMessage id="generic.Duplicates" /> </div>
                                                        <span> {run.result?.Duplicates} </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            )}
                        </Row>

                        {totalItems > pageIndex ?
                            <Row className="mt-2">
                                <Col className="ml-2">
                                    <TotalResults
                                        end={pageIndex * pageSize + runs.length}
                                        start={pageIndex * pageSize + 1}
                                        total={totalItems}
                                    />
                                </Col>

                                <Col className="text-right">
                                    <Pagination
                                        isPrevDisabled={pageIndex === 0}
                                        isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                        currentPage={pageIndex + 1}
                                        handlePrevButton={() => this.handlePagination(-1)}
                                        handleNextButton={() => this.handlePagination(1)}
                                    />
                                </Col>
                            </Row>
                        : ''}
                    </div>
                : 
                    <DefaultPage text="MonthlyReportRuns.NoRuns" icon="fas fa-calendar-day" />
                }
            </StyledCard>
        );
    }
}
export default injectIntl(MonthlyReportRuns)