import React, { Component } from 'react'
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, Col, Input, Modal, Nav, NavItem, NavLink, Row, Form } from 'reactstrap';
import CustomSelect from '../Base/CustomSelect';
import moment from 'moment-timezone';
import { SingleDatePicker } from 'react-dates';
import { getAPI, postAPI, getAPIBlob} from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { getSalesDealActionStatusOptions, getSalesDealActionTypes } from '../Base/ReferenceDataFunctions';
import { errorHandler } from '../Marketing/CommunicationJourney/CommonFunctions';
import CoolButtonCollapse from '../Base/CoolButtonCollapse';
import { CommonHelper } from '../Base/CommonUIComponents';
import EditorText from '../Base/EditorText';
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { CustomFormatDate, SelectCustomType, getAllowedSalesProcessUsers } from './SalesProcessFunctions';
import { ReviewProFormsSelect } from '../Marketing/Common/MarketingFunctions';
import { Pagination } from '../Base/PaginationComponents';

class SalesActionModal extends Component {

    constructor(props) {
        super(props);
        const defaultTime = moment().set({ minute: '00', hour: moment().add(1).format('HH') }).format('YYYY/MM/DD HH:mm');
        this.state = {
            block: false,
            error: null,
            deal: null,
            notEditable: this.props.notEditable ?? false,
            page: 1,
            pageSize: 8,
            pagination: [this.getNewPagination(1)],
            actionTypesOptions: getSalesDealActionTypes(this.props.intl, true, undefined, this.props.isFromDeal),
            actionActionTypeOptions: [
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Proposal` }), value: 'Proposal' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Requirements` }), value: 'Requirements' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Other` }), value: 'Other' }
            ],
            actionStatusOptions: getSalesDealActionStatusOptions(this.props.intl),
            action: {},
            actionHistory: [],
            satisfactionFormEmail: true,
            timeOptions: this.getArray(24).flatMap((d, index) => {
                const hour = index.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })
                return (
                    [{
                        label: `${hour}:00`,
                        value: `${hour}:00`,
                    }, {
                        label: `${hour}:30`,
                        value: `${hour}:30`,
                    }]
                );
            }),
            customersAndUsersOptions: [],
            defaultActionValue: [
                {
                    type: "Email",
                    title: "",
                    content: "",
                    editorStateHtml: null,
                    status: "Finished",
                    createdAt: defaultTime,
                    startDate: defaultTime,
                    salesPipelineStageId: this.props.salesPipelineStageId,
                    salesDealId: this.props.salesDealId,
                    salesInitiativeId: this.props.salesInitiativeId,
                    salesDealActionUsers: [],
                    salesDealActionProfiles: []
                },
                {
                    type: "Call",
                    title: "",
                    summary: "",
                    editorStateHtml: null,
                    status: "Scheduled",
                    createdAt: defaultTime,
                    startDate: defaultTime,
                    endDate: defaultTime,
                    salesPipelineStageId: this.props.salesPipelineStageId,
                    salesDealId: this.props.salesDealId,
                    salesInitiativeId: this.props.salesInitiativeId,
                    salesDealActionUsers: [],
                    salesDealActionProfiles: []
                },
                {
                    type: "File",
                    title: "",
                    fileName: "",
                    status: "Finished",
                    summary: "",
                    editorStateHtml: null,
                    contentType: null,
                    createdAt: defaultTime,
                    startDate: defaultTime,
                    salesPipelineStageId: this.props.salesPipelineStageId,
                    salesInitiativeId: this.props.salesInitiativeId,
                    salesDealId: this.props.salesDealId
                },
                {
                    type: "Meeting",
                    title: "",
                    summary: "",
                    editorStateHtml: null,
                    content: "",
                    editorStateHtmlContent: null,
                    status: "Scheduled",
                    createdAt: defaultTime,
                    startDate: defaultTime,
                    endDate: defaultTime,
                    salesPipelineStageId: this.props.salesPipelineStageId,
                    salesDealId: this.props.salesDealId,
                    salesInitiativeId: this.props.salesInitiativeId,
                    salesDealActionUsers: [],
                    salesDealActionProfiles: []
                },
                {
                    type: "Task",
                    title: "",
                    summary: "",
                    editorStateHtml: null,
                    status: "Scheduled",
                    createdAt: defaultTime,
                    dueDate: null,
                    startDate: null,
                    endDate: null,
                    salesPipelineStageId: this.props.salesPipelineStageId,
                    salesDealId: this.props.salesDealId,
                    salesInitiativeId: this.props.salesInitiativeId,
                    salesDealActionUsers: [],
                    salesDealActionProfiles: []
                },
                {
                    type: "Note",
                    editorStateHtml: null,
                    summary: "",
                    status: "Finished",
                    createdAt: defaultTime,
                    startDate: defaultTime,
                    endDate: defaultTime,
                    salesPipelineStageId: this.props.salesPipelineStageId,
                    salesInitiativeId: this.props.salesInitiativeId,
                    salesDealId: this.props.salesDealId
                },
                {
                    type: "SatisfactionForm",
                    title: "Satisfaction Form",
                    editorStateHtml: null,
                    status: "Finished",
                    createdAt: defaultTime,
                    startDate: defaultTime,
                    endDate: defaultTime,
                    salesPipelineStageId: this.props.salesPipelineStageId,
                    salesDealId: this.props.salesDealId,
                    salesInitiativeId: this.props.salesInitiativeId,
                    formId: "",
                    templateId: "",
                    salesDealActionUsers: [{ userId: getAllowedSalesProcessUsers()?.find(x => x.isCurrentUser)?.value, role: 'From', profileId: getAllowedSalesProcessUsers()?.find(x => x.isCurrentUser)?.userId }],
                    salesDealActionProfiles: []
                }
            ],
            prevStatus: null,
            salesDealUsers: this.props.salesDealUsers
        };
    }

    componentDidMount(){
        const { customers, salesUsers, intl, selectedAction, newTaskFromProfile, profileComboOptions, salesDealId, salesInitiativeId, salesDealUsers, salesDealHotelIds, isFromDeal } = this.props;

        if (salesDealId && !(salesDealUsers) && !(newTaskFromProfile))
            this.getDealDetail();

        if (!selectedAction && isFromDeal)
            this.getTemplates();

        const uniqueValues = [];
        const usersOptions = (!newTaskFromProfile  ? 
            salesUsers?.map(user => ({ ...user, opType: 'users' })) 
        : 
            profileComboOptions.filter(({ type, hotelIds, value }) => {
                if (selectedAction?.salesDealId || selectedAction?.salesInitiativeId) {
                    const isAssociatedToAction = selectedAction?.salesDealActionUsers?.some(sdu => sdu.userId === value);
                    const hasSameHotel = hotelIds?.some(hotelId => salesDealHotelIds?.includes(hotelId));
                    return type === "user" && (isAssociatedToAction || hasSameHotel);
                }
                return type === "user";
            })
        ).filter(({ value }) => {
            if (!uniqueValues.includes(value)) {
                uniqueValues.push(value);
                return true;
            }
            return false;
        });

        const customersAndUsersOptions = [
            {
                label: !newTaskFromProfile ? intl.formatMessage({ id: "SalesProcess.Staff" }) : intl.formatMessage({ id: "Profile.User" }),
                options: usersOptions?.filter(({ value, hotelIds }) => newTaskFromProfile ? 
                    true
                : 
                    salesDealUsers?.some(u => u?.value === value) || salesDealHotelIds?.some(hotelId => hotelIds?.includes(hotelId))
                ),
                groupType: "users"
            }
        ];

        if (customers || (newTaskFromProfile && profileComboOptions)) {
            const customersOptions = !newTaskFromProfile ? 
                customers
                    .filter(customer => {
                        if (!uniqueValues.includes(customer.value)) {
                            uniqueValues.push(customer.value);
                            return true;
                        }
                        return false;
                    })
                    .map(c => ({ ...c, opType: 'customers' }))
            :
                profileComboOptions.filter(({type}) => type === "profile")

            customersAndUsersOptions.push({
                label: !newTaskFromProfile ? intl.formatMessage({ id: "SalesProcess.Customers" }) : intl.formatMessage({ id: "Profile.Profile" }),
                options: customersOptions,
                groupType: "profiles"
            });
        }

        this.setState({ customersAndUsersOptions }, () => {
            if(selectedAction){
                this.setState({ block: true }, () => this.getActionDetail());
            }else{
                this.setState({ action: this.state.defaultActionValue.find(({type}) => type === 'Note') }, () => {
                    if(newTaskFromProfile){
                        if(salesInitiativeId){
                            this.getInitiativeDetail();
                        }else if(salesDealId){
                            this.getDealDetail();
                        }
                    }
                });
            }
        });
    }

    getTemplates = (token) => {
        const isEgoi = global.modules && global.modules.some(m => m === 'Egoi');
        const isSendGrid = global.modules && global.modules.some(m => m === 'SendGrid');
        if (isEgoi)
            this.getTemplatesEgoi()

        if (isSendGrid)
            this.getTemplatesSendGrid(token)

        this.setState({ isEgoi })
    }

    getTemplatesEgoi = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorTemplates: errorMessage, blockTemplates: false }, this.getSendersEgoi);
                return;
            }
            if (data && data.response && data.response.length > 0) {
                const templates = data.response.map(resp => {
                    resp.value = resp.id;
                    resp.label = resp.templateName;
                    return resp;
                });
                this.setState({ errorTemplates: errorMessage, blockTemplates: false, templates: templates }, this.getSendersEgoi);
            }
            else {
                this.setState({ errorTemplates: errorMessage, blockTemplates: false }, this.getSendersEgoi);
            }
        }, `/api/gms/Marketing/v1/egoitransactional/template/email`);
    }

    getTemplatesSendGrid = (token) => {
        const { page, pageSize, pagination } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorTemplates: errorMessage });
                return;
            }
            if (data && data.response && data.response.result && data.response.result.length > 0) {
                const templates = data.response.result
                    .filter(resp => resp.versions.some(vrs => vrs.active === 1))
                    .map(resp => {
                        const template = {
                            value: resp.id,
                            label: resp.name
                        }
                        const version = resp.versions.find(vrs => vrs.active === 1);
                        if (version) {
                            template.thumbnail_url = version.thumbnail_url?.startsWith('//') ? `https:${version.thumbnail_url}` : `https://${version.thumbnail_url}`;
                        }
                        return template;
                    });

                if (data.response._metadata) {
                    const paginationItem = pagination.find(p => p.page === page);
                    if (paginationItem) {
                        paginationItem.prevToken = data.response._metadata.self ? (new URLSearchParams(new URL(data.response._metadata.self).searchParams)).get("page_token") : null;
                        paginationItem.nextToken = data.response._metadata.next ? (new URLSearchParams(new URL(data.response._metadata.next).searchParams)).get("page_token") : null;
                    }
                }

                this.setState({ errorTemplates: errorMessage, templates: templates, pagination }, this.getSenders);
            }
            else {
                this.setState({ errorTemplates: errorMessage }, this.getSenders);
            }
        }, `/api/gms/SendGrid/v1/templates?generations=legacy,dynamic&page=${pageSize}${token ? `&token=${token}` : ``}`);
    }

    handlePagination = (token, newPage) => {
        const { pagination } = this.state;
        const hasPagination = pagination.some(p => p.page === newPage);
        if (!hasPagination) {
            pagination.push(this.getNewPagination(newPage));
        }

        this.setState({ page: newPage, pagination }, _ => this.getTemplates(token));
    }

    getNewPagination = (page) => {
        const newPagination = {
            prevToken: null,
            nextToken: null,
            page: page
        };
        return newPagination;
    }

    getSenders() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorSenders: errorMessage, blockSenders: false });
                return;
            }
            if (data && data.response && data.response.results && data.response.results.length > 0) {
                const senders = data.response.results
                    .filter(resp => resp.verified)
                    .map(resp => {
                        const sender = {
                            value: resp.from_email,
                            label: `${resp.from_name}(${resp.from_email})`,
                            name: resp.from_name,
                            id: resp.id
                        };
                        return sender;
                    });
                this.setState({ errorSenders: errorMessage, blockSenders: false, senders: senders });
            }
            else {
                this.setState({ errorSenders: errorMessage, blockSenders: false });
            }
        }, `/api/gms/SendGrid/v1/sender?limit=100`);
    }

    getSendersEgoi = () => {
        this.setState({ blockSenders: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorModal: errorMessage, blockSenders: false });
                return;
            }
            if (data) {
                if ((data.Errors && data.Errors.length > 0) || (data.errors && data.errors.length > 0)) {
                    handleNotification(data);
                }

                let senders = [];

                data.items && data.items.forEach(resp => {
                    senders.push({ value: resp.email, label: `${resp.name}(${resp.email})`, name: resp.name, id: resp.id });
                });

                this.setState({ blockSenders: false, senders });
            }
            else {
                this.setState({ errorModal: errorMessage, blockSenders: false });
            }
        }, `/api/gms/Marketing/v1/egoi/senders/email?offset=0&limit=100`);
    }

    getDealDetail = () => {
        const { salesDealId, salesUsers, newTaskFromProfile } = this.props;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage});
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                const deal = data.data && data.data[0];

                const salesDealUsers = salesUsers.filter(user =>
                    deal.salesDealUser.some(dealUser => dealUser.userId === user.value))

                if(newTaskFromProfile){
                    const { customersAndUsersOptions } = this.state;
                
                    const newProfiles = deal.salesDealProfile?.map(({ profileId, name }) => ({
                        value: profileId,
                        label: name,
                        type: "profile"
                    })) || [];
                
                    let profileOptions = customersAndUsersOptions?.find(({ groupType }) => groupType === "profiles");
                
                    if (profileOptions) {
                        const existingValues = new Set(profileOptions.options.map(option => option.value));
                        profileOptions.options = [
                            ...profileOptions.options,
                            ...newProfiles.filter(({ value }) => !existingValues.has(value))
                        ];
                    } else {
                        profileOptions = {
                            label: this.props.intl.formatMessage({ id: "Profile.Profile" }),
                            options: newProfiles,
                            groupType: "profiles"
                        };
                        customersAndUsersOptions.push(profileOptions);
                    }
                
                    this.setState({ customersAndUsersOptions, block: false });
                    return;
                }

                this.setState({ error: errorMessage, salesDealUsers })

                return;
            }
            else this.setState({ error: errorMessage });
        }, `/api/gms/SalesProcess/v1/deal/${salesDealId}/overview`);
    }

    getInitiativeDetail = () => {
        const { salesInitiativeId } = this.props;
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        this.setState({ error: errorMessage, block: false }, () => handleNotification(data));
                        return;
                    }

                    if (data.data?.length > 0) {
                        const { customersAndUsersOptions } = this.state;
                        const initiative = data.data[0];
                    
                        const newProfiles = initiative.salesInitiativeProfile?.map(({ profileId, name }) => ({
                            value: profileId,
                            label: name,
                            type: "profile"
                        })) || [];
                    
                        let profileOptions = customersAndUsersOptions?.find(({ groupType }) => groupType === "profiles");
                    
                        if (profileOptions) {
                            const existingValues = new Set(profileOptions.options.map(option => option.value));
                            profileOptions.options = [
                                ...profileOptions.options,
                                ...newProfiles.filter(({ value }) => !existingValues.has(value))
                            ];
                        } else {
                            profileOptions = {
                                label: this.props.intl.formatMessage({ id: "Profile.Profile" }),
                                options: newProfiles,
                                groupType: "profiles"
                            };
                            customersAndUsersOptions.push(profileOptions);
                        }
                    
                        this.setState({ customersAndUsersOptions, block: false });
                    }
                    else{
                        this.setState({ error: errorMessage, block: false });
                        return
                    }
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/initiative/${salesInitiativeId}`);
        });
    }

    getActionDetail = () => {
        const { selectedAction, salesInitiativeId, newTaskFromProfile, salesDealId } = this.props;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                const action = data.data[0];

                if(action){
                    const blocksFromHtml = htmlToDraft(action.summary??'');
                    if (blocksFromHtml) {
                        const { contentBlocks, entityMap } = blocksFromHtml;

                        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                         
                        action.editorStateHtml = EditorState.createWithContent(contentState);
                    }

                    if (action.type === "Meeting") {
                        const blocksFromHtml2 = htmlToDraft(action.content);
                        if (blocksFromHtml) {
                            const { contentBlocks, entityMap } = blocksFromHtml2;
    
                            const contentState2 = ContentState.createFromBlockArray(contentBlocks, entityMap);
                             
                            action.editorStateHtmlContent = EditorState.createWithContent(contentState2);
                        }
                    }
                    action.salesInitiativeId = salesInitiativeId;
                }
                
                this.setState({
                    block: false,
                    action
                }, () => {
                    if(newTaskFromProfile){
                        if(salesInitiativeId){
                            this.getInitiativeDetail();
                        }else if(salesDealId){
                            this.getDealDetail();
                        }
                    }
                });

                return;
            }
            else this.setState({ error: errorMessage, block: false, blockScroll: false });
        }, `/api/gms/SalesProcess/v1/deal/${selectedAction.salesDealId??selectedAction.salesInitiativeId}/actions/${selectedAction.id}?isInitiative=${selectedAction.salesInitiativeId ? true : false}`);
    }

    createAction = (e) => {
        e.preventDefault();
        const { prevStatus, action } = this.state;
        const { newTaskFromProfile, selectedAction, profileComboOptions, salesInitiativeId } = this.props;
        let body = { ...action };

        if((!body.salesInitiativeId) && (!body.salesDealId) && salesInitiativeId){
            body.salesInitiativeId = salesInitiativeId;
        }

        if(body.type === "Task"){
            if(prevStatus === "Scheduled")
                body.startDate = moment().format('YYYY-MM-DD HH:mm');
            
            if(prevStatus === "Finished")
                body.endDate = null;

            if(body.status === "Finished")
                body.endDate = moment().format('YYYY-MM-DD HH:mm');
        }

        if(newTaskFromProfile && body){
            if(selectedAction?.id){
                body = {...body , id: selectedAction.id}
            }

            const profile = profileComboOptions?.find(({ type }) => type === "profile");
            if (profile) {

                body = { ...body, salesDealActionProfiles: [{ profileId: profile.value, role: 'CC' }] };
            }
        }

        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    const data = { errors: { message: error.message, code: error.stack } }
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    if (this.props.updateActions) 
                        this.props.updateActions();

                    handleNotification(data, <FormattedMessage id="SalesDeal.ActionSavedSuccessfully" />, <FormattedMessage id="generic.success" />);

                    this.setState({ block: false }, () => this.props.toggle());
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/deals/action?isProfile=${newTaskFromProfile ? true : false}&isInitiative=${salesInitiativeId ? true : false}`, body);
        });
    }

    sendEmailSatisfaction = (e) => {
        const { satisfactionFormEmail } = this.state;

        if (satisfactionFormEmail) {
            this.sendSatisfactionForm(e)
        } else {
            this.props.toggle()
        }
    }

    sendSatisfactionForm = (e) => {
        e.preventDefault();
        const { action } = this.state;

        var qs = '?'

        if (action.salesDealActionProfiles && action.salesDealActionProfiles.length > 0) {            
            action.salesDealActionProfiles.forEach(x => {
                qs += `&profileIds=${x.userId}`;;
            })
        }

        if (action.salesDealId) {
            qs += `&dealId=${action.salesDealId}`;
        }

        if (action.templateId) {
            qs += `&templateId=${action.templateId}`;
        }

        if (action.sender) {
            qs += `&senderId=${action.sender.id}`;
            qs += `&senderEmail=${action.sender.value}`;
            qs += `&senderName=${action.sender.name}`;
        }
            

        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    const data = { errors: { message: error.message, code: error.stack } }
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    handleNotification(data, <FormattedMessage id="SalesDeal.ActionSavedSuccessfully" />, <FormattedMessage id="generic.success" />);

                    this.setState({ block: false }, () => this.props.toggle());
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/FormExternal/v1/satisfactionform/${action.formId}/email/send` + qs);
        });
    }

    createActionFile = (e) => {
        e.preventDefault();
        const { files, action } = this.state;
        const { newTaskFromProfile, profileComboOptions } = this.props;

        let formData = new FormData();
        formData.append("Type", action.type); 
        formData.append("Title", action.title); 
        formData.append("Status", action.status); 
        formData.append("Summary", action.summary??''); 
        formData.append("CreatedAt", action.createdAt); 
        formData.append("StartDate", action.startDate); 
        if (action.salesPipelineStageId) formData.append("SalesPipelineStageId", action.salesPipelineStageId); 
        if (action.salesDealId) formData.append("SalesDealId", action.salesDealId); 
        if (action.salesInitiativeId) formData.append("SalesInitiativeId", action.salesInitiativeId);
        formData.append("ActionType", action.actionType); 
        formData.append("ActionTypeId", action.actionTypeId); 

        if (action.id) formData.append("Id", action.id); 

        if (this.state.files) {
            formData.append("File", files[0]);
            formData.append("FileName", files[0]?.name);
        }

        if (newTaskFromProfile && formData) {
            const profile = profileComboOptions?.find(({ type }) => type === "profile");
            if (profile) {
                formData.append("SalesDealActionProfiles[0].profileId", profile.value);
                formData.append("SalesDealActionProfiles[0].role", "CC");
            }
        }

        this.setState({ block: true }, () => {
            postAPI(
                (result) => {
                    const { data, error } = result;
                    if (error) {
                        const errorMessage = [
                            {
                                message: error.message,
                                stack: error.stack,
                                messageType: 'danger'
                            }
                        ];
                        handleNotification({ errors: { message: error.message, code: error.stack } });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }

                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            handleNotification(data);
                            this.setState({ block: false });
                            return;
                        }

                        if (this.props.updateActions) {
                            this.props.updateActions();
                        }

                        handleNotification(data, <FormattedMessage id="SalesDeal.ActionSavedSuccessfully" />, <FormattedMessage id="generic.success" /> );

                        this.setState({ block: false }, () => this.props.toggle());
                    } else {
                        this.setState({ error: [], block: false });
                    }
                },
                `/api/gms/SalesProcess/v1/deals/action/file`,
                formData,
                { headers: { "Content-Type": "multipart/form-data" } }
            );
        });
    };
    
    toggleActionTab = tab => {
        const { actionHistory, action, defaultActionValue } = this.state;

        let presetAction = actionHistory.find(({type}) => type === tab);
        const idx = actionHistory.findIndex(({type}) => type === action.type);

        if(!presetAction){
            presetAction = defaultActionValue.find(({type}) => type === tab);
        }

        if(idx < 0){
            actionHistory.push(action);
        }
        else{
            actionHistory.splice(idx, 1, action);
        }

        this.setState({ action: presetAction, actionHistory });
    }

    handleComboChange = (combo, name) => {
        let { prevStatus, action } = this.state;
        if(name === "status" && action.type === "Task" && !prevStatus)
            prevStatus = action.status;

        if(name === 'actionType'){
            this.setState({ prevStatus, action: {...this.state.action, [name]: combo ? combo.label : null, actionTypeId: combo ? combo.value : null} });
            return;
        }

        this.setState({ prevStatus, action: {...this.state.action, [name]: combo ? combo.value : null} });
    }

    handleChange = (e, secName) => {
        const { value, name } = e.target;
        const newAction = {...this.state.action, [name]: value};

        if(secName){
            newAction[secName] = value;
        }

        this.setState({ action: newAction });
    }

    handleMultiComboChange = (combo, name) => {
        this.setState({
            action: {
                ...this.state.action,
                [name]: combo ? combo?.map(cmb =>
                    ({
                        userId: cmb.value,
                        role: 'CC'
                    })
                ) : []
            }
        });
    }

    handleMultiComboFromChange = (combo, name) => {
        this.setState({
            action: {
                ...this.state.action,
                [name]: combo ? combo?.map(cmb =>
                ({
                    userId: cmb.value,
                    role: 'From'
                })
                ) : []
            }
        });
    }

    handleTemplateIdChange = (combo, name) => {
        this.setState({
            action: {
                ...this.state.action,
                [name]: combo?.value??null
            }
        });
    }

    handleSenderChange = (combo, name) => {
        this.setState({
            action: {
                ...this.state.action,
                [name]: combo
            }
        });
    }

    selectTemplate = (newTemplateId) => {
        const templateId = this.state.templateId === newTemplateId ? null : newTemplateId;

        this.setState({
            templateId,
            action: {
                ...this.state.action,
                templateId
            } });
    }

    handleDateChange = date => {
        const formattedDate = date ? moment(date).format("YYYY-MM-DD HH:mm") : null;
        this.setState({ action: { ...this.state.action, startDate: formattedDate, endDate: formattedDate } });
    }

    handleStartEndDateChange = (date, name) => {
        const formattedDate = date ? moment(date).format("YYYY-MM-DD HH:mm") : null;

        this.setState((prevState) => {
            const { startDate, endDate } = prevState.action;

            if (name === "startDate") {
                let newEndDate = endDate;
                if (formattedDate && endDate && moment(formattedDate).isAfter(moment(endDate))) {
                    newEndDate = moment(formattedDate).add(1, 'hour').format("YYYY-MM-DD HH:mm");
                }
                return {
                    action: {
                        ...prevState.action,
                        startDate: formattedDate,
                        endDate: newEndDate,
                    },
                };
            }

            if (name === "endDate") {
                let newStartDate = startDate;
                if (formattedDate && startDate && moment(formattedDate).isBefore(moment(startDate))) {
                    newStartDate = moment(formattedDate).subtract(1, 'hour').format("YYYY-MM-DD HH:mm");
                }
                return {
                    action: {
                        ...prevState.action,
                        endDate: formattedDate,
                        startDate: newStartDate,
                    },
                };
            }

            return prevState;
        });
    };

    handleDueDateDateChange = date => {
        const { action } = this.state;

        const hours = action.dueDate ? moment(action.dueDate).hour() : 18;
        const minutes = action.dueDate ? moment(action.dueDate).minute() : 0;

        const formattedDate = date ? moment(date).set({ hour: hours, minute: minutes }).format("YYYY-MM-DD HH:mm") : null;
        this.setState({ action: { ...this.state.action, dueDate: formattedDate } });
    }

    handleHoursChange = (combo, isEnd) => {
        const { action } = this.state;

        const date = moment(action.startDate);
        const hour = combo?.value ? combo?.value.substring(0, 2) : '00';
        const minute = combo?.value ? combo?.value.substring(3) : '00';

        date.set({ hour, minute });

        const formattedstartDate = moment(date).format("YYYY-MM-DD HH:mm");
        const formattedendDate = isEnd ? moment(date).format("YYYY-MM-DD HH:mm") : moment(date).add(30, 'm').format("YYYY-MM-DD HH:mm");
        
        this.setState({
            action: {
                ...this.state.action,
                startDate: isEnd ? this.state.action.startDate : formattedstartDate,
                endDate: isEnd ? formattedstartDate : formattedendDate
            }
        });
    }

    handleDeadLineHoursChange = (combo) => {
        const { action } = this.state;

        const date = moment(action.dueDate);
        const hour = combo?.value ? combo?.value.substring(0, 2) : '00';
        const minute = combo?.value ? combo?.value.substring(3) : '00';

        date.set({ hour, minute });

        const formattedstartDate = moment(date).format("YYYY-MM-DD HH:mm");

        this.setState({
            action: {
                ...this.state.action,
                dueDate: formattedstartDate,
            }
        });
    }

    getArray(size) {
        const array = [];
        for (let i = 0; i < size; i++) {
            array.push(i);
        }
        return array;
    }

    fileChanged = (e) => {
        e.target.value = null;
    };

    handleFileChange = (event) => {
        var files = event.target.files;

        if (files[0].size > 4194304) {
            errorHandler(<FormattedMessage id="generic.FileTooBig" />);
            return;
        }

        this.setState({
            action: {
                ...this.state.action,
                fileName: files[0].name,
                title: this.state.action.title ? this.state.action.title : files[0].name,
            },
            files
        });
    }

    getFile = () => {
        this.setState({ block: true }, () => {
            getAPIBlob(result => {
                const { data, error,headers } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    if(data ){
                        const a = document.createElement("a");
                        //a.href = `${data.response[0].contentType}${data.response[0].fileBase64}`;
                        var blob = new File([data], { type: headers["content-type"] });
                        var url = window.URL.createObjectURL(blob);
                        a.href = url;
                        var f = headers["content-disposition"].match("filename=([^;]*);")
                        a.download = f != null ? f[1] : "file";
                        a.click();
    
                        this.setState({ block: false });
                        return;   
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/deals/action/${this.state.action.id}/file`);
        });
    }

    onEditorStateChange = (editorState, name, name2) => {
        const contentState = editorState.getCurrentContent();
        const markup = draftToHtml(convertToRaw(contentState));
        this.setState({
            action: {
                ...this.state.action,
                [name]: editorState,
                [name2]: markup
            }
        });
    }

    handleComplexMultiComboChange = (combo, name, comboAction) => {
        const { action } = this.state;
        const { newTaskFromProfile } = this.props;
        let salesDealActionUsers = [...action.salesDealActionUsers];
        let salesDealActionProfiles = [...action.salesDealActionProfiles];

        const selectedUsers = combo?.filter(({opType, type}) => !newTaskFromProfile ? opType === "users" : type === "user")??[];
        const selectedCustomers = combo?.filter(({opType, type}) => !newTaskFromProfile ? opType === "customers" : type === "profile")??[];

        if(comboAction.action === "select-option"){
            let newValue = selectedUsers.find(({value}) =>
                !salesDealActionUsers.find(({userId, role}) => value === userId && name === role)
            );

            if(!newValue){
                newValue = selectedCustomers.find(({value}) =>
                    !salesDealActionProfiles.find(({profileId, role}) => value === profileId && name === role)
                );
            }

            if(newValue){
                if(newValue.opType === "users" || (newTaskFromProfile && newValue.type === "user")){
                    salesDealActionUsers.push({
                        userId: newValue.value,
                        role: name
                    });
                }
                else{
                    salesDealActionProfiles.push({
                        profileId: newValue.value,
                        role: name
                    });
                }
            }
        }
        if(comboAction.action === "remove-value"){
            let valueType = "users";
            let oldValue = salesDealActionUsers.find(({userId, role}) =>
                !selectedUsers.find(({value}) => value === userId) && name === role
            )?.userId;

            if(!oldValue){
                valueType = "customers";
                oldValue = salesDealActionProfiles.find(({profileId, role}) =>
                    !selectedCustomers.find(({value}) => value === profileId) && name === role
                )?.profileId;
            }

            if(oldValue){
                if(valueType === "users"){
                    salesDealActionUsers = salesDealActionUsers.filter(({userId}) => userId !== oldValue);
                }
                else{
                    salesDealActionProfiles = salesDealActionProfiles.filter(({profileId}) => profileId !== oldValue);
                }
            }
        }

        this.setState({
            action: {
                ...action,
                salesDealActionProfiles,
                salesDealActionUsers
            }
        });
    }

    toggleSatisfactionFormEmail = () => {
        this.setState({ satisfactionFormEmail: !this.state.satisfactionFormEmail })
    }

    getFormUrl = (e) => {
        e.preventDefault()
        const { action } = this.state;
        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    if (data) {
                        this.setState({ formUrl: data.response && data.response[0].replace("https", "https"), block: false });
                    }
                }
            }, `/api/gms/FormExternal/v1/satisfactionform/formurl?${action.salesDealActionProfiles && action.salesDealActionProfiles.length > 0 ? 'profileId=' + action.salesDealActionProfiles[0].userId : ''}&${action.formId ? 'formId=' + action.formId : ''}&${action.salesDealId ? 'dealId=' + action.salesDealId : ''}`);
        });
    }

    render() {
        const { action, actionTypesOptions, actionStatusOptions, block, timeOptions, customersAndUsersOptions, notEditable, templates, templateId, page, pagination, senders, satisfactionFormEmail, formUrl, isEgoi } = this.state;
        const { toggle, salesUsers, onlyTask, dealName, stageName, profileComboOptions, newTaskFromProfile, intl } = this.props;

        return (
            <Modal isOpen={true} className={'modal-medium-size'}>
                <BlockUi tag="div" blocking={block}>
                    <Button onClick={toggle} className="closeModal">
                        <i className="fas fa-times fa-sm"/>
                    </Button>
                    <Card className='border-0 p-4 shadow'>
                        <Form onSubmit={action.type === 'File' ? this.createActionFile : action.type === 'SatisfactionForm' ? this.sendEmailSatisfaction : this.createAction}>
                            <Row>
                                <Col className='col-12 col-lg-3 d-flex align-items-center pr-3'>
                                    <i className="fas fa-play mr-2"/>
                                    <h5 className='m-0 text-truncate'>
                                        {action.id ?
                                            <>
                                                <FormattedMessage id="SalesProcess.Action"/>
                                                <b className='ml-2'>
                                                    {action.id}
                                                </b>
                                            </>
                                        :
                                            <FormattedMessage id="SalesProcess.NewAction"/>
                                        }
                                    </h5>
                                </Col>
                                <Col className='col-12 mt-3 mt-lg-0 col-lg-9 d-flex align-items-center justify-content-end'>
                                    {!onlyTask ?
                                        <Nav tabs className="border-0">
                                            {actionTypesOptions.map((opType, key) =>
                                                <NavItem key={key} className={action.id && action.type !== opType.value ? 'disabled-nav-item' : ''} >
                                                    <NavLink className={action.type === opType.value ? 'tab-border-host text-host border-0 ' : 'border-0'}
                                                        onClick={() => action.type === opType.value ? undefined : this.toggleActionTab(opType.value)} style={{ cursor: "pointer" }}>
                                                        <i className={`mr-2 ${opType.icon}`} />
                                                        <FormattedMessage id={`SalesProcess.${opType.value}`} />
                                                    </NavLink>
                                                </NavItem>
                                            )}
                                        </Nav>
                                    :
                                        <div className='mr-4 d-flex align-items-center'>
                                            <div className='mr-3'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Deal"/>
                                                </div>
                                                <div>
                                                    {dealName??'-'}
                                                </div>
                                            </div>
                                            <div>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Stage"/>
                                                </div>
                                                <div>
                                                    {stageName??'-'}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div>
                                        <CommonHelper help={<FormattedMessage id="SalesActions.Help" />} id={'SalesProcessActions'}/>
                                    </div>
                                </Col>
                            </Row>
                            <div className='mt-3'>
                                <div>
                                    {action.type === 'Email' ?
                                        <Row>
                                            <Col className="col-6 col-lg-4">
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.From" />
                                                </div>
                                                <div>
                                                    <CustomSelect
                                                        value={customersAndUsersOptions
                                                            .flatMap(el => el.options)
                                                            .filter(({ value }) =>
                                                                action.salesDealActionUsers?.some(crd => crd.role === "From" && value === crd.userId) ||
                                                                action.salesDealActionProfiles?.some(crd => crd.role === "From" && value === crd.profileId)
                                                            )
                                                        }
                                                        options={customersAndUsersOptions
                                                            .map(op => ({
                                                                ...op,
                                                                options: op.options
                                                                    .filter(({value}) =>
                                                                        !action.salesDealActionUsers?.find(crd => value === crd.userId) &&
                                                                        !action.salesDealActionProfiles?.find(crd => value === crd.profileId)
                                                                    )
                                                            }))
                                                            .filter(({ options }) => options && options.length)
                                                        }
                                                        isMulti={true}
                                                        required={true}
                                                        isClearable={false}
                                                        isSearchable={true}
                                                        isDisabled={notEditable}
                                                        forceNoDisabledStyles={notEditable}
                                                        placeholder=""
                                                        onChange={(combo, comboAction) => this.handleComplexMultiComboChange(combo, 'From', comboAction)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-6 col-lg-4">
                                                <div>
                                                    <div className='title-sm required'>
                                                        <FormattedMessage id="SalesProcess.To" />
                                                    </div>
                                                    <div>
                                                        <CustomSelect
                                                            value={customersAndUsersOptions
                                                                .flatMap(el => el.options)
                                                                .filter(({ value }) =>
                                                                    action.salesDealActionUsers?.some(crd => crd.role === "CC" && value === crd.userId) ||
                                                                    action.salesDealActionProfiles?.some(crd => crd.role === "CC" && value === crd.profileId)
                                                                )
                                                            }
                                                            options={customersAndUsersOptions
                                                                .map(op => ({
                                                                    ...op,
                                                                    options: op.options
                                                                        .filter(({value}) =>
                                                                            !action.salesDealActionUsers?.find(crd => value === crd.userId) &&
                                                                            !action.salesDealActionProfiles?.find(crd => value === crd.profileId)
                                                                        )
                                                                }))
                                                                .filter(({ options }) => options && options.length)
                                                            }
                                                            isMulti={true}
                                                            required={true}
                                                            isClearable={false}
                                                            isSearchable={true}
                                                            isDisabled={notEditable}
                                                            forceNoDisabledStyles={notEditable}
                                                            placeholder=""
                                                            onChange={(combo, comboAction) => this.handleComplexMultiComboChange(combo, 'CC', comboAction)}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className='col-12 col-lg-4 mt-3 mt-lg-0'>
                                                <div>
                                                    <div className='title-sm'>
                                                        <FormattedMessage id="SalesProcess.BCC" />
                                                    </div>
                                                    <div>
                                                        <CustomSelect
                                                            value={customersAndUsersOptions
                                                                .flatMap(el => el.options)
                                                                .filter(({ value }) =>
                                                                    action.salesDealActionUsers?.some(crd => crd.role === "BCC" && value === crd.userId) ||
                                                                    action.salesDealActionProfiles?.some(crd => crd.role === "BCC" && value === crd.profileId)
                                                                )
                                                            }
                                                            options={customersAndUsersOptions
                                                                .map(op => ({
                                                                    ...op,
                                                                    options: op.options
                                                                        .filter(({value}) =>
                                                                            !action.salesDealActionUsers?.find(crd => value === crd.userId) &&
                                                                            !action.salesDealActionProfiles?.find(crd => value === crd.profileId)
                                                                        )
                                                                }))
                                                                .filter(({ options }) => options && options.length)
                                                            }
                                                            isMulti={true}
                                                            required={false}
                                                            isClearable={false}
                                                            isSearchable={true}
                                                            isDisabled={notEditable}
                                                            forceNoDisabledStyles={notEditable}
                                                            placeholder=""
                                                            onChange={(combo, comboAction) => this.handleComplexMultiComboChange(combo, 'BCC', comboAction)}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className='col-12 mt-3'>
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.Subject" />
                                                </div>
                                                <div>
                                                    <Input
                                                        type="text"
                                                        value={action.title}
                                                        name="subject"
                                                        required={true}
                                                        onChange={e => this.handleChange(e, 'title')}
                                                        disabled={notEditable}
                                                        className={` ${notEditable ? 'noDisabledStyle' : ''}`}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-12 mt-3">
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.EmailContent" />
                                                </div>
                                                <div>
                                                    <EditorText
                                                        editorState={action.editorStateHtml}
                                                        onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, 'editorStateHtml', 'summary')}
                                                        placeholder=""
                                                        disabled={notEditable}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    : action.type === 'Call' ?
                                        <Row>
                                            <Col className="col-6 col-lg-8">
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.Title" />
                                                </div>
                                                <div>
                                                    <Input
                                                        type="text"
                                                        value={action.title}
                                                        name="title"
                                                        required={true}
                                                        onChange={this.handleChange}
                                                        disabled={notEditable}
                                                        className={` ${notEditable ? 'noDisabledStyle' : ''}`}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className='col-6 col-lg-4'>
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.Status"/>
                                                </div>
                                                <CustomSelect
                                                    value={action.status ? actionStatusOptions.find(({ value }) => value === action.status) : ''}
                                                    options={actionStatusOptions}
                                                    required={true}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isDisabled={notEditable}
                                                    forceNoDisabledStyles={notEditable}
                                                    onChange={(combo) => this.handleComboChange(combo, 'status')}
                                                />
                                            </Col>
                                            <Col id={global.isMobile ? 'mobileDatePicker' : ''} className="col-12 col-lg-3 mt-3 noDisabledStyle">
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.Date" />
                                                </div>
                                                <SingleDatePicker
                                                    id="startDateId"
                                                    isOutsideRange={day => false}
                                                    date={action.startDate ? moment(action.startDate) : null}
                                                    focused={this.state.focused}
                                                    onFocusChange={({ focused }) => this.setState({ focused })}
                                                    small={true}
                                                    numberOfMonths={1}
                                                    showDefaultInputIcon={true}
                                                    required={true}
                                                    onDateChange={this.handleDateChange}
                                                    showClearDate={true}
                                                    disabled={notEditable}
                                                />
                                            </Col>
                                            <Col className='col-6 col-lg-2 mt-3'>
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.Start" />
                                                </div>
                                                <CustomSelect
                                                    value={action.startDate ? timeOptions.find(({ value }) => value === `${moment(action.startDate).format('HH:mm')}`) : ''}
                                                    options={timeOptions}
                                                    required={true}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isDisabled={notEditable}
                                                    forceNoDisabledStyles={notEditable}
                                                    onChange={(combo) => this.handleHoursChange(combo, false)}
                                                />
                                            </Col>
                                            <Col className='col-6 col-lg-2 mt-3'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.End" />
                                                </div>
                                                <CustomSelect
                                                    value={action.endDate ? timeOptions.find(({ value }) => value === `${moment(action.endDate).format('HH:mm')}`) : ''}
                                                    options={timeOptions
                                                        .map((op) => (
                                                                {
                                                                    ...op,
                                                                    disabled: moment(action.startDate)
                                                                        .isAfter(
                                                                            moment(action.startDate)
                                                                            .set({ hour: `${op.value[0]}${op.value[1]}`, minute: `${op.value[3]}${op.value[4]}` })
                                                                        )
                                                                }
                                                        ))
                                                    }
                                                    required={false}
                                                    isClearable={false}
                                                    isDisabled={notEditable}
                                                    forceNoDisabledStyles={notEditable}
                                                    isSearchable={false}
                                                    onChange={e => this.handleHoursChange(e, true)}
                                                />
                                            </Col>
                                            <Col className="col-12 col-lg-5 mt-3">
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.Participants"/>
                                                </div>
                                                <div>
                                                    <CustomSelect
                                                        value={customersAndUsersOptions
                                                            .flatMap(el => el.options)
                                                            .filter(({ value }) =>
                                                                action.salesDealActionUsers?.some(crd => crd.role === "CC" && value === crd.userId) ||
                                                                action.salesDealActionProfiles?.some(crd => crd.role === "CC" && value === crd.profileId)
                                                            )
                                                        }
                                                        options={customersAndUsersOptions
                                                            .map(op => ({
                                                                ...op,
                                                                options: op.options
                                                                    .filter(({value}) =>
                                                                        !action.salesDealActionUsers?.find(crd => value === crd.userId) &&
                                                                        !action.salesDealActionProfiles?.find(crd => value === crd.profileId)
                                                                    )
                                                            }))
                                                            .filter(({ options }) => options && options.length)
                                                        }
                                                        isMulti={true}
                                                        required={true}
                                                        isClearable={false}
                                                        isSearchable={true}
                                                        isDisabled={notEditable}
                                                        forceNoDisabledStyles={notEditable}
                                                        placeholder=""
                                                        onChange={(combo, comboAction) => this.handleComplexMultiComboChange(combo, 'CC', comboAction)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-12 mt-3">
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Summary" />
                                                </div>
                                                <div>
                                                    <EditorText
                                                        editorState={action.editorStateHtml}
                                                        onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, 'editorStateHtml', 'summary')}
                                                        placeholder=""
                                                        disabled={notEditable}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    : action.type === 'Note' ?
                                        <Row>
                                            <Col className="col-12">
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.Note" />
                                                </div>
                                                <div>
                                                    <EditorText
                                                        editorState={action.editorStateHtml}
                                                        onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, 'editorStateHtml', 'summary')}
                                                        placeholder=""
                                                        disabled={notEditable}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    : action.type === 'File' ?
                                        <Row>
                                            <Col className="col-12">
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.Name"/>
                                                </div>
                                                <div>
                                                    <Input
                                                        type="text"
                                                        value={action.title}
                                                        name="title"
                                                        required={true}
                                                        onChange={this.handleChange}
                                                        disabled={notEditable}
                                                        className={` ${notEditable ? 'noDisabledStyle' : ''}`}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className='col-6 mt-3'>
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.File"/>
                                                </div>
                                                <div className='position-relative'>
                                                    <input
                                                        required={action.fileBase64 ? false : true}
                                                        type="file"
                                                        disabled={action.id ? true : false}
                                                        className="custom-file-input"
                                                        id="customFile"
                                                        onChange={e => this.handleFileChange(e)}
                                                        onClick={(e) => this.fileChanged(e)}
                                                    />
                                                    <label className="custom-file-label" for="customFile">
                                                        {action.fileName ?? <FormattedMessage id="SalesProcess.File" />}
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col className='col-6 mt-3'>
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.Type"/>
                                                </div>
                                                <SelectCustomType 
                                                    name="actionType"
                                                    onChangeFunc={(combo) => this.handleComboChange(combo, 'actionType')}
                                                    required={true}
                                                    value={action.actionTypeId}
                                                    placeholder=""
                                                    isDisabled={notEditable}
                                                    forceNoDisabledStyles={notEditable}
                                                    isClearable={true}
                                                    isSearchable={false}
                                                    type={'FileActionType'}
                                                />
                                            </Col>
                                            <Col className='col-12 mt-3'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Description"/>
                                                </div>
                                                <div>
                                                    <EditorText
                                                        editorState={action.editorStateHtml}
                                                        onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, 'editorStateHtml', 'summary')}
                                                        placeholder=""
                                                        disabled={notEditable}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    : action.type === 'Meeting' ?
                                        <Row>
                                            <Col className="col-6 col-lg-8">
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.EventName" />
                                                </div>
                                                <div>
                                                    <Input
                                                        type="text"
                                                        value={action.title}
                                                        name="title"
                                                        required={true}
                                                        onChange={this.handleChange}
                                                        disabled={notEditable}
                                                        className={` ${notEditable ? 'noDisabledStyle' : ''}`}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className='col-6 col-lg-4'>
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.Status"/>
                                                </div>
                                                <CustomSelect
                                                    value={action.status ? actionStatusOptions.find(({ value }) => value === action.status) : ''}
                                                    options={actionStatusOptions}
                                                    required={true}
                                                    isClearable={false}
                                                    isDisabled={notEditable}
                                                    forceNoDisabledStyles={notEditable}
                                                    isSearchable={false}
                                                    onChange={(combo) => this.handleComboChange(combo, 'status')}
                                                />
                                            </Col>
                                            <Col className="col-12 col-lg-3 mt-3 noDisabledStyle">
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.StartDate"/>
                                                </div>
                                                <SingleDatePicker
                                                    id="startDateId"
                                                    isOutsideRange={day => false}
                                                    date={action.startDate ? moment(action.startDate) : null}
                                                    focused={this.state.focused}
                                                    onFocusChange={({ focused }) => this.setState({ focused })}
                                                    small={true}
                                                    numberOfMonths={1}
                                                    showDefaultInputIcon={true}
                                                    onDateChange={(combo) => this.handleStartEndDateChange(combo, "startDate")}
                                                    showClearDate={true}
                                                    disabled={notEditable}
                                                />
                                            </Col>
                                            {global.isMobile ?
                                                <Col className="col-12 mt-3 noDisabledStyle">
                                                    <div className='title-sm required'>
                                                        <FormattedMessage id="SalesProcess.EndDate"/>
                                                    </div>
                                                    <SingleDatePicker
                                                        id="endDateId"
                                                        isOutsideRange={day => false}
                                                        date={action.endDate ? moment(action.endDate) : null}
                                                        focused={this.state.focused2}
                                                        onFocusChange={({ focused }) => this.setState({ focused2: focused })}
                                                        small={true}
                                                        required={true}
                                                        numberOfMonths={1}
                                                        showDefaultInputIcon={true}
                                                        onDateChange={(combo) => this.handleStartEndDateChange(combo, "endDate")}
                                                        showClearDate={true}
                                                        disabled={notEditable}
                                                    />
                                                </Col>
                                            :''}
                                            <Col className='col-6 col-lg-3 mt-3'>
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.Start" />
                                                </div>
                                                <CustomSelect
                                                    value={action.startDate ? timeOptions.find(({ value }) => value === `${moment(action.startDate).format('HH:mm')}`) : ''}
                                                    options={timeOptions}
                                                    required={true}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isDisabled={notEditable}
                                                    forceNoDisabledStyles={notEditable}
                                                    onChange={(combo) => this.handleHoursChange(combo, false)}
                                                />
                                            </Col>
                                            <Col className='col-6 col-lg-3 mt-3'>
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.End" />
                                                </div>
                                                <CustomSelect
                                                    value={action.endDate ? timeOptions.find(({ value }) => value === `${moment(action.endDate).format('HH:mm')}`) : ''}
                                                    options={timeOptions}
                                                    required={true}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    onChange={e => this.handleHoursChange(e, true)}
                                                    isDisabled={notEditable}
                                                    forceNoDisabledStyles={notEditable}
                                                />
                                            </Col>
                                            {!global.isMobile ?
                                                <Col className="col-3 mt-3 noDisabledStyle">
                                                    <div className='title-sm required'>
                                                        <FormattedMessage id="SalesProcess.EndDate"/>
                                                    </div>
                                                    <SingleDatePicker
                                                        id="endDateId"
                                                        isOutsideRange={day => false}
                                                        date={action.endDate ? moment(action.endDate) : null}
                                                        focused={this.state.focused2}
                                                        onFocusChange={({ focused }) => this.setState({ focused2: focused })}
                                                        small={true}
                                                        required={true}
                                                        numberOfMonths={1}
                                                        showDefaultInputIcon={true}
                                                        onDateChange={(combo) => this.handleStartEndDateChange(combo, "endDate")}
                                                        showClearDate={true}
                                                        disabled={notEditable}
                                                    />
                                                </Col>
                                            :''}
                                            <Col className="col-12 mt-3">
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.Participants" />
                                                </div>
                                                <div>
                                                    <CustomSelect
                                                        value={customersAndUsersOptions
                                                            .flatMap(el => el.options)
                                                            .filter(({ value }) =>
                                                                action.salesDealActionUsers?.some(crd => crd.role === "CC" && value === crd.userId) ||
                                                                action.salesDealActionProfiles?.some(crd => crd.role === "CC" && value === crd.profileId)
                                                            )
                                                        }
                                                        options={customersAndUsersOptions
                                                            .map(op => ({
                                                                ...op,
                                                                options: op.options
                                                                    .filter(({value}) =>
                                                                        !action.salesDealActionUsers?.find(crd => value === crd.userId) &&
                                                                        !action.salesDealActionProfiles?.find(crd => value === crd.profileId)
                                                                    )
                                                            }))
                                                            .filter(({ options }) => options && options.length)
                                                        }
                                                        isMulti={true}
                                                        required={true}
                                                        isClearable={false}
                                                        isSearchable={true}
                                                        isDisabled={notEditable}
                                                        forceNoDisabledStyles={notEditable}
                                                        placeholder=""
                                                        onChange={(combo, comboAction) => this.handleComplexMultiComboChange(combo, 'CC', comboAction)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-12 mt-3">
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Place"/>
                                                </div>
                                                <div>
                                                    <Input
                                                        type="text"
                                                        value={action.location}
                                                        name="location"
                                                        onChange={this.handleChange}
                                                        disabled={notEditable}
                                                        className={` ${notEditable ? 'noDisabledStyle' : ''}`}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-12 mt-3">
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.InternalNotes"/>
                                                </div>
                                                <div>
                                                    <EditorText
                                                        editorState={action.editorStateHtml}
                                                        onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, 'editorStateHtml', 'summary')}
                                                        placeholder=""
                                                        disabled={notEditable}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-12 mt-3">
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.MeetingDesc"/>
                                                </div>
                                                <div>
                                                    <EditorText
                                                        editorState={action.editorStateHtmlContent}
                                                        onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, 'editorStateHtmlContent', 'content')}
                                                        placeholder=""
                                                        disabled={notEditable}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    : action.type === 'Task' ?
                                        <Row>
                                            <Col className='col-6 col-lg-9'>
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.TaskName"/>
                                                </div>
                                                <div>
                                                    <Input
                                                        type="text"
                                                        value={action.title}
                                                        name="title"
                                                        required={true}
                                                        onChange={this.handleChange}
                                                        disabled={notEditable}
                                                        className={` ${notEditable ? 'noDisabledStyle' : ''}`}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className='col-6 col-lg-3'>
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.AssignedTo" />
                                                </div>
                                                <div>
                                                    <CustomSelect
                                                        value={customersAndUsersOptions?.flatMap(el => el.options)?.filter(({ value }) => action?.salesDealActionUsers?.some(({userId}) => value === userId))}
                                                        options={customersAndUsersOptions?.filter(({ groupType }) => groupType === 'users') || []}
                                                        isMulti={true}
                                                        required={true}
                                                        isClearable={false}
                                                        isSearchable={true}
                                                        isDisabled={notEditable}
                                                        forceNoDisabledStyles={notEditable}
                                                        placeholder=""
                                                        onChange={e => this.handleMultiComboChange(e, 'salesDealActionUsers')}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className='col-6 col-lg-3 mt-3'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Type"/>
                                                </div>
                                                <SelectCustomType 
                                                    name="actionType"
                                                    onChangeFunc={(combo) => this.handleComboChange(combo, 'actionType')}
                                                    value={action.actionTypeId}
                                                    placeholder=""
                                                    isDisabled={notEditable}
                                                    forceNoDisabledStyles={notEditable}
                                                    isClearable={true}
                                                    isSearchable={false}
                                                    type={'TaskActionType'}
                                                />
                                            </Col>
                                            <Col className='col-6 col-lg-3 mt-3'>
                                                <div className='title-sm required'>
                                                    <FormattedMessage id="SalesProcess.Status"/>
                                                </div>
                                                <CustomSelect
                                                    value={action.status ? actionStatusOptions.find(({ value }) => value === action.status) : ''}
                                                    options={actionStatusOptions}
                                                    required={true}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    onChange={(combo) => this.handleComboChange(combo, 'status')}
                                                    isDisabled={notEditable}
                                                    forceNoDisabledStyles={notEditable}
                                                />
                                            </Col>
                                            <Col className='col-6 col-lg-3 mt-3 noDisabledStyle'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.DeadLine"/>
                                                </div>
                                                <SingleDatePicker
                                                    id="dueDateId"
                                                    isOutsideRange={day => false}
                                                    date={action.dueDate ? moment(action.dueDate) : null}
                                                    focused={this.state.focused}
                                                    onFocusChange={({ focused }) => this.setState({ focused })}
                                                    small={true}
                                                    numberOfMonths={1}
                                                    showDefaultInputIcon={true}
                                                    onDateChange={this.handleDueDateDateChange}
                                                    showClearDate={true}
                                                    disabled={notEditable}
                                                />
                                            </Col>
                                            <Col className='col-6 col-lg-3 mt-3'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Hour" />
                                                </div>
                                                <CustomSelect
                                                    value={action.dueDate ? timeOptions.find(({ value }) => value === `${moment(action.dueDate).format('HH:mm')}`) : ''}
                                                    options={timeOptions}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isDisabled={notEditable || !action.dueDate}
                                                    forceNoDisabledStyles={notEditable}
                                                    onChange={(combo) => this.handleDeadLineHoursChange(combo)}
                                                />
                                            </Col>
                                            <Col className="col-12 mt-3">
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Description"/>
                                                </div>
                                                <div>
                                                    <EditorText
                                                        editorState={action.editorStateHtml}
                                                        onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, 'editorStateHtml', 'summary')}
                                                        placeholder=""
                                                        disabled={notEditable}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    : action.type === 'SatisfactionForm' ?
                                                                <>
                                                                    <Row className="mb-3">
                                                                        <Col>
                                                                            <Nav tabs className="border-0">
                                                                                <NavItem>
                                                                                    <NavLink className={satisfactionFormEmail ? 'tab-border-host text-host border-0 ' : 'border-0'}
                                                                                        onClick={() => satisfactionFormEmail ? undefined : this.toggleSatisfactionFormEmail()} style={{ cursor: "pointer" }}>
                                                                                        <i className={`mr-2 fab fa-wpforms`} />
                                                                                        <FormattedMessage id={`SalesProcess.SendEmail`} />
                                                                                    </NavLink>
                                                                                </NavItem>
                                                                                <NavItem>
                                                                                    <NavLink className={!satisfactionFormEmail ? 'tab-border-host text-host border-0 ' : 'border-0'}
                                                                                        onClick={() => !satisfactionFormEmail ? undefined : this.toggleSatisfactionFormEmail()} style={{ cursor: "pointer" }}>
                                                                                        <i className={`mr-2 fas fa-pen`} />
                                                                                        <FormattedMessage id={`SalesProcess.FillForm`} />
                                                                                    </NavLink>
                                                                                </NavItem>
                                                                            </Nav>
                                                                        </Col>
                                                                    </Row>
                                                                    {satisfactionFormEmail ?
                                                                        <Row>
                                                                            <Col className="col-6 col-lg-6">
                                                                                <div className='title-sm required'>
                                                                                    <FormattedMessage id="SalesProcess.From" />
                                                                                </div>
                                                                                <div>
                                                                                    <CustomSelect
                                                                                        value={customersAndUsersOptions?.flatMap(el => el.options)?.filter(({ value }) => action?.salesDealActionUsers?.some(({ userId }) => value === userId))}
                                                                                        options={customersAndUsersOptions?.filter(({ groupType }) => groupType === 'users') || []}
                                                                                        isMulti={true}
                                                                                        required={true}
                                                                                        isClearable={false}
                                                                                        isSearchable={true}
                                                                                        isDisabled={notEditable}
                                                                                        forceNoDisabledStyles={notEditable}
                                                                                        placeholder=""
                                                                                        onChange={e => this.handleMultiComboFromChange(e, 'salesDealActionUsers')}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col className="col-6 col-lg-6">
                                                                                <div>
                                                                                    <div className='title-sm required'>
                                                                                        <FormattedMessage id="SalesProcess.To" />
                                                                                    </div>
                                                                                    <div>
                                                                                        <CustomSelect
                                                                                            value={customersAndUsersOptions?.flatMap(el => el.options)?.filter(({ value }) => action?.salesDealActionProfiles?.some(({ userId }) => value === userId))}
                                                                                            options={customersAndUsersOptions?.filter(({ groupType }) => groupType === 'profiles') || []}
                                                                                            isMulti={true}
                                                                                            required={true}
                                                                                            isClearable={false}
                                                                                            isSearchable={true}
                                                                                            isDisabled={notEditable}
                                                                                            forceNoDisabledStyles={notEditable}
                                                                                            placeholder=""
                                                                                            onChange={e => this.handleMultiComboChange(e, 'salesDealActionProfiles')}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col className="col-6 mt-3">
                                                                                <div className='title-sm required'>
                                                                                    <FormattedMessage id="Campaigns.Sender" />
                                                                                </div>
                                                                                <div>
                                                                                    <CustomSelect name="sender" options={senders} value={senders && senders.find(s => s.value === action.senderEmail)} onChange={e => this.handleSenderChange(e, 'sender')} required />
                                                                                </div>
                                                                            </Col>
                                                                            <Col className="col-6 mt-3">
                                                                                <div className='title-sm required'>
                                                                                    <FormattedMessage id="ReferenceDataFunctions.Form" />
                                                                                </div>
                                                                                <div>
                                                                                    <ReviewProFormsSelect
                                                                                        id="ReviewProFormsSelectProfileNow"
                                                                                        key="ReviewProFormsSelectProfileNow"
                                                                                        onChangeFunc={e => this.handleTemplateIdChange(e, 'formId')}
                                                                                        value={action.formId}
                                                                                        url={`/api/gms/Survey/v1/survey?hotelGroup=${global.hotelGroupId}&type=DealSatisfactionForm`}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col className="col-12 mt-3">
                                                                                <div className='title-sm required'>
                                                                                    <FormattedMessage id="SalesProcess.Templates" />
                                                                                </div>
                                                                                <Row>
                                                                                    {
                                                                                        templates && templates.length > 0 ?
                                                                                            templates.map((template, key) =>
                                                                                                <Col key={key} sm={3} className="mb-3 px-2">
                                                                                                    <Card className="border-0 px-3 py-2 h-100 shadow d-flex justify-content-between" style={{ backgroundColor: templateId === template.value ? '#d3eaff' : 'white', cursor: 'pointer' }} onClick={_ => this.selectTemplate(template.value)}>
                                                                                                        <div>
                                                                                                            <Row>
                                                                                                                <Col>
                                                                                                                    <h4 className="m-0">{template.label}
                                                                                                                    </h4>
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        </div>
                                                                                                        <div style={{ width: '100%', height: 'auto' }}>
                                                                                                            <img src={template.thumbnail_url ?? template.thumbnail} style={{ width: 'inherit' }} />
                                                                                                        </div>
                                                                                                    </Card>
                                                                                                </Col>
                                                                                            )
                                                                                            :
                                                                                            <Col className="text-center mt-3">
                                                                                                <FormattedMessage id="generic.NoEgoiTemplatesAvailable" />
                                                                                            </Col>
                                                                                    }
                                                                                </Row>
                                                                                {!isEgoi ?
                                                                                    <Row className="my-4">
                                                                                        <Col className="text-right">
                                                                                            <Pagination
                                                                                                isPrevDisabled={page === 1}
                                                                                                isNextDisabled={pagination[page - 1].nextToken === null}
                                                                                                currentPage={page}
                                                                                                handlePrevButton={_ => this.handlePagination(pagination[page - 2].prevToken, page - 1)}
                                                                                                handleNextButton={_ => this.handlePagination(pagination[page - 1].nextToken, page + 1)}
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                    : ''}
                                                                            </Col>

                                                                        </Row>
                                                                        : 
                                                                        <Row>
                                                                            <Col className="col-6">
                                                                                <div>
                                                                                    <div className='title-sm required'>
                                                                                        <FormattedMessage id="SalesProcess.To" />
                                                                                    </div>
                                                                                    <div>
                                                                                        <CustomSelect
                                                                                            value={customersAndUsersOptions?.flatMap(el => el.options)?.filter(({ value }) => action?.salesDealActionProfiles?.some(({ userId }) => value === userId))}
                                                                                            options={customersAndUsersOptions?.filter(({ groupType }) => groupType === 'profiles') || []}
                                                                                            isMulti={true}
                                                                                            required={true}
                                                                                            isClearable={false}
                                                                                            isSearchable={true}
                                                                                            isDisabled={notEditable}
                                                                                            forceNoDisabledStyles={notEditable}
                                                                                            placeholder=""
                                                                                            onChange={e => this.handleMultiComboChange(e, 'salesDealActionProfiles')}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col className="col-5">
                                                                                <div className='title-sm required'>
                                                                                    <FormattedMessage id="ReferenceDataFunctions.Form" />
                                                                                </div>
                                                                                <div>
                                                                                    <ReviewProFormsSelect
                                                                                        id="ReviewProFormsSelectProfileNow"
                                                                                        key="ReviewProFormsSelectProfileNow"
                                                                                        onChangeFunc={e => this.handleTemplateIdChange(e, 'formId')}
                                                                                        value={action.formId}
                                                                                        url={`/api/gms/Survey/v1/survey?hotelGroup=${global.hotelGroupId}&type=DealSatisfactionForm`}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col className="col-1 mt-4">
                                                                                <Button className="float-right btn-sm btn-host btn" onClick={(e) => this.getFormUrl(e)} disabled={!action.formId || !action.salesDealActionProfiles || action.salesDealActionProfiles?.length === 0}>
                                                                                    <i className="fas fa-search" />
                                                                                </Button>
                                                                            </Col>
                                                                            {formUrl ?
                                                                                <Col className="col-12 mt-3">
                                                                                    <iframe
                                                                                        title='SurveyJs'
                                                                                        style={{ width: '100%', height: '100%', minHeight: '80vh', border: '0' }}
                                                                                        src={formUrl}
                                                                                    />
                                                                                </Col>
                                                                                : ''
                                                                            }
                                                                        </Row>}
                                    </>
                                    :''}
                                </div>
                                <div className='w-100 text-right mt-3'>
                                    {action.type === 'Email' && !notEditable ?
                                        <Button type="submit" className="btn btn-host btn-sm">
                                            <FormattedMessage id={`SalesProcess.Save${action.type}`} />
                                            <i className="fas fa-save ml-2"></i>
                                        </Button>
                                        : action.type === 'File' && action.id && !notEditable ?
                                            <CoolButtonCollapse
                                                buttonText="SalesProcess.File"
                                                buttonIcon="fas fa-paper-plane"
                                                options={[
                                                    {
                                                        function: this.getFile,
                                                        icon: "fas fa-file-download",
                                                        text: "SalesProcess.Download"
                                                    }
                                                ]}
                                            />
                                    : action.type === 'SatisfactionForm' && !satisfactionFormEmail ? ''
                                    : action.id ?
                                        action.type === 'Task' ?
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='d-flex align-items-center'>
                                                    {action.createdBy && salesUsers?.find(({ userId }) => action?.createdBy === userId) ?
                                                        <div className='pr-2'>
                                                            <div className='title-sm text-left'>
                                                                <FormattedMessage id="SalesProcess.CreatedBy"/>
                                                            </div>
                                                            <div className='d-flex align-items-center justify-content-start' style={{ fontSize: '0.8em' }}>
                                                                {salesUsers?.find(({ userId }) => action?.createdBy === userId)?.label}
                                                            </div>
                                                        </div>
                                                    :''}
                                                    {action.startDate ? 
                                                        <div className='px-3'>
                                                            <div className='title-sm text-left'>
                                                                <FormattedMessage id="SalesProcess.StartDate" />
                                                            </div>
                                                            <div style={{ fontSize: '0.8em' }}>
                                                                {CustomFormatDate(action.startDate, null, 'HH:mm', intl)}
                                                            </div>
                                                        </div>
                                                     : ''}
                                                    {action.endDate ? 
                                                        <div className='pl-2'>
                                                            <div className='title-sm text-left'>
                                                                <FormattedMessage id="SalesProcess.EndDate" />
                                                            </div>
                                                            <div style={{ fontSize: '0.8em' }}>
                                                                {CustomFormatDate(action.endDate, null, 'HH:mm', intl)}
                                                            </div>
                                                        </div>
                                                     : ''}
                                                        </div>
                                                {!notEditable ? 
                                                            <Button type="submit" className="btn btn-host btn-sm">
                                                                <FormattedMessage id={`SalesProcess.Save${action.type}`} />
                                                                <i className="fas fa-save ml-2"></i>
                                                            </Button>
                                                    : ''}
                                            </div>
                                        : !notEditable ?
                                                        <Button type="submit" className="btn btn-host btn-sm">
                                                            <FormattedMessage id={`SalesProcess.Save${action.type}`}/>
                                                            <i className="fas fa-save ml-2"></i>
                                                        </Button>
                                            : ''
                                    : !notEditable ?
                                                    <Button type="submit" className="btn btn-host btn-sm">
                                                        {action.type ? <FormattedMessage id={`SalesProcess.Create${action.type}`} /> : ''}
                                                        <i className="fas fa-save ml-2"></i>
                                                    </Button>
                                        : ''
                                    }
                                </div>
                            </div>
                        </Form>
                    </Card>
                </BlockUi>
            </Modal>
        )
    }
}

export default injectIntl(SalesActionModal);