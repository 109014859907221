import React, { Component } from 'react';
import { getInitiativeActionType, getInitiativeStatus, getInitiativeType, SalesProcessCoolSelectHotel, SelectCustomType } from '../SalesProcessFunctions';
import { Col, Input, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import CustomSelect from '../../Base/CustomSelect';
import EditorText from '../../Base/EditorText';

class InitiativeModalStep1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focusedInput: null
        };
    }

    render() {
        const { intl, handleChange, initiative, handleSelect, handleDate, onEditorStateChange, hotelList, handleLoc, handleSelectAll } = this.props;
        return (
            <div>
                <Row>
                    <Col className="col-6 mb-3 pr-1 pr-lg-3">
                        <div className="title-sm required"> <FormattedMessage id="generic.name" /></div>
                        <Input
                            required
                            type="text"
                            name="name"
                            placeholder=""
                            value={initiative.name || ''}
                            onChange={handleChange}
                            maxLength={100}
                        />
                    </Col>
                    <Col className="col-6 mb-3 pl-1 pl-lg-3">
                        <div className="title-sm required"> <FormattedMessage id="generic.status" /></div>
                        <CustomSelect
                            required
                            name="status"
                            placeholder=""
                            isClearable={false}
                            isSearchable={false}
                            options={getInitiativeStatus(intl)}
                            value={getInitiativeStatus(intl).find(({ value }) => initiative.status === value) || ''}
                            onChange={(combo) => handleSelect('status', combo)}
                        />
                    </Col>
                    <Col className="col-6 mb-3 pr-1 pr-lg-3">
                        <div className="title-sm required">
                            <FormattedMessage id="SalesProcess.Type"/>
                        </div>
                        <CustomSelect
                            required
                            name="type"
                            placeholder=""
                            isClearable={false}
                            isSearchable={false}
                            options={getInitiativeType(intl)}
                            value={getInitiativeType(intl).find(({ value }) => initiative.type === value) || ''}
                            onChange={(combo) => handleSelect('type', combo)}
                        />
                    </Col>
                    <Col className="col-6 mb-3 pl-1 pl-lg-3">
                        <div className="title-sm required">
                            <FormattedMessage id="SalesProcess.ActionType"/>
                        </div>
                        <CustomSelect
                            required
                            name="actionType"
                            placeholder=""
                            isClearable={false}
                            isSearchable={false}
                            options={getInitiativeActionType(intl)}
                            value={getInitiativeActionType(intl).find(({ value }) => initiative.actionType === value) || ''}
                            onChange={(combo) => handleSelect('actionType', combo)}
                        />
                    </Col>
                    <Col className="col-lg-6 col-12 mb-3">
                        <SalesProcessCoolSelectHotel
                            isSearchable
                            isMulti
                            name='hotelIds'
                            icon={"icon-icon-hotel"}
                            onChangeFunc={handleSelect}
                            placeholder={'Hotel'}
                            required={true}
                            value={initiative.hotelIds || ''}
                            allGroupHotels={hotelList || []}
                            handleLoc={handleLoc}
                            handleSelectAll={handleSelectAll}
                            isLead={true}
                        />
                    </Col>
                    <Col className={`fullWidth col-lg-6 col-12 mb-3 ${global.isMobile ? 'mobileDateRangePicker' : ''}`} style={{ marginTop: !global.isMobile ? '1%' : '' }}>
                        <div className={`title-sm`}> <FormattedMessage id="SalesDeal.InitiativeDates" /></div>
                        <DateRangePicker
                            startDate={initiative.startDate ? moment(initiative.startDate) : null}
                            startDateId="initiativeStartDate"
                            endDate={initiative.endDate ? moment(initiative.endDate) : null}
                            endDateId="initiativeEndDate"
                            isOutsideRange={_ => false}
                            onDatesChange={({ startDate, endDate }) => handleDate(this.state.focusedInput == "startDate" ? "startDate" : "endDate", this.state.focusedInput == "startDate" ? startDate : endDate)}
                            focusedInput={this.state.focusedInput}
                            showClearDates={true}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            small={true}
                            showDefaultInputIcon={true}
                            numberOfMonths={!global.isMobile ? 2 : 1}
                            renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                            required={false}
                        />
                    </Col>

                    {initiative.status === 'Cancelled' ?
                        <>
                            <Col className="col-lg-6 col-12 mb-3">
                                <div className="title-sm required">
                                    <FormattedMessage id="SalesDeal.CancellationReasonType"/>
                                </div>
                                <SelectCustomType
                                    name="cancelReasonType"
                                    onChangeFunc={(combo) => handleChange({ target: { name: 'cancelReasonType', value: combo ?? null } })}
                                    required={true}
                                    value={initiative.cancelReasonId}
                                    placeholder=""
                                    isDisabled={false}
                                    isClearable={true}
                                    type={'DealCancelReasonType'}
                                />
                            </Col>
                            <Col className="col-lg-6 col-12 mb-3">
                                <div className="title-sm required">
                                    <FormattedMessage id="SalesDeal.CancellationReason"/>
                                </div>
                                <Input
                                    required
                                    type="textarea"
                                    name="statusReason"
                                    placeholder=""
                                    value={initiative.statusReason || ''}
                                    onChange={handleChange}
                                    maxLength={100}
                                    style={{ minHeight: '60px' }}
                                />
                            </Col>
                        </>
                    : ''}
                    <Col className="col-12 mb-3">
                        <div className="title-sm required">
                            <FormattedMessage id="SalesProcess.Summary"/>
                        </div>
                        <EditorText
                            editorState={initiative.editorStateHtml}
                            onEditorStateChange={(editorState) => onEditorStateChange(editorState, 'editorStateHtml', 'summary')}
                            placeholder=""
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default injectIntl(InitiativeModalStep1);
