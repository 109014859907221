import React, { Component } from "react";
import { BlankCard, CommonHelper, CoolTooltip, StyledModal } from "../../Base/CommonUIComponents";
import { Table, Row, Col, Button, Input } from "reactstrap";
import { FormattedMessage, injectIntl } from 'react-intl';
import { postAPI } from "../../Base/API";
import { handleNotification } from "../../Base/Notification";
import CustomSelect from "../../Base/CustomSelect";

class CsvProfilesTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            profiles: this.props.profiles,
            profileRoleOptions: [],
            feedbackMode: false
        }
    }

    handleCreateCSVProfiles = () => {
        const { profiles } = this.state;
        const { initiative, profileTypeOptions } = this.props;
        
        if(!profiles || profiles.length === 0) return;

        const updatedProfiles = profiles.map(profile => {
            const correctProfileType = profileTypeOptions?.find(option => option.value?.toLowerCase() === profile.type?.toLowerCase());
            return (
                {
                    ...profile,
                    type: correctProfileType?.value || profile.type
                }
            );
        });

        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors?.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    if(data.data?.length > 0){
                        this.setState({ profiles: data.data, feedbackMode: true, block: false });
                        return;
                    }

                    this.setState({ block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/initiative/${initiative?.id}/importprofiles?verifyMode=false`, updatedProfiles);
        });
    };

    handleProfileChanges = (e, index) => {
        if(!e || index === null || index === undefined) return;
        const { name, value } = e.target;
        const { profiles } = this.state;
        const { profileRoleOptions } = this.props;
        const updatedProfiles = [...profiles];

        if(updatedProfiles?.[index]){
            updatedProfiles[index][name] = value;
            if(name === 'role'){
                updatedProfiles[index].roleId = profileRoleOptions?.find(r => r.label === value)?.value;
            }
            updatedProfiles[index] = this.updateProfileValidation(updatedProfiles[index])
        }
        
        this.setState({ profiles: updatedProfiles });
    };

    updateProfileValidation = (profile) => {
        if(!profile) return;

        const { profileRoleOptions, profileTypeOptions } = this.props;

        if (!profile.type || !profileTypeOptions.some((option) => option.value?.toLowerCase() === profile.type?.toLowerCase())) {
            if(!profile.missingFields?.includes('Type')){
                profile.missingFields.push('Type');
            }
            profile.status = false;
        }else{
            if(profile.missingFields?.includes('Type')){
                profile.missingFields = profile.missingFields.filter(field => field !== 'Type');
            }
        }

        if (!profile.role || !profileRoleOptions.some((option) => option.label?.toLowerCase() === profile.role?.toLowerCase() || ((!isNaN(profile.roleId)) && option.value === profile.roleId))) {
            if(!profile.missingFields?.includes('Role')){
                profile.missingFields.push('Role');
            }
            profile.status = false;
        }else{
            if(profile.missingFields?.includes('Role')){
                profile.missingFields = profile.missingFields.filter(field => field !== 'Role');
            }
        }

        if(profile.missingFields?.length === 0 && profile.wrongSyntaxFields?.length === 0){
            profile.status = true;
        }

        return {...profile};
    };

    renderHelper = () => {
        return (
            <div className="d-flex align-items-start flex-column">
                <div className="d-flex align-items-start justify-content-between w-100">
                    <div className="mr-4">
                        <span><FormattedMessage id="SalesDeal.Created" />:</span>
                    </div>
                    <div className="d-flex flex-column align-items-end">
                        <div className="d-flex align-items-center">
                            <i className="fas fa-check mr-2" style={{ color: '#76EFAC' }} />
                            <FormattedMessage id="SalesDeal.ProfileCreated" />
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="mr-2 text-muted">-</span>
                            <FormattedMessage id="SalesDeal.ProfileAlreadyCreated" />
                        </div>
                    </div>
                </div>
                <div>
                    <hr />
                </div>
                <div className="d-flex align-items-start justify-content-between w-100">
                    <div className="mr-4">
                        <span><FormattedMessage id="SalesDeal.Associated" />:</span>
                    </div>
                    <div className="d-flex flex-column align-items-end">
                        <div className="d-flex align-items-center">
                            <i className="fas fa-check mr-2" style={{ color: '#76EFAC' }} />
                            <FormattedMessage id="SalesDeal.ProfileAssociated" />
                        </div>
                        <div className="d-flex align-items-center">
                            <i className="fas fa-check mr-2" style={{ color: '#0665FF' }} />
                            <FormattedMessage id="SalesDeal.ProfileAlreadyAssociated" />
                        </div>
                    </div>
                </div>
                <div>
                    <hr />
                </div>
                <div className="d-flex align-items-start justify-content-between w-100">
                    <div className="mr-4">
                        <span><FormattedMessage id="SalesDeal.Errors" />:</span>
                    </div>
                    <div className="d-flex flex-column align-items-end">
                        <div className="d-flex align-items-center">
                            <i style={{ color: '#EE6A5F' }} className='fas fa-exclamation-circle mr-2' />
                            <FormattedMessage id="SalesDeal.ErrorCreatingOrAssociatingProfile" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { modal, error, toggleModal, profileRoleOptions, profileTypeOptions } = this.props;
        const { profiles, feedbackMode, block } = this.state;
        
        return (
            <StyledModal style={{ minWidth: '95%' }} toggleModal={() => toggleModal(feedbackMode)} modal={modal} size="xl">
                <BlankCard block={block} error={error}>
                    <Row>
                        <Col>
                            <h4><FormattedMessage id="SalesDeal.ImportedProfiles" /></h4>
                        </Col>
                            <Col className="text-right col-2">
                                {!feedbackMode ?
                                    <>
                                    {!profiles || profiles?.length === 0 || profiles?.some(p => !p.status) ?
                                        <>
                                            <Button id="downalodProfilesStructureBtnTable" className="btn-host btn-sm mr-2" onClick={() => this.props.downloadProfilesStructure()}>
                                                <i className="fas fa-file-excel" />
                                            </Button>
                                            <CoolTooltip target="downalodProfilesStructureBtnTable" placement="top">
                                                <FormattedMessage id="SalesDeal.DownloadProfilesStructure" />
                                            </CoolTooltip>
                                        </>
                                    :''}

                                        <Button className="btn-sm btn-host" disabled={!profiles || profiles?.length === 0 || profiles?.some(p => !p.status)} onClick={() => this.handleCreateCSVProfiles()}>
                                            <i className="fas fa-save" />
                                        </Button>
                                    </>
                                :
                                    <CommonHelper help={this.renderHelper()} id={'ImportInitiativeProfilesHelp'} placement='left' />
                                }
                            </Col>
                    </Row>
                    <div>
                        <Table hover style={{ width: '100%' }}>
                            <thead style={{ position: 'sticky', top: '0', backgroundColor: '#FAFCFF', boxShadow: '0px 2px 0px #E0E4E8', zIndex: '1' }}>
                                <tr>
                                    <th><FormattedMessage id="SalesDeal.Type" /></th>
                                    <th><FormattedMessage id="SalesDeal.FirstName" /></th>
                                    <th><FormattedMessage id="SalesDeal.LastName" /></th>
                                    <th><FormattedMessage id="SalesDeal.Role" /></th>
                                    <th><FormattedMessage id="SalesDeal.Email" /></th>
                                    <th><FormattedMessage id="SalesDeal.Mobile" /></th>
                                    <th><FormattedMessage id="SalesDeal.NIF" /></th>
                                    <th><FormattedMessage id="SalesDeal.Notes" /></th>
                                    {feedbackMode ?
                                        <>
                                            <th style={{ textAlign: "center" }}><FormattedMessage id="SalesDeal.Created" /></th>
                                            <th style={{ textAlign: "center" }}><FormattedMessage id="SalesDeal.Associated" /></th>
                                            <th style={{ textAlign: "center" }}><FormattedMessage id="SalesDeal.Errors" /></th>
                                        </>
                                    :
                                        <th style={{ textAlign: "center" }}><FormattedMessage id="SalesDeal.Status" /></th>
                                    }
                                </tr>
                            </thead>
                            <tbody >
                                {profiles && profiles.map((profile, index) => {
                                    const email = profile.profileContacts?.find(c => c.type === 'Email')?.contact;
                                    const mobile = profile.profileContacts?.find(c => c.type === 'Mobile')?.contact;
                                    const nif = profile.profileDocuments?.find(d => d.type === 'FiscalNumber')?.number;

                                    return (
                                        <tr key={index}>
                                            <td>
                                                {feedbackMode ?
                                                    profile.type || <span>-</span>
                                                :
                                                    <CustomSelect 
                                                        name="type"
                                                        options={profileTypeOptions}
                                                        value={profileTypeOptions?.find(t => t.value?.toLowerCase() === profile.type?.toLowerCase()) || null}
                                                        onChange={(combo) => this.handleProfileChanges({ target: { value: combo?.value, name: 'type' } }, index)}
                                                        placeholder=""
                                                        isDisabled={false}
                                                        isClearable={false}
                                                        isSearchable={true}
                                                    />
                                                }
                                            </td>
                                            <td style={{ color: feedbackMode ? "inherit" : !profile?.missingFields?.includes('FirstName') ? "inherit" : "red" }}>
                                                {profile.firstName || <span style={{ color: "red" }}>-</span>}
                                            </td>
                                            <td style={{ color: feedbackMode ? "inherit" : !profile?.missingFields?.includes('LastName') ? "inherit" : "red" }}>
                                                {profile.lastName || <span style={{ color: "red" }}>-</span>}
                                            </td>
                                            <td>
                                                {feedbackMode ?
                                                    <span>{profile.role || <span>-</span>}</span>
                                                :
                                                    <CustomSelect 
                                                        name="role"
                                                        options={profileRoleOptions}
                                                        value={profileRoleOptions?.find(r => r.label?.toLowerCase() === profile.role?.toLowerCase()) || null}
                                                        onChange={(combo) => this.handleProfileChanges({ target: { value: combo?.label, name: 'role' } }, index)}
                                                        placeholder=""
                                                        isDisabled={false}
                                                        isClearable={false}
                                                        isSearchable={true}
                                                    />
                                                }
                                            </td>
                                            <td style={{ color: feedbackMode ? "inherit" : (!profile.missingFields?.includes('Email')) && (!profile.wrongSyntaxFields?.includes('Email')) ? "inherit" : "red" }}>
                                                {email || <span>-</span>}
                                            </td>
                                            <td style={{ color: feedbackMode ? "inherit" : !profile.missingFields?.includes('Mobile') && (!profile.wrongSyntaxFields?.includes('Mobile')) ? "inherit" : "red" }}>
                                                {mobile || <span>-</span>}
                                            </td>
                                            <td style={{ color: feedbackMode ? "inherit" : !profile.missingFields?.includes('NIF') ? "inherit" : "red" }}>
                                                {nif || <span>-</span>}
                                            </td>
                                            <td>
                                                {feedbackMode ?
                                                    profile.notes || <span>-</span>
                                                :
                                                    <Input type="textarea" name="notes" value={profile.notes} onChange={(e) => this.handleProfileChanges(e, index)} />
                                                }
                                            </td>
                                            {feedbackMode ?
                                                <>
                                                    <td style={{ textAlign: "center", cursor: profile.status === 'ALREADY ASSOCIATED' || profile.status === 'ASSOCIATED' ? 'pointer' : '' }}>
                                                        {profile.status === 'CREATED' ? 
                                                            <i style={{ color: '#76EFAC' }} className='fas fa-check' />
                                                        : profile.status === 'ASSOCIATED' ? 
                                                            <>
                                                                <span id={`ProfileAlreadyCreated${index}`} className="text-muted">-</span>
                                                                <CoolTooltip target={`ProfileAlreadyCreated${index}`} placement="left">
                                                                    <FormattedMessage id='SalesDeal.ProfileAlreadyCreated' />
                                                                </CoolTooltip>
                                                            </>
                                                        : profile.status === 'ALREADY ASSOCIATED' ?
                                                            <>
                                                                <span id={`PofileAlreadyAssociated${index}`} className="text-muted">-</span>
                                                                <CoolTooltip target={`PofileAlreadyAssociated${index}`} placement="left">
                                                                    <FormattedMessage id='SalesDeal.ProfileAlreadyAssociatedToInitiative' />
                                                                </CoolTooltip>
                                                            </>
                                                        : '-'}
                                                    </td>
                                                    <td style={{ textAlign: "center", cursor: profile.status === 'ALREADY ASSOCIATED' ? 'pointer' : '' }}>
                                                        {profile.status === 'ASSOCIATED' || profile.status === 'CREATED' ? 
                                                            <i style={{ color: '#76EFAC' }} className='fas fa-check' />
                                                        : profile.status === 'ALREADY ASSOCIATED' ?
                                                            <>
                                                                <i id={`PofileAlreadyAssociatedTemp${index}`} style={{ color: '#0665FF' }} className='fas fa-check' />
                                                                <CoolTooltip target={`PofileAlreadyAssociatedTemp${index}`} placement="left">
                                                                    <FormattedMessage id='SalesDeal.ProfileAlreadyAssociatedToInitiative' />
                                                                </CoolTooltip>
                                                            </>
                                                        : '-'}
                                                    </td>
                                                    <td style={{ textAlign: "center", cursor: profile.status === 'ERROR' ? 'pointer' : '' }}>
                                                        {profile.status === 'ERROR' ?
                                                            <>
                                                                <i id={`ProfileWithCreationErrors${index}`} style={{ color: '#EE6A5F' }} className='fas fa-exclamation-circle' />
                                                                <CoolTooltip target={`ProfileWithCreationErrors${index}`} placement="left">
                                                                    <div className="d-flex flex-column align-items-start">
                                                                        <FormattedMessage id='SalesDeal.ProfileErrorReason' />
                                                                        <span>{profile.errorMessage || '-'}</span>
                                                                    </div>
                                                                </CoolTooltip>
                                                            </>
                                                        : '-'}
                                                    </td>
                                                </>
                                            :
                                                <td style={{ textAlign: "center" }}>
                                                    <span id={`Tooltip${index}`}>
                                                        <i style={{ color: profile.status ? '#76EFAC' : '#EE6A5F' }} className={`fas fa-${profile.status ? 'check' : 'times'}`}></i>
                                                    </span>
                                                    {!profile.status ?
                                                        <CoolTooltip target={`Tooltip${index}`} placement="left">
                                                            <div className="d-flex flex-column align-items-start">
                                                                <b><FormattedMessage id='Profile.Missing' /></b>
                                                                {profile.missingFields?.length > 0 && 
                                                                    profile.missingFields.map((field, i) => {
                                                                        return (
                                                                            <FormattedMessage key={i} id={`SalesDeal.${field}`} />
                                                                        )
                                                                    })
                                                                }
                                                                {profile.wrongSyntaxFields?.length > 0 && 
                                                                    profile.wrongSyntaxFields.map((field, i) => {
                                                                        return (
                                                                            <FormattedMessage key={i} id={`SalesDeal.${field}`} />
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </CoolTooltip>
                                                    :''}
                                                </td>
                                            }
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                    {!feedbackMode ?
                        <Row>
                            <Col className="d-flex align-items-start justify-content-start col-12 text-muted" style={{ fontSize: '0.8rem' }}>
                                <div className="d-flex flex-column align-items-start mr-4">
                                    <span className="font-weight-bold mb-1"><FormattedMessage id="SalesDeal.ValidProfileTypeValues" /></span>
                                    {profileTypeOptions?.map((type, index) => (
                                        <span key={index}>- {type.value}</span>
                                    ))}
                                </div>
                                <div className="d-flex flex-column align-items-start">
                                    <span className="font-weight-bold mb-1"><FormattedMessage id="SalesDeal.ValidProfileRoleValues" /></span>
                                    {profileRoleOptions?.map((role, index) => (
                                        <span key={index}>- {role.label}</span>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    :''}
                </BlankCard>
            </StyledModal>
        );
    }
}

export default injectIntl(CsvProfilesTable);