import React, { Component } from 'react';

export function getConsentsCheckboxStyle() {
    return `\n#formComponent .sd-checkbox:has(input[type="checkbox"][value="Mailings"]) .sd-checkbox__decorator,
              #formComponent .sd-checkbox:has(input[type="checkbox"][value="GenericConsent"]) .sd-checkbox__decorator{
                height: 15px;
                width: 15px;
            }\n#formComponent .sd-checkbox:has(input[type="checkbox"][value="Mailings"]) .sd-item__control-label,
               #formComponent .sd-checkbox:has(input[type="checkbox"][value="GenericConsent"]) .sd-item__control-label{
                font-size: 16px;
            }\n#formComponent .sd-checkbox:has(input[type="checkbox"][value="Mailings"]) .sd-selectbase__label,
               #formComponent .sd-checkbox:has(input[type="checkbox"][value="GenericConsent"]) .sd-selectbase__label{
                align-items: center;
            }\n`;
};