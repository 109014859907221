import React, { Component } from 'react';
import { Button, Col, Row, CardBody } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getMultiLangEmpty, getVoucherCountries } from './HeyCardFunctions';
import ReactHtmlParser from 'react-html-parser';
import { getAPI } from '../Base/API';
import { BlankCard, StyledCard, StyledModal } from '../Base/CommonUIComponents';

class PrintVoucherModal extends Component {
    static displayName = PrintVoucherModal.name;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            color: '#0000ff',
            col: 3,
            mode: 'card',
            voucherTemplate: '',
            countries: getVoucherCountries,
            language: getVoucherCountries[0]
        }
    }

    componentDidMount() {
        this.getVoucherTemplate();
    }

    getVoucherTemplate = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                }
                if (data.response && data.response.length > 0) {
                    this.setState({ voucherTemplate: data.response[0], error: data.erros });
                }
            }
            this.setState({ block: false });
        }, `/api/Voucher/v1/Notification/voucherNotification/${this.props.voucherId}?hasPdf=false&lang=${getMultiLangEmpty().filter(lang => lang.language === this.state.language).map((lang) => lang.languageCode)}`); 
    }

    setLanguage = (lang) => {
        let { language } = this.state;

        if (language !== lang) {
            this.setState({
                language: lang,
                block: true
            }, () => {
                this.getVoucherTemplate();
            });
        }
    }

    prt() {
        var divContents = document.getElementById("printThis").innerHTML;
        var a = window.open('', 'PRINT');
        a.document.write(divContents);
        a.document.close();

        a.onafterprint = a.close;


        var is_chrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;

        a.onload = function () { // necessary if the div contain images
            a.focus();
            a.print();
            a.close();
        };
    }

    setLanguage(country) {
        let { language } = this.state;
        if (language !== country) {
            language = country;
            this.setState({ language }, () => {
                if (this.props.match.params.id) {
                    this.getVouherDetails(this.props.match.params.id);
                }
            });
        }
    }

    render() {
        return (
            <StyledModal toggleModal={this.props.toggleModal} modal={this.props.modal} size="xl">
                <BlankCard block={this.state.block} error={this.state.error}>
                    <Row className="mb-3 align-items-center">
                        <Col className="ml-2">
                            {this.state.countries.map((country, key) =>
                                <span key={key}>
                                    <div
                                        className={`flag-icon flag-icon-${country.toLowerCase()} fa-lg mb-2 px-2 mr-1 pointer`}
                                        style={{ filter: this.state.language !== country ? 'brightness(50%)' : '' }}
                                        onClick={() => this.setLanguage(country)}
                                    />
                                </span>
                            ) }
                        </Col>
                        <Col className="my-2 text-right">
                            <Button className="btn btn-sm btn-host" onClick={this.prt.bind(this)}>
                                <i className="fas fa-print" />
                            </Button>
                        </Col>
                    </Row>
                    <div id="printThis">
                        {ReactHtmlParser(this.state.voucherTemplate && this.state.voucherTemplate.template)}
                    </div>
                </BlankCard>
            </StyledModal>
        );
    }
}

export default injectIntl(PrintVoucherModal);