import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Col, Row, Card, UncontrolledCollapse, UncontrolledTooltip } from "reactstrap";
import { MarketingSendRunStatus, LastStatusBadge } from '../Common/MarketingFunctions';
import { Pagination, ResultsPerPage, TotalResults } from '../../Base/PaginationComponents'; 
import { StyledCard, CommonHelper } from "../../Base/CommonUIComponents";
import { getCountryISO2 } from "../../Base/ReferenceDataFunctions";
import { SendGridTemplateModal, TemplateModal } from '../../ProfileMenu/TemplateModal';
import { handleNotification } from "../../Base/Notification";
import CustomSelect from "../../Base/CustomSelect";
import { DateRangePicker } from 'react-dates';
import { getAPI } from "../../Base/API";
import BlockUi from 'react-block-ui';
import moment from 'moment';

class SendDetails extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            pageIndex: 0,
            pageSize: 10,
            totalItems: 0,
            marketingSendRun: [],
            templateModal: false,
            previewTemplate: null,
            communicationFilter: [],
            statusFilter: [{ options: [{ label: this.props.intl.formatMessage({ id: "Status.True" }), value: true }, { label: this.props.intl.formatMessage({ id: "Status.False" }), value: false }] }],
            fromDate: moment().subtract(7, 'days'),
            toDate: moment()
        };
    }

    componentDidMount() {
        this._isMounted = true;

        this.getCampaignsAndAlerts();
        this.getMarketingSendRuns();
    }

    getMarketingSendRuns = () => {
        this.setState({ block: true });

        var params = '';
        params += this.state.campaignId ? `&id=${this.state.campaignId}` : '';
        params += this.state.alertId ? `&alertId=${this.state.alertId}` : '';
        params += this.state.fromDate && this.state.toDate ? `&fromDate=${moment(this.state.fromDate).format('YYYY-MM-DD')}&toDate=${moment(this.state.toDate).format('YYYY-MM-DD')}` : '';
        if (this.state.status != null) {
            params += `&success=${this.state.status}`;
        }
        
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({
                    marketingSendRun: data.response,
                    totalItems: data.totalItems ? data.totalItems : 0,
                    block: false
                });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Marketing/v1/MarketingSendRun?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}` + params);
    }

    getCampaignsAndAlerts = async () => {
        this.setState({ blockCommFilter: true });

        let communications = [
            { label: this.props.intl.formatMessage({ id: "CommunicationJourney.UniqueCampaigns" }), options: [] },
            { label: this.props.intl.formatMessage({ id: "CommunicationJourney.RecurringCampaigns" }), options: [] },
            { label: this.props.intl.formatMessage({ id: "CommunicationJourney.EgoiCampaigns" }), options: [] },
            { label: this.props.intl.formatMessage({ id: "CommunicationJourney.Alerts" }), options: [] }
        ];

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.getAlerts(communications);
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.getAlerts(communications);
                }
                else if (data.response) {
                    data.response.forEach((campaign, idx) => {
                        const obj = { label: campaign.name, value: `Campaign-${campaign.id}`, id: campaign.id };

                        if (campaign.marketingType === 'Custom' || campaign.marketingType === 'CustomAB' || campaign.marketingType === 'CustomLinked' || campaign.marketingType === 'CustomInquiry') {
                            communications[0].options.push(obj);
                        }
                        else if (campaign.marketingType === 'EgoiCampaign') {
                            communications[2].options.push(obj)
                        }
                        else communications[1].options.push(obj)

                        if (idx + 1 === data.response.length) {
                            this.getAlerts(communications);
                        }
                    });
                }
                else {
                    this.getAlerts(communications)
                }

            }
        }, `/api/gms/Marketing/v1/marketingsend/basicList`);
    }

    getAlerts = (communications) => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockCommFilter: false });
                return;
            }
            if (data) {
                data.response && data.response.forEach(alert => {
                    const obj = { label: alert.name, value: `Alert-${alert.id}`, isAlert: true, id: alert.id };

                    communications[3].options.push(obj)
                });
                this.setState({ communicationFilter: communications, blockCommFilter: false });
            }
            else this.setState({ communicationFilter: communications, blockCommFilter: false });
        }, `/api/gms/Marketing/v1/marketingalert/list?pageSize=1000&pageIndex=0`);
    }

    getPreviewTemplate = (e, templateId, channel) => {
        e.preventDefault();

        if (parseInt(templateId)) {
            this.getEgoiTemplate(templateId, channel);
        }
        else {
            this.getSendGridTemplate(templateId);
        }
    }

    getEgoiTemplate = (templateId, channel) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ previewTemplate: data, templateModal: true, error: errorMessage, block: false });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Marketing/v1/egoitransactional/template/${channel === 'Email' ? 'email' : 'sms'}/${templateId}`);
    }

    getSendGridTemplate = (templateId) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ previewTemplate: data.response, templateModal: true, error: errorMessage, block: false });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/SendGrid/v1/templates/${templateId}`);
    }

    handleCommunication = (combo) => {
        let alertId = null, campaignId = null;

        if (combo) {
            if (combo.isAlert) alertId = combo.id;
            else campaignId = combo.id;
        }

        this.setState({
            alertId, campaignId
        });
    };

    handleStatus = (combo) => {
        let status = null;

        if (combo) {
            status = combo.value;
        }

        this.setState({
            status
        });
    };

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getMarketingSendRuns());
    }

    toggleModal = () => {
        this.setState(prevState => ({ templateModal: !prevState.templateModal }));
    }

    toggleCollapse = (key) => {
        this.setState(prevState => ({
            [key]: !prevState[key]
        }));
    }

    doSearch = (e) => {
        e.preventDefault();
        this.setState({
            pageIndex: 0
        }, () => this.getMarketingSendRuns());
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0 }, () => this.getMarketingSendRuns());
        }
    }

    render() {
        const { marketingSendRun, block, error, pageIndex, totalItems, pageSize, templateModal, previewTemplate, communicationFilter, statusFilter } = this.state;

        return (
            <StyledCard icon={'fas fa-paper-plane'} title={'NavMenu.SendDetails'} block={block} error={error} >
                {templateModal ?
                    previewTemplate?.versions ? 
                    <SendGridTemplateModal isOpen={templateModal} toggle={this.toggleModal} previewTemplate={previewTemplate} />
                    :
                    <TemplateModal isOpen={templateModal} toggle={this.toggleModal} previewTemplate={previewTemplate} />
                : ''}

                <Row className="my-3">
                    <Col className="col-3" style={{ zIndex: '90' }}>
                        <BlockUi tag="div" blocking={this.state.blockCommFilter}>
                            <CustomSelect
                                options={communicationFilter}
                                isClearable
                                isSearchable
                                placeholder={<FormattedMessage id="SendDetails.Communication" />}
                                onChange={(e) => this.handleCommunication(e)}
                            />
                        </BlockUi>
                    </Col>
                    <Col className="col-2 text-left">
                        <BlockUi tag="div" blocking={this.state.blockCommFilter}>
                            <CustomSelect
                                options={statusFilter}
                                isClearable
                                isSearchable
                                placeholder={<FormattedMessage id="SendDetails.Status" />}
                                onChange={(e) => this.handleStatus(e)}
                            />
                        </BlockUi>
                    </Col>
                    <Col>
                        <DateRangePicker
                            startDate={this.state.fromDate}
                            startDateId="fromDate"
                            isOutsideRange={day => day > moment()}
                            endDate={this.state.toDate}
                            endDateId="toDate"
                            onDatesChange={({ startDate, endDate }) => this.setState({ fromDate: startDate, toDate: endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>

                    <Col className="col-1 text-right pt-2 mr-3">
                        <Button className=" btn btn-host btn-sm" type="submit" onClick={this.doSearch}>
                            <i className="icon-icon-search" />
                        </Button>
                        <CommonHelper help={<FormattedMessage id="MarketingSendDetails.Help" />} id={'MarketingSendDetails'} lang={this.props.intl.locale} />
                    </Col>
                </Row>


                {marketingSendRun && marketingSendRun.length > 0 ?
                    <div>
                        <Row>
                            <Col>
                                <Card className=" shadow p-2 py-2 border-0 mb-2 text-muted" >
                                    <Row className="px-2 align-items-center">
                                        <Col>
                                            <FormattedMessage id="SendDetails.Channel/CommunicationName" />
                                        </Col>

                                        <Col>
                                            <FormattedMessage id="SendDetails.Sender" />
                                        </Col>

                                        <Col sm={1}>
                                            <FormattedMessage id="SendDetails.Country" />
                                        </Col>

                                        <Col sm={1}>
                                            <FormattedMessage id="SendDetails.OS" />
                                        </Col>

                                        <Col sm={1}>
                                            <FormattedMessage id="SendDetails.Browser" />
                                        </Col>

                                        <Col sm={3} className="text-right" style={{ paddingRight: '60px' }}>
                                            <FormattedMessage id="SendDetails.Status/Preview" />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>

                        {marketingSendRun.map((comm, key) => {
                            return (
                                <Row className="my-1" key={key}>
                                    <Col>
                                        <Card className=" shadow p-2 py-3 border-0 mb-2" >
                                            <Row className="px-2 align-items-center">
                                                <Col onClick={() => this.toggleCollapse(`collapse${key}`)} id={`MarketingSend${key}`} className="pointer" >
                                                    {!this.state[`collapse${key}`] ? <i className="fas fa-angle-right"/> : <i className="fas fa-angle-down"/>}
                                                    <span className="bg-light-grey circle-sm mx-2">
                                                        {comm.senderChannel === "Email" ? <i className="icon-icon-mail mx-1" /> : <i className="icon-icon-sms" style={{ padding: '0 5px' }} />}
                                                    </span>
                                                    <b>{comm.campaignName}   </b>
                                                </Col>

                                                <Col className="d-flex flex-column" style={{ overflow: 'overlay' }}>
                                                    <b className="pointer" onClick={() => this.props.history.push({ pathname: `/ProfileDetails/${comm.profileId}` })}>
                                                        {comm.firstName} {comm.lastName}
                                                    </b>
                                                    <span className="text-muted"> {comm.recipientEmail} </span>
                                                </Col>

                                                <Col sm={1}>
                                                    <span className={'mr-2 ' + (getCountryISO2(comm.country) ? ` flag-icon flag-icon-${getCountryISO2(comm.country).toLowerCase()}` : ' ')} style={{ borderRadius: '2px' }} /> {comm.country}
                                                </Col>

                                                <Col sm={1}>
                                                    {comm.os && comm.os !== 'unknown' ? comm.os : ''}
                                                </Col>

                                                <Col sm={1}>
                                                    {comm.browser && comm.browser !== 'unknown' ? comm.browser : ''}
                                                </Col>

                                                <Col className="col-3 text-right">
                                                    <LastStatusBadge data={comm} />

                                                    <span className={"circle-sm color-white mx-5 " + (comm.success ? 'bg-green' : ' bg-light-red')}>
                                                        {comm.success ? <i className="fas fa-sm fa-check mx-1" /> : <i className="fas fa-sm fa-times " id={`response${key}`} style={{ padding: '0 5px' }} />}
                                                        {!comm.success && comm.response ?
                                                            <UncontrolledTooltip placement="bottom" target={`response${key}`}>
                                                                <div>{comm.responseStatus}</div>
                                                                <div>{comm.response}</div>
                                                            </UncontrolledTooltip>
                                                        : ''}

                                                    </span>
                                                    <Button className="btn btn-host btn-sm" onClick={(e) => this.getPreviewTemplate(e, comm.templateId, comm.senderChannel)} disabled={comm.templateId === null || comm.templateId === 0}>
                                                        <i className="icon-icon-preview" />
                                                    </Button>
                                                </Col>
                                            </Row>

                                            <UncontrolledCollapse toggler={`#MarketingSend${key}`}>
                                                <MarketingSendRunStatus
                                                    key={key}
                                                    index={key}
                                                    comm={comm}
                                                />
                                            </UncontrolledCollapse >
                                        </Card>
                                    </Col>
                                </Row>
                            );
                        })
                        }


                        <Row>
                            <Col>
                                <ResultsPerPage
                                    changeSizePerPage={this.changePageSize}
                                    pageSize={pageSize}
                                />

                                <span className="ml-2">
                                    <TotalResults
                                        end={pageIndex * pageSize + marketingSendRun.length}
                                        start={pageIndex * pageSize + 1}
                                        total={totalItems}
                                    />
                                </span>
                            </Col>

                            <Col className="text-right mr-3">
                                <Pagination
                                    isPrevDisabled={pageIndex === 0}
                                    isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                    currentPage={pageIndex + 1}
                                    handlePrevButton={() => this.handlePagination(-1)}
                                    handleNextButton={() => this.handlePagination(1)}
                                />
                            </Col>
                        </Row>
                    </div>
                    :
                    <Row>
                        <Col className="d-flex flex-column align-items-center">
                            <FormattedMessage id="SendDetails.NoCampaignsSent" />
                        </Col>
                    </Row>
                }
            </StyledCard>
        );
    }
}
export default injectIntl(SendDetails)