import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Card, Col, Row, Badge, Button, Input, Form } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../Base/ErrorAlert';
import TicketDetails from './TicketDetails';
import { DefaultPage } from '../Base/CommonUIComponents';
import CustomSelect from '../Base/CustomSelect';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { getGoContactMessageStatus } from './TicketsFunctions';
import * as InfiniteScroll from 'react-infinite-scroller';


class Tickets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            pageIndex: 0,
            hasMore: false,
            goContactMsgStatus: getGoContactMessageStatus(this.props.intl)
        };
    }

    componentDidMount() {
        this.getTickets(null, true);
    }

    getTickets = (e, newSearch, pageIndex) => {
        if (e) e.preventDefault();

        if (this.state.hasMore || newSearch) {
            this.setState({ blockList: true, hasMore: false }, () => {
                let { status, selectedTicket, ticketId, createdAtStartDate, createdAtEndDate, updatedAtStartDate, updatedAtEndDate } = this.state;

                var params = `?pageSize=20&pageIndex=${pageIndex || this.state.pageIndex}`;
                if (status) params += `&status=${status}`;
                if (ticketId) params += `&externalId=${ticketId}`;
                if (createdAtStartDate) params += `&createdAtStartDate=${moment(createdAtStartDate).format("YYYY-MM-DD")}`;
                if (createdAtEndDate) params += `&createdAtEndDate=${moment(createdAtEndDate).format("YYYY-MM-DD") }`;
                if (updatedAtStartDate) params += `&updatedAtStartDate=${moment(updatedAtStartDate).format("YYYY-MM-DD")}`;
                if (updatedAtEndDate) params += `&updatedAtEndDate=${moment(updatedAtEndDate).format("YYYY-MM-DD")}`;

                getAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];

                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            handleNotification(data);
                        }

                        let { tickets } = this.state;

                        if (!tickets || newSearch) {
                            const chatWindow = document.getElementById("ticketsList");

                            if (chatWindow) {
                                chatWindow.scrollTo(0, 0);
                            }

                            selectedTicket = null;
                            tickets = [];
                        }

                        tickets = tickets.concat(data.response);

                        const hasMore = data.totalItems > tickets.length;

                        this.setState({
                            tickets, hasMore,
                            totalItems: data.totalItems,
                            blockList: false,
                            pageIndex: pageIndex || this.state.pageIndex,
                            selectedTicket
                        });

                    }
                    else this.setState({ error: errorMessage, blockList: false });
                }, `/api/gms/GoContact/v1/Tickets` + params);
            })
        }
    }

    getTicketDetails = (ticketId) => {
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    this.setState({ selectedTicket: data.data?.[0], block: false });

                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/GoContact/v1/Ticket/${ticketId}`);
        })

    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null,
        });
    };
    

    render() {
        const { block, error, goContactMsgStatus, ticketId, status, updatedAtStartDate, updatedAtEndDate, tickets, selectedTicket, createdAtStartDate, createdAtEndDate, pageIndex } = this.state;

        return (
            <div className="border-0 h-100 py-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <h5> <i className="fas fa-headset" /> Tickets</h5>

                    <Form onSubmit={(e) => this.getTickets(e, true, 0)}>
                        <Row className="mb-3">
                            <Col className="pr-0" style={{ width: '19.2%', maxWidth: '19.2%' }}>
                                <div className="title-sm"><FormattedMessage id="Tickets.TicketId" /></div>
                                <Input
                                    type="text"
                                    name="Ticket"
                                    onChange={(e) => this.setState({ ticketId: e.target.value })}
                                    value={ticketId || ''}
                                    placeholder={this.props.intl.formatMessage({ id: "Tickets.TicketId" })}
                                    maxLength="100"
                                />
                            </Col>
                            <Col style={{ width: '80.8%', maxWidth: '80.8%' }}>
                                <Row className=" align-items-end">
                                    <Col className="pr-0">
                                        <div className="title-sm"><FormattedMessage id="generic.status" /></div>
                                        <CustomSelect
                                            options={[]}
                                            value={status || ''}
                                            onChangeFunc={(evt) => this.handleSelect('status', evt)}
                                            placeholder=""
                                        />
                                    </Col>
                                    <Col className="fullWidth pr-0">
                                        <div className="title-sm"><FormattedMessage id="Tickets.CreatedAtDates" /></div>
                                        <DateRangePicker
                                            startDate={createdAtStartDate ? moment(createdAtStartDate) : null}
                                            startDateId="createdAtStartDate"
                                            isOutsideRange={day => day > moment()}
                                            endDate={createdAtEndDate ? moment(createdAtEndDate) : null}
                                            endDateId="createdAtEndDate"
                                            onDatesChange={({ startDate, endDate }) => this.setState({ createdAtStartDate: startDate, createdAtEndDate: endDate })}
                                            focusedInput={this.state.focusedInput2}
                                            onFocusChange={focusedInput => this.setState({ focusedInput2: focusedInput })}
                                            small={true}
                                            numberOfMonths={1}
                                            showDefaultInputIcon={true}
                                        />
                                    </Col>
                                    <Col className="fullWidth">
                                        <div className="title-sm"><FormattedMessage id="Tickets.UpdatedAtDates" /></div>
                                        <DateRangePicker
                                            startDate={updatedAtStartDate ? moment(updatedAtStartDate) : null}
                                            startDateId="updatedAtStartDate"
                                            isOutsideRange={day => day > moment()}
                                            endDate={updatedAtEndDate ? moment(updatedAtEndDate) : null}
                                            endDateId="updatedAtEndDate"
                                            onDatesChange={({ startDate, endDate }) => this.setState({ updatedAtStartDate: startDate, updatedAtEndDate: endDate })}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                            small={true}
                                            numberOfMonths={1}
                                            showDefaultInputIcon={true}
                                        />
                                    </Col>
                                    <Col className="text-right mb-2">
                                        <Button className="mr-2 btn btn-sm btn-host"  >
                                            <i className="fa fa-plus" />
                                        </Button>
                                        <Button className="btn-sm btn-host" type="submit">
                                            <i className="fas fa-search" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>

                    <Row>
                        <Col className='col-12 d-flex align-items-start pr-0'>
                            <div style={{ width: '18.5%' }}>
                                <Card className="border-0 shadow px-0" body>
                                    <h5 className="px-3">Tickets</h5>

                                    <BlockUi tag="div" blocking={this.state.blockList}>
                                        <div className="verticalScroll" id="ticketsList" style={{ height: '73vh', overflowX: 'hidden' }}>
                                            {tickets && tickets.length > 0 ?
                                                <InfiniteScroll
                                                    pageStart={0}
                                                    loadMore={_ => this.getTickets(null, false, pageIndex + 1)}
                                                    element='div'
                                                    hasMore={this.state.hasMore}
                                                    useWindow={false}
                                                    loader={<div style={{ width: '100%' }} key="loader"><BlockUi tag="div" blocking={true} /></div>}
                                                >
                                                    {tickets?.map((ticket, index) => {
                                                        const isSelected = ticket.externalId === selectedTicket?.ticket_id;

                                                        return (
                                                            <div key={index} style={isSelected ? { borderLeft: '6px solid #0665ff' } : {}} className={isSelected ? 'text-host' : ''}>
                                                                <div className="overbglight py-2 px-3 my-1 pointer" onClick={_ => !isSelected && this.getTicketDetails(ticket.externalId)}>
                                                                    <Row className=" align-items-center">
                                                                        <Col className=" text-truncate">
                                                                            <span className=" text-truncate w-100" title={ticket.subject}>
                                                                                <i className="fas fa-bed mr-2" />
                                                                                {ticket.subject}
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="py-1 align-items-center">
                                                                        <Col className="text-muted">#{ticket.externalId}</Col>
                                                                        <Col className="text-right"> <Badge color="primary">{ticket.status}</Badge></Col>
                                                                    </Row>
                                                                    <Row className="title-sm align-items-center">
                                                                        <Col className="">Name</Col>
                                                                        <Col className="text-right"> {moment(ticket.updatedAt).fromNow()}</Col>
                                                                    </Row>
                                                                </div>
                                                                <hr className="mx-3 my-0" />
                                                            </div>
                                                        )
                                                    })}
                                                </InfiniteScroll>
                                                :
                                                <DefaultPage text="ProfileDetails.NoTicketsAvailable" />
                                            }
                                        </div>
                                    </BlockUi>
                                </Card>
                            </div>
                            <div style={{ width: '81.5%' }}>
                                <Col>
                                    <TicketDetails
                                        selectedTicket={selectedTicket}
                                        goContactMsgStatus={goContactMsgStatus}
                                    />
                                </Col>
                            </div>
                        </Col>
                    </Row>
                </BlockUi>
            </div>
        );
    }
}
export default injectIntl(Tickets)
