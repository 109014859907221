import React, { Component } from 'react'
import egoi from '../../../img/egoi.png';
import roiback from '../../../img/roiback.jpg'; 
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Col, FormGroup, Label, Row, Input, Button, UncontrolledTooltip, CustomInput, Badge } from 'reactstrap'
import CustomSelect from "../../Base/CustomSelect"
import { SelectHotel, CommonHelper } from '../../Base/CommonUIComponents';
import { ErrorAlert } from "../../Base/ErrorAlert";
import BlockUi from 'react-block-ui';
import { CustomTable } from '../../Base/CustomTable';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import countryList from 'react-select-country-list';
import { getAPI, putAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';

import EgoiMappings from './EgoiMappings';
import { errorHandler } from '../../Marketing/CommunicationJourney/CommonFunctions';

class ImportProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ImportType: 'CSV',
            Search: '',
            hotelId: null,
            List: null,
            extraFields: [],
            Profiles: [],
            ProfilesEGOI: [],
            ProfilesRoiback: [],
            EGOISearchParams: [
                { name: "first_name", val: null },
                { name: "last_name", val: null },
                { name: "email", val: null },
                { name: "email_status", val: null },
                { name: "cellphone", val: null },
                { name: "cellphone_status", val: null },
                { name: "phone", val: null },
                { name: "phone_status", val: null },
                { name: "birth_date", val: null },
                { name: "language", val: null },
                { name: "extraField", val: null }
            ],
            RoibackSearchParams: [
                { name: "Name", val: null },
                { name: "Email", val: null },
                { name: "Active", val: null },
                { name: "SignUpGreater", val: null },
                { name: "SignUpLess", val: null },
                { name: "ModifyGreater", val: null },
                { name: "ModifyLess", val: null }
            ],
            focused: {},
            lists: [],
            EGOIoffset: 0,
            EgoiSizePerPage: 10,
            Roibackoffset: 1,
            RoibackCurrentPage: 1,
            CurrentPage: 1,
            Roibacktotal_items: 0,
            total_items: 0,
            modal: false,
            tags: [],
            considerRemoved: false,
            importTypes: [
                { value: 'CSV', label: 'CSV', img: '', autoImport: false, hidden: false },
                { value: 'Roiback', label: 'Roiback', img: roiback, autoImport: false, hidden: (global.modules && global.modules.some(m => m === 'Roiback') ? false : true) },
                { value: 'EGOI', label: 'Egoi', img: egoi, autoImport: false, hidden: (global.modules && global.modules.some(m => m === 'Egoi') ? false : true) }
            ]
        };
    }

    componentDidMount() {
        this.getBathRun();
        this.getLists();
        this.getTags();
    }

    getBathRun = () => {
            getAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.response) {
                        const { importTypes } = this.state;

                        importTypes[1].autoImport = data.response.find(el => el.apiConnectionName === 'Roiback')?.autoImport;

                        importTypes[2].autoImport = data.response.find(el => el.apiConnectionName === 'Egoi')?.autoImport;

                        this.setState({ importTypes });
                    }
                }
            }, `/api/gms/Hotel/v1/batchrun`)
    }

    getTags = () => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                const list = [];

                data.response && data.response.forEach(tag => {
                    list.push({ value: tag.id, label: tag.code });
                })

                this.setState({ tags: list });
            }
        }, `/api/gms/Profile/v1/tag?tagType=all`)
    }

    getLists = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                let list = [];

                data.items && data.items.forEach(el => {
                    list.push({ value: el.list_id, label: el.public_name });
                })

                this.setState({ lists: list, block: false }, this.getEgoiMappings);

                return
            }
            handleNotification(data)
        }, `/api/gms/Marketing/v1/egoi/lists?limit=100&offset=0`);
    }

    getExtraFields = () => {
        const { List } = this.state;
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    const extraFields = [];
                    const imported = data.response.find(resp => resp.name.toUpperCase() === "IMPORTED");
                    if (imported) {
                        const isImported = { value: `[${imported.field_id}]=IMPORTED`, label: this.props.intl.formatMessage({ id: `Profile.ExtraFieldIsImported` }) };
                        extraFields.push(isImported);
                        const isNotImported = { value: `[${imported.field_id}]=""`, label: this.props.intl.formatMessage({ id: `Profile.ExtraFieldIsNotImported` }) };
                        extraFields.push(isNotImported);
                    }
                        
                    this.setState({ error: errorMessage, block: false, extraFields });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false, extraFields: []});
            }
        }, `/api/gms/Marketing/v1/egoi/lists/${List.value}/fields`)
    }

    getEgoiMappings = (e, index) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                const lists = this.state.lists;

                lists.forEach(list => {
                    list.mappings = data.response && data.response.filter(el => el.listId === list.value.toString());
                })

                this.setState({ lists, block: false })
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/gms/Marketing/v1/GenericMapping?originType=Egoi`);
    }

    TransformCSV = (CSV) => {
        var reader = new FileReader();

        reader.readAsText(CSV);

        reader.onload = (e) => {
            var allTextLines = e.target.result.split(/\r\n|\n/).filter(t => t);
            var lines = allTextLines.map(data => data.split(/,|;/));
            var list = [];

            lines.forEach((line, idx) => {
                var object = {};

                object.externalId = line[0];

                object.type = line[1];

                object.firstName = line[2];

                object.fullName = line[2] || line[3] ? `${line[2]} ${line[3]}` : null;

                object.lastName = line[3];

                object.nif = line[4];

                object.passport = line[5];

                object.citizenCard = line[6];

                object.eMail = line[7];

                object.eMailStatus = line[8];

                object.mobilePhone = line[9];

                object.mobilePhoneStatus = line[10];

                object.phone = line[11];

                object.phoneStatus = line[12];

                object.birthDate = line[13];

                object.nationality = line[14];

                object.consent = line[15];

                object.consentStatus = line[16];

                object.startConsent = line[17];

                object.endConsent = line[18];

                object.MissingFields = [];

                object.Status = true;

                if(object.type !== 'Person' && object.type !== 'Company' && object.type !== 'Agency' && object.type !== 'CRO' && object.type !== 'Group' && object.type !== 'TimeSharing'
                && object.type !== 'Owner' && object.type !== 'Supplier'){
                    object.MissingFields.push('Type')
                    object.Status = false
                }
                if(!object.fullName){
                    object.MissingFields.push('fullName')
                    object.Status = false
                }
                if (!object.firstName) {
                    object.MissingFields.push('Name')
                    object.Status = false
                }
                if (!object.lastName) {
                    object.MissingFields.push('LastName')
                    object.Status = false
                }
                if(!object.externalId){
                    object.MissingFields.push('ID')
                    object.Status = false
                }
                if(object.eMail && !object.eMail.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
                    object.MissingFields.push('Email')
                    object.Status = false
                }
                if (object.eMailStatus) {
                    this.validateBoolean(object.eMailStatus, object, 'email_status');
                }
                if(object.mobilePhone && !object.mobilePhone.match(/^[\+]{0,1}(\d{9,15}|[\(][\+]{0,1}\d{2,}[\13)]*\d{5,13}|\d{2,6}[\-]{1}\d{2,13}[\-]*\d{3,13})$/g)){
                    object.MissingFields.push('Cell')
                    object.Status = false
                }
                if (object.mobilePhoneStatus) {
                    this.validateBoolean(object.mobilePhoneStatus, object, 'ellphone_status');
                }
                if(object.phone && !object.phone.match(/^[\+]{0,1}(\d{9,15}|[\(][\+]{0,1}\d{2,}[\13)]*\d{5,13}|\d{2,6}[\-]{1}\d{2,13}[\-]*\d{3,13})$/g)){
                    object.MissingFields.push('Phone')
                    object.Status = false
                }
                if (object.phoneStatus) {
                    this.validateBoolean(object.phoneStatus, object, 'phone_status');
                }
                if(object.birthDate){
                    if (!moment(object.birthDate) || moment(object.birthDate).isAfter(moment()) || this.isValidDate(object.birthDate) === false){
                        object.MissingFields.push('Birthdate')
                        object.Status = false
                    }
                }
                if(object.nationality && !countryList().getValueList()[object.nationality.toLowerCase()]){
                    object.MissingFields.push('Nationality')
                    object.Status = false
                }
                if(object.consent && object.consent !== 'General' && object.consent !== 'Interfaces' && object.consent !== 'Mailings'
                && object.consent !== 'GuestInfo'&& object.consent !== 'Stats' && object.consent !== 'Partners' && object.consent !== 'Notifications'){
                    object.MissingFields.push('Consent')
                    object.Status = false
                }
                if (object.startConsent){
                    if (!moment(object.startConsent) || this.isValidDate(object.startConsent) === false){
                        object.MissingFields.push('ConsentStart')
                        object.Status = false
                    }
                    if (object.endConsent){
                        if (!moment(object.endConsent) || this.isValidDate(object.endConsent) === false){
                            object.MissingFields.push('ConsentEnd')
                            object.Status = false
                        }
                        else{
                            if (moment(object.startConsent).isAfter(moment(object.endConsent))){
                                object.MissingFields.push('ConsentStart>')
                                object.Status = false
                            }
                        }
                    }
                }
                if (object.endConsent && !moment(object.endConsent) && !object.MissingFields.find((miss) => miss === 'endConsent')){
                    object.MissingFields.push('ConsentEnd')
                    object.Status = false
                }
                if (object.consentStatus) {
                    this.validateBoolean(object.consentStatus, object, 'ConsentStatus');
                }
                if(idx === 0 && !object.Status && object.externalId.toLowerCase() === 'id'){}
                else {
                    list.push(object);
                }
            })

            if (lines.find(l => l.length !== 17) && lines.indexOf(lines.find(l => l.length !== 17)) !== 0) {
                errorHandler(<FormattedMessage id="Profile.MissingFields" />)
            }
            else {
                this.setState({
                    Profiles: list,
                    error: null
                })
            }
        }
    }

    isValidDate = (string) => {
        let isValid = false;
        const dateValues = string.includes('-') ? string.split('-') : string.split('/');

        if (dateValues.length === 3 && dateValues[0].length === 4 && dateValues[1].length === 2 && dateValues[2].length === 2) {
            const month = parseInt(dateValues[1]);
            const day = parseInt(dateValues[2]);
            const daysInMonth = moment(`${dateValues[0]}-${month}`, "YYYY-MM").daysInMonth();

            if (month > 0 && month < 13 && day > 0 && day <= daysInMonth) {
                isValid = true;
            }
        }

        return isValid;
    }

    validateBoolean = (field, object, fieldName) => {
        const booleanOptions = ["true", "false"];

        if (booleanOptions.find(bolOpt => bolOpt === field.toLowerCase()) === undefined) {
            object.MissingFields.push(fieldName)
            object.Status = false;
        }
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    handleFiles = (event) => {
        var files = event.target.files;

        if (window.FileReader && files[0].size < 2097152) {
            this.TransformCSV(files[0]);
        }
        else {
            errorHandler("FileReader is not supported in this browser.")
        }
    };

    fileChanged = (e) => {
        e.target.value = null;
    };

    handleTableChange = (type, { page, sizePerPage }) =>{
        const { ImportType } = this.state;
        if(ImportType === "EGOI"){
            this.setState({
                EGOIoffset: (sizePerPage * (page - 1)),
                EgoiSizePerPage: sizePerPage,
                CurrentPage: page
            }, () => { this.GetEGOIProfiles() });
        }
        else if(ImportType === "Roiback"){
            this.setState({
                RoibackCurrentPage:page
            }, () => { this.GetRoibackProfiles(false, true) });
        }
    }

    handleDateChange = (val, name) => {
        const { ImportType } = this.state;
        
        var prev;

        if(ImportType === "EGOI"){
            prev = this.state.EGOISearchParams.find((param) => param.name === name);
        }
        else if(ImportType === "Roiback"){
            prev = this.state.RoibackSearchParams.find((param) => param.name === name);
        }

        prev.val = val ? val.format("YYYY-MM-DD") : '';

        this.setState(prevState => ({ Params: { ...prevState.Params, [name]: prev } }));
    }

    handleFormChange = (val, name) => {
        const { ImportType } = this.state;

        var prev;
        
        if(ImportType === "EGOI"){
            prev = this.state.EGOISearchParams.find((param) => param.name === name);
        }
        else if(ImportType === "Roiback"){  
            prev = this.state.RoibackSearchParams.find((param) => param.name === name);
        }
        
        prev.val = val
        this.setState(prevState => ({ Params: { ...prevState.Params, [name]: prev } }));
    }

    doEgoiSearch = () => {
        this.setState({
            EGOIoffset: 0,
            CurrentPage: 1
        }, () => { this.GetEGOIProfiles() });
    }

    GetEGOIProfiles = () => {
        const { List, EGOISearchParams, EGOIoffset, EgoiSizePerPage } = this.state;
        const firstName = EGOISearchParams.find((param) => param.name === "first_name").val;
        const last_name = EGOISearchParams.find((param) => param.name === "last_name").val;
        const email = EGOISearchParams.find((param) => param.name === "email").val;
        const email_status = EGOISearchParams.find((param) => param.name === "email_status").val;
        const cellphone = EGOISearchParams.find((param) => param.name === "cellphone").val;
        const cellphone_status = EGOISearchParams.find((param) => param.name === "cellphone_status").val;
        const phone = EGOISearchParams.find((param) => param.name === "phone").val;
        const phone_status = EGOISearchParams.find((param) => param.name === "phone_status").val;
        const birth_date = EGOISearchParams.find((param) => param.name === "birth_date").val;
        const language = EGOISearchParams.find((param) => param.name === "language").val;
        const extraField = EGOISearchParams.find((param) => param.name === "extraField").val;

        if(List){
            this.setState({ block: true });
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                
                if (error){
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.Errors && data.Errors.length > 0) {
                        handleNotification(data);
                    }

                    const profile = [];

                    data.items && data.items.forEach(pro => {
                        const item = pro.base;
                        item.tags = pro.tags;
                        item.extra = pro.extra;

                        profile.push(item);
                    })
                    profile.forEach((pro) =>{
                        if(pro.birth_date ==="0000-00-00 00:00:00"){
                            pro.birth_date = ""
                        }
                        if(pro.consent_date ==="0000-00-00 00:00:00"){
                            pro.consent_date = ""
                        }
                    })
                    this.setState({ProfilesEGOI:profile, total_items:data.total_items})
                }
                this.setState({ error: errorMessage, block: false });

            }, `/api/gms/Marketing/v1/egoi/contacts/${List.value}?offset=${EGOIoffset}&limit=${EgoiSizePerPage}${firstName ? `&firstName=${firstName}` : ''}${last_name ? `&lastName=${last_name}` : ''}${email ? `&email=${email}` : ''}${email_status !== null ? `&mailStatus=${email_status}` : ''}${cellphone ? `&cellphone=${cellphone}` : ''}${cellphone_status !== null ? `&cellphoneStatus=${cellphone_status}` : ''}${phone ? `&phone=${phone}` : ''}${phone_status !== null ? `&phoneStatus=${phone_status}` : ''}${birth_date ? `&birthdate=${birth_date}` : ''}${language ? `&language=${language}` : ''}${extraField ? `&extraField=${extraField}` : ``}`);
        }
    }

    Save = () => {
        const { List, EGOISearchParams, hotelId } = this.state;

        this.setState({ block: true });

        if(this.state.ImportType === "CSV"){
            if(!this.state.Profiles.find((pro) => !pro.Status)){
                const OBJ = { request: JSON.parse(JSON.stringify(this.state.Profiles))}

                OBJ.request.forEach(el => {
                    delete el.MissingFields;
                    delete el.Status;
                    delete el.duplicatedProfiles;
                })

                const objSizeInMB = new TextEncoder().encode(JSON.stringify(OBJ)).length / 1024 / 1024;

                if (objSizeInMB > 1) {
                    handleNotification('', <FormattedMessage id="ImportProfile.PleaseSelectAFileLessThan" />, <FormattedMessage id="SendersList.SelectedFileIsTooBig" />, 'info');
                    this.setState({ block: false });
                    return;
                }


                putAPI(result => {
                    const { data, error } = result;

                    if (error) {
                        var errorMessage = [];
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    this.setState({ block: false });
                    handleNotification(data, `${this.state.Profiles.length} ${this.props.intl.formatMessage({ id: "Profile.ImportSuccess" })}`)
                }, `/api/gms/Profile/v1/profile/csv/load?hotelId=${hotelId}`, OBJ);
            }
        }
        else if(this.state.ImportType === "Roiback"){
            this.GetRoibackProfiles(true);
        }
        else{
            const firstName = EGOISearchParams.find((param) => param.name === "first_name").val;
            const last_name = EGOISearchParams.find((param) => param.name === "last_name").val;
            const email = EGOISearchParams.find((param) => param.name === "email").val;
            const email_status = EGOISearchParams.find((param) => param.name === "email_status").val;
            const cellphone = EGOISearchParams.find((param) => param.name === "cellphone").val;
            const cellphone_status = EGOISearchParams.find((param) => param.name === "cellphone_status").val;
            const phone = EGOISearchParams.find((param) => param.name === "phone").val;
            const phone_status = EGOISearchParams.find((param) => param.name === "phone_status").val;
            const birth_date = EGOISearchParams.find((param) => param.name === "birth_date").val;
            const language = EGOISearchParams.find((param) => param.name === "language").val;

            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                this.setState({ error: errorMessage, block: false });
                handleNotification(data, <FormattedMessage id="Profile.ImportSuccess" />, <FormattedMessage id="generic.success" />)
            }, `/api/gms/Profile/v1/profile/egoi/load?hotelId=${hotelId}&listId=${List.value}${firstName ? `&firstName=${firstName}` : ''}${last_name ? `&lastName=${last_name}` : ''}${email ? `&email=${email}` : ''}${email_status ? `&mailStatus=${email_status}` : ''}${cellphone ? `&cellphone=${cellphone}` : ''}${cellphone_status ? `&cellphoneStatus=${cellphone_status}` : ''}${phone ? `&phone=${phone}` : ''}${phone_status ? `&phoneStatus=${phone_status}` : ''}${birth_date ? `&birthdate=${birth_date}` : ''}${language ? `&language=${language}` : ''}&considerRemoved=${this.state.considerRemoved}`);
        }
    }

    doRoibackSearch = () => {
        this.setState({
            EGOIoffset: 0,
            CurrentPage: 1
        }, () => { this.GetRoibackProfiles() });
    }

    GetRoibackProfiles = (save, IsNewPage) => {
        const { RoibackSearchParams, RoibackCurrentPage, hotelId } = this.state;
        
        const Name = RoibackSearchParams.find((param) => param.name === "Name").val;
        const Email = RoibackSearchParams.find((param) => param.name === "Email").val;
        const Active = RoibackSearchParams.find((param) => param.name === "Active").val;
        const SignUpGreater = RoibackSearchParams.find((param) => param.name === "SignUpGreater").val;
        const SignUpLess = RoibackSearchParams.find((param) => param.name === "SignUpLess").val;
        const ModifyGreater = RoibackSearchParams.find((param) => param.name === "ModifyGreater").val;
        const ModifyLess = RoibackSearchParams.find((param) => param.name === "ModifyLess").val;

        const QSFiltesr = `${Name ? `&nombre=${Name}` : ''}${Email ? `&email=${Email}` : ''}${Active !== null ? `&active=${Active}` : ''}
        ${SignUpGreater ? `&signFrom=${SignUpGreater}` : ''}${SignUpLess ? `&signTo=${SignUpLess}` : ''}${ModifyGreater ? `&modifyFrom=${ModifyGreater}` : ''}
        ${ModifyLess ? `&modifyTo=${ModifyLess}` : ''}`

        this.setState({ block: true,  Roibacktotal_items:0 });
        if(save){
            this.setState({ ProfilesRoiback: [] });
        }

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            
            if (error){
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data){
                if(data.errors && data.errors.length > 0){
                    data.errors.forEach((error) => {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    })
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if(!save){
                    if(data.response && data.response[0].result){
                        const profile = data.response[0].result.map((pro) => {pro.loyalty = pro.level.code; return pro});
                        this.setState({ProfilesRoiback: profile, Roibacktotal_items:data.response[0].pagination && data.response[0].pagination.count})
                    }
                }
                else{
                    handleNotification(data, `${data.totalItems} ${this.props.intl.formatMessage({ id: "Profile.ImportSuccess" })}`);
                }
            }
            this.setState({ error: errorMessage, block: false });

        }, `/api/gms/Roiback/v1/RegisteredUsers?hotelId=${hotelId}&page=${IsNewPage ? RoibackCurrentPage : 1}&importUser=${save ? save : false}${QSFiltesr}`);
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    handleCustomInput = (evt) => {
        if (evt && evt.target) {
            const { name, checked } = evt.target;

            this.setState({ [name]: checked });
        }
    }

    getCSVStatus = (cell, row, idx) => {
        return (
            <div>
                {
                    row.duplicatedProfiles && row.duplicatedProfiles.length > 0 ?
                        <div>
                            <UncontrolledTooltip target={`TooltipDuplicated${idx}`} placement="left" >
                                <div>
                                    <b><FormattedMessage id='Profile.Duplicated' />:</b>
                                    {
                                        row.duplicatedProfiles                                            
                                            .map(dp => dp.dataTypes && dp.dataTypes
                                                .reduce((acc, curr) => {
                                                    let dataType = acc.find(dt => dt.dataType === curr);
                                                    if (dataType) {
                                                        dataType.amount++;
                                                    }
                                                    else {
                                                        acc.push({
                                                            dataType: curr,
                                                            amount: 1
                                                        });
                                                    }

                                                    return acc;
                                                }, [])
                                                .map(dt => <div><FormattedMessage id={`ReferenceDataFunctions.${dt.dataType}`} />{`${dt.amount > 1 ? ` (${dt.amount}x)` : ``}`} </div>))
                                    }
                                </div>
                            </UncontrolledTooltip>
                            <span id={`TooltipDuplicated${idx}`}>
                                <i className="fas fa-sm fa-exclamation-triangle ml-2" style={{ color: '#ffc107' }} />
                            </span>
                        </div>
                        :
                        <div>
                            {!cell &&
                                <UncontrolledTooltip target={`Tooltip${idx}`} placement="left" >
                                    <div>
                                        <b><FormattedMessage id='Profile.Missing' /></b>
                                        {row.MissingFields.map((miss, key) => {
                                            if (miss !== 'Status') {
                                                return (
                                                    <div key={key}><FormattedMessage id={`Profile.Import${miss}`} /></div>
                                                )
                                            }
                                        }
                                        )}
                                    </div>
                                </UncontrolledTooltip>
                            }
                            <span id={`Tooltip${idx}`}>
                                <i style={{ color: cell ? '#76EFAC' : '#EE6A5F' }} className={`fas fa-${cell ? 'check' : 'times'}`}></i>
                            </span>
                        </div>
                }
            </div>
        )
    }

    handleEgoiList = (combo) => {
        const { EGOISearchParams } = this.state;
        const extraField = EGOISearchParams.find((param) => param.name === "extraField")
        if (extraField) {
            extraField.val = null;
        }
        if (combo !== null) {
            this.setState({ List: combo, EGOISearchParams }, this.getExtraFields);
        }
        else {
            
            this.setState({ List: combo, extraFields: [], EGOISearchParams });
        }
    }

    checkDuplicatedProfiles = () => {
        const { hotelId, Profiles } = this.state;

        const OBJ = {
            request: JSON.parse(JSON.stringify(Profiles))
        };

        OBJ.request.forEach(el => {
            delete el.MissingFields;
            delete el.Status;
            delete el.duplicatedProfiles;
        });

        const objSizeInMB = new TextEncoder().encode(JSON.stringify(OBJ)).length / 1024 / 1024;

        if (objSizeInMB > 1) {
            handleNotification('', <FormattedMessage id="ImportProfile.PleaseSelectAFileLessThan" />, <FormattedMessage id="SendersList.SelectedFileIsTooBig" />, 'info');
            return;
        }

        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                handleNotification(data, <FormattedMessage id="Profile.VerifyDuplicatedProfilesSuccess" />);

                Profiles.forEach((prof, idx) => {
                    if (data.response[idx].profiles && data.response[idx].profiles.length > 0) {
                        prof.duplicatedProfiles = data.response[idx].profiles;
                    }
                });
                this.setState({ Profiles: [] }, _ => this.setState({ error: errorMessage, block: false, Profiles }));
            }
        }, `/api/gms/Profile/v1/profile/verifyDuplicatedProfiles?hotelId=${hotelId}`, OBJ);
    }

    render() {
        const columnsCSV = [
            {
                dataField: 'externalId',
                text: "ID",
                sort: true
            },
            {
                dataField: 'type',
                text: this.props.intl.formatMessage({ id: "Profile.ImportType" }),
                sort: true
            },
            {
                dataField: 'firstName',
                text: this.props.intl.formatMessage({ id: "Profile.ImportName" }),
                sort: true
            },
            {
                dataField: 'lastName',
                text: this.props.intl.formatMessage({ id: "Profile.ImportLastName" }),
                sort: true
            },
            {
                dataField: 'nif',
                text: this.props.intl.formatMessage({ id: "Profile.ImportNIF" }),
                sort: true,
            },
            {
                dataField: 'passport',
                text: this.props.intl.formatMessage({ id: "Profile.ImportPassport" }),
                sort: true,
            },
            {
                dataField: 'citizenCard',
                text: this.props.intl.formatMessage({ id: "Profile.CitizenCard" }),
                sort: true,
            },
            {
                dataField: 'eMail',
                text: this.props.intl.formatMessage({ id: "Profile.ImportEmail" }),
                sort: true,
            },
            {
                dataField: 'eMailStatus',
                text: this.props.intl.formatMessage({ id: "Profile.ImportStatus" })
            },
            {
                dataField: 'mobilePhone',
                text: this.props.intl.formatMessage({ id: "Profile.ImportCell" }),
                sort: true,
            },
            {
                dataField: 'mobilePhoneStatus',
                text: this.props.intl.formatMessage({ id: "Profile.ImportStatus" })
            },
            {
                dataField: 'phone',
                text: this.props.intl.formatMessage({ id: "Profile.ImportPhone" }),
                sort: true,
            },
            {
                dataField: 'phoneStatus',
                text: this.props.intl.formatMessage({ id: "Profile.ImportStatus" })
            },
            {
                dataField: 'birthDate',
                text: this.props.intl.formatMessage({ id: "Profile.ImportBirthdate" }),
                sort: true,
                //formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : ''
            },
            {
                dataField: 'nationality',
                text: this.props.intl.formatMessage({ id: "Profile.ImportNationality" }),
                sort: true,
            },
            {
                dataField: 'consent',
                text: this.props.intl.formatMessage({ id: "Profile.ImportConsent" }),
                sort: true
            },
            {
                dataField: 'consentStatus',
                text: this.props.intl.formatMessage({ id: "Profile.ImportStatus" }),
                sort: true
            },
            {
                dataField: 'startConsent',
                text: this.props.intl.formatMessage({ id: "Profile.ImportConsentStart" }),
                sort: true,
                //formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : ''
            },
            {
                dataField: 'endConsent',
                text: this.props.intl.formatMessage({ id: "Profile.ImportConsentEnd" }),
                sort: true,
                //formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : ''
            },
            {
                dataField: 'Status',
                text: this.props.intl.formatMessage({ id: "Profile.ImportProfileStatus" }),
                sort: true,
                formatter: (cell, row, idx) => this.getCSVStatus(cell, row, idx),
                csvExport: false
            }
        ];

        const columnsEGOI = [
            {
                dataField: 'first_name',
                text: this.props.intl.formatMessage({ id: "Profile.ImportName" })
            },
            {
                dataField: 'last_name',
                text: this.props.intl.formatMessage({ id: "Profile.ImportLastName" })
            },
            {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: "Profile.Status" })
            },
            {
                dataField: 'email',
                text: this.props.intl.formatMessage({ id: "Profile.ImportEmail" })
            },
            {
                dataField: 'email_status',
                text: this.props.intl.formatMessage({ id: "Profile.Importemail_status" })
            },
            {
                dataField: 'cellphone',
                text: this.props.intl.formatMessage({ id: "Profile.ImportCell" })
            },
            {
                dataField: 'cellphone_status',
                text: this.props.intl.formatMessage({ id: "Profile.Importcellphone_status" })
            },
            {
                dataField: 'phone',
                text: this.props.intl.formatMessage({ id: "Profile.ImportPhone" })
            },
            {
                dataField: 'phone_status',
                text: this.props.intl.formatMessage({ id: "Profile.Importphone_status" })
            },
            {
                dataField: 'birth_date',
                text: this.props.intl.formatMessage({ id: "Profile.ImportBirthdate" })
            },
            {
                dataField: 'consent',
                text: this.props.intl.formatMessage({ id: "Profile.ImportConsent" })
            },
            {
                dataField: 'consent_date',
                text: this.props.intl.formatMessage({ id: "Profile.ImportConsentStart" })
            },
            {
                dataField: 'language',
                text: this.props.intl.formatMessage({ id: "Profile.Importlanguage" })
            },
            {
                dataField: 'tags',
                text: this.props.intl.formatMessage({ id: "Profile.Tags" }),
                formatter: cell => cell ? cell.reduce((acc, curr) => `${acc.length > 0 ? `${acc},${curr}` : `${acc}${curr}`}`, ``) : ''
            },
            {
                dataField: 'extra',
                text: this.props.intl.formatMessage({ id: "Profile.Extra" }),
                formatter: (cell, _, idx) => <div key={idx}>{cell ? cell.reduce((acc, curr) => curr.value && curr.value.length > 0 ? <span>{acc}{Array.isArray(curr.value) ? `${curr.field_id}:[${curr.value.reduce((extraArr, val) => `${extraArr.length === 0 ? `${val}` : `${extraArr},${val}`}`, ``)}]` : `${curr.field_id}:${curr.value}`}<br /></span> : acc, ``) : ''}</div>
            }
        ];
        
        const columnsRoiback = [
            {
                dataField: 'nombre',
                text: this.props.intl.formatMessage({ id: "Profile.ImportName" })
            },
            {
                dataField: 'apellidos',
                text: this.props.intl.formatMessage({ id: "Profile.ImportLastName" })
            },
            {
                dataField: 'birthdate',
                text: this.props.intl.formatMessage({ id: "Profile.Importbirth_date" })
            },
            {
                dataField: 'dni',
                text: this.props.intl.formatMessage({ id: "Profile.Importdni" })
            },
            {
                dataField: 'idioma',
                text: this.props.intl.formatMessage({ id: "Profile.Importidioma" })
            },
            {
                dataField: 'loyalty',
                text: this.props.intl.formatMessage({ id: "Profile.Importloyalty" })
            },
            {
                dataField: 'telefono',
                text: this.props.intl.formatMessage({ id: "Profile.ImportCell" })
            },
            {
                dataField: 'recibir_newsletter',
                text: this.props.intl.formatMessage({ id: "Profile.Importrecibir_newsletter" }),
                formatter:
                    (cell, row, idx) => {
                        return(
                            <div key={idx}>
                                <span id={`Tooltip${idx}`}>
                                    <i style={{color: '#76EFAC' }} className={`fas fa-check`}></i>
                                </span>
                            </div>
                        )
                    }
            },
            {
                dataField: 'email',
                text: this.props.intl.formatMessage({ id: "Profile.ImportEmail" })
            },
            {
                dataField: 'pais',
                text: this.props.intl.formatMessage({ id: "Profile.ImportCountry" })
            },
            {
                dataField: 'alta',
                text: this.props.intl.formatMessage({ id: "Profile.ImportSigned" })
            },
            {
                dataField: 'modificacion',
                text: this.props.intl.formatMessage({ id: "Profile.ImportModified" })
            },
            {
                dataField: 'activo',
                text: this.props.intl.formatMessage({ id: "Profile.ImportActivo" }),
                formatter:
                    (cell, row, idx) => {
                        return(
                            <div key={idx}>
                                <span id={`Tooltip${idx}`}>
                                    <i style={{color: cell ? '#76EFAC' :  '#EE6A5F'}} className={`fas fa-${cell ? 'check' : 'times'}`}></i>
                                </span>
                            </div>
                        )
                    }
            }
        ];

        const help =  <div>
            <div> <b> ID <FormattedMessage id="ImportProfile.Required" />: </b> <FormattedMessage id="ImportProfile.ProfileId" /> </div>
            <div> <b> <FormattedMessage id="Profile.ImportType" /> <FormattedMessage id="ImportProfile.Required" />: </b> <FormattedMessage id="ImportProfile.ProfileTypes" /> </div>
            <div> <b> <FormattedMessage id="Profile.ImportName" /> <FormattedMessage id="ImportProfile.Required" /> </b> </div>
            <div> <b> <FormattedMessage id="Profile.ImportLastName" /> <FormattedMessage id="ImportProfile.Required" /> </b>  </div>
            <div> <b> <FormattedMessage id="Profile.ImportNIF" /> </b>  </div>
            <div> <b> <FormattedMessage id="Profile.ImportPassport" /> </b>  </div>
            <div> <b> <FormattedMessage id="Profile.CitizenCard" /> </b>  </div>
            <div> <b> <FormattedMessage id="Profile.ImportEmail" /> <FormattedMessage id="ImportProfile.Required" /> </b>  </div>
            <div> <b> <FormattedMessage id="Profile.ImportCell" />: </b> <FormattedMessage id="ImportProfile.ContactFormat" /> </div>
            <div> <b> <FormattedMessage id="Profile.ImportPhone" />: </b> <FormattedMessage id="ImportProfile.ContactFormat" /> </div>
            <div> <b> <FormattedMessage id="Profile.ImportBirthdate" />: </b> <FormattedMessage id="ImportProfile.FormatDate" /> </div>
            <div> <b> <FormattedMessage id="Profile.ImportNationality" />: </b> <FormattedMessage id="ImportProfile.CountryISO2Format" /> </div>
            <div> <b> <FormattedMessage id="Profile.ImportConsent" />: </b> <FormattedMessage id="ImportProfile.ConsentTypes" /> </div>
            <div> <b> <FormattedMessage id="Profile.ImportConsentStart" />: </b> <FormattedMessage id="ImportProfile.FormatDate" /> </div>
            <div> <b> <FormattedMessage id="Profile.ImportConsentEnd" />: </b> <FormattedMessage id="ImportProfile.FormatDate" /> </div>
            <div> <b> <FormattedMessage id="Profile.ImportContactsStatus" /> <FormattedMessage id="ImportProfile.Required" />: </b> <FormattedMessage id="ImportProfile.ContactsStatus" /> </div>
        </div >

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />


                {this.state.modal && this.state.List &&
                    <EgoiMappings
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        List={this.state.List}
                        history={this.props.history}
                        tags={this.state.tags}
                    />
                }

                <Row className="mt-4">
                        <Col>
                        <h5><i className="fas fa-user-plus mr-2"/><FormattedMessage id="ImportProfile.Title"/></h5>
                        </Col>
                        <Col className="text-right">
                        <CommonHelper help={<FormattedMessage id="ProfileImport.Help" />} id={'ProfileImport'} lang={this.props.intl.locale} />
                        </Col>
                </Row>
                
                <FormGroup className='mt-2' row>
                    <Col className='col-10'>
                        <Row>
                            {this.state.importTypes.map((type, key) =>
                                <Col sm={2} key={key} style={{ minWidth: '220px' }}>
                                    <Card className="border-0 shadow pointer" body
                                        onClick={() => this.setState({ ImportType: type.value })}
                                        style={{ backgroundColor: this.state.ImportType === type.value ? '#d3eaff' : 'white' }}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <>
                                            <img className='mr-2' src={type.img} width={20} alt=""/>
                                                <div>{type.label}</div>
                                                </>
                                            {type.autoImport ?
                                                <div className="w-100 text-right">
                                                    <Badge color="primary ml-2"> <FormattedMessage id="BatchRunModal.AutoImport" /> </Badge>
                                                </div>
                                            : <div/>}
                                        </div>
                                    </Card>
                                </Col>
                                
                            )}
                        </Row>
                    </Col>
                    <div className='col-2' style={{display:'flex', justifyContent:'end',}}>
                        {this.state.ImportType === "EGOI" ?
                            <div className='d-flex'>
                                <div>

                                    <Button disabled={!this.state.List} className="btn btn-sm btn-host mr-2" onClick={this.toggleModal} id="egoiMapping">
                                        <i className="icon-icon-preview"></i>
                                    </Button>

                                    <UncontrolledTooltip target={`egoiMapping`} placement="left" >
                                        <FormattedMessage id="ImportProfile.ViewEgoiMappings" />
                                    </UncontrolledTooltip>

                                    <Button disabled={!this.state.List || !this.state.hotelId} className="btn btn-sm btn-host" onClick={this.doEgoiSearch}>
                                        <i className="icon-icon-search"></i>
                                    </Button>
                                </div>
                                {this.state.ProfilesEGOI.length > 0 &&
                                <div className='ml-2'>
                                    <Button className="btn btn-sm btn-host" onClick={this.Save}>
                                        <i className="fas fa-save"></i>
                                    </Button>
                                </div>}
                            </div>
                        :this.state.ImportType === "Roiback" ?
                            <div className='d-flex'>
                                <div>
                                    <Button disabled={!this.state.hotelId} className="btn btn-sm btn-host" onClick={this.doRoibackSearch}>
                                        <i className="icon-icon-search"></i>
                                    </Button>
                                </div>
                                {this.state.ProfilesRoiback.length > 0 &&
                                <div className='ml-2'>
                                    <Button className="btn btn-sm btn-host" onClick={this.Save}>
                                        <i className="fas fa-save"></i>
                                    </Button>
                                </div>}
                            </div>
                        : this.state.Profiles.length > 0 &&
                            <div>
                                <Button disabled={this.state.Profiles.find((pro) => !pro.Status)} className="btn btn-sm btn-host mr-2" onClick={this.checkDuplicatedProfiles}>
                                    <FormattedMessage id="Profile.CheckDuplicatedProfiles" />
                                </Button>

                                <Button disabled={this.state.Profiles.find((pro) => !pro.Status)} className="btn btn-sm btn-host" onClick={this.Save}>
                                    <i className="fas fa-save"></i>
                                </Button>
                            </div>
                        }
                    </div>
                </FormGroup>

                <div >
                    <div>
                        {this.state.ImportType === "CSV" ?
                            <div>
                                <FormGroup>
                                    <Row>
                                        <Col className='col-4'>
                                            <Label className='px-0' sm={4}> <FormattedMessage id="generic.Hotel"/></Label>
                                            <SelectHotel
                                                className='shadow-none'
                                                name={'hotelId'}
                                                onChangeFunc={this.handleChangeSelect}
                                                placeholder={<FormattedMessage id="generic.Hotel" />}
                                                value={this.state.hotelId ? this.state.hotelId : ''}
                                            />
                                        </Col>
                                        <Col className='col-4'>
                                            <Label className='px-0' sm={4}> <FormattedMessage id="Profile.ImportFile"/></Label>
                                            <div className="custom-file">
                                                <input
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="customFile"
                                                    disabled={this.state.hotelId ? false : true}
                                                    onChange={(e) => this.handleFiles(e)}
                                                    accept=".csv"
                                                    required
                                                    onClick={(e) => this.fileChanged(e)}
                                                />
                                                <label className="custom-file-label" for="customFile">
                                                    <FormattedMessage id="generic.ChooseFile" />
                                                </label>
                                            </div>
                                        </Col>
                                        <Col className="text-right mt-4 pt-3 pr-3">
                                            <CommonHelper help={help} id="ImportProfileHelp"/>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                
                                <div>
                                    <Row>
                                        <Col className="col-12">
                                            <CustomTable
                                                title={this.state.Profiles.length > 0 && this.state.Profiles.find((pro) => !pro.Status) &&
                                                `${this.state.Profiles.filter((pro) => !pro.Status).length} ${this.props.intl.formatMessage({ id: `Profile.ImportWithProblems` })}`}
                                                keyField='externalId'
                                                data={this.state.Profiles}
                                                columns={columnsCSV}
                                                totalSize={this.state.Profiles.length}
                                                shadow={false}
                                                exportCSV={true}
                                                showTotal={this.state.Profiles.length > 0}
                                                search={this.state.Profiles.length > 0}
                                                hideSizePerPage={this.state.Profiles && this.state.Profiles.length > 10 ? false : true}
                                                defaultSorted={[{
                                                    dataField: 'Status',
                                                    order: 'asc'
                                                }]}
                                            />
                                        </Col>

                                        <UncontrolledTooltip target={`exportCsvButton`} placement="left" >
                                            <FormattedMessage id="ImportProfile.ExportFileTooltip" />
                                        </UncontrolledTooltip>
                                    </Row>
                                </div>
                            </div>
                        : this.state.ImportType === "EGOI" ?
                                <div>
                                    <FormGroup>
                                        <Row>
                                            <Col className='col-4'>
                                                <Label className='px-0' sm={4}><FormattedMessage id="generic.Hotel"/></Label>
                                                <SelectHotel
                                                    className='shadow-none'
                                                    name={'hotelId'}
                                                    onChangeFunc={this.handleChangeSelect}
                                                    placeholder={<FormattedMessage id="generic.Hotel" />}
                                                    value={this.state.hotelId ? this.state.hotelId : ''}
                                                />
                                            </Col>
                                            <Col className='col-4'>
                                                <Label className='px-0' sm={4}><FormattedMessage id="EgoiWebhookList.List"/></Label>
                                                <CustomSelect
                                                    options={this.state.lists}
                                                    name={'List'}
                                                    value={this.state.List ? this.state.List : ''}
                                                    onChange={this.handleEgoiList}
                                                    placeholder={<FormattedMessage id="EgoiWebhookList.List" />}
                                                    isClearable
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <h5 className='px-2 mt-4'>
                                                    <FormattedMessage id="Profile.SearchParams" />
                                                </h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-3 pt-2'>
                                                <Input
                                                    name={'first_name'}
                                                    onChange={(e) => this.handleFormChange(e.target.value, 'first_name')}
                                                    type={'text'}
                                                    placeholder={this.props.intl.formatMessage({ id: `Profile.Importfirst_name` })}
                                                    value={this.state.EGOISearchParams.find((param) => param.name === 'first_name').val || ''}
                                                />
                                            </Col>
                                            <Col className='col-3 pt-2'>
                                                <Input
                                                    name={'last_name'}
                                                    onChange={(e) => this.handleFormChange(e.target.value, 'last_name')}
                                                    type={'text'}
                                                    placeholder={this.props.intl.formatMessage({ id: `Profile.Importlast_name` })}
                                                    value={this.state.EGOISearchParams.find((param) => param.name === 'last_name').val || ''}
                                                />
                                            </Col>
                                            <Col style={{ maxWidth: '12.5%', flex: '0 0 12.5%' }} className='pt-2'>
                                                <Input
                                                    name={'email'}
                                                    onChange={(e) => this.handleFormChange(e.target.value, 'email')}
                                                    type={'email'}
                                                    placeholder={this.props.intl.formatMessage({ id: `Profile.ImporteMail` })}
                                                    value={this.state.EGOISearchParams.find((param) => param.name === 'email').val || ''}
                                                />
                                            </Col>
                                            <Col style={{maxWidth: '12.5%', flex: '0 0 12.5%'}} className='pt-2'>
                                                <CustomSelect
                                                    options={[{label:'true', value:true},{label:'false', value:false}]}
                                                    onChange={e => this.handleFormChange(e ? e.value : null, 'email_status')}
                                                    placeholder={<FormattedMessage id="Profile.Importemail_status" />}
                                                    isClearable
                                                />
                                            </Col>
                                            <Col style={{ maxWidth: '12.5%', flex: '0 0 12.5%'}} className='pt-2'>
                                                <CustomSelect
                                                    name={'language'}
                                                    options={[{label:"PT", value:"pt"},{label:"EN", value:"en"},{label:"ES", value:"es"},{label:"BR", value:"br"},{label:"FR", value:"fr"},{label:"DE", value:"de"}]}
                                                    onChange={(e) => this.handleFormChange(e ? e.value : null, 'language')}
                                                    type={'text'}
                                                    placeholder={this.props.intl.formatMessage({ id: `Profile.Importlanguage` })}
                                                    isClearable
                                                />
                                            </Col>
                                            <Col style={{ maxWidth: '12.5%', flex: '0 0 12.5%' }} className='pt-2'>
                                                <CustomSelect
                                                    options={this.state.extraFields}
                                                    onChange={(e) => this.handleFormChange(e ? e.value : null, 'extraField')}
                                                    placeholder={this.props.intl.formatMessage({ id: `Profile.ExtraField` })}
                                                    isClearable
                                                />
                                            </Col> 
                                            <Col className='col-3 pt-2'>
                                                <SingleDatePicker
                                                    isOutsideRange={day => day >= moment()}
                                                    date={this.state.EGOISearchParams.find((param) => param.name === 'birth_date').val ?
                                                    moment(this.state.EGOISearchParams.find((param) => param.name === 'birth_date').val) : undefined}
                                                    focused={this.state.focused.birth_date}
                                                    onFocusChange={({ focused }) => this.setState(prevState => ({ focused: { ...prevState.focused, birth_date: focused}}))}
                                                    small={true}
                                                    numberOfMonths={1}
                                                    onDateChange={date => this.handleDateChange(date, 'birth_date')}
                                                    showDefaultInputIcon={true}
                                                    placeholder={this.props.intl.formatMessage({ id: `Profile.Importbirth_date` })}
                                                />
                                            </Col>
                                            <Col style={{maxWidth: '12.5%', flex: '0 0 12.5%'}} className='pt-2'>
                                                <Input
                                                    name={'cellphone'}
                                                    onChange={(e) => this.handleFormChange(e.target.value, 'cellphone')}
                                                    type={'tel'}
                                                    placeholder={this.props.intl.formatMessage({ id: `Profile.Importcellphone` })}
                                                    value={this.state.EGOISearchParams.find((param) => param.name === 'cellphone').val || ''}
                                                />
                                            </Col>
                                            <Col style={{maxWidth: '12.5%', flex: '0 0 12.5%'}} className='pt-2'>
                                                <CustomSelect
                                                    options={[{label:'true', value:true},{label:'false', value:false}]}
                                                    onChange={e => this.handleFormChange(e ? e.value : null, 'cellphone_status')}
                                                    placeholder={<FormattedMessage id="Profile.Importcellphone_status" />}
                                                    isClearable
                                                />
                                            </Col>
                                            <Col style={{maxWidth: '12.5%', flex: '0 0 12.5%'}} className='pt-2'>
                                                <Input
                                                    name={'phone'}
                                                    onChange={(e) => this.handleFormChange(e.target.value, 'phone')}
                                                    type={'tel'}
                                                    placeholder={this.props.intl.formatMessage({ id: `Profile.Importphone` })}
                                                    value={this.state.EGOISearchParams.find((param) => param.name === 'phone').val || ''}
                                                />
                                            </Col>
                                            <Col style={{maxWidth: '12.5%', flex: '0 0 12.5%'}} className='pt-2'>
                                                <CustomSelect
                                                    options={[{label:'true', value:true},{label:'false', value:false}]}
                                                    onChange={e => this.handleFormChange(e ? e.value : null, 'phone_status')}
                                                    placeholder={<FormattedMessage id="Profile.Importphone_status" />}
                                                    isClearable
                                                />
                                            </Col>
                                            <Col className='col-2 pt-2'>
                                                <CustomInput type="checkbox" id="considerRemoved" name="considerRemoved" checked={this.state.considerRemoved} onChange={this.handleCustomInput}>
                                                    <FormattedMessage id="ImportProfile.ConsiderRemoved" />
                                                    <span id="considerRemovedHelp">
                                                        *
                                                    </span>
                                                </CustomInput>
                                                <UncontrolledTooltip target="considerRemovedHelp" placement="bottom">
                                                    <FormattedMessage id="ImportProfile.ConsiderOnImportOnly" />
                                                </UncontrolledTooltip>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <div>
                                        <Row>
                                            <Col className="col-12">
                                                <CustomTable
                                                    keyField='externalId'
                                                    data={this.state.ProfilesEGOI}
                                                    columns={columnsEGOI}
                                                    page={this.state.CurrentPage}
                                                    totalSize={this.state.total_items}
                                                    onTableChange={this.handleTableChange}
                                                    shadow={false}
                                                    showTotal={this.state.ProfilesEGOI.length > 0}
                                                    remote={true}
                                                    sizePerPage={this.state.EgoiSizePerPage}
                                                    hideSizePerPage={this.state.total_items > 10 ? false : true}
                                                    wrapperClasses="table-responsive"
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                        : this.state.ImportType === "Roiback" &&
                            <div>
                                <FormGroup>
                                    <Row>
                                        <Col className='col-4'>
                                            <Label className='px-0' sm={4}><FormattedMessage id="generic.Hotel"/></Label>
                                            <SelectHotel
                                                className='shadow-none'
                                                name={'hotelId'}
                                                onChangeFunc={this.handleChangeSelect}
                                                placeholder={<FormattedMessage id="generic.Hotel" />}
                                                value={this.state.hotelId ? this.state.hotelId : ''}
                                            />
                                        </Col>
                                    </Row>
                                    <Row><Col><h5 className='px-2 mt-4'><FormattedMessage id="Profile.SearchParams"/></h5></Col></Row>
                                    <Row id="RoibackSearchParameters">   
                                        <Col className='col-3 pt-2'>
                                            <Input
                                                name={'Name'}
                                                onChange={(e) => this.handleFormChange(e.target.value, 'Name')}
                                                type={'text'}
                                                placeholder={this.props.intl.formatMessage({ id: `Profile.ImportName` })}
                                                value={this.state.RoibackSearchParams.find((param) => param.name === 'Name').val || ''}
                                            />
                                        </Col>
                                        <Col className='col-3 pt-2'>
                                            <Input
                                                name={'email'}
                                                onChange={(e) => this.handleFormChange(e.target.value, 'Email')}
                                                type={'email'}
                                                placeholder={this.props.intl.formatMessage({ id: `Profile.ImportEmail` })}
                                                value={this.state.RoibackSearchParams.find((param) => param.name === 'Email').val || ''}
                                            />
                                        </Col>
                                        <Col className='col-3 pt-2' id="RoibackActive">
                                            <CustomSelect
                                                onChange={e => this.handleFormChange(e ? e.value : null, 'Active')}
                                                options={[{label:'true', value:true},{label:'false', value:false}]}
                                                placeholder={<FormattedMessage id="Profile.ImportActive" />}
                                                isClearable
                                            />
                                        </Col>
                                        <Col className='col-3 pt-2'>
                                            <SingleDatePicker
                                                isOutsideRange={day => day >= moment()}
                                                date={this.state.RoibackSearchParams.find((param) => param.name === 'SignUpGreater').val ?
                                                moment(this.state.RoibackSearchParams.find((param) => param.name === 'SignUpGreater').val) : undefined}
                                                focused={this.state.focused.SignUpGreater}
                                                onFocusChange={({ focused }) => this.setState(prevState => ({ focused: { ...prevState.focused, SignUpGreater: focused}}))}
                                                small={true}
                                                numberOfMonths={1}
                                                onDateChange={date => this.handleDateChange(date, 'SignUpGreater')}
                                                showDefaultInputIcon={true}
                                                placeholder={this.props.intl.formatMessage({ id: `Profile.ImportSignUpGreater` })}
                                            />
                                        </Col>
                                        <Col className='col-3 pt-2'>
                                            <SingleDatePicker
                                                isOutsideRange={day => day >= moment()}
                                                date={this.state.RoibackSearchParams.find((param) => param.name === 'SignUpLess').val ?
                                                moment(this.state.RoibackSearchParams.find((param) => param.name === 'SignUpLess').val) : undefined}
                                                focused={this.state.focused.SignUpLess}
                                                onFocusChange={({ focused }) => this.setState(prevState => ({ focused: { ...prevState.focused, SignUpLess: focused}}))}
                                                small={true}
                                                numberOfMonths={1}
                                                onDateChange={date => this.handleDateChange(date, 'SignUpLess')}
                                                showDefaultInputIcon={true}
                                                placeholder={this.props.intl.formatMessage({ id: `Profile.ImportSignUpLess` })}
                                            />
                                        </Col>
                                        <Col className='col-3 pt-2'>
                                            <SingleDatePicker
                                                isOutsideRange={day => day >= moment()}
                                                date={this.state.RoibackSearchParams.find((param) => param.name === 'ModifyGreater').val ?
                                                moment(this.state.RoibackSearchParams.find((param) => param.name === 'ModifyGreater').val) : undefined}
                                                focused={this.state.focused.ModifyGreater}
                                                onFocusChange={({ focused }) => this.setState(prevState => ({ focused: { ...prevState.focused, ModifyGreater: focused}}))}
                                                small={true}
                                                numberOfMonths={1}
                                                onDateChange={date => this.handleDateChange(date, 'ModifyGreater')}
                                                showDefaultInputIcon={true}
                                                placeholder={this.props.intl.formatMessage({ id: `Profile.ImportModifyGreater` })}
                                            />
                                        </Col>
                                        <Col className='col-3 pt-2'>
                                            <SingleDatePicker
                                                isOutsideRange={day => day >= moment()}
                                                date={this.state.RoibackSearchParams.find((param) => param.name === 'ModifyLess').val ?
                                                moment(this.state.RoibackSearchParams.find((param) => param.name === 'ModifyLess').val) : undefined}
                                                focused={this.state.focused.ModifyLess}
                                                onFocusChange={({ focused }) => this.setState(prevState => ({ focused: { ...prevState.focused, ModifyLess: focused}}))}
                                                small={true}
                                                numberOfMonths={1}
                                                onDateChange={date => this.handleDateChange(date, 'ModifyLess')}
                                                showDefaultInputIcon={true}
                                                placeholder={this.props.intl.formatMessage({ id: `Profile.ImportModifyLess` })}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <div>
                                    <Row>
                                        <Col className="col-12">
                                            <CustomTable
                                                data={this.state.ProfilesRoiback}
                                                columns={columnsRoiback}
                                                page={this.state.RoibackCurrentPage}
                                                totalSize={this.state.Roibacktotal_items}
                                                onTableChange={this.handleTableChange}
                                                shadow={false}
                                                showTotal={this.state.ProfilesRoiback.length > 0}
                                                remote={true}
                                                sizePerPage={100}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </BlockUi>
        )
    }
}

export default injectIntl(ImportProfile)