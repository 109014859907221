import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, FormGroup, Input, Card, Button } from 'reactstrap';
import { getAlertActionType, getEgoiSenderStatus, getConsentTypeList } from '../../Base/ReferenceDataFunctions';
import { handleNotification } from "../../Base/Notification";
import { BlankCard, StyledModal } from "../../Base/CommonUIComponents";
import { getAPI } from "../../Base/API";
import BlockUi from 'react-block-ui';
import { RenderTemplateSelection, SenderChannelOptions } from '../Common/CommunicationFunctions';

class AlertStep2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            blockTemplates: false,
            templates: null,
            selectedAction: null,
            editMode: false,
            modal: false,
            totalItems: 0,
            offset: 0,
            limit: 12,
            consentType: getConsentTypeList(),
            invalidUrl: false,
        };
    }
    
    
    saveAction = () => {
        this.props.upsertAction(this.state.selectedAction);

        this.setState({
            selectedAction: null,
            modal: false
        })
    }

    handleChange = (field, data) => {
        this.setState(prevState => ({
            selectedAction: {
                ...prevState.selectedAction,
                [field]: field === "consentType" ? prevState.selectedAction[field] === data ? null : data : data
            }
        }), () => {
            if(field === 'url') {
                this.validateUrl();
            }
        })
    }

    selectSender = (_, data) => {
        if (data.sender_id_str) {
            this.setState(prevState => ({ selectedAction: { ...prevState.selectedAction, sender: data.sender_id_str } }));
        }
        else {
            this.setState(prevState => ({ selectedAction: { ...prevState.selectedAction, senderId: data.sender_id } }));
        }
    }

    handleChannel = (field, data) => {
        this.setState(prevState => ({
            selectedAction: {
                ...prevState.selectedAction,
                [field]: data,
                templateId: null
            }
        }))
    }

    handleTemplates = (templateId, countries, data) => {
        const { selectedAction } = this.state;
        selectedAction.marketingSendTemplates = selectedAction.marketingSendTemplates ? selectedAction.marketingSendTemplates : [];
        const idx = selectedAction.marketingSendTemplates.findIndex(el => el === data);

        if (idx !== -1) {
            if (templateId) {
                selectedAction.marketingSendTemplates[idx].templateId = templateId;
            }
            if (countries !== undefined) {
                selectedAction.marketingSendTemplates[idx].countries = countries;
            }
        }

        this.setState({ selectedAction });
    }

    handleDefaultTemplate = (templateId) => {
        const { selectedAction } = this.state;
        selectedAction.marketingSendTemplates = selectedAction.marketingSendTemplates ? selectedAction.marketingSendTemplates : [];

        const index = selectedAction.marketingSendTemplates.findIndex(el => el.isDefault === true);

        if (index !== -1) {
            selectedAction.marketingSendTemplates[index].templateId = templateId;
        }
        else {
            selectedAction.marketingSendTemplates.push({ templateId: templateId, isDefault: true, countries: [] });
        }

        this.setState({ selectedAction });
    }

    addTab = () => {
        const { selectedAction } = this.state;

        selectedAction.marketingSendTemplates = selectedAction.marketingSendTemplates ? selectedAction.marketingSendTemplates : [];
        selectedAction.marketingSendTemplates.push({ isDefault: false });

        this.setState({ selectedAction });
    }

    removeTab = (data) => {
        const { selectedAction } = this.state;

        selectedAction.marketingSendTemplates = selectedAction.marketingSendTemplates.filter(el => el !== data);

        this.setState({ selectedAction });
    }

    toggleModal = (data) => {
        this.setState(prevState => ({
            editMode: data ? true : false,
            selectedAction: data ? data : {},
            modal: !prevState.modal
        }));
    }

    isDisabled = () => {
        const { selectedAction, invalidUrl } = this.state;
        let disabled = true;

        if (selectedAction !== undefined && selectedAction.channel !== undefined && selectedAction.alertActionType !== undefined &&
            selectedAction.marketingSendTemplates && selectedAction.marketingSendTemplates.length > 0 && selectedAction.marketingSendTemplates.some(el => el.isDefault) &&
            selectedAction.senderId && (selectedAction.alertActionType === 'Client' || selectedAction.alertActionType === 'Fixed' && selectedAction.to)) {
            disabled = false;
        }

        if(selectedAction?.url){
            disabled = invalidUrl;
        }

        return disabled;
    }

    validateUrl = () => {
        const { selectedAction } = this.state;
        const regex = /^(https?:\/\/)([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(:\d+)?(\/.*)?$/;
    
        this.setState({ invalidUrl: selectedAction?.url && !regex.test(selectedAction?.url) });
    };

    render() {
        const { alert } = this.props;
        const { selectedAction, consentType, invalidUrl } = this.state;
        const actionType = getAlertActionType();

        const otaEmailsOptions = [
            { value: true, label: <FormattedMessage id="CustomCampaign.ProfilesWithOtaEmails" /> },
            { value: false, label: <FormattedMessage id="CustomCampaign.ProfilesWithoutOtaEmails" /> }
        ];

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <Row className="my-3">
                    <Col>
                        <h5><FormattedMessage id="MarketingAlertDetails.Actions" /></h5>
                    </Col>
                    <Col className="text-right mr-3 pr-3">
                        <Button className="btn btn-sm btn-host mr-1" onClick={() => this.toggleModal(null)}>
                            <i className="fas fa-plus"></i>
                        </Button>
                    </Col>
                </Row>

                {alert && alert.marketingAlertActions && alert.marketingAlertActions.map((action, key) =>
                    <Row className="mb-2" key={key}>
                        <Col className="py-1">
                            <Card className="border-0 overbglight pointer shadow" body>
                                <Row className="align-items-center">
                                    <Col onClick={() => this.toggleModal(action)}><FormattedMessage id="MarketingAlert.Send" /> <b>{action.channel}</b> <FormattedMessage id="MarketingAlert.to" /> {action.alertActionType === 'Client' ? <FormattedMessage id="MarketingAlert.Client" /> : action.to}</Col>
                                    <Col className="col-1 text-right">
                                        <Button className="btn btn-sm btn-host" onClick={() => this.props.removeAction(action.id)}>
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                )}

                {this.state.modal ?
                    <StyledModal toggleModal={this.toggleModal} modal={this.state.modal} size="xl">
                        <BlankCard block={this.state.block} error={this.state.error}>
                            <Row className="mb-2">
                                <Col>
                                    <h4>{this.state.editMode ? <FormattedMessage id="MarketingAlertDetails.EditAction" /> : <FormattedMessage id="MarketingAlertDetails.AddAction" />}</h4>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn btn-sm btn-host" onClick={() => this.saveAction()} disabled={this.isDisabled()} >
                                        <i className="fas fa-save"></i>
                                    </Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Row className="my-2">
                                        <Col>
                                            <h5><FormattedMessage id="MarketingAlert.ActionType" /></h5>
                                        </Col>
                                    </Row>
                                    <FormGroup row>
                                        <Col>
                                            <Row>
                                                {actionType.map((at, key) =>
                                                    <Col className="col-6" key={key}>
                                                        <Card className="border-0 shadow pointer" body
                                                            style={selectedAction.alertActionType === at.value ? { backgroundColor: '#d3eaff' } : {}}
                                                            onClick={() => this.handleChange('alertActionType', at.value)}
                                                        >
                                                            {at.label}
                                                        </Card>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                    </FormGroup>
                                </Col>

                                <Col>
                                    {
                                        selectedAction.alertActionType === 'Fixed' ?
                                            <div>
                                                <Row className="mt-2 mb-3">
                                                    <Col>
                                                        <h5><FormattedMessage id="MarketingAlert.To" /></h5>
                                                    </Col>
                                                </Row>
                                                <FormGroup row>
                                                    <Col>
                                                        <Input
                                                            type="text"
                                                            name="to"
                                                            onChange={(e) => this.handleChange('to', e.target.value)}
                                                            value={selectedAction.to ? selectedAction.to : ''}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </div>
                                            :
                                            selectedAction.alertActionType === 'Client' ?
                                                <div>
                                                    <Row className="my-2">
                                                        <Col>
                                                            <h5><FormattedMessage id="CampaignDetails.OtaEmails" /></h5>
                                                        </Col>
                                                    </Row>
                                                    <FormGroup row>
                                                        <Col>
                                                            <Row>
                                                                {
                                                                    otaEmailsOptions.map((opt, key) =>
                                                                        <Col className="col-6" key={key}>
                                                                            <Card className="border-0 shadow pointer" body
                                                                                style={selectedAction.hasOtaEmail === opt.value ? { backgroundColor: '#d3eaff' } : {}}
                                                                                onClick={() => this.handleChange('hasOtaEmail', selectedAction.hasOtaEmail === opt.value ? null : opt.value)}
                                                                            >
                                                                                {opt.label}
                                                                            </Card>
                                                                        </Col>
                                                                    )
                                                                }
                                                            </Row>
                                                        </Col>
                                                    </FormGroup>
                                                </div>
                                                :
                                                ''
                                    }
                                </Col>
                            </Row>

                            {selectedAction.alertActionType === 'Client' ?
                                <div>
                                    <Row className="mt-2 mb-3">
                                        <Col>
                                            <h5><FormattedMessage id="MarketingAlert.Consent" /></h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {consentType.map((consent, key) =>
                                            <Col className="mb-4" key={key}>
                                                {consent.value ?
                                                    <Card className="border-0 shadow pointer pr-1" body
                                                        style={selectedAction.consentType && selectedAction.consentType === consent.value ? { backgroundColor: '#d3eaff' } : {}}
                                                        onClick={() => this.handleChange('consentType', consent.value)}
                                                    >
                                                        {consent.label}
                                                    </Card>
                                                    : ''}
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                            : ''}

                            {alert?.source === 'PNForm' &&
                                <Row className='mb-4'>
                                    <Col className='col-12'>
                                        <h5><FormattedMessage id="MarketingAlert.Url" /></h5>
                                    </Col>
                                    <Col className='col-3'>
                                        <Input
                                            type="text" 
                                            name="url" 
                                            value={selectedAction?.url ?? ''}
                                            invalid={invalidUrl}
                                            onChange={(e) => this.handleChange('url', e?.target?.value)}
                                            placeholder={"Url"}
                                        />
                                    </Col>
                                </Row>
                            }
                            {this.props.hasEgoi || this.props.hasSendGrid ?                                    
                                <div>
                                    <SenderChannelOptions
                                        hasEgoi={this.props.hasEgoi}
                                        hasSendGrid={this.props.hasSendGrid}
                                        hasTwillio={this.props.hasTwillio}
                                        selectedChannel={selectedAction.channel}
                                        senderId={selectedAction.senderId}
                                        handleSenderChannel={(channel) => this.handleChannel('channel', channel)}
                                        selectSender={(sender) => this.handleChange('senderId', sender?.sender_id)}
                                        displayCountriesToSend={false}
                                    />

                                    {selectedAction.channel &&
                                        <RenderTemplateSelection
                                            hasEgoi={this.props.hasEgoi}
                                            hasSendGrid={this.props.hasSendGrid}
                                            hasTwillio={this.props.hasTwillio}
                                            campaignTemplates={selectedAction.marketingSendTemplates ? selectedAction.marketingSendTemplates : []}
                                            tabs={this.state.tabs}
                                            handleTemplates={this.handleTemplates}
                                            addTab={this.addTab}
                                            removeTab={this.removeTab}
                                            handleDefaultTemplate={this.handleDefaultTemplate}
                                            senderChannel={selectedAction.channel}
                                        />
                                    }
                                </div>
                            : ''}
                        </BlankCard>
                    </StyledModal>
                : ''}

            </BlockUi>
        );
    }
}
export default injectIntl(AlertStep2)