import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Card, Badge, Button, UncontrolledTooltip } from 'reactstrap';
import { handleNotification } from "../../Base/Notification";
import { StyledCard, CommonHelper } from "../../Base/CommonUIComponents";
import { CustomTable } from '../../Base/CustomTable';
import BatchRunModal from './BatchRunModal';
import { getAPI } from "../../Base/API";
import moment from 'moment';
import { CSVLink } from "react-csv";
import { getFormSource, getLicenseModules } from '../../Base/ReferenceDataFunctions';

class BatchLogs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            modal: false,
            batchRuns: [],
            runDetails: [],
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0,
            selectedBatchRunId: null,
            availableConnections: [],
            columns: this.getColumns(null)
        };
    }

    componentDidMount() {
        this.getBathRun();
    }

    getBathRun = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response) {
                    const availableConnections = []

                    if (global.modules && global.modules.some(m => m === 'Egoi') && !data.response.some(el => el.apiConnectionName === 'Egoi' && el.type === 'Import')) {
                        availableConnections.push('Egoi');
                    }
                    if (global.modules && global.modules.some(m => m === 'Roiback') && !data.response.some(el => el.apiConnectionName === 'Roiback' && el.type === 'CardExport') && !data.response.some(el => el.apiConnectionName === 'Roiback' && el.type === 'Import')) {
                        availableConnections.push('Roiback');
                    }

                    this.setState({ batchRuns: data.response, selectedBatchRunId: null, availableConnections, block: false });
                }
                else {
                    const availableConnections = []

                    if (global.modules && global.modules.some(m => m === 'Egoi') )   availableConnections.push('Egoi');
                    if (global.modules && global.modules.some(m => m === 'Roiback') )  availableConnections.push('Roiback');

                    this.setState({ block: false, availableConnections });
                }
            }
        }, `/api/gms/Hotel/v1/batchrun`)
    }

    getBathRunDetails = (id, name) => {
        this.setState({ block: true, selectedBatchRunId: id });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    runDetails: data.response ? data.response : [],
                    totalItems: data.totalItems,
                    block: false,
                    columns: this.getColumns(name)
                });
            }
            else this.setState({ block: false });
        }, `/api/gms/Hotel/v1/batchRunDetails/${id}?pageIndex=${this.state.pageIndex}&pageSize=${this.state.pageSize}`)
    }

    getColumns = (selectedBatchRunName) => {
        function getCsvData(data) {
            const titles = ['Email'];
            const csvData = [titles];
            return csvData.concat(data.reduce((acc, curr) => { acc.push([curr]); return acc; }, []));
        }

        const columns = [
            {
                dataField: 'action',
                text: this.props.intl.formatMessage({ id: "BatchLogs.Action" }),
                sort: true,
                formatter: (cell, row) => {
                    const url = row.reviewMappingId ?
                        row.reviewMappingProvider === getFormSource()[0].value ?
                            `/EgoiMapForm?list=${row.reviewMappingListId}&form=${row.reviewMappingFormId}` :
                            (
                                row.reviewMappingProvider === getFormSource()[1].value ?
                                    `/ReviewProMapForm?form=${row.reviewMappingFormId}`
                                    :
                                    `/MapTypeForm/${row.reviewMappingFormId}`
                            )
                        :
                        null;

                    return <div>
                        <span><FormattedMessage id={`BatchLogs.${cell}`} /></span>

                        {url &&
                            <>
                                <Button className="btn-sm btn-host ml-3" onClick={() => window.open(url, "_blank")} id="mapping">
                                    <i className="fas fa-random" />
                                </Button>

                                <UncontrolledTooltip placement="bottom" target="mapping">
                                    <FormattedMessage id={`BatchLogs.ReviewMapping`} />
                                </UncontrolledTooltip>
                            </>
                        }
                    </div>
                }
            },
            {
                dataField: 'createdAt',
                text: this.props.intl.formatMessage({ id: "BatchLogs.CreatedAt" }),
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm") : ''
            },
            {
                dataField: 'totalProcessed',
                text: this.props.intl.formatMessage({ id: "BatchLogs.TotalProcessed" }),
                sort: true
            },
            {
                dataField: 'totalError',
                text: this.props.intl.formatMessage({ id: "BatchLogs.TotalError" }),
                sort: true
            },
            {
                dataField: 'totalDiscarted',
                text: this.props.intl.formatMessage({ id: "BatchLogs.TotalDiscarted" }),
                //name == ReviewPro add button to extract emails
                formatter: (cell, row) => selectedBatchRunName === "ReviewPro" ?
                    <Row>
                        <Col>
                            {cell}
                        </Col>
                        <Col>
                            <CSVLink className="btn btn-sm btn-host" data={getCsvData(row.discartedEmails)} filename={"DiscartedEmails.csv"}>
                                <i className="fas fa-file-download" />
                            </CSVLink>
                        </Col>
                    </Row>
                    :
                    cell
            },
            {
                dataField: 'totalRecords',
                text: this.props.intl.formatMessage({ id: "BatchLogs.TotalRecords" }),
                sort: true
            },
            {
                dataField: 'result',
                text: this.props.intl.formatMessage({ id: "BatchLogs.Result" }),
                sort: true,
                formatter: (cell, row) => cell === 'Success' ? <Badge color="success" className="py-1"> <FormattedMessage id="BatchLogs.Success" /> </Badge> : <Badge color="danger" className="py-1"><FormattedMessage id="BatchLogs.Error" />  </Badge>
            },
            {
                dataField: 'errorDetail',
                text: this.props.intl.formatMessage({ id: "BatchLogs.ErrorDetail" }),
                sort: true
            }
        ];

        return columns;
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page - 1,
            pageSize: sizePerPage,
        }, () => { this.getBathRunDetails(this.state.selectedBatchRunId, this.state.selectedBatchRunName) });
    }

    doSearch = (batchRunId, batchRunName) => {
        this.setState({
            pageIndex: 0
        }, () => this.getBathRunDetails(batchRunId, batchRunName))
    }

    toggleModal = (selectedRun) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedRun
        }));
    }

    render() {
        const { block, error, selectedBatchRunId, columns, runDetails, totalItems, pageIndex, pageSize, batchRuns, modal, availableConnections } = this.state;

        const columnSize = batchRuns.length > 3 ? 'col-4' : 'col-6';

        return (
            <StyledCard block={block} error={error} >
                <Row className="mb-3">
                    <Col>
                        <h5>
                            <i className="fas fa-plug mr-2"></i>
                            <FormattedMessage id="NavMenu.BatchLogs" />
                        </h5>
                    </Col>
                    <Col className="text-right">
                    {availableConnections && availableConnections.length > 0 ?
                            <Button className="btn-sm btn-host" onClick={_ => this.toggleModal(null)}>
                                <i className="fas fa-plus" />
                            </Button>
                            : ''}
                        <CommonHelper help={<FormattedMessage id="IntBatchLogs.Help" />} id={'IntBatchLogs'} lang={this.props.intl.locale} />
                    </Col>
                </Row>

                <Row>
                    {this.state.batchRuns && this.state.batchRuns.map((batchRun, key) =>
                        <Col key={key} className={columnSize + ' h-100'}>
                            <Card body className={"border-0 shadow pointer mb-3 " + (selectedBatchRunId === batchRun.id ? 'bg-host-light' : '')} onClick={() => this.doSearch(batchRun.id, batchRun.apiConnectionName)}>
                                <Row className="mb-2">
                                    <Col>
                                        <h5><b> <FormattedMessage id={`BatchLogs.${batchRun.apiConnectionName}`} /> </b></h5>
                                    </Col>
                                    <Col className="col-3 text-right pl-0">
                                        {batchRun.lastStatus === 'Success' ?
                                            <Badge color="success" className="py-1"> <FormattedMessage id="BatchLogs.Success" /> </Badge>
                                        : batchRun.lastStatus === 'Error' ?
                                            <Badge color="danger" className="py-1"> <FormattedMessage id="BatchLogs.Error" /> </Badge>
                                        : ''}

                                        {batchRun.apiConnectionName === 'Egoi' || batchRun.apiConnectionName === 'Roiback' ?
                                            <Button className="btn-sm btn-host ml-2" onClick={() => this.toggleModal(batchRun)}>
                                                <i className="fas fa-edit" />
                                            </Button>
                                        : ''}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-3">
                                        <b> <FormattedMessage id="BatchLogs.Type" /></b>
                                    </Col>
                                    <Col>
                                        {batchRun.type}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-3">
                                        <b> <FormattedMessage id="BatchLogs.HotelGroup" /></b>
                                    </Col>
                                    <Col>
                                        {batchRun.hotelGroupName}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-3">
                                        <b> <FormattedMessage id="BatchLogs.LastRun" /></b>
                                    </Col>
                                    <Col>
                                        {moment(batchRun.lastRun).format("YYYY-MM-DD HH:mm")}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    )}
                </Row>

                <Row className="mt-5">
                    <Col>
                        <CustomTable
                            data={runDetails}
                            columns={columns}
                            shadow={false}
                            exportCSV={false}
                            showTotal={true}
                            remote={true}
                            page={pageIndex + 1}
                            sizePerPage={pageSize}
                            totalSize={totalItems}
                            onTableChange={this.handleTableChange}
                        />
                    </Col>
                </Row>

                {modal ?
                    <BatchRunModal
                        modal={modal}
                        toggleModal={this.toggleModal}
                        selectedRun={this.state.selectedRun}
                        updateBatchRuns={this.updateBatchRuns}
                        batchRuns={batchRuns}
                        availableConnections={availableConnections}
                        getBathRun={this.getBathRun}
                    />
                : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(BatchLogs)
