import React, { useEffect, useState } from 'react';
import { Row, Col, Badge, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import CustomSelect from '../Base/CustomSelect';
import { CheckAuthorization } from '../Base/Authorization';
import moment from 'moment-timezone';
import { CoolBadge } from '../Base/CommonUIComponents';


export function getGoContactMessageStatus(intl) {
    return [
        { value: 'new', label: intl.formatMessage({ id: "ReferenceDataFunctions.New" }), icon: 'fas fa-thumbtack', badgeColor: 'text-host bg-host-light' }, 
        { value: 'open', label: intl.formatMessage({ id: "ReferenceDataFunctions.Open" }), icon: 'far fa-envelope-open', badgeColor: 'bg-green-light color-green' },
        { value: 'pending', label: intl.formatMessage({ id: "ReferenceDataFunctions.Pending" }), icon: 'far fa-clock', badgeColor: 'bg-yellow-light color-yellow' },
        { value: 'closed', label: intl.formatMessage({ id: "ReferenceDataFunctions.Closed" }), icon: 'fas fa-lock', badgeColor: 'bg-light-grey text-secondary' },
        { value: 'fw_closed', label: intl.formatMessage({ id: "ReferenceDataFunctions.Closed" }), icon: 'fas fa-lock', badgeColor: 'bg-light-grey text-secondary' },
        { value: 'deleted', label: intl.formatMessage({ id: "ReferenceDataFunctions.Deleted" }), icon: 'fas fa-trash-alt', badgeColor: 'bg-light-red color-red' },
    ]
}
