import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormatAmountNumber } from '../Base/CommonUIComponents';
import { Card, Col, Row } from 'reactstrap';
import Chart from 'react-google-charts';
import moment from 'moment';
import { getColorPallete, getNextColor } from '../Base/ReferenceDataFunctions';

class SalesDealProposalStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalRoomsValue: 0,
            totalEventsValue: 0
        };
    }

    componentDidMount() {
        this.calculateStats(this.props.proposals);
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.filtersLength !== prevProps.filtersLength) {
            this.calculateStats(this.props.proposals);
        }
    }

    calculateStats = (proposals) => {
        let totalRoomsValue = 0, totalRoomNights = 0, totalEventsValue = 0, totalSpaces = 0, totalEventGuests = 0;
        let avgRoomDailyPrice = 0, avgEventDailyPricePerPerson = 0, avgPricePerSpace = 0;
        let totalRows = 0, sumDailyRoomPrice = 0;

        let groupedRoomCategoryData = [], groupEventData = [], groupedSpaceData = [], groupedEventValuesPerDay = [];
        
        let dailyPricePerRoom = [["Day"]];
        const pricePerRoom = [["Category", "Price", { role: 'style' }]];
        const dailyEventPricePerPerson = [["Day", "Price"]];
        const pricePerSpace = [["Space", "Price"]];
        
        proposals.forEach(proposal => {
            if (proposal.isVisible) {
                if (proposal.proposalJSON.BlockData?.details) {
                    //Rooms
                    proposal.proposalJSON.BlockData.details.forEach(room => {
                        if (room.isVisible) {
                            const roomTotalDayPrice = (room.CurrentRooms * room.PriceAfterTaxPerRoom_Total) || 0;

                            dailyPricePerRoom = this.organizeDailyPricePerRoom(dailyPricePerRoom, room.Date, room.CategoryCode, room.PriceAfterTaxPerRoom_Total);

                            //Data for avgRoomDailyPrice
                            totalRows++;
                            sumDailyRoomPrice += room.PriceAfterTaxPerRoom_Total;

                            //Totals
                            totalRoomsValue += roomTotalDayPrice;
                            totalRoomNights += room.CurrentRooms;

                            //Group Data
                            groupedRoomCategoryData = this.updateArrayData(groupedRoomCategoryData, room.CategoryCode, room.CurrentRooms, roomTotalDayPrice);
                        }
                    })
                }

                if (proposal.proposalJSON?.data?.length > 0) {
                    //Events
                    proposal.proposalJSON.data[0].DetailsEvents && proposal.proposalJSON.data[0].DetailsEvents.forEach(event => {
                        if (event.isVisible) {
                            const eventGuests = event.Adults + event.Children1 + event.Children2 + event.Children3;
                            const eventStart = moment.min(event.Spaces.map(({ FromDate }) => moment(FromDate)));
                            const eventEnd = moment.max(event.Spaces.map(({ ToDate }) => moment(ToDate)));

                            //Total
                            totalEventsValue += event.PriceTotal || 0;
                            totalSpaces += event.Spaces.length;
                            totalEventGuests += eventGuests;


                            //Group Data
                            groupEventData = this.updateArrayData(groupEventData, event.EventName, event.Spaces?.length || 0, event.PriceTotal);
                            groupedEventValuesPerDay = this.organizeDatesValues(groupedEventValuesPerDay, eventStart, eventEnd, event.Spaces.length, event.PriceTotal, eventGuests);

                            event.Spaces && event.Spaces.forEach(space => {
                                if (space.isVisible) {
                                    groupedSpaceData = this.updateArrayData(groupedSpaceData, space.SpaceName, 1, space.PriceDetails?.reduce((acc, cur) => acc + cur.AmountAfterTax, 0));
                                }
                            })
                        }
                    }) 
                }
            }
        })


        //Room Charts 
        if (groupedRoomCategoryData?.length > 0) {
            //Price per Room
            groupedRoomCategoryData.forEach((room, idx) => {
                room.avg = room.totalQuantity > 0 ? (room.totalPrice / room.totalQuantity) : 0;

                pricePerRoom.push([room.name, room.totalPrice, getNextColor(idx)]);
            });

            avgRoomDailyPrice = sumDailyRoomPrice / totalRows;
        }

        //sort chart dates
        dailyPricePerRoom.sort((a, b) => {
            const dateA = moment(a[0], 'YYYY-MM-DD');
            const dateB = moment(b[0], 'YYYY-MM-DD');
            return dateA.diff(dateB);
        });




        //Event Charts
        if (groupEventData.length > 0) {
            //Price per Space
            groupedSpaceData.forEach(space => {
                //const avgPrice = space.totalPrice / space.totalQuantity;
                pricePerSpace.push([space.name, space.totalPrice]);
            })    


            //Price per person
            groupedEventValuesPerDay.forEach(day => {
                const avg = day.totalPrice / day.totalGuests;

                dailyEventPricePerPerson.push([day.date, avg]);
            })


            avgEventDailyPricePerPerson = totalEventsValue / totalEventGuests;
            avgPricePerSpace = totalEventsValue / totalSpaces;
        }

        
        this.setState({
            totalRoomsValue, totalEventsValue,
            dailyPricePerRoom, pricePerRoom, pricePerSpace, dailyEventPricePerPerson,
            avgRoomDailyPrice, avgEventDailyPricePerPerson, avgPricePerSpace
        });
    }

    organizeDailyPricePerRoom = (dailyPricePerRoom, date, categoryCode, dailyPrice) => {
        const dayIndex = dailyPricePerRoom.findIndex(data => data[0] === moment(date).format("YYYY-MM-DD"));
        const categoryIndex = dailyPricePerRoom[0].findIndex(label => label === categoryCode);
        

        if (categoryIndex === -1) {
            dailyPricePerRoom.forEach((row, idx) => {
                if (idx === 0) row.push(categoryCode);
                else row.push(0); //adiciona um valor � categoria para todos os dias
            })
        }


        if (dayIndex === -1) {
            //create line with day values
            const categoryValues = new Array(dailyPricePerRoom[0].length).fill(0);
            categoryValues[0] = moment(date).format("YYYY-MM-DD");


            //fill category values
            if (categoryIndex === -1) {
                categoryValues[categoryValues.length - 1] = dailyPrice;
            }
            else {
                categoryValues[categoryIndex] = dailyPrice;
            }


            dailyPricePerRoom.push(categoryValues);
        }
        else {
            //fill category values
            if (categoryIndex === -1) {
                dailyPricePerRoom[dayIndex][dailyPricePerRoom[0].length - 1] = dailyPrice;
            }
            else {
                dailyPricePerRoom[dayIndex][categoryIndex] += dailyPrice;
            }
        }

        return dailyPricePerRoom;
    }


    //Agrupa os dados pelo name (categoria ou evento)
    updateArrayData = (array, name, totalQuantity, totalPrice) => {
        const index = array.findIndex(el => el.name === name);

        if (index !== -1) {
            array[index].totalQuantity += totalQuantity || 0;
            array[index].totalPrice += totalPrice || 0;
        }
        else {
            array.push({
                name, totalQuantity, totalPrice
            });
        }

        return array;
    }

    organizeDatesValues = (array, startDate, endDate, totalQuantity, totalPrice, totalGuests) => {
        var newDates = new Array(moment(endDate).diff(moment(startDate), 'days')).fill(undefined)
            .reduce((acc, cur) => {
                acc.push(moment(startDate).add(acc.length, 'days').format("YYYY-MM-DD"));

                return acc;
            }, [moment(startDate).format("YYYY-MM-DD")]);


        newDates.forEach(date => {
            const dateIndex = array.findIndex(el => el.date === date);

            if (dateIndex !== -1) {
                array[dateIndex].totalQuantity += totalQuantity;
                array[dateIndex].totalPrice += totalPrice;
                array[dateIndex].totalGuests += totalGuests;
            }
            else {
                array.push({
                    date, totalQuantity, totalPrice, totalGuests
                });
            }
        })

        return array;
    }

    render() {
        const { totalRoomsValue, totalEventsValue, dailyPricePerRoom, pricePerRoom, pricePerSpace, dailyEventPricePerPerson, avgRoomDailyPrice, avgEventDailyPricePerPerson, avgPricePerSpace } = this.state;
        const { hotelCurrency } = this.props;
        return (
            <div>
                <div>
                    <div className='d-flex align-items-center justify-content-between text-muted'>
                        <div style={{ fontSize: '1.1em' }}>
                            <i className="fas fa-bed mr-2"/>
                            <FormattedMessage id="SalesDeal.Rooms"/>
                        </div>
                        <div style={{ fontSize: '1.4em' }}>
                            <FormatAmountNumber value={totalRoomsValue} currency={hotelCurrency} />
                        </div>
                    </div>
                    <Row className='mt-3'>
                        <Col className='col-12 col-lg-6'>
                            <Card className='shadow bg-white border-0 p-3 justify-content-between' style={{ borderRadius: '5px', minHeight: '363px' }}>
                                <div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='text-muted'>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesDeal.DailyPricePerRoom"/>
                                        </div>
                                        <div style={{ fontSize: '1.4em' }}>
                                            <FormatAmountNumber value={avgRoomDailyPrice} currency={hotelCurrency} />
                                        </div>
                                    </div>
                                    {dailyPricePerRoom?.length > 1 && dailyPricePerRoom[0].length > 1 ?
                                        <div className='mt-3'>
                                            <Chart
                                                chartType="Bar"
                                                width="100%"
                                                height="300px"
                                                data={dailyPricePerRoom}
                                                options={{
                                                    legend: {position: 'none'},
                                                    colors: getColorPallete()
                                                }}
                                            />
                                        </div>
                                    :''}
                                </div>
                                {!(dailyPricePerRoom?.length > 1 && dailyPricePerRoom[0].length > 1) ?
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <h5 className='text-center text-muted' style={{ fontSize: '1em' }}>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesDeal.NoData" />
                                        </h5>
                                    </div>
                                :''}
                                <div style={{ display: !(dailyPricePerRoom?.length > 1 && dailyPricePerRoom[0].length > 1) ? 'hidden' : 'none' }} />
                            </Card>
                        </Col>
                        <Col className='col-12 mt-3 col-lg-6 mt-lg-0'>
                            <Card className='shadow bg-white border-0 p-3 justify-content-between' style={{ borderRadius: '5px', minHeight: '363px' }}>
                                <div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='text-muted'>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesDeal.PriceRoom"/>
                                        </div>
                                    </div>
                                    {pricePerRoom?.length > 1 && pricePerRoom[0].length > 1 ?
                                        <div className='mt-3'>
                                            <Chart
                                                chartType="Bar"
                                                width="100%"
                                                height="300px"
                                                data={pricePerRoom}
                                                options={{
                                                    legend: {position: 'none'},
                                                    colors: getColorPallete()
                                                }}
                                            />
                                        </div>
                                    :''}
                                </div>
                                {!(pricePerRoom?.length > 1 && pricePerRoom[0].length > 1) ?
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <h5 className='text-center text-muted' style={{ fontSize: '1em' }}>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesDeal.NoData" />
                                        </h5>
                                    </div>
                                :''}
                                <div style={{ display: !(pricePerRoom?.length > 1 && pricePerRoom[0].length > 1) ? 'hidden' : 'none' }} />
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className='mt-4'>
                    <div className='d-flex align-items-center justify-content-between text-muted'>
                        <div style={{ fontSize: '1.1em' }}>
                            <i className="fas fa-theater-masks mr-2"/>
                            <FormattedMessage id="SalesDeal.Events"/>
                        </div>
                        <div style={{ fontSize: '1.4em' }}>
                            <FormatAmountNumber value={totalEventsValue} currency={hotelCurrency} />
                        </div>
                    </div>
                    <Row className='mt-3 pb-3'>
                        <Col className='col-12 col-lg-6'>
                            <Card className='shadow bg-white border-0 p-3 justify-content-between' style={{ borderRadius: '5px', minHeight: '363px' }}>
                                <div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='text-muted'>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesDeal.DailyPricePerson"/>
                                        </div>
                                        <div style={{ fontSize: '1.4em' }}>
                                            <FormatAmountNumber value={avgEventDailyPricePerPerson} currency={hotelCurrency} />
                                        </div>
                                    </div>
                                    {dailyEventPricePerPerson?.length > 1 && dailyEventPricePerPerson[0].length > 1 ?
                                        <div className='mt-3'>
                                            <Chart
                                                chartType="Bar"
                                                width="100%"
                                                height="300px"
                                                data={dailyEventPricePerPerson}
                                                options={{
                                                    legend: {position: 'none'},
                                                }}
                                            />
                                        </div>
                                    :''}
                                </div>
                                {!(dailyEventPricePerPerson?.length > 1 && dailyEventPricePerPerson[0].length > 1) ?
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <h5 className='text-center text-muted' style={{ fontSize: '1em' }}>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesDeal.NoData" />
                                        </h5>
                                    </div>
                                :''}
                                <div style={{ display: !(dailyEventPricePerPerson?.length > 1 && dailyEventPricePerPerson[0].length > 1) ? 'hidden' : 'none' }} />
                            </Card>
                        </Col>
                        <Col className='col-12 mt-3 col-lg-6 mt-lg-0'>
                            <Card className='shadow bg-white border-0 p-3 justify-content-between' style={{ borderRadius: '5px', minHeight: '363px' }}>
                                <div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='text-muted'>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesDeal.PriceSpace"/>
                                        </div>
                                        <div style={{ fontSize: '1.4em' }}>
                                            <FormatAmountNumber value={avgPricePerSpace} currency={hotelCurrency} />
                                        </div>
                                    </div>
                                    {pricePerSpace?.length > 1 && pricePerSpace[0].length > 1 ?
                                        <div className='mt-3'>
                                            <Chart
                                                chartType="Bar"
                                                width="100%"
                                                height="300px"
                                                data={pricePerSpace}
                                                options={{
                                                    legend: {position: 'none'},
                                                }}
                                            />      
                                        </div>
                                    :''}
                                </div>
                                {!(pricePerSpace?.length > 1 && pricePerSpace[0].length > 1) ?
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <h5 className='text-center text-muted' style={{ fontSize: '1em' }}>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesDeal.NoData" />
                                        </h5>
                                    </div>
                                :''}
                                <div style={{ display: !(pricePerSpace?.length > 1 && pricePerSpace[0].length > 1) ? 'hidden' : 'none' }} />                                  
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default SalesDealProposalStats;
