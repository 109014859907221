import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Col, Row, Card } from "reactstrap";
import { handleNotification } from "../../Base/Notification";
import { StyledCard, CommonHelper } from "../../Base/CommonUIComponents";
import { getAPI, putAPI } from "../../Base/API";
import RuleCard from "./RuleCard";

class LoyaltyCardRules extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: true,
            blockLevels: true,
            blockPrefixes: true,
            loyaltyCardRules: [],
            rulesApplied: [],
            rulesNotApplied: [],
            loyalties: [],
            prefixes: [],
            showRemoveRule: false,
            showAddRule: false,
            draggingCard: false,
            ruleTypes: [
                { index: -1, type: "CardTypeCode", active: false, bgColor: 'bg-green', options: 'loyalties' },
                { index: -1, type: "MembershipSourceCode", active: false, bgColor: 'bg-yellow', options: 'prefixes' }
            ]
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;

        this.getLoyaltyCardRule();
        this.getLoyaltyCardLevelConfigs();
        this.getLoyaltyCardPrefixes();
    }

    getLoyaltyCardRule = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                let rulesApplied = [], rulesNotApplied = [];

                this.state.ruleTypes.forEach(rule => {
                    const response = data.response.find(el => el.type === rule.type);

                    if (response) { Object.assign(rule, response); }

                    if (rule.active) {
                        rulesApplied.unshift(rule)
                    }
                    else {
                        rulesNotApplied.unshift(rule)
                    }
                });

                rulesApplied.sort((a, b) => a.index - b.index);

                rulesApplied.push({ index: (rulesApplied.length > 0 ? rulesApplied[rulesApplied.length - 1].index + 1 : 0), type: "CreationDate", bgColor: 'bg-cyan', isFixed: true });

                this.setDisplayIndex(rulesApplied, rulesNotApplied);

                this.setState({ block: false, rulesApplied, rulesNotApplied });
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardRule`);
    }

    getLoyaltyCardLevelConfigs = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, blockLevels: false });

                return;
            }
            if (data) {
                const loyalties = data.response
                    .sort((a, b) => a.minRoomNightsToLevel + a.minPointsToLevel - b.minRoomNightsToLevel - b.minPointsToLevel)
                    .map(conf => {
                        const opt = {
                            value: conf.code,
                            label: `${conf.code} (${conf.description})`
                        };
                        return opt;
                    });

                this.setState({ blockLevels: false, loyalties: loyalties });
            }
            else {
                this.setState({ blockLevels: false });
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardLevelConfig`);
    }

    getLoyaltyCardPrefixes = () => {
        this.setState({ blockPrefixes: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                const prefixes = [];

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.response && data.response.length > 0) {
                    data.response.forEach(prefix => {
                        prefixes.push({
                            value: prefix.code,
                            label: `${prefix.code} (${prefix.complexCode})`
                        });
                    });
                }

                this.setState({ prefixes, error: errorMessage, blockPrefixes: false });
            }
            else this.setState({ error: errorMessage, blockPrefixes: false });
        }, '/api/gms/LoyaltyCard/v1/HotelGroupLoyaltyCardConfigPrefix');
    }

    setDisplayIndex = (rulesApplied, rulesNotApplied) => {
        rulesApplied.forEach(rule => rule.diplayIndex = rule.index + 1);
        rulesNotApplied.forEach(rule => rule.diplayIndex = rule.index + 1);
    }

    saveLoyaltyCardRule = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const body = [...this.state.rulesApplied, ...this.state.rulesNotApplied].filter(r => r.type !== 'CreationDate');

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="LoyaltyCardRules.RulesSaved" />, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `api/gms/LoyaltyCard/v1/LoyaltyCardRule`, JSON.stringify({ request: body }));
    }

    addRule = (e) => {
        e.preventDefault();
        const rule = JSON.parse(e.dataTransfer.getData('rule'));

        if (rule) {
            let { rulesApplied, rulesNotApplied } = this.state;
            let creationDate = rulesApplied.find(el => el.type === 'CreationDate');

            rule.index = rulesApplied.length - 1;
            rule.active = true;

            rulesApplied.splice((rulesApplied.length - 1), 0, rule);
            rulesNotApplied = rulesNotApplied.filter(el => el.type !== rule.type);

            creationDate.index = rulesApplied.length > 1 ? rulesApplied[rulesApplied.length - 2].index + 1 : 0;

            this.setDisplayIndex(rulesApplied, rulesNotApplied);

            this.setState({ rulesApplied, rulesNotApplied, showRemoveRule: false, showAddRule: false });
        }
    }

    removeRule = (e) => {
        e.preventDefault();
        const rule = JSON.parse(e.dataTransfer.getData('rule'));

        if (rule && rule.isFixed !== true) {
            let { rulesApplied, rulesNotApplied } = this.state;
            let creationDate = rulesApplied.find(el => el.type === 'CreationDate');

            rule.index = -1;
            rule.active = false;

            rulesNotApplied.unshift(rule);
            rulesApplied = rulesApplied.filter(el => el.type !== rule.type);

            if (rulesApplied.length > 1) {
                rulesApplied[0].index = rulesApplied.length - 2;
            }

            creationDate.index = rulesApplied.length > 1 ? rulesApplied[rulesApplied.length - 2].index + 1 : 0;

            this.setDisplayIndex(rulesApplied, rulesNotApplied);

            this.setState({ rulesApplied, rulesNotApplied, showRemoveRule: false, showAddRule: false });
        }
    }

    onDragStart = (e, rule, dragDropName, ruleIndex) => {
        e.dataTransfer.setData("rule", JSON.stringify(rule));

        this.setState(prevState => ({ ...prevState, [dragDropName]: true, draggingCard: true, ruleIndex }))
    }

    onDragEnd = () => {
        this.setState({ showRemoveRule: false, showAddRule: false, draggingCard: false })
    }

    onDragOver = (e) => {
        e.preventDefault();
    }

    removeCode = (arrayName, ruleIndex, codeIndex) => {
        const array = [...this.state[arrayName]];

        array[ruleIndex].code.splice(codeIndex, 1);

        this.setState({ [arrayName]: array });
    }

    updateRule = (arrayName, ruleIndex, rule) => {
        const array = [...this.state[arrayName]];

        array[ruleIndex] = rule;

        this.setState({ [arrayName]: array });
    }

    switchRuleIndex = (e, index1) => {
        if (this.state.rulesApplied[index1].type !== 'CreationDate') {
            const rulesApplied = [...this.state.rulesApplied];
            const index2 = this.state.ruleIndex;
            const ruleIndexData = rulesApplied[index1].index;

            rulesApplied[index1].index = rulesApplied[index2].index;
            rulesApplied[index2].index = ruleIndexData;

            rulesApplied.sort((a, b) => a.index - b.index);

            this.setDisplayIndex(rulesApplied, []);

            this.setState({ rulesApplied, showRemoveRule: false });
        }
        else {
            this.setState({ showRemoveRule: false });
        }
    }

    render() {
        const { block, blockLevels, blockPrefixes, rulesApplied, rulesNotApplied, showRemoveRule, showAddRule, draggingCard, loyalties, prefixes } = this.state;

        return (
            <StyledCard block={block || blockLevels || blockPrefixes} error={this.state.error} >
                <Row className="mb-2">
                    <Col>
                        <h5>
                            <i className="fas fa-id-card-alt mr-2" />
                            <FormattedMessage id="LoyaltyCardRules.Title" />
                        </h5>
                    </Col>
                    <Col className="text-right">
                        <Button className="btn-sm btn-host" onClick={this.saveLoyaltyCardRule}>
                            <i className="fas fa-save" />
                        </Button>
                        <CommonHelper help={<FormattedMessage id="LoyaltyRules.Help" />} id={'LoyaltyRules'} lang={this.props.intl.locale} />
                    </Col>
                </Row>


                <Row style={{ height: '75vh' }}>
                    <Col className="border-right">
                        <Card className="border-0 p-2 h-100" style={{ backgroundColor: 'transparent' }}>
                            <div className="p-2 h-100" style={showAddRule ? { border: '2px dashed grey' } : {}}
                                onDragOver={(e) => { if (showAddRule) this.onDragOver(e) }}
                                onDrop={(e) => { if (showAddRule) this.addRule(e) }}>

                                <h5 className="text-center">
                                    <FormattedMessage id="LoyaltyCardRules.RulesApplied" />
                                </h5>

                                {rulesApplied && rulesApplied.map((rule, key) =>
                                    <div key={key} className="mt-3">
                                        <RuleCard
                                            rule={rule} ruleIndex={key}
                                            dragDropName={'showRemoveRule'} arrayName="rulesApplied"
                                            onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}
                                            removeCode={this.removeCode}
                                            updateRule={this.updateRule}
                                            draggingCard={draggingCard}
                                            switchRuleIndex={this.switchRuleIndex}
                                            loyalties={loyalties}
                                            prefixes={prefixes}
                                        />
                                    </div>
                                )}
                            </div>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="border-0 p-2 h-100" style={{ backgroundColor: 'transparent' }}>
                            <div className="p-2 h-100" style={showRemoveRule ? { border: '2px dashed grey' } : {}}
                                onDragOver={(e) => { if (showRemoveRule) this.onDragOver(e) }}
                                onDrop={(e) => { if (showRemoveRule) this.removeRule(e) }}
                            >
                                <h5 className="text-center">
                                    <FormattedMessage id="LoyaltyCardRules.RulesNotApplied" />
                                </h5>

                                {rulesNotApplied && rulesNotApplied.map((rule, key) =>
                                    <div key={key} className="mt-3">
                                        <RuleCard
                                            rule={rule} ruleIndex={key}
                                            dragDropName={'showAddRule'} arrayName="rulesNotApplied"
                                            onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}
                                            removeCode={this.removeCode}
                                            updateRule={this.updateRule}
                                            draggingCard={draggingCard}
                                            switchRuleIndex={this.switchRuleIndex}
                                            loyalties={loyalties}
                                            prefixes={prefixes}
                                        />
                                    </div>
                                )}
                            </div>
                        </Card>
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}
export default injectIntl(LoyaltyCardRules)