import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Card, Col, Row, Form, Input } from 'reactstrap';
import { StyledCard, ActiveInactiveStatusCombo, ActiveInactiveStatusBadge, CommonHelper } from "../../Base/CommonUIComponents";
import { getAlertType, getAlertFieldCompare } from "../../Base/ReferenceDataFunctions";
import { getAPI } from "../../Base/API";
import { FormattedMessage } from "react-intl";
import CustomSelect from "../../Base/CustomSelect";

import Authorization from '../../Base/Authorization';
import PreviewTemplatesByCountry from '../PreviewTemplatesByCountry';

class MarketingAlerts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            modal: false,
            alerts: [],
            selectedCampaign: null,
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0,
            status: null,
            alertType: null,
            name: null
        };
    }

    componentDidMount() {
        this.getAlerts();
    }

    getAlerts = () => {
        this.setState({ block: true });

        var params = '';
        params += this.state.alertType ? `&alertType=${this.state.alertType}` : '';
        params += this.state.status !== null ? `&status=${this.state.status}` : '';
        params += this.state.name ? `&name=${this.state.name}` : '';

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({
                    alerts: data.response,
                    totalItems: data.totalItems
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/marketingalert/list?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}` + params);
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
    };

    selectCampaign = (id) => {
        this.props.history.push({ pathname: "/MarketingAlertDetails/" + id })
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getAlerts());
    }

    doSearch = (e) => {
        e.preventDefault();

        this.setState({
            pageIndex: 0
        }, () => this.getAlerts())
    }


    toggleModal = (actions) => {
        let channel = ''; 
        let templates = null;

        if (actions && actions.length > 0) {
            channel = actions[0].channel;
            templates = actions.flatMap(temp => (temp.marketingSendTemplates));
        }

        this.setState(prevState => ({
            modal: !prevState.modal,
            alertTemplates: templates,
            channel
        }));
    }

    render() {
        const { block, error, pageSize, pageIndex, totalItems, alerts } = this.state;

        return (
            <StyledCard block={block} error={error} icon={'fa-lg icon-icon-alerts'} title={'NavMenu.Alerts'}>
                <Form onSubmit={(e) => this.doSearch(e)}>
                    <Row className="my-4 align-items-center">
                        <Col className="col-3">
                            <CustomSelect
                                options={getAlertType()}
                                isClearable
                                isSearchable
                                onChange={this.handleSelect.bind(this, "alertType")}
                                placeholder={this.props.intl.formatMessage({ id: "MarketingAlert.AlertType" })}
                            />
                        </Col>
                        <Col className="col-3 px-1">
                            <ActiveInactiveStatusCombo
                                name='status'
                                onChangeFunc={this.handleSelect.bind(this)}
                                placeholder={this.props.intl.formatMessage({ id: "generic.status" })}
                                required={false}
                            />
                        </Col>
                        <Col className="col-3">
                            <Input
                                type="text"
                                name="name"
                                onChange={(e) => this.handleChange(e)}
                                placeholder={this.props.intl.formatMessage({ id: "generic.name" })}
                            />
                        </Col>

                        <Col className="text-right pt-1 mr-2">
                            <Authorization
                                perform="marketingAlerts:add"
                                yes={() => (
                                    <Button className="btn btn-sm btn-host mr-2" onClick={() => this.props.history.push({ pathname: "/MarketingAlertDetails" })}>
                                        <i className="fas fa-plus"></i>
                                    </Button>
                                )}
                                no={() => <div></div>}
                            />

                            <Button className="btn btn-sm btn-host" type="submit">
                                <i className="icon-icon-search"></i>
                            </Button>
                            <CommonHelper help={<FormattedMessage id="MarketingNotifications.Help" />} id={'MarketingNotifications'} lang={this.props.intl.locale} />
                        </Col>
                    </Row>
                </Form>

                {alerts && alerts.map((alert, index) => {
                    const alertType = getAlertType().find(at => at.value === alert.alertType);
                    const fieldType = alertType && alertType.fieldType && alertType.fieldType.find(ft => ft.value === alert.fieldType);

                    return <Card className="border-0 shadow mb-2 p-3" key={index}>
                        <Row>
                            <Col><h5><b>{alert.name}</b> </h5></Col>
                            <Col className="col-3 text-right">
                                <Button className="btn btn-host btn-sm mr-2" onClick={() => this.toggleModal(alert.marketingAlertActions)}>
                                    <i className="icon-icon-preview"></i>
                                </Button>

                                <Authorization
                                    perform="marketingAlerts:edit"
                                    yes={() => (
                                        <Button className="btn btn-host btn-sm" onClick={() => this.props.history.push({ pathname: "/MarketingAlertDetails/" + alert.id, alert: alert })}>
                                            <i className="far fa-edit"></i>
                                        </Button>
                                    )}
                                    no={() => ''}
                                />

                                {/*<Button className="btn btn-host btn-sm ml-2">
                                    <i className="icon-icon-statistics-campaigns"></i>
                                </Button>*/}

                            </Col>
                        </Row>

                        <Row>
                            <Col className="col-2">
                                <div><b><FormattedMessage id="MarketingAlert.Type" /></b>: {alert.alertType}</div>
                                <div><b><FormattedMessage id="generic.status" /></b>: <ActiveInactiveStatusBadge status={alert.active} /></div>
                            </Col>
                            <Col className="col-4">
                                <div><b>{alert.alertType === 'Review' ? <FormattedMessage id="MarketingAlert.When" /> : ''}</b></div>
                                <div>
                                    {alert.alertType === 'Review' ?
                                        <span>  {fieldType && fieldType.label} {getAlertFieldCompare().find(c => c.value === alert.fieldCompare).label} {alert.fieldValue} </span>
                                    : '' }
                                </div>
                            </Col>
                            <Col>
                                <Row>
                                    <Col> <b><FormattedMessage id="MarketingAlert.Then" /></b></Col>
                                    {/*<Col><b>Template </b></Col>*/}
                                </Row>

                                {alert.marketingAlertActions && alert.marketingAlertActions.map((action, key) =>
                                    <Row key={key}>
                                        <Col><FormattedMessage id="MarketingAlert.Send" /> {action.channel} <FormattedMessage id="MarketingAlert.to" /> {action.alertActionType === 'Client' ? <FormattedMessage id="MarketingAlert.Client" /> : action.to} </Col>
                                        {/*<Col>{action.templateId}</Col>*/}
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    </Card>
                })}

                {totalItems > 0 && totalItems >= pageSize ?
                    <Row className="my-3 px-2">
                        <Col>
                            <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(-1)} disabled={pageIndex === 0}>
                                <i className="fas fa-angle-left" />
                            </Button>
                        </Col>
                        <Col className="text-right">
                            <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(1)} disabled={totalItems <= ((pageIndex + 1) * pageSize)}>
                                <i className="fas fa-angle-right" />
                            </Button>
                        </Col>
                    </Row>
                : ''}

                {this.state.modal ?
                    <PreviewTemplatesByCountry
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        templates={this.state.alertTemplates}
                        channel={this.state.channel}
                    />
                : ''}
              
            </StyledCard>
        );
    }
}
export default injectIntl(MarketingAlerts)
