import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Row, Col, Input } from 'reactstrap';
import { BlankCard, DefaultPage, StyledModal } from '../../Base/CommonUIComponents';
import { getCommissionTypes } from '../SalesProcessFunctions';
import CustomSelect from '../../Base/CustomSelect';
import { postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';


class CommissionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            dealCommissions: this.props.dealCommissions ? [...this.props.dealCommissions] : [],
            commissionTypes: getCommissionTypes(this.props.intl)
        };
    }

    saveCommissions = (e) => {
        if (e) e.preventDefault();

        if (this.commissionsValidations(this.state.dealCommissions) === false) {
            return
        }

        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    else {
                        this.props.updateCommissions(data.data);
                        handleNotification(data, <FormattedMessage id="CommissionModal.SavedSuccessfully" />, <FormattedMessage id="generic.success" />);
                        this.props.toggleModal();
                        return;
                    }

                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/deal/${this.props.salesDealId}/comissions`, this.state.dealCommissions);
        });
    }

    commissionsValidations = (dealCommissions) => {
        const result = [];

        for (let i = 0; i < dealCommissions.length; i++) {
            const item = dealCommissions[i];
            const profileCommissions = result.filter(row => row.profileId === item.profileId);

            if (profileCommissions) {
                if (profileCommissions.some(el => el.comissionType === item.comissionType)) {
                    handleNotification({ warnings: [{ message: <FormattedMessage id="CommissionModal.DuplicatedTypes" /> }] });
                    return false;
                }
                else if ((profileCommissions.length > 1 && item.comissionType === 'Total') || profileCommissions.some(el => el.comissionType === 'Total')) {
                    handleNotification({ warnings: [{ message: <FormattedMessage id="CommissionModal.TotalTypeValidationText" /> }] });
                    return false;
                }
                else {
                    result.push(item);
                }
            }
            else {
                result.push(item);
            }
        }

        return true;
    }

    handleCommissions = (index, propName, value, isToRemove) => {
        if (index !== -1) {
            const { dealCommissions } = this.state;

            if (isToRemove) {
                dealCommissions.splice(index, 1);
            }
            else {
                dealCommissions[index][propName] = value;
            }

            this.setState({ dealCommissions });
        }
    }

    addCommissionRow = (e) => {
        if (e) e.preventDefault();
        const { dealCommissions } = this.state;

        dealCommissions.push({ salesDealId: this.props.salesDealId });

        this.setState({ dealCommissions });
    }


    render() {
        const { block, error, dealCommissions, commissionTypes } = this.state;
        const { toggleModal, modal, customers } = this.props;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="xl">
                <BlankCard block={block} error={error}>
                    <Form onSubmit={this.saveCommissions}>
                        <Row className="align-items-center mb-3">
                            <Col>
                                <h5><i className="fas fa-hand-holding-usd mr-2" /><FormattedMessage id="CommissionModal.EditCommissions" /> </h5>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host mr-2" type="button" onClick={this.addCommissionRow}>
                                    <i className="fas fa-plus" />
                                </Button>
                                <Button className="btn-sm btn-host" type="submit" disabled={this.props.dealCommissions?.length === 0 && this.state.dealCommissions.length === 0}>
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col className='text-muted' sm={4}>
                                <FormattedMessage id="generic.name" />
                            </Col>
                            <Col className='text-muted'>
                                <FormattedMessage id="SalesProcess.Type" />
                            </Col>
                            <Col className='text-muted'>
                                <FormattedMessage id="SalesProcess.Percent" />
                            </Col>
                            <Col className='text-muted'>
                                <FormattedMessage id="SalesProcess.Value" />
                            </Col>
                            <Col sm={1} />
                        </Row>

                        {dealCommissions?.length > 0 ?
                            dealCommissions.map((commission, index) =>
                                <Row key={index} className="mt-2">
                                    <Col sm={4}>
                                        <CustomSelect
                                            value={customers?.find(el => el.value === commission.profileId) || ''}
                                            options={customers?.filter((profile, index, array) => array.findIndex(el => el.value === profile.value) === index) || []}
                                            required={true}
                                            isSearchable={true}
                                            placeholder=""
                                            onChange={(combo) => this.handleCommissions(index, 'profileId', combo?.value ?? 0, false)}
                                        />
                                    </Col>
                                    <Col>
                                        <CustomSelect
                                            value={commissionTypes.find(el => el.value === commission.comissionType) || ''}
                                            options={commissionTypes}
                                            required={true}
                                            isSearchable={true}
                                            placeholder=""
                                            onChange={(combo) => this.handleCommissions(index, 'comissionType', combo?.value ?? 0, false)}
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            type="number"
                                            name="percent"
                                            placeholder=""
                                            value={commission.percent || ''}
                                            onChange={(event) => this.handleCommissions(index, 'percent', event.target?.value, false)}
                                            step={0.1}
                                            min={0}
                                            max={100}
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            required
                                            type="number"
                                            name="value"
                                            placeholder=""
                                            value={commission.value || ''}
                                            onChange={(event) => this.handleCommissions(index, 'value', event.target?.value, false)}
                                            min={0}
                                        />
                                    </Col>
                                    <Col sm={1} className=" text-right pr-4">
                                        <i className="fas fa-trash-alt text-danger pointer" onClick={_ => this.handleCommissions(index, null, null, true)} />
                                    </Col>
                                </Row>
                            )
                            : <DefaultPage text="SalesDeal.NoCommissionsYet" height="20vh" />}
                    </Form>
                </BlankCard>
            </StyledModal>
        );
    }
}
export default injectIntl(CommissionModal)
