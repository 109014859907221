import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Input, Row } from 'reactstrap';
import { getUsersRoles, SalesProcessUsersCombo } from '../SalesProcessFunctions';
import CustomSelect from '../../Base/CustomSelect';

class InitiativeModalStep4 extends Component {
    
    addUser = () => {
        const { initiative, handleProfilesList } = this.props;
        const profiles = initiative.salesInitiativeUsers ? [...initiative.salesInitiativeUsers] : [];

        profiles.push({});

        handleProfilesList(profiles)
    }

    render() {
        const { initiative, handleListElement, intl } = this.props;

        return (
            <div>
                <Row className="mb-3 border-bottom pb-2 mt-4">
                    <Col>
                        <div className={`title-bold required`}>
                            <b>
                                <FormattedMessage id="SalesDeal.Users"/>
                            </b>
                        </div>
                    </Col>
                    <Col className="text-right">
                        <Button className="btn-host btn-sm" onClick={this.addUser}>
                            <i className="fas fa-plus"/>
                        </Button>
                    </Col>
                </Row>

                <Row className="text-muted mb-lg-3">
                    <Col className='col-1'></Col>
                    <Col className='col-4'><FormattedMessage id="generic.name" /></Col>
                    <Col className='col-lg col-5'><FormattedMessage id="SalesDeal.Role" /></Col>
                    {!global.isMobile ?
                        <Col><FormattedMessage id="SalesDeal.Notes" /></Col>
                    :''}
                    <Col className='col-1'></Col>
                </Row>

                {initiative.salesInitiativeUsers?.map((user, key) => {
                    return(
                        !global.isMobile ?
                            <Row className="align-items-center overbglight py-2" key={key}>
                                <Col className="text-center col-1">
                                    <i className="fas fa-user"/>
                                </Col>
                                <Col sm={4}>
                                    <SalesProcessUsersCombo
                                        placeholder=""
                                        value={user.userId}
                                        onChangeFunc={(name, combo) => handleListElement(key, 'userId', combo ? combo.value : null)}
                                        fromInitiative
                                        isClearable
                                        isSearchable
                                        required
                                    />
                                </Col>

                                <Col>
                                    <CustomSelect
                                        placeholder=""
                                        options={getUsersRoles(intl)}
                                        value={getUsersRoles(intl).find(t => t.value === user.role) || ''}
                                        onChange={(combo) => handleListElement(key, 'role', combo ? combo.value : null)}
                                        isClearable
                                        isSearchable
                                        required
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        type="text"
                                        name="notes"
                                        placeholder=""
                                        value={user.notes || ''}
                                        onChange={(event) => handleListElement(key, 'notes', event.target?.value)}
                                        maxLength={500}
                                    />
                                </Col>
                                <Col sm={1} className="text-right pr-4">
                                    <i className="fas fa-trash-alt text-danger pointer" onClick={ _ => handleListElement(key, null, null, true)} />
                                </Col>
                            </Row>
                        :
                            <Row className={`align-items-center overbglight ${key ? 'py-2' : 'pb-2'}`} key={key}>
                                <Col className="text-center col-1">
                                    <i className="fas fa-user"/>
                                </Col>
                                <Col className='px-0 col-9'>
                                    <Col className='col-12 d-flex align-items-center px-0'>
                                        <Col className='col-6'>
                                            <SalesProcessUsersCombo
                                                placeholder=""
                                                value={user.userId}
                                                onChangeFunc={(name, combo) => handleListElement(key, 'userId', combo ? combo.value : null)}
                                                isClearable
                                                isSearchable
                                                required
                                            />
                                        </Col>
                                        <Col className='col-6 px-0'>
                                            <CustomSelect
                                                placeholder=""
                                                options={getUsersRoles(intl)}
                                                value={getUsersRoles(intl).find(t => t.value === user.role) || ''}
                                                onChange={(combo) => handleListElement(key, 'role', combo ? combo.value : null)}
                                                isClearable
                                                isSearchable
                                                required
                                            />
                                        </Col>
                                    </Col>
                                    <Col className='col-3 text-muted mt-2'><FormattedMessage id="SalesDeal.Notes"/></Col>
                                    <Col className="pr-0 col-12">
                                        <Input
                                            type="text"
                                            name="notes"
                                            placeholder=""
                                            value={user.notes || ''}
                                            onChange={(event) => handleListElement(key, 'notes', event.target?.value)}
                                            maxLength={500}
                                        />
                                    </Col>
                                </Col>
                                <Col className="text-right col-2">
                                    <i className="fas fa-trash-alt text-danger pointer" onClick={ _ => handleListElement(key, null, null, true)} />
                                </Col>
                            </Row>
                    )   
                })}
            </div>
        );
    }
}

export default injectIntl(InitiativeModalStep4);
