import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col, Button, Input, FormGroup } from 'reactstrap';
import { AssociateProfile } from '../../Card/VoucherDetails/AssociationModals';
import { ProfileCard } from '../SalesProcessFunctions';
import { handleNotification } from '../../Base/Notification';


class SalesDealStep3 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            associationModal: false,
            selectedType: null,
            selectedProfile: null,
            createModal: false
        };
    }

    toggleModal = (type) => {
        this.setState(prevState => ({
            associationModal: !prevState.associationModal,
            selectedType: type
        }))
    }

    selectProfile = (profile) => {
        this.setState({
            selectedProfile: {
                profileId: profile.id,
                name: `${profile.firstName ? profile.firstName : ''} ${profile.lastName ? profile.lastName : ''}`
            }
        })
    }

    associateProfile = () => {
        const { selectedType, selectedProfile } = this.state;
        const { deal } = this.props;
        
        if (selectedType === 'salesDealProfile') {
            const profiles = deal.salesDealProfile ? [...deal.salesDealProfile] : [];
            if(profiles.some(p => p.profileId === selectedProfile?.profileId) || deal?.mainContactId === selectedProfile?.profileId) {
                const message ={ warnings: [{ message: this.props.intl.formatMessage({ id: 'SalesDeal.ProfileAlreadyAssociated' }) }] }
                
                this.setState({ selectedProfile: null }, () => handleNotification(message));
                return;
            }

            profiles.push(selectedProfile);

            this.props.handleProfilesList(selectedType, profiles);
        }
        else {
            if(selectedType === 'mainContact' && deal?.salesDealProfile && deal.salesDealProfile.some(p => p.profileId === selectedProfile?.profileId)) {
                const message ={ warnings: [{ message: this.props.intl.formatMessage({ id: 'SalesDeal.ProfileAlreadyAssociated' }) }] }
                
                this.setState({ selectedProfile: null }, () => handleNotification(message));
                return;
            }
            this.props.changeProfile(selectedType, selectedProfile);
        }

        this.setState({ associationModal: false, selectedType: null, selectedProfile: null });
    }

    associateNewProfile = (id, firstName, lastName) => {
        const { deal, handleProfilesList } = this.props;
        const { selectedType } = this.state;

        const selectedProfile = {
            profileId: id,
            name: `${firstName ? firstName : ''} ${lastName ? lastName : ''}`
        };

        if (selectedType === 'salesDealProfile') {
            const profiles = deal.salesDealProfile ? [...deal.salesDealProfile] : [];
            profiles.push(selectedProfile);

            handleProfilesList(selectedType, profiles);
        }
        else {
            this.props.changeProfile(selectedType, selectedProfile);
        }

        this.setState({ createModal: false, associationModal: false });
    }

    toggleCreateModal = () => {
        this.setState({ createModal: !this.state.createModal });
    }

    render() {
        const { associationModal, createModal } = this.state;
        const { deal, roles, isLead } = this.props;

        return (
            <div>
                <FormGroup row>
                    <Col className='pr-2 pr-lg-3'>
                        {!global.isMobile ? 
                            <div className={`title-sm ${isLead ? '' : 'required'}`}>
                                <FormattedMessage id="SalesDeal.Customer" />
                                <i className="fas fa-edit ml-2 pointer" onClick={_ => this.toggleModal('customer')} />
                            </div>
                        :
                            <div className={`title-sm  ${global.isMobile ? 'd-flex align-items-center justify-content-between' : ''}`}>
                                <div className="title-sm required">
                                    <FormattedMessage id="SalesDeal.Customer" />
                                </div>
                                <i className="fas fa-edit ml-2 pointer" onClick={_ => this.toggleModal('customer')} />
                            </div>
                        }
                        <Input
                            required={!isLead}
                            type="text"
                            name="notes"
                            placeholder=""
                            value={deal.customer?.name || ''}
                            disabled
                        />
                    </Col>
                    <Col className='pl-2 pl-lg-3'>
                        {!global.isMobile ? 
                            <div className="title-sm required">
                                <FormattedMessage id="SalesDeal.MainContact" />
                                <i className="fas fa-edit ml-2 pointer" onClick={_ => this.toggleModal('mainContact')} />
                            </div>
                        :
                            <div className={`title-sm  ${global.isMobile ? 'd-flex align-items-center justify-content-between' : ''}`}>
                                <div className="title-sm required">
                                    <FormattedMessage id="SalesDeal.MainContact" />
                                </div>
                                <i className="fas fa-edit ml-2 pointer" onClick={_ => this.toggleModal('mainContact')} />
                            </div>
                        }
                        <Input
                            required
                            type="text"
                            name="notes"
                            placeholder=""
                            value={deal.mainContact?.name || ''}
                            disabled
                        />
                    </Col>
                </FormGroup>

                <Row className="mb-3 border-bottom pb-2 mt-4">
                    <Col>
                        <b><FormattedMessage id="SalesDeal.Profiles" /></b>
                    </Col>
                    <Col className="text-right">
                        <Button className="btn-host btn-sm" onClick={() => this.toggleModal('salesDealProfile')}>
                            <i className="fas fa-plus " />
                        </Button>
                    </Col>
                </Row>

                <Row className="text-muted mb-lg-3 mb-0">
                    <Col className='col-lg col-5'><FormattedMessage id="generic.name"/></Col>
                    <Col className='col-lg col-6'><FormattedMessage id="SalesDeal.Role"/></Col>
                    {!global.isMobile ?
                        <> 
                            <Col className='col-lg col-3'><FormattedMessage id="SalesDeal.Notes"/></Col>
                            <Col className='col-1' />
                        </>
                    :''}
                </Row>

                {deal.salesDealProfile?.map((profile, key) =>
                    <Row className={`${!global.isMobile || key ? 'py-2' : 'pb-2' } overbglight`} key={key}>
                        <Col>
                            <ProfileCard
                                name={profile.name}
                                roleId={profile.roleId}
                                notes={profile.notes}
                                hasAddicionalFields={true}
                                roleList={roles}
                                handleListElement={(propName, value) => this.props.handleListElement('salesDealProfile', key, propName, value, false)}
                                removeElement={_ => this.props.handleListElement('salesDealProfile', key, null, null, true)}
                            />
                        </Col>
                    </Row>
                )}

                {associationModal ?
                    <AssociateProfile
                        modal={associationModal}
                        toggleModal={this.toggleModal}
                        associateProfileOrReservation={this.associateProfile}
                        selectProfile={this.selectProfile}
                        profile={this.state.selectedProfile}
                        toggleCreateModal={_ => this.toggleCreateModal()}
                        associateNewProfile={this.associateNewProfile}
                        createModal={createModal}
                        enableCreate={true}
                        disableMasterFilter={true}
                        createMasterAPI={true}
                        isPortal={true}
                    />
                : ''}
            </div>
        )
    }
}

export default injectIntl(SalesDealStep3);