import React, { Component } from 'react';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { Button, Card, Col, Form, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../Base/CustomSelect';
import CustomFunnel from '../Base/CustomFunnel';
import { Doughnut } from 'react-chartjs-2';
import { FormatAmountNumber, CommonHelper, ExportToPDFButton, FilterButton, CoolTooltip } from '../Base/CommonUIComponents';
import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import CustomTwoMonthDateRangePicker from '../Base/CustomTwoMonthDateRangePicker';
import { getSalesProcessTypeOfRevenue } from '../Base/ReferenceDataFunctions';
import { SalesProcessHotelCombo } from './SalesProcessFunctions';
import Chart from 'react-google-charts';
import { downloadExcelFromJson } from '../Base/ReferenceDataFunctions';
import moment from 'moment';


class SalesProcessExecutiveReportYearly extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            block: true,
            hotelList: [],
            totalStageMetrics: [],
            funnelData: null,
            modal: false,
            selectedHotel: null,
            selectedRevenue: 'totalValue',
            preSelectedRevenue: 'totalValue',
            segmentOptions: null,
            subSegmentOptions: null
        };
    }

    componentDidMount() {
        this.getQueryStringValues();
    }

    getQueryStringValues = () => {
        const search = new URLSearchParams(window.location.search);

        const hotels = search.getAll('hotels');
        const selectedRevenue = search.get('revenueType') || this.state.selectedRevenue;
        const startDate = search.get('startDate');
        const endDate = search.get('endDate');
        const selectedSegment = search.get('segment');
        const selectedSubSegment = search.get('subSegment');

        if (startDate && endDate) {
            this.props.handleDates(moment(startDate), moment(endDate));
        }

        this.setState({
            hotelList: hotels || [],
            selectedRevenue,
            preSelectedRevenue: selectedRevenue,
            selectedSegment,
            selectedSubSegment

        }, () => {
            const { yearlyReport } = this.props;

            if (!yearlyReport) {
                this.getYearlyReport();
            }
            else {
                this.inicialCalc(yearlyReport);
            }
        });
    }


    buildQueryString = () => {
        const { hotelList, preSelectedRevenue, selectedSegment, selectedSubSegment } = this.state;
        const { startDate, endDate } = this.props;
        const queryParams = new URLSearchParams();

        if (hotelList?.length > 0) {
            hotelList.forEach(s => queryParams.append('hotels', s));
        }

        if (startDate && moment(startDate)) {
            queryParams.append('startDate', moment(startDate).format('YYYY-MM-DD'));
        }

        if (endDate && moment(endDate)) {
            queryParams.append('endDate', moment(endDate).format('YYYY-MM-DD'));
        }

        if (preSelectedRevenue) {
            queryParams.append('revenueType', preSelectedRevenue);
        }

        if (selectedSegment) {
            queryParams.append('segment', selectedSegment);
        }

        if (selectedSubSegment) {
            queryParams.append('subSegment', selectedSubSegment);
        }

        this.setState({ selectedRevenue: preSelectedRevenue }, () => {
            const queryString = queryParams.toString();
            const newUrl = `/SalesProcess${queryString ? `?${queryString}` : ''}#SalesProcessExecutiveReport`;

            window.history.pushState(null, '', newUrl);
            this.getYearlyReport();
        })
    };

    getYearlyReport = () => {
        this.setState({ block: true }, () => {
            const { hotelList, selectedSubSegment, selectedSegment, selectedRevenue } = this.state;
            const { startDate, endDate } = this.props;

            let qs = `?startDate=${startDate?.format('YYYY-MM-DD')}&endDate=${endDate?.format('YYYY-MM-DD')}`;

            if (hotelList?.length)
                hotelList.forEach(h => {
                    qs += `&hotelIds=${h}`;
                });

            if (selectedSegment) qs += `&segment=${selectedSegment}`;
            if (selectedSubSegment) qs += `&subSegment=${selectedSubSegment}`;
            if (selectedRevenue) qs += `&revenueType=${selectedRevenue}`;

            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.data?.length) {
                        this.inicialCalc(data.data[0]);
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/executivereport/yearly${qs}`);
        })
    }

    inicialCalc = (yearlyReport) => {
        const { intl, colors } = this.props;
        const { selectedRevenue } = this.state;
        const totalStageMetrics = [];
        const pieDataRevenueData = { fb: 0, banquets: 0, lodging: 0, misc: 0, spaceRental: 0 };
        let segmentChart = [ ["Location", "Parent", "Total"], ["Segment", null, 0] ];
        let funnelData, pieDataRevenue, chartData = null;
        let groupedSources = {};

        if (yearlyReport.hotels) {
            const numbOfDeals = yearlyReport.hotels.length > 0 ? yearlyReport.hotels.reduce((a,b) => a + b.stagesTypes
                .reduce((a, b) => a + b.sourceTypes
                    .reduce((a, b) => a + (b?.deals?.numberOfDeals??0), 0), 0
                ), 0
            ) : 0;

            yearlyReport.hotels.forEach((hotel, key) => {
                hotel.color = colors.at(key);

                const totalBudget = {
                    numberOfLodging: 0, fb: 0, banquets: 0, lodging: 0, misc: 0, spaceRental: 0, totalValue: 0, numberOfSpaces: 0
                };

                hotel.budget.forEach(el => {
                    if (el.salesGroup === 'FB') {
                        totalBudget.fb += el.value;
                    }
                    else if (el.salesGroup === 'ROOM') {
                        totalBudget.numberOfLodging += el.roomNights || 0;
                        totalBudget.lodging += el.value;
                    }
                    else if (el.salesGroup === 'ETC') {
                        totalBudget.misc += el.value;
                    }
                    else if (el.salesGroup === 'BANQ') {
                        totalBudget.banquets += el.value;
                    }
                    else if (el.salesGroup === 'SPORT') {
                        totalBudget.spaceRental += el.value;
                        totalBudget.numberOfSpaces += el.roomNights;
                    }

                    totalBudget.totalValue += el.value || 0;
                })

                hotel.totalBudget = totalBudget;
                
                
                if (hotel.stagesTypes) {
                    const hotelNumbOfDeals = hotel.stagesTypes.reduce((a, b) => a + b.sourceTypes
                        .reduce((a, b) => a + (b?.deals?.numberOfDeals??0), 0), 0
                    );
                    
                    hotel.numberOfDeals = hotelNumbOfDeals;
                    hotel.dealShare = Math.round((hotelNumbOfDeals / numbOfDeals * 100) * 100) / 100;

                    hotel.stagesTypes.forEach(stage => {
                        let current = totalStageMetrics.find(({stageTypeId}) => stageTypeId === stage.stageTypeId);

                        if(!current){
                            totalStageMetrics.push({
                                stageTypeId: stage.stageTypeId,
                                stageName: stage.stageName,
                                index: stage.index,
                                isDef: stage.isDef
                            });
                            current = totalStageMetrics.find(({stageTypeId}) => stageTypeId === stage.stageTypeId);
                        }

                        stage.sourceTypes.forEach((source) => {
                            stage.numberOfDeals = (stage.numberOfDeals ?? 0) + (source.deals?.numberOfDeals ?? 0);
                            stage.numberOfWonDeals = (stage.numberOfWonDeals ?? 0) + (source.deals?.numberOfWonDeals ?? 0);
                            stage.numberOfLostDeals = (stage.numberOfLostDeals ?? 0) + (source.deals?.numberOfLostDeals ?? 0);

                            stage.fb = (stage.fb ?? 0) + (source.revenueMetrics?.fb ?? 0);
                            stage.banquets = (stage.banquets ?? 0) + (source.revenueMetrics?.banquets ?? 0);
                            stage.misc = (stage.misc ?? 0) + (source.revenueMetrics?.misc ?? 0);
                            stage.lodging = (stage.lodging ?? 0) + (source.revenueMetrics?.lodging ?? 0);
                            stage.spaceRental = (stage.spaceRental ?? 0) + (source.revenueMetrics?.spaceRental ?? 0);
                            stage.totalValue = (stage.totalValue ?? 0) + (source.revenueMetrics?.totalValue ?? 0);
                            stage.numberOfLodging = (stage.numberOfLodging ?? 0) + (source.revenueMetrics?.numberOfLodging ?? 0);


                            //Group Sources
                            const sourceTypeId = source.sourceTypeId;
                            if (!groupedSources[sourceTypeId]) {
                                groupedSources[sourceTypeId] = {
                                    totalDeals: 0,
                                    totalWonDeals: 0,
                                    totalValue: 0,
                                };
                            }

                            groupedSources[sourceTypeId].totalDeals += source.deals.numberOfDeals;
                            groupedSources[sourceTypeId].totalWonDeals += source.deals.numberOfWonDeals;
                            groupedSources[sourceTypeId].totalValue += source.revenueMetrics.totalValue;
                            groupedSources[sourceTypeId].sourceName = source.sourceName;


                            if (stage.isDef) {
                                //Segment chart
                                segmentChart = this.segmentChartCalc(segmentChart, source.segmentTypes);

                                //pieDataRevenue
                                pieDataRevenueData.fb += source.revenueMetrics.fb ?? 0;
                                pieDataRevenueData.banquets += source.revenueMetrics.banquets ?? 0;
                                pieDataRevenueData.lodging += source.revenueMetrics.lodging ?? 0;
                                pieDataRevenueData.misc += source.revenueMetrics.misc ?? 0;
                                pieDataRevenueData.spaceRental += source.revenueMetrics.spaceRental ?? 0;

                            }
                        });



                        const numberOfDeals = (current.deals?.numberOfDeals??0) + stage.numberOfDeals;
                        
                        stage.conversionRate = Math.round(((stage.numberOfDeals / hotelNumbOfDeals) * 100) * 100) / 100;
                        
                        current.deals = {
                            numberOfDeals,
                            numberOfWonDeals: (current.deals?.numberOfWonDeals??0) + stage.numberOfWonDeals??0,
                            numberOfLostDeals: (current.deals?.numberOfLostDeals??0) + stage.numberOfLostDeals??0,
                            conversionRate: Math.round(((numberOfDeals / numbOfDeals) * 100) * 100) / 100
                        };

                        current.revenueMetrics = {
                            fb: (current.revenueMetrics?.fb??0) + stage.fb??0,
                            banquets: (current.revenueMetrics?.banquets ?? 0) + stage.banquets ??0,
                            misc: (current.revenueMetrics?.misc??0) + stage.misc??0,
                            lodging: (current.revenueMetrics?.lodging??0) + stage.lodging??0,
                            spaceRental: (current.revenueMetrics?.spaceRental??0) + stage.spaceRental??0,
                            totalValue: (current.revenueMetrics?.totalValue??0) + stage.totalValue??0,
                            numberOfLodging: (current.revenueMetrics?.numberOfLodging??0) + stage.numberOfLodging??0
                        };
                    });
                }
            });

            //Order stage by index
            totalStageMetrics.sort((a, b) => a.index - b.index);
             
            let revenueColors = colors.slice(0, 5);
            pieDataRevenue = {
                datasets: [
                    {
                        label: intl.formatMessage({ id: "SalesProcess.RevenueDistribution" }),
                        data: selectedRevenue === "totalValue" ? Object.values(pieDataRevenueData) : Object.entries(pieDataRevenueData).filter(([key]) => selectedRevenue === key).map(([, value]) => value),
                        sourceNames: getSalesProcessTypeOfRevenue(intl).filter(x => x.value !== "totalValue" && (selectedRevenue === "totalValue" || x.value === selectedRevenue)).map(x => x.label),
                        backgroundColor: revenueColors
                    },
                ],
                labels: (getSalesProcessTypeOfRevenue(intl).filter(x => x.value !== "totalValue" && (selectedRevenue === "totalValue" || x.value === selectedRevenue)).map(x => x.label) ?? '-')
            };

            funnelData = totalStageMetrics.map(stage => ({
                id: (stage.deals.numberOfDeals - stage.deals.numberOfLostDeals) + " Deals\n" + this.formatAmountNumber(stage.revenueMetrics[selectedRevenue], "EUR") + "\n" + stage.deals.conversionRate + " %\n",
                value: stage.revenueMetrics[selectedRevenue],
                label: stage.stageName 
            }))

            chartData = {
                datasets: [
                    {
                        label: intl.formatMessage({ id: "SalesProcess.RevenueDistribution" }),
                        data: yearlyReport.deals.lostDealsReasons.map(el => el.value),
                        backgroundColor: colors
                    },
                ],
                labels: yearlyReport.deals.lostDealsReasons.map(el => { return { v: el.customTypeId, f: el.customTypeName } })
            };
            
        }

        this.setState({
            block: false,
            totalStageMetrics: totalStageMetrics??[],
            funnelData,
            groupedSources,
            pieDataRevenue,
            chartData,
            segmentChart
        }, () => this.props.updateYearlyReport(yearlyReport));
        return;
    }

    segmentChartCalc = (segmentChart, segments) => {
        var chart = segmentChart;
        
        if (segments?.length > 0) {
            const { segmentList, subSegmentList } = this.props;
            
            segments.forEach(segment => {
                segment.subSegmentTypes.forEach(subSegment => {

                    const segmentName = segment.segmentName || 'N/A';//? segmentList?.find(el => el.hotelId === hotel.hotelId && el.code === segment.name)?.description ?? segment.name : 'N/A';
                    const subSegmentName = `${subSegment.subSegmentName || 'N/A' /*? subSegmentList?.find(el => el.hotelId === hotel.hotelId && el.code === subSegment.name)?.description ?? subSegment.name : 'N/A'*/} - ${segmentName}`;
                    const subSegmentValue = subSegment.revenueMetrics[this.state.selectedRevenue];
                    const segmentId = `Segment-${segmentName}`;

                    const segmentIndex = chart.findIndex(data => data[0].v === segmentId);

                    if (segmentIndex !== -1) {
                        const subSegmentIndex = chart.findIndex(data => data[0] === subSegmentName && data[1] === segmentId);

                        if (subSegmentIndex !== -1) {
                            chart[subSegmentIndex][2] += subSegmentValue ?? 0;
                        }
                        else {
                            chart.push([subSegmentName, segmentId, subSegmentValue ?? 0]);
                        }

                        chart[segmentIndex][2] += subSegmentValue ?? 0;
                    }
                    else {
                        chart.push([{ v: segmentId, f: segmentName }, "Segment", subSegmentValue ?? 0]);
                        chart.push([subSegmentName, segmentId, subSegmentValue ?? 0]);
                    }
                })
            })
        }

        return chart;
    }

    filterRefData = (hotelList) => {
        const { selectedSegment, selectedSubSegment } = this.state;
        let segmentOptions = [], subSegmentOptions = [];

        if (hotelList) {
            const { segmentList, subSegmentList } = this.props;

            segmentOptions = [...segmentList]?.filter(({ hotelId, subSegments }) => (!hotelId || !hotelList?.length || hotelList.includes(hotelId)) && (!selectedSubSegment || subSegments.includes(selectedSubSegment)));
            subSegmentOptions = [...subSegmentList]?.filter(({ hotelId, segments }) => (!hotelId || !hotelList?.length || hotelList.includes(hotelId)) && (!selectedSegment || segments.includes(selectedSegment)));
        }

        this.setState({ segmentOptions, subSegmentOptions })
    }

    handleHotelListChange = (e, combo) => {
        const hotelList = combo ? combo?.map(cmb => cmb.value) : []

        this.setState({ hotelList }, this.filterRefData(hotelList));
    }
    
    handleSegment = (combo, name) => {
        this.setState({
            [name]: combo ? combo.code : null
        }, () => this.filterRefData(this.state.hotelList))
    }

    handleSearch = (e) => {
        e.preventDefault();

        this.buildQueryString();
    }

    downLoadFormatAmountNumber = (value, currency) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value).replaceAll(",", "");
    };

    downloadExcelData = () => {
        const { totalStageMetrics, selectedRevenue, groupedSources, pieDataRevenue } = this.state;
        const { yearlyReport, intl } = this.props;

        const yearlyStats = [
            {
                "Deal Type": "Lost Deals",
                "Deals": yearlyReport?.deals?.numberOfLostDeals ?? '0',
                "Value (€)": this.downLoadFormatAmountNumber(yearlyReport?.deals?.proposalValueLost ?? 0, "EUR")
            },
            {
                "Deal Type": "Won Deals",
                "Deals": yearlyReport?.deals?.numberOfWonDeals ?? '0',
                "Value (€)": this.downLoadFormatAmountNumber(yearlyReport?.deals?.proposalValueWon ?? 0, "EUR")
            },
            {
                "Deal Type": "Negotiation Deals",
                "Deals": (yearlyReport?.deals?.numberOfDeals ?? 0) - ((yearlyReport?.deals?.numberOfLostDeals ?? 0) + (yearlyReport?.deals?.numberOfWonDeals ?? 0)) ?? '0', 
                "Value (€)": this.downLoadFormatAmountNumber((yearlyReport?.deals?.proposalValueTotal ?? 0) - ((yearlyReport?.deals?.proposalValueLost ?? 0) + (yearlyReport?.deals?.proposalValueWon ?? 0)), "EUR")
            },
            {
                "Deal Type": "Deals",
                "Deals": yearlyReport?.deals?.numberOfDeals ?? '0',
                "Value (€)": this.downLoadFormatAmountNumber(yearlyReport?.deals?.proposalValueTotal ?? 0, "EUR")
            }
        ];

        const hotelMetrics = yearlyReport.hotels.map(hotel => {
            const hotelName = global.hotelList.find(({ value }) => value === hotel.hotelId)?.label ?? '-';
            const hotelData = { "Hotel": hotelName };

            totalStageMetrics.forEach(stageMetric => {
                const stage = hotel.stagesTypes.find(({ stageTypeId }) => stageMetric.stageTypeId === stageTypeId);
                hotelData[`${stageMetric.stageName} #`] = stage?.numberOfLodging ?? '0';
                hotelData[`${stageMetric.stageName} EUR`] = this.downLoadFormatAmountNumber(stage?.totalValue ?? 0, "EUR");
                hotelData[`${stageMetric.stageName} Nr`] = stage?.numberOfDeals ?? '0';
                hotelData[`${stageMetric.stageName} %`] = stage?.conversionRate ?? '0';
            });

            hotelData["Budget #"] = hotel.totalBudget.numberOfLodging ?? '-';
            hotelData["Budget EUR"] = this.downLoadFormatAmountNumber(hotel.totalBudget[selectedRevenue], "EUR");

            const definitiveStage = totalStageMetrics.find(el => el.isDef);
            if (definitiveStage) {
                const stage = hotel.stagesTypes.find(({ stageTypeId }) => definitiveStage.stageTypeId === stageTypeId);
                if (stage) {
                    const diffNumberOfLodging = stage.numberOfLodging - hotel.totalBudget.numberOfLodging;
                    const diffRevenue = stage[selectedRevenue] - hotel.totalBudget[selectedRevenue];
                    hotelData["Difference #"] = diffNumberOfLodging;
                    hotelData["Difference EUR"] = this.downLoadFormatAmountNumber(diffRevenue, "EUR");
                }
            }

            hotelData["Difference #"] = hotelData["Difference #"] ?? "0";
            hotelData["Difference EUR"] = hotelData["Difference EUR"] ?? "0";

            return hotelData;
        });

        const stageMetrics = totalStageMetrics.map(stage => ({
            "Stage": stage.stageName ?? '-',
            "Number of Deals": stage.revenueMetrics.numberOfLodging ?? 0,
            "Revenue": this.downLoadFormatAmountNumber(stage.revenueMetrics[selectedRevenue] ?? 0, "EUR"),
            "Deals": stage.deals.numberOfDeals ?? 0,
            "Conversion Rate (%)": stage.deals.conversionRate ?? 0
        }));

        const sourceData = groupedSources && Object.entries(groupedSources).map(([sourceTypeId, sourceData]) => ({
            "Source": sourceData.sourceName,
            "Deals": sourceData.totalDeals ?? '0',
            "Won Deals": sourceData.totalWonDeals ?? '0',
            "Total Value": this.downLoadFormatAmountNumber(sourceData.totalValue ?? '0', "EUR")
        }));

        const cancelReasons = yearlyReport.deals.lostDealsReasons && yearlyReport.deals.lostDealsReasons.map(el => ({
            "Reason": el.customTypeName,
            "Total": el.value
        }));

        const revenueDistribution = yearlyReport.hotels.map(hotel => ({
            "Hotel": global.hotelList?.find(({ value }) => value === hotel.hotelId)?.label ?? '-',
            "Total": this.downLoadFormatAmountNumber(hotel.totalValue ?? 0, "EUR")
        }));

        const revenueByTypeDistribution = pieDataRevenue && pieDataRevenue.datasets[0].data.map((type, idx) => ({
            "Type": pieDataRevenue.datasets[0].sourceNames[idx],
            "Total": this.downLoadFormatAmountNumber(type ?? 0, "EUR")
        }));

        const jsonData = {
            "YearlyStats": yearlyStats,
            "HotelMetrics": hotelMetrics,
            "StageMetrics": stageMetrics,
            "SourceData": sourceData,
            "CancelReasons": cancelReasons,
            "RevenueDistribution": revenueDistribution,
            "RevenueByTypeDistribution": revenueByTypeDistribution
        };

        downloadExcelFromJson(jsonData, 'Sales_Report', intl, 'ExecutiveReportHeaders', true);
    };

    handleTypeOfRevenueChange = (combo) => {
        this.setState({ preSelectedRevenue: combo.value });
    }

    calcDiff = (stageProp, budgetValue, hotelStagesTypes, isAmount) => {
        const definitiveStage = [...this.state.totalStageMetrics].find(el => el.isDef);
        
        if (definitiveStage) {
            let value = 0;

            if (hotelStagesTypes) {//por hotel
                const stage = hotelStagesTypes.find(({ stageTypeId }) => definitiveStage.stageTypeId === stageTypeId);
                
                if(stage)
                    value = stage[stageProp] - budgetValue;
            }
            else { //total
                value = definitiveStage.revenueMetrics[stageProp] - budgetValue;
            }

            if (isAmount) {
                return <span className={`${value >= 0 ? 'text-success' : 'text-danger'}`}><FormatAmountNumber value={Math.round(value * 100) / 100} hideDecimals={true} currency={"EUR"} /></span>
            }
            else return <span className={`${value >= 0 ? 'text-success' : 'text-danger'}`}>{Math.round(value * 1) / 1}</span>
        }

        return '-';
    }

    formatAmountNumber = (value, currency) => {
        return new Intl.NumberFormat(this.props.intl.locale, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
    }
    
    countFilters = (e) => {
        if (e) e.preventDefault();
        const { hotelList, preSelectedRevenue, selectedSubSegment, selectedSegment } = this.state;
        const { startDate, endDate } = this.props;
        let count = 0;

        if (hotelList?.length > 0) count++;
        if (preSelectedRevenue) count++;
        if (startDate || endDate) count++;
        if (selectedSubSegment) count++;
        if (selectedSegment) count++;

        return count;
    }

    handleCancelReasonClick = (event, chartElement) => {
        if (chartElement.length > 0) {
            const { chartData, hotelList } = this.state;
            
            const elementIndex = chartElement[0].index;
            const cancelReasonId = chartData.labels[elementIndex].v;

            this.props.toggleTab("SalesDealList", hotelList, null, null, null, null, null, null, null, cancelReasonId);
        }
    }

    render() {
        const { error, block, totalStageMetrics, hotelList, funnelData, selectedRevenue, preSelectedRevenue, groupedSources, pieDataRevenue, chartData, segmentChart, segmentOptions, subSegmentOptions, selectedSubSegment, selectedSegment } = this.state;
        const { icon, intl, yearlyReport, toggleTab, startDate, endDate } = this.props;
        const optionsPieCancel = {
            responsive: true,
            maintainAspectRatio: false,
            cutout: '60%',
            plugins: {
                datalabels: {
                    display: false,
                },
                legend: {
                    display: true,
                    position: 'left',
                    labels: {
                        usePointStyle: true,
                        boxWidth: 2,
                        generateLabels: (chart) => {
                            const data = chart.data;
                            const dataset = data.datasets[0];
                            return data.labels.map((label, index) => {
                                const value = dataset.data[index];
                                return {
                                    text: ` ${label.f} - ${value}`,
                                    fillStyle: dataset.backgroundColor[index],
                                    strokeStyle: dataset.backgroundColor[index],
                                    hidden: false,
                                    lineWidth: 10,
                                    pointStyle: 'rect',
                                };
                            });
                        },
                    },
                },
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            const value = tooltipItem.raw;
                            const label = tooltipItem.label;
                            const customTypeName = label.f;

                            const dataset = tooltipItem.chart.data.datasets[0].data;

                            const total = dataset.reduce((sum, val) => sum + val, 0);

                            const percentage = ((value / total) * 100).toFixed(2);

                            return `${customTypeName} - ${value} (${percentage}%)`;
                        },
                    },
                },
            },
            onHover: (event, chartElement) => {
                if (chartElement.length > 0)
                    event.native.target.style.cursor = 'pointer';
                else
                    event.native.target.style.cursor = 'default';
            },
            onClick: this.handleCancelReasonClick
        };

        const optionsPieRevenue = {
            responsive: true,
            maintainAspectRatio: false,
            cutout: '60%',
            plugins: {
                datalabels: {
                    display: false,
                },
                legend: {
                    display: true,
                    position: 'left',
                    labels: {
                        usePointStyle: true,
                        boxWidth: 2,
                        generateLabels: (chart) => {
                            const data = chart.data;
                            const dataset = data.datasets[0];
                            return data.labels.map((label, index) => {
                                const value = dataset.data[index];
                                return {
                                    text: `${label} - ${this.formatAmountNumber(value, "EUR")}`,
                                    fillStyle: dataset.backgroundColor[index],
                                    strokeStyle: dataset.backgroundColor[index],
                                    hidden: false,
                                    lineWidth: 10,
                                    pointStyle: 'rect', 
                                };
                            });
                        }
                    }
                }
            }
        };

        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error}/>
                <BlockUi tag="div" blocking={block}>
                    <div className='mb-4'>
                        <Row>
                            <Col>
                                <h4 className='m-0'>
                                    <i className={`${icon} mr-2`} />
                                    <FormattedMessage id="SalesProcess.ExecutiveReport" />
                                </h4>
                            </Col>
                            <Col className="pr-2">
                                <CommonHelper help={''} id={'ExecutiveReportHelp'} />
                            </Col>

                        </Row>
                    </div>
                    <div>
                        <Form onSubmit={this.handleSearch}>
                            <Row>
                                <Col className='col-3'>
                                    <div className='title-sm'>
                                        <i className="icon-icon-hotel mr-2"/>
                                        <FormattedMessage id="SalesProcess.Hotels"/>
                                    </div>
                                    <div>
                                        <SalesProcessHotelCombo
                                            isMulti={true}
                                            isClearable={true}
                                            name='hotelList'
                                            placeholder={'Hotel'}
                                            value={hotelList}
                                            onChangeFunc={this.handleHotelListChange}
                                        />
                                    </div>
                                </Col>
                                <Col className='col-3'>
                                    <div className='title-sm'>
                                        <i className="fas fa-dollar-sign mr-2"></i>
                                        <FormattedMessage id="SalesProcess.TypeOfRevenue"/>
                                    </div>
                                    <div>
                                        <CustomSelect
                                            options={getSalesProcessTypeOfRevenue(intl)}
                                            value={getSalesProcessTypeOfRevenue(intl).find(({ value }) => value === preSelectedRevenue) || ''}
                                            isSearchable={true}
                                            onChange={e => this.handleTypeOfRevenueChange(e)}
                                            placeholder={intl.formatMessage({ id: "SalesProcess.TypeOfRevenue" })}
                                        />
                                    </div>
                                </Col>
                                <Col className={`col-3`}>
                                    <div className='title-sm'>
                                        <i className="far fa-calendar-alt mr-2" />
                                        <FormattedMessage id="SalesProcess.Dates" />
                                    </div>
                                    <div>
                                        <CustomTwoMonthDateRangePicker
                                            startDate={startDate}
                                            endDate={endDate}
                                            required={true}
                                            handleDates={this.props.handleDates}
                                        />
                                    </div>
                                </Col>
                                <Col className='col-3 d-flex align-items-center justify-content-end'>
                                    <div className="mr-4">
                                        <FilterButton totalFilters={this.countFilters()} butId={"buttonSubmit"}>
                                            <div className="fullWidth">
                                                <div className='mt-3'>
                                                    <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                                        <i className="fas fa-layer-group mr-2" />
                                                        <FormattedMessage id="SalesProcess.Segment" />
                                                    </div>
                                                    <div>
                                                        <CustomSelect
                                                            options={segmentOptions??this.props.segmentList}
                                                            isClearable={true}
                                                            value={this.props.segmentList?.filter(({ code }) => selectedSegment === code) || ''}
                                                            isSearchable={true}
                                                            onChange={e => this.handleSegment(e, 'selectedSegment')}
                                                            placeholder={intl.formatMessage({ id: "SalesProcess.Segment" })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-3'>
                                                    <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                                        <i className="fas fa-layer-group mr-2" />
                                                        <FormattedMessage id="SalesProcess.SubSegment" />
                                                    </div>
                                                    <div>
                                                        <CustomSelect
                                                            options={subSegmentOptions??this.props.subSegmentList}
                                                            isClearable={true}
                                                            value={this.props.subSegmentList?.filter(({ code }) => selectedSubSegment === code) || ''}
                                                            isSearchable={true}
                                                            onChange={e => this.handleSegment(e, 'selectedSubSegment')}
                                                            placeholder={intl.formatMessage({ id: "SalesProcess.SubSegment" })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </FilterButton>
                                    </div>

                                    <div className="mr-2">
                                        <ExportToPDFButton name="executiveReportYearlyPdf" fromPage='executiveReport' />
                                    </div>
                                    <Button className="btn btn-host btn-sm" onClick={this.downloadExcelData}>
                                        <i className="fas fa-file-excel"/>
                                    </Button>
                                    <Button className="btn btn-host btn-sm ml-2" type="submit" id="buttonSubmit" disabled={!startDate || !endDate}>
                                        <i className='fas fa-search'/>
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                        {yearlyReport ?
                            <Row id='executiveReportYearlyPdf' className='mt-4' style={{ fontSize: '0.9em' }}>
                                <Col className='col-12'>
                                    <Row>
                                        <Col className='col-3'>
                                            <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px' }} onClick={_ => toggleTab("SalesDealList", hotelList)}>
                                                <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                    <i className={`fas fa-briefcase mr-2`}/>
                                                    <FormattedMessage id='SalesProcess.TotalDeals'/>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                    <div>
                                                        {yearlyReport?.deals?.numberOfDeals??'0'}
                                                    </div>
                                                    <div>
                                                        <FormatAmountNumber
                                                            value={yearlyReport?.deals?.proposalValueTotal ?? '0'}
                                                            currency={"EUR"}
                                                            hideDecimals={true}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col className='col-3'>
                                            <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px', color: '#0665ff' }} onClick={_ => toggleTab("SalesDealList", hotelList, null, null, null, null, ["WaitingHotel", "WaitingClient"])}>
                                                <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                    <i className={`fas fa-people-arrows mr-2`}/>
                                                    <FormattedMessage id='SalesProcess.NegotiationDeals'/>
                                                </div>
                                                {(() => {
                                                    const number = (yearlyReport?.deals?.numberOfDeals??0) - ((yearlyReport?.deals?.numberOfLostDeals??0) + (yearlyReport?.deals?.numberOfWonDeals??0));

                                                    const lost = yearlyReport?.deals?.proposalValueLost??0;
                                                    const won = yearlyReport?.deals?.proposalValueWon??0;
                                                    const total = yearlyReport?.deals?.proposalValueTotal??0;
                                                    const wonAndLost = won + lost;
                                                    const value = total - wonAndLost;

                                                    return (
                                                        <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                            <div>
                                                                {number}
                                                            </div>
                                                            <div>
                                                                <FormatAmountNumber
                                                                    value={value}
                                                                    currency={"EUR"}
                                                                    hideDecimals={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                })()}
                                            </Card>
                                        </Col>
                                        <Col className='col-3'>
                                            <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px', color: '#F38375' }} onClick={_ => toggleTab("SalesDealList", hotelList, null, null, null, null, "Cancelled")}>
                                                <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                    <i className={`far fa-thumbs-down mr-2`}/>
                                                    <FormattedMessage id='SalesProcess.LostDeals'/>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                    <div>
                                                        {yearlyReport?.deals?.numberOfLostDeals??'0'}
                                                    </div>
                                                    <div>
                                                        <FormatAmountNumber
                                                            value={yearlyReport?.deals?.proposalValueLost ?? '0'}
                                                            currency={"EUR"}
                                                            hideDecimals={true}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col className='col-3'>
                                            <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px', color: '#36ad51' }} onClick={_ => toggleTab("SalesDealList", hotelList, null, null, null, null, ["Finished", "Effective"])}>
                                                <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                    <i className={`fas fa-trophy mr-2`}/>
                                                    <FormattedMessage id='SalesProcess.WonDeals'/>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                    <div>
                                                        {yearlyReport?.deals?.numberOfWonDeals??'0'}
                                                    </div>
                                                    <div>
                                                        <FormatAmountNumber
                                                            value={yearlyReport?.deals?.proposalValueWon ?? '0'}
                                                            currency={"EUR"}
                                                            hideDecimals={true}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='col-12 mt-3'>
                                    <Card className='shadow bg-white border-0 p-3 h-100 overflow-auto scrollableDiv' style={{ borderRadius: '5px', display: 'grid' }}>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='text-muted'>
                                                <i className="icon-icon-hotel mr-2"/>
                                                <FormattedMessage id="SalesProcess.GroupMetrics"/>
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <div className='d-flex align-items-center justify-content-between px-3 py-1' style={{ borderRadius: '4px', fontWeight: '600' }}>
                                                <div className='w-100' style={{ minWidth: '300px' }}>
                                                    <div className='text-truncate'>
                                                        <FormattedMessage id="SalesProcess.Hotel"/>
                                                    </div>
                                                </div>
                                                {totalStageMetrics.map((stage, key) =>
                                                    <div key={key} className='w-100 text-truncate' style={{  borderLeft: '1px solid #a5a5a5', minWidth: '250px' }}>
                                                        <div className='text-truncate text-center'>
                                                            {stage.stageName??'-'}
                                                        </div>
                                                        <div className="mt-1 d-flex align-items-center justify-content-between text-muted" style={{ fontWeight: '400' }}>
                                                            <div id={`CustomColRooms${key}`} className='text-center' style={{ width: "20%" }}>#</div>
                                                            <div id={`CustomColRev${key}`} className='text-center' style={{ width: "40%" }}>€</div>
                                                            <div id={`CustomColDeals${key}`} className='text-center' style={{ width: "20%" }}>Deals</div>
                                                            <div id={`CustomColPerc${key}`} className='text-center' style={{ width: "20%" }}>%</div>
                                                        </div>
                                                        <CoolTooltip placement="top" target={`CustomColRooms${key}`}>
                                                            <div><FormattedMessage id="SalesProcess.NumberOfRooms" /></div>
                                                        </CoolTooltip>
                                                        <CoolTooltip placement="top" target={`CustomColRev${key}`}>
                                                            <div><FormattedMessage id="SalesProcess.Revenue" /></div>
                                                        </CoolTooltip>
                                                        <CoolTooltip placement="top" target={`CustomColDeals${key}`}>
                                                            <div><FormattedMessage id="SalesProcess.NumberOfDeals" /></div>
                                                        </CoolTooltip>
                                                        <CoolTooltip placement="top" target={`CustomColPerc${key}`}>
                                                            <div><FormattedMessage id="SalesProcess.ConvertionRate" /></div>
                                                        </CoolTooltip>
                                                    </div>
                                                )}
                                                <div className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '200px' }}>
                                                    <div className='text-truncate text-center'>
                                                        <FormattedMessage id="SalesProcess.Budget" />
                                                    </div>
                                                    <div className="mt-1 d-flex align-items-center justify-content-between text-muted" style={{ fontWeight: '400' }}>
                                                        <div id="BudgetRooms" className='text-center w-100'>#</div>
                                                        <div id="BudgetRev" className='text-center w-100'>€</div>
                                                    </div>
                                                    <CoolTooltip placement="top" target={`BudgetRooms`}>
                                                        <div><FormattedMessage id="SalesProcess.NumberOfRooms" /></div>
                                                    </CoolTooltip>
                                                    <CoolTooltip placement="top" target={`BudgetRev`}>
                                                        <div><FormattedMessage id="SalesProcess.Revenue" /></div>
                                                    </CoolTooltip>
                                                </div>
                                                <div className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '200px' }}>
                                                    <div className='text-truncate text-center'>
                                                        <FormattedMessage id="SalesProcess.Difference" />
                                                    </div>
                                                    <div className="mt-1 d-flex align-items-center justify-content-between text-muted" style={{ fontWeight: '400' }}>
                                                        <div id="DifferenceRooms" className='text-center w-100'>#</div>
                                                        <div id="DifferenceRev" className='text-center w-100'>€</div>
                                                    </div>
                                                    <CoolTooltip placement="top" target={`DifferenceRooms`}>
                                                        <div><FormattedMessage id="SalesProcess.NumberOfRooms" /></div>
                                                    </CoolTooltip>
                                                    <CoolTooltip placement="top" target={`DifferenceRev`}>
                                                        <div><FormattedMessage id="SalesProcess.TotalNumberOfDeals" /></div>
                                                    </CoolTooltip>
                                                </div>
                                            </div>
                                            <div>
                                                {yearlyReport.hotels?.map((hotel, key) =>
                                                    <div style={{ borderTop: key ? '1px solid lightgrey' : '' }} key={key} className='py-2 px-3 my-1 table-row-exec-report'>
                                                        <div className='w-100 text-truncate d-flex align-items-center' style={{ minWidth: '300px' }}>
                                                            <div className='text-truncate d-flex align-items-center' style={{ minWidth: '210px', maxWidth: '210px' }}>
                                                                <div className='text-truncate cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesProcessExecutiveReportMonthly", hotel.hotelId)}>
                                                                    {global.hotelList?.find(({value}) => value === hotel.hotelId)?.label?? hotel.hotelId??'-'}
                                                                </div>
                                                            </div>
                                                            <div className='text-muted d-flex align-items-center ml-2'>
                                                                <div id={`HotelDeals-${key}`} className='text-truncate mr-2' style={{ minWidth: '25px', maxWidth: '25px' }}>{hotel.numberOfDeals}</div>
                                                                <div id={`HotelShare-${key}`} className='text-truncate' style={{ minWidth: '65px', maxWidth: '65px' }}>{`${hotel.dealShare}%`}</div>
                                                            </div>
                                                        </div>
                                                        <CoolTooltip placement="top" target={`HotelDeals-${key}`}>
                                                            <div><FormattedMessage id="SalesProcess.TotalNumberOfDeals" /></div>
                                                        </CoolTooltip>
                                                        <CoolTooltip placement="top" target={`HotelShare-${key}`}>
                                                            <div><FormattedMessage id="SalesProcess.TotalConversionRate" /></div>
                                                        </CoolTooltip>
                                                        {totalStageMetrics.map((s, key) => {
                                                            const stage = hotel.stagesTypes.find(({ stageTypeId }) => s.stageTypeId === stageTypeId);
                                                            return (
                                                                <div key={key} className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '250px' }}>
                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                        <div className='text-center text-truncate' style={{ width: "20%", fontSize: "0.9em" }}>
                                                                            <span>
                                                                                {stage?.numberOfLodging??'0'}
                                                                            </span>
                                                                        </div>
                                                                        <div className='text-center text-truncate' style={{ width: "40%", fontSize: "0.9em" }}>
                                                                            <FormatAmountNumber value={stage ? stage[selectedRevenue] ? stage[selectedRevenue] : '0' : '0'} currency={"EUR"} hideDecimals={true} />
                                                                        </div>
                                                                        <div className='text-center text-truncate' style={{ width: "20%", fontSize: "0.9em" }}>
                                                                            <span className='cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesDealList", hotel.hotelId, s.stageTypeId, null, null, null, ["WaitingHotel", "WaitingClient", "Finished", "Effective"])}>
                                                                                {stage?.numberOfDeals??'0'}
                                                                            </span>
                                                                        </div>
                                                                        <div className='text-center text-truncate' style={{ width: "20%", fontSize: "0.9em" }}>
                                                                            {stage?.conversionRate??'0'}%
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        )}
                                                        <div className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '200px' }}>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <div className='text-center text-truncate w-100'>
                                                                    {hotel.totalBudget.numberOfLodging ?? '-'}
                                                                </div>
                                                                <div className='text-center text-truncate w-100'>
                                                                    <FormatAmountNumber value={hotel.totalBudget[selectedRevenue]} hideDecimals={true} currency={"EUR"}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '200px' }}>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <div className='text-center text-truncate w-100'>
                                                                    {this.calcDiff("numberOfLodging", hotel.totalBudget.numberOfLodging, hotel.stagesTypes, false)}
                                                                </div>
                                                                <div className='text-center text-truncate w-100'>
                                                                    {this.calcDiff(selectedRevenue, hotel.totalBudget[selectedRevenue], hotel.stagesTypes, true)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <div style={{ borderTop: '1px solid lightgrey' }} className='py-2 px-3 my-1 table-row-exec-report'>
                                                    <div className='w-100 text-truncate d-flex align-items-center justify-content-between' style={{ minWidth: '300px' }}>
                                                        <div className='text-truncate d-flex align-items-center'>
                                                            <FormattedMessage id="SalesProcess.Total"/>
                                                        </div>
                                                    </div>
                                                    {totalStageMetrics.map((stage, key) =>
                                                        <div key={key} className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '250px' }}>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <div className='text-center text-truncate' style={{ width: "20%", fontSize: "0.9em" }}>
                                                                    <span>
                                                                        {stage.revenueMetrics.numberOfLodging??0}
                                                                    </span>
                                                                </div>
                                                                <div className='text-center text-truncate' style={{ width: "40%", fontSize: "0.9em" }}>
                                                                    <FormatAmountNumber value={stage.revenueMetrics[selectedRevenue] ?? 0} currency={"EUR"} hideDecimals={true} />
                                                                </div>
                                                                <div className='text-center text-truncate' style={{ width: "20%", fontSize: "0.9em" }}>
                                                                    <div className='cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesDealList", hotelList, stage.stageTypeId)}>
                                                                        {stage.deals.numberOfDeals??0}
                                                                    </div>
                                                                </div>
                                                                <div className='text-center text-truncate' style={{ width: "20%", fontSize: "0.9em" }}>
                                                                    <div>{stage.deals.conversionRate??0}%</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '200px' }}>
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <div className='text-center text-truncate w-100'>
                                                                {yearlyReport.hotels.reduce((acc, cur) => acc + (cur.totalBudget.numberOfLodging ?? 0), 0)}
                                                            </div>
                                                            <div className='text-center text-truncate w-100'>
                                                                <FormatAmountNumber value={yearlyReport.hotels.reduce((acc, cur) => acc + (cur.totalBudget[selectedRevenue] ?? 0), 0)} hideDecimals={true} currency={"EUR"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='w-100 text-truncate' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '200px' }}>
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <div className='text-center text-truncate w-100'>
                                                                {this.calcDiff("numberOfLodging", yearlyReport.hotels.reduce((acc, cur) => acc + (cur.totalBudget.numberOfLodging ?? 0), 0), null, false)}
                                                            </div>
                                                            <div className='text-center text-truncate w-100'>
                                                                {this.calcDiff(selectedRevenue, yearlyReport.hotels.reduce((acc, cur) => acc + (cur.totalBudget[selectedRevenue] ?? 0), 0), null, true)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col className='col-4 mt-4'>
                                    <Card className='shadow bg-white border-0 p-3 h-100 overflow-auto scrollableDiv' style={{ borderRadius: '5px' }}>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='text-muted'>
                                                <i className={`fas fa-code-branch mr-2`} />
                                                <FormattedMessage id="SalesProcess.SourceData" />
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <div className='d-flex align-items-center justify-content-between px-3 py-1' style={{ borderRadius: '4px', fontWeight: '600' }}>
                                                <div className='w-100' style={{ minWidth: '100px' }}>
                                                    <div className='text-truncate'>
                                                        <FormattedMessage id="SalesProcess.Source" />
                                                    </div>
                                                </div>
                                                <div className='w-100' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '50px' }} >
                                                    <div className='text-truncate text-center'>
                                                        <FormattedMessage id="SalesProcess.TotalDeals" />
                                                    </div>
                                                </div>
                                                <div className='w-100' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '50px' }}>
                                                    <div className='text-truncate text-center'>
                                                        <FormattedMessage id="SalesProcess.WonDeals" />
                                                    </div>
                                                </div>
                                                <div className='w-100' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '50px' }}>
                                                    <div className='text-truncate text-center'>
                                                        <FormattedMessage id="SalesProcess.TotalValue" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                {groupedSources && Object.entries(groupedSources).map(([sourceTypeId, sourceData], key) => (
                                                    <div style={{ borderTop: key ? '1px solid lightgrey' : '' }} key={key} className='py-2 px-3 my-1 table-row-exec-report'>
                                                        <div className='w-100 text-truncate' style={{ minWidth: '100px' }}>
                                                            {sourceData.sourceName}
                                                        </div>

                                                        <div className='w-100 text-truncate text-center cursor-pointer hrefStyle' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '50px' }} onClick={_ => toggleTab("SalesDealList", hotelList, null, null, null, null, null, null, sourceTypeId)}>
                                                            {sourceData.totalDeals ?? '0'}
                                                        </div>

                                                        <div className='w-100 text-truncate text-center cursor-pointer hrefStyle' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '50px' }} onClick={_ => toggleTab("SalesDealList", hotelList, null, null, null, null, ["Finished", "Effective"], null, sourceTypeId)}>
                                                            {sourceData.totalWonDeals ?? '0'}
                                                        </div>

                                                        <div className='w-100 text-truncate text-center' style={{ borderLeft: '1px solid #a5a5a5', minWidth: '50px' }}>
                                                            <FormatAmountNumber value={sourceData.totalValue ?? '0'} currency={"EUR"} hideDecimals={true} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col className='col-8 mt-4'>
                                    <Card className='shadow bg-white border-0 p-3 h-100' style={{ borderRadius: '5px' }}>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='text-muted'>
                                                <i className="fas fa-chart-line mr-2" />
                                                <FormattedMessage id="SalesProcess.ConversionperStage" />
                                            </div>
                                        </div>
                                        {funnelData && funnelData.length > 0 ?
                                            <div>
                                                <CustomFunnel
                                                    data={funnelData}
                                                />
                                            </div>
                                            :
                                            <div className='d-flex align-items-center justify-content-center mt-3' style={{ height: '100%' }}>
                                                <FormattedMessage id="SalesProcess.NoDealsDesc" />
                                            </div>
                                        }
                                    </Card>
                                </Col>

                                <Col className='col-4 mt-4'>
                                    <Card className='shadow bg-white border-0 p-3 h-100' style={{ borderRadius: '5px' }}>
                                        <div className='d-flex align-items-center justify-content-between mb-4'>
                                            <div className='text-muted'>
                                                <i className="fas fa-dollar-sign mr-2"></i>
                                                <FormattedMessage id="SalesProcess.CancelReasons" />
                                            </div>
                                        </div>
                                        {chartData && chartData.datasets[0].data.length > 0 ?
                                            <div>
                                                <Doughnut
                                                    data={chartData}
                                                    height={250}
                                                    options={optionsPieCancel}
                                                />
                                            </div>
                                            :
                                            <div className='d-flex align-items-center justify-content-center mt-3' style={{ height: '100%' }}>
                                                <FormattedMessage id="SalesProcess.NoCancelReasons" />
                                            </div>
                                        }
                                    </Card>
                                </Col>
                                <Col className='col-4 mt-4'>
                                    <Card className='shadow bg-white border-0 p-3 h-100' style={{ borderRadius: '5px' }}>
                                        <div className='d-flex align-items-center justify-content-between mb-4'>
                                            <div className='text-muted'>
                                                <i className="fas fa-dollar-sign mr-2"></i>
                                                <FormattedMessage id="SalesProcess.TypeDistribution" />
                                            </div>
                                        </div>
                                        {pieDataRevenue && pieDataRevenue.datasets[0].data.length > 0 ?
                                            <div>
                                                <Doughnut
                                                    data={pieDataRevenue}
                                                    height={250}
                                                    options={optionsPieRevenue}
                                                />
                                            </div>
                                            :
                                            <div className='d-flex align-items-center justify-content-center mt-3' style={{ height: '100%' }}>
                                                <FormattedMessage id="SalesProcess.NoDealsDesc" />
                                            </div>
                                        }
                                    </Card>
                                </Col>
                                <Col className='col-4 mt-4'>
                                    <Card className='shadow bg-white border-0 p-3 h-100' style={{ borderRadius: '5px' }}>
                                        <div className='d-flex align-items-center justify-content-between mb-4'>
                                            <div className='text-muted'>
                                                <i className="fas fa-dollar-sign mr-2"></i>
                                                <FormattedMessage id="SalesProcess.SegmentsDistribution" />
                                            </div>
                                        </div>

                                        {segmentChart?.length > 0 ?
                                            <Chart
                                                chartType="TreeMap"
                                                width="100%"
                                                height="250px"
                                                data={segmentChart}
                                                options={{
                                                    minColor: "#D6EDFE",
                                                    midColor: "#75AEFC",
                                                    maxColor: "#0867FA",
                                                    headerHeight: 20,
                                                    fontColor: "black",
                                                    showScale: true,
                                                    headerColor: '#D6EDFE',
                                                    fontFamily: "Poppins, sans-serif",
                                                    generateTooltip: (row, size, value) => {
                                                        return (
                                                            '<div style="background: white; padding: 5px; font-size: 0.8em" class="shadow">  Total: ' + parseFloat(size).toFixed(2) + '</div>'
                                                        )
                                                    },
                                                }}
                                            />
                                            :
                                            <div className='d-flex align-items-center justify-content-center mt-3' style={{ height: '100%' }}>
                                                <FormattedMessage id="SalesProcess.NoSegments" />
                                            </div>
                                        }
                                    </Card>
                                </Col> 
                            </Row>
                        :''}
                    </div>
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(SalesProcessExecutiveReportYearly);