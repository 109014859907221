import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import { SelectHotel, StyledCard } from "../Base/CommonUIComponents";
import { handleNotification } from '../Base/Notification';
import { Button, Col, Row, Badge } from 'reactstrap';

import { CustomTable } from '../Base/CustomTable';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { getFormSource } from '../Base/ReferenceDataFunctions';
import CustomSelect from '../Base/CustomSelect';
import { Pagination, TotalResults } from '../Base/PaginationComponents';
import { Link } from 'react-router-dom';
import { format } from 'ol/coordinate';

export class GoContactUser extends Component {

    constructor(props) {
        super(props);

        const states = [
            {
                value: 'active',
                label: <FormattedMessage id="GoContactUser.Active" />,
                badge: 'success'
            },
            {
                value: 'inactive',
                label: <FormattedMessage id="GoContactUser.Inactive" />,
                badge: 'secondary'
            },
            {
                value: 'blocked',
                label: <FormattedMessage id="GoContactUser.Blocked" />,
                badge: 'secondary'
            },
            {
                value: 'pending',
                label: <FormattedMessage id="GoContactUser.Pending" />,
                badge: 'primary'
            },
            {
                value: 'deleted',
                label: <FormattedMessage id="GoContactUser.Deleted" />,
                badge: 'danger'
            }
        ];

        this.state = {
            block: true,
            status: states[0].value,
            role: null,
            userList: [],
            states: states    
        };
    }

    componentDidMount() {
        this.getContact()
    }

    getContact = () => {
        const { status, role } = this.state;
        const queryParams = new URLSearchParams();
        if (status) {
            queryParams.append('status', status);
        }

        if (role) {
            queryParams.append('role', role);
        }

        const queryString = queryParams.toString();

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                if (data.data) {
                    this.setState({ userList: data.data, error: errorMessage, block: false });
                }
                else {
                    this.setState({ userList: [], error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/GoContact/v1/Users?${queryString}`);
    }

    setCombo = (name, combo) => {
        this.setState({ [name]: combo && combo.value });
    }

    render() {
        const { block, error, role, status, userList, states } = this.state;

        const roles = [
            {
                value: 'admin',
                label: <FormattedMessage id="GoContactUser.Admin" />
            },
            {
                value: 'agent',
                label: <FormattedMessage id="GoContactUser.Agent" />
            },
            {
                value: 'quality',
                label: <FormattedMessage id="GoContactUser.Quality" />
            },
            {
                value: 'reports',
                label: <FormattedMessage id="GoContactUser.Reports" />
            }
        ];

        const columns = [
            {
                dataField: 'user_id',
                text: this.props.intl.formatMessage({ id: "GoContactUser.Id" }),
                style: { width: '25px' }
            },
            {
                dataField: 'username',
                text: this.props.intl.formatMessage({ id: "GoContactUser.Username" }),
                style: { width: '50px' }
            },
            {
                dataField: 'full_name',
                text: this.props.intl.formatMessage({ id: "GoContactUser.Fullname" }),
                style: { width: '120px' }
            },
            {
                dataField: 'role',
                text: this.props.intl.formatMessage({ id: "GoContactUser.Role" }),
                style: { width: '40px' },
                formatter: cell => cell ? roles.find(rl => rl.value === cell)?.label : ''
            },
            {
                dataField: 'group_name',
                text: this.props.intl.formatMessage({ id: "GoContactUser.Group" }),
                style: { width: '50px' }
            },
            {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: "GoContactUser.Status" }),
                style: { width: '50px' },
                formatter: (cell) => {
                    if (cell) {
                        const statusOpt = states.find(stt => stt.value === cell);
                        if (statusOpt) {
                            return <Badge className="m-1 p-1" color={statusOpt.badge}>{statusOpt.label}</Badge>
                        }
                        else {
                            return <Badge className="m-1"> {cell} </Badge>
                        }
                    }
                    return '';
                },
            },
            {
                dataField: 'email',
                text: this.props.intl.formatMessage({ id: "GoContactUser.Email" }),
                style: { width: '50px' }
            },
            {
                dataField: 'register_date',
                text: this.props.intl.formatMessage({ id: "GoContactUser.CreateAt" }),
                style: { width: '50px' },
                formatter: cell => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : ''
            },
            {
                dataField: 'last_update',
                text: this.props.intl.formatMessage({ id: "GoContactUser.UpdatedAt" }),
                style: { width: '50px' },
                formatter: cell => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : ''
            }
        ];

        return (
            <StyledCard block={block} error={error} icon="far fa-user" title="GoContactUser.User">
                <Row className="mb-3">
                    <Col className="col-3">
                        <CustomSelect
                            placeholder={<FormattedMessage id="GoContactUser.Role" />}
                            options={roles}
                            value={roles.find(rl => rl.value === role)}
                            onChange={this.setCombo.bind(this, 'role')}
                            isClearable
                            isSearchable
                        />
                    </Col>
                    <Col className="col-3">
                        <CustomSelect
                            placeholder={<FormattedMessage id="GoContactUser.Status" />}
                            options={states}
                            value={states.find(st => st.value === status)}
                            onChange={this.setCombo.bind(this, 'status')}
                            isClearable
                            isSearchable
                        />
                    </Col>
                    <Col className="text-right" >
                        <Button className="btn btn-sm btn-host" onClick={_ => this.setState({ block: true }, this.getContact)}>
                            <i className="fa fa-search" />
                        </Button>
                    </Col>
                </Row>
                <CustomTable
                    data={userList}
                    columns={columns}
                    rowStyle={{ cursor: 'pointer' }}
                    hasPagination={true}
                    sizePerPage={10}
                    showTotal={true}
                    search={true}
                />
            </StyledCard>
        );
    }
}
export default injectIntl(GoContactUser)