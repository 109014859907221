import React, { useEffect, useState } from 'react';
import { Row, Col, Badge, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import CustomSelect from '../Base/CustomSelect';
import { CheckAuthorization } from '../Base/Authorization';
import moment from 'moment-timezone';
import { CoolBadge } from '../Base/CommonUIComponents';


export function getSalesSourceTypes(intl) {
    return [
        { value: 'Event', label: intl.formatMessage({ id: "ReferenceDataFunctions.Event" }) },
        { value: 'Form', label: intl.formatMessage({ id: "ReferenceDataFunctions.Form" }) },
        { value: 'Partner', label: intl.formatMessage({ id: "ReferenceDataFunctions.Partner" }) },
        { value: 'Direct', label: intl.formatMessage({ id: "ReferenceDataFunctions.Direct" }) },
        { value: 'Renegotiation', label: intl.formatMessage({ id: "ReferenceDataFunctions.Renegotiation" }) },
    ]
}

export function getSalesConfigTypes(intl) {
    return [
        { value: 'HotelGroup', label: intl.formatMessage({ id: "ReferenceDataFunctions.HotelGroup" }) },
        { value: 'Brand', label: intl.formatMessage({ id: "ReferenceDataFunctions.Brand" }) },
        { value: 'Hotel', label: intl.formatMessage({ id: "ReferenceDataFunctions.Hotel" }) },
    ]
}

export function getSalesPipelineTypes(intl) {
    return [
        { value: 'Event', label: intl.formatMessage({ id: "ReferenceDataFunctions.Event" }) },
        { value: 'GroupReservation', label: intl.formatMessage({ id: "ReferenceDataFunctions.GroupReservation" }) },
        { value: 'Generic', label: intl.formatMessage({ id: "ReferenceDataFunctions.Generic" }) }
    ]
}

export function getSalesPipelineStageTypes(intl) {
    return [
        { value: 'Lead', label: intl.formatMessage({ id: "ReferenceDataFunctions.Lead" }) },
        { value: 'DealProcess', label: intl.formatMessage({ id: "ReferenceDataFunctions.DealProcess" }) },
        { value: 'Effective', label: intl.formatMessage({ id: "ReferenceDataFunctions.Effective" }) }
    ]
} //remover aqui

export function getDealStatus(intl) {
    return [
        { value: 'WaitingClient', label: intl.formatMessage({ id: "ReferenceDataFunctions.WaitingClient" }), color: 'primary' },
        { value: 'WaitingHotel', label: intl.formatMessage({ id: "ReferenceDataFunctions.WaitingHotel" }), color: 'primary' },
        { value: 'Cancelled', label: intl.formatMessage({ id: "ReferenceDataFunctions.Cancelled" }), color: 'danger' },
        { value: 'Finished', label: intl.formatMessage({ id: "ReferenceDataFunctions.Finished" }), color: 'success' }
        // { value: 'Effective', label: intl.formatMessage({ id: "ReferenceDataFunctions.Effective" }) }
    ]
}

export function getInitiativeStatus(intl) {
    return [
        { value: 'Done', label: intl.formatMessage({ id: "ReferenceDataFunctions.Done" }), color: 'success' },
        { value: 'OnGoing', label: intl.formatMessage({ id: "ReferenceDataFunctions.OnGoing" }), color: 'primary' },
        { value: 'Scheduled', label: intl.formatMessage({ id: "ReferenceDataFunctions.Scheduled" }), color: 'primary' },
        { value: 'Cancelled', label: intl.formatMessage({ id: "ReferenceDataFunctions.Cancelled" }), color: 'danger' }
    ]
}

export function getInitiativeType(intl) {
    return [
        { value: 'Trip', label: intl.formatMessage({ id: "ReferenceDataFunctions.Trip" }) },
        { value: 'Prospect', label: intl.formatMessage({ id: "ReferenceDataFunctions.Prospect" })},
        { value: 'AccountMaintenance', label: intl.formatMessage({ id: "ReferenceDataFunctions.AccountMaintenance" })},
        { value: 'Conversion', label: intl.formatMessage({ id: "ReferenceDataFunctions.Conversion" })},
        { value: 'Opening', label: intl.formatMessage({ id: "ReferenceDataFunctions.Opening" })},
        { value: 'Reconversion', label: intl.formatMessage({ id: "ReferenceDataFunctions.Reconversion" }) },
    ]
}

export function getInitiativeActionType(intl) {
    return [
        { value: 'SiteVisits', label: intl.formatMessage({ id: "ReferenceDataFunctions.SiteVisits" })},
        { value: 'Charm', label: intl.formatMessage({ id: "ReferenceDataFunctions.Charm" })},
        { value: 'SalesCalls', label: intl.formatMessage({ id: "ReferenceDataFunctions.SalesCalls" })},
        { value: 'Workshop', label: intl.formatMessage({ id: "ReferenceDataFunctions.Workshop" })},
        { value: 'Roadshow', label: intl.formatMessage({ id: "ReferenceDataFunctions.Roadshow" })},
        { value: 'Fair', label: intl.formatMessage({ id: "ReferenceDataFunctions.Fair" })},
        { value: 'Forum', label: intl.formatMessage({ id: "ReferenceDataFunctions.Forum" })},
    ]
}

export function getLeadStatus(intl) {
    return [
        { value: 'WaitingClient', label: intl.formatMessage({ id: "SalesProcess.InNegotiation" }) },
        { value: 'Cancelled', label: intl.formatMessage({ id: "SalesProcess.Cancelled" }) },
        { value: 'Finished', label: intl.formatMessage({ id: "SalesProcess.Finished" }) }
    ]
}

export function getUsersRoles(intl) {
    return [
        { value: 'Responsible', label: intl.formatMessage({ id: "ReferenceDataFunctions.Responsible" }) },
        { value: 'Assistant', label: intl.formatMessage({ id: "ReferenceDataFunctions.Assistant" }) },
        { value: 'Related', label: intl.formatMessage({ id: "ReferenceDataFunctions.Related" }) }
    ]
}

export function getUsersRoleType(intl){ 
    return [
        { value: 'CentralUser', label: intl.formatMessage({ id: "ReferenceDataFunctions.CentralUser" }) },
        { value: 'HelpDeskUser', label: intl.formatMessage({ id: "ReferenceDataFunctions.HelpDeskUser" }) },
        { value: 'Manager', label: intl.formatMessage({ id: "ReferenceDataFunctions.Manager" }) },
        { value: 'SalesRep', label: intl.formatMessage({ id:"ReferenceDataFunctions.SalesRep" }) },
        { value: 'Admin', label: intl.formatMessage({ id:"ReferenceDataFunctions.Admin" }) },
    ]
}

export function getCustomTypeFixedValues(intl) {        
    return [
        { value: 'Duplicate', label: intl.formatMessage({ id: "ReferenceDataFunctions.Duplicate" }) },
        { value: 'Won', label: intl.formatMessage({ id: "ReferenceDataFunctions.Won" }) },
        { value: 'Lost', label: intl.formatMessage({ id: "ReferenceDataFunctions.Lost" }) },
        { value: 'Negotiation', label: intl.formatMessage({ id: "ReferenceDataFunctions.Negotiation" }) },
        { value: 'Agency', label: intl.formatMessage({ id: "ReferenceDataFunctions.Agency" }) },
        { value: 'Company', label: intl.formatMessage({ id: "ReferenceDataFunctions.Company" }) },
        { value: 'Effective', label: intl.formatMessage({ id: "ReferenceDataFunctions.Effective" }) }
    ]
}

export function getCommissionTypes(intl) {
    return [
        { value: 'FB', label: intl.formatMessage({ id: "ReferenceDataFunctions.FB" }) },
        { value: 'Lodging', label: intl.formatMessage({ id: "ReferenceDataFunctions.Lodging" }) },
        { value: 'Others', label: intl.formatMessage({ id: "ReferenceDataFunctions.Others" }) },
        { value: 'Total', label: intl.formatMessage({ id: "ReferenceDataFunctions.Total" }) }
    ]
}


export function getSalesProcessUsers(cb) {
    getAPI(result => {
        const { data, error } = result;
        if (error) {
            var errorMessage = [];
            errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

            return;
        }
        if (data) {
            global.salesProcessUsers = data.data?.map(u => ({
                value: u.id,
                label: `${u.firstName} ${u.lastName}`,
                isCurrentUser: u.userId === global.userId,
                hotelList: u.hotelIds,
                ...u
            }))

            if (cb) {
                cb();
            }
        }
    }, '/api/gms/SalesProcess/v1/salesprocessusers?pageSize=1000&pageIndex=0');
}

const customTypeCache = {};

export const SelectCustomType = ({ icon, name, onChangeFunc, isMulti = false, required, value, placeholder, isDisabled, isClearable = true, isSearchable=true, type }) => {

    const [data, setData] = useState([]);
    const [block, setLoad] = useState(true);
    const [error, setError] = useState('');
    
    useEffect(() => {
        window.setTimeout(() => {
            if (customTypeCache[type]) {
                if (Array.isArray(customTypeCache[type])) {
                    setData(customTypeCache[type]);
                    setLoad(false);
                } else {
                    customTypeCache[type]
                        .then(cachedData => {
                            setData(cachedData);
                        })
                        .catch(err => {
                            setError(err.message || "Error loading data");
                        })
                        .finally(() => {
                            setLoad(false);
                        });
                }
                return;
            }
    
            const fetchData = new Promise((resolve, reject) => {
                getAPI(result => {
                    const { data, error } = result;
                    if (error) {
                        const errorMessage = [{ 
                            message: error.message, 
                            stack: error.stack, 
                            messageType: 'danger' 
                        }];
                        setError(errorMessage);
                        reject(error);
                        return;
                    }
                    if (data?.data?.length > 0) {
                        const combo = data.data.map(el => ({
                            label: el.name,
                            value: el.id
                        }));
                        resolve(combo);
                    } else {
                        resolve([]);
                    }
                }, `/api/gms/SalesProcess/v1/customtypes?type=${type}`);
            });

            customTypeCache[type] = fetchData;

            fetchData
                .then(cachedData => {
                    customTypeCache[type] = cachedData;
                    setData(cachedData);
                })
                .catch(err => {
                    setError(err.message || "Error loading data");
                })
                .finally(() => {
                    setLoad(false);
                });
        }, global.modules ? 0 : 2500);
    }, [type]);

    return (
        <CustomSelect
            icon={icon}
            name={name}
            options={data || []}
            required={required}
            isClearable={isClearable}
            isMulti={isMulti}
            value={data ? data.filter(el => Array.isArray(value) ? value.find(v => el.value == v.id) : el.value == value) : ''}
            placeholder={placeholder}
            onChange={onChangeFunc.bind(this)}
            isDisabled={isDisabled}
            isLoading={block}
            isSearchable={isSearchable}
        /> 
    );    
};

export const SalesProcessCoolSelectHotel = ({ icon, required, isClearable, isMulti, value, placeholder, onChangeFunc, name, isDisabled, block, isSingleCurrency, handleLoc, handleSelectAll, allGroupHotels = null, isLead }) => {
    const [hotelList, setHotelList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [selectAllBtnPressed, setSelectAllBtnPressed] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const currentUser = global.salesProcessUsers?.find(u => u.isCurrentUser);

        if ((CheckAuthorization("salesProcess:all") || currentUser) && global.hotelListFromGroup) {
            if (allGroupHotels) { //Ignore role and show all hotels from group (used in deals, lead ans initiative modal)
                if (allGroupHotels.length > 0) {
                    const cityList = Array.from(
                        new Set(allGroupHotels.map(h => h.city).filter(city => city))
                    ).map(city => ({ city, isSelected: false }));

                    setCityList(cityList);
                    setHotelList(allGroupHotels);
                }
                else {
                    const cityList = Array.from(
                        new Set(global.hotelListFromGroup.map(h => h.city).filter(city => city))
                    ).map(city => ({ city, isSelected: false }));
   
                    setHotelList(hotelList);
                    setCityList(cityList);
                }
            }
        }
        setLoading(false);
    }, []);

    const toggleSelectAllBtn = (bool) => {
        if (cityList?.length > 0) {
            setCityList(cityList.map(city => ({ ...city, isSelected: bool })));
        }

        handleSelectAll(bool);
        setSelectAllBtnPressed(bool);
    }

    return (
        <div>
            <div className='d-flex align-items-end'>
                <div
                    className={`title-sm ${isLead ? '' : 'required'} mr-2`}
                    style={{ minWidth: global.isMobile ? '110px'  :'130px' }}
                >
                    <FormattedMessage id="SalesDeal.RelatedHotels" />
                </div>
                <div
                    className="d-flex align-items-end justify-content-end"
                    style={{ width: isLead ? '100%' : '83%' }} 
                >
                    <div
                        style={{ flexShrink: 0 }}
                        className={`mr-2 text-truncate my-1 coolBadge smallBadge${selectAllBtnPressed ? ' selectedBadge' : ''}`}
                        onClick={() => toggleSelectAllBtn(!selectAllBtnPressed)}
                    >
                        <span><FormattedMessage id="SalesProcess.SelectAll" /></span>
                    </div>
                    <div
                        className='d-flex align-items-center veryCoolScrollBar'
                        style={{
                            overflowX: 'auto',
                            whiteSpace: 'nowrap',
                            transform: 'rotateX(180deg)',
                            width: global.isMobile ? '100px' : ''
                        }}
                    >
                        {cityList?.length > 0 ?
                            cityList.map((city, key) => (
                                <div
                                    key={`coolBadgeDiv-${key}`}
                                    className={`text-truncate my-1 coolBadge smallBadge${city.isSelected ? ' selectedBadge' : ''}`}
                                    style={{ flexShrink: 0, transform: 'rotateX(180deg)' }}
                                    onClick={() => handleLoc(city.city, cityList)}
                                >
                                    <span key={`coolBadge-${key}`}>{city.city}</span>
                                </div>
                            ))
                            : ''}
                    </div>
                </div>
            </div>
            <CustomSelect
                icon={icon}
                options={
                    value && value.length && isSingleCurrency ? 
                        hotelList.filter(x => Array.isArray(value) ? 
                            hotelList.find(y => y.value == value[0])?.currency == x.currency 
                        : 
                            hotelList.find(y => y.value == value)?.currency == x.currency) 
                    : 
                        hotelList
                }
                required={required}
                isClearable={isClearable}
                isMulti={isMulti}
                value={hotelList ? hotelList.filter(el => Array.isArray(value) ? value.find(v => el.value == v) : el.value == value) : ''}
                isSearchable
                placeholder={placeholder}
                onChange={(e) => onChangeFunc(name, e)}
                isDisabled={isDisabled}
                isLoading={block || loading}
            />
        </div>
    )
};

export const ProfileCard = ({ name, roleId, notes, handleListElement, removeElement }) => {
    const nameDivided = name?.split(' ');

    return (
        <div>
            {!global.isMobile ? 
                <Row className="align-items-center">
                    <Col className="d-flex align-items-center">
                        {nameDivided ?
                            <Badge className="profileBadge mr-2" style={{ height: '33px', width: '33px' }}>
                                {nameDivided[0].substr(0, 1).toUpperCase()}{nameDivided?.length > 1 ? nameDivided[nameDivided.length - 1].substr(0, 1).toUpperCase() : ''}
                            </Badge>
                            : ''}

                        <span style={{ "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", display: 'block' }}>
                            {name}
                        </span>
                    </Col>

                    <Col>
                        <SelectCustomType 
                            name="roleId"
                            onChangeFunc={(combo) => handleListElement('roleId', combo ? combo.value : null)}
                            required={true}
                            value={roleId}
                            placeholder=""
                            isDisabled={false}
                            isClearable={true}
                            type={'DealProfileRole'}
                        />
                    </Col>
                    <Col className="pr-0">
                        <Input
                            required
                            type="text"
                            name="notes"
                            placeholder=""
                            value={notes || ''}
                            onChange={(event) => handleListElement('notes', event.target.value)}
                            maxLength={500}
                        />
                    </Col>
                    <Col sm={1} className=" text-right pr-4">
                        <i className="fas fa-trash-alt text-danger pointer" onClick={removeElement} />
                    </Col>
                </Row>
            
            :
                <Row className="align-items-center">
                    <Col className='px-0 col-10'>
                        <Col className="col-12 d-flex align-items-center px-0">
                            <Col className="d-flex align-items-center col-6">
                                {nameDivided ?
                                    <Badge className="profileBadge mr-2" style={{ height: '25px', width: '25px' }}>
                                        {nameDivided[0].substr(0, 1).toUpperCase()}{nameDivided?.length > 1 ? nameDivided[nameDivided.length - 1].substr(0, 1).toUpperCase() : ''}
                                    </Badge>
                                    : ''}

                                <span style={{ "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", display: 'block', fontSize: '0.9em' }}>
                                    {name}
                                </span>
                            </Col>

                            <Col className='col-6 pr-0'>
                                <SelectCustomType 
                                    name="roleId"
                                    onChangeFunc={(combo) => handleListElement('roleId', combo ? combo.value : null)}
                                    required={true}
                                    value={roleId}
                                    placeholder=""
                                    isDisabled={false}
                                    isClearable={true}
                                    type={'DealProfileRole'}
                                />
                            </Col>
                        </Col>
                        <Col className='col-3 text-muted'><FormattedMessage id="SalesDeal.Notes"/></Col>
                        <Col className="pr-0 col-12">
                            <Input
                                required
                                type="text"
                                name="notes"
                                placeholder=""
                                value={notes || ''}
                                onChange={(event) => handleListElement('notes', event.target.value)}
                                maxLength={500}
                            />
                        </Col>
                    </Col>
                    <Col className="text-right col-2">
                        <i className="fas fa-trash-alt text-danger pointer" onClick={removeElement} />
                    </Col>
                </Row>
            }
        </div>
    )
};

export const DealStatusBadge = ({ status, isLead, id }) => {
    const color = (status === "WaitingHotel" || status === "WaitingClient") ? "primary" : status === "Finished" ? "success" : status === "Cancelled" ? "danger" : '';

    return (
        isLead && ["WaitingClient", "WaitingHotel"].includes(status) ?
            <Badge id={id} color="primary" className="py-1 px-2" style={{ fontSize: '0.8em' }}>
                <FormattedMessage id={`SalesProcess.InNegotiation`} />
            </Badge>
            :
            <Badge id={id} color={color} className={"py-1 px-2 " + (status === "Effective" && ' bg-green ' )} style={{fontSize: '0.8em' }}>
                <FormattedMessage id={`SalesProcess.${status}`} />
            </Badge>
    )
}

export const InitiativeStatusBadge = ({ status, id }) => {
    const color = status === "OnGoing" ? "primary" : status === "Done" ? "success" : status === "Cancelled" ? "danger" : '';

    return (
        <Badge id={id} color={color} className={"py-1 px-2"} style={{ background: status === "Scheduled" ? 'lightgrey' : '', fontSize: '0.8em' }}>
            <FormattedMessage id={`SalesProcess.${status}`}/>
        </Badge>
    )
}

export const CustomFormatDate = (date, format, detailFormat, intl) => {
    const defaultFormat = `${intl?.locale === 'pt' ? 'DD/MM/YYYY' : 'YYYY/MM/DD'}${detailFormat ? ` ${detailFormat}` : ''}`;
    
    return date ? moment(date).format(format || defaultFormat) : '';
};

//#region Sales Process Users
export function getAllowedSalesProcessUsers(fromInitiative) {
    const usersList = global.salesProcessUsers;

    if (usersList) {
        const currentUser = usersList?.find(el => el.isCurrentUser);

        if (CheckAuthorization("salesProcess:all") || currentUser) {
            if (currentUser && (currentUser.role === 'Manager' || currentUser.role === 'SalesRep')) {
                if(fromInitiative){
                    const usersFiltered = usersList.filter(user => user.role === "CentralUser" || user.role === "Admin" || user.hotelIds?.some(hotel => global.hotelListFromGroup?.some(el => el.value === hotel) || !user.hotelIds || user.hotelIds?.length === 0))
                    return usersFiltered;
                }

                return usersList.filter(user => user.hotelIds?.some(hotel => global.hotelList?.some(el => el.value === hotel) || !user.hotelIds || user.hotelIds?.length === 0))
            }
            else {
                return usersList;
            }
        }
    }
};

export function getCurrentUser() {
    return global.salesProcessUsers?.find(el => el.isCurrentUser);
};

export const SalesProcessUsersCombo = ({ icon, required, isClearable, isMulti, value, placeholder, onChangeFunc, name, isDisabled, block, fromInitiative/*, setDefault*/ }) => {
    const [usersList, setUsersList] = useState();

    useEffect(() => {
        const list = getAllowedSalesProcessUsers(fromInitiative);

        setUsersList(list);

        /*if (setDefault) {
            const currentUser = list.find(el => el.isCurrentUser);

            if (isMulti) {
                onChangeFunc(name, [currentUser], true);
            }
            else {
                onChangeFunc(name, currentUser, true);
            }
        }*/
    }, [])


    return (
        <CustomSelect
            icon={icon}
            options={usersList}
            required={required}
            isClearable={isClearable}
            isMulti={isMulti}
            value={global.salesProcessUsers ? global.salesProcessUsers.filter(el => Array.isArray(value) ? value.find(v => el.value == v) : el.value == value) : ''}
            isSearchable
            placeholder={placeholder}
            onChange={(e) => onChangeFunc(name, e)}
            isDisabled={isDisabled}
            isLoading={block}
        />
    )
};

//#endregion

//#region Sales Process User Hotels
export const SalesProcessHotelCombo = ({ icon, required, isClearable, isMulti, value, placeholder, onChangeFunc, name, isDisabled, block, isSingleCurrency, dealHotelList, filterByDealHotels, allGroupHotels = null }) => {
    const [hotelList, setHotelList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const currentUser = global.salesProcessUsers?.find(u => u.isCurrentUser);

        if ((CheckAuthorization("salesProcess:all") || currentUser) && global.hotelListFromGroup) {
            if (allGroupHotels) { //Ignore role and show all hotels from group (used in deals, lead and initiative modal)
                if (allGroupHotels.length > 0) {
                    setHotelList(allGroupHotels);
                }
                else {
                    setHotelList(global.hotelListFromGroup);
                }
            }
            else if (currentUser && (currentUser.role === 'Manager' || currentUser.role === 'SalesRep') && currentUser.hotelIds) {
                if (filterByDealHotels) {
                    if (dealHotelList?.length > 0) {
                        setHotelList(dealHotelList.filter(hotel => currentUser.hotelIds.some(hotelId => hotelId === hotel.value)));
                    } else {
                        setHotelList([]);
                    }
                } else {
                    setHotelList(global.hotelListFromGroup.filter(hotel => currentUser.hotelIds.some(hotelId => hotelId === hotel.value)));
                }
            }
            else {
                setHotelList(global.hotelListFromGroup);
            }
        }
        setLoading(false);
    }, []);


    return (    
        <CustomSelect
            icon={icon}
            options={value && isSingleCurrency ? hotelList.filter(x => Array.isArray(value) ? hotelList.find(y => y.value == value[0])?.currency == x.currency : hotelList.find(y => y.value == value)?.currency == x.currency) : hotelList}
            required={required}
            isClearable={isClearable}
            isMulti={isMulti}
            value={hotelList ? hotelList.filter(el => Array.isArray(value) ? value.find(v => el.value == v) : el.value == value) : ''}
            isSearchable
            placeholder={placeholder}
            onChange={(e) => onChangeFunc(name, e)}
            isDisabled={isDisabled}
            isLoading={block || loading}
        />
    )
};
//#endregion


//#region User Access
const userPermissions = (componentHotelList, userHotelList, userId, componentUsersIds) => {
    return {
        'SalesProcessExecutiveReport': ['Admin', 'Manager', 'CentralUser', 'SalesRep'],
        'SalesInitiativeList': ['Admin', 'CentralUser', 'Manager', 'SalesRep'],
        'SalesInitiativeDetail': ['Admin', 'CentralUser', 'Manager', 'SalesRep'],
        'SalesSource': ['Admin'],
        'SalesPipeline': () => ['Admin'],
        'SalesLeads': ['Admin', 'SalesRep', 'Manager', 'CentralUser', 'HelpDeskUser'],
        'SalesPipelineStage': ['Admin', 'SalesRep', 'Manager', 'CentralUser', 'HelpDeskUser'],
        'SalesDealList': ['Admin', 'SalesRep', 'Manager', 'CentralUser', 'HelpDeskUser'],
        'SalesProcessGrid': ['Admin', 'CentralUser', 'Manager', 'SalesRep'],
        'SalesProcessTasks': ['Admin', 'CentralUser', 'Manager', 'SalesRep'],
        'SalesDealUsers': ['Admin', 'CentralUser', 'Manager', 'SalesRep', 'HelpDeskUser'],
        'SalesDealUsers:edit': () => {
            let roles = ['Admin'];

            if (componentUsersIds?.some(el => el.value === userId)) {
                roles = roles.concat(['CentralUser', 'Manager', 'SalesRep', 'HelpDeskUser']);
            }

            return roles;
        },
        'SalesDealUsers:manageAll': ['Admin'],
        'SalesProcessSettings': ['Admin', 'CentralUser', 'Manager', 'SalesRep'],
        'SalesProcessSettings:actions': ['Admin'],
        'SalesProcessCustomization': ['Admin'],
        'SalesProcessCityEvents': ['Admin', 'SalesRep', 'Manager', 'CentralUser'],
        'SaveCityEvents': ['Admin', 'CentralUser', 'Manager', 'SalesRep'],
        'SalesProcessSpaceManagement': ['Admin', 'SalesRep', 'Manager', 'CentralUser', 'HelpDeskUser'],
        'SetSpaceVisibility': ['Admin', 'Manager', 'CentralUser'],
        'SalesBudget': ['Admin'],
        'SalesLead:detail': () => {
            let roles = ['Admin', 'CentralUser', 'HelpDeskUser'];

            if (componentHotelList?.some(h => userHotelList.some(hotelId => hotelId === h.value)) && componentUsersIds?.some(el => el.value === userId)) {
                roles = roles.concat(['Manager', 'SalesRep']);
            }

            return roles;
        },
        'SalesDealDetail': () => {
            let roles = ['Admin', 'CentralUser', 'HelpDeskUser'];

            if (componentHotelList?.some(h => userHotelList.some(hotelId => hotelId === h.value)) && componentUsersIds?.some(el => el.value === userId)) {
                roles = roles.concat(['Manager', 'SalesRep']);
            }

            return roles;
        },
        'SalesDeal:detail': () => {
            let roles = ['Admin', 'CentralUser', 'HelpDeskUser'];

            if (componentHotelList?.some(h => userHotelList.some(hotelId => hotelId === h)) || componentUsersIds?.some(el => el.userId === userId)) {
                roles = roles.concat(['Manager', 'SalesRep']);
            }

            return roles;
        },
        'SalesDealDetail:proposal': () => {
            let roles = [];

            if (componentHotelList?.some(hotelId => userHotelList?.includes(hotelId))) {
                roles = roles.concat(['Manager', 'SalesRep']);
            }

            return roles;
        },
        'ImportDeals': ['Manager', 'SalesRep'],

        //Profile
        'ProfileDetails:deals': ['Admin', 'CentralUser', 'Manager', 'SalesRep', 'HelpDeskUser'],
        'ProfileDetails:deals:details': () => {
            let roles = ['Admin', 'CentralUser', 'HelpDeskUser'];
            if (componentHotelList?.some(h => userHotelList.some(hotelId => hotelId === h)) || componentUsersIds?.some(el => el.userId === userId)) {
                roles = roles.concat(['Manager', 'SalesRep']);
            }

            return roles;
        },
        'ProfileDetails:dealActions': ['Admin', 'CentralUser', 'Manager', 'SalesRep', 'HelpDeskUser'],
        'ProfileDetails:dealActions:details': () => {
            let roles = ['Admin', 'CentralUser', 'HelpDeskUser'];

            if (componentHotelList?.some(h => userHotelList.some(hotelId => hotelId === h)) && componentUsersIds?.some(el => el.userId === userId)) {
                roles = roles.concat(['Manager', 'SalesRep']);
            }

            return roles;
        },
        'ProfileDetails:initiatives': ['Admin', 'CentralUser', 'Manager', 'SalesRep'],
        'ProfileDetails:initiatives:details': ['Admin', 'CentralUser', 'Manager', 'SalesRep'],
    }
}

export const checkSalesProcessPermission = (name, role, componentHotelList, userHotelList, componentUsersIds, userId) => {
    if (CheckAuthorization("salesProcess:all")) {
        return true;
    }
    else {
        var pagePermissions = userPermissions(componentHotelList, userHotelList, userId, componentUsersIds)[name];

        if (pagePermissions && !Array.isArray(pagePermissions)) {
            pagePermissions = pagePermissions();
        }
        
        return pagePermissions?.some(el => el === role);
    }
}
//#endregion



//#region Files Repo
export function getMasterTemplateFileType() {
    return [
        { value: 'PDF', label: "PDF", icon: 'far fa-file-pdf text-danger' },
        { value: 'HTML', label: "HTML", icon: 'far fa-file-code text-secondary' },
        { value: 'XLS', label: "XLS", icon: 'far fa-file-excel text-success' },
        { value: 'DOCX', label: "DOCX", icon: 'far fa-file-word text-primary' }
    ]
}

export function getMasterTemplateType(intl) {
    return [
        { value: 'ProForma', label: intl.formatMessage({ id: "ReferenceDataFunctions.ProForma" }) },
        { value: 'Proposal', label: intl.formatMessage({ id: "ReferenceDataFunctions.Proposal" }) },
        { value: 'FactSheet', label: intl.formatMessage({ id: "ReferenceDataFunctions.FactSheet" }) },
        { value: 'Contract', label: intl.formatMessage({ id: "ReferenceDataFunctions.Contract" }) }
    ]
}
//#endregion


//#region Deal Tags
export const RenderDealTags = ({ dealTagList, selectedTags, handleDealTagChange, classString }) => {
    const [toggledTags, setToggledTags] = useState(false);
    const [showTagButtons, setShowTagButtons] = useState(null);

    useEffect(() => {
        if (dealTagList?.length > 0 && showTagButtons === null) {
            var container = document.getElementById("tagContainer");

            setShowTagButtons(container.scrollHeight > container.offsetHeight + 5);
        }
    }, [dealTagList]);
    
    return (
        dealTagList && dealTagList.length > 0 ?
            <Col className={classString}>
                <div className={`title-sm`}> <FormattedMessage id="SalesProcess.DealTag" /></div>
                <div className="d-flex flex-wrap w-100" style={{ overflow: showTagButtons !== null ? 'hidden' : 'auto', minHeight: !toggledTags ? '2.5rem' : '', maxHeight: !toggledTags ? '2.5rem' : '' }} id="tagContainer">
                    {dealTagList.map((tag, idx) => {
                        const isSelected = selectedTags?.some(x => x.id === tag.id);

                        return (
                            <CoolBadge
                                key={idx}
                                text={tag.label}
                                type="tags"
                                isSelected={isSelected}
                                data={tag}
                                hasInput={false}
                                onClick={handleDealTagChange}
                            />
                        )
                    }
                    )}
                </div>
                {showTagButtons ?
                    <div className="text-right">
                        <button type="button"
                            className="btn btn-link p-0 mt-2 text-muted text-right"
                            style={{ fontSize: '0.8em' }}
                            onClick={() => setToggledTags(!toggledTags)}
                        >
                            {toggledTags ? 'Show Less' : 'Show More'}
                        </button>
                    </div>
                    : ''}
            </Col>
        : ''
    )
}
//#endregion