import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../../Base/ErrorAlert';
import { Button, Card, Col, Row } from 'reactstrap';
import { DefaultPage, FormatAmountNumber, SelectHotel } from '../../Base/CommonUIComponents';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { SingleDatePicker } from 'react-dates';
import CustomSelect from '../../Base/CustomSelect';
import { salesDealStatus } from '../../Base/ReferenceDataFunctions';
import { Pagination, ResultsPerPage, TotalResults } from '../../Base/PaginationComponents';
import { checkSalesProcessPermission } from '../../SalesProcess/SalesProcessFunctions';

class ProfileSalesProcess extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            block: true,
            error: null,
            startDate: null,
            endDate: null,
            hotel: null,
            status: null,
            profileDeals: [],
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0,
        }
    }

    componentDidMount(){
        this.getProfileDeals();
    }

    getProfileDeals = () => {
        const { profileId } = this.props;
        const { startDate, endDate, status, hotel, pageSize, pageIndex } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                this.setState({ block: false, profileDeals: data.data ?? [], totalItems: data.total })
                return;
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/deals/profile/${profileId}?${startDate ? `startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}${hotel ? `&hotelId=${hotel}` : ''}${status ? `&status=${status}` : ''}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
    }

    createNewDeal = () => {
        this.props.history.push({ pathname: `/SalesProcess`, search: `?newDeal=true` });
    }
    
    goToDealDetail = (id) => {
        this.props.history.push({ pathname: `/SalesProcess` , search: `?dealId=${id}#SalesDealDetail` });
    }

    handleHotel = (e, combo) => {
        this.setState({ hotel: combo?.value });
    }

    handleCombo = (combo, name) => {
        this.setState({ [name]: combo?.value });
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, this.getProfileDeals);
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0 }, this.getProfileDeals);
        }
    }

    render() {
        const { block, error, profileDeals, startDate, endDate, status, hotel, pageSize, pageIndex, totalItems } = this.state;
        const { profileId, intl, currentSalesProcessUser } = this.props;

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <Card className="ml-1 my-0 mr-0 border-0 bg-light h-100">
                    <div className="m-2 px-2">
                        <Row className="mb-3">
                            <Col>
                                <h5>
                                    <i className="fas fa-briefcase mr-1"/>
                                    <FormattedMessage id="ProfileDetails.Deals"/>
                                </h5>
                            </Col>
                            {global.isMobile ?
                                <Col className='text-right'>
                                    <Button className="btn-sm btn-host" onClick={this.createNewDeal}>
                                        <i className="fas fa-plus" />
                                    </Button>
                                    <Button className="btn-sm btn-host ml-2" onClick={this.getProfileDeals}>
                                        <i className="fas fa-search" />
                                    </Button>
                                </Col>
                            :''}
                        </Row>
                        <Row className="mb-3">
                            <Col className={global.isMobile ? 'col-6 pr-2 mb-2' : ''}>
                                <div className='title-sm'>
                                    <i className={`fas fa-code-branch mr-2`} />
                                    <FormattedMessage id="SalesProcess.Hotel" />
                                </div>
                                <div>
                                    <SelectHotel
                                        isSearchable
                                        onChangeFunc={this.handleHotel}
                                        placeholder={intl.formatMessage({ id: "SalesProcess.Hotel" })}
                                        value={hotel || ''}
                                        params='?onlyFromHotelGroup=true'
                                    />
                                </div>
                            </Col>
                            <Col className={global.isMobile ? 'col-6 pl-0 mb-2' : ''}>
                                <div className='title-sm'>
                                    <i className={`fas fa-code-branch mr-2`} />
                                    <FormattedMessage id="SalesProcess.Status" />
                                </div>
                                <div>
                                    <CustomSelect
                                        options={salesDealStatus()}
                                        required={true}
                                        isClearable={true}
                                        isMulti={false}
                                        value={salesDealStatus().filter(({ value }) => value === status) || ''}
                                        isSearchable={true}
                                        onChange={e => this.handleCombo(e, 'status')}
                                        placeholder={intl.formatMessage({ id: "SalesProcess.Status" })}
                                    />
                                </div>
                            </Col>
                            <Col className={global.isMobile ? 'col-6 pr-2 mt-2' : ''}>
                                <div className='title-sm'>
                                    <i className="far fa-calendar-alt mr-2" />
                                    <FormattedMessage id="SalesProcess.StartDate" />
                                </div>
                                <div>
                                    <SingleDatePicker
                                        id="startDateId"
                                        isOutsideRange={day => false}
                                        date={startDate ? moment(startDate) : ''}
                                        focused={this.state.focusedStart}
                                        onFocusChange={({ focused }) => this.setState({ focusedStart: focused })}
                                        small={true}
                                        numberOfMonths={1}
                                        showDefaultInputIcon={true}
                                        onDateChange={date => this.setState({ startDate: date?.format("YYYY-MM-DD") })}
                                        showClearDate={true}
                                    />
                                </div>
                            </Col>
                            <Col className={global.isMobile ? 'col-6 pl-0 mt-2' : ''}>
                                <div className='title-sm'>
                                    <i className="far fa-calendar-alt mr-2" />
                                    <FormattedMessage id="SalesProcess.EndDate" />
                                </div>
                                <div id={global.isMobile ? 'mobileDatePicker' : ''}>
                                    <SingleDatePicker
                                        id="endDateId"
                                        isOutsideRange={day => false}
                                        date={endDate ? moment(endDate) : ''}
                                        focused={this.state.focusedEnd}
                                        onFocusChange={({ focused }) => this.setState({ focusedEnd: focused })}
                                        small={true}
                                        numberOfMonths={1}
                                        showDefaultInputIcon={true}
                                        onDateChange={date => this.setState({ endDate: date?.format("YYYY-MM-DD") })}
                                        showClearDate={true}
                                    />
                                </div>
                            </Col>
                            {!global.isMobile ?
                                <Col className='text-right'>
                                    <Button className="btn-sm btn-host" onClick={this.createNewDeal}>
                                        <i className="fas fa-plus" />
                                    </Button>
                                    <Button className="btn-sm btn-host ml-2" onClick={this.getProfileDeals}>
                                        <i className="fas fa-search" />
                                    </Button>
                                </Col>
                            :''}
                        </Row>
                        <div className={`${!global.isMobile ? 'verticalScroll' : ''} p-lg-2`} style={{ height: !global.isMobile ? '81vh' : '' }}>
                            {profileDeals.length > 0 ?
                                <>
                                    <Row>
                                        {profileDeals.map((deal, key) => {
                                            const hotelCurrency = global.hotelList.find(x => x.value == deal.hotelIds?.[0])?.currency ?? global.hotelCurrency;
                                            const canSeeDealDetail = checkSalesProcessPermission('ProfileDetails:deals:details', currentSalesProcessUser?.role, deal?.hotelIds, currentSalesProcessUser?.hotelIds, deal?.salesDealUser, currentSalesProcessUser?.value);
                                            
                                            return (
                                                <Col key={key} className="col-12 col-lg-6 mb-3">
                                                    <Card className={`shadow border-0 h-100 pb-2 ${canSeeDealDetail  ? 'pointer' : ''}`}
                                                        body onClick={_ => canSeeDealDetail ? this.goToDealDetail(deal.salesDealId) : undefined}>
                                                        <Row>
                                                            <Col className='col-6'>
                                                                <div style={{ fontSize: '1.1em' }} className='text-truncate'>{deal.salesDealName}</div>
                                                                {deal.customer ?
                                                                    deal.customer.id === profileId ?
                                                                        <div style={{ fontSize: '0.8em' }} className='text-muted'>
                                                                            {deal.customer.name}
                                                                        </div>
                                                                        :
                                                                        <div style={{ fontSize: '0.8em' }} className='text-muted hover-underline' onClick={e => e.stopPropagation()}>
                                                                            <Link to={{ pathname: "/ProfileDetails/" + deal.customer.id }}>
                                                                                {deal.customer.name}
                                                                            </Link>
                                                                        </div>
                                                                    : ''}
                                                            </Col>
                                                            <Col className='col-6 col-lg-3 text-right text-lg-left' style={{ fontSize: '0.8em' }}>
                                                                {deal.status ?
                                                                    deal.status == 'WaitingHotel' ?
                                                                        <div className={`deal-action-badge-OnGoingFixed p-1`}>
                                                                            <i class="icon-icon-hotel mr-1" />
                                                                            <FormattedMessage id={`SalesProcess.WaitingForHotel`} />
                                                                        </div>
                                                                        : deal.status == "WaitingClient" ?
                                                                            <div className={`deal-action-badge-ScheduledFixed p-1`}>
                                                                                <i class="far fa-building mr-1" />
                                                                                <FormattedMessage id={`SalesProcess.WaitingForClient`} />
                                                                            </div>
                                                                            : deal.status == "Cancelled" ?
                                                                                <div className={`deal-action-badge-CancelledFixed p-1`}>
                                                                                    <i class="far fa-thumbs-down mr-1" />
                                                                                    <FormattedMessage id={`SalesProcess.Lost`} />
                                                                                </div>
                                                                                : deal.status == "Finished" ?
                                                                                    <div className={`deal-action-badge-FinishedFixed p-1`}>
                                                                                        <i class="fas fa-trophy mr-1" />
                                                                                        <FormattedMessage id={`SalesProcess.Won`} />
                                                                                    </div>
                                                                                    : deal.status == "Effective" ?
                                                                                        <div className={`deal-action-badge-EffectiveFixed p-1`}>
                                                                                            <FormattedMessage id={`SalesProcess.Effective`} />
                                                                                        </div>
                                                                                        :''
                                                                    : ''}
                                                            </Col>
                                                            <Col className='text-right col-12 col-lg-3'>
                                                                <b style={{ fontSize: '1.1em' }}>
                                                                    <FormatAmountNumber
                                                                        value={deal.effectiveValue ?? deal.expectedValue} currency={hotelCurrency}
                                                                    />
                                                                </b>
                                                            </Col>
                                                        </Row>
                                                        <hr className='my-3' />
                                                        <Row>
                                                            <Col className='col-6 col-lg-3'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="ProfileSalesProcess.CreatedAt" />
                                                                </div>
                                                                <div>
                                                                    {deal.createdAt ?
                                                                        moment(deal.createdAt).format('YYYY-MM-DD')
                                                                        : '-'}
                                                                </div>
                                                            </Col>
                                                            <Col className='col-6 col-lg-3'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="ProfileSalesProcess.SalesSourceType" />
                                                                </div>
                                                                <div>
                                                                    {deal.salesSourceType ?? '-'}
                                                                </div>
                                                            </Col>
                                                            <Col className='col-12 col-lg-6 mt-2 mt-lg-0'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="ProfileSalesProcess.HotelIds" />
                                                                </div>
                                                                <div>
                                                                    {deal.hotelIds ?
                                                                        <div style={{ maxHeight: '25px' }} className='overflow-auto veryCoolScrollBar'>
                                                                            {deal.hotelIds?.map((hotelId, key) => {
                                                                                const hotel = global.hotelList?.find(({ value }) => value === hotelId);
                                                                                return (
                                                                                    <React.Fragment key={key}>
                                                                                        {key ?
                                                                                            <span>,</span>
                                                                                            : ''}
                                                                                        <span className={key ? 'ml-1' : ''}>
                                                                                            {hotel?.label || hotelId}
                                                                                        </span>
                                                                                    </React.Fragment>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        : '-'}
                                                                </div>
                                                            </Col>

                                                            <Col className='col-6 col-lg-3 mt-2'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="ProfileSalesProcess.NegotiationStartDate" />
                                                                </div>
                                                                <div>
                                                                    {deal.negotiationStartDate ?
                                                                        moment(deal.negotiationStartDate).format('YYYY-MM-DD')
                                                                        : '-'}
                                                                </div>
                                                            </Col>
                                                            <Col className='col-6 col-lg-3 mt-2'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="ProfileSalesProcess.NegotiationEndDate" />
                                                                </div>
                                                                <div>
                                                                    {deal.negotiationEndDate ?
                                                                        moment(deal.negotiationEndDate).format('YYYY-MM-DD')
                                                                        : '-'}
                                                                </div>
                                                            </Col>
                                                            <Col className='col-6 col-lg-3 mt-2'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="ProfileSalesProcess.BusinessStartDate" />
                                                                </div>
                                                                <div>
                                                                    {deal.businessStartDate ?
                                                                        moment(deal.businessStartDate).format('YYYY-MM-DD')
                                                                        : '-'}
                                                                </div>
                                                            </Col>
                                                            <Col className='col-6 col-lg-3 mt-2'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="ProfileSalesProcess.BusinessEndDate" />
                                                                </div>
                                                                <div>
                                                                    {deal.businessEndDate ?
                                                                        moment(deal.businessEndDate).format('YYYY-MM-DD')
                                                                        : '-'}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            )}
                                        )}
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ResultsPerPage
                                                changeSizePerPage={this.changePageSize}
                                                pageSize={pageSize}
                                            />
                                            {!global.isMobile ?
                                                <span className="ml-2">
                                                    <TotalResults
                                                        end={pageIndex * pageSize + profileDeals.length}
                                                        start={pageIndex * pageSize + 1}
                                                        total={totalItems}
                                                    />
                                                </span>
                                            :''}
                                        </Col>
                                        <Col className="text-right">
                                            <Pagination
                                                isPrevDisabled={pageIndex === 0}
                                                isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                                currentPage={pageIndex + 1}
                                                handlePrevButton={() => this.handlePagination(-1)}
                                                handleNextButton={() => this.handlePagination(1)}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            :
                                <DefaultPage text="ProfileSalesProcess.NoDeals" icon="fas fa-briefcase"/>
                            }
                        </div>
                    </div>
                </Card>
            </BlockUi>
        );
    }
}

export default injectIntl(ProfileSalesProcess);
