import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Col, Row, Modal, ModalBody, Card, UncontrolledTooltip } from "reactstrap";
import { StyledCard, ActiveInactiveStatusBadge, DefaultPage, KebabMenu, CommonHelper } from "../../Base/CommonUIComponents";
import { getAPI, postAPI } from "../../Base/API";
import CustomSelect from "../../Base/CustomSelect";
import DailyReportModal from "./DailyReportModal";
import Authorization, { CheckAuthorization } from "../../Base/Authorization";
import { getDataQualityReportType } from "../../Base/ReferenceDataFunctions";
import { handleNotification } from "../../Base/Notification";

class DailyReport extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.form = React.createRef();
        this.state = {
            block: false,
            previewModal: false,
            modal: false,
            availableTypes: null
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;

        this.getHotelList();
    }

    getHotelList = () => {
        this.setState({ block: true });

        var list = [];

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                data.response && data.response.forEach(hotel => {
                    list.push({ value: hotel.hotelId, label: hotel.name2 });
                })
            }

            this.setState({ hotelList: list }, (e) => this.getDailyReportConfig(e, list));
        }, `/api/gms/Hotel/v1/hotel/list?onlyFromHotelGroup=true`);
    }

    getDailyReportConfig = (e, hotelList) => {
        if (e) e.preventDefault();
        this.setState({ block: true });

        let params = '';
        if (this.state.hotelId) params += `hotelId=${this.state.hotelId}&`;
        if (this.state.active !== null && this.state.active !== undefined) params += `active=${this.state.active}&`;
        if (this.state.type) params += `type=${this.state.type}`;

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const hotelListCopy = hotelList || this.state.hotelList;
                let availableTypes = [];

                if (data.response && data.response.length > 0) {
                    if (hotelListCopy && hotelListCopy.length > 0) {
                        data.response && data.response.forEach(config => {
                            const hotelIndex = hotelListCopy.findIndex(el => el.value === config.hotelId);

                            if (hotelIndex !== -1) {
                                config.hotelName = hotelListCopy[hotelIndex].label;

                                hotelListCopy[hotelIndex].hasConfig = true;
                            }
                        })
                    }

                    if (!data.response.some(r => r.type === getDataQualityReportType()[1].value)) {
                        availableTypes.push(getDataQualityReportType()[1]);
                    }
                    if (hotelListCopy?.length > data.response.filter(r => r.type === getDataQualityReportType()[0].value).length) {
                        availableTypes.push(getDataQualityReportType()[0]);
                    }
                    if (!data.response.some(r => r.type === getDataQualityReportType()[2].value)) {
                        availableTypes.push(getDataQualityReportType()[2]);
                    }
                }
                else {
                    availableTypes = getDataQualityReportType();
                }

                this.setState({ dailyReportConfigs: data.response, hotelList: hotelListCopy, availableTypes, block: false });
            }
            else this.setState({ block: false });
        }, `/api/gms/DataQuality/v1/DataQualityReports?` + params);
    }



    resendDailyReport = (reportId) => {
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="DailyReport.DailyReportResent" />, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gmsscheduletask/DataQuality/DataQualityReport/${reportId}/resend`);
    }

    updateList = (newConfig) => {
        const { dailyReportConfigs } = this.state;
        const configIdx = dailyReportConfigs?.findIndex(el => el.id === newConfig.id);

        if (configIdx !== -1) {
            dailyReportConfigs[configIdx] = newConfig;
        }
        else {
            dailyReportConfigs.push( newConfig );
        }

        this.setState({ dailyReportConfigs });
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    };

    toggleModal = (configData) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedConfig: configData
        }))
    }

    togglePreview = (template) => {
        this.setState(prevState => ({
            previewModal: !prevState.previewModal,
            notifyGenericTemplate: template
        }))
    }

    getExtraFields = (config) => {
        var options = [];

        if (config.type === "InhouseReport") {
            options.push(
                { text: "MonthlyReportRuns.Resend", function: _ => this.resendDailyReport(config.id), icon: "far fa-paper-plane mr-2" }
            )
        }

        return options;
    }

    render() {
        const { dailyReportConfigs, modal, hotelList, availableTypes } = this.state;
        
        const status = [
            { value: true, label: <FormattedMessage id="generic.active"/> },
            { value: false, label: <FormattedMessage id="generic.inactive"/> }
        ]


        return (
            <StyledCard block={this.state.block} error={this.state.error} >
                {modal ?
                    <DailyReportModal
                        modal={modal}
                        toggleModal={() => this.toggleModal(null)}
                        hotelList={hotelList}
                        selectedConfig={this.state.selectedConfig}
                        updateList={this.updateList}
                        availableTypes={availableTypes}
                    />
                : ''}

                <Row className="mb-4">
                    <Col>
                        <h5>
                            <i className="fas fa-file-invoice mr-2"></i>
                            <FormattedMessage id="DailyReport.Title" />
                        </h5>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col sm={3}>
                        <CustomSelect
                            options={hotelList}
                            isClearable
                            isSearchable
                            placeholder={<FormattedMessage id="generic.Hotel"/>}
                            onChange={(e) => this.handleChangeSelect(e, 'hotelId')}
                        />
                    </Col>
                    <Col sm={3}>
                        <CustomSelect
                            options={getDataQualityReportType()}
                            isClearable
                            isSearchable
                            placeholder={<FormattedMessage id="generic.Type" />}
                            onChange={(e) => this.handleChangeSelect(e, 'type')}
                        />
                    </Col>
                    <Col sm={2}>
                        <CustomSelect
                            options={status}
                            isClearable
                            isSearchable
                            placeholder={<FormattedMessage id="generic.status" />}
                            onChange={(e) => this.handleChangeSelect(e, 'active')}
                        />
                    </Col>
                    <Col className="text-right py-1">
                        <CommonHelper help={<FormattedMessage id="Reports.Help" />} id={'Reports'} lang={this.props.intl.locale} />
                        {
                            availableTypes?.length > 0 ?
                                <span>
                                    <Button id="createReport" className="btn btn-sm btn-host mr-2" onClick={() => this.toggleModal(null)}>
                                        <i className="fas fa-plus" />
                                    </Button>
                                    <UncontrolledTooltip target="createReport" placement="bottom">
                                        <FormattedMessage id="DailyReport.CreateReport" />
                                    </UncontrolledTooltip>
                                </span>
                                :
                                ''
                        }
                        <span>
                            <Button id="searchReport" className="btn btn-sm btn-host " onClick={this.getDailyReportConfig}>
                                <i className="fas fa-search" />
                            </Button>
                            <UncontrolledTooltip target="searchReport" placement="bottom">
                                <FormattedMessage id="DailyReport.SearchReport" />
                            </UncontrolledTooltip>
                        </span>
                    </Col>
                </Row>
                {dailyReportConfigs?.length > 0 ?
                    <Row>
                        {dailyReportConfigs.map((config, idx) => {
                            const type = getDataQualityReportType().find(rt => rt.value === config.type);
                            const isInhouseReport = config.type === getDataQualityReportType()[0].value;
                            const isMonthlyOverview = config.type === getDataQualityReportType()[1].value;

                            return <Col sm={6} key={idx} className="mb-3">
                                <Card className="border-0 shadow mb-3 h-100" body>
                                    <Row className="align-items-center">
                                        <Col>
                                            <b><i className={`${type.icon} mr-2`} /> {type.label}</b>
                                        </Col>
                                        <Col className="d-flex justify-content-end col-3">
                                            <span className="mr-4">
                                                <ActiveInactiveStatusBadge status={config.active} />
                                            </span>

                                            <KebabMenu
                                                editFunction={() => this.toggleModal(config)}
                                                previewFunction={() => { if (CheckAuthorization("dailyReport:preview")) this.togglePreview(config.notifyGenericTemplate) }}
                                                extraFields={this.getExtraFields(config)}
                                            />
                                        </Col>
                                    </Row>

                                    <hr />

                                    <Row>
                                        <Col>
                                            <div className="title-sm"><FormattedMessage id="generic.Template" />  </div>
                                            <div className="pl-1 mb-2"> {config.notifyGenericTemplate?.name} </div>
                                        </Col>
                                        {isInhouseReport ?
                                            <Col>
                                                <div className="title-sm">
                                                    <Row>
                                                        <Col>
                                                            <FormattedMessage id="generic.Hotel" />
                                                        </Col>
                                                        <Col className="col-4">
                                                            <FormattedMessage id="DailyReport.DaysToReport" />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="pl-1 mb-2">
                                                    <Row>
                                                        <Col>
                                                            <b>{config.hotelName}</b>
                                                        </Col>
                                                        <Col className="col-4">
                                                            <b>{config.daysToReport ? config.daysToReport : '-'}</b>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            :
                                            isMonthlyOverview ?
                                                <Col>
                                                    <div className="title-sm"> <FormattedMessage id="DailyReport.Runs" />  </div>
                                                    <span onClick={() => this.props.history.push({ pathname: `/MonthlyReportRuns` })} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                                        <FormattedMessage id="generic.SeeDetails" />
                                                    </span>
                                                </Col>
                                                :
                                                ''
                                        }
                                    </Row>

                                    <div className="title-sm mt-2"> Email </div>
                                    <div className="pl-1 mb-3"> {config.email?.split(';').join(', ')} </div>


                                    {isInhouseReport ?
                                        <>
                                            <div className="title-sm"> <FormattedMessage id="DailyReport.Elements" /> </div>
                                            <div className="pl-1">
                                                {config.reportElements?.map(el => this.props.intl.formatMessage({ id: `ReferenceDataFunctions.${el}` })).join(', ')}
                                            </div>
                                        </>
                                        : ''}
                                </Card>
                            </Col>

                        })}
                    </Row>               
                : <DefaultPage text="DailyReport.NoReports" icon="fas fa-file-invoice" />
                }

                <Modal isOpen={this.state.previewModal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                    <Button onClick={this.togglePreview} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        <h5 className="my-2"> {this.state.notifyGenericTemplate?.name} </h5>
                        <div dangerouslySetInnerHTML={{ __html: this.state.notifyGenericTemplate?.htmlTemplate }} style={{ maxHeight: "100%", overflow: "auto", overflowX: "hidden" }} />
                    </ModalBody>
                </Modal>
            </StyledCard>
        );
    }
}
export default injectIntl(DailyReport)