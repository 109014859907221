import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col, Button, Form, PopoverBody, Input } from 'reactstrap';
import { CommonHelper, CoolTooltip, FilterButton, FormatAmountNumber, SelectHotel } from '../Base/CommonUIComponents';
import CustomSelect from '../Base/CustomSelect';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { DateRangePicker } from 'react-dates';
import moment from 'moment-timezone';
import SalesDealModal from './SalesDealModal/SalesDealModal';
import { handleNotification } from '../Base/Notification';
import { putAPI } from '../Base/API';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import SalesActionsPopOver from './SalesActionsPopOver';
import SalesProcessDefaultPage from './SalesProcessDefaultPage';
import CustomToolTip from '../Base/CustomToolTip';
import CreateDealByImportGroupRes from './CreateDealByImportGroupRes';
import { SalesProcessHotelCombo, SalesProcessUsersCombo, getAllowedSalesProcessUsers, checkSalesProcessPermission } from './SalesProcessFunctions';
import { salesDealStatusFilters } from '../Base/ReferenceDataFunctions';

class SalesPipelineStage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedStaff: null,
            salesSourceId: null,
            startDate: null,
            pipeline: null,
            endDate: null,
            hotels: null,
            dealName: null,
            searchProfile: null,
            error: null,
            block: false,
            salesStatus: ['WaitingHotel', 'WaitingClient'],
        };
    }

    componentDidMount() {
        var currentUser = getAllowedSalesProcessUsers()?.find(x => x.isCurrentUser);
        var importDealPermission = checkSalesProcessPermission("ImportDeals", currentUser?.role);

        this.setState({ importDealPermission });

        this.getQueryStringData();
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.selectedPipeline?.value !== this.props.selectedPipeline?.value)
            this.setState({ pipeline: nextProps.selectedPipeline });
    }

    buildQueryString = () => {
        const { selectedStaff, startDate, endDate, salesSourceId, hotels, dealName, searchProfile, salesStatus } = this.state;
        const queryParams = new URLSearchParams();

        if (selectedStaff?.length > 0) {
            selectedStaff.forEach(s => queryParams.append('staffIds', s));
        }

        if (startDate && moment(startDate)) {
            queryParams.append('startDate', moment(startDate).format('YYYY-MM-DD'));
        }

        if (endDate && moment(endDate)) {
            queryParams.append('endDate', moment(endDate).format('YYYY-MM-DD'));
        }

        if (salesSourceId) {
            queryParams.append('sourceId', salesSourceId);
        }

        if (dealName) {
            queryParams.append('dealName', dealName);
        }

        if (searchProfile) {
            queryParams.append('searchProfile', searchProfile);
        }

        if (hotels?.length > 0) {
            hotels.forEach(s => queryParams.append('hotels', s));
        }

        if (salesStatus?.length > 0) {
            salesStatus.forEach(s => queryParams.append('salesStatus', s));
        }else{
            queryParams.append('salesStatus', 'WaitingHotel');
            queryParams.append('salesStatus', 'WaitingClient');
        }

        const queryString = queryParams.toString();
        const newUrl = `/SalesProcess${queryString ? `?${queryString}` : ''}#SalesPipelineStage`;

        this.setState({ queryString, salesStatus: salesStatus?.length > 0 ? salesStatus : ['WaitingHotel', 'WaitingClient'] }, () => {
            window.history.pushState(null, '', newUrl);
            this.props.getDeals(this.state.pipeline ?? this.props.selectedPipeline, this.state);
        });
    }

    getQueryStringData = () => {
        const { getPipelineQueryStringData, currentUser } = this.props;
        let { selectedStaff, startDate, endDate, salesSourceId, hotels, dealName, searchProfile, salesStatus } = getPipelineQueryStringData();

        if(!hotels?.length){
            const hotelStaffRoles = ["Manager", "SalesRep"];
            const isHotelStaff = hotelStaffRoles.includes(currentUser?.role);

            if(isHotelStaff){
                hotels = currentUser.hotelIds;
            }
            else{
                hotels = global.hotelListFromGroup && global.hotelListFromGroup[0];
            }
        }

        this.setState({
            selectedStaff: selectedStaff ?? [],
            startDate: startDate ? moment(startDate) : null,
            endDate: endDate ? moment(endDate) : null,
            salesSourceId: salesSourceId ?? null,
            dealName: dealName ?? null,
            searchProfile: searchProfile ?? null,
            hotels: hotels ?? [],
            salesStatus: salesStatus ?? ['WaitingHotel', 'WaitingClient'],
        }, () => {
            if (this.props.selectedPipeline) {
                this.props.getDeals(this.props.selectedPipeline, this.state)
            }
        } );
    }

    changeDealStage = (e) => {
        e.preventDefault();

        this.setState({ block: true }, () => {
            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else if (data.data?.length > 0) {
                        handleNotification(data, <FormattedMessage id="SalesDeal.DealMoved" />, <FormattedMessage id="generic.success" />);
                        
                        this.props.updateDealStage(data.data[0]);
                        this.setState({ error: errorMessage, block: false });

                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `api/gms/SalesProcess/v1/deals/${this.state.grabbingDealId}/pipelinestage/${this.state.dragToStageId}`);
        });
    }

    handlePipeline = (combo) => {
        this.setState({ pipeline: combo });
    }

    handleHotel = (e, combo) => {
        this.setState({ hotels: combo?.map(({value}) => value) });
    }

    handleSelectedStaff = (e, combo) => {
        this.setState({ selectedStaff: combo?.map(({value}) => value) });
    }

    handleSelectedSource = (el) => {
        this.setState({ salesSourceId: el?.value });
    }
    

    handleDateChanges = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate });
    }

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));
    }

    onDragStart = (e, grabbingDealId, excludedStageId) => {
        e.dataTransfer.setData("deal", JSON.stringify(grabbingDealId));

        this.setState(prevState => ({ ...prevState, showDragArea: true, grabbingDealId, excludedStageId }))
    }

    onDragEnd = () => {
        this.setState({ showDragArea: false, dragToStageId: null, grabbingDealId: null, excludedStageId: null })
    }

    onDragOver = (e, stageId) => {
        e.preventDefault();

        if (this.state.dragToStageId !== stageId) {
            this.setState({ dragToStageId: stageId });
        }
    }
    
    applySearch = (e) => {
        e.preventDefault();

        this.buildQueryString()
    }

    handleMultiCombo = (combo, name) => {
        this.setState({ [name]: combo ? combo?.map(cmb => cmb.value) : [] });
    }

    renderHelperMsg = () => {
        return (
            <div style={{ width: '200px' }}>
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className='actionStatusIcon' style={{ background: "#dc3545" }}>
                        <i className="fas fa-angle-right" />
                    </div>
                    <FormattedMessage id="SalesProcess.MissedAction" />
                </div>
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className='actionStatusIcon' style={{ background: "#0665ff" }}>
                        <i className="fas fa-angle-right" />
                    </div>
                    <FormattedMessage id="SalesProcess.OnGoingAction" />
                </div>
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className='actionStatusIcon' style={{ background: "#28a745" }}>
                        <i className="fas fa-angle-right" />
                    </div>
                    <FormattedMessage id="SalesProcess.ScheduledAction" />
                </div>
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className='actionStatusIcon' style={{ background: "#ffc107" }}>
                        <i className="fas fa-angle-right" />
                    </div>
                    <FormattedMessage id="SalesProcess.NoActions" />
                </div>
                <hr />
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className='p-3 w-100 bg-white shadow' style={{ borderRadius: '5px', borderLeft: '5px solid #0665ff', maxWidth: '50px' }} />
                    <p className='m-0'><FormattedMessage id="SalesProcess.WaitingHotel" /></p>
                </div>
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className=' p-3 w-100 bg-white shadow' style={{ borderRadius: '5px', borderLeft: '5px solid #d3d3d3', maxWidth: '50px' }} />
                    <p className='m-0'><FormattedMessage id="SalesProcess.WaitingClient" /></p>
                </div>
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className='p-3 w-100 bg-white shadow' style={{ borderRadius: '5px', borderLeft: '5px solid #28a745', maxWidth: '50px' }} />
                    <p className='m-0'><FormattedMessage id="SalesProcess.Won" /></p>
                </div>
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className=' p-3 w-100 bg-white shadow' style={{ borderRadius: '5px', borderLeft: '5px solid #dc3545', maxWidth: '50px' }} />
                    <p className='m-0'><FormattedMessage id="SalesProcess.Lost" /></p>
                </div>
                <div className='d-flex align-items-center justify-content-between p-2'>
                    <div className=' p-3 w-100 bg-white shadow' style={{ borderRadius: '5px', borderLeft: '5px solid #6EDE8A', maxWidth: '50px' }} />
                    <p className='m-0'><FormattedMessage id="SalesProcess.Effective" /></p>
                </div>
            </div>
        );
    }

    toggleImportGroupResModal = () => {
        this.setState({ importGroupResModal: !this.state.importGroupResModal });
    }

    countFilters = () => {
        const { selectedPipeline, pipeline, dealName, searchProfile, selectedStaff, hotels, salesSourceId, startDate, endDate, salesStatus } = this.state;
        let count = 0;
        
        if (pipeline?.value || selectedPipeline?.value) count++;
        if (dealName) count++;
        if (searchProfile) count++;
        if (selectedStaff?.length > 0) count++;
        if (hotels && hotels.length > 0) count++;
        if (salesSourceId) count++;
        if (startDate || endDate) count++;
        if(salesStatus?.length > 0) count++;

        return count;
    }

    render() {
        const { selectedStaff, salesSourceId, startDate, endDate, excludedStageId, showDragArea, dragToStageId, pipeline, createDeal, error, block, 
            hotels, importGroupResModal, dealName, searchProfile, importDealPermission, salesStatus } = this.state;
        const { salesPipelines, salesSources, deals, intl, selectDealDetail, selectedPipeline, blockPipelines, salesUsers, getDeals, hotelList } = this.props;

        return (
            <div className='h-100 pb-2'>
                {importGroupResModal ?
                    <CreateDealByImportGroupRes
                        selectedPipeline={this.props.selectedPipeline}
                        toggleModal={this.toggleImportGroupResModal}
                        block={block}
                        modal={true}
                        salesPipelines={salesPipelines}
                        salesSources={salesSources}
                        history={this.props.history}
                    />
                    : ''}
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    {createDeal ?
                        <SalesDealModal
                            modal={createDeal}
                            salesUsers={salesUsers}
                            toggleModal={_ => this.toggleModal('createDeal')}
                            salesStages={selectedPipeline.stages}
                            salesSources={salesSources}
                            getDeals={_ => this.props.getDeals(selectedPipeline)}
                            salesPipelineId={selectedPipeline.id}
                            salesPipelines={salesPipelines}
                            hotelList={hotelList}
                        />
                    :''}

                    <Row className="mb-4">
                        <Col className='d-flex align-items-center justify-content-between'>
                            <h4 className='m-0'>
                                <i className={`${this.props.icon} mr-2`} />
                                <FormattedMessage id="SalesProcess.SalesPipelineStage" />
                            </h4>
                            <CommonHelper help={this.renderHelperMsg()} id={'salesPipelineStageHelp'} />
                        </Col>
                    </Row>

                    <Form onSubmit={this.applySearch}>
                        <Row>
                            <Col>
                                <div className='title-sm'>
                                    <i className={`fas fa-sliders-h mr-2`} />
                                    <FormattedMessage id="SalesProcess.SalesPipelines"/>
                                </div>
                                <div>
                                    <CustomSelect
                                        options={salesPipelines}
                                        required={true}
                                        isClearable={false}
                                        isMulti={false}
                                        value={salesPipelines?.filter(({ value }) => value === (pipeline?.value ?? selectedPipeline?.value)) || ''}
                                        isSearchable={true}
                                        onChange={this.handlePipeline}
                                        placeholder={intl.formatMessage({ id: "SalesProcess.SalesPipelines" })}
                                        isLoading={blockPipelines}
                                    />
                                </div>
                            </Col>
                            <Col className="col-3">
                                <div className='title-sm'>
                                    <i className="fas fa-briefcase mr-2" />
                                    <FormattedMessage id="SalesProcess.DealName" />
                                </div>
                                <div>
                                    <Input
                                        type="text"
                                        name="dealName"
                                        placeholder={this.props.intl.formatMessage({ id: "SalesProcess.DealName" })}
                                        onChange={(e) => this.setState({ dealName: e.target.value })}
                                        value={dealName || ''}
                                        maxLength="500"
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className='title-sm'>
                                    <i className="fas fa-users mr-2"/>
                                    <FormattedMessage id="SalesProcess.Staff"/>
                                </div>
                                <div>
                                    <SalesProcessUsersCombo
                                        isMulti={true}
                                        isClearable={true}
                                        name='staffIds'
                                        placeholder={intl.formatMessage({ id: "SalesProcess.Staff" })}
                                        value={selectedStaff}
                                        onChangeFunc={this.handleSelectedStaff}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className='title-sm'>
                                    <i className="icon-icon-hotel mr-2"/>
                                    <FormattedMessage id="SalesProcess.Hotel"/>
                                </div>
                                <div>
                                    <SalesProcessHotelCombo
                                        required={true}
                                        isMulti={true}
                                        isClearable={true}
                                        name='hotelIds'
                                        placeholder={intl.formatMessage({ id: "SalesProcess.Hotel" })}
                                        value={hotels}
                                        onChangeFunc={this.handleHotel}
                                    />
                                </div>
                            </Col>
                            <Col className='d-flex flex-column align-items-end justify-content-end'>
                                <div className='d-flex align-items-center'>
                                    <div className="mr-3">
                                        <FilterButton totalFilters={this.countFilters()} butId={"buttonSubmit"}>
                                            <div className="fullWidth">
                                                <div>
                                                    <div className='title-sm'>
                                                        <i className={`fas fa-power-off mr-2`} />
                                                        <FormattedMessage id="SalesProcess.DealStatus" />
                                                    </div>
                                                    <div>
                                                        <CustomSelect
                                                            options={salesDealStatusFilters(intl)}
                                                            isClearable={true}
                                                            isMulti={true}
                                                            isSearchable={true}
                                                            value={salesStatus ? salesDealStatusFilters(intl)?.filter(({ value }) => salesStatus.includes(value)) : ''}
                                                            onChange={e => this.handleMultiCombo(e, 'salesStatus')}
                                                            placeholder={intl.formatMessage({ id: "SalesProcess.Status" })}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='title-sm mt-3'>
                                                        <i className={`fas fa-code-branch mr-2`} />
                                                        <FormattedMessage id="SalesProcess.SalesSources" />
                                                    </div>
                                                    <div>
                                                        <CustomSelect
                                                            options={salesSources}
                                                            required={false}
                                                            isClearable={true}
                                                            isMulti={false}
                                                            value={salesSources?.find(({ value }) => value === salesSourceId)}
                                                            isSearchable={true}
                                                            onChange={this.handleSelectedSource}
                                                            placeholder={intl.formatMessage({ id: "SalesProcess.SalesSources" })}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='title-sm mt-3'>
                                                        <i className="far fa-calendar-alt mr-2" />
                                                        <FormattedMessage id="SalesProcess.BusinessDates" />
                                                    </div>
                                                    <div>
                                                        <DateRangePicker
                                                            startDate={startDate}
                                                            startDateId="startDateId"
                                                            endDate={endDate}
                                                            endDateId="endDateId"
                                                            isOutsideRange={_ => false}
                                                            onDatesChange={this.handleDateChanges}
                                                            focusedInput={this.state.focusedInput}
                                                            showClearDates={true}
                                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                            small={true}
                                                            showDefaultInputIcon={true}
                                                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='title-sm mt-3'>
                                                        <i className="fas fa-users mr-2" />
                                                        <FormattedMessage id="SalesProcess.SearchProfile" />
                                                    </div>
                                                    <div>
                                                        <Input
                                                            type="text"
                                                            name="searchProfile"
                                                            placeholder={this.props.intl.formatMessage({ id: "SalesProcess.SearchProfile" })}
                                                            onChange={(e) => this.setState({ searchProfile: e.target.value })}
                                                            value={searchProfile || ''}
                                                            maxLength="300"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </FilterButton>
                                    </div>
                                    {importDealPermission ?
                                        <Button className="btn-sm btn-host ml-2" onClick={this.toggleImportGroupResModal}>
                                            <i className="fas fa-upload"></i>
                                        </Button>
                                        : ''}
                                    <Button className="btn-sm btn-host ml-2" onClick={_ => selectedPipeline ? this.toggleModal('createDeal') : {}}>
                                        <i className="mr-1 fas fa-plus" />
                                        <FormattedMessage id="SalesProcess.Deal" />
                                    </Button>
                                    <Button className="btn-sm btn-host ml-2" type='submit' id="buttonSubmit">
                                        <i className="fas fa-search" />
                                    </Button>
                                </div>
                                {deals && deals.length > 0 ? (() => {
                                    const valuesList = deals.reduce((acc, deal) => {
                                        const currency = global.hotelList.find(x => x.value == deal.hotelIds?.[0])?.currency ?? global.hotelCurrency;
                                        if (!acc[currency]) {
                                            acc[currency] = { totalValue: 0, nDeals: 0, currency };
                                        }
                                        acc[currency].totalValue += deal.expectedValue ?? 0;
                                        acc[currency].nDeals += 1;
                                        return acc;
                                    }, {});

                                    const mainTotalValue = Object.values(valuesList).find((curr) => curr.currency === global.hotelCurrency);
                                    return (
                                        <div id="searchValuesTotal" className='d-flex align-items-center justify-content-end mt-1'>
                                            <div>
                                                <FormatAmountNumber value={mainTotalValue?.totalValue ?? 0} currency={mainTotalValue?.currency} />
                                            </div>
                                            <div className='mx-2'>-</div>
                                            <div>
                                                <span className='mr-1'>{mainTotalValue?.nDeals ?? 0}</span>
                                                <FormattedMessage id="SalesProcess.Deals" />
                                            </div>
                                            {Object.values(valuesList).filter(x => x.currency !== global.hotelCurrency).length > 0 ?
                                                <CoolTooltip placement="bottom" target={"searchValuesTotal"}>
                                                    {Object.values(valuesList).filter(x => x.currency !== global.hotelCurrency).map((x, idx) =>
                                                        <div key={idx} className='d-flex align-items-center justify-content-center'>
                                                            <div>
                                                                <FormatAmountNumber value={x.totalValue} currency={x.currency} />
                                                            </div>
                                                            <div className='mx-2'>-</div>
                                                            <div>
                                                                <span className='mr-1'>{x.nDeals}</span>
                                                                <FormattedMessage id="SalesProcess.Deals" />
                                                            </div>
                                                        </div>
                                                    )}
                                                </CoolTooltip>
                                                : ''}
                                        </div>
                                    );
                                })() : ''}
                            </Col>
                        </Row>
                    </Form>
                    <SalesProcessDefaultPage
                        showSelect={!selectedPipeline}
                        mainValue={salesPipelines?.length}
                        noValueText={"SalesProcess.NoPipelines"}
                        text={"SalesProcess.SelectPipeline"}
                        icon={this.props.icon}
                    >
                        <div className='mt-4 p-3 h-100 d-flex w-100 overflow-auto scrollableDiv' style={{ maxHeight: '75vh' }}>
                            {selectedPipeline?.stages?.sort((a, b) => a.index - b.index)?.map((stage, key) => {
                                const relatedDeals = deals.filter(({ salesPipelineStageId }) => salesPipelineStageId === stage.value);

                                const valuesList = relatedDeals.reduce((acc, deal) => {
                                    const currency = global.hotelList.find(x => x.value == deal.hotelIds?.[0])?.currency ?? global.hotelCurrency;
                                    if (!acc[currency]) {
                                        acc[currency] = { totalValue: 0, nDeals: 0, currency };
                                    }
                                    acc[currency].totalValue += deal.expectedValue??0;
                                    acc[currency].nDeals += 1;
                                    return acc;
                                }, {});

                                const mainTotalValue = Object.values(valuesList).find((curr) => curr.currency === global.hotelCurrency);
                                return (
                                    <div key={key} className='h-100 mr-3 px-2' style={{ minWidth: '250px', width: '100%', minHeight: '60vh' }}
                                        onDragOver={(e) => { this.onDragOver(e, (excludedStageId !== stage.value ? stage.value : null)) }}
                                        onDrop={(e) => { if (excludedStageId !== stage.value) this.changeDealStage(e) }}
                                    >
                                        <div className='text-center pipeline'>
                                            <div className='arrow top'></div>
                                            <div className='pipelineName bg-white text-truncate'>
                                                <div className='d-flex align-items-center justify-content-center' style={{ maxWidth: '100%' }}>
                                                    {stage.type === "Lead" ?
                                                        <i title={intl.formatMessage({ id: `SalesProcess.${stage.type}` })} className="fas fa-bullseye mr-2"/>
                                                    : stage.type === "DealProcess" ?
                                                        <i title={intl.formatMessage({ id: `SalesProcess.${stage.type}` })} className="fas fa-briefcase mr-2"/>
                                                    : stage.type === "Effective" ?
                                                        <i title={intl.formatMessage({ id: `SalesProcess.${stage.type}` })} className="fas fa-user-tie mr-2"/>
                                                    :''}
                                                    <div title={stage.label} className='text-truncate' style={{ maxWidth: '100%' }}>
                                                        {stage.label}
                                                    </div>
                                                </div>
                                                {valuesList && Object.values(valuesList).length > 0 ? (
                                                    <div id="totalValues" style={{ fontSize: '0.9em' }} className='text-muted d-flex align-items-center justify-content-center'>
                                                        {mainTotalValue && (
                                                            <>
                                                                <div>
                                                                    <FormatAmountNumber value={mainTotalValue.totalValue} currency={mainTotalValue.currency} />
                                                                </div>
                                                                <div className='mx-2'>-</div>
                                                                <div>
                                                                    <span className='mr-1'>{mainTotalValue.nDeals}</span>
                                                                    <FormattedMessage id="SalesProcess.Deals" />
                                                                </div>
                                                            </>
                                                        )}
                                                        {Object.values(valuesList).filter(x => x.currency !== global.hotelCurrency).length > 0 ?
                                                            <CoolTooltip placement="bottom" target={"totalValues"}>
                                                                {Object.values(valuesList).filter(x => x.currency !== global.hotelCurrency).map((x, idx) =>
                                                                    <div key={idx} className='d-flex align-items-center justify-content-center'>
                                                                        <div>
                                                                            <FormatAmountNumber value={x.totalValue} currency={x.currency} />
                                                                        </div>
                                                                        <div className='mx-2'>-</div>
                                                                        <div>
                                                                            <span className='mr-1'>{x.nDeals}</span>
                                                                            <FormattedMessage id="SalesProcess.Deals" />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </CoolTooltip>
                                                            : ''}
                                                    </div>
                                                ) : (
                                                    <div style={{ fontSize: '0.9em' }} className='text-muted d-flex align-items-center justify-content-center'>
                                                        <div>
                                                            <FormatAmountNumber value={0} />
                                                        </div>
                                                        <div className='mx-2'>-</div>
                                                        <div>
                                                            <span className='mr-1'>0</span>
                                                            <FormattedMessage id="SalesProcess.Deals" />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='arrow bottom'></div>
                                        </div>
                                        {showDragArea && dragToStageId === stage.value ?
                                            <div className="mt-3" style={{
                                                height: '15px',
                                                backgroundColor: '#165C7D20',
                                                borderRadius: '10px'
                                            }} />
                                        : ''}
                                        <div className='d-flex flex-column mt-2 h-100'>
                                            {relatedDeals.map((deal, k) => {
                                                const hotelCurrency = global.hotelList.find(x => x.value == deal.hotelIds?.[0])?.currency ?? global.hotelCurrency;

                                                return (

                                                    <div key={k} className='mt-2 py-2 px-3 w-100 bg-white shadow cursor-pointer'
                                                        style={{
                                                            borderRadius: '5px',
                                                            borderLeft: deal.status === 'WaitingHotel' ?
                                                                '5px solid #0665ff'
                                                                : deal.status === 'WaitingClient' ?
                                                                    '5px solid #d3d3d3'
                                                                    : deal.status === 'Finished' ?
                                                                        '5px solid #28a745' 
                                                                        : deal.status === 'Cancelled' ?
                                                                            '5px solid #dc3545'
                                                                            : deal.status === 'Effective' ?
                                                                                '5px solid #6EDE8A'
                                                                                : 'none'
                                                        }}
                                                        onClick={_ => selectDealDetail(deal.id, "SalesPipelineStage")}
                                                        draggable={true}
                                                        onDragStart={(event) => this.onDragStart(event, deal.id, stage.value)}
                                                        onDragEnd={this.onDragEnd}
                                                    >
                                                        <div>
                                                            <div className='d-flex align-items-start justify-content-between'>
                                                                <div className='text-truncate'>
                                                                    <div className='text-truncate'>
                                                                        {deal.name}
                                                                    </div>
                                                                    {deal.customer?.name ?
                                                                        <div style={{ marginTop: '-3px' }} className='text-truncate'>
                                                                            <span style={{ fontSize: '0.8em' }} className='text-muted hover-underline'>
                                                                                <Link to={{ pathname: "/ProfileDetails/" + deal.customer.id }}>
                                                                                    {deal.customer.name}
                                                                                </Link>
                                                                            </span>
                                                                        </div>
                                                                        : ''}
                                                                </div>
                                                                <SalesActionsPopOver
                                                                    id={`SalesAction-${key}-${k}`}
                                                                    salesUsers={salesUsers}
                                                                    placement={'right'}
                                                                    deal={deal}
                                                                    getDeals={getDeals}
                                                                    selectedPipeline={selectedPipeline}
                                                                />
                                                            </div>
                                                            {deal?.salesDealUser?.length > 0 ?
                                                                <>
                                                                    <div className='mt-1' style={{ maxWidth: '60%', width: 'fit-content' }}>
                                                                        <div style={{ fontSize: '0.8em' }} className='text-truncate text-muted d-flex align-items-center' id={`dealResponsibleUser-${deal.id}`}>
                                                                            <i className="fas fa-user mr-1" />
                                                                            {deal.salesDealUser.find(u => u.role === 'Responsible')?.name || '-'}
                                                                        </div>
                                                                    </div>
                                                                    <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="right" target={`dealResponsibleUser-${deal.id}`}>
                                                                        <PopoverBody>
                                                                            <FormattedMessage id="SalesProcess.Responsible" />
                                                                        </PopoverBody>
                                                                    </CustomToolTip>
                                                                </>
                                                                : ''}
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <div style={{ maxWidth: '80%' }} className="text-truncate">
                                                                    {deal.hotelIds?.length ?
                                                                        <div style={{ fontSize: '0.8em' }} className='text-truncate text-muted d-flex align-items-center' id={`deal-hotelList-${deal.id}`}>
                                                                            <i className="icon-icon-hotel mr-1" />
                                                                            {(() => {
                                                                                const hotel = global.hotelList?.find(({ value }) => value === deal.hotelIds[0]);
                                                                                return (
                                                                                    <div className='text-truncate'>
                                                                                        <div className='text-truncate'>{hotel?.label ?? ''}</div>
                                                                                        <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="right" target={`deal-hotelList-${deal.id}`}>
                                                                                            <PopoverBody>
                                                                                                {deal.hotelIds.map((h, k) => {
                                                                                                    const ho = global.hotelList.find(({ value }) => value === h);
                                                                                                    return (
                                                                                                        <div key={k}>{ho?.label ?? ''}</div>
                                                                                                    );
                                                                                                })}
                                                                                            </PopoverBody>
                                                                                        </CustomToolTip>
                                                                                    </div>
                                                                                );
                                                                            })()}
                                                                        </div>
                                                                        : ''}
                                                                </div>
                                                                <div>
                                                                    <b><FormatAmountNumber value={deal.totalValue || deal.expectedValue || 0} currency={hotelCurrency} /></b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            )}
                                        </div>
                                    </div>
                                )}
                            )}
                        </div>
                    </SalesProcessDefaultPage>
                </BlockUi>
            </div>
        )
    }
}

export default injectIntl(SalesPipelineStage);