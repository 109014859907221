import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Input, FormGroup } from 'reactstrap';
import moment from 'moment';
import EditorText from '../../Base/EditorText';


class SalesDealStep2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        const { handleChange, handleDate, deal, isLead } = this.props;

        return (
            <div>
                <FormGroup row className={global.isMobile ? "align-items-end" : ""}>
                    <Col className='col-lg col-6 pr-1 pr-lg-3' style={{ marginBottom: global.isMobile ? '1rem' : '' }}>
                        <div className="title-sm required"> <FormattedMessage id="SalesDeal.NegotiationStartDate" /></div>
                        <SingleDatePicker
                            id="negotiationStartDate"
                            date={deal.negotiationStartDate ? moment(deal.negotiationStartDate) : null}
                            onDateChange={date => handleDate('negotiationStartDate', date)}
                            focused={this.state.focused}
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            small={true}
                            numberOfMonths={1}
                            required={true}
                            showDefaultInputIcon={true}
                            showClearDate={true}
                        />
                    </Col>
                    <Col className='col-lg col-6 pl-1 pl-lg-3' style={{ marginBottom: global.isMobile ? '1rem' : '' }}>
                        <div className={`title-sm ${(!deal.negotiationStartDate || deal.status === 'InProgress') ? '' : ''}`}> <FormattedMessage id="SalesDeal.NegotiationEndDate" /></div>
                        <SingleDatePicker
                            id="negotiationEndDate"
                            isOutsideRange={day => deal.negotiationStartDate ? day <= moment(deal.negotiationStartDate) : null}
                            date={deal.negotiationEndDate ? moment(deal.negotiationEndDate) : null}
                            onDateChange={date => handleDate('negotiationEndDate', date)}
                            focused={this.state.focused2}
                            onFocusChange={({ focused }) => this.setState({ focused2: focused })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            disabled={!deal.negotiationStartDate || deal.status === 'InProgress'}
                            showClearDate={true}
                        />
                    </Col>
                    {!isLead ?
                        <Col className='col-lg col-12'>
                            <div className="title-sm"> <FormattedMessage id="SalesDeal.Budget" /></div>
                            <Input
                                type="number"
                                name="budget"
                                placeholder=""
                                value={deal.budget || ''}
                                onChange={handleChange}
                            />
                        </Col>
                    :''}
                </FormGroup>
                {!isLead ?
                    <FormGroup row>
                        <Col className='col-lg col-6 pr-1 pr-lg-3'>
                            <div className="title-sm"> <FormattedMessage id="SalesDeal.ExpectedEndDate" /></div>
                            <SingleDatePicker
                                id="expectedEndDate"
                                isOutsideRange={day => day <= moment()}
                                date={deal.expectedEndDate ? moment(deal.expectedEndDate) : ''}
                                onDateChange={date => handleDate('expectedEndDate', date || null)}
                                focused={this.state.focused3}
                                onFocusChange={({ focused }) => this.setState({ focused3: focused })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                showClearDate={true}
                            />
                        </Col>
                        <Col className='col-lg col-6 pl-1 pl-lg-3'>
                            <div className="title-sm"> <FormattedMessage id="SalesDeal.ExpectedValue" /></div>
                            <Input
                                type="number"
                                name="expectedValue"
                                placeholder=""
                                value={deal.expectedValue || ''}
                                onChange={handleChange}
                            />
                        </Col>
                    </FormGroup>
                :''}
                <FormGroup row>
                    <Col>
                        <div className="title-sm required">
                            <FormattedMessage id="SalesDeal.Summary"/>
                        </div>
                        <EditorText
                            editorState={deal.editorStateHtml}
                            onEditorStateChange={(editorState) => this.props.onEditorStateChange(editorState, 'editorStateHtml', 'summary')}
                            placeholder=""
                        />
                    </Col>
                </FormGroup>
            </div>
        )
    }
}

export default injectIntl(SalesDealStep2);