import React, { Component } from 'react';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { Button, Card, Col, Row, Nav, NavItem, NavLink, PopoverBody } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { deleteAPI, getAPI, postAPI, putAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { errorHandler } from '../Marketing/CommunicationJourney/CommonFunctions';
import { CustomFormatDate, DealStatusBadge, getInitiativeActionType, getInitiativeStatus, getInitiativeType, InitiativeStatusBadge } from './SalesProcessFunctions';
import { CommonHelper, CoolTooltip, FormatAmountNumber, KebabMenu } from '../Base/CommonUIComponents';
import countryList from 'react-select-country-list';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import CustomToolTip from '../Base/CustomToolTip';
import SalesInitiativeModal from './SalesInitiativeModal/SalesInitiativeModal';
import SalesInitiativeActions from './SalesInitiativeActions';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import SalesActionModal from './SalesActionModal';
import AssociationModal from './SalesDealModal/AssociateDeal';
import ChangeAssociationTypeModal from './SalesDealModal/ChangeAssociationTypeModal';
import CsvProfilesTable from './SalesInitiativeModal/CsvProfilesTable';
import { AssociateProfile } from '../Card/VoucherDetails/AssociationModals';
import { deepClone, getSalesDealActionTypes } from '../Base/ReferenceDataFunctions';
import * as ExcelJS from 'exceljs';
import moment from 'moment';

class SalesInitiativeDetail extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            initiative: {},
            statusOptions: false,
            queryString: '',
            roles: [],
            modal: false,
            initiativeStatusOptions: getInitiativeStatus(this.props.intl),
            confirmActionModal: false,
            actionModal: false,
            selectedAction: null,
            associatedDeals: [],
            associatedModal: false,
            changeAssociationTypeModal: false, 
            selectedDealRelationTypeId: null, 
            selectedDealRelationId: null, 
            selectedDealId: null,
            associatedInitiative: [],
            deleteDealAssociationModal: false,
            selectedDealRelationId: null,
            changeAssociationTypeModal: false,
            activeTab: 'Overview',
            confirmDeleteInitiativeModal: false,
            specificEditStep: null,
            profileTypeOptions: [
                { value: "Person", label: this.props.intl.formatMessage({ id: "ReferenceDataFunctions.Person" }) },
                { value: "Company", label: this.props.intl.formatMessage({ id: "ReferenceDataFunctions.Company" }) },
                { value: "Agency", label: this.props.intl.formatMessage({ id: "ReferenceDataFunctions.Agency" }) },
                { value: "Group", label: this.props.intl.formatMessage({ id: "ReferenceDataFunctions.Group" }) },
                { value: "CRO", label: this.props.intl.formatMessage({ id: "ReferenceDataFunctions.CRO" }) },
                { value: "TimeSharing", label: this.props.intl.formatMessage({ id: "ReferenceDataFunctions.TimeSharing" }) },
                { value: "Owner", label: this.props.intl.formatMessage({ id: "ReferenceDataFunctions.Owner" }) },
                { value: "Supplier", label: this.props.intl.formatMessage({ id: "ReferenceDataFunctions.Supplier" }) }
            ],
            csvProfiles: [],
            csvProfilesModal: false,
            blockProfilesModal: false,
            profilesModalError: null,
            profileRoleOptions: [],
            associateProfileModal: false,
            selectedProfile: null,
            addProfileList: [],
            createModal: false,
            createInitiativeCopyModal: false
        };
    }

    componentDidMount(){
        const queryString = window.location.search;

        this.setState({
            queryString,
        }, () => this.getInitiativeDetail());
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedInitiativeId !== this.props.selectedInitiativeId && this.props.selectedInitiativeId){
            this.getInitiativeDetail();
        }
    }

    getInitiativeDetail = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false }, () => this.getRoles());
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.getRoles();
                }
                if(data.data && data.data[0]){
                    const initiative = data.data && data.data[0];

                    if(initiative.summary){
                        const blocksFromHtml = htmlToDraft(initiative.summary);
                        if (blocksFromHtml) {
                            const { contentBlocks, entityMap } = blocksFromHtml;
        
                            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        
                            initiative.editorStateHtml = EditorState.createWithContent(contentState);
                        }
                    }

                    if(initiative.report){                    
                        const blocksFromHtml2 = htmlToDraft(initiative.report);
                        if (blocksFromHtml2) {
                            const { contentBlocks, entityMap } = blocksFromHtml2;
        
                            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        
                            initiative.editorStateHtml2 = EditorState.createWithContent(contentState);
                        }
                    }
                    
                    const associatedInitiative = [];

                    if(initiative?.relatedDeals){
                        initiative.relatedDeals.forEach(d => {
                            if(d.relationName && d.relationId !== null && d.relationId !== undefined){
                                const associationExists = associatedInitiative.find(ad => ad.relationId === d.relationId)
                                if(!associationExists){
                                    associatedInitiative.push({
                                        relationId: d.relationId,
                                        relationName: d.relationName,
                                        fixedValue: d.relatedFixedValue,
                                        initiatives: [d]
                                    });
                                }else{
                                    associatedInitiative.find(ad => ad.relationId === d.relationId).initiatives.push(d);
                                }
                            }
                        });
                    }
                    
                    this.setState({ initiative, associatedInitiative, block: false }, () => this.getRoles());
                    return;
                }
                else{
                    this.setState({ error: errorMessage, block: false }, () => this.getRoles());
                }
            }
            else this.setState({ error: errorMessage, block: false }, () => this.getRoles());
        }, `/api/gms/SalesProcess/v1/initiative/${this.props.selectedInitiativeId}`);
    }

    changeStatus = (status) => {
        this.setState({ block: true }, () => {
            const { initiative } = this.state;
            if(!initiative) return;

            const body = {
                ...initiative,
                status
            }
            
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    this.updateInitiative(data, true);
                    handleNotification(data, <FormattedMessage id="SalesProcess.InitiativeStatusChanged" />, <FormattedMessage id="generic.success" />);


                    this.setState({ block: false, confirmDeleteInitiativeModal: false, statusOptions: false, statusOptions2: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/initiatives`, body);
        });
    }

    getRoles = () => {
        getAPI((result) => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger",
                });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.data && data.data.length > 0) {
                    const profileRoleOptions = data.data.map(el => ({
                        label: el.name,
                        value: el.id
                    }));

                    this.setState({ roles: data.data, profileRoleOptions, block: false });
                    return;
                }else if(data.errors && data.errors.length > 0){
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/customtypes?type=DealProfileRole`);
    }

    changeActionPin = (e, action) => {
        if (e) e.preventDefault();

        if (action) {
            action.pinned = !action.pinned;
            this.setState({ block: true }, () => {
                const { initiative } = this.state;

                postAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];

                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data && data.errors && data.errors.length > 0) {
                            handleNotification(data);
                            this.setState({ block: false });
                            return;
                        }

                        if (initiative) {
                            this.setState({
                                initiative,
                                block: false
                            }, () => handleNotification(data, <FormattedMessage id="SalesDeal.ActionSavedSuccessfully" />, <FormattedMessage id="generic.success" />));
                        } else {
                            this.setState({ block: false });
                        }
                        return;
                    }
                    else this.setState({ error: errorMessage, block: false });
                }, `/api/gms/SalesProcess/v1/deals/action`, { ...action });
            })
        }
    }

    toggleModal = (specificEditStep) => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
            specificEditStep: isNaN(specificEditStep) ? null : specificEditStep,
        }));
    };

    updateInitiative = (initiative, updateCustomers) => {
        if (updateCustomers) {
            this.updateDetail();
        }
        else{
            this.setState({ initiative });
        }
    }

    updateDetail = () => {
        this.setState({ block: true }, () => this.getInitiativeDetail());
    }

    handleFinnishButton = () => {
        const { initiative } = this.state;

        let newStatus = "Done";

        if(initiative.status === "Done" || initiative.status === "Scheduled"){
            newStatus = "OnGoing";
        }

        this.changeStatus(newStatus);
    }

    handleChangeStatus = (newStatus) => {
        if(newStatus === "Cancelled"){
            this.toggleConfirmDeleteInitiativeModal();
        }
        else {
            this.changeStatus(newStatus);
        }
    }

    toggleActionModal = (selectedAction, event) => {
        const { intl } = this.props;

        if (this.state.actionModal) {
            if (!window.confirm(intl.formatMessage({ id: 'stripo.confirmUnsaved' }))) {
                event.preventDefault();
            }
            else {
                this.setState({ actionModal: !this.state.actionModal, selectedAction });
            }
        }
        else {
            this.setState({ actionModal: !this.state.actionModal, selectedAction });
        }
    }

    resetFiltersAndSearch = () => {
        this.setState({
            confirmActionModal: false,
            actionModal: false
        }, () => this.getInitiativeDetail());
    }

    changeInitiativeStatus = () => {
        const { changeToStatus, selectedAction } = this.state;
        this.setState({ block: true }, () => {
            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    handleNotification(data, <FormattedMessage id="SalesProcess.InitiativeStatusChanged"/>, <FormattedMessage id="generic.success"/>);

                    this.setState({ block: false, confirmActionModal: false }, () => this.getInitiativeDetail());
                    return;
                }
                else this.setState({ error: errorMessage,  block: false });
            }, `/api/gms/SalesProcess/v1/deals/action/${selectedAction.id}/status/${changeToStatus}`);
        });
    }

    toggleConfirmActionModal = (action, status) => {
        this.setState({
            confirmActionModal: !this.state.confirmActionModal,
            selectedAction: action,
            changeToStatus: status
        })
    }

    toggleAssociatedModal = () => {
        this.setState({ associatedModal: !this.state.associatedModal });
    }

    associateDeal = (e, dealAId, dealBId, relationId, salesDealRelationId) => {
        if(e){
            e.preventDefault();
        }

        const body = {
            initiativeId: dealAId,
            dealId: dealBId,
            relationId,
            id: salesDealRelationId ?? null
        }

        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    if(data.data && data.data.length > 0){
                        this.setState({ 
                            associatedModal: false,
                            changeAssociationTypeModal: false, 
                            selectedDealRelationTypeId: null, 
                            selectedDealRelationId: null, 
                            selectedDealId: null 
                        }, () => this.getInitiativeDetail());
                        return;
                    }

                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/initiativerelation`, body);
        });
    }

    toggleDeleteDealAssociationModal = (salesDealRelationId) => {
        this.setState({ deleteDealAssociationModal: !this.state.deleteDealAssociationModal, selectedDealRelationId: salesDealRelationId });
    }

    toggleChangeAssociationTypeModal = (selectedDealRelationTypeId, selectedDealRelationId, selectedDealId) => {
        this.setState({ changeAssociationTypeModal: !this.state.changeAssociationTypeModal, selectedDealRelationTypeId, selectedDealRelationId, selectedDealId });
    }

    deleteDealAssociation = (salesDealRelationId) => {
        this.setState({ block: true }, () => {
            const { deal } = this.state;

            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }else{
                        this.setState({ deleteDealAssociationModal: false, selectedDealRelationId: null }, () => this.getInitiativeDetail());
                    }

                    this.setState({ deal, block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/initiativerelation/${salesDealRelationId}`);
        });
    }

    toggleTab = (tab) => {
        if (tab !== this.state.activeTab)
            this.setState({ activeTab: tab });
    }

    toggleConfirmDeleteInitiativeModal = () => {
        this.setState({ confirmDeleteInitiativeModal: !this.state.confirmDeleteInitiativeModal });
    }

    convertHtmlToString = (html) => {
        if(!html) return '-';
        return html.replace(/<[^>]*>?/gm, '');
    };

    downloadInitiativeDetails = () => {
        const { initiative, associatedInitiative } = this.state;
        const { intl, salesUsers } = this.props;

        if(!initiative || !intl) return;
    
        const workbook = new ExcelJS.Workbook();
        const initiativeDetailsWorksheet = workbook.addWorksheet(intl.formatMessage({ id: 'SalesDeal.InitiativeOverview' }));
        const initiativeProfilesWorksheet = workbook.addWorksheet(intl.formatMessage({ id: 'SalesDeal.InitiativeProfiles' }));
        const initiativeActionsWorksheet = workbook.addWorksheet(intl.formatMessage({ id: 'SalesDeal.InitiativeActions' }));
        
        if (initiativeDetailsWorksheet) {
            const baseColumns = [
                { header: intl.formatMessage({ id: "SalesProcess.InitiativeName" }), key: "initiativeName", width: 30 },
                { header: intl.formatMessage({ id: "SalesProcess.CreationDate" }), key: "creationDate", width: 30 },
                { header: intl.formatMessage({ id: "SalesProcess.Type" }), key: "type", width: 30 },
                { header: intl.formatMessage({ id: "SalesProcess.ActionType" }), key: "actionType", width: 30 },
                { header: intl.formatMessage({ id: "SalesProcess.Location" }), key: "location", width: 30 },
                { header: intl.formatMessage({ id: "SalesProcess.Budget" }), key: "budget", width: 30 },
                { header: intl.formatMessage({ id: "SalesProcess.Cost" }), key: "cost", width: 30 },
                { header: intl.formatMessage({ id: "SalesProcess.RegistrationDate" }), key: "registrationDate", width: 30 },
                { header: intl.formatMessage({ id: "SalesProcess.PaymentDueDate" }), key: "paymentDueDate", width: 30 },
                { header: intl.formatMessage({ id: "SalesProcess.Summary" }), key: "summary", width: 120 },
                { header: intl.formatMessage({ id: "SalesProcess.Report" }), key: "report", width: 120 },
            ];
            
            if (initiative.statusReason) {
                baseColumns.push({ header: intl.formatMessage({ id: "SalesDeal.CancellationReason" }), key: "cancellationReason", width: 30 });
            }
            
            let currentRow = 1;
            const addTable = (worksheet, columns, rows, mergeUnderCells) => {
                const headerRow = worksheet.getRow(currentRow);

                headerRow.values = columns.map(col => col.header);
                headerRow.font = { bold: true, name: 'Roboto' };
                headerRow.alignment = { vertical: 'middle', horizontal: 'center' };
                headerRow.height = 30;

                headerRow.eachCell({ includeEmpty: true }, (cell) => {
                    cell.border = { 
                        top: { style: "thin", color: { argb: "D9D9D9" } }, 
                        bottom: {}, 
                        left: { style: "thin", color: { argb: "D9D9D9" } }, 
                        right: { style: "thin", color: { argb: "D9D9D9" } }
                    };
                    cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FFFFFF" } };
                });
            
                rows.forEach((rowData, rowIndex) => {
                    const valuesRow = worksheet.getRow(currentRow + rowIndex + 1);
                    valuesRow.values = columns.map(col => rowData[col.key] || '-');
                    valuesRow.alignment = { vertical: 'middle', horizontal: 'center' };
                    valuesRow.height = 30;
            
                    valuesRow.eachCell({ includeEmpty: true }, (cell) => {
                        cell.border = {
                            left: { style: "thin", color: { argb: "D9D9D9" } },
                            right: { style: "thin", color: { argb: "D9D9D9" } },
                            top: {}, 
                            bottom: rowIndex === rows.length - 1 ? { style: "thin", color: { argb: "D9D9D9" } } : {}
                        };
                        cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: rowIndex % 2 === 0 ? "FFF3F3F3" : "FFFFFF" } };
                    });
                });
            
                currentRow += rows.length + 3;

                initiativeDetailsWorksheet.mergeCells(`A${currentRow-2}:K${currentRow-1}`);
            };
            
            initiativeDetailsWorksheet.columns = baseColumns;
            addTable(initiativeDetailsWorksheet, baseColumns, [{
                initiativeName: initiative.name ?? '-',
                creationDate: initiative.createdAt ? CustomFormatDate(initiative.createdAt, null, null, intl) : '-',
                type: getInitiativeType(intl).find(({ value }) => value === initiative.type)?.label ?? '-',
                actionType: getInitiativeActionType(intl).find(({ value }) => value === initiative.actionType)?.label ?? '-',
                location: initiative.location ?? '-',
                budget: initiative.budget ? intl.formatNumber(initiative.budget, { maximumFractionDigits: 2, style: "currency", currency: global.hotelCurrency }) : '-',
                cost: initiative.cost ? intl.formatNumber(initiative.cost, { maximumFractionDigits: 2, style: "currency", currency: global.hotelCurrency }) : '-',
                registrationDate: initiative.registrationDate ? CustomFormatDate(initiative.registrationDate, null, null, intl) : '-',
                paymentDueDate: initiative.paymentDueDate ? CustomFormatDate(initiative.paymentDueDate, null, null, intl) : '-',
                cancellationReason: initiative.statusReason ?? '-',
                summary: initiative.summary ? this.convertHtmlToString(initiative.summary) : '-',
                report: initiative.report ? this.convertHtmlToString(initiative.report) : '-',
            }], true);
            
            if (initiative.hotelIds?.length > 0) {
                addTable(initiativeDetailsWorksheet, [{ header: intl.formatMessage({ id: "SalesProcess.HotelIds" }), key: "hotelIds", width: 30 }], initiative.hotelIds.map(hotelId => ({
                    hotelIds: global.hotelList?.find(x => x.value === hotelId)?.label || hotelId || '-'
                })), true);
            }
            
            if (initiative.markets?.length > 0) {
                addTable(initiativeDetailsWorksheet, [
                    { header: intl.formatMessage({ id: "SalesProcess.Markets" }), key: "markets", width: 30 }
                ], initiative.markets.map(market => ({
                    markets: market?.name || '-'
                })), true);
            }
            
            if (initiative.salesInitiativeUsers?.length > 0) {
                addTable(initiativeDetailsWorksheet, [
                    { header: intl.formatMessage({ id: "SalesProcess.Staff" }), key: "staff", width: 30 },
                    { header: intl.formatMessage({ id: "SalesProcess.StaffRole" }), key: "role", width: 30 },
                    { header: intl.formatMessage({ id: "SalesProcess.StaffNotes" }), key: "notes", width: 30 }
                ], initiative.salesInitiativeUsers.map(u => {
                    const user = salesUsers?.find(({ id }) => id === u.userId);
                    return {
                        staff: user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : '-',
                        role: u?.role || '-',
                        notes: u?.notes || '-'
                    };
                }), true);
            }
            
            if (associatedInitiative?.length > 0) {
                const associatedRows = [];
                associatedInitiative.forEach(association => {
                    if (association.initiatives?.length > 0) {
                        association.initiatives.forEach(relatedDeal => {
                            associatedRows.push({
                                associated: relatedDeal.relatedDealName,
                                dealAssociatedType: !relatedDeal?.isLead ? intl.formatMessage({ id: "SalesProcess.Deal" }) : intl.formatMessage({ id: "SalesProcess.Lead" })
                            });
                        });
                    }
                });
                
                if (associatedRows?.length > 0) {
                    addTable(initiativeDetailsWorksheet, [
                        { header: intl.formatMessage({ id: "SalesProcess.Associated" }), key: "associated", width: 30 },
                        { header: intl.formatMessage({ id: "SalesProcess.DealAssociatedType" }), key: "dealAssociatedType", width: 30 }
                    ], associatedRows);
                }
            }
        }

        if (initiativeProfilesWorksheet) {
            const profileColumns = [
                { header: intl.formatMessage({ id: "SalesDeal.Name" }), key: "name", width: 30 },
                { header: intl.formatMessage({ id: "SalesDeal.Role" }), key: "role", width: 30 },
                { header: intl.formatMessage({ id: "SalesProcess.Nationality" }), key: "nationality", width: 30 },
                { header: intl.formatMessage({ id: "SalesDeal.Email" }), key: "email", width: 30 },
                { header: intl.formatMessage({ id: "SalesDeal.Mobile" }), key: "mobile", width: 30 },
                { header: intl.formatMessage({ id: "SalesDeal.Notes" }), key: "notes", width: 30 }
            ];
        
            initiativeProfilesWorksheet.columns = profileColumns;
        
            
            const headerRow = initiativeProfilesWorksheet.getRow(1);
            headerRow.height = 30;

            headerRow.eachCell((cell) => {
                cell.alignment = { horizontal: "center", vertical: "middle" };
                cell.font = { bold: true, name: 'Roboto' };
                cell.border = { 
                    top: { style: "thin", color: { argb: 'D9D9D9' } },
                    left: { style: "thin", color: { argb: 'D9D9D9' } },
                    bottom: {},
                    right: { style: "thin", color: { argb: 'D9D9D9' } }
                };
                cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FFFFFF" } };
            });
        
            if (initiative.salesInitiativeProfile?.length) {
                initiative.salesInitiativeProfile.forEach((profile, index) => {
                    const row = initiativeProfilesWorksheet.addRow({
                        name: profile.name || '-',
                        role: profile.role || '-',
                        nationality: countryList().getData().find(country => country.value === profile.nationality)?.label ?? '-',
                        email: profile.email || '-',
                        mobile: profile.mobile || '-',
                        notes: profile.notes || '-'
                    }, 'i');
        
                    row.eachCell((cell, colNumber) => {
                        cell.border = { 
                            top: {},
                            left: { style: "thin", color: { argb: 'D9D9D9' } },
                            right: { style: "thin", color: { argb: 'D9D9D9' } },
                            bottom: index === initiative.salesInitiativeProfile.length - 1 ? { style: "thin", color: { argb: 'D9D9D9' } } : {}
                        };
                        cell.font = { bold: false, name: 'Roboto' };
                        cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: index % 2 === 0 ? "F2F2F2" : "FFFFFF" } };
                    });
                });
            }
        }

        if(initiativeActionsWorksheet){
            const actionTypeOptions = getSalesDealActionTypes(intl, true, undefined, false);
            const actionsColumns = [
                { header: intl.formatMessage({ id: "SalesProcess.Type" }), key: "type", width: 30 },
                { header: intl.formatMessage({ id: "SalesProcess.Title" }), key: "title", width: 30 },
                { header: intl.formatMessage({ id: "SalesProcess.StartDate" }), key: "startDate", width: 30 },
                { header: intl.formatMessage({ id: "SalesProcess.EndDate" }), key: "endDate", width: 30 },
                { header: intl.formatMessage({ id: "SalesDeal.Profiles" }), key: "profiles", width: 30 },
                { header: intl.formatMessage({ id: "SalesDeal.Users" }), key: "users", width: 30 },
                { header: intl.formatMessage({ id: "SalesProcess.Description" }), key: "description", width: 120 }
            ];

            initiativeActionsWorksheet.columns = actionsColumns;

            const headerRow = initiativeActionsWorksheet.getRow(1);
            headerRow.height = 30;

            headerRow.eachCell((cell) => {
                cell.alignment = { horizontal: "center", vertical: "middle" };
                cell.font = { bold: true, name: 'Roboto' };
                cell.border = { 
                    top: { style: "thin", color: { argb: 'D9D9D9' } },
                    left: { style: "thin", color: { argb: 'D9D9D9' } },
                    bottom: {},
                    right: { style: "thin", color: { argb: 'D9D9D9' } }
                };
                cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FFFFFF" } };
            });

            if(initiative.salesDealActions?.length){
                initiative.salesDealActions.forEach((action, index) => {
                    const row = initiativeActionsWorksheet.addRow({
                        type: actionTypeOptions.find(({ value }) => value === action.type)?.label ?? '-',
                        title: action.title || '-',
                        description: action.summary ? this.convertHtmlToString(action.summary) || '-' : '-',
                        startDate: action.startDate ? CustomFormatDate(action.startDate, null, null, intl) : '-',
                        endDate: action.endDate ? CustomFormatDate(action.endDate, null, null, intl) : '-',
                        profiles: action.salesDealActionProfiles?.length ? action.salesDealActionProfiles.map(u => u.name).join(', ') : '-',
                        users: action.salesDealActionUsers?.length ? action.salesDealActionUsers.map(p => p.name).join(', ') : '-'
                    }, 'i');

                    row.eachCell((cell) => {
                        cell.border = { 
                            top: {},
                            left: { style: "thin", color: { argb: 'D9D9D9' } },
                            right: { style: "thin", color: { argb: 'D9D9D9' } },
                            bottom: index === initiative.salesDealActions.length - 1 ? { style: "thin", color: { argb: 'D9D9D9' } } : {}
                        };
                        cell.font = { bold: false, name: 'Roboto' };
                        cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: index % 2 === 0 ? "F2F2F2" : "FFFFFF" } };
                        cell.alignment = { horizontal: "center", vertical: "middle", wrapText: true };
                    });
                });
            }
        }

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `${initiative.name} ${moment().format('DD/MM/YYYY')}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch((error) => {
            handleNotification({ warnings: [{ message: this.props.intl.formatMessage({ id: 'SalesDeal.ErrorDownloadingProfilesStructure' }) }] });
        });
    };

    downloadProfilesStructure = () => {
        const { profileTypeOptions, profileRoleOptions } = this.state;
    
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Profiles Structure");
    
        if (worksheet) {
            worksheet.columns = [
                { header: "type", key: "type", width: 20 },
                { header: "firstName", key: "firstName", width: 20 },
                { header: "lastName", key: "lastName", width: 20 },
                { header: "role", key: "role", width: 20 },
                { header: "email", key: "email", width: 20 },
                { header: "mobile", key: "mobile", width: 20 },
                { header: "nif", key: "nif", width: 20 },
                { header: "notes", key: "notes", width: 80 }
            ];

            worksheet.getRow(1).eachCell((cell) => {
                cell.alignment = { horizontal: "center" };
            });

            const typeOptionsValues = profileTypeOptions ? [`"${profileTypeOptions.map(t => t.value)?.join()}"`] : ['"-"'];
            const roleOptionsValues = profileRoleOptions ? [`"${profileRoleOptions.map(r => r.label)?.join()}"`] : ['"-"'];

            worksheet.dataValidations.add("A2:A9999",{
                type: 'list',
                allowBlank: false,
                formulae: typeOptionsValues
            });

            worksheet.dataValidations.add("D2:D9999",{
                type: 'list',
                allowBlank: false,
                formulae: roleOptionsValues
            });
    
            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = "profiles_structure_example.xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((error) => {
                handleNotification({ warnings: [{ message: this.props.intl.formatMessage({ id: 'SalesDeal.ErrorDownloadingProfilesStructure' }) }] });
            });
        }
    };

    uploadProfilesStructure = async (event) => {
        if (!event?.target?.files || event.target.files.length === 0) return;
    
        const file = event.target.files[0];
        if (!file) return;

        if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            handleNotification({ warnings: [{ message: this.props.intl.formatMessage({ id: 'SalesDeal.InvalidFileType' }) }] });
            return;
        }
    
        event.target.value = null;
    
        if (window.FileReader && file.size < 2097152) {
            const reader = new FileReader();
            
            reader.onload = async (e) => {
                try {
                    const buffer = e.target.result;
                    const workbook = new ExcelJS.Workbook();
                    await workbook.xlsx.load(buffer);
                    
                    const worksheet = workbook.worksheets[0];
                    if (!worksheet) return;
                    
                    const correctHeaders = ["type", "firstName", "lastName", "role", "email", "mobile", "nif", "notes"];
                    let profiles = [];  
                    let headers = [];
    
                    worksheet.eachRow((row, rowIndex) => {
                        const rowValues = row?.values?.slice(1);
                        
                        if(rowValues?.length > 0){
                            if (rowIndex === 1) {
                                headers = rowValues.map((header) => String(header)?.trim() || "");
                            } else {
                                const rowData = rowValues.map((cell) => {
                                    if (cell && typeof cell === "object" && cell.text !== undefined) {
                                        return String(cell.text).trim();
                                    } else if (cell !== null && cell !== undefined) {
                                        return String(cell).trim();
                                    }
                                    return "";
                                });
                                
                                const profileParsed = this.validateProfileData(rowData, headers);
                                if (profileParsed) {
                                    profiles.push(profileParsed);
                                }
                            }
                        }
                    });

                    if(!headers || headers.length < 8 || correctHeaders.some(ch => !headers.includes(ch))){
                        handleNotification({ warnings: [{ message: this.props.intl.formatMessage({ id: 'SalesDeal.MissingProfileFileHeaders' })}] });
                        return;
                    }

                    if (!profiles || profiles.length === 0) {
                        handleNotification({ warnings: [{ message: this.props.intl.formatMessage({ id: 'SalesDeal.InvalidProfilesFile' })}] });
                        return;
                    }
    
                    this.setState({ csvProfiles: profiles, csvProfilesModal: true });
                    return;
                } catch (error) {
                    console.error("Error reading the Excel file:", error);
                }
            };
    
            reader.onerror = () => {
                console.error("Error reading the file.");
            };
    
            reader.readAsArrayBuffer(file);
        } else {
            errorHandler("FileReader is not supported in this browser.");
        }
    };

    validateProfileData = (row, headers) => {
        const { profileRoleOptions, profileTypeOptions } = this.state;

        const profileRawData = { missingFields: [], wrongSyntaxFields: [], status: true };
        headers.forEach((header, index) => {
            profileRawData[header.trim()] = row[index];
        });

        const { type, firstName, lastName, role, email, mobile, nif } = profileRawData;

        const roleId = profileRoleOptions?.find((option) => option.label?.toLowerCase() === role?.toLowerCase())?.value;

        if (!type || !profileTypeOptions.some((option) => option.value?.toLowerCase() === type?.toLowerCase())) {
            profileRawData.missingFields.push('Type');
            profileRawData.status = false;
        }
        if (!firstName) {
            profileRawData.missingFields.push('FirstName');
            profileRawData.status = false;
        }
        if (!lastName) {
            profileRawData.missingFields.push('LastName');
            profileRawData.status = false;
        }
        if (!role || !profileRoleOptions.some((option) => option.label?.toLowerCase() === role?.toLowerCase()) || !roleId) {
            profileRawData.missingFields.push('Role');
            profileRawData.status = false;
        }
        if(!email){
            if(!mobile && !nif){
                profileRawData.missingFields.push('Email');
            }
        }else if (!email?.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            profileRawData.wrongSyntaxFields.push('Email');
        }
        if(!mobile){
            if(!email && !nif){
                profileRawData.missingFields.push('Mobile');
            }
        }else if (!mobile?.match(/^[\+]{0,1}(\d{9,15}|[\(][\+]{0,1}\d{2,}[\)]*\d{5,13}|\d{2,6}[\-]{1}\d{2,13}[\-]*\d{3,13})$/g)) {
            profileRawData.wrongSyntaxFields.push('Mobile');
        }
        if (!nif) {
            if(!email && !mobile){
                profileRawData.missingFields.push('NIF');
            }
        }
        if((profileRawData.wrongSyntaxFields?.length > 0) || (profileRawData?.missingFields?.includes('Email') && profileRawData?.missingFields?.includes('Mobile') && profileRawData?.missingFields?.includes('NIF'))){
            profileRawData.status = false;
        }

        return {
            type,
            firstName,
            lastName,
            role,
            roleId,
            profileContacts: [...(email ? [{ contact: email, type: "Email" }] : []), ...(mobile ? [{ contact: mobile, type: "Mobile" }] : [])].length ? 
                [...(email ? [{ contact: email, type: "Email", active: true, main: true }] : []), ...(mobile ? [{ contact: mobile, type: "Mobile", active: true }] : [])]
            : 
                null,
            profileDocuments: nif ? [{ type: "FiscalNumber", number: nif }] : null,
            notes: profileRawData.notes,
            status: profileRawData.status,
            missingFields: profileRawData.missingFields,
            wrongSyntaxFields: profileRawData.wrongSyntaxFields
        };
    };

    toggleImportProfilesModal = (updateInitiative) => {
        this.setState((prevState) => ({ csvProfilesModal: !prevState.csvProfilesModal, csvProfiles: prevState.csvProfilesModal ? [] : prevState.csvProfiles}), () => {
            if (updateInitiative) {
                this.setState({ modal: false }, () => this.getInitiativeDetail());
            }
        });
    };

    toggleAssociateProfileModal = () => {
        this.setState((prevState) => ({ associateProfileModal: !prevState.associateProfileModal, selectedProfile: null }));
    };

    toggleCreateProfileModal = () => {
        this.setState((prevState) => ({ createModal: !prevState.createModal }));
    };

    selectProfile = (profile) => {
        this.setState({
            selectedProfile: {
                profileId: profile?.id,
                name: `${profile?.firstName || ''} ${profile?.lastName || ''}`.trim(),
                roleId: profile?.roleId,
                role: profile?.role, 
                notes: profile?.notes 
            },
        });
    };

    associateProfile = () => {
        const { selectedProfile, initiative } = this.state;
        
        const profiles = initiative.salesInitiativeProfile ? [...initiative.salesInitiativeProfile] : [];
        if(profiles.some(p => p.profileId === selectedProfile?.profileId)) {
            const message ={ warnings: [{ message: this.props.intl.formatMessage({ id: 'SalesDeal.ProfileAlreadyAssociated' }) }] }
            
            this.setState({ selectedProfile: null }, () => handleNotification(message));
            return;
        };
        profiles.push(selectedProfile);
        this.handleAddProfile(profiles);
    };

    associateNewProfile = (id, firstName, lastName, initiativeProfileFields) => {
        const { initiative } = this.state;

        const selectedProfile = {
            profileId: id,
            name: `${firstName ? firstName : ''} ${lastName ? lastName : ''}`,
            roleId: initiativeProfileFields?.roleId,
            role: initiativeProfileFields?.role,
            notes: initiativeProfileFields?.notes
        };

        const profiles = initiative?.salesInitiativeProfile ? [...initiative.salesInitiativeProfile] : [];
        profiles.push(selectedProfile);

        this.handleAddProfile(profiles);
    };

    handleAddProfile = (profiles) => {
        this.setState({ block: true }, () => {
            const { initiative } = this.state;
            
            const body = {
                ...initiative,
                salesInitiativeProfile: profiles
            };
            
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    handleNotification(data, <FormattedMessage id="SalesProcess.InitiativeSaved" />, <FormattedMessage id="generic.success" />);
                    this.setState({ associateProfileModal: false, selectedProfile: null, createModal: false, block: false }, () => this.getInitiativeDetail());
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/initiatives`, body);
        });
    };

    toggleCopyModal = (copyData) => {
        this.setState({
            createInitiativeCopyModal: !this.state.createInitiativeCopyModal,
            newInitiativeCopy: copyData
        })
    }

    copyInitiative = () => {
        const { initiative } = this.state;
        const newInitiativeCopy = deepClone(initiative);
        newInitiativeCopy.salesPipelineId = newInitiativeCopy.salesPipelineStage?.salesPipelineId;
        
        delete newInitiativeCopy.id;

        if (newInitiativeCopy.salesInitiativeProfile?.length > 0) {
            newInitiativeCopy.salesInitiativeProfile = newInitiativeCopy.salesInitiativeProfile.map(({ name, profileId, roleId }) => ({ name, profileId, roleId }))
        }
        if (newInitiativeCopy.salesInitiativeUsers?.length > 0) {
            newInitiativeCopy.salesInitiativeUsers = newInitiativeCopy.salesInitiativeUsers.map(({ role, userId }) => ({ role, userId }))
        }


        if (newInitiativeCopy.summary) {
            const blocksFromHtml = htmlToDraft(newInitiativeCopy.summary);
            if (blocksFromHtml) {
                const { contentBlocks, entityMap } = blocksFromHtml;

                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

                newInitiativeCopy.editorStateHtml = EditorState.createWithContent(contentState);
            }
        }

        if (newInitiativeCopy.report) {
            const blocksFromHtml2 = htmlToDraft(newInitiativeCopy.report);
            if (blocksFromHtml2) {
                const { contentBlocks, entityMap } = blocksFromHtml2;

                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

                newInitiativeCopy.editorStateHtml2 = EditorState.createWithContent(contentState);
            }
        }

        this.setState({
            newInitiativeCopy,
            createInitiativeCopyModal: true
        })
    }

    goToCopyDetails = (data) => {
        this.props.selectInitiativeDetail(data.id);
    }


    render() {
        const { error, block, statusOptions, initiative, modal, initiativeStatusOptions, confirmActionModal, actionModal, selectedAction, associatedInitiative,
        associatedModal, deleteDealAssociationModal, selectedDealRelationId, changeAssociationTypeModal, selectedDealRelationTypeId, selectedDealId, activeTab, changeToStatus,
        confirmDeleteInitiativeModal, specificEditStep, profileTypeOptions, profileRoleOptions, csvProfiles, csvProfilesModal, blockProfilesModal, profilesModalError, 
        associateProfileModal, selectedProfile, createModal,statusOptions2, createInitiativeCopyModal } = this.state;
        const { salesUsers, hotelList, intl, salesSourcesTypes, salesPipelines, salesSources, getDealList, dealList, selectDealDetail, returnToPreviousPage, renderActivityHelperMsg, totalDeals } = this.props;
        

        return (
            <>
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <Card className='border-0 shadow p-3 bg-white' style={{ minHeight: '90vh' }}>
                        {salesUsers?.length && initiative ?
                            <>
                                <Row className='d-flex align-items-center justify-content-between'>
                                    <Col className={`d-flex align-items-center justify-content-start col-9`}>
                                        <div onClick={_ => returnToPreviousPage()} className='p-3 cursor-pointer'>
                                            <i className='fas fa-chevron-left'/>
                                        </div>
                                        <div className='ml-2'>
                                            <div className="d-flex align-items-center">
                                                <h5 className='m-0'>
                                                    {initiative.name}
                                                </h5>
                                                <div className="ml-4">
                                                    {initiative.status ?
                                                        <InitiativeStatusBadge
                                                            status={initiative.status}
                                                        />
                                                    :''}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className='d-flex align-items-center justify-content-end col-3 col-lg'>
                                        <div className='mr-5'>
                                            {initiative.startDate ?
                                                <>
                                                    <span>{CustomFormatDate(initiative.startDate, null, null, intl)}</span>
                                                    <span className='mx-2'>-</span>
                                                </>
                                            :''}
                                            {initiative.endDate ?
                                                CustomFormatDate(initiative.endDate, null, null, intl)
                                            :''}
                                        </div>
                                        <div>
                                            {initiative.status === 'OnGoing' ?
                                                <>
                                                    {global.isMobile ? '' :
                                                        <>
                                                            <Button className="btn btn-host btn-sm" onClick={this.handleFinnishButton} style={{
                                                                background: initiative.status !== "Done" && initiative.status !== "Scheduled" ? '#36ad51' : '',
                                                                borderColor: initiative.status !== "Done" && initiative.status !== "Scheduled" ? '#36ad51' : '',
                                                                borderRadius: '0.2rem 0 0 0.2rem'
                                                            }}>
                                                                {initiative.status === "Done" || initiative.status === "Scheduled" ?
                                                                    <>
                                                                        <FormattedMessage id="ReferenceDataFunctions.OnGoing" />
                                                                        <i className="fas fa-people-arrows ml-2" />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <FormattedMessage id="SalesDeal.Finnish" />
                                                                        <i className="fas fa-trophy ml-2"></i>
                                                                    </>
                                                                }
                                                            </Button>
                                                            <Button onClick={_ => this.setState({ statusOptions: !this.state.statusOptions })} className="btn btn-host btn-sm" id="StatusOptions" style={{ borderRadius: '0 0.2rem 0.2rem 0', background: '#bfbfbf', border: '1px solid #bfbfbf' }}>
                                                                <i className='fas fa-chevron-down' />
                                                            </Button>
                                                        </>
                                                    }
                                                </>
                                            :
                                                <i className='fas fa-ellipsis-v pointer mr-2' onClick={_ => this.setState({ statusOptions: !this.state.statusOptions })} />
                                            }
                                            {statusOptions ?
                                                <div style={{ borderRadius: '5px', zIndex: '1050', right: '0', width: 'max-content', fontSize: '0.95em' }} className='shadow bg-white mt-1 position-absolute'>
                                                    <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer' onClick={_ => this.copyInitiative()}>
                                                        <i className="far fa-clone mr-2" />
                                                        <FormattedMessage id='SalesProcess.CopyInitiative' />
                                                    </div>
                                                    <hr className='m-0' />

                                                    <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer' onClick={_ => this.downloadInitiativeDetails()}>
                                                        <i className="far fa-file-excel mr-2" />
                                                        <FormattedMessage id="SalesProcess.DownloadInitiaitve" />
                                                    </div>
                                                    <hr className='m-0' />

                                                    <div>
                                                        {initiativeStatusOptions.filter(({value}) => value !== initiative.status && (initiative.status === 'OnGoing' ? value !== 'Done' && value !== 'OnGoing' : initiative.status !== value))
                                                        .map((status, key) =>
                                                            <React.Fragment key={key}>
                                                                {key ?
                                                                    <hr className='m-0'/>
                                                                :''}

                                                                <div className={`changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer ${status.value === "Cancelled" ? 'text-danger' : status.value === "Done" ? 'text-success' : ''}`} onClick={_ => this.handleChangeStatus(status.value)} style={{ color: status.value === "OnGoing" ? '#0665ff' : '' }}>
                                                                    {status.value === "OnGoing" ?
                                                                        <i className="fas fa-people-arrows mr-2"/>
                                                                    : status.value === "Scheduled" ?
                                                                        <i className="fas fa-users mr-2"/>
                                                                    : status.value === "Cancelled" ?
                                                                        <i className="fas fa-handshake-slash mr-2"/>
                                                                    : status.value === "Done" ?
                                                                        <i className="fas fa-trophy mr-2"/>
                                                                    :''}
                                                                    <span>{status.value === 'Done' ? <FormattedMessage id="SalesDeal.Finnish" /> : status.label}</span>
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                </div>
                                            :''}
                                        </div>
                                        <div>
                                            <CommonHelper help={<FormattedMessage id="SalesProcess.InitiativeDetailsHelp" />} id={'InitiativeDetailsHelp'} />
                                        </div>
                                    </Col>
                                </Row>
                                {global.isMobile && initiative.status === 'OnGoing' ? 
                                    <Row className="text-right">
                                        <Col>
                                            <Button className="btn btn-host btn-sm" onClick={this.handleFinnishButton} style={{
                                                background: initiative.status !== "Done" && initiative.status !== "Scheduled" ? '#36ad51' : '',
                                                borderColor: initiative.status !== "Done" && initiative.status !== "Scheduled" ? '#36ad51' : '',
                                                borderRadius: '0.2rem 0 0 0.2rem'
                                            }}>
                                                {initiative.status === "Done" || initiative.status === "Scheduled" ?
                                                    <>
                                                        <FormattedMessage id="ReferenceDataFunctions.OnGoing" />
                                                        <i className="fas fa-people-arrows ml-2" />
                                                    </>
                                                    :
                                                    <>
                                                        <FormattedMessage id="SalesDeal.Finnish" />
                                                        <i className="fas fa-trophy ml-2"></i>
                                                    </>
                                                }
                                            </Button>
                                            <Button onClick={_ => this.setState({ statusOptions2: !this.state.statusOptions2 })} className="btn btn-host btn-sm" id="StatusOptions2" style={{ borderRadius: '0 0.2rem 0.2rem 0', background: '#bfbfbf', border: '1px solid #bfbfbf' }}>
                                                <i className='fas fa-chevron-down' />
                                            </Button>
                                            {statusOptions2 ?
                                                <div style={{ borderRadius: '5px', zIndex: '1050', right: '0', width: 'max-content', fontSize: '0.95em' }} className='shadow bg-white mt-1 position-absolute'>
                                                    <div>
                                                        {initiativeStatusOptions.filter(({ value }) => value !== initiative.status && (initiative.status === 'OnGoing' ? value !== 'Done' && value !== 'OnGoing' : initiative.status !== value))
                                                            .map((status, key) =>
                                                                <React.Fragment key={key}>
                                                                    {key ?
                                                                        <hr className='m-0' />
                                                                        : ''}

                                                                    <div className={`changeStatusOption p-2 d-flex align-items-center justify-content-between cursor-pointer ${status.value === "Cancelled" ? 'text-danger' : status.value === "Done" ? 'text-success' : ''}`} onClick={_ => this.handleChangeStatus(status.value)} style={{ color: status.value === "OnGoing" ? '#0665ff' : '' }}>
                                                                        {status.value === "OnGoing" ?
                                                                            <i className="fas fa-people-arrows mr-2" />
                                                                            : status.value === "Scheduled" ?
                                                                                <i className="fas fa-users mr-2" />
                                                                                : status.value === "Cancelled" ?
                                                                                    <i className="fas fa-handshake-slash mr-2" />
                                                                                    : status.value === "Done" ?
                                                                                        <i className="fas fa-trophy mr-2" />
                                                                                        : ''}
                                                                        <span>{status.value === 'Done' ? <FormattedMessage id="SalesDeal.Finnish" /> : status.label}</span>
                                                                    </div>
                                                                </React.Fragment>
                                                            )}
                                                    </div>
                                                </div>
                                                : ''}
                                        </Col>
                                    </Row>
                                    : ''}
                                <Row>
                                    <Col>
                                        <Nav tabs className={`${'my-3'}`}>
                                            <NavItem>
                                                <NavLink className={activeTab === 'Overview' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('Overview')} style={{ cursor: "pointer" }}>
                                                    <i className="fas fa-briefcase mr-2" />
                                                    <FormattedMessage id="SalesDeal.Overview" />
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={activeTab === 'Actions' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('Actions')} style={{ cursor: "pointer" }}>
                                                    <i className="fas fa-play mr-2" />
                                                    <FormattedMessage id="SalesDeal.Actions" />
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </Col>
                                </Row>
                                {activeTab === "Overview" ?
                                    <div>
                                        <Row className='mt-2 h-100'>
                                            <Col className='pt-2 col-12 col-lg-9'>
                                                <div className='d-flex aling-items-center justify-content-between'>
                                                    <div>
                                                        <h5 className='m-0'>
                                                            <FormattedMessage id="SalesProcess.Initiative" />
                                                        </h5>
                                                    </div>
                                                    <div>
                                                        <Button id="editFieldBtnTooltip" onClick={this.toggleModal} className="btn btn-host btn-sm">
                                                            <i className="fas fa-edit" />
                                                        </Button>
                                                        <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="bottom" target={`editFieldBtnTooltip`}>
                                                            <PopoverBody>
                                                                <FormattedMessage id="SalesProcess.EditInitiativeButton" />
                                                            </PopoverBody>
                                                        </CustomToolTip>
                                                    </div>
                                                </div>
                                                <div className='mt-4'>
                                                    <Row>
                                                        <Col className='col-12'>
                                                            <div className='shadow w-100 bg-white p-3'>
                                                                <div className="text-muted d-flex align-items-center justify-content-between">
                                                                    <div>
                                                                        <i className="mr-2 fas fa-suitcase-rolling"></i>
                                                                        <FormattedMessage id="SalesProcess.Details" />
                                                                    </div>
                                                                    <i className="fas fa-edit cursor-pointer editFieldBtn" onClick={_ => this.toggleModal(1)}/>
                                                                </div>
                                                                <Row className='mt-2'>
                                                                    <Col className='col-6 col-lg-3'>
                                                                        <div className='title-sm'>
                                                                            <FormattedMessage id="SalesProcess.CreationDate" />
                                                                        </div>
                                                                        <div>
                                                                            {initiative.createdAt ?
                                                                                CustomFormatDate(initiative.createdAt, null, null, intl)
                                                                            : '-'}
                                                                        </div>
                                                                    </Col>
                                                                    <Col className='col-6 col-lg-3'>
                                                                        <div className='title-sm'>
                                                                            <FormattedMessage id="SalesProcess.Type" />
                                                                        </div>
                                                                        <div>
                                                                            {initiative.type ?
                                                                                getInitiativeType(intl).find(({ value }) => value === initiative.type)?.label ?? '-'
                                                                                : '-'}
                                                                        </div>
                                                                    </Col>
                                                                    <Col className='col-6 mt-2 col-lg-3 mt-lg-0'>
                                                                        <div className='title-sm'>
                                                                            <FormattedMessage id="SalesProcess.ActionType" />
                                                                        </div>
                                                                        <div>
                                                                            {initiative.actionType ?
                                                                                getInitiativeActionType(intl).find(({ value }) => value === initiative.actionType)?.label ?? '-'
                                                                                : '-'}
                                                                        </div>
                                                                    </Col>
                                                                    <Col className='col-6 mt-2 col-lg-3 mt-lg-0'>
                                                                        <div className='title-sm'>
                                                                            <FormattedMessage id="SalesProcess.Location" />
                                                                        </div>
                                                                        <div>
                                                                            {initiative.location ?? '-'}
                                                                        </div>
                                                                    </Col>

                                                                    <Col className='col-6 col-lg-3 mt-2'>
                                                                        <div className='title-sm'>
                                                                            <FormattedMessage id="SalesProcess.Budget" />
                                                                        </div>
                                                                        <div>
                                                                            {initiative.budget ?
                                                                                <FormatAmountNumber value={initiative.budget} />
                                                                                : '-'}
                                                                        </div>
                                                                    </Col>
                                                                    <Col className='col-6 col-lg-3 mt-2'>
                                                                        <div className='title-sm'>
                                                                            <FormattedMessage id="SalesProcess.Cost" />
                                                                        </div>
                                                                        <div>
                                                                            {initiative.cost ?
                                                                                <FormatAmountNumber value={initiative.cost} />
                                                                                : '-'}
                                                                        </div>
                                                                    </Col>
                                                                    <Col className='col-6 col-lg-3 mt-2'>
                                                                        <div className='title-sm'>
                                                                            <FormattedMessage id="SalesProcess.RegistrationDate" />
                                                                        </div>
                                                                        <div>
                                                                            {initiative.registrationDate ?
                                                                                CustomFormatDate(initiative.registrationDate, null, null, intl)
                                                                            : '-'}
                                                                        </div>
                                                                    </Col>
                                                                    <Col className='col-6 col-lg-3 mt-2'>
                                                                        <div className='title-sm'>
                                                                            <FormattedMessage id="SalesProcess.PaymentDueDate" />
                                                                        </div>
                                                                        <div>
                                                                            {initiative.paymentDueDate ?
                                                                            CustomFormatDate(initiative.paymentDueDate, null, null, intl)
                                                                                : '-'}
                                                                        </div>
                                                                    </Col>

                                                                    <Col className="col-6 mt-2">
                                                                        <div className='title-sm'>
                                                                            <FormattedMessage id="SalesProcess.HotelIds" />
                                                                        </div>
                                                                        <div>
                                                                            {initiative.hotelIds ?
                                                                                <div className='d-flex align-items-center justify-content-start flex-wrap'>
                                                                                    {initiative.hotelIds.map((id, key) => {
                                                                                        const hotel = global.hotelList?.find(x => x.value === id);

                                                                                        return (
                                                                                            <div className={`${key === initiative.hotelIds.length - 1 ? '' : 'mr-1'}`} key={key}>
                                                                                                {`${hotel?.label || id}${key === initiative.hotelIds.length - 1 ? '' : ','}`}
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                                : '-'}
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="col-6 mt-2">
                                                                        <div className='title-sm'>
                                                                            <FormattedMessage id="SalesProcess.Markets" />
                                                                        </div>
                                                                        <div>
                                                                            {initiative.markets ?
                                                                                <div className='d-flex align-items-center justify-content-start flex-wrap'>
                                                                                    {initiative.markets.map((market, key) =>
                                                                                        <div key={key}>
                                                                                            {key ?
                                                                                                <span className='mr-1'>,</span>
                                                                                                : ''}
                                                                                            {market.name}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                                : '-'}
                                                                        </div>
                                                                    </Col>

                                                                    {initiative.statusReason ?
                                                                        <Col className="col-12 mt-2">
                                                                            <div className='title-sm'>
                                                                                <FormattedMessage id="SalesDeal.CancellationReason" />
                                                                            </div>
                                                                            <div>
                                                                                {initiative.statusReason ?? '-'}
                                                                            </div>
                                                                        </Col>
                                                                        : ''}
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                        <Col className='col-12 mt-4'>
                                                            <div className='shadow w-100 bg-white p-3'>
                                                                <div className="text-muted d-flex align-items-center justify-content-between">
                                                                    <div>
                                                                        <i className="mr-2 fas fa-list"></i>
                                                                        <FormattedMessage id="SalesProcess.Notes" />
                                                                    </div>
                                                                </div>
                                                                <Row className='mt-2'>
                                                                    <Col className="col-6">
                                                                        <div className='title-sm'>
                                                                            <FormattedMessage id="SalesProcess.Summary" />
                                                                        </div>
                                                                        <div>
                                                                            {initiative.summary ?
                                                                                <div dangerouslySetInnerHTML={{ __html: initiative.summary }} />
                                                                                : '-'}
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="col-6">
                                                                        <div className='title-sm'>
                                                                            <FormattedMessage id="SalesProcess.Report" />
                                                                        </div>
                                                                        <div>
                                                                            {initiative.report ?
                                                                                <div dangerouslySetInnerHTML={{ __html: initiative.report }} />
                                                                                : '-'}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                        {initiative.salesInitiativeProfile?.length ?
                                                            <Col className='mt-4 col-12 col-lg-6'>
                                                                <div className='shadow w-100 bg-white h-100' style={{ maxHeight: '350px', overflow: 'hidden' }}>
                                                                    <div className="d-flex align-items-center justify-content-between p-3" style={{ height: '50px', position: 'sticky', top: 0, zIndex: 10, background: 'white' }}>
                                                                        <div>
                                                                            <h6 className='m-0'><FormattedMessage id="SalesDeal.Profiles" /></h6>
                                                                        </div>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div>
                                                                                <i id="addProfile" onClick={() => this.toggleAssociateProfileModal()} className="fas fa-plus cursor-pointer editFieldBtn mr-3" />
                                                                                <CoolTooltip target="addProfile" placement="top">
                                                                                    <FormattedMessage id="SalesDeal.AddProfile" />
                                                                                </CoolTooltip>
                                                                            </div>

                                                                            <div>
                                                                                <input
                                                                                    type="file"
                                                                                    id="uploadProfilesStructureInputDetails"
                                                                                    style={{ display: "none" }}
                                                                                    accept=".xlsx"
                                                                                    onChange={(e) => this.uploadProfilesStructure(e)}
                                                                                />
                                                                                <i id="uploadProfilesStructureBtn" onClick={() => document.getElementById("uploadProfilesStructureInputDetails")?.click()} className="fas fa-file-upload cursor-pointer editFieldBtn mr-3" />
                                                                                <CoolTooltip target="uploadProfilesStructureBtn" placement="top">
                                                                                    <FormattedMessage id="SalesDeal.UploadProfilesStructure" />
                                                                                </CoolTooltip>
                                                                            </div>

                                                                            <div>
                                                                                <i id="editProfiles" onClick={() => this.toggleModal(3)} className="fas fa-edit cursor-pointer editFieldBtn" />
                                                                                <CoolTooltip target="editProfiles" placement="top">
                                                                                    <FormattedMessage id="SalesDeal.EditProfiles" />
                                                                                </CoolTooltip>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='veryCoolScrollBar p-3' style={{ maxHeight: 'calc(350px - 50px)', overflowY: 'auto' }}>
                                                                        {initiative.salesInitiativeProfile?.map((user, k) =>
                                                                            <>
                                                                                <div key={k} className={k ? 'mt-3 pt-3' : ''} style={{ borderTop: k ? '1px solid lightgrey' : '' }} id={`initiativeProfile-${k}`}>
                                                                                    <div className={`text-muted d-flex align-items-center justify-content-between`}>
                                                                                        <div>
                                                                                            <i className="fas fa-user-tie mr-2" />
                                                                                            {user?.role}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Row className='w-100'>
                                                                                            <Col className='col-6 mt-2'>
                                                                                                <div className='title-sm'>
                                                                                                    <FormattedMessage id="SalesProcess.Name" />
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span className='hover-underline'>
                                                                                                        <Link to={{ pathname: "/ProfileDetails/" + user.profileId }}>
                                                                                                            {user.name ?? '-'}
                                                                                                        </Link>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col className='col-6 mt-2'>
                                                                                                <div className='title-sm'>
                                                                                                    <FormattedMessage id="SalesProcess.Nationality" />
                                                                                                </div>
                                                                                                <div>
                                                                                                    {countryList().getData().find(country => country.value === user.nationality)?.label ?? '-'}
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col className='col-12 col-lg-6 mt-2'>
                                                                                                <div className='title-sm'>
                                                                                                    <FormattedMessage id="SalesProcess.Email" />
                                                                                                </div>
                                                                                                <div>
                                                                                                    {user.email ?? '-'}
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col className='col-12 col-lg-6 mt-2'>
                                                                                                <div className='title-sm'>
                                                                                                    <FormattedMessage id="SalesProcess.Mobile" />
                                                                                                </div>
                                                                                                <div>
                                                                                                    {user.mobile ?? '-'}
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col className='col-12 mt-2'>
                                                                                                <div className='title-sm'>
                                                                                                    <FormattedMessage id="SalesProcess.Notes" />
                                                                                                </div>
                                                                                                <div>
                                                                                                    {user.notes ?? '-'}
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            : ''}
                                                        <Col className='mt-4 col-12 col-lg-6'>
                                                            <div className='shadow w-100 bg-white p-3'>
                                                                <div className="text-muted d-flex align-items-center justify-content-between">
                                                                    <div>
                                                                        <i className="fas fa-user-cog mr-2"></i>
                                                                        <FormattedMessage id="SalesProcess.Staff" />
                                                                    </div>
                                                                    <i className="fas fa-edit cursor-pointer editFieldBtn" onClick={_ => this.toggleModal(4)}/>
                                                                </div>
                                                                <Row className='w-100'>
                                                                    {initiative.salesInitiativeUsers?.map((u, k) => {
                                                                        const user = salesUsers.find(({ id }) => id === u.userId);

                                                                        return (
                                                                            <Col key={k} className={`col-6 mt-2`}>
                                                                                <div id={`initiativeUser-${k}`} style={{ width: 'fit-content' }}>
                                                                                    <div className='title-sm'>{u.role}</div>
                                                                                    {user?.firstName && user.lastName ?
                                                                                        <div>{`${user.firstName} ${user.lastName}`}</div>
                                                                                    : '-'}
                                                                                </div>

                                                                                {u.notes &&
                                                                                    <CustomToolTip placementPrefix="coolTooltip bs-tooltip" placement="right" target={`initiativeUser-${k}`}>
                                                                                        {u.notes}
                                                                                    </CustomToolTip>
                                                                                }
                                                                            </Col>
                                                                        )
                                                                    }
                                                                    )}
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col className='col-12 pt-3 col-lg-3 pt-lg-2'>
                                                <div>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div>
                                                            <h5 className='m-0'>
                                                                <FormattedMessage id="SalesProcess.InitiativeActions" />
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <Button onClick={_ => this.toggleActionModal()} className="btn btn-host btn-sm">
                                                                <i className="fas fa-plus" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    {initiative.salesDealActions?.filter(x => x.pinned)?.length ?
                                                        <div className='mt-4'>
                                                            {initiative.salesDealActions.filter(x => x.pinned).map((action, key) => {
                                                                const showBottom = (action.summary && action.summary !== "<p></p>\n") || action.salesDealActionProfiles?.length || action.salesDealActionUsers?.length;
                                                                return (
                                                                    <div key={key} className={key ? 'mt-4' : ''}>
                                                                        <div className={`shadow w-100 bg-white p-3 d-flex ${showBottom ? 'align-items-start justify-content-start flex-column' : 'align-items-start justify-content-start'}`}>
                                                                            <div className='d-flex align-items-center w-100'>
                                                                                <div className='mr-2'>
                                                                                    <div id={`ActiontType-${action.type}-${action.id}`}
                                                                                        style={{
                                                                                            fontSize: '0.85em',
                                                                                            background: '#0665ff',
                                                                                            color: 'white',
                                                                                            width: '30px',
                                                                                            height: '30px',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'center',
                                                                                            borderRadius: '100%'
                                                                                        }}
                                                                                    >
                                                                                        {action.type === 'Email' ?
                                                                                            <i style={{ marginTop: '1px' }} className="far fa-envelope"></i>
                                                                                            : action.type === 'Call' ?
                                                                                                <i className="fas fa-phone"></i>
                                                                                                : action.type === 'Note' ?
                                                                                                    <i style={{ marginTop: '1px' }} className="far fa-sticky-note"></i>
                                                                                                    : action.type === 'File' ?
                                                                                                        <i style={{ marginTop: '1px' }} className="fas fa-file"></i>
                                                                                                        : action.type === 'Meeting' ?
                                                                                                            <i style={{ marginTop: '1px' }} className="far fa-building"></i>
                                                                                                            : action.type === 'Status' ?
                                                                                                                <i style={{ marginTop: '1px' }} className="fas fa-power-off"></i>
                                                                                                                : action.type === 'Create' ?
                                                                                                                    <i style={{ marginTop: '1px' }} className="fas fa-briefcase" />
                                                                                                                    : action.type === 'Stage' ?
                                                                                                                        <i style={{ marginTop: '1px' }} className="fas fa-level-up-alt" />
                                                                                                                        : action.type === 'Task' ?
                                                                                                                            <i className="far fa-clipboard" />
                                                                                                                            : ''}
                                                                                    </div>
                                                                                    <CoolTooltip placement="left" target={`ActiontType-${action.type}-${action.id}`}>
                                                                                        <FormattedMessage id={`SalesProcess.${action.type}`} />
                                                                                    </CoolTooltip>
                                                                                </div>
                                                                                <div className={`d-flex align-items-center justify-content-between w-100 text-truncate`}>
                                                                                    <div className={'text-left text-truncate'}>
                                                                                        {action.title}
                                                                                    </div>
                                                                                </div>
                                                                                <div className='mr-2 text-muted d-flex align-items-center' style={{ fontSize: '0.9em' }}>
                                                                                    <div style={{ whiteSpace: 'nowrap' }}>
                                                                                        {CustomFormatDate(action.startDate, null, 'HH:mm', intl)}
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ marginTop: '2px' }}>
                                                                                    <KebabMenu
                                                                                        direction={'left'}
                                                                                        editFunction={_ => this.toggleActionModal(action)}
                                                                                        extraFields={[]}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {showBottom ?
                                                                                <div className='mt-2'>
                                                                                    <div>
                                                                                        <div style={{ fontSize: '0.95em' }}>
                                                                                            <div dangerouslySetInnerHTML={{ __html: action.summary }} />
                                                                                        </div>
                                                                                    </div>
                                                                                    {action.salesDealActionProfiles?.length || action.salesDealActionUsers?.length ?
                                                                                        <div style={{ fontSize: '0.9em' }} className='d-flex align-items-center justify-content-between text-muted mt-2'>
                                                                                            {action.salesDealActionProfiles?.length ?
                                                                                                <div className='d-flex align-items-center'>
                                                                                                    <i className='fas fa-user-tie mr-2' />
                                                                                                    <div>
                                                                                                        {action.salesDealActionProfiles[0].name}
                                                                                                    </div>
                                                                                                </div>
                                                                                                : ''}
                                                                                            {action.salesDealActionUsers?.length ?
                                                                                                <div className='d-flex align-items-center'>
                                                                                                    <i className='fas fa-user-cog mr-2' />
                                                                                                    <div>
                                                                                                        {action.salesDealActionUsers[0].name}
                                                                                                    </div>
                                                                                                </div>
                                                                                                : ''}
                                                                                        </div>
                                                                                        : ''}
                                                                                </div>
                                                                                : ''}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            )}
                                                        </div>
                                                        :
                                                        <div className='mt-4 text-center text-muted'>
                                                            <FormattedMessage id="SalesProcess.NoActions" />
                                                        </div>
                                                    }
                                                </div>
                                                <div className='mt-4'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div>
                                                            <h5 className='m-0'>
                                                                <FormattedMessage id="SalesProcess.Associated" />
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <Button onClick={_ => this.toggleAssociatedModal()} className="btn btn-host btn-sm">
                                                                <FormattedMessage id="SalesProcess.NewAssociation" />
                                                                <i className="fas fa-random ml-2" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className='mt-4'>
                                                        {associatedInitiative?.length > 0 ?
                                                            associatedInitiative.map((association, key) =>
                                                                association.initiatives ?
                                                                    <div key={key}>
                                                                        <h6 className={`m-0 text-muted text-left ${key === 0 ? 'pb-3' : 'py-3'}`}>{association.fixedValue !== "Duplicate" ? <i className="fas fa-random mr-1" /> : <i className="fas fa-copy mr-1"></i>}{association.relationName}</h6>
                                                                        {association.initiatives.map((relatedDeal, dealKey) =>
                                                                            <div key={dealKey} className='p-3 mb-2 bg-white shadow border-0'>
                                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                                    <div className='d-flex align-items-center justify-content-between pr-2 cursor-pointer' style={{ width: '98%' }} onClick={() => selectDealDetail(relatedDeal?.relatedDealId, null)}>
                                                                                        <div style={{ width: '70%' }} className='d-flex align-items-center'>
                                                                                            <div id={`typeOfDeal-${key}-${dealKey}`}>
                                                                                                {!relatedDeal?.isLead ?
                                                                                                    <i className="fas fa-briefcase text-muted mr-2" />
                                                                                                    :
                                                                                                    <i className="fas fa-bullseye text-muted mr-2" />
                                                                                                }
                                                                                            </div>
                                                                                            <CustomToolTip placementPrefix="coolTooltip bs-tooltip" placement="top" target={`typeOfDeal-${key}-${dealKey}`}>
                                                                                                {!relatedDeal?.isLead ?
                                                                                                    <FormattedMessage id="SalesProcess.Deal" />
                                                                                                    :
                                                                                                    <FormattedMessage id="SalesProcess.Lead" />
                                                                                                }
                                                                                            </CustomToolTip>
                                                                                            <div className='text-left text-truncate ml-1' style={{ maxWidth: '80%' }} id={`DealName-${key}-${dealKey}`}>
                                                                                                <div style={{ width: 'fit-content', position: 'relative', fontSize: '0.9em' }}>
                                                                                                    {relatedDeal.relatedDealName}
                                                                                                </div>

                                                                                                {relatedDeal.hotelList && relatedDeal.hotelList.length > 0 ?
                                                                                                    <>
                                                                                                        <div id={`hotelListRelateds-${key}-${dealKey}`} className='text-muted' style={{ width: 'fit-content', position: 'relative', fontSize: '0.7em' }}>
                                                                                                            <div className='mt-1'>
                                                                                                                {global.hotelList?.find(x => x.value === relatedDeal.hotelList[0])?.label}
                                                                                                                {relatedDeal.hotelList.length > 1 ?
                                                                                                                    <span className="ml-1">
                                                                                                                        + {relatedDeal.hotelList.length - 1}
                                                                                                                    </span>
                                                                                                                    : ''}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <CustomToolTip placementPrefix="coolTooltip bs-tooltip" placement="bottom" target={`hotelListRelateds-${key}-${dealKey}`}>
                                                                                                            {relatedDeal.hotelList && relatedDeal.hotelList.map((id, key) => {
                                                                                                                const hotel = global.hotelList?.find(x => x.value === id);
                                                                                                                return (
                                                                                                                    <div key={key} className='mt-1'>
                                                                                                                        {hotel.label}
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })}
                                                                                                        </CustomToolTip>
                                                                                                    </>
                                                                                                    : ''}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="text-center">
                                                                                            {relatedDeal.relatedDealStatus ?
                                                                                                <DealStatusBadge status={relatedDeal.relatedDealStatus} isLead={relatedDeal.isLead} />
                                                                                                : ''}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='text-right'>
                                                                                        <KebabMenu
                                                                                            deleteFunction={() => this.toggleDeleteDealAssociationModal(relatedDeal.salesInitiativeRelationId)}
                                                                                            deleteText={"SalesProcess.DeleteRelation"}
                                                                                            extraFields={[
                                                                                                {
                                                                                                    text: "SalesProcess.ChangeDealAssociationType",
                                                                                                    function: _ => this.toggleChangeAssociationTypeModal(relatedDeal.relationId, relatedDeal.salesInitiativeRelationId, relatedDeal.relatedDealId),
                                                                                                    icon: "far fa-edit mr-2",
                                                                                                },
                                                                                            ]}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    : ''
                                                            )
                                                            :
                                                            <div className='text-muted text-center mt-4'>
                                                                <FormattedMessage id="SalesProcess.NoAssociations" />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                : activeTab === "Actions" ?
                                        <SalesInitiativeActions
                                            focusActions={initiative?.salesDealActions?.filter(x => x.pinned)}
                                            actions={initiative?.salesDealActions}
                                            toggleActionModal={this.toggleActionModal}
                                            deal={initiative}
                                            salesUsers={salesUsers}
                                            toggleEdit={this.toggleModal}
                                            changeActionPin={this.changeActionPin}
                                            deleteFunction={this.toggleConfirmActionModal}
                                            finishFunction={this.toggleConfirmActionModal}
                                            onGoingFunction={this.toggleConfirmActionModal}
                                            resetFiltersAndSearch={this.resetFiltersAndSearch}
                                        />
                                :''}
                            </>
                        :''}
                    </Card>
                </BlockUi>

                <>
                {deleteDealAssociationModal ?
                    <ConfirmActionModal
                        modal={deleteDealAssociationModal}
                        toggleModal={this.toggleDeleteDealAssociationModal}
                        actionFunction={() => this.deleteDealAssociation(selectedDealRelationId)}
                        text={<FormattedMessage id="SalesProcess.DeleteRelationText" />}
                        block={block}
                    />
                :''}

                {changeAssociationTypeModal ?
                    <ChangeAssociationTypeModal
                        block={block}
                        error={error}
                        modal={changeAssociationTypeModal}
                        selectedAssociationTypeId={selectedDealRelationTypeId}
                        toggleModal={this.toggleChangeAssociationTypeModal}
                        saveAssociation={(e, relationType) => this.associateDeal(e, initiative?.id, selectedDealId, relationType, selectedDealRelationId)} 
                    />
                :''}

                {associatedModal ?
                    <AssociationModal
                        block={block}
                        error={error}
                        modal={associatedModal}
                        dealList={dealList}
                        getDealList={getDealList}
                        salesUsers={salesUsers}
                        salesPipelines={salesPipelines}
                        salesSources={salesSources}
                        currentDeal={initiative}
                        salesSourcesTypes={salesSourcesTypes}
                        toggleModal={this.toggleAssociatedModal}
                        associateDeal={this.associateDeal}
                        isInitiative={true}
                        renderActivityHelperMsg={renderActivityHelperMsg}
                        totalDeals={totalDeals}
                    />
                :''}

                    {createInitiativeCopyModal ?
                        <SalesInitiativeModal
                            modal={createInitiativeCopyModal}
                            profileTypeOptions={profileTypeOptions}
                            toggleModal={this.toggleCopyModal}
                            initiative={this.state.newInitiativeCopy}
                            updateInitiative={this.goToCopyDetails}
                            hotelList={hotelList}
                            profileRoleOptions={profileRoleOptions}
                            uploadProfilesStructure={this.uploadProfilesStructure}
                            isCopy={true}
                            copyFromInitiative={{ id: initiative.id, name: initiative.name }}
                            downloadProfilesStructure={this.downloadProfilesStructure}
                        />
                        : ''}

                    {modal ?
                        <SalesInitiativeModal
                            modal={modal}
                            profileTypeOptions={profileTypeOptions}
                            toggleModal={this.toggleModal}
                            initiative={initiative}
                            updateInitiative={this.updateInitiative}
                            getInitiativeDetail={this.getInitiativeDetail}
                            hotelList={hotelList}
                            specificEditStep={specificEditStep}
                            isToEdit={!isNaN(specificEditStep)}
                            profileRoleOptions={profileRoleOptions}
                            uploadProfilesStructure={this.uploadProfilesStructure}
                            downloadProfilesStructure={this.downloadProfilesStructure}
                        />
                        : ''}

                {confirmDeleteInitiativeModal ?
                    <ConfirmActionModal
                        modal={confirmDeleteInitiativeModal}
                        toggleModal={() => this.toggleConfirmDeleteInitiativeModal()}
                        actionFunction={() => this.changeStatus('Cancelled')}
                        text={<FormattedMessage id="SalesProcess.CancelInitiativeConfirm" />}
                        block={block}
                    />
                :''}
                
                {confirmActionModal ?
                    <ConfirmActionModal
                        modal={confirmActionModal}
                        toggleModal={() => this.toggleConfirmActionModal()}
                        actionFunction={_ => this.changeInitiativeStatus(changeToStatus)}
                        text={<FormattedMessage id={`SalesDeal.Confirm${changeToStatus}Text`} values={{ action: selectedAction.title }} />}
                        block={block}
                    />
                :''}

                {actionModal ?
                    <SalesActionModal
                        customers={initiative.salesInitiativeProfile && initiative.salesInitiativeProfile.map(user => ({ value: user.profileId, label: user.name }))}
                        toggle={_ => this.toggleActionModal()}
                        salesUsers={salesUsers}
                        salesDealUsers={salesUsers.filter(user => initiative.salesInitiativeUsers.some(dealUser => dealUser.userId === user.value))}
                        salesDealHotelIds={initiative?.hotelIds}
                        salesInitiativeId={initiative.id}
                        selectedAction={selectedAction || null}
                        updateActions={this.resetFiltersAndSearch}
                    />
                :''}

                {csvProfilesModal ? 
                    <CsvProfilesTable
                        profiles={csvProfiles}
                        modal={csvProfilesModal}
                        block={blockProfilesModal}
                        error={profilesModalError}
                        profileTypeOptions={profileTypeOptions}
                        profileRoleOptions={profileRoleOptions}
                        initiative={initiative}
                        toggleModal={this.toggleImportProfilesModal}
                        downloadProfilesStructure={this.downloadProfilesStructure}
                    />
                :''}

                {associateProfileModal ?
                    <AssociateProfile
                        modal={associateProfileModal}
                        toggleModal={this.toggleAssociateProfileModal}
                        associateProfileOrReservation={this.associateProfile}
                        selectProfile={this.selectProfile}
                        profile={selectedProfile}
                        toggleCreateModal={_ => this.toggleCreateProfileModal()}
                        associateNewProfile={this.associateNewProfile}
                        createModal={createModal}
                        profileRoleOptions={profileRoleOptions}
                        enableCreate={true}
                        disableMasterFilter={true}
                        createMasterAPI={true}
                        isPortal={true}
                        showRoleOptions={true}
                    />
                : ''}
            </>
            </>
        );
    }
}

export default injectIntl(SalesInitiativeDetail);