import React, { Component } from 'react';
import { Button, Row, Col, Card, Form } from 'reactstrap';
import { SelectHotel, StyledCard, CommonHelper } from '../Base/CommonUIComponents';
import { getColorPallete, getNextColor } from '../Base/ReferenceDataFunctions';
import { ChartWithCustomLegend } from '../Base/CustomCharts';
import { FormattedMessage, injectIntl } from 'react-intl';
import DashboardCard from "../Base/DashboardCard";
import CustomSelect from '../Base/CustomSelect';
import { DateRangePicker } from 'react-dates';
import { Bar, Radar } from 'react-chartjs-2';
import { getAPI } from "../Base/API";
import moment from 'moment';

class QualityMetrics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            blockTemplates: false,
            error: null,
            fromDate: moment().subtract(7, 'days'),
            toDate: moment(),
            unconformitiesByRule: { "datasets": [{ "label": "Unconformities by Rule", "data": [], "backgroundColor": getColorPallete() }], "labels": [] },
            unconformitiesByTemplate: { "datasets": [{ "label": "Unconformities by Template", "data": [], "backgroundColor": getColorPallete() }], "labels": [] },
            unconformitiesByDay: { "drawOnChartArea": true, "datasets": [], "labels": [] },
            tableData: [],
            channelOptions: [],
            totalUnconformities: 0,
            totalProfilesUnChecked: 0,
            ruleLegend: [],
            barChartExpanded: false,
            radarChartExpanded: false,
            totalManuallyResolved: 0,
            totalAutoResolved: 0,
            colors: { unconformities: "#F16D84", manuallyResolved: "#4CAF50", autoResolved: "#00a099" },
            templateList: []
        };
    }

    componentDidMount() {
        this.getReservationSummary();

        this.getTemplates();
    }

    getTemplates = () => {
        this.setState({ blockTemplates: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockTemplates: false });
                return;
            }
            if (data) {
                var templates = [];

                data.response && data.response.forEach(el => {
                    el.dataQualityTemplates && el.dataQualityTemplates.forEach(template => {
                        templates.push({ value: template.id, label: template.name });
                    })
                });
                this.setState({ error: errorMessage, blockTemplates: false, templateList: templates.sort((a, b) => a.label.toLowerCase() < b.label.toLowerCase() ? -1 : a.label.toLowerCase() < b.label.toLowerCase() ? 1 : 0) });
            }
            else {
                this.setState({ error: errorMessage, blockTemplates: false });
            }
        }, `/api/gms/DataQuality/v1/DataQualityTemplate`);
    }

    getReservationSummary = (e) => {
        if(e) e.preventDefault();
        this.setState({ block: true });

        let params = this.state.hotelId ? `&hotelId=${this.state.hotelId}` : '';
        if (this.state.templateId) params += `&templateId=${this.state.templateId}`;

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.calculateData(data.response);
                this.setState({ error: [], block: false });
            }
        }, `/api/gms/DashBoard/DataQualityTemplateRunSummary?runsFromDate=${moment(this.state.fromDate).format('YYYY-MM-DD')}&runsToDate=${moment(this.state.toDate).format('YYYY-MM-DD')}` + params)
    }

    calculateData = (data) => {
        const ruleLegend = [];
        const formatDate = "MMM DD, YYYY";
        const { colors } = this.state;
        const dates = this.getDates(data, "YYYY-MM-DD").map(el => moment(el).format(formatDate));

        var unconformitiesTemplate = new Map(), totalProfilesUnChecked = 0, totalUnconformities = 0, totalManuallyResolved = 0, totalAutoResolved = 0;
        const unconformitiesByTemplate = { "datasets": [{ "label": "Unconformities by Template", "data": [], "backgroundColor": getColorPallete() }], "labels": [] };
        const unconformitiesByRule = {
            datasets: [
                { label: this.props.intl.formatMessage({ id: "QualityMetrics.ManuallyResolvedUnconformities" }), data: [], borderColor: colors.manuallyResolved, backgroundColor: `${colors.manuallyResolved}33`, order: 1 },
                { label: this.props.intl.formatMessage({ id: "QualityMetrics.AutoResolvedUnconformities" }), data: [], borderColor: colors.autoResolved, backgroundColor: `${colors.autoResolved}33`, order: 2 },
                { label: this.props.intl.formatMessage({ id: "QualityMetrics.TotalUnconformities" }), data: [], borderColor: colors.unconformities, backgroundColor: `${colors.unconformities}33`, order: 3 }
            ],
            labels: []
        };

        const unconformitiesByDay = {
            "drawOnChartArea": true,
            "datasets": [
                {
                    label: this.props.intl.formatMessage({ id: "QualityMetrics.#ManuallyResolved" }),
                    data: Array(dates.length).fill(0), type: "line",
                    borderColor: colors.manuallyResolved, backgroundColor: `${colors.manuallyResolved}33`
                },
                {
                    label: this.props.intl.formatMessage({ id: "QualityMetrics.#AutoResolved" }),
                    data: Array(dates.length).fill(0), type: "line", 
                    borderColor: colors.autoResolved, backgroundColor: `${colors.autoResolved}33`
                },
                {
                    label: this.props.intl.formatMessage({ id: "QualityMetrics.#Unconformities" }),
                    data: Array(dates.length).fill(0), type: "line", 
                    borderColor: colors.unconformities, backgroundColor: `${colors.unconformities}33`                  
                }
            ],
            labels: [...dates]
        };



        data && data.forEach(total => {
            totalProfilesUnChecked = total.totalProfilesUnChecked;

            total.dataQualityTempalteRuns && total.dataQualityTempalteRuns.forEach(template => {
                unconformitiesTemplate.set(template.templateName, template.totalProfiles);
                totalUnconformities += template.totalProfiles;

                template.dataQualityTemplateRunByDates && template.dataQualityTemplateRunByDates.forEach(date => {
                    const dateIdx = dates.findIndex(l => l === moment(date.date).format(formatDate));

                    date.dataQualityTemplateRunSummary && date.dataQualityTemplateRunSummary.forEach(rule => {
                        const ruleLabel = `${rule.ruleName}`;
                        const isManualMode = rule.mode === "Manual";

                        //Total Resolved
                        if (isManualMode) {
                            totalManuallyResolved += rule.totalResolved;
                        }
                        else totalAutoResolved += rule.totalResolved;


                        //Bar chart 
                        if (dateIdx !== -1) {
                            const datasetIdx = unconformitiesByDay.datasets.findIndex(ds => ds.ruleId === rule.ruleId);

                            if (datasetIdx !== -1) {
                                const currentValue = unconformitiesByDay.datasets[datasetIdx].data[dateIdx];

                                unconformitiesByDay.datasets[datasetIdx].data[dateIdx] = [(currentValue[0] + rule.totalProfiles), (currentValue[1] + -Math.abs(rule.totalResolved))];
                            }
                            else {
                                const ruleColor = getNextColor(unconformitiesByDay.datasets.length - 2);

                                unconformitiesByDay.datasets.push({
                                    label: ruleLabel, data: Array(dates.length).fill([0, 0]).fill([rule.totalProfiles, -Math.abs(rule.totalResolved)], dateIdx, dateIdx + 1),
                                    borderColor: ruleColor, backgroundColor: ruleColor, fill: true, ruleId: rule.ruleId, hidden: true
                                });
                            }


                            if (isManualMode) {
                                unconformitiesByDay.datasets[0].data[dateIdx] += -Math.abs(rule.totalResolved);
                            }
                            else {
                                unconformitiesByDay.datasets[1].data[dateIdx] += -Math.abs(rule.totalResolved);
                            }

                            unconformitiesByDay.datasets[2].data[dateIdx] += rule.totalProfiles;
                        }

                        //By Rule
                        const labelIdx = unconformitiesByRule.labels.findIndex(el => el === ruleLabel);

                        if (labelIdx === -1) {
                            unconformitiesByRule.labels.push(ruleLabel);

                            unconformitiesByRule.datasets[2].data.push(rule.totalProfiles);

                            if (isManualMode) {
                                unconformitiesByRule.datasets[0].data.push(rule.totalResolved);
                                unconformitiesByRule.datasets[1].data.push(0);
                            }
                            else {
                                unconformitiesByRule.datasets[0].data.push(0);
                                unconformitiesByRule.datasets[1].data.push(rule.totalResolved);
                            }
                        }
                        else {
                            unconformitiesByRule.datasets[2].data[labelIdx] += rule.totalProfiles;

                            if (isManualMode) {
                                unconformitiesByRule.datasets[0].data[labelIdx] += rule.totalResolved;
                            }
                            else {
                                unconformitiesByRule.datasets[1].data[labelIdx] += rule.totalResolved;
                            }
                        }
                        
                    })
                })
            })
        });


        //Pie - Unconformities by Template
        var values = [];
        unconformitiesByTemplate.labels = [...unconformitiesTemplate.keys()];

        unconformitiesByTemplate.labels.forEach(item => { values.push(unconformitiesTemplate.get(item)); });
        unconformitiesByTemplate.datasets[0].data = values;


        this.setState({
            unconformitiesByRule, unconformitiesByTemplate, unconformitiesByDay,
            totalProfilesUnChecked, totalUnconformities, ruleLegend,
            totalManuallyResolved, totalAutoResolved
        });
    }

    getDates = (data, formatDate) => {

        //Chart dates
        return data[0].dataQualityTempalteRuns && data[0].dataQualityTempalteRuns.reduce((acc, cur) => {
            cur.dataQualityTemplateRunByDates && cur.dataQualityTemplateRunByDates.forEach(date => {
                if (!acc.some(el => el === moment(date.date).format(formatDate))) {
                    acc.push(moment(date.date).format(formatDate));
                }
            })

            return acc;
        }, []).sort((a, b) => moment(a) - moment(b));
    }

    handleSelect = (evt, combo) => {
        this.setState({
            [evt]: combo ? combo.value : null
        })
    }

    toggleExpanded = (expanded) => {
        this.setState(prevState => ({
            [expanded]: !prevState[expanded]
        }))
    }

    render() {
        const { block, error, unconformitiesByTemplate, unconformitiesByRule, radarChartExpanded, barChartExpanded, colors, templateList } = this.state;

        let options = {
            responsive: true,
            maintainAspectRatio: false,
            //skipNull: true,
            plugins: {
                datalabels: {
                    display: false,
                },
                legend: {
                    display: true
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            if (Array.isArray(context.raw)) {
                                return [context.dataset.label, `# Inconformidades: ${context.raw[0]}`, `# Resolvidos: ${Math.abs(context.raw[1])}`];
                            }
                            else {
                                return `${context.dataset.label}: ${Math.abs(context.raw)}`;
                            }
                        }
                    }
                },
            },
            scales: {
                y: {
                    position: 'left',
                    title: {
                        display: true,
                        text: `# ${this.props.intl.formatMessage({ id: "QualityMetrics.UnconformityByRule" })}`
                    },
                    ticks: {
                        stepSize: 1000
                    },
                    grid: {
                        color: function (context) {
                            if (context.tick.value === 0) {
                                return '#000000';
                            }
                        },
                    },
                }
            }
        }


        let optionsRadar = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                datalabels: { display: false },
                plugins: {
                    filler: {
                        propagate: false
                    },
                    'samples-filler-analyser': {
                        target: 'chart-analyser'
                    }
                },
                interaction: {
                    intersect: false
                }
            }
        }
        
        return (
            <StyledCard block={block} error={error} >
                <Row>
                    <Col>
                        <h5> <i className="fas fa-database mr-2" /> <FormattedMessage id="NavMenu.QualityMetrics" /></h5>
                    </Col>
                </Row>

                <Form onSubmit={this.getReservationSummary}>
                    <Row className="my-3">
                        <Col className="col-6 pr-0">
                            <Row>
                                <Col className="col-6 pr-0">
                                    <SelectHotel
                                        name={'hotelId'} icon={'icon-icon-hotel'}
                                        onChangeFunc={this.handleSelect}
                                        value={this.state.hotelId}
                                        placeholder={<FormattedMessage id="generic.Hotel" />}
                                        params='?onlyFromHotelGroup=true'
                                    />
                                </Col>
                                <Col >
                                    <CustomSelect
                                        options={templateList}
                                        icon={'fas fa-file-alt'}
                                        placeholder={<FormattedMessage id="TaskResultDashboard.SelectTemplate" />}
                                        onChange={(e) => this.handleSelect('templateId', e)}
                                        value={templateList.find(t => t.value == this.state.templateId) || ''}
                                        isLoading={this.state.blockTemplates}
                                        isClearable
                                        isSearchable
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={6}>
                            <Row className="align-items-center">
                                <Col>
                                    <DateRangePicker
                                        startDate={this.state.fromDate ? moment(this.state.fromDate) : null}
                                        endDate={this.state.toDate ? moment(this.state.toDate) : null}
                                        onDatesChange={({ startDate, endDate }) => this.setState({ fromDate: startDate, toDate: endDate })}
                                        isOutsideRange={day => day <= moment().subtract(12, 'month') || day > moment().add(12, 'month')}
                                        endDateId="your_unique_end_date_id_announcement"
                                        startDateId="your_unique_start_date_id_announcement"
                                        focusedInput={this.state.focusedInput}
                                        onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                        small={true}
                                        numberOfMonths={1}
                                        showDefaultInputIcon={true}
                                        required
                                    />
                                </Col>
                                <Col className="text-right" >
                                    <CommonHelper help={<FormattedMessage id="QualityMetrics.Help" />} id={'QualityMetrics'} lang={this.props.intl.locale} />
                                    <Button className="float-right btn btn-sm btn-host" type="submit">
                                        <i className="fa fa-search" />
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>

                <Row className="mb-3">
                    {!radarChartExpanded ?
                        <Col className="col-6 pr-0">
                            <Row className="mb-3">
                                <Col className="col-6 pr-0">
                                    <Card className="shadow border-0 h-100" body>
                                        <Row className="mb-2">
                                            <Col><h5 className="text-muted"><FormattedMessage id="QualityMetrics.TotalProfilesWithUnconformities" /></h5></Col>
                                        </Row>
                                        <Row>
                                            <Col className="text-right">
                                                <h3 className="text-value-xl " style={{ color: colors.unconformities }}> {this.state.totalUnconformities}</h3>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col className="col-6">
                                    <Card className="shadow border-0 h-100 " body>
                                        <Row className="mb-2">
                                            <Col><h5 className="text-muted"><FormattedMessage id="QualityMetrics.TotalResolved" /></h5></Col>
                                        </Row>
                                        <Row>
                                            <Col className="text-right">
                                                <h3 className="text-value-xl" style={{ color: colors.manuallyResolved }} title={this.props.intl.formatMessage({ id: "QualityMetrics.ManuallyResolvedUnconformities" })}>
                                                    <i className="fas fa-xs fa-hand-point-up"/> {this.state.totalManuallyResolved}
                                                </h3>
                                            </Col>
                                            <Col className="text-right">
                                                <h3 className="text-value-xl" style={{ color: colors.autoResolved }} title={this.props.intl.formatMessage({ id: "QualityMetrics.AutoResolvedUnconformities" })}>
                                                    <i className="fas fa-xs fa-users-cog" /> {this.state.totalAutoResolved}
                                                </h3>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <div style={{ height: '299px' }}>
                                <DashboardCard header="QualityMetrics.UnconformityByTemplate" >                                    
                                    <ChartWithCustomLegend
                                        type="Doughnut"
                                        height={155}
                                        legendPlacement="right"
                                        data={unconformitiesByTemplate}
                                    />
                                </DashboardCard>
                            </div>
                        </Col>
                    : ''}
                    <Col className={!radarChartExpanded ? "col-6" : "col-12"}>
                        <Card className=" border-0 shadow pb-5" body style={radarChartExpanded ? { height: '600px' } : { height: '420px' }}>
                            <Row className="mb-2">
                                <Col><h5 className="text-muted"><FormattedMessage id="QualityMetrics.UnconformityByRule" /></h5></Col>

                                <Col>

                                </Col>
                                <div style={{ position: 'absolute', top: '0', right: '8px' }}>
                                    <i className={"fas text-host pointer pt-2 " + (radarChartExpanded ? ' fa-compress-alt' : ' fa-expand-alt')} onClick={() => this.toggleExpanded('radarChartExpanded')} />
                                </div>
                            </Row>
                            {unconformitiesByRule?.datasets[0]?.data.some(el => el !== 0) ?
                                <Radar data={unconformitiesByRule} height={230} options={optionsRadar} />
                            : ''}
                        </Card>
                    </Col>
                </Row>

                <div className="mb-4">
                    <Card className=" border-0 shadow pb-5" body style={barChartExpanded ? { height: '650px' } : { height: '420px' }}>
                        <Row className="mb-2">
                            <Col><h5 className="text-muted"><FormattedMessage id="QualityMetrics.UnconformityByDay" /></h5></Col>

                            <div style={{ position: 'absolute', top: '0', right: '8px' }}>
                                <i className={"fas text-host pointer pt-2 " + (barChartExpanded ? ' fa-compress-alt' : ' fa-expand-alt')} onClick={() => this.toggleExpanded('barChartExpanded')} />
                            </div>
                        </Row>

                        <Bar height={50} data={this.state.unconformitiesByDay} options={options} datasetKeyProvider={() => { return btoa(Math.random()).substring(0, 6) }} />
                    </Card>
                </div>

                {/*<Row className="mb-3">
                    <Col style={barChartExpanded ? { height: '600px' } : { height: '420px' }} >
                        <Card className=" border-0 shadow pb-4 h-100" body >
                            <Row className="mb-2">
                                <Col><h5 className="text-muted"><FormattedMessage id="QualityMetrics.UnconformityByDay" /></h5></Col>

                                <Col className="col-1 text-right">
                                    <i className={"fas text-host pointer pr-2 " + (barChartExpanded ? ' fa-compress-alt' : ' fa-expand-alt')} onClick={() => this.toggleExpanded('barChartExpanded')} />
                                </Col>
                            </Row>

                            {unconformitiesByDay.datasets && unconformitiesByDay.datasets.length > 0 ?
                                <div className="w-100">
                                    <Bar data={this.state.unconformitiesByDay} height={70} options={options} datasetKeyProvider={() => { return btoa(Math.random()).substring(0, 6) }} />
                                </div>
                            : ''}
                        </Card>
                    </Col>
                </Row>*/}
            </StyledCard>
        )
    }
}

export default injectIntl(QualityMetrics);