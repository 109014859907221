import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import { ProfileCard } from '../SalesProcessFunctions';
import { AssociateProfile } from '../../Card/VoucherDetails/AssociationModals';
import { handleNotification } from '../../Base/Notification';
import { CoolTooltip } from '../../Base/CommonUIComponents';

class InitiativeModalStep3 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            associationModal: false,
            selectedProfile: null,
            createModal: false,
            csvProfiles: [],
            csvProfilesModal: false,
            blockProfilesModal: false,
            profilesModalError: null,
        };
    }

    toggleModal = () => {
        this.setState(prevState => ({
            associationModal: !prevState.associationModal
        }))
    }

    associateProfile = () => {
        const { selectedProfile } = this.state;
        const { initiative, handleProfilesList } = this.props;
        
        const profiles = initiative.salesInitiativeProfile ? [...initiative.salesInitiativeProfile] : [];
        if(profiles.some(p => p.profileId === selectedProfile?.profileId)) {
            const message ={ warnings: [{ message: this.props.intl.formatMessage({ id: 'SalesDeal.ProfileAlreadyAssociated' }) }] }
            
            this.setState({ selectedProfile: null }, () => handleNotification(message));
            return;
        }

        profiles.push(selectedProfile);

        handleProfilesList(profiles);

        this.setState({ associationModal: false, selectedProfile: null });
    }

    associateNewProfile = (id, firstName, lastName) => {
        const { initiative, handleProfilesList } = this.props;

        const selectedProfile = {
            profileId: id,
            name: `${firstName ? firstName : ''} ${lastName ? lastName : ''}`
        };

        const profiles = initiative.salesInitiativeProfile ? [...initiative.salesInitiativeProfile] : [];
        profiles.push(selectedProfile);

        handleProfilesList(profiles);

        this.setState({ createModal: false, associationModal: false });
    }

    toggleCreateModal = () => {
        this.setState({ createModal: !this.state.createModal });
    }
    
    selectProfile = (profile) => {
        this.setState({
            selectedProfile: {
                profileId: profile.id,
                name: `${profile.firstName ? profile.firstName : ''} ${profile.lastName ? profile.lastName : ''}`
            }
        })
    }

    render() {
        const { selectedProfile, associationModal, createModal } = this.state;
        const { initiative, handleListElement, roles } = this.props;

        return (
            <div>
                <Row className="mb-3 border-bottom pb-2 mt-4">
                    <Col>
                        <b><FormattedMessage id="SalesDeal.Profiles" /></b>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-end">
                        {initiative?.id ? 
                            <>
                                <Button id="downalodProfilesStructureBtn" className="btn-host btn-sm mr-2" onClick={() => this.props.downloadProfilesStructure()}>
                                    <i className="fas fa-file-excel" />
                                </Button>
                                <CoolTooltip target="downalodProfilesStructureBtn" placement="top">
                                    <FormattedMessage id="SalesDeal.DownloadProfilesStructure" />
                                </CoolTooltip>

                                <input
                                    type="file"
                                    id="uploadProfilesStructureInput"
                                    style={{ display: "none" }}
                                    accept=".xlsx" 
                                    onChange={(e) => this.props.uploadProfilesStructure(e)}
                                />
                                <Button id="uploadProfilesStructureBtn" className="btn-host btn-sm mr-2" onClick={() => document.getElementById("uploadProfilesStructureInput")?.click()}>
                                    <i className="fas fa-file-upload" />
                                </Button>
                                <CoolTooltip target="uploadProfilesStructureBtn" placement="top">
                                    <FormattedMessage id="SalesDeal.UploadProfilesStructure" />
                                </CoolTooltip>
                            </>
                        : ''}

                        <Button className="btn-host btn-sm" onClick={this.toggleModal}>
                            <i className="fas fa-plus " />
                        </Button>
                    </Col>
                </Row>

                <Row className="text-muted mb-lg-3">
                    <Col className='col-5 col-lg'><FormattedMessage id="generic.name"/></Col>
                    <Col className='col-6 col-lg'><FormattedMessage id="SalesDeal.Role"/></Col>
                    {!global.isMobile ?
                        <>
                            <Col><FormattedMessage id="SalesDeal.Notes"/></Col>
                            <Col className='col-1' />
                        </>
                    :''}
                </Row>

                {initiative.salesInitiativeProfile?.map((profile, key) =>
                    <Row className={`${!global.isMobile || key ? 'py-2' : 'pb-2' } overbglight`} key={key}>
                        <Col>
                            <ProfileCard
                                name={profile.name}
                                roleId={profile.roleId}
                                notes={profile.notes}
                                hasAddicionalFields={true}
                                roleList={roles}
                                handleListElement={(propName, value) => handleListElement(key, propName, value, false)}
                                removeElement={_ => handleListElement(key, null, null, true)}
                            />
                        </Col>
                    </Row>
                )}

                {associationModal ?
                    <AssociateProfile
                        modal={associationModal}
                        toggleModal={this.toggleModal}
                        associateProfileOrReservation={this.associateProfile}
                        selectProfile={this.selectProfile}
                        profile={selectedProfile}
                        toggleCreateModal={_ => this.toggleCreateModal()}
                        associateNewProfile={this.associateNewProfile}
                        createModal={createModal}
                        enableCreate={true}
                        disableMasterFilter={true}
                        createMasterAPI={true}
                        isPortal={true}
                    />
                : ''}
            </div>
        );
    }
}

export default injectIntl(InitiativeModalStep3);
