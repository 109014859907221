import React, { Component } from 'react';
import { Form, Card, Col, Row, Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from '../../Base/API';
import { Crt, FormatAmountNumber, SelectCampaign, StyledCard, CommonHelper } from '../../Base/CommonUIComponents';
import { handleNotification } from '../../Base/Notification';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import RevenueAndCampaignKPI from './RevenueAndCampaignKPI';
import { getNextColor, continentList, getChartComboOptions } from '../../Base/ReferenceDataFunctions';
import { Bar, Doughnut } from 'react-chartjs-2';
import Chart from 'react-google-charts';
import CustomSelect from '../../Base/CustomSelect';
import * as countryList from 'react-select-country-list';
import StatsPerTemplate from '../../Marketing/Common/StatsPerTemplate';
import BlockUi from 'react-block-ui';


export class CampaignResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            gaAccounts: [],
            selectedCampaign: null,
            startDate: moment().subtract(15, 'days'),
            endDate: moment(),
            campaignKPI: {},
            conversionsPerDay: { labels: [], datasets: [] },
            conversionsPerDevice: { labels: [], datasets: [] },
            totalValues: {},
            selectedGeoMapData: 'conversions',
            selectedPieData: 'conversions',
            activeTab: 'GlobalStats'
        }
    }

    componentDidMount() {
        this.getGaAccounts();
    }
    
    getGaAccounts = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                let gaAccounts = [];

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.response?.length > 0) {
                    gaAccounts = data.response.map(account => ({ value: account.id, label: account.name }));
                }

                this.setState({ gaAccounts, error: errorMessage, block: false });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/GoogleAnalytics/v1/googleanalytics`);
    }

    doSearch = (e) => {
        if (e) e.preventDefault();

        //Dates
        var formatDate = 'YYYY-MM-DD', type = 'days';

        var dates = new Array(moment(this.state.endDate).diff(moment(this.state.startDate), type)).fill(undefined)
            .reduce((acc, cur) => {
                acc.push(moment(this.state.startDate).add(acc.length, type).format(formatDate));

                return acc;
            }, [moment(this.state.startDate).format(formatDate)]);


        this.getCampaignKpi();
        this.getMetrics(dates);
    }

    getCampaignKpi = () => {
        this.setState({ blockCampaigns: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockCampaigns: false });
                return;
            }
            if (data) {
                this.setState({ campaignKPI: data, error: errorMessage, blockCampaigns: false });
            }
            else {
                this.setState({ error: errorMessage, blockCampaigns: false });
            }
        }, `/api/gms/Analytics/CampaignKPIs?id=${this.state.selectedCampaign.id}&sentFrom=${moment(this.state.startDate).format("YYYY-MM-DD")}&sentTo=${moment(this.state.endDate).format("YYYY-MM-DD")}`)
    }

    getMetrics = (dates) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage }, () => this.getGaReport(dates));
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.response && data.response.length > 0) {
                    this.setState({ error: errorMessage, campaign: data.response[0] }, () => this.getGaReport(dates, data.response[0].marketingSendKpis));
                    return;
                }

                this.setState({ error: errorMessage }, () => this.getGaReport(dates));
            }
            else {
                this.setState({ error: errorMessage }, () => this.getGaReport(dates));
            }
        }, `api/gms/Marketing/v1/marketingsend/${this.state.selectedCampaign.id}`)
    }

    getGaReport = (dates, marketingSendKpis) => {
        this.setState({ block: true });

        let params = `?campaignName=${this.state.selectedCampaign.campaignName}&startDate=${moment(this.state.startDate).format("YYYY-MM-DD")}&endDate=${moment(this.state.endDate).format("YYYY-MM-DD")}`;
        if (this.state.selectedGaAccount) params += `&gaConnectionId=${this.state.selectedGaAccount}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else if (data.response && data.response.length > 0) {
                    this.organizeChartsData(data.response.filter(res => res.payload), dates, marketingSendKpis);
                }

                this.setState({ error: errorMessage, block: false });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/GoogleAnalytics/v1/GetConversionsDetail` + params)
    }

    organizeChartsData = (data, dates, marketingSendKpis) => {
        //Charts structure
        let conversionsPerDay = {
            labels: dates,
            datasets: []
        }

        let totalByChannel = []

        const totalValues = {
            sent: marketingSendKpis?.reduce((acc, cur) => acc + cur.totalSent, 0),
            views: marketingSendKpis?.reduce((acc, cur) => acc + cur.totalViewed, 0),
            visits: 0, conversions: 0, revenue: 0
        }

        let perCountryData = {
            conversionsPerCountry: [[{ v: "Country Code", f: "Country Label" }, "Conversion"]],
            viewsPerCountry: [[{ v: "Country Code", f: "Country Label" }, "Views"]],
            revenuePerCountry: [[{ v: "Country Code", f: "Country Label" }, "Revenue"]]
        }

        let perDeviceData = {
            viewsPerDevice: { labels: [], datasets: [{ label: 'Views', data: [], backgroundColor: [] }] },
            conversionsPerDevice: { labels: [], datasets: [{ label: 'Conversions', data: [], backgroundColor: [] }] },
            revenuePerDevice: { labels: [], datasets: [{ label: 'Revenue', data: [], backgroundColor: [] }] }
        }

        let googleAccounts = [];

        data.forEach(GaAccount => {
            const payload = JSON.parse(GaAccount.payload);

            //List of accounts
            googleAccounts.push(GaAccount.googleAnalyticsId);

            
            //Dimensions and metrics indexes
            const eventNameIndex = payload.dimensionHeaders?.findIndex(d => d.name === 'eventName');
            const dateIndex = payload.dimensionHeaders?.findIndex(d => d.name === 'date');
            const hostNameIndex = payload.dimensionHeaders?.findIndex(d => d.name === 'hostName');
            const countryIndex = payload.dimensionHeaders?.findIndex(d => d.name === 'countryId');
            const deviceCategoryIndex = payload.dimensionHeaders?.findIndex(d => d.name === 'deviceCategory');
            const eventCountIndex = payload.metricHeaders?.findIndex(d => d.name === 'eventCount');
            const purchaseRevenueIndex = payload.metricHeaders?.findIndex(d => d.name === 'purchaseRevenue');
            const userCountIndex = payload.metricHeaders?.findIndex(d => d.name === 'activeUsers');
            const sessionCampaignIndex = payload.dimensionHeaders?.findIndex(d => d.name === 'sessionCampaignName');
            
            if (dateIndex !== -1 && hostNameIndex !== -1 && purchaseRevenueIndex !== -1 && eventCountIndex !== -1 && eventNameIndex !== -1 && sessionCampaignIndex !== -1) {

                

                payload.rows && payload.rows.forEach(row => {
                    const dayIndex = dates?.findIndex(day => moment(row.dimensionValues[dateIndex]?.value).isSame(day));

                    if (dayIndex !== -1) {
                        //GA values
                        const eventCount = parseInt(row.metricValues[eventCountIndex]?.value);
                        const eventValue = parseInt(row.metricValues[purchaseRevenueIndex]?.value);
                        const userCount = parseInt(row.metricValues[userCountIndex]?.value);
                        const channelName = row.dimensionValues[hostNameIndex]?.value;
                        const country = row.dimensionValues[countryIndex]?.value;
                        const device = row.dimensionValues[deviceCategoryIndex]?.value;
                        const sessionCampaignId = row.dimensionValues[sessionCampaignIndex]?.value;
                        

                        const countryLabel = countryList().getLabel(country.toUpperCase());

                       
                        if (row.dimensionValues[eventNameIndex]?.value === 'purchase') { //Conversions
                        
                            //Conversions Per Day
                            conversionsPerDay = this.getConversionsPerDay(dayIndex, channelName, eventCount,userCount, eventValue, dates.length, conversionsPerDay);
                            
                            //Total value cards
                            totalValues.conversions += eventCount;
                            totalValues.revenue += eventValue;
                            
                            //Table
                            totalByChannel = this.getTotalByChannel(channelName, eventCount, eventValue, totalByChannel, sessionCampaignId, true);

                            //GeoMaps
                            perCountryData.conversionsPerCountry = this.fillGeoMap(perCountryData.conversionsPerCountry, country, countryLabel, eventCount);
                            perCountryData.revenuePerCountry = this.fillGeoMap(perCountryData.revenuePerCountry, country, countryLabel, eventValue);


                            //Chart per device
                            perDeviceData.conversionsPerDevice = this.getDataPerDevice(perDeviceData.conversionsPerDevice, device, eventCount);
                            perDeviceData.revenuePerDevice = this.getDataPerDevice(perDeviceData.revenuePerDevice, device, eventValue);
                        }
                        else if (row.dimensionValues[eventNameIndex]?.value === 'page_view') { //Views
                            totalValues.visits += userCount;

                            //Table
                            totalByChannel = this.getTotalByChannel(channelName, userCount, eventValue, totalByChannel, sessionCampaignId, false);


                            //GeoMap views per country
                            perCountryData.viewsPerCountry = this.fillGeoMap(perCountryData.viewsPerCountry, country, countryLabel, userCount);


                            //Views per device
                            perDeviceData.viewsPerDevice = this.getDataPerDevice(perDeviceData.viewsPerDevice, device, userCount);
                        }
                    }
                })
            }
        })


        perCountryData.conversionsPerCountry = perCountryData.conversionsPerCountry.sort((a, b) => b[1] - a[1]);
        perCountryData.viewsPerCountry = perCountryData.viewsPerCountry.sort((a, b) => b[1] - a[1]);
        perCountryData.revenuePerCountry = perCountryData.revenuePerCountry.sort((a, b) => b[1] - a[1]);




        this.setState({
            conversionsPerDay,
            totalByChannel,
            totalValues,
            perCountryData,
            perDeviceData
        })
    }

    getDataPerDevice = (dataPerDevice, device, eventCount) => {
        const index = dataPerDevice.labels.findIndex(el => el === device);

        if (index !== -1) {
            dataPerDevice.datasets[0].data[index] += eventCount;
        }
        else {
            dataPerDevice.datasets[0].backgroundColor.push(getNextColor(dataPerDevice.labels.length));

            dataPerDevice.labels.push(device);
            dataPerDevice.datasets[0].data.push(eventCount);
        }

        return dataPerDevice;
    }

    fillGeoMap = (dataPerCountry, country, countryLabel, value) => {
        const index = dataPerCountry.findIndex(el => el[0].v === country);

        if (index !== -1) {
            dataPerCountry[index][1] += value;
        }
        else {
            dataPerCountry.push([{ v: country, f: countryLabel }, value]);
        }

        return dataPerCountry;
    }

    getTotalByChannel = (channelName, eventCount, eventValue, totalByChannel, sessionCampaignId, isRevenue) => {
        const rowIndex = totalByChannel.findIndex(el => el.channelName === channelName && el.sessionCampaignId === sessionCampaignId);

        if (rowIndex !== -1) {
            if (isRevenue) {
                totalByChannel[rowIndex].conversions += eventCount;
                totalByChannel[rowIndex].revenue += eventValue;
            }
            else
                totalByChannel[rowIndex].siteView += eventCount;
        }
        else {
            totalByChannel.push({
                channelName,
                sessionCampaignId,
                conversions: isRevenue ? eventCount : 0,
                revenue: isRevenue ? eventValue : 0,
                siteView: !isRevenue ? eventCount : 0
            })
        }

        return totalByChannel;
    }

    getConversionsPerDay = (dayIndex, channelName, eventCount, userCount, eventValue, datesLength, conversionsPerDay) => {
        const channelCountName = `${channelName} ${this.props.intl.formatMessage({ id: "CampaignResults.Conversions" }) }`
        const channelRevName = `${channelName} ${this.props.intl.formatMessage({ id: "CampaignResults.Revenue" }) }`
        const datasetIndex = conversionsPerDay.datasets.findIndex(dataset => channelCountName === dataset.id);
        const datasetRevIndex = conversionsPerDay.datasets.findIndex(dataset => channelRevName === dataset.id);
        

        if (datasetIndex !== -1) {
            conversionsPerDay.datasets[datasetIndex].data[dayIndex] += eventCount;
            conversionsPerDay.datasets[datasetRevIndex].data[dayIndex] += eventValue;
        }
        else {
            const color = getNextColor((conversionsPerDay.datasets.length ?? 1 + 1)/2);

            conversionsPerDay.datasets.push(
                {
                    label: channelCountName,
                    id: channelCountName,
                    data: Array(datesLength).fill(0),
                    backgroundColor: `${color}70`,
                    borderColor: color,
                    yAxisID: 'conversions',
                    type: 'bar'
                },
                {
                    label: channelRevName,
                    id: channelRevName,
                    type: "line",
                    data: Array(datesLength).fill(0),
                    borderColor: color,
                    backgroundColor: color,
                    yAxisID: 'revenue',
                },
            );

            conversionsPerDay.datasets[conversionsPerDay.datasets.length - 1].data[dayIndex] = eventValue;
            conversionsPerDay.datasets[conversionsPerDay.datasets.length - 2].data[dayIndex] = userCount;

        }


        return conversionsPerDay;
    }

    handleCampaigns = (name, combo) => {
        let { startDate, endDate } = this.state;

        if (combo?.campaignType === 'unique' && combo?.scheduleDay) {
            const daysDiff = moment().diff(moment(combo.scheduleDay), 'days');

            startDate = combo.scheduleDay;
            endDate = moment(combo.scheduleDay).add(daysDiff > 30 ? 30 : daysDiff, 'days');
        }
        else {
            startDate = moment().subtract(15, 'days');
            endDate = moment();
        }

        this.setState({
            [name]: combo || null,
            startDate,
            endDate
        })
    }

    getContinent = (country) => {
        var result = Object.keys(continentList).find(key => continentList[key].some(c => c === country.toUpperCase()));
        const geoChart = document.getElementsByClassName('ChartGeoChart')[0];

        if (result) {
            if (this.state.selectedRegion !== result) {
                geoChart.style.cursor = 'zoom-out'
                this.setState({ selectedRegion: result });
            }
            else {
                geoChart.style.cursor = 'zoom-in'
                this.setState({ selectedRegion: 'world' });
            }
        }
    }
    
    toggleTab = (val) => {
        this.setState({ activeTab: val })
    }


    render() {
        const { error, campaignKPI, perCountryData, selectedGeoMapData, selectedPieData, selectedRegion, gaAccounts, campaign } = this.state;

        const barChartOptions = {
            responsive: true,
            stacked: false,
            maintainAspectRatio: false,
            legend: {
                display: true,
                position: "top"
            },
            plugins: { datalabels: { display: false } },
            scales: {
                x: {
                    grid: { display: false }
                },
                conversions: {
                    ticks: { precision: 0 },
                    beginAtZero: true,
                    min: 0,
                    position: 'left',
                    title: {
                        display: true,
                        text: this.props.intl.formatMessage({ id: "CampaignResults.Conversions" })
                    },
                    grid: { display: true }
                },
                revenue: {
                    beginAtZero: true,
                    min: 0,
                    position: 'right',
                    title: {
                        display: true,
                        text: this.props.intl.formatMessage({ id: "CampaignResults.Revenue" })
                    },
                    grid: { display: true }
                }
            }
        }

        const optionsPie = {
            responsive: true,
            cutout: '60%',
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: "right"
                },
                datalabels: {
                    display: false
                }
            },
            layout: {
                padding: {
                    right: 100,
                    top: 60
                }
            }
        }


        return (
            <StyledCard error={error} >
                <Row>
                    <Col>
                        <h5><i className="fas fa-poll mr-2 mb-3" /> <FormattedMessage id="NavMenu.CampaignResults" /></h5>
                    </Col>
                </Row>

                <Form onSubmit={this.doSearch}>
                    <Row>
                        <Col sm={4}>
                            <SelectCampaign
                                name='selectedCampaign'
                                icon="icon-icon-unique-campaign"
                                isClearable
                                value={this.state.selectedCampaign?.value || ''}
                                intl={this.props.intl}
                                onChangeFunc={this.handleCampaigns.bind(this)}
                                placeholder={<FormattedMessage id="generic.Campaigns" />}
                                includeAlerts={true}
                            />
                        </Col>
                        {gaAccounts?.length > 1 ?
                            <Col sm={2}>
                                <CustomSelect
                                    options={gaAccounts}
                                    isSearchable isClearable
                                    onChange={(combo) => this.setState({ selectedGaAccount: combo ? combo.value : null })}
                                    placeholder={<FormattedMessage id="GoogleAnalyticsAccounts.GaAccount" />}
                                />
                            </Col>
                        : ''}
                        <Col>
                            <DateRangePicker
                                startDate={this.state.startDate ? moment(this.state.startDate) : null}
                                endDate={this.state.endDate ? moment(this.state.endDate) : null}
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                isOutsideRange={day => day > moment()}
                                endDateId="your_unique_end_date_id_announcement"
                                startDateId="your_unique_start_date_id_announcement"
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                required
                            />
                        </Col>
                        <Col>
                            <CommonHelper help={<FormattedMessage id="CampaignResults.Help" />} id={'CampaignResults'} lang={this.props.intl.locale} />
                            <Button className="float-right btn btn-sm btn-host" type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <Nav tabs className="border-0 mt-3">
                    <NavItem>
                        <NavLink className={(this.state.activeTab === 'GlobalStats' ? 'tab-border-host text-host' : '') + ' border-0 pointer'} onClick={() => { this.toggleTab('GlobalStats'); }}>
                            <b> <FormattedMessage id="CampaignResults.GlobalStats" /></b>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={(this.state.activeTab === 'StatsPerTemplate' ? 'tab-border-host text-host' : '') + ' border-0 ointer'} onClick={() => { this.toggleTab('StatsPerTemplate'); }} >
                            <b> <FormattedMessage id="CampaignResults.StatsPerTemplate" /></b>
                        </NavLink>
                    </NavItem>
                </Nav>
                <hr className="m-0" style={{ backgroundColor: '#C9DEFE' }} />
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="GlobalStats" className="border-0 my-0 p-0 ">
                        <BlockUi tag="div" blocking={this.state.block}>
                            <Row className="mt-3">
                                <Col>
                                    <Crt
                                        text={this.props.intl.formatMessage({ id: "CampaignResults.TotalSent" })}
                                        value={this.state.totalValues.sent || '-'}
                                    />
                                </Col>
                                <Col>
                                    <Crt
                                        text={this.props.intl.formatMessage({ id: "CampaignResults.TotalViews" })}
                                        value={this.state.totalValues.views || '-'}
                                    />
                                </Col>
                                <Col>
                                    <Crt
                                        text={this.props.intl.formatMessage({ id: "CampaignResults.TotalVisits" })}
                                        value={this.state.totalValues.visits || '-'}
                                    />
                                </Col>
                                <Col>
                                    <Crt
                                        text={this.props.intl.formatMessage({ id: "CampaignResults.TotalConversions" })}
                                        value={this.state.totalValues.conversions || '-'}
                                    />
                                </Col>
                                <Col>
                                    <Crt
                                        text={this.props.intl.formatMessage({ id: "CampaignResults.SiteRevenue" })}
                                        value={this.state.totalValues.revenue ? <FormatAmountNumber value={this.state.totalValues.revenue} /> : '-'}
                                    />
                                </Col>
                            </Row>

                            <Card className="shadow border-0 h-100 my-3" body>
                                <h5 className="text-muted mb-3"><FormattedMessage id="CampaignResults.ConversionsPerDay" /> </h5>

                                <div>
                                    <Bar data={this.state.conversionsPerDay} height={350} options={barChartOptions} />
                                </div>
                            </Card>

                            <RevenueAndCampaignKPI
                                campaignKPI={campaignKPI}
                                blockCampaigns={this.state.blockCampaigns}
                                totalByChannel={this.state.totalByChannel}
                            />

                            <Row className="mb-5" >
                                <Col sm={6}>
                                    <Card className="shadow border-0 h-100 my-3" body>
                                        <span className="d-flex align-items-center mb-3">
                                            <span style={{ width: '200px' }}>
                                                <CustomSelect
                                                    options={getChartComboOptions(this.props.intl)}
                                                    isSearchable
                                                    value={getChartComboOptions(this.props.intl).find(x => x.value == selectedPieData) ?? getChartComboOptions(this.props.intl)[0]}
                                                    onChange={(combo) => this.setState({ selectedPieData: combo.value })}
                                                />
                                            </span>
                                            <h5 className="text-muted mb-0 ml-2">
                                                <FormattedMessage id="CampaignResults.PerDevice" />
                                            </h5>
                                        </span>

                                        <div>
                                            {this.state.perDeviceData && this.state.perDeviceData[`${selectedPieData}PerDevice`] &&
                                                <Doughnut id="pieChart" data={this.state.perDeviceData[`${selectedPieData}PerDevice`]} height={250} options={optionsPie} />
                                            }
                                        </div>
                                    </Card>
                                </Col>
                                <Col sm={6}>
                                    <Card className="shadow border-0 h-100 my-3" body>
                                        <span className="d-flex align-items-center mb-3">
                                            <span style={{ width: '200px' }}>
                                                <CustomSelect
                                                    options={getChartComboOptions(this.props.intl)}
                                                    isSearchable
                                                    value={getChartComboOptions(this.props.intl).find(x => x.value == selectedGeoMapData) ?? getChartComboOptions(this.props.intl)[0]}
                                                    onChange={(combo) => this.setState({ selectedGeoMapData: combo.value })}
                                                />
                                            </span>
                                            <h5 className="text-muted mb-0 ml-2">
                                                <FormattedMessage id="CampaignResults.PerCountry" />
                                            </h5>
                                        </span>

                                        {perCountryData && perCountryData[`${selectedGeoMapData}PerCountry`] &&
                                            <Row>
                                                <Col className="col-10" style={{ maxHeight: '380px', overflow: 'hidden' }}>
                                                    <Chart
                                                        className="ChartGeoChart"
                                                        width='auto'
                                                        height='300px'
                                                        chartType="GeoChart"
                                                        data={perCountryData[`${selectedGeoMapData}PerCountry`]}
                                                        style={{ cursor: 'zoom-in' }}
                                                        options={{
                                                            colorAxis: { colors: ['#d6edfe', '#0867fa'] },
                                                            enableRegionInteractivity: true,
                                                            region: selectedRegion
                                                        }}
                                                        chartEvents={[{
                                                            eventName: "select",
                                                            callback: ({ chartWrapper }) => {
                                                                const selectedId = chartWrapper.getChart().getSelection();

                                                                if (selectedId.length) {
                                                                    this.getContinent(perCountryData[`${selectedGeoMapData}PerCountry`][selectedId[0].row + 1][0]?.v)
                                                                }
                                                            }
                                                        }]}
                                                    />
                                                </Col>

                                                <Col className="col-2 pl-0 verticalScroll" style={{ maxHeight: '330px', overflow: 'auto' }}>
                                                    {perCountryData[`${selectedGeoMapData}PerCountry`]?.map((arr, key) => {
                                                        return key > 0 && <Row className="mb-2 text-right" key={key} style={{ cursor: 'pointer' }}>
                                                            <Col className="px-0 text-right">
                                                                {arr[0] && arr[0].v ?
                                                                    <span style={{ borderRadius: '3px', fontSize: '18px' }} className={' mr-1 fa-md flag-icon flag-icon-' + arr[0].v.toLowerCase()} />
                                                                    : <b className='mr-2' > NA </b>}
                                                            </Col>
                                                            <Col className="px-2 col-8 text-left">
                                                                <span > {arr[1]} </span>
                                                            </Col>
                                                        </Row>
                                                    })}
                                                </Col>
                                            </Row>
                                        }
                                    </Card>
                                </Col>
                                </Row>
                        </BlockUi>
                    </TabPane>
                    <TabPane tabId="StatsPerTemplate" className="border-0 my-0 p-0 ">
                        {campaign ?
                            <StatsPerTemplate
                                campaignType={campaign?.senderChannel && campaign.senderChannel.toLowerCase()}
                                marketingSendKpis={campaign?.marketingSendKpis}
                            />
                            : ''}
                    </TabPane>
                </TabContent>

            </StyledCard>
        );
    }
}

export default injectIntl(CampaignResults);