import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import { StyledCard } from "../Base/CommonUIComponents";
import { handleNotification } from '../Base/Notification';
import { Badge, Button, Col, Row } from 'reactstrap';
import { CustomTable } from '../Base/CustomTable';
import { getGoContactActions } from '../Base/ReferenceDataFunctions';
import moment from 'moment';
import { GoContactImportModal } from './GoContactImportModal';

export class GoContactImport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: true,
            importBatchRunList: [],
            templates: [],
            dataTypes: getGoContactActions()[0].dataTypes.concat(getGoContactActions()[1].dataTypes),
            goContactImportModal: false,
            importBatch: null,
            apiConnection: null
        };
    }

    componentDidMount() {
        this.getTemplates('ticket', _ => this.getTemplates('queue', this.getImportBatchRun));
        this.getHotelGroup();
    }

    getImportBatchRun = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response) {
                    this.setState({ importBatchRunList: data.response, block: false });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/GoContact/v1/ImportBatchRun`);
    }

    getTemplates = (action, cbFunction) => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage }, cbFunction);
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.data) {
                    const { templates } = this.state;
                    const newTemplates = data.data[0]
                        .templates
                        .map(tmp => {
                            const template = {
                                value: tmp.id,
                                label: tmp.name
                            };
                            return template;
                        });
                    templates.push({ reportType: action, templates: newTemplates });
                    this.setState({ templates }, cbFunction);
                }
                else {
                    this.setState({ error: errorMessage }, cbFunction);
                }
            }
            else {
                this.setState({ error: errorMessage }, cbFunction);
            }
        }, `/api/gms/GoContact/v1/ReportTemplates?action=get${action}`);
    }

    getHotelGroup = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage });

                return;
            }
            if (data && data.response && data.response.length > 0) {
                const connection = data.response[0].hotelGroupConnection.find(conn => conn.type === "GoContact");
                if (connection) {
                    this.setState({ apiConnection: connection.id });
                }
            }
        }, `/api/gms/Hotel/v1/hotelgroup`);
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    toggleGoContactImportModal = (importBatch) => {
        this.setState(prevState => ({ goContactImportModal: !prevState.goContactImportModal, importBatch }));
    }

    updateTable = (newImportBatch) => {
        const { importBatchRunList } = this.state;

        const importBatchIdx = importBatchRunList.findIndex(imp => imp.id === newImportBatch.id);
        if (importBatchIdx >= 0) {
            importBatchRunList[importBatchIdx] = newImportBatch;
        }
        else {
            importBatchRunList.push(newImportBatch);
        }

        this.setState({ newImportBatch });
    }

    removeOnTable = (importBatch) => {
        const { importBatchRunList } = this.state;

        const importBatchRunListAux = [... importBatchRunList];
        const importBatchIdx = importBatchRunListAux.findIndex(imp => imp.id === importBatch.id);
        if (importBatchIdx >= 0) {
            importBatchRunListAux.splice(importBatchIdx, 1);
        }

        this.setState({ importBatchRunList: importBatchRunListAux });
    }

    render() {
        const { block, error, importBatchRunList, templates, dataTypes, goContactImportModal, importBatch, apiConnection } = this.state;

        const results = [
            {
                value: 'Sucess',
                label: <FormattedMessage id="GoContactImport.Sucess" />,
                badge: 'success'
            },
            {
                value: 'No data returned.',
                label: <FormattedMessage id="GoContactImport.NoDataReturned" />,
                badge: 'secondary'
            },
            {
                value: 'No report name returned.',
                label: <FormattedMessage id="GoContactUser.NoReportNameReturned" />,
                badge: 'danger'
            }
        ];

        const templatesList = templates.flatMap(tmp => tmp.templates);

        const columns = [
            {
                dataField: 'id',
                text: this.props.intl.formatMessage({ id: "GoContactImport.Id" }),
                style: { width: '25px' }
            },
            {
                dataField: 'goContactTemplateReportId',
                text: this.props.intl.formatMessage({ id: "GoContactImport.Template" }),
                style: { width: '25px' },
                formatter: cell => {
                    const template = templatesList.find(tmp => tmp.value == cell);
                    return template ? template.label : cell
                }
            },
            {
                dataField: 'lastRun',
                text: this.props.intl.formatMessage({ id: "GoContactImport.LastRun" }),
                style: { width: '25px' },
                formatter: cell => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : cell
            },
            {
                dataField: 'reportType',
                text: this.props.intl.formatMessage({ id: "GoContactImport.ReportType" }),
                style: { width: '25px' }
            },
            {
                dataField: 'dataType',
                text: this.props.intl.formatMessage({ id: "GoContactImport.DataType" }),
                style: { width: '25px' },
                formatter: cell => {
                    const dataType = dataTypes.find(dt => dt.value == cell);
                    return dataType ? dataType.label : cell
                }
            },
            {
                dataField: 'result',
                text: this.props.intl.formatMessage({ id: "GoContactImport.Result" }),
                style: { width: '25px' },
                formatter: (cell) => {
                    if (cell) {
                        const resultOpt = results.find(rslt => rslt.value === cell);
                        if (resultOpt) {
                            return <Badge className="m-1 p-1" color={resultOpt.badge}>{resultOpt.label}</Badge>
                        }
                        else {
                            return <Badge className="m-1"> {cell} </Badge>
                        }
                    }
                    return '';
                }
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row) => this.toggleGoContactImportModal(row)
        };

        return (
            <StyledCard block={block} error={error} icon="fas fa-database" title="Import Batch Run Settings">
                {
                    goContactImportModal ?
                        <GoContactImportModal
                            isOpen={goContactImportModal}
                            toggle={_ => this.toggleGoContactImportModal(importBatch)}
                            templates={templates}
                            importBatch={importBatch}
                            updateTable={this.updateTable}
                            removeOnTable={this.removeOnTable}
                            apiConnection={apiConnection}
                        />
                        :
                        ''
                }
                <Row className="mt-3 mb-4 align-items-center">
                    <Col className="text-right">
                        <Button className="mr-3 btn btn-sm btn-host" onClick={this.getImportBatchRun}>
                            <i className="fa fa-search" />
                        </Button>
                        <Button className="btn btn-sm btn-host" onClick={_ => this.toggleGoContactImportModal()}>
                            <i className="fa fa-plus" />
                        </Button>
                    </Col>
                </Row>
                <CustomTable
                    data={importBatchRunList}
                    columns={columns}
                    rowStyle={{ cursor: 'pointer' }}
                    hasPagination={true}
                    sizePerPage={10}
                    showTotal={true}
                    search={true}
                    selectRow={selectRow}
                />
            </StyledCard>
        );
    }
}
export default injectIntl(GoContactImport)