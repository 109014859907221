import React, { Component } from 'react';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import CoolDataGrid from '../../Base/CoolDataGrid/CoolDataGrid';
import moment from 'moment-timezone';
import { CoolTooltip, ExportToPDFButton, FormatAmountNumber } from '../../Base/CommonUIComponents';
import { getAPI, postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { ErrorAlert } from '../../Base/ErrorAlert';
import CustomBlockUI from '../../Base/CustomBlockUI';
import { Button, Col, Progress, Row, UncontrolledPopover } from 'reactstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import SalesProcessGridSpaceCell from './SalesProcessGridSpaceCell';
import { CustomFormatDate } from '../SalesProcessFunctions';
import ExcelJS from 'exceljs';
import BlockUi from 'react-block-ui';

class SalesProcessDealsRooms extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            rows: [],
            startDate: null,
            endDate: null,
            spacesAvail: null,
            roomsAvail: null,
            error: null,
            blockSpaces: {},
            blockRooms: {},
            hotelIds: [],
            hideStageType: false,
            hideOption: false,
            hideNoInv: false,
            dealIdWithFocusedCells: null,
            statusAffectingInv: [],
            dailyValues: [],
            clxStatus: [],
            simulationCards: [
                {
                    type: "number",
                    budget: 0,
                    forecast: 0,
                    simulated: 0,
                    name: "RoomNights",
                    salesGroupCode: "ROOM",
                    label: "SalesProcess.RoomNights",
                    forecastKey: "numberOfRooms"
                },
                {
                    type: "value",
                    budget: 0,
                    forecast: 0,
                    simulated: 0,
                    name: "Lodging",
                    salesGroupCode: "ROOM",
                    label: "SalesProcess.Lodging",
                    forecastKey: "roomsValue"
                },
                {
                    type: "value",
                    budget: 0,
                    forecast: 0,
                    simulated: 0,
                    name: "PA",
                    salesGroupCode: "FB",
                    label: "SalesProcess.FB",
                    forecastKey: "fbValue"
                },
                {
                    type: "value",
                    budget: 0,
                    forecast: 0,
                    simulated: 0,
                    name: "MISC",
                    salesGroupCode: "ETC",
                    label: "SalesProcess.Misc",
                    forecastKey: "equipmentValue"
                },
                {
                    type: "value",
                    budget: 0,
                    forecast: 0,
                    simulated: 0,
                    name: "Banquets",
                    salesGroupCode: "BANQ",
                    label: "SalesProcess.Banquets",
                    forecastKey: "banquetsValue"
                },
                {
                    type: "value",
                    budget: 0,
                    forecast: 0,
                    simulated: 0,
                    name: "SpaceRental",
                    salesGroupCode: "SPORT",
                    label: "SalesProcess.SpaceRental",
                    forecastKey: "spacesValue"
                },
                {
                    type: "value",
                    budget: 0,
                    forecast: 0,
                    simulated: 0,
                    name: "TotalValue",
                    label: "SalesProcess.TotalValue",
                },
            ],
            budgetRaw: null
        };
    }

    componentWillReceiveProps(nextProps) {
        const { hotelIds, hotelList, startDate, endDate, hideStageType, hideOption, hideNoInv, simulationMode } = nextProps;
        
        const diffHotelLength = hotelList?.length !== this.props.hotelList?.length;
        const diffDealLength = () => hotelList?.reduce((a, b) => a + b.stageTypes?.reduce((a, b) => a + b.salesDeals?.length, 0), 0) !== this.props.hotelList?.reduce((a, b) => a + b.stageTypes?.reduce((a, b) => a + b.salesDeals?.length, 0), 0);
        const diffDates = (startDate && !startDate.isSame(this.state.startDate)) || (endDate && !endDate.isSame(this.state.endDate));
        const diffHotels = (hotelIds && (hotelIds?.length !== this.props.hotelIds?.length || hotelIds?.some((hotelId) => !this.props.hotelIds?.includes(hotelId)) || this.props.hotelIds?.some((hotelId) => !hotelIds?.includes(hotelId))));
        const diffViewParams = hideStageType !== this.state.hideStageType || hideOption !== this.state.hideOption || hideNoInv !== this.state.hideNoInv;
        const simulateMode = simulationMode !== this.props.simulationMode && simulationMode;
        
        if((diffViewParams || diffHotels || diffDates || diffHotelLength || diffDealLength() || simulationMode !== this.props.simulationMode)) {
            this.setState({
                hotelIds,
                hideStageType,
                hideOption,
                hideNoInv,
                hotelList,
                startDate: startDate?.clone(),
                endDate: endDate?.clone(),
            }, () => {
                if (diffHotels || (diffDates && startDate && endDate)){
                    this.getHotelsEvents()
                    this.initialLoad(hotelList, startDate, endDate, hotelIds);
                }
                else {
                    this.getHotelsEvents()
                    this.initialCalc(hotelList, startDate, endDate);
                }

                if(simulateMode){
                    this.getBudgetValues();
                }
            });
        }
    }

    componentDidMount() {
        const { startDate, endDate, hotelList, hotelIds } = this.props;
        this.getTypesList();
        if(hotelIds && (!startDate?.isSame(this.state.startDate) || !endDate?.isSame(this.state.endDate)) && startDate && endDate){
            this.setState({ hotelList, startDate, endDate }, () => this.initialLoad(hotelList, startDate, endDate, hotelIds));            
        }
    }

    getBudgetValues = () => {
        const { startDate, endDate, hotelIds } = this.state;
        this.setState({ block: true }, () =>
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        this.setState({ error: errorMessage, block: false }, () => handleNotification(data));
                        return;
                    }
                    
                    this.calcBudgetandForecast(data.response);
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/budget?startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}&hotelId=${hotelIds[0]}`)
        );
    }

    calcBudgetandForecast = (budgetRaw) => {
        const { simulationCards, hotelList, statusAffectingInv, clxStatus } = this.state;
        const noTotal = simulationCards.filter(({name}) => name !== "TotalValue");

        if(!budgetRaw || !budgetRaw[0] || !budgetRaw[0].hotels || !budgetRaw[0].hotels[0])
            return;

        const budget = budgetRaw[0].hotels[0].salesGroups;

        noTotal.forEach((card) => {
            const budgetObj = budget.find(({salesGroupCode}) => salesGroupCode === card.salesGroupCode);

            if(budgetObj && budgetObj.days){
                if(card.type === "number"){
                    card.budget = budgetObj.days.reduce((a, b) => a + (b.roomNights??0), 0)??0;
                }
                else{
                    card.budget = budgetObj.days.reduce((a, b) => a + (b.value??0), 0)??0;
                }

                card.budget = parseFloat(parseFloat(card.budget).toFixed(0));
            }

            const relevantProposals = hotelList.reduce((a, b) => a.concat(b.stageTypes
                .reduce((c, d) => c.concat(d.salesDeals
                    .filter(e => e.status !== "Lost")
                    .reduce((f, g) => f.concat(g.proposals
                        .filter(h => !h.isOption && !clxStatus.includes(h.pmsStatus) && (g.simulatedWon || statusAffectingInv.includes(h.pmsStatus)))
                        .map((a) => ({...a, simulatedWon: g.simulatedWon}))
                    ), [])
                ), [])
            ), []);

            if(relevantProposals?.length){
                card.forecast = relevantProposals.filter(({simulatedWon}) => !simulatedWon).reduce((a, b) => a + (b[card.forecastKey]??0), 0)??0;
                card.simulated = relevantProposals.filter(({simulatedWon}) => simulatedWon).reduce((a, b) => a + (b[card.forecastKey]??0), 0)??0;
            }
        });


        const total = simulationCards.find(({name}) => name === "TotalValue");

        total.budget = noTotal.filter(({type}) => type !== "number").reduce((a, b) => a + b.budget, 0);
        total.forecast = noTotal.filter(({type}) => type !== "number").reduce((a, b) => a + b.forecast, 0);
        total.simulated = noTotal.filter(({type}) => type !== "number").reduce((a, b) => a + b.simulated, 0);

        this.setState({ simulationCards, budgetRaw, block: false });
    }

    getTypesList = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.data?.length) {
                    const statusAffectingInv = data.data.filter(x => x.fixedValue === "Won").reduce((a, b) => b.mappingCodes ? [...a, ...b.mappingCodes] : a, [])??[];
                    const clxStatus = data.data.filter(x => x.fixedValue === "Lost").reduce((a, b) => b.mappingCodes ? [...a, ...b.mappingCodes] : a, [])??[];

                    this.setState({
                        block: false,
                        statusAffectingInv,
                        clxStatus
                    });

                    return;
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/customtypes?type=PipelineStageType`);
    }

    goToDealDetail = (e, id) => {
        e.stopPropagation();
        this.props.selectDealDetail(id, 'SalesProcessGrid');
    }

    toggleFocusedDeals = (dealId, focus) => {
        const { dealIdWithFocusedCells } = this.state;
        
        const idToUse = focus ? dealId : dealIdWithFocusedCells;
        const cells = document.getElementsByClassName(`space-cell-data-${idToUse}`);
        const remove = document.getElementsByClassName("resources-grid-focused-cell");

        if(remove && remove.length > 0){
            while(remove.length > 0){
                remove[0].classList.remove("resources-grid-focused-cell");
            };
        }

        if(cells && cells.length > 0){
            if(focus){
                cells.forEach(c => c.classList.add("resources-grid-focused-cell"));
            }

            cells[0].scrollIntoView(false);

            this.setState({ dealIdWithFocusedCells: focus ? dealId : null });
        }
    }

    sortByStatus(arr) {
        const statusOrder = {
            "Won": 1,
            "WaitingHotel": 2,
            "WaitingClient": 3,
        };
    
        return arr?.sort((a, b) => {
            const statusA = statusOrder[a?.status] || 4; // Default to 4 if status is not found
            const statusB = statusOrder[b?.status] || 4; // Default to 4 if status is not found
            
            return (statusA??0) - (statusB??0);
        });
    }

    getHotelsEvents = (e) => {
        if (e) e.preventDefault();
        const { startDate, endDate } = this.state;
        const { hotelIds } = this.props;

        if (!startDate || !endDate) {
            return;
        }

        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    if (data.response?.length) {
                        const dailySummary = {};

                        data.response.forEach(event => {
                            const startDate = new Date(event.startDate);
                            const endDate = new Date(event.endDate);

                            let currentDate = new Date(startDate);
                            while (currentDate <= endDate) {
                                const dayStr = currentDate.toISOString().split('T')[0];

                                if (!dailySummary[dayStr]) {
                                    dailySummary[dayStr] = { type: "", day: dayStr, pax: 0 };
                                }
                                dailySummary[dayStr].pax += event.pax;

                                dailySummary[dayStr].type = dailySummary[dayStr].type == "Event" ? "Event" : event.eventType;

                                currentDate.setDate(currentDate.getDate() + 1);
                            }
                        });

                        var sortedSummary = Object.values(dailySummary).sort((a, b) => new Date(a.day) - new Date(b.day));

                        this.setState({ hotelsCityEvents: sortedSummary, hotelsCityEventsDetails: data.response, block: false });
                        return;
                    }

                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/location/events?${hotelIds && hotelIds.map(h => `hotelIds=${h}`).join('&')}${startDate ? `&startDate=${moment(startDate)?.format('YYYY-MM-DD')}` : ''}${endDate ? `&endDate=${moment(endDate)?.format('YYYY-MM-DD')}` : ''}`)
        });
    }

    getDailyValues = async (proposals) => {
        return new Promise (resolve => postAPI((result) => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    resolve([]);
                    return;
                }

                if (data.data?.length) {
                    this.setState({ dailyValues: data.data, block: false });
                    resolve(data.data);
                    return;
                }

                this.setState({ error: errorMessage, block: false });
                resolve([]);
                return;
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/dailyValues`,
            {
                startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
                endDate: moment(this.state.endDate).format('YYYY-MM-DD'),
                proposalIds: proposals.map(p => p.proposalId)
            }
        ));
    }

    downloadExcel = async () => {
        const { startDate, endDate, hotelList, spacesAvail, roomsAvail, statusAffectingInv, clxStatus } = this.state;
        const { intl, spaceList } = this.props;
        
        this.setState({ block: true }, async () => {

            if(!roomsAvail || !spacesAvail || !hotelList?.length){
                this.setState({ block: false });
                return;
            }

            const proposalsReduce = hotelList.reduce((a, b) => a.concat(b.stageTypes.reduce((a, b) => a.concat(b.salesDeals.reduce((a, b) => a.concat(b.proposals), [])), [])), []);

            await this.getDailyValues(proposalsReduce)
            .then(dailyValues => {
                
                if (!dailyValues) {
                    this.setState({ block: false });
                    return;
                }

                const alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
                const dealNameLabel = intl.formatMessage({ id: "SalesProcess.Name" });
                const responsibleLabel = intl.formatMessage({ id: "SalesProcess.Responsible" });
                const dealStatusLabel = intl.formatMessage({ id: "SalesProcess.DealStatus" });
                const statusLabel = intl.formatMessage({ id: "SalesProcess.Status" });
                const totalPaxLabel = intl.formatMessage({ id: "SalesProcess.TotalPax" });
                const numberOfRoomsLabel = intl.formatMessage({ id: "SalesProcess.NumberOfRooms" });
                const checkInLabel = intl.formatMessage({ id: "SalesProcess.CheckIn" });
                const checkOutLabel = intl.formatMessage({ id: "SalesProcess.CheckOut" });
                const numberOfNightsLabel = intl.formatMessage({ id: "SalesProcess.NumberOfNights" });
                const avgRateLabel = intl.formatMessage({ id: "SalesProcess.TotalAvgRate" });
                const rateLabel = intl.formatMessage({ id: "SalesProcess.AvgRate" });
                const dealRevenueRoomsLabel = intl.formatMessage({ id: "SalesProcess.DealTotalRevenueRooms" });
                const dealFBLabel = intl.formatMessage({ id: "SalesProcess.DealTotalF&B" });
                const dealBanquetsLabel = intl.formatMessage({ id: "SalesProcess.DealTotalBanquets" });
                const dealSpaceRentalLabel = intl.formatMessage({ id: "SalesProcess.DealTotalSpaceRental" });
                const dealMiscLabel = intl.formatMessage({ id: "SalesProcess.DealTotalMisc" });
                const dealTotalRevenueLabel = intl.formatMessage({ id: "SalesProcess.DealTotalTotalRevenue" });
                const dealNETRevenueRoomsLabel = intl.formatMessage({ id: "SalesProcess.DealNETTotalRevenueRooms" });
                const dealNETFBLabel = intl.formatMessage({ id: "SalesProcess.DealNETTotalF&B" });
                const dealNETBanquetsLabel = intl.formatMessage({ id: "SalesProcess.DealNETTotalBanquets" });
                const dealNETSpaceRentalLabel = intl.formatMessage({ id: "SalesProcess.DealNETTotalSpaceRental" });
                const dealNETMiscLabel = intl.formatMessage({ id: "SalesProcess.DealNETTotalMisc" });
                const dealNETTotalRevenueLabel = intl.formatMessage({ id: "SalesProcess.DealNETTotalTotalRevenue" });
                const revenueRoomsLabel = intl.formatMessage({ id: "SalesProcess.RevenueRooms" });
                const fBLabel = intl.formatMessage({ id: "SalesProcess.F&B" });
                const banquetsLabel = intl.formatMessage({ id: "SalesProcess.Banquets" });
                const spaceRentalLabel = intl.formatMessage({ id: "SalesProcess.SpaceRental" });
                const miscLabel = intl.formatMessage({ id: "SalesProcess.Misc" });
                const totalRevenueLabel = intl.formatMessage({ id: "SalesProcess.TotalRevenue" });
                
                const cols = [
                    dealNameLabel,
                    responsibleLabel,
                    dealStatusLabel,
                    statusLabel,
                    checkInLabel,
                    checkOutLabel,
                    totalPaxLabel,
                    avgRateLabel,
                    numberOfNightsLabel,
                    numberOfRoomsLabel,
                    dealRevenueRoomsLabel,
                    dealFBLabel,
                    dealBanquetsLabel,
                    dealSpaceRentalLabel,
                    dealMiscLabel,
                    dealTotalRevenueLabel,
                    dealNETRevenueRoomsLabel,
                    dealNETFBLabel,
                    dealNETBanquetsLabel,
                    dealNETSpaceRentalLabel,
                    dealNETMiscLabel,
                    dealNETTotalRevenueLabel,
                    revenueRoomsLabel,
                    fBLabel,
                    banquetsLabel,
                    spaceRentalLabel,
                    miscLabel,
                    totalRevenueLabel
                ];

                const numberOfDays = moment(endDate).diff(moment(startDate), 'day') + 1;
                        
                const getCurrentLetter = (counter) => {
                    const value = (counter / alphabet.length);
                    
                    if(value > 0){
                        const startIdx = Math.floor(counter / alphabet.length);
                        const lastIdx = counter - (alphabet.length * startIdx);
                        
                        return `${alphabet[startIdx - 1]}${alphabet[lastIdx]}`;
                    }

                    return alphabet[counter];
                }

                const getValue = (isHotel, deal, key, hotelRoomsAvail, d, currency) => {
                    if(!isHotel){
                        switch(d){
                            case dealNameLabel:
                                return `HYPERLINK("${window.location.href.slice(0, window.location.href.indexOf('/', 8))}/SalesProcess?dealId=${deal.dealId}#SalesDealDetail", "${deal.dealName.replace('"', '""')}")`;;
                            case responsibleLabel:
                                return deal.salesDealUsers[0]?.name??'-';
                            case dealStatusLabel:
                                return (deal.status === "WaitingClient" || deal.status === "WaitingHotel") ? 
                                    intl.formatMessage({ id: "SalesProcess.InNegotiation" })
                                : deal.status === "Finished" ? 
                                    intl.formatMessage({ id: "SalesProcess.Finished" })
                                : deal.status === "Effective" ? 
                                    intl.formatMessage({ id: "SalesProcess.Effective" })
                                :
                                    intl.formatMessage({ id: "SalesProcess.Lost" });
                            case statusLabel:
                                return deal.proposals?.find(({pmsStatus, type}) => type === "Block" && statusAffectingInv.includes(pmsStatus))?.pmsStatus
                                    ??
                                        deal.proposals?.find(({type}) => type === "Block")?.pmsStatus
                                    ??
                                        deal.proposals?.find(({pmsStatus, type}) => type === "Event" && statusAffectingInv.includes(pmsStatus))?.pmsStatus
                                    ??
                                        deal.proposals?.find(({type}) => type === "Event")?.pmsStatus;
                            case totalPaxLabel:
                                return deal.pax;
                            case numberOfRoomsLabel:
                                return deal.numberOfRooms ?? 0;
                            case checkInLabel:
                                return CustomFormatDate(deal.checkIn, null, null, intl);
                            case checkOutLabel:
                                return CustomFormatDate(deal.checkOut, null, null, intl);
                            case numberOfNightsLabel:
                                return moment(deal.checkOut).diff(moment(deal.checkIn), 'd');
                            case avgRateLabel:
                                return !deal.totalRevenue || !deal.numberOfRooms || isNaN(deal.totalRevenue / deal.numberOfRooms) ?
                                    0
                                :
                                    parseFloat(parseFloat(((deal.totalRevenue / deal.numberOfRooms)?.toFixed(2) ?? 0)).toFixed(2));
                            case rateLabel:
                                return !deal.revenueRooms || !deal.numberOfRooms || isNaN(((deal.revenueRooms + (deal.revenueFB ?? 0)) / deal.numberOfRooms)) ?
                                    0
                                :
                                    parseFloat(parseFloat((((deal.revenueRooms + (deal.revenueFB ?? 0)) / deal.revenueRooms)?.toFixed(2) ?? 0)).toFixed(2));
                            case dealRevenueRoomsLabel:
                                return parseFloat(parseFloat((deal.revenueRooms?.toFixed(2)??0)).toFixed(2));
                            case dealFBLabel:
                                return parseFloat(parseFloat((deal.revenueFB?.toFixed(2)??0)).toFixed(2));
                            case dealBanquetsLabel:
                                return parseFloat(parseFloat((deal.revenueBanquets?.toFixed(2)??0)).toFixed(2));
                            case dealSpaceRentalLabel:
                                return parseFloat(parseFloat((deal.revenueSpaces?.toFixed(2)??0)).toFixed(2));
                            case dealMiscLabel:
                                return parseFloat(parseFloat((deal.revenueEquipment?.toFixed(2)??0)).toFixed(2));
                            case dealTotalRevenueLabel:
                                return parseFloat(parseFloat((deal.totalRevenue?.toFixed(2)??0)).toFixed(2));

                            case dealNETRevenueRoomsLabel:
                                return calcNETValueForDeal("rooms", deal.proposals);
                            case dealNETFBLabel:
                                return calcNETValueForDeal("fb", deal.proposals);
                            case dealNETBanquetsLabel:
                                return calcNETValueForDeal("banquets", deal.proposals);
                            case dealNETSpaceRentalLabel:
                                return calcNETValueForDeal("space", deal.proposals);
                            case dealNETMiscLabel:
                                return calcNETValueForDeal("misc", deal.proposals);
                            case dealNETTotalRevenueLabel:
                                return calcNETValueForDeal("total", deal.proposals);

                            case revenueRoomsLabel:
                                return calcValueForPeriod("rooms", deal.proposals);
                            case fBLabel:
                                return calcValueForPeriod("fb", deal.proposals);
                            case banquetsLabel:
                                return calcValueForPeriod("banquets", deal.proposals);
                            case spaceRentalLabel:
                                return calcValueForPeriod("space", deal.proposals);
                            case miscLabel:
                                return calcValueForPeriod("misc", deal.proposals);
                            case totalRevenueLabel:
                                return calcValueForPeriod("total", deal.proposals);

                            default:{
                                const value = (deal.proposals.reduce((a, b) => a + (JSON.parse(b.proposalJSON)?.BlockData?.details?.reduce((a, b) => a + (moment(b.Date).format('ddd - YYYY-MM-DD') === d ? b.CurrentRooms : 0), 0)??0), 0)??0);
                                
                                return value === 0 ? '' : value;
                            }
                        }
                    }
                    else{
                        if(d === dealNameLabel)
                            return intl.formatMessage({ id: "SalesProcess.Hotel" });
                        else if(key > 27)
                            return hotelRoomsAvail?.find(({hotelDate}) => moment(hotelDate).isSame(d))?.freeRooms??0;
                        return null;
                    }
                }


                const calcNETValueForDeal = (type, proposals) => {
                    const keyValue = {
                        block: {
                            rooms: "PriceBeforeTaxPerRoom_RoomOnly",
                            fb: "PriceBeforeTaxPerRoom_Fb",
                            misc: "PriceBeforeTaxPerRoom_Other",
                            total: "PriceBeforeTaxPerRoom_Total"
                        },
                        events: {
                            banquets: "FB",
                            space: "SPACE",
                            misc: "EQUIPMENT"
                        }
                    };

                    return (
                        parseFloat(
                            parseFloat(
                                proposals
                                .reduce((a, b) => {
                                    let value = 0;
                                    
                                    if(!clxStatus.includes(b.pmsStatus)){
                                        if(b.type === "Block") {
                                            const json = JSON.parse(b.proposalJSON);
                                            value = json.BlockData.details.reduce((a, b) =>
                                                a + (b.CurrentRooms * (b[keyValue.block[type]]??0))
                                            , 0);
                                        }
                                        else {
                                            const json = JSON.parse(b.proposalJSON);
                                            const event = json.data[0].DetailsEvents[0];
                                            value = (event?.PriceDetailsNonSpace?.filter(({Type}) => type === "total" || Type === keyValue.events[type]).reduce((a, b) => a + b.AmountBeforeTax, 0)??0);
                                            value += (event?.Spaces?.reduce((a, b) => a + b.PriceDetails.filter(({Type}) => type === "total" || Type === keyValue.events[type]).reduce((a, b) => a + b.AmountBeforeTax, 0), 0)??0);
                                        }
                                    }
                                    
                                    return a + value;
                                }, 0)??0
                            ).toFixed(2)
                        )
                    );
                }

                const calcValueForPeriod = (type, proposals) => {
                    const keyValue = {
                        rooms: "roomsValue",
                        fb: "fbValue",
                        banquets: "banquetsValue",
                        space: "spacesValue",
                        misc: "equipmentValue",
                        total: "totalValue"
                    };

                    const proposalsIds = proposals.map(({proposalId}) => proposalId);

                    const current = dailyValues.filter(d => proposalsIds.includes(d.salesDealProposalId));

                    const value = current.reduce((a, b) =>
                        a + b[keyValue[type]]??0
                    , 0);
    
                    return parseFloat(parseFloat(value).toFixed(2));
                }
        
                for (let d = 1; d <= numberOfDays; d++) {
                    const date = startDate.clone().add((d - 1), 'day');
                    
                    cols.push(...[moment(date).format('ddd - YYYY-MM-DD'), `${moment(date).format('ddd - YYYY-MM-DD')}a`, `${moment(date).format('ddd - YYYY-MM-DD')}b`, `${moment(date).format('ddd - YYYY-MM-DD')}c`]);
                }
                
                const workbook = new ExcelJS.Workbook();
                
                hotelList.forEach((hotel) => {
                    const hotelRoomsAvail = roomsAvail && roomsAvail[hotel.hotelId];
                    const allDeals = hotel.stageTypes.flatMap(s => s.salesDeals);
                    const currency = global.hotelList.find(x => x.value == hotel.hotelId)?.currency;

                    const rows = [
                        Object.assign({}, ...cols.map((d, key) => ({
                            [d]: getValue(true, null, key, hotelRoomsAvail, d, currency)
                        }))),
                        ...allDeals.map(deal => 
                            Object.assign({}, ...cols.map((d, key) => ({
                                [d]: getValue(false, deal, key, null, d, currency)
                            })
                        ))
                    )];

                    const worksheet = workbook.addWorksheet(global.hotelList.find(x => x.value == hotel.hotelId)?.label);
                    
                    worksheet.columns = cols.map((key, k) => ({
                        header: key,
                        key: key,
                        width: !k ? 50 : k < 28 ? 32 : 8
                    }));

                    for(let i = 1; i <= (cols.length - 28); i++){
                        const letterCounter = (i === 1 ? 1 : ((i - 1) * 4) + 1) + 27;
                        
                        const currentLetter = getCurrentLetter(letterCounter);
                        const lastLetter = getCurrentLetter(letterCounter + 3);
                        
                        worksheet.mergeCells(`${currentLetter}1:${lastLetter}1`);
                    }
            
                    worksheet.getRow(1).font = { bold: true, name: 'Roboto' };
                    worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
                    worksheet.getRow(1).height = 30;

                    worksheet.getCell(1, 1).alignment = { vertical: 'middle', horizontal: 'left' };
            
                    rows.forEach((rowData, index) => {
                        const row = worksheet.addRow(rowData);

                        if(index){
                            row.getCell(1).value = { formula: row.getCell(1).value };
                        }

                        const totalDays = (cols.length - 28) / 4;

                        for(let i = 1; i <= totalDays; i++){
                            const letterCounter = (i === 1 ? 1 : ((i - 1) * 4) + 1) + 27;
                            
                            const currentLetter = getCurrentLetter(letterCounter);
                            const lastLetter = getCurrentLetter(letterCounter + 3);

                            worksheet.mergeCells(`${currentLetter}${index + 2}:${lastLetter}${index + 2}`);
                        }

                        row.eachCell({ includeEmpty: true }, (cell, key) => {
                            cell.font = { name: 'Roboto', color: { argb: 'FF4A4A4A' } };

                            cell.border = {
                                top: { style: 'thin', color: { argb: 'FFCCCCCC' } },
                                left: { style: 'thin', color: { argb: 'FFCCCCCC' } },
                                bottom: { style: 'thin', color: { argb: 'FFCCCCCC' } },
                                right: { style: 'thin', color: { argb: 'FFCCCCCC' } }
                            };

                            if(key === 1){
                                cell.alignment = { vertical: 'middle', horizontal: 'left' };
                            }
                            else{
                                cell.alignment = { vertical: 'middle', horizontal: 'center' };
                            }

                            if(!index) {
                                if(key > 28){
                                    const date = moment(worksheet.getCell(1, key).value);
                                    const dailyInv = hotelRoomsAvail?.find(({hotelDate}) => moment(hotelDate).isSame(date));
                                    const roomsInv = dailyInv?.inventoryRooms??0;
                                    const freeRooms = dailyInv?.freeRooms??0;
                                    const total = roomsInv - freeRooms;
                                    const percentage = Math.round(((total / roomsInv) * 100) * 100) / 100;
                    
                                    const color = (0 <= percentage && percentage < 33) ? 'FF5CB85C'
                                    : (33 <= percentage && percentage < 66) ? 'FFF0AD4E'
                                    : 'FFD9534F';
                                    
                                    if(dailyInv){
                                        cell.fill = {
                                            type: 'pattern',
                                            pattern: 'solid',
                                            fgColor: { argb: color }
                                        };
                                    }
                                }
                            }
                            else{
                                if (index % 2 === 0) {
                                    cell.fill = {
                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FFF3F3F3' }
                                    };
                                }
                            }
                        });

                        row.height = 30;
                    });

                    const spaceTitleRow = worksheet.addRow({ [dealNameLabel]: intl.formatMessage({ id: "SalesProcess.Spaces" }) });
                    spaceTitleRow.font = { bold: true, name: 'Roboto' };
                    spaceTitleRow.alignment = { vertical: 'middle', horizontal: 'left' };
                    spaceTitleRow.height = 30;
                    
                    worksheet.mergeCells(spaceTitleRow.number, 1, spaceTitleRow.number, cols.length);

                    const hotelSpaceAvail = spacesAvail && spacesAvail[hotel.hotelId];

                    const spacesRows = spaceList
                        .filter(({hotelId}) => hotelId === hotel.hotelId)
                        .map(({description}) => {
                            const events = hotelSpaceAvail?.filter(({spaceName}) => spaceName === description);

                            const dailyOccBySlot = [];

                            if(events){
                                events.forEach(({eventId, spaceFromDate, spaceToDate, reservationStatusCode}) => {
                                    const numberOfDays = moment(spaceToDate).diff(moment(spaceFromDate), 'day') + 1;
                                    
                                    for (let d = 1; d <= numberOfDays; d++) {
                                        const date = moment(spaceFromDate).clone().add((d - 1), 'day').format('YYYY-MM-DD');

                                        let current = dailyOccBySlot.find(({day}) => day === date);

                                        if(!current){
                                            dailyOccBySlot.push({
                                                day: date,
                                                slots: [
                                                    {
                                                        value: 0,
                                                        events: []
                                                    },
                                                    {
                                                        value: 0,
                                                        events: []
                                                    },
                                                    {
                                                        value: 0,
                                                        events: []
                                                    },
                                                    {
                                                        value: 0,
                                                        events: []
                                                    }
                                                ]
                                            });

                                            current = dailyOccBySlot.find(({day}) => day === date);
                                        }

                                        current.slots = current.slots.map((slot, key) => {
                                            const newValue = (key) => {
                                                const from = moment(spaceFromDate);
                                                const to = moment(spaceToDate);
                                                const isFirstDay = from.clone().startOf('d').isSame(date);
                                                const isLastDay = to.clone().startOf('d').isSame(date);
                                                const startHour = parseInt(from.format('HH'));
                                                let endHour = parseInt(to.clone().format('HH'));
                                                
                                                if(endHour > 0) {
                                                    endHour = parseInt(to.clone().add('minute', -1).format('HH'));
                                                }

                                                const valueToCompareStart = 6 * (key + 1);
                                                const valueToCompareEnd = 6 * (key);

                                                if(isFirstDay && isLastDay){
                                                    return startHour < valueToCompareStart && endHour >= valueToCompareEnd;
                                                }

                                                if(isFirstDay) {
                                                    return startHour < valueToCompareStart;
                                                }

                                                if(isLastDay) {
                                                    return endHour > valueToCompareEnd;
                                                }

                                                return !isFirstDay && !isLastDay
                                            }

                                            const proposalForEvent = allDeals
                                                ?.filter(d => d.proposals)
                                                ?.flatMap(d => d.proposals)
                                                ?.filter((proposal) =>
                                                    moment(proposal.startDate).startOf('d').isSameOrBefore(date) &&
                                                    moment(proposal.endDate).startOf('d').isSameOrAfter(date)
                                                )
                                                .find(({externalId}) => parseInt(externalId) === eventId);

                                            const result = {
                                                value: slot.value + (newValue(key) ? 1 : 0),
                                                events: slot.events
                                            }

                                            if(proposalForEvent){
                                                result.events.push({
                                                    eventId,
                                                    spaceFromDate,
                                                    spaceToDate,
                                                    isDeal: true,
                                                    name: proposalForEvent.dealName,
                                                    pmsStatus: proposalForEvent.pmsStatus,
                                                    dealId: proposalForEvent.dealId,
                                                    start: moment(spaceFromDate).format("HH:mm"),
                                                    end: moment(spaceToDate).format("HH:mm"),
                                                    isDEF: statusAffectingInv.includes(proposalForEvent.pmsStatus)
                                                });
                                            }
                                            else{
                                                result.events.push({
                                                    eventId,
                                                    spaceFromDate,
                                                    spaceToDate,
                                                    pmsStatus: reservationStatusCode,
                                                    start: moment(spaceFromDate).format("HH:mm"),
                                                    end: moment(spaceToDate).format("HH:mm"),
                                                    isDEF: statusAffectingInv.includes(reservationStatusCode)
                                                });
                                            }

                                            return result;
                                        });
                                    }
                                });
                            }

                            return ({
                                [dealNameLabel]: description,
                                ...Object.assign({},
                                    ...cols.map((key, k) => {
                                        if(key === dealNameLabel)
                                            return
                                        if(k < 28)
                                            return {[key]: '-'};

                                        const dailyOcc = dailyOccBySlot.filter(({day}) => day === (key.length > 16 ? key.substring(6, 16) : key));
                                        
                                        const slotOcc = dailyOcc.reduce((a, b) => {
                                            const arr = [...a];

                                            arr[0].value += (b.slots[0]?.value??0);
                                            arr[1].value += (b.slots[1]?.value??0);
                                            arr[2].value += (b.slots[2]?.value??0);
                                            arr[3].value += (b.slots[3]?.value??0);

                                            if(b.slots[0]?.events && b.slots[0]?.events.length > 0){
                                                arr[0].events = [...arr[0].events, ...b.slots[0]?.events];
                                            }
                                            if(b.slots[1]?.events && b.slots[1]?.events.length > 0){
                                                arr[1].events = [...arr[1].events, ...b.slots[1]?.events];
                                            }
                                            if(b.slots[2]?.events && b.slots[2]?.events.length > 0){
                                                arr[2].events = [...arr[2].events, ...b.slots[2]?.events];
                                            }
                                            if(b.slots[3]?.events && b.slots[3]?.events.length > 0){
                                                arr[3].events = [...arr[3].events, ...b.slots[3]?.events];
                                            }

                                            return arr;
                                        }, [{value: 0, events: []},{value: 0, events: []},{value: 0, events: []},{value: 0, events: []}]);

                                        const letter = key.substring(16, 17);

                                        const idx = !letter ? 0 : letter === 'a' ? 1 : letter === 'b' ? 2 : 3;

                                        return ({
                                            [key]: slotOcc[idx]
                                        })
                                    })
                                )
                            });
                        });

                    spacesRows.forEach((rowData, index) => {
                        const rowDataOnlyValue = Object.values(rowData).map((x) => typeof x === "object" ? x?.value === 0 ? '' : x?.value : x);

                        const row = worksheet.addRow(rowDataOnlyValue);

                        row.eachCell({ includeEmpty: true }, (cell, key) => {

                            cell.border = {
                                top: { style: 'thin', color: { argb: 'FFCCCCCC' } },
                                left: { style: 'thin', color: { argb: 'FFCCCCCC' } },
                                bottom: { style: 'thin', color: { argb: 'FFCCCCCC' } },
                                right: { style: 'thin', color: { argb: 'FFCCCCCC' } }
                            };

                            if(key === 1){
                                cell.alignment = { vertical: 'middle', horizontal: 'left' };
                            }
                            else{
                                cell.alignment = { vertical: 'middle', horizontal: 'center' };
                            }
                            
                            let fgColor = 'FFFFFFFF';

                            if(key <= 28){
                                if(index % 2 === 0)
                                    fgColor = 'FFF3F3F3';
                            }
                            else{
                                if(cell.value > 0){
                                    if(cell.value > 1){
                                        const dateKey = Object.keys(rowData)[key - 1];

                                        if(rowData[dateKey]?.events.filter(({isDEF}) => isDEF).length > 1){
                                            fgColor = 'FF3B3434';
                                        }
                                        else{
                                            fgColor = 'FFD9534F';
                                        }
                                    }
                                    else{
                                        fgColor = 'FF5CB85C';
                                    }
                                }
                            }

                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: fgColor }
                            };

                            cell.font = { name: 'Roboto', color: { argb: fgColor === 'FF3B3434' ? 'FFFFFFFF' : 'FF4A4A4A' } };

                        });

                        row.height = 30;
                    });

                    const collapse = 28;
                    for(let i = 1; i <= cols.length; i++){
                        const col = worksheet.getColumn(i);
                        col.outlineLevel = i > 1 && i <= collapse ? 1 : 0;
                        col.hidden = i > 1 && i <= collapse;
                    }
                    
                    worksheet.views = [
                        { state: 'frozen', xSplit: 1, ySplit: 1 }
                    ];


                    const exportedRow = worksheet.addRow({ [dealNameLabel]: `Exported on ${moment().format("DD-MM-YYYY HH:mm")}` });
                    exportedRow.font = { name: 'Roboto' };
                    exportedRow.alignment = { vertical: 'middle', horizontal: 'left' };
                    exportedRow.height = 30;
                    
                    worksheet.mergeCells(exportedRow.number, 1, exportedRow.number, cols.length);
                });

                workbook.xlsx.writeBuffer().then((buffer) => {
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${`ResourcesGrid `}${moment(startDate).format("DD-MM-YYYY")} ${moment(endDate).format("DD-MM-YYYY")}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });

                this.setState({ block: false });
            });
        });
    }

    clearSimulateMode = () => {
        const removeCellsToggle = document.getElementsByClassName(`invalid-toggleSimulationModeDeal`);

        if(removeCellsToggle && removeCellsToggle.length > 0){
            while(removeCellsToggle.length > 0){
                removeCellsToggle[0].innerHTML = ``;
            };
        }

        const removeCellsSpace = document.getElementsByClassName(`resources-grid-focused-cell`);

        if(removeCellsSpace && removeCellsSpace.length > 0){
            while(removeCellsSpace.length > 0){
                removeCellsSpace[0].classList.remove("resources-grid-focused-cell");
            };
        }

        const removeCells = document.getElementsByClassName(`invalid-simulate`);

        if(removeCells && removeCells.length > 0){
            while(removeCells.length > 0){
                removeCells[0].classList.remove("invalid-simulate");
            };
        }
    }

    initialCalc = (data, startDate, endDate) => {
        const { blockRooms, spacesAvail, roomsAvail, blockSpaces, hotelIds, hideStageType, hideOption, hideNoInv, statusAffectingInv, hotelsCityEvents, hotelsCityEventsDetails } = this.state;
        const { spaceList, intl, colorsByStatus, simulationMode } = this.props;

        this.clearSimulateMode();

        const columns = [
            {
                key: 'name',
                isFixed: true,
                name: intl.formatMessage({ id: "SalesProcess.Name" }),
                filterable: true,
                width: 280
            }
        ];

        const numberOfDays = moment(endDate).diff(moment(startDate), 'day') + 1;

        for (let d = 1; d <= numberOfDays; d++) {
            const date = startDate.clone().add((d - 1), 'day');
            
            const isWeekend = (date.day() === 0) || (date.day()  === 6);

            columns.push(
                {
                    key: moment(date).format('YYYY-MM-DD'),
                    name: `${moment(date).format('ddd')} ${moment(date).format('DD-MM')}`,
                    width: 100,
                    isWeekend,
                    filterable: true,
                    isToday: moment(date).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD'),
                    isHolidayOrNote: hotelsCityEvents?.some(x => (x.type == "Holiday" || x.type == "Note")
                        && moment(date).format('YYYY-MM-DD') == moment(x.day).format('YYYY-MM-DD')),
                    isSmallEvent: hotelsCityEvents?.some(x => x.type == "Event" && x.pax < 2000
                        && moment(date).format('YYYY-MM-DD') == moment(x.day).format('YYYY-MM-DD')),
                    isEvent: hotelsCityEvents?.some(x => x.type == "Event" && x.pax >= 2000 && x.pax < 15000
                        && moment(date).format('YYYY-MM-DD') == moment(x.day).format('YYYY-MM-DD')),
                    isBigEvent: hotelsCityEvents?.some(x => x.type == "Event" && x.pax >= 15000
                        && moment(date).format('YYYY-MM-DD') == moment(x.day).format('YYYY-MM-DD')),
                    eventDetails: hotelsCityEventsDetails?.filter(x => moment(date).format('YYYY-MM-DD') >= moment(x.startDate).format('YYYY-MM-DD') && moment(date).format('YYYY-MM-DD') <= moment(x.endDate).format('YYYY-MM-DD'))
                }
            );
        }

        const days = columns.slice(1);

        const calcRooms = (stage, hotelId, hideStageType, hideOption, hideNoInv, statusAffectingInv) => {
            return this.sortByStatus(stage.salesDeals)?.map((deal, dealKey) => {
                const roomsPerDay = {};
                const daysWithOptions = {};
                const daysNoAffecting = {};
                let dealHasEvents = deal.status !== "Cancelled" && deal.proposals.some(a => {
                    if(a.type !== "Event" || a.proposalJSON === null)
                        return false;

                    const json = JSON.parse(a.proposalJSON);
                    const events = json.data?.[0]?.DetailsEvents;
                    
                    if(events?.length === 0)
                        return false;

                    return (
                        events.some(e => e.Spaces.some(({FromDate, ToDate}) => moment(FromDate).isSameOrBefore(endDate) && moment(ToDate).isSameOrAfter(startDate)))
                    );
                });
                
                deal.proposals.filter(({isOption}) => !hideOption || !isOption).forEach(p => {
                    const json = JSON.parse(p.proposalJSON);
    
                    if(json?.BlockData?.details && (!hideNoInv || (json?.BlockData?.headerData && statusAffectingInv.includes(p.pmsStatus)))){
                        const notAffecting = !statusAffectingInv.includes(p.pmsStatus);
                        json.BlockData.details.forEach(d => {
                            const date = moment(d.Date).format('YYYY-MM-DD');
    
                            if(!roomsPerDay[date])
                                roomsPerDay[date] = 0;
                            
                            roomsPerDay[date] += d.CurrentRooms;

                            if(p.isOption)
                                daysWithOptions[date] = true;

                            if(notAffecting)
                                daysNoAffecting[date] = true;
                        });
                    }
                });

                const originalRoomsPerDay = JSON.parse(JSON.stringify(roomsPerDay));

                Object.keys(roomsPerDay).forEach((key) => {
                    const current = roomsPerDay[key];
                    const dayHasOption = daysWithOptions[key];
                    const dayNoInv = daysNoAffecting[key];
                    
                    roomsPerDay[key] = (
                        <div
                            className='w-100 h-100 d-flex align-items-center justify-content-center'
                            style={{
                                background: dayHasOption && dayNoInv ?
                                    'repeating-linear-gradient(45deg, #614a0457, #614a0457 5px, #bdd4f3 5px, #bdd4f3 12px)'
                                : dayHasOption ?
                                    'repeating-linear-gradient(45deg, #614a0457, #614a0457 5px, #ffffff 5px, #ffffff 12px)'
                                : dayNoInv ?
                                    'repeating-linear-gradient(45deg, #bdd4f3, #bdd4f3 5px, #ffffff 5px, #ffffff 12px)'
                                :''
                                }}
                            >
                            {current}
                        </div>
                    );
                });

                const preWrapContent = simulationMode ? 
                    deal.status === "WaitingHotel" || deal.status === "WaitingClient" ? (
                            <div className='d-flex align-items-center justify-content-center'>
                                <div style={{ border: '1px solid #7d7d7d', borderRadius: '1px', width: '15px', height: '15px' }} className='d-flex align-items-center justify-content-center cursor-pointer' onClick={_ => this.toggleWonDeal(deal.dealId)}>
                                    <div className='toggleSimulationModeDeal' id={`toggleSimulationModeDeal-${deal.dealId}`}></div>
                                </div>
                            </div>
                    ) : ''
                : dealHasEvents ? (
                    <div className='w-100 h-100 d-flex align-items-center justify-content-center cursor-pointer' onClick={_ => this.toggleFocusedDeals(deal.dealId, true)}>
                        <i className="far fa-eye"/>
                    </div>
                ):'';

                const currency = global.hotelList.find(x => x.value == hotelId)?.currency;

                const mainBlockProposal =
                    deal.proposals.find(({pmsStatus, type}) => type === "Block" && statusAffectingInv.includes(pmsStatus))
                ??
                    deal.proposals.find(({type}) => type === "Block");
                    
                const mainEventProposal =
                    deal.proposals.find(({pmsStatus, type}) => type === "Event" && statusAffectingInv.includes(pmsStatus))
                ??
                    deal.proposals.find(({type}) => type === "Event");

                return (
                    {
                        data: {
                            name: (
                                <>
                                    <div style={{ borderTop: hideStageType && !dealKey && stage.stageColor ? `3px solid ${stage.stageColor}` :'' }} className="h-100 cursor-pointer d-flex align-items-center justify-content-between w-100 px-2" id={`deal-detail-cell-${deal.dealId}-${hotelId}`}>
                                        <div className="text-truncate">
                                            <div className="text-truncate">
                                                <a className='hover-underline text-truncate' href={`/SalesProcess?dealId=${deal.dealId}#SalesDealDetail`} target="_blank">
                                                    {deal.dealName}
                                                </a>
                                            </div>
                                            {deal.salesDealUsers && deal.salesDealUsers[0] ?
                                                <div style={{ fontSize: '0.8em', marginTop: '-2px' }}>{deal.salesDealUsers[0].name}</div>
                                            :''}
                                        </div>
                                        <div className="ml-2 d-flex align-items-center justify-content-between">
                                            <div className='mr-3 text-muted' style={{ fontSize: '0.8em' }}>
                                                {mainBlockProposal?.pmsStatus ?? mainEventProposal?.pmsStatus}
                                            </div>
                                            <div>
                                                {(deal.status === "WaitingClient" || deal.status === "WaitingHotel") ? 
                                                    <i style={{ color: '#0665ff' }} className="fas fa-people-arrows"/>
                                                : deal.status === "Finished" ?
                                                    <i style={{ color: '#36ad51' }} className="fas fa-trophy"/>
                                                : deal.status === "Effective" ?
                                                    <i style={{ color: "#6EDE8A" }} className="far fa-check-circle mr-1"/>
                                                :
                                                    <i style={{ color: '#f38375' }} className="far fa-thumbs-down"/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <UncontrolledPopover target={`deal-detail-cell-${deal.dealId}-${hotelId}`} placement={"right"} placementPrefix='salesProcessGridCellPopover coolPopover bs-popover'>
                                        <div style={{ width: '20rem' }} className='p-2'>
                                            <div>
                                                <div className='text-truncate' style={{ fontSize: '1.1em' }}>
                                                    <div className='text-truncate'>
                                                        {deal.dealName}
                                                    </div>
                                                </div>
                                                <div className='text-truncate' style={{ fontSize: '0.85em' }}>
                                                    <Link to={{ pathname: "/ProfileDetails/" + deal.customer?.id }} target="_blank" rel="noopener noreferrer">
                                                        <div className='text-truncate'>
                                                            {deal.customer?.name}
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <Row className='mt-1'>
                                                <Col className="col-6">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.Resposible"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {deal.salesDealUsers[0] ?
                                                            deal.salesDealUsers[0].name??'-'
                                                        :'-'}
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.MainBlock"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {mainBlockProposal?.pmsStatus ?? mainEventProposal?.pmsStatus}
                                                    </div>
                                                </Col>
                                                <Col className="col-6">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.TotalPax"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {deal.pax}
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.NumberOfRooms"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {deal.numberOfRooms}
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.CheckIn"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {CustomFormatDate(deal.checkIn, null, null, intl)}
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.CheckOut"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {CustomFormatDate(deal.checkOut, null, null, intl)}
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.NumberOfNights"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {moment(deal.checkOut).diff(moment(deal.checkIn), 'd')}
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.TotalAvgRate"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {!deal.totalRevenue || !deal.numberOfRooms || isNaN((deal.totalRevenue / deal.numberOfRooms)) ?
                                                            <FormatAmountNumber
                                                                value={0}
                                                                currency={currency}
                                                                hideDecimals={true}
                                                            />
                                                        :
                                                            <FormatAmountNumber
                                                                value={(deal.totalRevenue / deal.numberOfRooms)}
                                                                currency={currency}
                                                                hideDecimals={true}
                                                            />
                                                        }
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.AvgRate"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {!deal.revenueRooms || !deal.numberOfRooms || isNaN((deal.revenueRooms / deal.numberOfRooms)) ?
                                                            <FormatAmountNumber
                                                                value={0}
                                                                currency={currency}
                                                                hideDecimals={true}
                                                            />
                                                        :
                                                            <FormatAmountNumber
                                                                value={(deal.revenueRooms / deal.numberOfRooms)}
                                                                currency={currency}
                                                                hideDecimals={true}
                                                            />
                                                        }
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.RevenueRooms"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        <FormatAmountNumber
                                                            value={deal.revenueRooms}
                                                            currency={currency}
                                                            hideDecimals={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.F&B"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        <FormatAmountNumber
                                                            value={deal.revenueFB}
                                                            currency={currency}
                                                            hideDecimals={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.Banquets"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        <FormatAmountNumber
                                                            value={deal.revenueBanquets}
                                                            currency={currency}
                                                            hideDecimals={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.SpaceRental"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        <FormatAmountNumber
                                                            value={deal.revenueSpaces}
                                                            currency={currency}
                                                            hideDecimals={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.Misc"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        <FormatAmountNumber
                                                            value={deal.revenueEquipment}
                                                            currency={currency}
                                                            hideDecimals={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.TotalRevenue"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        <FormatAmountNumber
                                                            value={deal.totalRevenue}
                                                            currency={currency}
                                                            hideDecimals={true}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </UncontrolledPopover>
                                </>
                            ),
                            dealId: deal.dealId,
                            ...roomsPerDay
                        },
                        dailyData: originalRoomsPerDay, // just for calcs 
                        cellsNoPadding: true,
                        preWrapContent
                    }
                );
            })??[];
        }

        const rows = hotelIds?.map((hotelId) => {
            const h = data.find((h) => h.hotelId === hotelId);

            let stages;

            if(!hideStageType){
                stages = h?.stageTypes?.map((stage) => {
                    const children = calcRooms(stage, hotelId, false, hideOption, hideNoInv, statusAffectingInv);
        
                    const dailyInv = Object.assign({}, ...days.map(({key}) => ({ [key]: ((children?.reduce((a,b) => a + (b.dailyData[key]??0), 0)??0) > 0 ? (children?.reduce((a,b) => a + (b.dailyData[key]??0), 0)??0) : '-') })));
    
                    return ({
                        data: {
                            name: (
                                <div className='w-100 d-flex align-items-center justify-content-between'>
                                    <div className="text-muted">{stage.stageName}</div>
                                    {stage.stageColor ?
                                        <div style={{ background: stage.stageColor, width: '10%', height: '10px' }}/>
                                    :''}
                                </div>
                            ),
                            ...dailyInv
                        },
                        isSmall: true,
                        isOpened: true,
                        children
                    })
                });
            }
            else{
                stages = h?.stageTypes.map((stage) => calcRooms(stage, hotelId, true, hideOption, hideNoInv, statusAffectingInv))?.flat();
            }

            const dailyInv = Object.assign({}, ...days.map(({key}) => {
                const dailyInv = roomsAvail && roomsAvail[hotelId]?.find(({hotelDate}) => moment(hotelDate).isSame(key));
                const roomsInv = dailyInv?.inventoryRooms??0;
                const freeRooms = dailyInv?.freeRooms??0;
                const total = roomsInv - freeRooms;
                const percentage = Math.round(((total / roomsInv) * 100) * 100) / 100;

                const color = (0 <= percentage && percentage < 33) ?
                    { color: 'success', hex: '#5cb85c' }
                : (33 <= percentage && percentage < 66) ?
                    { color: 'warning', hex: '#f0ad4e' }
                : { color: 'danger', hex: '#d9534f' };


                return ({
                    [key]: (
                        <CustomBlockUI blocking={blockRooms[hotelId]}>
                            <div className={`availabilityPercentage-${hotelId} w-100`} style={{ visibility: !dailyInv ? 'hidden' : '' }} id={`availabilityPercentage-${key}-${hotelId}`}>
                                <div className='px-3'>
                                    <Progress
                                        color={color.color}
                                        value={percentage + 1}
                                        style={{
                                            maxHeight: '5px',
                                        }}
                                    />
                                </div>
                                <div className='cursor-default text-center mt-2'>
                                    {freeRooms}
                                </div>
                            </div>
                            <CoolTooltip trigger="legacy" target={`availabilityPercentage-${key}-${hotelId}`} placement="right">
                                {roomsInv > 0 ?
                                    <>
                                        <i className={`far fa-square mr-2`} style={{ background: color.hex }} /> {percentage}%
                                    </>
                                :''}
                                <div className="d-flex align-items-center"> <i className="fas fa-hashtag fa-xs mr-2 pr-1 ml-1" /> {total}/{roomsInv} </div>
                            </CoolTooltip>
                        </CustomBlockUI>
                    )
                })
            }));

            const hotelSpaceAvail = spacesAvail && spacesAvail[hotelId];

            const newDeals = h?.stageTypes?.flatMap(({salesDeals}) => salesDeals);

            const spaceRows = spaceList
                ?.filter((s) => s.hotelId === hotelId)
                .map(({description, id}) => {
                    const events = hotelSpaceAvail?.filter(({spaceName}) => spaceName === description);

                    const dailyOccBySlot = [];

                    if(events){
                        events.forEach(({eventId, spaceFromDate, spaceToDate, reservationStatusCode}) => {
                            const numberOfDays = moment(spaceToDate).diff(moment(spaceFromDate), 'day') + 1;
                            
                            for (let d = 1; d <= numberOfDays; d++) {
                                const date = moment(spaceFromDate).clone().add((d - 1), 'day').format('YYYY-MM-DD');

                                let current = dailyOccBySlot.find(({day}) => day === date);

                                if(!current){
                                    dailyOccBySlot.push({
                                        day: date,
                                        slots: [
                                            {
                                                value: 0,
                                                events: []
                                            },
                                            {
                                                value: 0,
                                                events: []
                                            },
                                            {
                                                value: 0,
                                                events: []
                                            },
                                            {
                                                value: 0,
                                                events: []
                                            }
                                        ]
                                    });

                                    current = dailyOccBySlot.find(({day}) => day === date);
                                }

                                current.slots = current.slots.map((slot, key) => {
                                    const newValue = (key) => {
                                        const from = moment(spaceFromDate);
                                        const to = moment(spaceToDate);
                                        const isFirstDay = from.clone().startOf('d').isSame(date);
                                        const isLastDay = to.clone().startOf('d').isSame(date);
                                        const startHour = parseInt(from.format('HH'));
                                        let endHour = parseInt(to.clone().format('HH'));
                                        
                                        if(endHour > 0) {
                                            endHour = parseInt(to.clone().add('minute', -1).format('HH'));
                                        }

                                        const valueToCompareStart = 6 * (key + 1);
                                        const valueToCompareEnd = 6 * (key);

                                        if(isFirstDay && isLastDay){
                                            return startHour < valueToCompareStart && endHour >= valueToCompareEnd;
                                        }

                                        if(isFirstDay) {
                                            return startHour < valueToCompareStart;
                                        }

                                        if(isLastDay) {
                                            return endHour > valueToCompareEnd;
                                        }

                                        return !isFirstDay && !isLastDay
                                    }

                                    const proposalForEvent = newDeals
                                        ?.filter(d => d.proposals)
                                        ?.flatMap(d => d.proposals)
                                        ?.filter((proposal) =>
                                            moment(proposal.startDate).startOf('d').isSameOrBefore(date) &&
                                            moment(proposal.endDate).startOf('d').isSameOrAfter(date)
                                        )
                                        .find(({externalId}) => parseInt(externalId) === eventId);

                                    const calcValue = (newValue(key) ? 1 : 0);

                                    const result = {
                                        value: slot.value + calcValue,
                                        events: slot.events
                                    }

                                    if(calcValue > 0) {
                                        if(proposalForEvent){
                                            result.events.push({
                                                eventId,
                                                spaceFromDate,
                                                spaceToDate,
                                                isDeal: true,
                                                name: proposalForEvent.dealName,
                                                pmsStatus: proposalForEvent.pmsStatus,
                                                dealId: proposalForEvent.dealId,
                                                start: moment(spaceFromDate).format("HH:mm"),
                                                end: moment(spaceToDate).format("HH:mm"),
                                                isDEF: statusAffectingInv.includes(proposalForEvent.pmsStatus)
                                            });
                                        }
                                        else {
                                            result.events.push({
                                                eventId,
                                                spaceFromDate,
                                                spaceToDate,
                                                pmsStatus: reservationStatusCode,
                                                start: moment(spaceFromDate).format("HH:mm"),
                                                end: moment(spaceToDate).format("HH:mm"),
                                                isDEF: statusAffectingInv.includes(reservationStatusCode)
                                            });
                                        }
                                    }

                                    return result;
                                });
                            }
                        });
                    }

                    return ({
                        cellsNoPadding: false,
                        data: {
                            name: <div className=''>{description}</div>,
                            ...Object.assign({},
                                ...days.map(({key}) => {
                                    const dailyOcc = dailyOccBySlot.filter(({day}) => day === key);
                                    
                                    const dealList = dailyOcc && dailyOcc
                                        .flatMap(({slots}) => slots)
                                        .flatMap(({events}) => events)
                                        .filter((a, b, c) => c.findIndex(({dealId}) => dealId === a.dealId) === b);

                                    const customClasses = dealList?.map(({dealId}) => `space-cell-data-${dealId}`)?.toString()?.replace(',',' ');
                                    
                                    const slotOcc = dailyOcc.reduce((a, b) => {
                                        const arr = [...a];

                                        arr[0].value += (b.slots[0]?.value??0);
                                        arr[1].value += (b.slots[1]?.value??0);
                                        arr[2].value += (b.slots[2]?.value??0);
                                        arr[3].value += (b.slots[3]?.value??0);

                                        if(b.slots[0]?.events && b.slots[0]?.events.length > 0){
                                            arr[0].events = [...arr[0].events, ...b.slots[0]?.events];
                                        }
                                        if(b.slots[1]?.events && b.slots[1]?.events.length > 0){
                                            arr[1].events = [...arr[1].events, ...b.slots[1]?.events];
                                        }
                                        if(b.slots[2]?.events && b.slots[2]?.events.length > 0){
                                            arr[2].events = [...arr[2].events, ...b.slots[2]?.events];
                                        }
                                        if(b.slots[3]?.events && b.slots[3]?.events.length > 0){
                                            arr[3].events = [...arr[3].events, ...b.slots[3]?.events];
                                        }

                                        return arr;
                                    }, [{value: 0, events: []},{value: 0, events: []},{value: 0, events: []},{value: 0, events: []}]);

                                    return ({
                                        [key]: (
                                            <SalesProcessGridSpaceCell
                                                slotOcc={slotOcc}
                                                blockSpaces={blockSpaces}
                                                hotelId={hotelId}
                                                customClasses={customClasses}
                                                dealList={dealList}
                                                d={key}
                                                r={{data: {id}}}
                                                description={description}
                                                toggleFocusedDeals={this.toggleFocusedDeals}
                                                colorsByStatus={colorsByStatus}
                                            />
                                        )
                                    })
                                })
                            )
                        }
                    });
                });

            return (
                {
                    data: {
                        name: global.hotelList.find(({value}) => value === hotelId)?.label??hotelId,
                        ...dailyInv
                    },
                    isOpened: true,
                    emptyNull: true,
                    children: [
                        {
                            separator: true,
                            isOpened: true,
                            title: intl.formatMessage({ id: "SalesProcess.Deals" }),
                            children: stages,
                        },
                        {
                            separator: true,
                            isOpened: true,
                            title: intl.formatMessage({ id: "SalesProcess.Spaces" }),
                            children: spaceRows
                        }
                    ]
                    .filter(({children}) => children && children.length > 0)
                }
            );
        });

        this.setState({ columns, rows });
    }

    toggleWonDeal = (id) => {
        let { budgetRaw, hotelList, roomsAvail, statusAffectingInv } = this.state;
        const hotelId = hotelList[0]?.hotelId;
        
        const dealList = hotelList.reduce((a, b) => a.concat(b.stageTypes.reduce((c, d) => c.concat(d.salesDeals), [])), []);
        const deal = dealList.find(({dealId}) => dealId === id);

        deal.simulatedWon = !deal.simulatedWon;

        const toggleDomElement = document.getElementById(`toggleSimulationModeDeal-${id}`);

        if(!toggleDomElement)
            return;

        if(deal.simulatedWon){
            toggleDomElement.innerHTML = `<i style="font-size: 0.75em;" class="fas fa-check"></i>`;
        }
        else{
            toggleDomElement.innerHTML = ``;
            deal.invalidSimulation = false;
        }


        const simulatedDeals = dealList.filter(({simulatedWon}) => simulatedWon !== undefined);
        const allProposals = simulatedDeals.flatMap(({proposals, simulatedWon}) => proposals.map(p => ({...p, simulatedWon})));

        const hotelAvailDomElements = document.getElementsByClassName(`availabilityPercentage-${hotelId}`);

        const dailyCalculatedInv = allProposals
            .filter(a => a.simulatedWon && !statusAffectingInv.includes(a.pmsStatus) && a.type === "Block")
            .reduce((a, b) => {
                if(!b.proposalJSON)
                    return a;

                const json = JSON.parse(b.proposalJSON);

                const dailyInvData = json.BlockData.details.reduce((c, d) => {
                    let current = c.find(({date}) => moment(date).isSame(d.Date));

                    if(!current){
                        c.push({
                            date: d.Date,
                            occupiedRooms: d.CurrentRooms,
                            dealIds: [b.dealId]
                        });   
                    }
                    else{
                        current.occupiedRooms += d.CurrentRooms;
                        current.dealIds = [...current.dealIds, b.dealId];
                    }

                    return c;
                }, []);

                dailyInvData.forEach(({date, occupiedRooms, dealIds}) => {
                    let current = a.find((b) => moment(date).isSame(b.date));

                    if(!current){
                        a.push({
                            date,
                            occupiedRooms,
                            dealIds
                        });
                    }
                    else{
                        current.occupiedRooms += occupiedRooms;
                        current.dealIds = [...current.dealIds, ...dealIds];
                    }
                });

                return a;
            }, []);

        hotelAvailDomElements.forEach((element) => {
            const date = moment(element.id.slice(23, 33));

            const dayCalculatedOccupation = dailyCalculatedInv.find((d) => date.isSame(d.date));
            const dailyInv = roomsAvail && roomsAvail[hotelId]?.find(({hotelDate}) => moment(hotelDate).isSame(date));

            const roomsInv = dailyInv?.inventoryRooms??0;
            const freeRooms = (dailyInv?.freeRooms??0);
            const calc = freeRooms - (dayCalculatedOccupation?.occupiedRooms??0);

            if(dayCalculatedOccupation?.dealIds?.length){
                const currentDeals = simulatedDeals.filter(({dealId}) => dayCalculatedOccupation.dealIds.includes(dealId));
    
                currentDeals.forEach(deal => {
                    deal.invalidSimulation = calc < 0;
                });
            }

            const total = roomsInv - calc;
            const percentage = Math.round(((total / roomsInv) * 100) * 100) / 100;

            const progressBar = document.querySelector(`#${element.id} .progress .progress-bar`);

            if(progressBar){
                progressBar.style.width = `${percentage}%`;

                progressBar.classList.remove('bg-success');
                progressBar.classList.remove('bg-warning');
                progressBar.classList.remove('bg-danger');

                const newColor = (0 <= percentage && percentage < 33) ?
                    { color: 'success', hex: '#5cb85c' }
                : (33 <= percentage && percentage < 66) ?
                    { color: 'warning', hex: '#f0ad4e' }
                : { color: 'danger', hex: '#d9534f' };

                progressBar.classList.add(`bg-${newColor.color}`);
            }

            element.lastChild.innerHTML = calc;
        });

        const removeCells = document.getElementsByClassName(`resources-grid-focused-cell`);

        if(removeCells && removeCells.length > 0){
            while(removeCells.length > 0){
                removeCells[0].classList.remove("resources-grid-focused-cell");
            };
        }

        simulatedDeals.forEach(({dealId, invalidSimulation, simulatedWon}) => {
            const dealCells = document.getElementsByClassName(`CoolDataGrid-cell-deal-${dealId}`);

            dealCells.forEach(el => {
                let cell = el;

                while (cell != null){
                    if(cell.classList){
                        if(invalidSimulation){
                            cell.classList.add("invalid-simulate");
                        }
                        else{
                            cell.classList.remove("invalid-simulate");
                        }
                    }

                    cell = cell.firstChild;
                }
            });
            
            if(simulatedWon){
                const spaceCells = document.getElementsByClassName(`space-cell-data-${dealId}`);
    
                spaceCells.forEach(cell => {
                    cell.classList.add("resources-grid-focused-cell");
                });
            }
        });

        this.setState({ hotelList, block: true }, () => this.calcBudgetandForecast(budgetRaw));
    }

    initialLoad = (hotelList, startDate, endDate, hotelIds) => {
        const { blockRooms, blockSpaces, spacesAvail, roomsAvail } = this.state;
        
        const getRoomsData = (hotelId, key, array) => {
            this.getRoomsAvailability(hotelId, (hasError) => {
                if(hasError){
                    this.setState({
                        blockRooms: {
                            ...blockRooms,
                            [hotelId]: false
                        }
                    });
                }
                if((key + 1) === array.length){
                    this.setState({
                        blockRooms: {
                            ...blockRooms,
                            [hotelId]: false
                        }
                    }, () => this.initialCalc(hotelList, startDate, endDate));
                }
                else{
                    const nextKey = key + 1;
                    getRoomsData(array[nextKey], nextKey, array);
                }
            });
        }
        
        const getSpacesData = (hotelId, key, array) => {
            this.getSpacesAvailability(hotelId, (hasError) => {
                if(hasError){
                    this.setState({
                        blockSpaces: {
                            ...blockSpaces,
                            [hotelId]: false
                        }
                    });
                }
                if((key + 1) === array.length){
                    this.setState({
                        blockSpaces: {
                            ...blockSpaces,
                            [hotelId]: false
                        }
                    }, () => this.initialCalc(hotelList, startDate, endDate));
                }
                else{
                    const nextKey = key + 1;
                    getSpacesData(array[nextKey], nextKey, array);
                }
            });
        }

        if(hotelIds?.length){
            const hotelKey = hotelIds && hotelIds[0];
    
            const blockSpaces = {
                ...this.state.blockSpaces
            };
            const blockRooms = {
                ...this.state.blockRooms
            };
    
            if(hotelKey){
                blockRooms[hotelKey] = !(roomsAvail && roomsAvail[hotelKey] && roomsAvail[hotelKey].find(({hotelDate}) => moment(hotelDate).isSame(startDate) && roomsAvail[hotelKey].findLast(({hotelDate}) => moment(hotelDate).isSame(endDate))));
                blockSpaces[hotelKey] = !(spacesAvail && spacesAvail[hotelKey] && spacesAvail[hotelKey].find(({hotelDate}) => moment(hotelDate).isSame(startDate) && spacesAvail[hotelKey].findLast(({hotelDate}) => moment(hotelDate).isSame(endDate))));

                this.setState({ blockRooms, blockSpaces, hotelIds }, () => {
                    this.initialCalc(hotelList, startDate, endDate);
                    getRoomsData(hotelIds[0], 0, hotelIds);
                    getSpacesData(hotelIds[0], 0, hotelIds);
                });
            }
            else{
                this.initialCalc(hotelList, startDate, endDate);
            }
        }
    }

    getRoomsAvailability = (hotelId, cb) => {
        const { startDate, endDate, roomsAvail } = this.state;
        
        let qs = `?hotelId=${hotelId}&fromDate=${startDate.format('YYYY-MM-DD')}&toDate=${endDate.clone().add(1, 'day').format('YYYY-MM-DD')}`;
        let hasError = false;
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                hasError = true
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage }, _ => cb(hasError));
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    hasError = true;
                }

                this.setState({
                    roomsAvail: {
                        ...roomsAvail,
                        [hotelId]: data.response??[]
                    }
                }, _ => cb(hasError));

                return;
            }
            else this.setState({ error: errorMessage }, _ => cb(true));
        }, `/api/gms/SalesProcess/v1/roomsforecast${qs}`);
    }

    getSpacesAvailability = (hotelId, cb) => {
        const { startDate, endDate, spacesAvail } = this.state;

        let qs = `?hotelId=${hotelId}&fromDate=${startDate.format('YYYY-MM-DD')}&toDate=${endDate.clone().add(1, 'day').format('YYYY-MM-DD')}`;
        let hasError = false;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage }, _ => cb(hasError));
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    spacesAvail: {
                        ...spacesAvail,
                        [hotelId]: data.response ? data.response[0] ? data.response[0].reservationList ?? [] : [] : []
                    }
                }, _ => cb(hasError));

                return;
            }
            else this.setState({ error: errorMessage }, () => cb(true));
        }, `/api/gms/SalesProcess/v1/spacesavailability${qs}`);
    }

    render() {
        const { columns, rows, error, block, simulationCards } = this.state;
        const { simulationMode, propsBlock } = this.props;

        return (
            columns?.length > 1 ?
                <BlockUi tag="div" blocking={block}>
                    <div>
                        {simulationMode && !propsBlock ?
                            <div className='d-flex align-items-center justify-content-between py-3 shadow mt-3 mb-4' style={{ fontSize: '0.9em' }}>
                                <div className='w-100'>
                                    <div className='px-3'>
                                        <div style={{ borderBottom: '1px solid lightgrey' }} className='mb-2 pb-2'>
                                            <b><FormattedMessage id="SalesProcess.Type"/></b>
                                        </div>
                                    </div>
                                    <div className='px-3'>
                                        <div className='my-1'>
                                            <FormattedMessage id="SalesProcess.Simulated"/>
                                        </div>
                                        <div className='my-1'>
                                            <FormattedMessage id="SalesProcess.Forecast"/>
                                        </div>
                                        <div className='my-1'>
                                            <FormattedMessage id="SalesProcess.Budget"/>
                                        </div>
                                        <div style={{ borderTop: "1px solid lightgrey" }} className={'mt-2 pt-2'}>
                                            <b><FormattedMessage id="SalesProcess.Diff"/></b>
                                        </div>
                                    </div>
                                </div>
                                {simulationCards.map((card, key) => {
                                    const value = card.simulated + card.forecast - card.budget;
                                    return (
                                        <div key={key} className='w-100' style={{ borderLeft: "1px solid lightgrey" }}>
                                            <div className='px-3'>
                                                <div style={{ borderBottom: '1px solid lightgrey' }} className='mb-2 pb-2'>
                                                    <b><FormattedMessage id={card.label}/></b>
                                                </div>
                                            </div>
                                            <div className='px-3'>
                                                <div className='text-truncate my-1'>
                                                    {card.type === "value" ?
                                                        <FormatAmountNumber
                                                            value={card.simulated}
                                                        />
                                                    :
                                                        <FormattedNumber
                                                            value={card.simulated}
                                                        />
                                                    }
                                                </div>
                                                <div className='text-truncate my-1'>
                                                    {card.type === "value" ?
                                                        <FormatAmountNumber
                                                            value={card.forecast}
                                                        />
                                                    :
                                                        <FormattedNumber
                                                            value={card.forecast}
                                                        />
                                                    }
                                                </div>
                                                <div className='my-1 text-truncate'>
                                                    {card.type === "value" ?
                                                        <FormatAmountNumber
                                                            value={card.budget}
                                                        />
                                                    :
                                                        <FormattedNumber
                                                            value={card.budget}
                                                        />
                                                    }
                                                </div>
                                                <div style={{ borderTop: "1px solid lightgrey" }} className={value < 0 ? 'mt-2 pt-2 text-danger text-truncate' : 'mt-2 pt-2 text-success text-truncate'}>
                                                    <b>
                                                        {card.type === "value" ?
                                                            <FormatAmountNumber
                                                                value={value}
                                                            />
                                                        :
                                                            <FormattedNumber
                                                                value={value}
                                                            />
                                                        }
                                                    </b>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                )}
                            </div>
                        :
                            <div className='d-flex align-items-start justify-content-end my-2'>
                                <div className="mr-2">
                                    <ExportToPDFButton name="MainCoolDataGrid-GridRooms" fromPage="ResourcesGrid" />
                                </div>
                                <div>
                                    <Button className="btn btn-host btn-sm" onClick={this.downloadExcel}>
                                        <i className="fas fa-file-download" />
                                    </Button>
                                </div>
                            </div>
                        }
                        <ErrorAlert error={error} />
                        <CoolDataGrid
                            rows={rows}
                            cols={columns}
                            tableName="GridRooms"
                        />
                    </div>
                </BlockUi>
            :''
        );
    }
}

export default injectIntl(SalesProcessDealsRooms);