import React, { Component } from 'react'
import { deleteAPI, getAPI, postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { Badge, Button, Card, CardBody, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../../Base/ErrorAlert';
import moment from 'moment-timezone';
import { KebabMenu, CommonHelper } from '../../Base/CommonUIComponents';
import ConfirmActionModal from '../../Base/ConfirmActionModal';

export default class CampaignTargets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deleteModal: false,
            campaignTargets: [],
            block: true,
            selectedTarget: null
        };
    }

    componentDidMount() {
        this.getCampaignTargets();
    }

    getCampaignTargets = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ campaignTargets: data.response ? data.response : [], block: false });
                return;
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/CampaignTargets`);
    }

    toggleDeleteModal = (id) => {
        const { deleteModal } = this.state;
        this.setState({ deleteModal: !deleteModal, selectedTarget: id });
    }

    deleteTarget = (id) => {
        this.setState({ block: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        this.setState({ deleteModal: false, block: false }, () =>
                            handleNotification(data)
                        );
                        return;
                    }

                    this.setState({ deleteModal: false, block: false }, () => {
                        handleNotification(data, <FormattedMessage id="CampaignTargets.Deleted" />, <FormattedMessage id="generic.success" />);
                        this.getCampaignTargets();
                    });
                    return;
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Marketing/v1/CampaignTargets/${id}`)
        );
    }
    
    toogleActive = (id) => {
        const { campaignTargets } = this.state;

        const campaignTarget = campaignTargets.find(x => x.id === id);

        this.setState({ block: true }, () => {
            const payload = {...campaignTarget};

            payload.params = JSON.stringify(payload.params);
            payload.active = !payload.active;

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        this.setState({ block: false }, () => handleNotification(data));
                    }
                    if(data.response && data.response[0]){
                        campaignTarget.active = !campaignTarget.active;
                        this.setState({ block: false, campaignTargets }, () => 
                            handleNotification(data, <FormattedMessage id="CampaignTargets.Saved" />, <FormattedMessage id="generic.success" />)
                        );
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Marketing/v1/CampaignTargets`, payload);
        });
    }

    goToDetails = (id) => {
        this.props.history.push(`/Targets/${id ?? ''}`);
    }

    render() {
        const { block, error, campaignTargets, deleteModal } = this.state;
        return (
            <div className="border-0 h-100 py-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <Row>
                        <Col>
                            <h5>
                                <i className="fas fa-hat-wizard mr-1" />
                                <FormattedMessage id="NavMenu.CampaignTargets"/>
                            </h5>
                        </Col>
                        <Col className='col-2 text-right'>
                            <Button className="btn-sm btn-host mr-2" onClick={_ => this.goToDetails()}>
                                <i className="fas fa-plus"/>
                            </Button>
                            <CommonHelper help={<FormattedMessage id="MarketingCampaignTargets.Help" />} id={'MarketingCampaignTargets'} />
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        {campaignTargets?.map((t, k) =>
                            <Col className='col-4 mb-2' key={k}>
                                <Card>
                                    <CardBody className='p-3'>
                                        <Row>
                                            <Col onClick={_ => this.goToDetails(t.id)} className='cursor-pointer'>
                                                <Row>
                                                    <Col className='col-10'>
                                                        <div>
                                                            <b>{t.name}</b>
                                                        </div>
                                                        <div className='text-muted' style={{ fontSize: '0.8em' }}>
                                                            {t.lastRun ?
                                                                moment(t.lastRun).format('DD/MM/YYYY HH:mm:ss')
                                                            :
                                                                <FormattedMessage id="CampaignTargets.NotRun"/>
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col className='col-2 text-right'>
                                                        <Badge className='ml-2' color={t.active ? 'primary' : 'secondary'}>
                                                            {t.active ?
                                                                <FormattedMessage id="CampaignTargets.Active"/>
                                                            :
                                                                <FormattedMessage id="CampaignTargets.Inactive"/>
                                                            }
                                                        </Badge>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className='col-1 d-flex align-items-center justify-content-center'>
                                                <KebabMenu
                                                    deleteFunction={_ => this.deleteTarget(t.id)}
                                                    extraFields={[{
                                                        icon: 'far fa-check-square mr-2',
                                                        text: !t.active ? 'SendGridAdmin.Enable' : 'SendGridAdmin.Disabled',
                                                        function: _ => this.toogleActive(t.id)
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                        {deleteModal ?
                            <ConfirmActionModal
                                modal={deleteModal}
                                toggleModal={e => this.toggleDeleteModal()}
                                actionFunction={this.deleteTarget}
                                text={<FormattedMessage id="CampaignTargets.ConfirmDeleteText" />}
                                block={block}
                            />
                        : ''}
                    </Row>
                </BlockUi>
            </div>
        )
    }
}
