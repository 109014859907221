import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { getAPI } from '../Base/API';
import { DefaultPage, StyledCard } from "../Base/CommonUIComponents";
import { handleNotification } from '../Base/Notification';
import { Button, Col, Row, Input, Form } from 'reactstrap';
import { getGoContactMessageStatus } from '../Ticket/TicketsFunctions';
import TicketDetails from '../Ticket/TicketDetails';


export class GoContactTicket extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            ticketid: null,
            ticket: null,
            goContactMsgStatus: getGoContactMessageStatus(this.props.intl)
        };
    }
    

    getTicket = (e) => {
        if (e) e.preventDefault();
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                if (data?.data.length>0) {
                    this.setState({ ticket: data.data[0], block: false, error: errorMessage });
                }

            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/GoContact/v1/Ticket/${this.state.ticketid}`);
    }

    render() {
        const { block, error, ticketid, ticket, goContactMsgStatus } = this.state;

        return (
            <StyledCard block={block} error={error} icon="fas fa-headset" title="GoContactTicket.Tickets">
                <Form onSubmit={this.getTicket}>
                    <Row className="mt-3 mb-4 align-items-center">
                        <Col className="col-2 pr-0">
                            <Input
                                type="text"
                                name="Ticket"
                                onChange={(e) => this.setState({ ticketid: e.target.value })}
                                value={ticketid || ''}
                                placeholder={this.props.intl.formatMessage({ id: "GoContactTicket.TicketId" })}
                                maxLength="20"
                            />
                        </Col>
                        <Col>

                        </Col>
                        <Col className="text-right" >
                            <Button className="mr-3 btn btn-sm btn-host" type="submit" >
                                <i className="fa fa-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>
                {ticket ? 
                    <div>
                        <TicketDetails
                            selectedTicket={ticket}
                            goContactMsgStatus={goContactMsgStatus}
                        />
                    </div>
                    :
                    <DefaultPage text="GoContactTicket.SearchTicket" icon="fas fa-headset" />
                }                
            </StyledCard>
        );
    }
}
export default injectIntl(GoContactTicket)