import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Card, Col, Row, Badge } from 'reactstrap';
import { handleNotification } from "../../Base/Notification";
import QrReader from 'react-qr-reader';
import { isValidVCardText, vCardToJson } from './VCardFunctions';
import { BlankCard, DefaultPage, StyledCard, StyledModal } from '../../Base/CommonUIComponents';
import moment from 'moment';


class VCardScanner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            vCardData: null,
            isValidQrCode: true,
            showQrCodeScanner: true,
        };
    }
    

    handleError = (err) => {
        handleNotification({ errors: [err] });

        this.setState({ qrCodeError: err.message });
    }

    handleScan = (data) => {
        if (data) {
            const isValidQrCode = isValidVCardText(data);

            if (isValidVCardText(data)) {
                const result = vCardToJson(data);

                if (result) {
                    this.setState({ vCardData: result, isValidQrCode, showQrCodeScanner: false }, _ => {
                        if (window.location.pathname !== '/CreateProfile') {
                            if (this.props.history) {
                                this.props.history.push({
                                    pathname: `/CreateProfile`,
                                    state: { vCardData: result }
                                });
                            }
                            else {
                                if (this.props.getVCardData) {
                                    this.props.getVCardData(result);
                                }
                            }

                            this.props.toggleModal();
                        }
                        else {
                            if (this.props.getVCardData) {
                                this.props.getVCardData(result);
                            }
                        }
                    });
                }
            }
            else {
                this.setState({ vCardData: null, isValidQrCode });
            }
        }
        else {
            this.setState({ vCardData: null });
        }
    }

    render() {
        const { block, error, isValidQrCode, qrCodeError } = this.state;
        const { modal, toggleModal } = this.props;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="md" className="vCardScanner">
                <StyledCard block={block} error={error} >
                    <div className="px-2">
                        <Row className="align-items-center mb-3">
                            <Col>
                                <h5> <i className="fas fa-qrcode" /> <FormattedMessage id="VCardScanner.Title" /> </h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                {qrCodeError ?
                                    <div className="h-100 d-flex align-items-center justify-content-center flex-column">
                                        <div>
                                            <i className="fas fa-video color-red my-4" style={{ fontSize: '50px' }} />
                                        </div>

                                        <div> {qrCodeError}</div>
                                    </div>
                                    :
                                    <QrReader
                                        delay={300}
                                        onError={this.handleError}
                                        onScan={this.handleScan}
                                        style={{ width: '100%' }}
                                        showViewFinder={false}
                                    />
                                }
                            </Col>
                        </Row>

                        <Row>
                            <Col className="text-center text-muted mt-4">
                                {!isValidQrCode ?
                                    <FormattedMessage id="VCardScanner.InvalidQrCode" />
                                    :
                                    <FormattedMessage id="VCardScanner.ScanQrCodeToSeeVCardDetails" />
                                }
                            </Col>
                        </Row>
                    </div>
                </StyledCard>
            </StyledModal>
        );
    }
}
export default injectIntl(VCardScanner)
