import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Card, Col, Row, CardBody, Modal, ModalBody, Form, Label, UncontrolledTooltip } from 'reactstrap';
import { getAPI, deleteAPI } from "../Base/API";
import { StyledCard, ActiveInactiveStatusBadge, BlankCard, CommonHelper } from "../Base/CommonUIComponents";
import { getComparationList } from "../Base/ReferenceDataFunctions";
import { handleNotification } from "../Base/Notification";
import CustomSelect from '../Base/CustomSelect';

import Authorization from '../Base/Authorization';
import ConfirmActionModal from '../Base/ConfirmActionModal';

export class RuleDashboard extends Component {

    constructor(props) {
        super(props);
        this.getHotels = this.getRules.bind(this);
        this.state = {
            block: true,
            ruleList: [],
            hotel: null,
            duplicateModal: false,
            nameOptions: [],
            entityOptions: [],
            comparationOptions: getComparationList(),
            fieldOptions: [],
            deleteModal: false
        };
    }

    componentDidMount() {
        this.getRules();
    }

    getRules() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var nameOptions = [];
                var entityOptions = [];
                var fieldOptions = [];

                data.response && data.response.forEach((rule, i) => {
                    if (!nameOptions.some(n => n.value === rule.name))
                        nameOptions.push({ value: rule.name, label: rule.name, id: rule.id });

                    if (rule.entity && !entityOptions.some(e => e.value === rule.entity))
                        entityOptions.push({
                            value: rule.entity, label: <FormattedMessage id={`ReferenceDataFunctions.${rule.entity}`} /> });

                    if (rule.field && !fieldOptions.some(f => f.value === rule.field))
                        fieldOptions.push({ value: rule.field, label: <FormattedMessage id={`ReferenceDataFunctions.${rule.field}`} /> });

                    data.response[i].visible = true;
                })

                this.setState({ ruleList: data.response, nameOptions, entityOptions, fieldOptions });
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/gms/DataQuality/v1/Rule');
    }

    deleteRule = (e, id) => {
        e.preventDefault();
        this.setState({ blockDelete: true });

        deleteAPI(result => {
            const { data, error } = result;
            this.setState({ blockDelete: true });
            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockDelete: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ blockDelete: false });
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="RuleDashboard.RuleDeletedSuccessfully" />, <FormattedMessage id="generic.success" />);
                    var rules = this.state.ruleList.filter(r => r.id !== id);
                    this.setState({ blockDelete: false, ruleList: rules }, this.toggleDeleteModal);
                }
            }
        }, `/api/gms/DataQuality/v1/Rule/${id}`);
    }

    handleFilters = (key, combo) => {
        this.setState({ block: true });

        const targetValue = combo ? combo.value : '';
        var rules = [...this.state.ruleList];

        rules.forEach((rule, i) => {
            if (rule[key] === targetValue || targetValue === '') {
                rules[i].visible = true;
            }
            else {
                rules[i].visible = false;
            }
        });

        this.setState({ ruleList: rules, block: false });
    }

    toggleModal = () => {
        this.setState(prevState => ({
            duplicateModal: !prevState.duplicateModal
        }))
    }

    toggleDeleteModal = (ruleToDelete) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            ruleToDelete
        }));
    }

    render() {
        const { block, error, ruleList, nameOptions, entityOptions, comparationOptions, fieldOptions, deleteModal, ruleToDelete } = this.state;

        const statusOptions = [
            {
                value: true,
                label: <FormattedMessage id="generic.active" />
            },
            {
                value: false,
                label: <FormattedMessage id="generic.inactive" />
            }
        ]

        return (
            <StyledCard block={block} error={error}>
                <Row className="mb-2">
                    <Col>
                        <h5><i className="fas fa-list-ol mr-2"></i><FormattedMessage id="RuleDashboard.title" /></h5>
                    </Col>

                    <Col className="text-right">
                        <CommonHelper help={<FormattedMessage id="Rules.Help" />} id={'Rules'} lang={this.props.intl.locale} />
                        <Authorization
                            perform="ruleDashboard:add"
                            yes={() => (
                                <>
                                    <Button className="btn btn-sm btn-host mr-2" onClick={this.toggleModal} id="duplicateRule">
                                        <i className="far fa-clone"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="duplicateRule">
                                        <FormattedMessage id="RuleDashboard.DuplicateExistingRule" />
                                    </UncontrolledTooltip>

                                    <Link to="/RuleDetail">
                                        <Button className="btn btn-sm btn-host">
                                            <i className="fas fa-plus"></i>
                                        </Button>
                                    </Link>
                                </>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>

                </Row>
                <Row className="mb-2">
                    <Col>
                        <CustomSelect placeholder={<FormattedMessage id="RuleDashboard.SelectAName" />} options={nameOptions} onChange={this.handleFilters.bind(this, 'name')} isClearable isSearchable/>
                    </Col>
                    <Col>
                        <CustomSelect placeholder={<FormattedMessage id="RuleDashboard.SelectAEntity" />} options={entityOptions} onChange={this.handleFilters.bind(this, 'entity')} isClearable isSearchable />
                    </Col>
                    <Col>
                        <CustomSelect placeholder={<FormattedMessage id="RuleDashboard.SelectAComparation" />} options={comparationOptions} onChange={this.handleFilters.bind(this, 'comparisonType')} isClearable isSearchable />
                    </Col>
                    <Col>
                        <CustomSelect placeholder={<FormattedMessage id="RuleDashboard.SelectAField" />} options={fieldOptions} onChange={this.handleFilters.bind(this, 'field')} isClearable isSearchable />
                    </Col>
                    <Col>
                        <CustomSelect placeholder={<FormattedMessage id="RuleDashboard.SelectAStatus" />} options={statusOptions} onChange={this.handleFilters.bind(this, 'active')} isClearable isSearchable />
                    </Col>
                </Row>
                <Row>
                    <Col className="h-100">
                        {ruleList && ruleList.map((el, k) =>
                            <Card className="border-0 shadow mb-2" key={k} style={{ display: (el.visible ? 'block' : 'none') }}>
                                <CardBody>
                                    <Row className="mb-2">
                                        <Col>
                                            <b>{el.name}</b>
                                        </Col>
                                        <Col className="col-3 text-right">
                                            <ActiveInactiveStatusBadge status={el.active}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-4">
                                            {el.description}
                                        </Col>
                                        {el.ruleType === "FieldCheck" ?
                                            <>
                                                <Col className="col-2">
                                                    <b><FormattedMessage id="RuleDashboard.Entity" />: </b>
                                                    <FormattedMessage id={`ReferenceDataFunctions.${el.entity}`} />
                                                </Col>
                                                <Col className="col-2 px-0">
                                                    <b><FormattedMessage id="RuleDashboard.Field" />: </b>
                                                    <FormattedMessage id={`ReferenceDataFunctions.${el.field}`} />
                                                </Col>
                                                <Col className="col-2 pr-0">
                                                    <b><FormattedMessage id="RuleDashboard.NullValue" />: </b>
                                                    <FormattedMessage id={el.ignoreNull ? `RuleDashboard.Ignore` : `RuleDashboard.DoNotIgnore`} />
                                                </Col>
                                            </>
                                        : ''}


                                        <Col className="text-right">
                                            <Authorization
                                                perform="ruleDashboard:edit"
                                                yes={() => (
                                                    <Button className="btn btn-sm btn-host" onClick={() => this.props.history.push("/RuleDetail/" + el.id)}>
                                                        <i className="fas fa-edit"></i>
                                                    </Button>
                                                )}
                                                no={() => <div></div>}
                                            />

                                            <Authorization
                                                perform="ruleDashboard:delete"
                                                yes={() => (
                                                    <Button className="btn btn-sm btn-host ml-2" onClick={() => this.toggleDeleteModal(el)}>
                                                        <i className="fas fa-trash"></i>
                                                    </Button>
                                                )}
                                                no={() => <div></div>}
                                            />

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                        )
                        }

                    </Col>
                </Row>

                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        block={this.state.blockDelete}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={(e) => this.deleteRule(e, ruleToDelete.id)}
                        text={<FormattedMessage id="RuleDashboard.ConfirmDeleteText" values={{ rule: ruleToDelete.name }} />}
                    />
                : ''}

                <Modal isOpen={this.state.duplicateModal} fade={false} size={"md"} style={{ minWidth: 0 }} >
                    <Button onClick={this.toggleModal} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        <BlankCard>
                            <Form onSubmit={() => this.props.history.push({ pathname: "/RuleDetail", duplicateId: this.state.duplicateRule })}>
                                <Row className="mb-4">
                                    <Col>
                                        <h5><FormattedMessage id="RuleDashboard.DuplicateExistingRule" /> </h5>
                                    </Col>
                                    <Col className="text-right col-2">
                                        <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-plus" /></Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label sm={3}> <FormattedMessage id="RuleDashboard.Rule" /></Label>
                                    <Col sm={9} >
                                        <CustomSelect
                                            options={nameOptions}
                                            required
                                            isSearchable
                                            placeholder={""}
                                            onChange={(combo) => this.setState({ duplicateRule: combo.id })}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </BlankCard>
                    </ModalBody>
                </Modal>
            </StyledCard>
        );
    }
}
export default injectIntl(RuleDashboard)
