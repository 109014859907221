import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';


const supportedBrowsers = {
    Chrome: 80,
    Firefox: 75,
    Edge: 80,
    Safari: 14,
    Opera: 66,
    'Internet Explorer': 11,
};


const browserVersion2023 = {
    Chrome: 109,
    Firefox: 109,
    Edge: 109,
    Safari: 16,
    Opera: 91,
    'Internet Explorer': 11,
};


export function isBrowserVersionSupported() {
    return checkBrowserVersion(supportedBrowsers);
}

export function isAnOldBrowserVersion() {
    return !checkBrowserVersion(browserVersion2023);
}


function checkBrowserVersion(broserVersions) {
    let isSupported = true;

    const userAgent = navigator.userAgent;
    
    if (userAgent.includes('Chrome')) {
        const browserInfo = userAgent.match(/Chrome\/(\d+)/);
        const majorVersion = parseInt(browserInfo[1]);

        isSupported = majorVersion >= broserVersions.Chrome;

    } else if (userAgent.includes('Firefox')) {
        const browserInfo = userAgent.match(/Firefox\/(\d+)/);
        const majorVersion = parseInt(browserInfo[1]);

        isSupported = majorVersion >= broserVersions.Firefox;

    } else if (userAgent.includes('Edge')) {
        const browserInfo = userAgent.match(/Edg\/(\d+)/);
        const majorVersion = parseInt(browserInfo[1]);

        isSupported = majorVersion >= broserVersions.Edge;

    } else if (userAgent.includes('Safari')) {
        const browserInfo = userAgent.match(/Version\/(\d+)/);
        const majorVersion = parseInt(browserInfo[1]);

        isSupported = majorVersion >= broserVersions.Safari;

    } else if (userAgent.includes('OPR')) {
        const browserInfo = userAgent.match(/OPR\/(\d+)/);
        const majorVersion = parseInt(browserInfo[1]);

        isSupported = majorVersion >= broserVersions.Opera;
    }

    return isSupported;
}



export const BrowserVersionNotSupported = ({ }) => {
    return (
        <div className='overflow-hidden' style={{ height: '100vh' }}>
            <div className='d-flex align-items-center justify-content-center flex-column h-100'>
                <div style={{
                    fontSize: '70px',
                    color: '#0667ff',
                    padding: '2vh 8vh',
                    borderStyle: 'solid',
                    borderColor: 'grey',
                    borderRadius: '15px',
                    borderWidth: '24px 7px 7px'
                }}>
                    <i className="far fa-dizzy" />
                </div>
                <h1 className="text-center mt-4 mb-2">
                    <FormattedMessage id="BrowserVersion.NotSupported" />
                </h1>
                <h6>
                    <FormattedMessage id="BrowserVersion.PleaseUpdateBrowser" />
                </h6>
            </div>
        </div>
    )
}


export const OldBrowserVersionWarning = ({ }) => {
    return (isAnOldBrowserVersion() &&
        <div className="d-flex align-items-center py-3 px-4 mb-3" style={{ backgroundColor: '#fff7de', color: '#333333', borderRadius: '8px' }} >
            <b className=" pr-3"><i className="icon-icon-warnings-enabled text-warning mr-1" /> <FormattedMessage id="BrowserVersion.OutdatedBrowserVersionTitle"/> </b>
            <FormattedMessage id="BrowserVersion.OutdatedBrowserVersionDescription" />
        </div>
    )
}