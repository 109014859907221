import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { getAPI, getAPIBlob } from '../Base/API';
import { StyledCard } from "../Base/CommonUIComponents";
import { handleNotification } from '../Base/Notification';
import { Button, Col, Row } from 'reactstrap';
import CustomSelect from "../Base/CustomSelect";
import { getGoContactActions } from '../Base/ReferenceDataFunctions';

export class GoContactReport extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: true,
            action: 'getqueue',
            ownerType: 'queue',
            dataType: null,
            dataTypes: [
                {
                    value: 0,
                    label: <FormattedMessage id="GoContactReport.AllCalls" />
                },
                {
                    value: 1,
                    label: <FormattedMessage id="GoContactReport.CallsWithScript" />
                },
                {
                    value: 2,
                    label: <FormattedMessage id="GoContactReport.AnsweredCalls" />
                },
                {
                    value: 3,
                    label: <FormattedMessage id="GoContactReport.NotAnsweredCalls" />
                },
                {
                    value: 4,
                    label: <FormattedMessage id="GoContactReport.ContactStateByLastCallDate" />
                },
                {
                    value: 5,
                    label: <FormattedMessage id="GoContactReport.ContactStateByLoadDate" />
                },
                {
                    value: 6,
                    label: <FormattedMessage id="GoContactReport.ContactStateByLastUpdateDate" />
                }
            ],
            templateId: null,
            templateName: null,
            templates: [],
            startDate: null,
            endDate: null,
            reportInfo: []
        };
    }

    componentDidMount() {
        this.getTemplates();
    }

    searchTemplates = () => {
        this.setState({ block: true }, this.getTemplates);
    }

    getTemplates = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.data) {
                    const templates = data.data[0]
                        .templates
                        .map(tmp => {
                            const template = {
                                value: tmp.id,
                                label: tmp.name
                            };
                            return template;
                        });
                    this.setState({ reportList: data.data[0], templates, block: false });
                }
                else {
                    this.setState({ reportList: [], templates: [], error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        //}, `/api/gms/GoContact/v1/DownloadReport?file=14-01-2025_Report-67863c648a3140.89403962`);
        //}, `/api/gms/GoContact/v1/BuildReport?templateId=493&ownerType=ticket&startDate=2025-01-01&endDate=2025-01-09&dataType=1`);
        //}, `/api/gms/GoContact/v1/ImportTickets?templateId=493&ownerType=ticket&startDate=2024-01-01&endDate=2024-12-31&updateTickets=false`);
        }, `/api/gms/GoContact/v1/ReportTemplates?action=${this.state.action}`);
    }

    getReportInfo = () => {
        const { templateId } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.data) {
                    
                    this.setState({ reportInfo: data.data[0].elements, block: false });
                }
                else {
                    this.setState({ reportInfo: [], error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ reportInfo: [], error: errorMessage, block: false });
            }
        }, `/api/gms/GoContact/v1/ReportInfo?action=getTemplate&templateId=${templateId}`);
    }

    handleActionSelect = (name, combo) => {
        if (combo) {
            const { dataTypes, ownerType, value } = combo;
            this.setState({ block: true, ownerType, dataTypes, templateId: null, dataType: null, [name]: value, reportInfo: [], templateName: null }, this.getTemplates);
        }
        else {
            this.setState({ ownerType: null, dataTypes: [], templateId: null, dataType: null, [name]: null, reportInfo: [], templateName: null });
        }
    }

    handleTemplateSelect = (name, combo) => {
        if (combo) {
            const { label, value } = combo;
            this.setState({ block: true, dataType: null, [name]: value, templateName: label }, this.getReportInfo);
        }
        else {
            this.setState({ dataType: null, [name]: null, templateName: null, reportInfo: [] });
        }
    }

    handleSelect = (name, combo) => {
        this.setState({ [name]: combo ? combo.value : null });
    }

    buildReport = () => {
        const { templateId, dataType, endDate, ownerType, startDate } = this.state;

        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.data && data.data.length > 0) {
                    data.data.forEach(dt => {
                        if (dt.file) {
                            this.downloadReport(dt.file);
                        }
                    });
                }
            }

            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/GoContact/v1/BuildReport?templateId=${templateId}&ownerType=${ownerType}&startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}&dataType=${dataType}`);
    }

    downloadReport = (file) => {
        const { ownerType } = this.state;

        getAPIBlob(result => {
            const { data, error, headers } = result;

            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    const a = document.createElement("a");
                    const blob = new File([data], { type: headers["content-type"] });
                    a.href = window.URL.createObjectURL(blob);

                    if (headers["content-disposition"]) {
                        const f = headers["content-disposition"].match("filename=([^;]*);")
                        a.download = f != null ? f[1] : "file";
                    }
                    else {
                        a.download = "file";
                    }
                    
                    a.click();
                }
            }
        }, `/api/gms/GoContact/v1/DownloadReport?file=${file}&ownerType=${ownerType}`);
    }

    render() {
        const { block, error, action, dataType, dataTypes, templateId, templates, startDate, endDate, reportInfo, templateName } = this.state;

        const options = getGoContactActions();

        return (
            <StyledCard block={block} error={error} icon="far fa-chart-bar" title="GoContactMenu.Reporting">
                <form ref={this.form}>
                    <Row className="mt-3 mb-4 align-items-center">
                        <Col className="col-4 pr-0">
                            <CustomSelect
                                options={options}
                                value={options.find(m => m.value === action)}
                                placeholder={''}
                                onChange={this.handleActionSelect.bind(this, 'action')}
                                required={true}
                            />
                        </Col>
                        <Col className="text-right" >
                            <Button className="mr-3 btn btn-sm btn-host" onClick={this.buildReport}>
                                <i className="fa fa-download" />
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-4">
                            <div>
                                <h4 className="my-2">
                                    <FormattedMessage id="GoContactReport.Templates" />
                                </h4>
                                <Row>
                                    <Col>
                                        <CustomSelect
                                            options={templates}
                                            onChange={this.handleTemplateSelect.bind(this, 'templateId')}
                                            value={templateId !== null && templates.find(dt => dt.value === templateId)}
                                            placeholder={<FormattedMessage id="GoContactReport.Templates" />}
                                            required
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <h4 className="my-2">
                                    <FormattedMessage id="GoContactReport.DataType" />
                                </h4>
                                <Row>
                                    <Col>
                                        <CustomSelect
                                            options={dataTypes}
                                            onChange={this.handleSelect.bind(this, 'dataType')}
                                            value={dataType !== null && dataTypes.find(dt => dt.value === dataType)}
                                            placeholder={<FormattedMessage id="GoContactReport.DataType" />}
                                            required
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <h4 className="my-2">
                                    <FormattedMessage id="GoContactReport.DateRange" />
                                </h4>
                                <Row>
                                    <Col>
                                        <DateRangePicker
                                            startDate={startDate}
                                            startDateId="your_unique_start_date_id"
                                            endDate={endDate}
                                            endDateId="your_unique_end_date_id"
                                            isOutsideRange={day => day > moment()}
                                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                            small={true}
                                            numberOfMonths={2}
                                            showDefaultInputIcon={true}
                                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                            required
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <h4 className="my-2">
                                    <FormattedMessage id="GoContactReport.Template" />: {templateName}
                                </h4>
                                {
                                    reportInfo.map((info, key) =>
                                        <Row key={key} className="mb-2">
                                            <Col className="ml-3 mr-3 border">
                                                {info.editedText}
                                            </Col>
                                        </Row>
                                    )
                                }
                            </div>
                        </Col>
                    </Row>
                </form>
            </StyledCard>
        );
    }
}
export default injectIntl(GoContactReport)