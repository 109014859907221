import React, { Component } from 'react';
import { Row, Col, Card, Collapse, Badge, UncontrolledTooltip } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { CheckAuthorization } from '../Base/Authorization';


export class GuestsList extends Component {
    static displayName = GuestsList.name;

    constructor(props) {
        super(props);

        this.state = {
            block: true,
        };
    }

    componentDidMount() {       
    }

    getResStatusBadge = (statusList, reserveStatus) => {
        const status = statusList.find(el => el.value === reserveStatus);

        return <Badge className="py-1 w-100" color={status ? status.color : 'secondary'} style={{ fontSize: '12px' }}>
            {status ? status.label : reserveStatus}
        </Badge>
    }

    toggleCollapse = (collapseName) => {
        this.setState(prevState => ({
            [collapseName]: !prevState[collapseName]
        }));
    }

    render() {
        const { setFilters, guestsIssues, filters, birthdaysToday, reservations, reservationStatus, totalMainGuest, totalMailingConsent, totalGuests } = this.props;
        

        return (
            <Card className={`border-0 shadow ${!global.isMobile ? 'homeGuestList' : ''} px-0 h-100`} body>
                <Row className="align-items-center px-3">
                    <Col className={`d-flex align-items-center ${global.isMobile ? 'col-6' : ''}`}>
                        <h5 className="week-day m-0"> <FormattedMessage id="Home.GuestList" /></h5>
                        {birthdaysToday ?
                            <GuestsFilter
                                icon="fas fa-birthday-cake color-cyan"
                                coloredCircle={{ name: "birthDate", value: birthdaysToday, setFilter: () => setFilters("birthDate", ''), bgColor: "bg-cyan" }}
                                intl={this.props.intl}
                            />
                        : ''}
                    </Col>
                    <Col className={`${!global.isMobile ? 'col-5 d-flex justify-content-end' : 'col-6 text-right'}`}>
                        <div className={`bg-light-grey d-flex align-items-center ${!global.isMobile ? ' justify-content-end' : ''} px-3 p-1 invisible-input`} style={{ borderRadius: '20px', width: !global.isMobile ? 'fit-content' : '' }}>
                            <i className="icon-icon-search mr-2" />
                            <input
                                type="text"
                                onChange={(e) => { if (e && e.target) setFilters("fullName", e.target.value) }}
                                placeholder={this.props.intl.formatMessage({ id: "Home.SearchName" })}
                                className={global.isMobile ? 'w-100' : ''}
                            />
                        </div>
                    </Col>
                </Row>


                <Row className=" mb-4 mt-2 px-3">
                    <Col className={`d-flex justify-content-center ${global.isMobile ? 'flex-wrap mt-2' : ''}`}>
                        {guestsIssues.map((el, key) => {
                            return <div key={key} className={`d-flex align-items-center px-2 py-1 mx-2 ${global.isMobile ? 'mb-2' : ''}`} style={{ border: '1px solid #e9e9e9', borderRadius: '40px', height: '30px', boxShadow: '0px 3px 15px #00000012' }} >
                                <i className={`${el.icon} mr-1`} />

                                {!el.unfilled && !el.invalid ?
                                    <div className="circle-xs color-white bg-green ml-1">
                                        <i className="fas fa-check" />
                                    </div>
                                    : ''}

                                {el.unfilled ?
                                    <div className="circle-xs color-white cursor-pointer ml-1" onClick={() => setFilters(`without${el.type}`, '')} style={{ backgroundColor: '#a1a1a1' }} title={this.props.intl.formatMessage({ id: `Home.without${el.type}` })}>
                                        {el.unfilled}
                                    </div>
                                    : ''}

                                {el.invalid ?
                                    <div className="circle-xs color-white bg-red cursor-pointer ml-1" onClick={() => setFilters(`invalid${el.type}`, '')} title={this.props.intl.formatMessage({ id: `Home.invalid${el.type}` })}>
                                        {el.invalid}
                                    </div>
                                    : ''}
                            </div>
                        })}

                        {totalGuests ?
                            <>
                                <GuestsFilter
                                    icon="fas fa-crown fa-sm color-orange"
                                    coloredCircle={{ name: "isMainGuest:true", value: totalMainGuest, setFilter: () => setFilters("isMainGuest", true), bgColor: "bg-orange" }}
                                    greyCircle={{ name: "isMainGuest:false", value: totalGuests - totalMainGuest, setFilter: () => setFilters("isMainGuest", false) }}
                                    intl={this.props.intl}
                                />

                                <GuestsFilter
                                    setFilters={() => setFilters("hasMailingsConsent", true)}
                                    icon="fas  fa-file-contract color-purple"
                                    coloredCircle={{ name: "hasMailingsConsent:true", value: totalMailingConsent, setFilter: () => setFilters("hasMailingsConsent", true), bgColor: "bg-purple" }}
                                    greyCircle={{ name: "hasMailingsConsent:false", value: totalGuests - totalMailingConsent, setFilter: () => setFilters("hasMailingsConsent", false) }}
                                    intl={this.props.intl}
                                />
                            </>
                        : ''}
                    </Col>
                </Row>


                <div className="verticalScroll px-3 mb-2" style={{ overflowX: 'hidden' }}>
                    {reservations.map((el, k) =>
                        <div key={k} className="mb-4">
                            <Row className="overbglight">
                                <Col className="pointer" id={el.id} onClick={() => this.toggleCollapse(el.id)}>
                                    <b> <i className={this.state[el.id] ? "fas fa-chevron-circle-down" : "fas fa-chevron-circle-right"} /> {el.title} </b>
                                </Col>
                                <Col className="col-3 d-flex justify-content-end">
                                    {el.data && el.data.length > 0 &&
                                        <div className="bg-light-grey circle-sm mr-2">
                                            <b>{el.data.length}</b>
                                        </div>
                                    }
                                    <div className="pointer" onClick={() => setFilters("list", el.title, el.id, el.data)}>
                                        <i className={"fa-xs icon-icon-filter " + (filters.some(f => f.type === "list" && f.val === el.title) ? ' color-host' : '')} />
                                    </div>
                                </Col>
                            </Row>
                            <Collapse isOpen={this.state[el.id]} style={{ fontSize: '13px' }}>
                                {el.data.map((guest, key) => {
                                    const warnings = guest.warnings && guest.warnings.filter(warn => warn.hasWarning === true);
                                    //const info = guest.information && guest.information.length > 0 ? guest.information : null;
                                    const membershipCard = guest.membershipCards && guest.membershipCards.length > 0 ? guest.membershipCards[0] : null;

                                    return <Row key={key} className="py-3 font-weight-bold overbglight">
                                        <Col className={`col-${global.isMobile ? '6' : '3'} pointer`} onClick={() => { if (CheckAuthorization("profileMenu:view", null)) this.props.history.push({ pathname: `/ProfileDetails/${guest.id}` }) }}>
                                            <Row>
                                                {!global.isMobile?
                                                    <Col className="pr-0">
                                                        {guest.fullName} {guest.isMainGuest ? <i className="fas fa-crown color-orange fa-sm ml-1" /> : ''}
                                                    </Col>
                                                :
                                                    <Col className="pr-0 d-flex align-items-center">
                                                        <div className='text-truncate'>
                                                            {guest.fullName}
                                                        </div>
                                                         {guest.isMainGuest ? <i className="fas fa-crown color-orange fa-sm ml-1" /> : ''}
                                                    </Col>
                                                }
                                            </Row>
                                            <Row><Col className="week-day">{guest.roomTypeCode}</Col></Row>
                                        </Col>

                                        <Col className={`col-${global.isMobile ? '6' : '4'} pl-0`}>
                                            <Row><Col>
                                                {guest.contacts && guest.contacts.some(c => c.type === 'Mobile') ? <span> {guest.contacts.find(c => c.type === 'Mobile').contact} </span> : guest.contacts && guest.contacts.some(c => c.type === 'Phone') ? <span> {guest.contacts.find(c => c.type === 'Phone').contact} </span> : ''}
                                            </Col></Row>
                                            <Row><Col>
                                                {guest.contacts && guest.contacts.some(c => c.type === 'Email') ? <span> {guest.contacts.find(c => c.type === 'Email').contact} </span> : ''}
                                            </Col></Row>
                                        </Col>

                                        <Col className={`col-${global.isMobile ? '12 mt-3' : '5 pl-0'} d-flex justify-content-center align-items-center w-100`}>
                                            <Row className={`w-100 d-flex ${global.isMobile ? 'justify-content-between' : 'justify-content-center'} align-items-center`}>
                                                <Col className={`pr-1 pl-3 d-flex justify-content-center ${global.isMobile ? 'col-3' : 'col-2'}`}>
                                                    {guest.hasMailingsConsent ?
                                                        <span>
                                                            <i className="fas fa-file-contract bg-purple color-white circle-md p-2 mb-1 d-flex align-items-center justify-content-center" id={`mailing-${el.id}-${key}`} style={{ height: '26.8px', width: '26.8px' }} />

                                                            <UncontrolledTooltip placement="bottom" target={`mailing-${el.id}-${key}`}>
                                                                <span> <FormattedMessage id="Home.hasMailingsConsent:true" /> </span>
                                                            </UncontrolledTooltip>
                                                        </span>
                                                        : ''}
                                                </Col>
                                                <Col className={`px-1 text-center ${global.isMobile ? 'col-3' : 'col-2'}`}>

                                                    {warnings && warnings.length > 0 ?
                                                        <div>
                                                            <span><i className="icon-icon-warnings bg-yellow color-white circle-md p-2" id={`warning-${el.id}-${key}`} /></span>
                                                            <UncontrolledTooltip placement="bottom" target={`warning-${el.id}-${key}`}>
                                                                {warnings.map(warning =>
                                                                    <span> {warning.warningText} <br /></span>
                                                                )}
                                                            </UncontrolledTooltip>
                                                        </div>
                                                        : ''}
                                                </Col>
                                                <Col className={`px-1 text-center ${global.isMobile ? 'col-3' : 'col-2'}`}>
                                                    {membershipCard &&
                                                        <div>
                                                            <i className="icon-icon-member-card bg-violet color-white circle-md p-2" id={`membershipCard-${el.id}-${key}`} />
                                                            <UncontrolledTooltip placement="bottom" target={`membershipCard-${el.id}-${key}`}>
                                                                {membershipCard.cardTypeDescription}
                                                            </UncontrolledTooltip>
                                                        </div>
                                                    }
                                                </Col>
                                                <Col className={`${global.isMobile ? 'col-12 mt-2 text-right' : 'col-4 pr-0 pl-2'} `}>
                                                    {this.getResStatusBadge(reservationStatus, guest.reserveStatus)}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                })}
                            </Collapse>
                        </div>
                    )}
                </div>
            </Card>
        );
    }
}

export default injectIntl(GuestsList);


const GuestsFilter = ({ icon, coloredCircle, greyCircle, intl }) => {
    return <div className="d-flex align-items-center mx-2" style={{ border: '1px solid #e9e9e9', borderRadius: '40px', height: '30px', boxShadow: '0px 3px 15px #00000012', padding: "0 10px" }}>
        <i className={icon + ` mr-1`} />

        {coloredCircle && coloredCircle.value ?
            <div className={coloredCircle.bgColor + " circle-xs color-white ml-1 pointer"} onClick={coloredCircle.setFilter} title={intl.formatMessage({ id: `Home.${coloredCircle.name}` })}>
                {coloredCircle.value}
            </div>
        : ''}

        {greyCircle && greyCircle.value ?
            <div className="circle-xs color-white pointer ml-1" onClick={greyCircle.setFilter} style={{ backgroundColor: '#a1a1a1' }} title={intl.formatMessage({ id: `Home.${greyCircle.name}` })}>
                {greyCircle.value}
            </div>
        : ''}
    </div>
}
