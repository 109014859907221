import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI, postAPI } from '../Base/API';
import { StyledCard, CommonHelper } from '../Base/CommonUIComponents';
import CustomSelect from "../Base/CustomSelect";
import { CustomTable } from '../Base/CustomTable';
import { handleNotification } from '../Base/Notification';

class AssociateTags extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: true,
            tags: [],
            selectedTags: [],
            selectedEmails: [],
            isValid: true
        }
    }

    componentDidMount() {

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                const tagGroups = data.response.map(resp => {
                    const tags = resp.tag.map(t => { const tag = { value: t.id, label: t.code }; return tag; });
                    const obj = {
                        label: <div className="d-flex justify-content-between"><span>{resp.code}</span><span className="px-2" style={{ backgroundColor: '#EBECF0', borderRadius: '20px' }}>{tags.length}</span></div>,
                        options: tags
                    };

                    return obj;
                });
                this.setState({ tags: tagGroups, error: errorMessage, block: false });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }

        }, `api/gms/Profile/v1/tag/groupDetails?tagType=all`);
    }

    handleTags = (combo, evt) => {
        if (evt) {
            const selectedTags = combo ? combo : [];
            this.setState({ selectedTags: selectedTags });
        }
    }

    onSelectFile = (evt) => {
        if (evt && evt.target) {
            const file = evt.target.files[0];

            if (file !== undefined) {
                const reader = new FileReader();

                reader.readAsText(file);

                reader.onload = (e) => {
                    const csv = e.target.result;
                    this.processFile(csv);
                }
            }
        }
    }

    processFile = (csv) => {
        const error = [];
        const lines = csv.split(/\r\n|\n/);
        const data = [];
        var valid = true;

        lines
            .slice(1) //Remove column names
            .forEach(line => {
                if (line && line.length > 0) {
                    const mailFormat = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}$/;
                    const email = line.trim().toLowerCase();
                    
                    if (!email.match(mailFormat)){
                        data.push({ email: email, valid: false });
                        valid = false
                    }
                    else{
                        data.push({ email: email, valid: true });
                    }
                }
            });
        this.setState({ selectedEmails: data, error: error, isValid: valid });
    }

    postAssociations = (evt) => {
        evt.preventDefault();

        const { selectedEmails, selectedTags } = this.state;

        if (!this.form.current.reportValidity()) {
            return;
        }

        const req = {
            request: {
                tags: selectedTags.map(tag => tag.value),
                emails: selectedEmails.map(email => email.email)
            }
        };

        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="AssociateTags.SuccessSave" />, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/associateTagsToProfiles`, req);
    }

    render() {
        const { block, error, tags, selectedTags, selectedEmails, isValid } = this.state;
        const { intl } = this.props;

        const columns = [
            { text: 'Email', dataField: 'email' },
            { text: intl.formatMessage({ id: 'AssociateTags.Valid' }), dataField: 'valid', sort: true, headerStyle: () => {
                return { width: "10%", textAlign: 'center' };
              },
                formatter:
                    (cell, row, idx) => {
                        return(
                            <div style={{ textAlign: 'center'}} key={idx}>
                                <span id={`Tooltip${idx}`}>
                                    <i style={{color: cell ? '#76EFAC' :  '#EE6A5F'}} className={`fas fa-${cell ? 'check' : 'times'}`}></i>
                                </span>
                            </div>
                        )
                    }
            }
        ]

        return (
            <StyledCard block={block} error={error}>
                <form ref={this.form}>
                    <Row className="mb-3">
                        <Col>
                            <h5>
                                <i className="fas fa-tag mr-2" /> <FormattedMessage id="NavMenu.AssociateTags" />
                            </h5>
                        </Col>
                        <Col className="text-right">
                            <CommonHelper help={<FormattedMessage id="ProfileTags.Help" />} id={'ProfileTags'} lang={this.props.intl.locale} />
                            <Button disabled={!selectedEmails.length > 0 || !isValid} className="btn btn-host btn-sm float-right" onClick={this.postAssociations}>
                                <i className="fas fa-save" />
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col className="col-1">
                            <FormattedMessage id="AssociateTags.Tags" />
                        </Col>
                        <Col className="col-5">
                            <CustomSelect options={tags} isMulti={true} value={selectedTags.filter(st => tags.find(t => t.options.find(opt => opt.value === st.value)))} onChange={this.handleTags} required />
                        </Col>
                        <Col>
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" id="loademails" onChange={this.onSelectFile} accept=".csv" required />
                                <label className="custom-file-label" for="loademails">
                                    <FormattedMessage id="generic.ChooseFile" />
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col>
                            <CustomTable
                                data={selectedEmails}
                                columns={columns}
                                search={true}
                                exportCSV={true}
                                hideSizePerPage={false}
                                showTotal={selectedEmails.length > 0}
                            />
                        </Col>
                    </Row>
                </form>
            </StyledCard>
        );
    }
}
export default injectIntl(AssociateTags)