import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Col, Row, Card, Input, CustomInput, Form } from "reactstrap";
import { handleNotification } from "../../../Base/Notification";
import { StyledCard, CommonHelper } from "../../../Base/CommonUIComponents";
import { getAPI, postAPI } from "../../../Base/API";
import CustomSelect from '../../../Base/CustomSelect';
import { getProfileType } from "../../../Base/ReferenceDataFunctions";
import PrefixModal from "./NewFolder/PrefixModal";

class LoyaltyCardAttribution extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            attributionConfig: {},
            profileTypeOptions: getProfileType(this.props.intl).filter(pt => pt.value === 'Person' || pt.value === 'Company'),
            hotels: [],
            modal: false,
            selectedPrefix: null,
            loyalties: [],
            tagOptions: [],
            blockTags: true,
            loyaltyCardTypes: [],
            blockCardType: true
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;

        this.getLoyaltyCardLevelConfigs();
        this.getHotels();
        this.getAttributionConfig();
        this.getTags();
        this.getLoyaltyCardTypes();
    }

    getLoyaltyCardTypes = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, blockCardType: false });
                return;
            }
            if (data) {
                this.setState({ error: errorMessage, blockCardType: false, loyaltyCardTypes: data.response?.map((el) => ({ label: el.description, value: el.code })) });
            }
            else {
                this.setState({ error: errorMessage, blockCardType: false });
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardTypeConfig`);
    }

    getLoyaltyCardLevelConfigs = () => {
        this.setState({ blockLoyalties: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, blockLoyalties: false });

                return;
            }
            if (data) {
                const loyalties = data.response
                    .sort((a, b) => a.minRoomNightsToLevel + a.minPointsToLevel - b.minRoomNightsToLevel - b.minPointsToLevel)
                    .map(conf => {
                        const opt = { value: conf.code, label: conf.code, loyaltyCardTypeConfigCode: conf.loyaltyCardTypeConfigCode };
                        return opt;
                    });

                this.setState({ blockLoyalties: false, loyalties: loyalties });
            }
            else {
                this.setState({ blockLoyalties: false });
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardLevelConfig`);
    }

    getAttributionConfig = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ attributionConfig: {}, block: false });
                }
                else if (data.response && data.response.length > 0) {
                    this.setState({ attributionConfig: data.response[0], block: false });
                }
                else {
                    this.setState({ attributionConfig: {}, block: false });
                }
            }
            else this.setState({ block: false })
        }, `/api/gms/LoyaltyCard/v1/HotelGroupLoyaltyCardConfig`);
    }

    getHotels = () => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });

                return;
            }
            if (data) {
                if (data.response) {
                    const hotels = data.response.map(h => {
                        const hotel = {
                            value: `${h.hotelId}`,
                            label: `${h.name2}`
                        };
                        return hotel;
                    });

                    this.setState({ hotels: hotels });
                }
            }
        }, '/api/gms/Hotel/v1/hotel/list?onlyFromHotelGroup=true');
    }

    saveConfig = (e) => {
        e.preventDefault();

        this.setState({ block: true });
        const { attributionConfig } = this.state;

        if (attributionConfig.mode === null || attributionConfig.mode === undefined) {
            attributionConfig.mode = 'Manual';
        }

        const request = {
            request: attributionConfig
        };


        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="LoyaltyCardAttribution.ConfigSaved" />, <FormattedMessage id="generic.success" />);
                    this.setState({ attributionConfig: data.response[0], error: errorMessage, block: false });
                }
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/LoyaltyCard/v1/HotelGroupLoyaltyCardConfig`, request);
    }

    updatePrefixList = (evt, prefix, isToDelete) => {
        if (evt) evt.preventDefault();

        const { attributionConfig } = this.state;
        let prefixes = attributionConfig.hotelGroupLoyaltyCardConfigPrefixes || [];

        const idx = prefix.id ? prefixes.findIndex(p => p.id === prefix.id) : -1;


        if (isToDelete) {
            if (idx !== -1) {
                prefixes.splice(idx, 1);
            }
            else {
                const arrayIdx = prefixes.findIndex(p => JSON.stringify(p) === JSON.stringify(prefix));

                if (arrayIdx !== -1) {
                    prefixes.splice(arrayIdx, 1);
                }
            }
        }
        else {
            if (idx !== -1) {
                prefixes[idx] = prefix;
            }
            else {
                prefixes.push(prefix);
            }
        }

        attributionConfig.hotelGroupLoyaltyCardConfigPrefixes = prefixes;

        this.setState({ attributionConfig, modal: false });
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            attributionConfig: {
                ...this.state.attributionConfig,
                [name]: value
            }
        });
    };

    handleSelect = (name, combo) => {
        const { attributionConfig } = this.state;

        const shouldGetTags = name !== 'eventType' || combo?.value === 'Tag';

        this.setState({
            attributionConfig: {
                ...attributionConfig,
                tagId: shouldGetTags ? attributionConfig.tagId : null,
                minimumAge: shouldGetTags ? null : attributionConfig.minimumAge,
                [name]: combo ? combo.value : null
            },
        });
    };

    handleCardType = (combo) => {
        if (!combo || combo.value !== this.state.attributionConfig.loyaltyCardTypeConfigCode) {
            this.setState({
                attributionConfig: {
                    ...this.state.attributionConfig,
                    loyaltyCardTypeConfigCode: combo ? combo.value : null,
                    membershipTypeCode: combo ? combo.code : null,
                    typeCode: null
                }
            })
        }
    }

    getTags = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockTags: false });
                return;
            }
            if (data) {
                this.setState({ tagOptions: data.response?.map((t) => ({
                    value: t.id,
                    label: t.code
                })) });
            }
            this.setState({ error: errorMessage, blockTags: false });
        }, `/api/gms/Profile/v1/tag`);
    }

    handleChangeSwitch = (e) => {
        const { name, checked } = e.target;

        this.setState({
            attributionConfig: {
                ...this.state.attributionConfig,
                [name]: checked ? 'Automatic' : 'Manual'
            }
        })
    }

    toggleModal = (prefix) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedPrefix: prefix
        }))
    }    

    render() {
        const { block, error, attributionConfig, profileTypeOptions, hotels, selectedPrefix, modal, loyalties, tagOptions, blockTags, loyaltyCardTypes, blockCardType, isSmallScreen } = this.state;
        const { intl } = this.props;

        const eventOptions = [
            { value: 'CheckIn', label: intl.formatMessage({ id: 'LoyaltyCardAttribution.CheckIn' }) },
            { value: 'CheckOut', label: intl.formatMessage({ id: 'LoyaltyCardAttribution.CheckOut' }) },
            { value: 'Booking', label: intl.formatMessage({ id: 'LoyaltyCardAttribution.Booking' }) },
            { value: 'Tag', label: intl.formatMessage({ id: 'LoyaltyCardAttribution.Tag' }) }
        ];
        
        return (
            <StyledCard block={block} error={error} >
                <Form onSubmit={this.saveConfig}>
                    <Row className="mb-3">
                        <Col>
                            <h5>
                                <i className="icon-icon-member-card fa-sm mr-2" />
                                <FormattedMessage id="LoyaltyCardAttribution.Title" />
                            </h5>
                        </Col>
                        <Col className="text-right">
                            <Button className="btn-sm btn-host" type="submit">
                                <i className="fas fa-save" />
                            </Button>
                            <CommonHelper help={<FormattedMessage id="LoyaltyAttribution.Help" />} id={'LoyaltyAttribution'} lang={this.props.intl.locale} />
                        </Col>
                    </Row>

                    <div className="pl-4">
                        <Row className="mb-4 align-items-center">
                            <Col sm={2}> <FormattedMessage id="LoyaltyCardAttribution.StartingIndex" /> </Col>
                            <Col sm={4}>
                                {attributionConfig.id ?
                                    <span>  {attributionConfig.startingIndex}</span>
                                    :
                                    <Input
                                        type="number"
                                        name="startingIndex"
                                        onChange={(e) => this.handleChange(e)}
                                        value={attributionConfig.startingIndex || ''}
                                        required
                                        min="0"
                                    />
                                }
                            </Col>
                            <Col className="col-2"><FormattedMessage id="LoyaltyCardAttribution.CurrentIndex" /></Col>
                            <Col className="col-4" >
                                {attributionConfig.currentIndex || ''}
                            </Col>
                        </Row>

                        <Row className="mb-4 align-items-center">
                            <Col sm={2}> <FormattedMessage id="LoyaltyCardAttribution.Prefix" /> </Col>
                            <Col sm={4}>
                                <Input
                                    type="text"
                                    name="prefix"
                                    onChange={(e) => this.handleChange(e)}
                                    value={attributionConfig.prefix || ''}
                                    maxLength="5"
                                />
                            </Col>
                        </Row>
                    </div>

                    <div className="mt-5 mb-3" style={{ display: "flex"}}>
                        <h5 className="mr-4">
                                <i className="fas fa-cogs fa-sm mr-2" />
                                <FormattedMessage id="LoyaltyCardAttribution.AutoAttribution" />
                            </h5>
                            <CustomInput
                                type="switch"
                                name="mode"
                                id="mode"
                                onChange={(e) => this.handleChangeSwitch(e)}
                                checked={attributionConfig.mode === 'Automatic'}
                            />
                    </div>
                    <div className="pl-4">
                        {global.modules && global.modules.some(m => m === 'OneCardByType') ?
                            <Row className="mb-4 align-items-center">
                                <Col sm={2}> <FormattedMessage id="LoyaltyCardAttribution.CardType" /> </Col>
                                <Col sm={4}>
                                    <CustomSelect
                                        isSearchable={false}
                                        placeholder={""}
                                        isLoading={blockCardType}
                                        options={loyaltyCardTypes}
                                        onChange={(e) => this.handleCardType(e)}
                                        value={attributionConfig.loyaltyCardTypeConfigCode ? loyaltyCardTypes.find(el => el.value === attributionConfig.loyaltyCardTypeConfigCode) : null}
                                    />
                                </Col>
                            </Row>
                        : ''}

                        <Row className="mb-4 align-items-center">
                            <Col sm={2}> <FormattedMessage id="LoyaltyCardAttribution.TypeCode" /> </Col>
                            <Col sm={4}>
                                <CustomSelect
                                    options={attributionConfig.loyaltyCardTypeConfigCode != null ? loyalties?.filter(p => p.loyaltyCardTypeConfigCode == attributionConfig.loyaltyCardTypeConfigCode || !p.loyaltyCardTypeConfigCode) : loyalties}
                                    value={loyalties?.find(m => m.value === attributionConfig.typeCode) || { value: attributionConfig.typeCode, label: attributionConfig.typeCode }}
                                    isSearchable
                                    placeholder={''}
                                    onChange={this.handleSelect.bind(this, 'typeCode')}
                                    required={attributionConfig.mode === 'Automatic'}
                                    isLoading={this.state.blockLoyalties}
                                />
                            </Col>
                            <Col sm={2}> <FormattedMessage id="LoyaltyCardAttribution.Prefix" /> </Col>
                            <Col sm={4}>
                                <Input
                                    type="text"
                                    name="sourceCode"
                                    onChange={(e) => this.handleChange(e)}
                                    value={attributionConfig.sourceCode || ''}
                                    maxLength="50"
                                    required={attributionConfig.mode === 'Automatic'}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-4 align-items-center">
                            <Col sm={2}> <FormattedMessage id="LoyaltyCardAttribution.ProfileType" /> </Col>
                            <Col sm={4}>
                                <CustomSelect
                                    options={profileTypeOptions}
                                    value={profileTypeOptions.find(m => m.value === attributionConfig.profileType) || ''}
                                    isSearchable
                                    placeholder={''}
                                    onChange={this.handleSelect.bind(this, 'profileType')}
                                    required={attributionConfig.mode === 'Automatic'}
                                />
                            </Col>
                            <Col sm={2}> <FormattedMessage id="LoyaltyCardAttribution.Vality" /> </Col>
                            <Col sm={3}>
                                <Input
                                    type="number"
                                    name="vality"
                                    onChange={(e) => this.handleChange(e)}
                                    value={attributionConfig.vality || ''}
                                />
                            </Col>
                            <Col className="col-1 text-right pr-4"> <FormattedMessage id="LoyaltyCardAttribution.Months" /> </Col>
                        </Row>

                        <Row className="mb-4 align-items-center">
                            <Col sm={2}> <FormattedMessage id="LoyaltyCardAttribution.EventType" /> </Col>
                            <Col sm={4}>
                                <CustomSelect
                                    options={eventOptions}
                                    value={eventOptions.find(m => m.value === attributionConfig.eventType) || ''}
                                    isSearchable
                                    placeholder={''}
                                    onChange={this.handleSelect.bind(this, 'eventType')}
                                    required={attributionConfig.mode === 'Automatic'}
                                />
                            </Col>
                            {attributionConfig.eventType === 'Tag' ?
                                <>
                                    <Col sm={2}> <FormattedMessage id="LoyaltyCardAttribution.Tag" /> </Col>
                                    <Col sm={4}>
                                        <CustomSelect
                                            options={tagOptions}
                                            value={tagOptions?.find(m => m.value === attributionConfig.tagId) || ''}
                                            isSearchable
                                            placeholder={''}
                                            onChange={this.handleSelect.bind(this, 'tagId')}
                                            required={true}
                                            isLoading={blockTags}
                                        />
                                    </Col>
                                </>
                            :
                                <>
                                    <Col sm={2}> <FormattedMessage id="LoyaltyCardAttribution.MinimumAge" /> </Col>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            name="minimumAge"
                                            onChange={(e) => this.handleChange(e)}
                                            value={attributionConfig.minimumAge || ''}
                                        />
                                    </Col>
                                </>
                            }
                        </Row>
                    </div>


                    <Row className="mt-5 mb-3">
                        <Col>
                            <h5>
                                <i className="fas fa-terminal fa-sm mr-2" />
                                <FormattedMessage id="LoyaltyCardAttribution.Prefixes" />
                            </h5>
                        </Col>
                        <Col className="text-right">
                            <Button className="btn-sm btn-host" onClick={() => this.toggleModal(null)}>
                                <i className="fas fa-plus" />
                            </Button>
                        </Col>
                    </Row>
                    <div className="pl-4">
                        <Row>
                            <Col>
                                <Card className=" shadow p-2 py-2 border-0 mb-2 text-muted" >
                                    <Row className="px-2 align-items-center">
                                        <Col sm={3}>
                                            <FormattedMessage id="generic.Hotel" />
                                        </Col>
                                        <Col sm={3}>
                                            <FormattedMessage id="LoyaltyCardAttribution.ComplexCode" />
                                        </Col>
                                        <Col sm={2}>
                                            <FormattedMessage id="LoyaltyCardAttribution.Code" />
                                        </Col>
                                        <Col>
                                            <FormattedMessage id="LoyaltyCardAttribution.Description" />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        {attributionConfig.hotelGroupLoyaltyCardConfigPrefixes?.map((prefix, key) => {
                            const hotel = hotels?.find(h => h.value === prefix.hotelId);

                            return (
                                <Row className="my-1" key={key}>
                                    <Col>
                                        <Card className=" shadow p-2 py-3 mb-2 border-0 pointer overbglight" onClick={() => this.toggleModal(prefix)} >
                                            <Row className="px-2 align-items-center">
                                                <Col sm={3}>
                                                    {hotel ? hotel.label : '-'}
                                                </Col>
                                                <Col sm={3}>
                                                    {prefix.complexCode}
                                                </Col>
                                                <Col sm={2}>
                                                    {prefix.code}
                                                </Col>
                                                <Col>
                                                    {prefix.description}
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            );
                        })}
                    </div>
                </Form>

                {modal ?
                    <PrefixModal
                        modal={modal}
                        toggleModal={this.toggleModal}
                        selectedPrefix={selectedPrefix}
                        hotels={this.state.hotels}
                        updatePrefixList={this.updatePrefixList}
                    />
                : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(LoyaltyCardAttribution);