import React, { Component } from 'react'
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../../Base/ErrorAlert';
import { Button, Card, CardBody, Col, Input, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../../Base/CustomSelect';
import GenericTextArea from '../../Base/GenericTextArea';
import { CampaignTargetStatusBadge } from './CampaignTargetsStatusBadge';
import moment from 'moment-timezone';
import { getSenderChannel, getSenderChannelOptions } from '../../Base/ReferenceDataFunctions';

class CampaignTargetConfig extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            hasEgoi: false,
            hasSendGrid: false,
            hasTwillio: false,
            senderChannelOptions: []
        };
    }

    componentDidMount() {
        window.setTimeout(
            () => {
                const hasEgoi = global.modules && global.modules.some(m => m === 'Egoi');
                const hasSendGrid = global.modules && global.modules.some(m => m === 'SendGrid');
                const hasTwillio = global.modules && global.modules.some(m => m === 'TwilioMessaging');

                this.setState({ hasEgoi, hasSendGrid, hasTwillio, senderChannelOptions: getSenderChannel() });
            },
            global.modules ? 0 : 2500
        );
    }

    render() {
        const { block, error, hasEgoi, senderChannelOptions } = this.state;
        const { campaignTarget, intl, nextFireTime, handleCountriesToSend } = this.props;

        const activeOptions = [
            { value: true, label: intl.formatMessage({ id: "CampaignTargets.Active" }) },
            { value: false, label: intl.formatMessage({ id: "CampaignTargets.Inactive" }) } 
        ];

        const campaignTargetStatus = ['Finished', 'Running', 'NotRun'];

        const countriesToSendOptions = [
            {
                value: 'PT',
                label: <FormattedMessage id="Campaigns.Portugal" />
            },
            {
                value: 'ES',
                label: <FormattedMessage id="Campaigns.Spain" />
            }
        ];
        
        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <form onSubmit={this.props.saveCampaignTargets}>
                    <Card className='border-0 shadow'>
                        <CardBody className='pt-2 pb-3'>
                            <div className='mt-3'>
                                <Row>
                                    <Col className="col-6">
                                        <h5>
                                            <i className="fas fa-cogs mr-1" />
                                            <FormattedMessage id="CampaignTargets.Config"/>
                                        </h5>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col className='title-sm'>
                                                <FormattedMessage id="CampaignTargets.Status" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <CampaignTargetStatusBadge status={campaignTarget.status} />
                                            </Col>                                            
                                        </Row>
                                    </Col>
                                    {
                                        campaignTarget.status === campaignTargetStatus[0] ?
                                            <Col>
                                                <Row>
                                                    <Col className='title-sm'>
                                                        <FormattedMessage id="CampaignTargets.LastRun" />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {moment(campaignTarget.lastRun).format("DD/MM/YYYY HH:mm:ss")}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            :
                                            ''
                                    }
                                    {
                                        campaignTarget.status === campaignTargetStatus[2] ?
                                            <Col>
                                                <Row>
                                                    <Col className='title-sm'>
                                                        <FormattedMessage id="CampaignTargets.WillRun" />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {moment(nextFireTime).format("DD/MM/YYYY HH:mm:ss")}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            :
                                            ''
                                    }
                                    <Col className='col-1 text-right'>
                                        <Button className="btn-sm btn-host mr-2">
                                            <i className="fas fa-save"/>
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            <div className='mt-2'>
                                <Row>
                                    <Col className='col-6'>
                                        <div className='title-sm'>
                                            <FormattedMessage id="CampaignTargets.Name"/>
                                        </div>
                                        <div>
                                            <Input
                                                type="text"
                                                name="name"
                                                placeholder={intl.formatMessage({ id: "CampaignTargets.Name" })}
                                                value={campaignTarget.name || ''}
                                                onChange={this.props.handleChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col className='col-6'>
                                        <div className='title-sm'>
                                            <FormattedMessage id="CampaignTargets.Status"/>
                                        </div>
                                        <div>
                                            <CustomSelect
                                                name="active"
                                                placeholder={intl.formatMessage({ id: "CampaignTargets.Status" })}
                                                isClearable={false}
                                                isSearchable={false}
                                                options={activeOptions}
                                                value={campaignTarget.active ? activeOptions.find(({value}) => value) : activeOptions.find(({value}) => !value)}
                                                onChange={(combo) => this.props.handleSelect('active', combo)}
                                            />
                                        </div>
                                    </Col>
                                    <Col className='col-12 mt-2'>
                                        <div className='title-sm'>
                                            <FormattedMessage id="CampaignTargets.Description"/>
                                        </div>
                                        <div>
                                            <GenericTextArea
                                                backgroundColor={"white"}
                                                onChange={this.props.handleChange}
                                                name={'description'}
                                                value={campaignTarget.description ?? ''}
                                                placeholder={intl.formatMessage({ id: "CampaignTargets.Description" })}
                                                maxLength='999'
                                                className="py-1"
                                                style={{
                                                    width: '100%',
                                                    background: 'transparent',
                                                    boxShadow: 'unset',
                                                    outline: '0',
                                                    maxHeight : '500px',
                                                    minHeight: '50px',
                                                    height: '50px',
                                                    padding: '5px 0',
                                                    resize: 'none',
                                                    color: '#07294C',
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col className='col-6 mt-2'>
                                        <div className='title-sm'>
                                            <FormattedMessage id="Campaigns.Channel" />
                                        </div>
                                        <div>
                                            <CustomSelect
                                                name="senderChannel"
                                                placeholder={intl.formatMessage({ id: "Campaigns.Channel" })}
                                                isClearable={false}
                                                isSearchable={false}
                                                options={senderChannelOptions}
                                                value={senderChannelOptions.filter(chn => chn.value === campaignTarget.senderChannel)}
                                                onChange={(combo) => this.props.handleSelectSenderChannel('senderChannel', combo)}
                                                required
                                            />
                                        </div>
                                    </Col>
                                    {
                                        hasEgoi && campaignTarget.senderChannel === getSenderChannelOptions()[1].value ?
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="Campaigns.CountriesToSend" />
                                                </div>
                                                <div>
                                                    <CustomSelect
                                                        name="countriesToSend"
                                                        placeholder={intl.formatMessage({ id: "Campaigns.CountriesToSend" })}
                                                        isClearable
                                                        isSearchable
                                                        options={countriesToSendOptions}
                                                        onChange={handleCountriesToSend}
                                                        isMulti={true}
                                                        value={campaignTarget.params && campaignTarget.params.countriesToSend && countriesToSendOptions.filter(cntOpt => campaignTarget.params.countriesToSend.some(cnt => cntOpt.value === cnt))}
                                                    />
                                                </div>
                                            </Col>
                                            :
                                            ''
                                    }
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                </form>
            </BlockUi>
        )
    }
}

export default injectIntl(CampaignTargetConfig);