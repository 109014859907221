import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Card, FormGroup, Modal, ModalBody, Input } from 'reactstrap';
import { ActiveInactiveStatusBadge, BlankCard, DefaultPage, StyledCard, CommonHelper } from '../../Base/CommonUIComponents';
import { postAPI, getAPI } from '../../Base/API';
import CustomFieldModal from './CustomFieldModal';


class CustomFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            customFields: []
        };
    }

    componentDidMount() {
        this.getCustomFields();
    }

    getCustomFields = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ customFields: data.response, block: false })
            }
            else this.setState({ block: false })
        }, `/api/gms/CustomFieldConfig/v1`);
    }

    updateCustomField = (field, isToRemove) => {
        const customFields = [...this.state.customFields];
        const index = customFields.findIndex(el => el.id === field.id);

        if (index !== -1) {
            if (isToRemove) {
                customFields.splice(index, 1);
            }
            else {
                customFields[index] = field;
            }
        }
        else {
            customFields.push(field);
        }

        this.setState({ customFields });
    }
    
    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            tagMapping: {
                ...this.state.tagMapping,
                [name]: value
            }
        })
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            tagMapping: {
                ...this.state.tagMapping,
                [name]: combo ? combo.value : null
            }
        })
    };

    toggleModal = (modal, field) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedCustomField: field
        }))
    }


    render() {
        const { error, block, customFields, customFieldModal } = this.state;
        

        return (
            <StyledCard block={block} error={error}>
                <Row className="mb-4 py-1">
                    <Col>
                        <h5> <i className="icon-icon-custom-fields mr-2" /> <FormattedMessage id="NavMenu.CustomFields"/> </h5>
                    </Col>
                    <Col className="text-right col-2">
                        <Button className="btn-sm btn-host mr-1" onClick={() => this.toggleModal("customFieldModal")}>
                            <i className="fas fa-plus" />
                        </Button>
                        <CommonHelper help={<FormattedMessage id="ConfigCustomFields.Help" />} id={'ConfigCustomFields'} lang={this.props.intl.locale} />
                    </Col>
                </Row>

                {customFields?.length > 0 ?
                    <div>
                        <Card className="text-muted shadow border-0 py-2 mb-3 mx-1" body>
                            <Row>
                                <Col sm={3}> <FormattedMessage id="generic.Code" /> </Col>
                                <Col sm={4}> <FormattedMessage id="generic.Description" /> </Col>
                                <Col sm={2}> <FormattedMessage id="CustomFields.DataType" /> </Col>
                                <Col sm={2}> <FormattedMessage id="CustomFields.SortOrder" /> </Col>
                                <Col className="text-right col-1"> <FormattedMessage id="generic.status" /> </Col>
                            </Row>
                        </Card>

                        <div className="verticalScroll p-1" style={{ height: '67vh' }}>
                            {customFields.map((field, key) =>
                                <Card className="shadow border-0 py-2 mb-2 overbglight pointer" body key={key} onClick={() => this.toggleModal("customFieldModal", field)}>
                                    <Row>
                                        <Col sm={3}> {field.code} </Col>
                                        <Col sm={4}> {field.description} </Col>
                                        <Col sm={2}> {field.dataType} </Col>
                                        <Col sm={2}> {field.sortOrder} </Col>
                                        <Col className="text-right col-1"> <ActiveInactiveStatusBadge status={field.active}/> </Col>
                                    </Row>
                                </Card>
                            )}
                        </div>
                    </div>                
                    : <DefaultPage text="CustomFields.NoCustomDataConfigured" icon="icon-icon-custom-fields" />}


                {customFieldModal ?
                    <CustomFieldModal
                        modal={customFieldModal}
                        toggleModal={() => this.toggleModal("customFieldModal")}
                        selectedCustomField={this.state.selectedCustomField}
                        updateCustomField={this.updateCustomField}
                    />
                : ''}
            </StyledCard>
        );
    }
}

export default injectIntl(CustomFields)