import React, { Component } from 'react'
import { Button, Card, Input, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import SalesActionModal from '../SalesActionModal';
import SalesDealModal from '../SalesDealModal/SalesDealModal';
import ConfirmActionModal from '../../Base/ConfirmActionModal';
import { putAPI, postAPI, getAPI, deleteAPI, getAPIBlob } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import SalesDealDetailProposal from './SalesDealDetailProposal';
import SalesDealDetailOverview from './SalesDealDetailOverview';
import SalesDealDetailActions from './SalesDealDetailActions';
import { ErrorAlert } from '../../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import ImportGroupResModal from '../ImportGroupResModal';
import { DealStatusBadge, SelectCustomType, checkSalesProcessPermission, getCurrentUser } from '../SalesProcessFunctions';
import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import AssociationModal from '../SalesDealModal/AssociateDeal';
import ChangeAssociationTypeModal from '../SalesDealModal/ChangeAssociationTypeModal';
import FormReplyModal from '../FormReplyModal';
import { deepClone, pmsFixedStatus } from '../../Base/ReferenceDataFunctions';
import { CommonHelper } from '../../Base/CommonUIComponents';
import { CancelProposalsModal } from './CancelProposalsModal';
import CommissionModal from './CommissionModal';



class SalesDealDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            deal: null,
            dealModal: false,
            confirmActionModal: false,
            updatedTypes: null,
            specificEditStep: null,
            actionTypesOptions: [
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Email` }), icon: 'far fa-envelope', value: 'Email' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Call` }), icon: 'fas fa-phone', value: 'Call' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Note` }), icon: 'far fa-sticky-note', value: 'Note' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.File` }), icon: 'fas fa-file', value: 'File' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Meeting` }), icon: 'far fa-building', value: 'Meeting' }
            ],
            actionStatusOptions: [
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Scheduled` }), value: 'Scheduled' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Cancelled` }), value: 'Cancelled' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.OnGoing` }), value: 'OnGoing' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Finished` }), value: 'Finished' }
            ],
            newAction: {
                type: "Note",
                title: this.props.intl.formatMessage({ id: `SalesProcess.NewNote` }),
                summary: this.props.intl.formatMessage({ id: `SalesProcess.NewNoteSummary` }),
                content: this.props.intl.formatMessage({ id: `SalesProcess.NewNoteContent` }),
                status: "Scheduled",
                createdAt: moment().format('YYYY/MM/DD'),
                startDate: moment().format('YYYY/MM/DD')
            },
            proposalDetailsCards: [
                { icon: 'icon-icon-hotel', label: this.props.intl.formatMessage({ id: `SalesDeal.Hotels` }), value: 0 },
                { icon: 'fas fa-calendar-day', label: this.props.intl.formatMessage({ id: `SalesDeal.Nights` }), value: 0 },
                { icon: 'fas fa-bed', label: this.props.intl.formatMessage({ id: `SalesDeal.RoomNights` }), value: 0 },
                { icon: 'fas fa-theater-masks', label: this.props.intl.formatMessage({ id: `SalesDeal.Events` }), value: 0 },
                { icon: 'fas fa-male', label: this.props.intl.formatMessage({ id: `SalesDeal.TotalPax` }), value: 0 },
                { icon: 'fas fa-store-alt', label: this.props.intl.formatMessage({ id: `SalesDeal.Spaces` }), value: 0 },
                { icon: 'fas fa-microphone', label: this.props.intl.formatMessage({ id: `SalesDeal.Equipment` }), value: 0 },
                { icon: 'fas fa-dollar-sign', label: this.props.intl.formatMessage({ id: `SalesDeal.TotalValue` }), value: 0, isAmount: true }
            ],
            activeActionTab: 'Note',
            actionModal: false,
            dealAssociationModal: false,
            associatedDeals: [],
            deleteDealAssociationModal: false, 
            selectedRelationId: null,
            selectedDealRelationTypeId: null,
            changeAssociationTypeModal: false,
            selectedDealId: null,
            statusReason: "",
            activeTab: 'Overview',
            customers: [],
            proposalCollapse: {},
            importGroupResModal: false,
            proposalHistoryModal: false,
            dealProposals: null,
            roles: [],
            isLead: true,
            queryString: '',
            isProposalsSorted: false,
            changeLeadToDeal: false,
            createNewAssociation: false,
            canAddProposals: false,
            canEditProposals: false,
            customStatusOptions: [],
            formReplyModal: true,
            cancelProposalsModal: false,
            commissionsModal: false,
            createDealCopyModal: false
        };
    }

    componentDidMount(){
        const queryString = window.location.search;

        this.setState({
            queryString,
        }, () => this.getDealDetail());
    }

    componentDidUpdate(prevProps){
        if (prevProps.selectedDealId !== this.props.selectedDealId && this.props.selectedDealId) {
            this.setState({ dealProposals: null }, this.getDealDetail);

            if (this.state.activeTab === 'Proposal') {
                this.getDealProposals();
            }
        }
    }

    getDealDetail = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false }, () => this.getRoles());
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.getRoles();
                }
                const deal = data.data && data.data[0];

                if(deal){
                    const blocksFromHtml = deal?.summary ? htmlToDraft(deal.summary) : "";
                    if (blocksFromHtml) {
                        const { contentBlocks, entityMap } = blocksFromHtml;
    
                        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    
                        deal.editorStateHtml = EditorState.createWithContent(contentState);
                    }
                    this.initialCalc(deal);
                    return;
                }
                
                this.setState({ block: false });
                return;
            }
            else this.setState({ error: errorMessage, block: false }, () => this.getRoles());
        }, `/api/gms/SalesProcess/v1/deal/${this.props.selectedDealId}/overview`);
    }

    updatedProposals = (data, cb) => {
        let { isProposalsSorted, customStatusOptions } = this.state;
        const  currentUser = getCurrentUser();

        var proposals = [];
                    
        if (data.data?.length > 0) {
            data.data.forEach(p => {
                const json = JSON.parse(p.proposalJSON);

                if (json.data[0].DetailsRooms?.length > 0) json.data[0].DetailsRooms = json.data[0].DetailsRooms.map(dr => ({ ...dr, isVisible: true }));
                if (json.data[0].DetailsEvents?.length > 0) json.data[0].DetailsEvents = json.data[0].DetailsEvents.map(dr => ({ ...dr, isVisible: true, Spaces: dr.Spaces?.map(sp => ({ ...sp, isVisible: true })) }));
                

                if (json.BlockData) {
                    const jsonBlock = JSON.parse(JSON.stringify(json.BlockData));
                    jsonBlock.details = jsonBlock.details.map(dr => ({ ...dr, isVisible: true }));

                    json.BlockData = jsonBlock;

                    json.groupedCategories = jsonBlock.details.reduce((acc, curr) => {
                        const group = acc.find((group) => group.categoryId === curr.CategoryId);
                        const roomNights = curr.CurrentRooms || 0;
                        const totalValue = roomNights * curr.PriceAfterTaxPerRoom_Total;

                        if (group) {
                            group.totalRooms += roomNights;
                            group.priceTotal += totalValue;
                        } else {
                            acc.push({
                                categoryId: curr.CategoryId,
                                categoryCode: curr.CategoryCode,
                                totalRooms: roomNights,
                                priceTotal: totalValue
                            });
                        }
                        return acc;
                    }, []);
                }

                p.isVisible = true;
                p.proposalJSON = json;
                
                proposals.push(p);
            });

            if (proposals && proposals.length > 0 && !isProposalsSorted) {
                proposals = proposals.sort((a, b) => {
                    if (a.isOption === false && b.isOption === true) {
                        return -1;
                    }
                    if (a.isOption === true && b.isOption === false) {
                        return 1;
                    }
                    return 0;
                });
                isProposalsSorted = true;
            }
        }

        this.setState({
            dealProposals: proposals?.map((p) => ({
                ...p,
                statusDesc: customStatusOptions.find(({value}) => value === p.pmsStatus)?.label ?? p.pmsStatus,
                editableByCurrentUser: checkSalesProcessPermission('SalesDealDetail:proposal', currentUser?.role, [p?.hotelId], currentUser?.hotelIds, null, currentUser?.value)
            })),
            block: false,
            isProposalsSorted
        }, () => {
            if(cb){
                cb(this.state.dealProposals);
            }
        });
    }

    updateDealProposal = (proposalId, data) => {
        const { dealProposals, customStatusOptions } = this.state;
        const index = dealProposals.findIndex(el => el.id === proposalId);

        if (index !== -1) {
            dealProposals[index].pmsStatus = data.statusCode;
            dealProposals[index].statusDesc = customStatusOptions.find(({ value }) => value === data.statusCode)?.label ?? data.statusCode;
            dealProposals[index].isCancelled = true;
        }

        this.setState({ dealProposals });
    }

    getDealProposals = (cb) => {
        if(!this.props.selectedDealId){
            this.setState({ dealProposals: [], block: false });
            return;
        }
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    this.updatedProposals(data, cb);
                }
                else this.setState({ dealProposals: [], error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/deal/${this.props.selectedDealId}/proposals`);
        })
    }

    getTypesList = () => {
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                    }
                    if (data.data?.length) {
                        const updatedTypes = data.data
                            .filter(x => x.fixedValue === "Lost" && x.mappingCodes != null) 
                            .flatMap(item =>
                                item.mappingCodes.map(code => ({ value: code, label: code })) 
                            );

                        this.setState({ updatedTypes, error: errorMessage, block: false });
                        return;
                    }
                }
            }, `/api/gms/SalesProcess/v1/customtypes?type=PipelineStageType`)
        })
    }

    initialCalc = (deal) => {
        const currentUser = getCurrentUser();
        const canAddProposals = checkSalesProcessPermission('SalesDealDetail:proposal', currentUser?.role, deal?.hotelIds, currentUser?.hotelIds, null, currentUser?.value);
        const canSeeDealDetail = checkSalesProcessPermission('SalesDeal:detail', currentUser?.role, deal?.hotelIds, currentUser?.hotelIds, deal?.salesDealUser, currentUser?.value);

        if(!canSeeDealDetail){
            this.setState({ block: false }, () => {
                handleNotification({warnings: [{message: <FormattedMessage id="SalesProcess.NoPermissionToSeeDeal" />, code: <FormattedMessage id="SalesProcess.NoPermission" />}]});
                this.props.returnToPreviousPage();
            });
            return
        }
        const customers = [];
        const associatedDeals = [];

        if(deal.customer){
            customers.push({
                value: deal.customer.id,
                label: deal.customer.name,
                ...deal.customer
            });
        }
        if(deal.mainContact){
            customers.push({
                value: deal.mainContact.id,
                label: deal.mainContact.name,
                ...deal.mainContact
            });
        }
        if(deal.salesDealProfile){
            deal.salesDealProfile.forEach(p => {
                customers.push({
                    value: p.profileId,
                    label: p.name,
                    ...p
                });
            });
        }

        if (deal?.relatedInitiatives) {
            deal.relatedInitiatives.forEach(d => {
                if (d.relationName && d.relationId !== null && d.relationId !== undefined) {
                    const associationExists = associatedDeals.find(ad => ad.relationId === d.relationId)
                    if (!associationExists) {
                        associatedDeals.push({
                            relationId: d.relationId,
                            relationName: d.relationName,
                            fixedValue: 'Initiative',
                            deals: [{ ...d, isInitiative: true }]
                        });
                    } else {
                        associatedDeals.find(ad => ad.relationId === d.relationId).deals.push({ ...d, isInitiative: true });
                    }
                }
            });
        }

        if(deal?.relatedDeals){
            deal.relatedDeals.forEach(d => {
                if(d.relationName && d.relationId !== null && d.relationId !== undefined){
                    const associationExists = associatedDeals.find(ad => ad.relationId === d.relationId)

                    if(!associationExists){
                        associatedDeals.push({
                            relationId: d.relationId,
                            relationName: d.relationName,
                            fixedValue: d.relatedFixedValue,
                            deals: [{ ...d, isInitiative: false }]
                        });
                    }else{
                        associatedDeals.find(ad => ad.relationId === d.relationId).deals.push({ ...d, isInitiative: false });
                    }
                }
            });
        }

        const isLead = !deal?.salesPipelineStageId;

        this.setState({ customers, isLead, deal, block: false, associatedDeals, canAddProposals }, () => this.getRoles());
    }

    changeDealStatus = (e, status, changeStage) => {
        e.preventDefault();

        if (status) {
            this.setState({ block: true }, () => {
                putAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];
                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            handleNotification(data);
                        }
                        if (data.data && data.data.length > 0) {
                            handleNotification(data, <FormattedMessage id="SalesDeal.StatusChanged" />, <FormattedMessage id="generic.success" />);

                            const { deal } = this.state;

                            if (!deal.salesDealActions) deal.salesDealActions = [];

                            deal.salesDealActions.unshift(data.data[0].salesDealActions[0]);
                            deal.statusReason = data.data[0].statusReason;
                            deal.status = status;

                            this.toggleConfirmActionModal();
                            this.setState({ deal, error: errorMessage, block: false, cancelProposalsModal: status === 'Cancelled' });

                            return;
                        }   
                    }
                    this.toggleConfirmActionModal();
                    this.setState({ error: errorMessage, block: false });
                }, `/api/gms/SalesProcess/v1/deals/${this.state.deal.id}/status/${status}?ChangeStage=${changeStage || false}`, this.state.deal);
            });
        }        
    }

    updateActionStatus = (e, id, status, cb) => {
        this.setState({ block: true }, () => {
            const { deal } = this.state;

            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    
                    if(cb){
                        cb();
                    }
            
                    const fIndex = deal.salesDealFocusedActions.findIndex(el => el.id === id);

                    if (fIndex !== -1) {
                        if(data.data && data.data.length > 0){
                            if (status === 'OnGoing') {
                                const action = data.data[0];
                                deal.salesDealFocusedActions[fIndex] = action;
                            } else {
                                deal.salesDealFocusedActions.splice(fIndex, 1);
                            }
                        }else{
                            deal.salesDealFocusedActions.splice(fIndex, 1);
                        }
                    }

                    this.setState({
                        block: false,
                        confirmActionModal: false,
                        selectedAction: null,
                        changeToStatus: null,
                        deal
                    }, () => this.getDealDetail());
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/deals/action/${id}/status/${status}`);
        })
    }

    toggleModal = (modalName, propName, propValue) => {
        this.setState(prevState => ({
            [modalName]: !prevState[modalName],
            [propName]: propValue
        }))
    }

    toggleEdit = (specificEditStep) => {
        this.setState((prevState) => ({
            dealModal: !prevState.dealModal,
            specificEditStep: isNaN(specificEditStep) ? null : specificEditStep,
            changeLeadToDeal: this.state.dealModal ? false : prevState.changeLeadToDeal,
            createNewAssociation: this.state.dealModal ? false : prevState.createNewAssociation,
            createNewAssociatedLead: this.state.dealModal ? false : prevState.createNewAssociatedLead
        }));
    };

    toggleActionModal = (action) => {
        this.setState({
            actionModal: !this.state.actionModal,
            selectedAction: action
        })
    }

    toggleDealAssociationModal = () => {
        this.setState({ dealAssociationModal: !this.state.dealAssociationModal });
    }

    toggleConfirmActionModal = (action, status) => {
        this.setState({
            confirmActionModal: !this.state.confirmActionModal,
            selectedAction: action,
            changeToStatus: status,
            statusOptions: false,
            statusOptions2: false
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'cancelReasonType') {
            this.setState({
                deal: {
                    ...this.state.deal,
                    cancelReason: value ? value.label : null,
                    cancelReasonId: value ? value.value : null
                }
            })
            return;
        }

        this.setState({
            deal: {
                ...this.state.deal,
                [name]: value
            }
        })
    }

    toggleTab = (tab) => {
        if(tab !== this.state.activeTab)
            this.setState({ activeTab: tab });
    }

    updateDeal = (deal, updateCustomers) => {
        if (updateCustomers) {
            this.getDealDetail();
            return;
        }
        this.setState({ deal });
    }

    downloadAction = (e, action) => {
        if (e) e.preventDefault();

        this.setState({ block: true }, () => {
            getAPIBlob(result => {
                const { data, error, headers } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    if (data) {
                        const a = document.createElement("a");
                        //a.href = `${data.response[0].contentType}${data.response[0].fileBase64}`;
                        var blob = new Blob([data], { type: headers["content-type"] });
                        var url = window.URL.createObjectURL(blob);
                        a.href = url;
                        var f = headers["content-disposition"].match("filename=([^;]*);")
                        a.download = f != null ? f[1] : "file";
                        a.click();

                        this.setState({ block: false });
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/deals/action/${action.id}/file`);
        });
    }

    changeActionPin = (e, action) => {
        if(e) e.preventDefault();

        if(action){
            action.pinned = !action.pinned;
            this.setState({ block: true }, () => {
                const { deal } = this.state;

                postAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];

                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data && data.errors && data.errors.length > 0) {
                            handleNotification(data);
                            this.setState({ block: false });
                            return;
                        }

                        if (deal) {
                            if (!deal.salesDealActions)
                                deal.salesDealActions = [];
                            
                            if(action.pinned){ // if pinned == true add to focused actions and remove from action list
                                deal.salesDealFocusedActions.unshift(action);
                                
                                const actionIndex = deal.salesDealActions.findIndex(el => el.id === action.id);

                                if (actionIndex !== -1)    
                                    deal.salesDealActions.splice(actionIndex, 1);
                            }
                            else{ // if pinned == false remove from focused actions and update action list

                                const actionIndex = deal.salesDealActions.findIndex(el => el.id === action.id);

                                if (actionIndex !== -1)    
                                    deal.salesDealActions[actionIndex] = action;

                                const fIndex = deal.salesDealFocusedActions.findIndex(el => el.id === action.id);
                                
                                if (fIndex !== -1)
                                    deal.salesDealFocusedActions.splice(fIndex, 1);
                            }

                            this.setState({
                                deal,
                                block: false
                            }, () => handleNotification(data, <FormattedMessage id="SalesDeal.ActionSavedSuccessfully" />, <FormattedMessage id="generic.success" />));
                        }else{
                            this.setState({ block: false });
                        }
                        return;
                    }
                    else this.setState({ error: errorMessage, block: false });
                }, `/api/gms/SalesProcess/v1/deals/action`, { ...action });
            })
        }
    }

    toggleTabandModal = () => {
        this.setState({ activeTab: 'Proposal', importGroupResModal: true });
    }

    toggleImportGroupResModal = () => {
        this.setState({ importGroupResModal: !this.state.importGroupResModal });
    }
    
    importGroupRes = (selectedGroupResId, hotelId, proposalId, type) => {
        this.setState({ block: true }, () => {
            const { deal } = this.state;

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    handleNotification(data, <FormattedMessage id="SalesDeal.ReservationImported" />, <FormattedMessage id="generic.success" />);

                    this.setState({ block: false }, () => {
                        if (!proposalId) {
                            this.toggleImportGroupResModal();
                        }

                        this.getDealProposals();
                        this.getDealDetail();
                    });

                    return;
                }
                else
                    this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/Proposal/Import?externalId=${selectedGroupResId}&hotelId=${hotelId}&salesDealId=${deal.id}${proposalId ? `&salesDealProposalId=${proposalId}` : ''}${type ? `&type=${type}` : ''}`);
        });
    }

    setProposalDetailsCards = (proposalDetailsCards) => {
        this.setState({ proposalDetailsCards });
    }

    getRoles = () => {
        this.setState({ block: true }, () =>
            getAPI((result) => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({
                        message: error.message,
                        stack: error.stack,
                        messageType: "danger",
                    });
                    this.setState({ error: errorMessage }, () => this.getReferenceDataCustomStatus());
                    return;
                }
                if (data) {
                    if (data.data && data.data.length > 0) {
                        this.setState({ roles: data.data }, () => this.getReferenceDataCustomStatus());
                        return;
                    }else if(data.errors && data.errors.length > 0){
                        handleNotification(data);
                        this.getReferenceDataCustomStatus();
                        return;
                    }
                }
                this.setState({ error: errorMessage }, () => this.getReferenceDataCustomStatus());
            }, `/api/gms/SalesProcess/v1/customtypes?type=DealProfileRole`)
        );
    }

    getReferenceDataCustomStatus = () => {
        getAPI(result => {
            const { data, error } = result;

            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({
                    customStatusOptions: data.response?.map(({description, code, hotelId}) => ({
                        value: code,
                        hotelId,
                        label: (
                            <span>
                                {description}
                                <b className="ml-1">({code})</b>
                            </span>
                        )
                    })).concat(pmsFixedStatus(this.props.intl)),
                    block: false
                });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Hotel/v1/ReferenceData?type=ReservationStatus`)
    }

    associateDeal = (e, dealAId, dealBId, relationId, salesDealRelationId) => {

        if(e) e.preventDefault();
        const body = {
            dealAId,
            dealBId,
            relationId,
            id: salesDealRelationId ?? null
        }

        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    if (data.data && data.data.length > 0) {
                        this.updateAssociation();
                        return;
                    }

                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/dealrelation`, body);
        });
    }

    associateInitiative = (e, dealId, initiativeId, relationId, salesInitiativeRelationId) => {
        if (e) e.preventDefault();

        const body = {
            dealId,
            initiativeId,
            relationId,
            id: salesInitiativeRelationId ?? null
        }

        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    if (data.data && data.data.length > 0) {
                        this.updateAssociation();
                        return;
                    }

                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/initiativerelation`, body);
        });
    }

    updateAssociation = () => {
        this.setState({
            dealAssociationModal: false,
            changeAssociationTypeModal: false,
            selectedDealRelationTypeId: null,
            selectedRelationId: null,
            selectedDealId: null,
            selectedInitiativeId: null
        }, () => this.getDealDetail());
    }

    deleteDealAssociation = (salesDealRelationId) => {
        this.setState({ block: true }, () => {
            const { deal } = this.state;

            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    } else {
                        this.setState({ deleteDealAssociationModal: false, selectedRelationId: null }, () => this.getDealDetail());
                    }

                    this.setState({ deal, block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/dealrelation/${salesDealRelationId}`);
        });
    }

    deleteInitiativeAssociation = (salesDealRelationId) => {
        this.setState({ block: true }, () => {
            const { deal } = this.state;

            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    } else {
                        this.setState({ deleteDealAssociationModal: false, selectedRelationId: null, deletedRelationIsInitiative: null }, () => this.getDealDetail());
                    }

                    this.setState({ deal, block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/initiativerelation/${salesDealRelationId}`);
        });
    }

    switchDealToLead = () => {
        this.setState({ block: true }, () => {
            const dealToLead = this.convertDealToLeadData();

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    else {
                        this.props.selectDealDetail(dealToLead.id, "SalesLeads");

                        this.toggleConfirmChangeDealToLead();
                        this.getDealDetail();

                        handleNotification(data, <FormattedMessage id="SalesDeal.SavedSuccessfully" />, <FormattedMessage id="generic.success" />);

                    }

                    this.setState({ block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/deals`, { ...dealToLead });
        })
    }

    convertDealToLeadData = () => {
        const dealToLead = { ...this.state.deal };

        dealToLead.salesPipelineId = null;
        dealToLead.salesPipelineStage = null;
        dealToLead.salesPipelineStageId = null;

        return dealToLead;
    }

    toggleDeleteDealAssociationModal = (salesDealRelationId, deletedRelationIsInitiative) => {
        this.setState({ deleteDealAssociationModal: !this.state.deleteDealAssociationModal, selectedRelationId: salesDealRelationId, deletedRelationIsInitiative });
    }

    toggleChangeAssociationTypeModal = (selectedRelationId, selectedRelationTypeId, selectedDealId, selectedInitiativeId, selectedRelationIsInitiative) => {
        this.setState({
            changeAssociationTypeModal: !this.state.changeAssociationTypeModal, selectedRelationId,
            selectedRelationTypeId, selectedDealId, selectedInitiativeId, selectedRelationIsInitiative
        });
    }

    saveAssociation = (e, relationType) => {
        const { deal, selectedDealId, selectedRelationId, selectedInitiativeId } = this.state;

        if (this.state.selectedRelationIsInitiative) {
            this.associateInitiative(e, selectedDealId, selectedInitiativeId, relationType, selectedRelationId);
        }
        else {
            this.associateDeal(e, deal?.id, selectedDealId, relationType, selectedRelationId);
        }
    }

    toggleFormReplyModal = () => {
        this.setState({ formReplyModal: !this.state.formReplyModal });
    }

    updateCommissions = (data) => {
        this.setState(prevState => ({
            deal: {
                ...prevState.deal,
                salesDealProfileCommissions: data || []
            }
        }))
    }

    copyDeal = () => {
        const { deal } = this.state;
        const newDealCopy = deepClone(deal);
        newDealCopy.salesPipelineId = newDealCopy.salesPipelineStage?.salesPipelineId;

        if (newDealCopy.salesDealProfile?.length > 0) {
            newDealCopy.salesDealProfile = newDealCopy.salesDealProfile.map(({ name, profileId, roleId }) => ({ name, profileId, roleId }))
        }
        if (newDealCopy.salesDealUser?.length > 0) {
            newDealCopy.salesDealUser = newDealCopy.salesDealUser.map(({ name, role, userId }) => ({ name, role, userId }))
        }

        delete newDealCopy.id;
        delete newDealCopy.salesDealProfileCommissions;
        delete newDealCopy.dealProposalData;
        delete newDealCopy.salesDealFocusedActions;

        const blocksFromHtml = newDealCopy?.summary ? htmlToDraft(newDealCopy.summary) : "";

        if (blocksFromHtml) {
            const { contentBlocks, entityMap } = blocksFromHtml;

            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

            newDealCopy.editorStateHtml = EditorState.createWithContent(contentState);
        }

        this.setState({
            newDealCopy,
            createDealCopyModal: true
        })
    }

    goToNewDealDetails = (deal) => {
        this.props.selectDealDetail(deal.id);
    }

    toggleConfirmChangeDealToLead = () => {
        this.setState({ confirmChangeToLeadModal: !this.state.confirmChangeToLeadModal });
    }

    createAssociatedLead = () => {
        const newAssociatedLeadData = this.convertDealToLeadData();
        

        this.setState({ changeLeadToDeal: false, createNewAssociatedLead: true, newAssociatedLeadData },
            () => this.toggleEdit())
    }
    


    render() {
        const { deal, block, error, selectedAction, confirmActionModal, actionModal, dealModal, changeToStatus, activeTab, customers, specificEditStep, confirmChangeToLeadModal, createNewAssociatedLead,
            importGroupResModal, roles, associatedDeals, dealAssociationModal, deleteDealAssociationModal, selectedRelationId, changeAssociationTypeModal, createDealCopyModal,
            selectedDealRelationTypeId, isLead, changeLeadToDeal, createNewAssociation, canAddProposals, formReplyModal, cancelProposalsModal, commissionsModal, updatedTypes } = this.state;
        const { selectDealDetail, salesSources, salesUsers, salesPipelines, tempDeal, salesSourcesTypes, dealList, getDealList, currentUser, selectInitiativeDetail, returnToPreviousPage, hotelList, renderActivityHelperMsg, totalDeals } = this.props;

        const selectedPipeline = salesPipelines?.find(({ stages }) => stages?.some(({ value }) => value === deal?.salesPipelineStage?.id));
        const source = salesSources?.find(s => s.value === deal?.salesSourceId);
        const hotelCurrency = global.hotelList?.find(x => x.value == deal?.hotelIds?.[0])?.currency ?? global.hotelCurrency;

        
        return (
            <>
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <Card className='border-0 shadow px-3 pb-3 pt-3 bg-white' style={{ minHeight: '90vh' }}>
                        {salesSources?.length && salesUsers?.length && salesPipelines?.length ?
                            <div>
                                {(() => {
                                    const dealData = deal ?? tempDeal;
                                    return (
                                        dealData ?
                                            <>
                                                <Row className='d-flex align-items-center justify-content-between'>
                                                    <Col className={`d-flex align-items-center justify-content-start col-9`}>
                                                        <div onClick={_ => returnToPreviousPage()} className='p-3 cursor-pointer'>
                                                            <i className='fas fa-chevron-left' />
                                                        </div>
                                                        <div className='ml-2'>
                                                            <div className="d-flex align-items-center">
                                                                <h5 className='m-0'>
                                                                    {dealData.name}
                                                                </h5>
                                                                <div className="ml-4">
                                                                    <DealStatusBadge status={dealData.status} isLead={isLead} />
                                                                </div>
                                                            </div>
                                                            {!isLead ?
                                                                <div className='mt-1'>
                                                                    <span className='text-muted hover-underline'>
                                                                        <Link to={{ pathname: "/ProfileDetails/" + dealData.customer?.id }}>
                                                                            {dealData.customer?.name}
                                                                        </Link>
                                                                    </span>
                                                                </div>
                                                                : ''}
                                                        </div>
                                                    </Col>

                                                    <Col className='d-flex align-items-center justify-content-end'>
                                                        <div>
                                                            {dealData.status !== "Finished" && dealData.status !== "Cancelled" ?
                                                                <>
                                                                    {global.isMobile ? '' :
                                                                        <>
                                                                            <Button onClick={_ => this.toggleConfirmActionModal(null, dealData.status !== "Finished" && dealData.status !== "Effective" ? 'Finished' : isLead ? 'Cancelled' : 'WaitingHotel')}
                                                                                className="btn btn-host btn-sm"
                                                                                style={{
                                                                                    background: dealData.status !== "Finished" && dealData.status !== "Effective" ? '#36ad51' : isLead ? '#0665ff' : '',
                                                                                    borderColor: dealData.status !== "Finished" && dealData.status !== "Effective" ? '#36ad51' : isLead ? '#0665ff' : '',
                                                                                    borderRadius: '0.2rem 0 0 0.2rem'
                                                                                }}>
                                                                                {isLead ?
                                                                                    dealData.status !== "Finished" ?
                                                                                        <FormattedMessage id="SalesDeal.FinnishLead" />
                                                                                        :
                                                                                        <FormattedMessage id="SalesProcess.SetOnGoing" />
                                                                                    :
                                                                                    dealData.status === "Finished" || dealData.status === "Effective" ?
                                                                                        <FormattedMessage id="ReferenceDataFunctions.WaitingHotel" />
                                                                                        :
                                                                                        <FormattedMessage id="SalesDeal.WinDeal" />
                                                                                }

                                                                                {(!isLead && dealData.status !== "Effective") || dealData.status !== "Finished" ?
                                                                                    <i className="fas fa-trophy ml-2"></i>
                                                                                    :
                                                                                    <i className="fas fa-handshake-slash ml-2" />
                                                                                }
                                                                            </Button>
                                                                            <Button onClick={_ => this.setState({ statusOptions: !this.state.statusOptions })} className="btn btn-host btn-sm" id="StatusOptions" style={{ borderRadius: '0 0.2rem 0.2rem 0', background: '#bfbfbf', border: '1px solid #bfbfbf' }}>
                                                                                <i className='fas fa-chevron-down' />
                                                                            </Button>
                                                                        </>
                                                                    }
                                                                </>
                                                                :
                                                                <i id="StatusOptions" className='fas fa-ellipsis-v pointer' onClick={_ => this.setState({ statusOptions: !this.state.statusOptions })} />
                                                            }
                                                            {this.state.statusOptions ?
                                                                <div style={{ borderRadius: '5px', zIndex: '1050', right: '0', width: 'max-content', fontSize: '0.95em' }} className='shadow bg-white mt-1 position-absolute'>
                                                                    <div>
                                                                        {!isLead ?
                                                                            <>
                                                                                <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer' onClick={_ => this.createAssociatedLead()}>
                                                                                    <i className="fas fa-plus mr-2" />
                                                                                    <FormattedMessage id="SalesDeal.CreateAssociatedLead" />
                                                                                </div>

                                                                                <hr className='m-0' />

                                                                                <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer' onClick={_ => this.copyDeal()}>
                                                                                    <i className="far fa-clone mr-2" />
                                                                                    <FormattedMessage id='SalesDeal.CopyDeal' />
                                                                                </div>

                                                                                <hr className='m-0' />

                                                                                {!dealData.dealProposalData ?
                                                                                    <>
                                                                                        <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer' onClick={_ => this.toggleConfirmChangeDealToLead()}>
                                                                                            <i className="fas fa-exchange-alt mr-2" />
                                                                                            <FormattedMessage id="SalesDeal.SwitchToLead" />
                                                                                        </div>
                                                                                        <hr className='m-0' />
                                                                                    </>
                                                                                :''}

                                                                                {dealData.status !== "WaitingHotel" && dealData.status !== "Effective" ?
                                                                                    <>
                                                                                        <div className='changeStatusOption p-2 d-flex align-items-center cursor-pointer' onClick={_ => this.toggleConfirmActionModal(null, 'WaitingHotel')}>
                                                                                            <i className="fas fa-users mr-2" />
                                                                                            <FormattedMessage id="ReferenceDataFunctions.WaitingHotel" />
                                                                                        </div>
                                                                                    </>
                                                                                    : ''}

                                                                                {dealData.status !== "WaitingClient" ?
                                                                                    <>
                                                                                        <div className='changeStatusOption p-2 d-flex align-items-center cursor-pointer' onClick={_ => this.toggleConfirmActionModal(null, 'WaitingClient')}>
                                                                                            <i className="icon-icon-hotel mr-2" />
                                                                                            <FormattedMessage id="ReferenceDataFunctions.WaitingClient" />
                                                                                        </div>
                                                                                    </>
                                                                                    : ''}
                                                                            </>
                                                                            : ''}
                                                                        {isLead ?
                                                                            <>
                                                                                <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer' onClick={_ => this.setState({ changeLeadToDeal: false, createNewAssociation: true }, () => this.toggleEdit())}>
                                                                                    <i className="fas fa-plus mr-2" />
                                                                                    <FormattedMessage id="SalesDeal.CreateAssociatedDeal" />
                                                                                </div>

                                                                                <hr className='m-0' />

                                                                                <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer' onClick={_ => this.copyDeal()}>
                                                                                    <i className="far fa-clone mr-2" />
                                                                                    <FormattedMessage id='SalesDeal.CopyLead' />
                                                                                </div>

                                                                                <hr className='m-0' />

                                                                                <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer' onClick={_ => this.setState({ changeLeadToDeal: true, createNewAssociation: false }, () => this.toggleEdit())}>
                                                                                    <i className="fas fa-exchange-alt mr-2" />
                                                                                    <FormattedMessage id="SalesDeal.SwitchDeal" />
                                                                                </div>

                                                                                <hr className='m-0' />

                                                                                {dealData.status === 'Finished' || dealData.status === 'Cancelled' ?
                                                                                    <>

                                                                                        <hr className='m-0' />
                                                                                        <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer' onClick={_ => this.toggleConfirmActionModal(null, 'WaitingClient')} style={{ color: '#0665ff' }}>
                                                                                            <i className="fas fa-play mr-2" />
                                                                                            <FormattedMessage id="SalesProcess.SetOnGoing" />
                                                                                        </div>
                                                                                        {dealData.status !== 'Finished' ?
                                                                                            <>
                                                                                                <hr className='m-0' />
                                                                                                <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer text-success' onClick={_ => this.toggleConfirmActionModal(null, 'Finished')}>
                                                                                                    <i className="fas fa-trophy mr-2" />
                                                                                                    <FormattedMessage id="SalesDeal.FinnishLead" />
                                                                                                </div>
                                                                                            </>
                                                                                            : ''}
                                                                                    </>
                                                                                    : ''}
                                                                            </>
                                                                            : ''}
                                                                        {dealData.status !== "Cancelled" ?
                                                                            <>
                                                                                <hr className='m-0' />
                                                                                <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start text-danger cursor-pointer' onClick={_ => this.toggleConfirmActionModal(null, 'Cancelled')}>
                                                                                    <i className="fas fa-handshake-slash mr-2" />
                                                                                    {isLead ?
                                                                                        <FormattedMessage id="SalesDeal.CancelLead" />
                                                                                        :
                                                                                        <FormattedMessage id="SalesDeal.LoseDeal" />
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                            : ''}
                                                                    </div>
                                                                </div>
                                                                : ''}
                                                        </div>
                                                        {!isLead ?
                                                            <div className='ml-2'>
                                                                <CommonHelper help={<FormattedMessage id="SalesDeal.Help" />} id={'DealDetailsHelp'} />
                                                            </div>
                                                            : ''}
                                                    </Col>
                                                </Row>
                                                {global.isMobile ?
                                                    <Row>
                                                        <Col className='d-flex align-items-center justify-content-end'>
                                                            <div>
                                                                {dealData.status !== "Finished" && dealData.status !== "Cancelled" ?
                                                                    <>
                                                                        <Button onClick={_ => this.toggleConfirmActionModal(null, dealData.status !== "Finished" && dealData.status !== "Effective" ? 'Finished' : isLead ? 'Cancelled' : 'WaitingHotel')}
                                                                            className="btn btn-host btn-sm"
                                                                            style={{
                                                                                background: dealData.status !== "Finished" && dealData.status !== "Effective" ? '#36ad51' : isLead ? '#0665ff' : '',
                                                                                borderColor: dealData.status !== "Finished" && dealData.status !== "Effective" ? '#36ad51' : isLead ? '#0665ff' : '',
                                                                                borderRadius: '0.2rem 0 0 0.2rem'
                                                                            }}>
                                                                            {isLead ?
                                                                                dealData.status !== "Finished" ?
                                                                                    <FormattedMessage id="SalesDeal.FinnishLead" />
                                                                                    :
                                                                                    <FormattedMessage id="SalesProcess.SetOnGoing" />
                                                                                :
                                                                                dealData.status === "Finished" || dealData.status === "Effective" ?
                                                                                    <FormattedMessage id="ReferenceDataFunctions.WaitingHotel" />
                                                                                    :
                                                                                    <FormattedMessage id="SalesDeal.WinDeal" />
                                                                            }

                                                                            {(!isLead && dealData.status !== "Effective") || dealData.status !== "Finished" ?
                                                                                <i className="fas fa-trophy ml-2"></i>
                                                                                :
                                                                                <i className="fas fa-handshake-slash ml-2" />
                                                                            }
                                                                        </Button>
                                                                        <Button onClick={_ => this.setState({ statusOptions2: !this.state.statusOptions2 })} className="btn btn-host btn-sm" id="StatusOptions2" style={{ borderRadius: '0 0.2rem 0.2rem 0', background: '#bfbfbf', border: '1px solid #bfbfbf' }}>
                                                                            <i className='fas fa-chevron-down' />
                                                                        </Button>
                                                                    </>
                                                                    : ''
                                                                }
                                                                {this.state.statusOptions2 ?
                                                                    <div style={{ borderRadius: '5px', zIndex: '1050', right: '0', width: 'max-content', fontSize: '0.95em' }} className='shadow bg-white mt-1 position-absolute'>
                                                                        <div>
                                                                            {!isLead ?
                                                                                <>
                                                                                    <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer' onClick={_ => this.createAssociatedLead()}>
                                                                                        <i className="fas fa-plus mr-2" />
                                                                                        <FormattedMessage id="SalesDeal.CreateAssociatedLead" />
                                                                                    </div>

                                                                                    <hr className='m-0' />

                                                                                    <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer' onClick={_ => this.copyDeal()}>
                                                                                        <i className="far fa-clone mr-2" />
                                                                                        <FormattedMessage id='SalesDeal.CopyDeal' />
                                                                                    </div>

                                                                                    <hr className='m-0' />

                                                                                    {!dealData.dealProposalData ?
                                                                                        <>
                                                                                            <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer' onClick={_ => this.toggleConfirmChangeDealToLead()}>
                                                                                                <i className="fas fa-exchange-alt mr-2" />
                                                                                                <FormattedMessage id="SalesDeal.SwitchToLead" />
                                                                                            </div>
                                                                                            <hr className='m-0' />
                                                                                        </>
                                                                                    :''}

                                                                                    {dealData.status !== "WaitingHotel" && dealData.status !== "Effective" ?
                                                                                        <>
                                                                                            <div className='changeStatusOption p-2 d-flex align-items-center cursor-pointer' onClick={_ => this.toggleConfirmActionModal(null, 'WaitingHotel')}>
                                                                                                <i className="fas fa-users mr-2" />
                                                                                                <FormattedMessage id="ReferenceDataFunctions.WaitingHotel" />
                                                                                            </div>
                                                                                        </>
                                                                                        : ''}

                                                                                    {dealData.status !== "WaitingClient" ?
                                                                                        <>
                                                                                            <div className='changeStatusOption p-2 d-flex align-items-center cursor-pointer' onClick={_ => this.toggleConfirmActionModal(null, 'WaitingClient')}>
                                                                                                <i className="icon-icon-hotel mr-2" />
                                                                                                <FormattedMessage id="ReferenceDataFunctions.WaitingClient" />
                                                                                            </div>
                                                                                        </>
                                                                                        : ''}
                                                                                </>
                                                                                : ''}
                                                                            {isLead ?
                                                                                <>
                                                                                    <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer' onClick={_ => this.setState({ changeLeadToDeal: false, createNewAssociation: true }, () => this.toggleEdit())}>
                                                                                        <i className="fas fa-plus mr-2" />
                                                                                        <FormattedMessage id="SalesDeal.CreateAssociatedDeal" />
                                                                                    </div>

                                                                                    <hr className='m-0' />

                                                                                    <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer' onClick={_ => this.copyDeal()}>
                                                                                        <i className="far fa-clone mr-2" />
                                                                                        <FormattedMessage id='SalesDeal.CopyLead' />
                                                                                    </div>

                                                                                    <hr className='m-0' />

                                                                                    <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer' onClick={_ => this.setState({ changeLeadToDeal: true, createNewAssociation: false }, () => this.toggleEdit())}>
                                                                                        <i className="fas fa-exchange-alt mr-2" />
                                                                                        <FormattedMessage id="SalesDeal.SwitchDeal" />
                                                                                    </div>

                                                                                    <hr className='m-0' />

                                                                                    {dealData.status === 'Finished' || dealData.status === 'Cancelled' ?
                                                                                        <>

                                                                                            <hr className='m-0' />
                                                                                            <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer' onClick={_ => this.toggleConfirmActionModal(null, 'WaitingClient')} style={{ color: '#0665ff' }}>
                                                                                                <i className="fas fa-play mr-2" />
                                                                                                <FormattedMessage id="SalesProcess.SetOnGoing" />
                                                                                            </div>
                                                                                            {dealData.status !== 'Finished' ?
                                                                                                <>
                                                                                                    <hr className='m-0' />
                                                                                                    <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start cursor-pointer text-success' onClick={_ => this.toggleConfirmActionModal(null, 'Finished')}>
                                                                                                        <i className="fas fa-trophy mr-2" />
                                                                                                        <FormattedMessage id="SalesDeal.FinnishLead" />
                                                                                                    </div>
                                                                                                </>
                                                                                                : ''}
                                                                                        </>
                                                                                        : ''}
                                                                                </>
                                                                                : ''}
                                                                            {dealData.status !== "Cancelled" ?
                                                                                <>
                                                                                    <hr className='m-0' />
                                                                                    <div className='changeStatusOption p-2 d-flex align-items-center justify-content-start text-danger cursor-pointer' onClick={_ => this.toggleConfirmActionModal(null, 'Cancelled')}>
                                                                                        <i className="fas fa-handshake-slash mr-2" />
                                                                                        {isLead ?
                                                                                            <FormattedMessage id="SalesDeal.CancelLead" />
                                                                                            :
                                                                                            <FormattedMessage id="SalesDeal.LoseDeal" />
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                                : ''}
                                                                        </div>
                                                                    </div>
                                                                    : ''}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    : ''}
                                        </>

                                        :''
                                    );
                                })()}
                                <Nav tabs className={`${isLead ? 'mt-2 mb-3' : 'my-3'}`}>
                                    <NavItem>
                                        <NavLink className={activeTab === 'Overview' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('Overview')} style={{ cursor: "pointer" }}>
                                            <i className="fas fa-briefcase mr-2"/>
                                            <FormattedMessage id="SalesDeal.Overview"/>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={activeTab === 'Actions' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('Actions')} style={{ cursor: "pointer" }}>
                                            <i className="fas fa-play mr-2"/>
                                            <FormattedMessage id="SalesDeal.Actions"/>
                                        </NavLink>
                                    </NavItem>
                                    {!isLead ?
                                        <NavItem>
                                            <NavLink className={activeTab === 'Proposal' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleTab('Proposal')} style={{ cursor: "pointer" }}>
                                                <i className="fas fa-receipt mr-2"/>
                                                <FormattedMessage id="SalesDeal.Proposal"/>
                                            </NavLink>
                                        </NavItem>
                                    :''}
                                </Nav>
                                {deal ?
                                    <div>
                                        {activeTab === 'Overview' ?
                                            <SalesDealDetailOverview
                                                toggleFormReplyModal={this.toggleFormReplyModal}
                                                isLead={isLead}
                                                source={source}
                                                selectedPipeline={selectedPipeline}
                                                deal={deal}
                                                roles={roles}
                                                associatedDeals={associatedDeals}
                                                selectDealDetail={selectDealDetail}
                                                selectInitiativeDetail={selectInitiativeDetail}
                                                toggleDeleteDealAssociationModal={this.toggleDeleteDealAssociationModal}
                                                toggleDealAssociationModal={this.toggleDealAssociationModal}
                                                toggleChangeAssociationTypeModal={this.toggleChangeAssociationTypeModal}
                                                toggleActionModal={this.toggleActionModal}
                                                toggleEdit={this.toggleEdit}
                                                toggleConfirmActionModal={this.toggleConfirmActionModal}
                                                changeActionPin={this.changeActionPin}
                                                downloadAction={this.downloadAction}
                                                customers={customers}
                                                salesUsers={salesUsers}
                                                focusActions={deal?.salesDealFocusedActions}
                                                toggleTabandModal={this.toggleTabandModal}
                                                canAddProposals={canAddProposals}
                                                hotelCurrency={hotelCurrency}
                                                toggleCommissionsModal={_ => this.toggleModal("commissionsModal")}
                                            />
                                        : activeTab === 'Actions' ?
                                            <SalesDealDetailActions
                                                focusActions={deal?.salesDealFocusedActions}
                                                getDealActions={this.getDealActions}
                                                toggleActionModal={this.toggleActionModal}
                                                updateActionStatus={this.updateActionStatus}
                                                changeActionPin={this.changeActionPin}
                                                downloadAction={this.downloadAction}
                                                isLead={isLead}
                                                selectedPipeline={selectedPipeline}
                                                source={source}
                                                toggleEdit={this.toggleEdit}
                                                deal={deal}
                                                salesUsers={salesUsers}
                                                customers={customers}
                                                hotelCurrency={hotelCurrency}
                                            />
                                        : activeTab === 'Proposal' ?
                                            <SalesDealDetailProposal
                                                selectedPipeline={selectedPipeline}
                                                deal={deal}
                                                proposalDetailsCards={this.state.proposalDetailsCards}
                                                setProposalDetailsCards={this.setProposalDetailsCards}
                                                toggleImportGroupResModal={this.toggleImportGroupResModal}
                                                importGroupResModal={importGroupResModal}
                                                getDealProposals={this.getDealProposals}
                                                updatedProposals={this.updatedProposals}
                                                dealProposals={this.state.dealProposals}
                                                importGroupRes={this.importGroupRes}
                                                canAddProposals={canAddProposals}
                                                hotelCurrency={hotelCurrency}
                                                currentUser={currentUser}
                                            />
                                        :''}
                                    </div>
                                :''}
                            </div>
                        :''}
                    </Card>
                    
                    <>
                        {commissionsModal ?
                            <CommissionModal
                                modal={commissionsModal}
                                toggleModal={_ => this.toggleModal("commissionsModal")}
                                customers={customers}
                                dealCommissions={[...deal.salesDealProfileCommissions]}
                                salesDealId={deal.id}
                                updateCommissions={this.updateCommissions}
                            />
                        : ''}

                        {formReplyModal ?
                            <FormReplyModal
                                toggleModal={this.toggleFormReplyModal}
                                modal={true}
                                formId={deal?.formId}
                            />
                        :''}

                        {importGroupResModal ?
                            <ImportGroupResModal
                                toggleModal={this.toggleImportGroupResModal}
                                modal={true}
                                salesDealId={deal.id}
                                hotels={global.hotelList.filter(({ value }) => deal?.hotelIds && deal.hotelIds.includes(value))}
                                importGroupRes={this.importGroupRes}
                                getDealProposals={this.getDealProposals}
                            />
                            : ''}

                        {createDealCopyModal ?
                            <SalesDealModal
                                deal={this.state.newDealCopy}
                                modal={createDealCopyModal}
                                salesUsers={salesUsers}
                                apiRoles={roles}
                                customers={customers}
                                toggleModal={_ => this.toggleModal('createDealCopyModal', 'newDealCopy', null)}
                                salesStages={salesPipelines?.flatMap(({ stages }) => stages)}
                                isLead={isLead}
                                salesSources={salesSources}
                                updateDeal={this.goToNewDealDetails}
                                salesPipelineId={selectedPipeline?.id}
                                salesPipelines={salesPipelines}
                                currentUser={currentUser}
                                editableSalesPipeline={true}
                                hotelList={hotelList}
                                isDealCopy={true}
                                copyFromDeal={{ id: deal.id, name: deal.name }}
                            />
                        : ''}

                        {dealModal ?
                            <SalesDealModal
                                deal={createNewAssociatedLead ? this.state.newAssociatedLeadData : deal}
                                modal={dealModal}
                                salesUsers={salesUsers}
                                apiRoles={roles}
                                customers={customers}
                                toggleModal={this.toggleEdit}
                                salesStages={((!changeLeadToDeal) && (!createNewAssociation)) ? selectedPipeline?.stages : (salesPipelines?.flatMap(({ stages }) => stages))}
                                isLead={createNewAssociatedLead || (isLead && (!changeLeadToDeal) && (!createNewAssociation)) ? true : false}
                                changeLeadToDeal={changeLeadToDeal}
                                createNewAssociation={createNewAssociation || createNewAssociatedLead}
                                salesSources={salesSources}
                                updateDeal={createNewAssociatedLead || createNewAssociation ? this.goToNewDealDetails : this.updateDeal}
                                salesPipelineId={selectedPipeline?.id}
                                salesPipelines={salesPipelines}
                                specificEditStep={specificEditStep}
                                isToEditDeal={(!changeLeadToDeal) && (!createNewAssociation) && (!createNewAssociatedLead)}
                                currentUser={currentUser}
                                editableSalesPipeline={changeLeadToDeal || createNewAssociation ? true : false}
                                handleChangeLeadToDeal={this.handleChangeLeadToDeal}
                                hotelList={hotelList}
                            />
                        :''}

                        {actionModal ?
                            <SalesActionModal
                                customers={customers}
                                toggle={_ => this.toggleActionModal()}
                                salesUsers={salesUsers}
                                salesDealUsers={salesUsers.filter(user => deal.salesDealUser.some(dealUser => dealUser.userId === user.value))}
                                salesDealId={deal.id}
                                salesDealHotelIds={deal?.hotelIds}
                                salesPipelineStageId={deal.salesPipelineStage?.id}
                                selectedAction={selectedAction || null}
                            />
                        :''}

                        {dealAssociationModal ?
                            <AssociationModal 
                                block={block}
                                error={error}
                                modal={dealAssociationModal}
                                salesUsers={salesUsers}
                                salesPipelines={salesPipelines}
                                salesSources={salesSources}
                                salesSourcesTypes={salesSourcesTypes}
                                dealList={dealList ? dealList.filter(({ id }) => id !== deal.id) : []}
                                getDealList={getDealList}
                                currentDeal={deal}
                                toggleModal={this.toggleDealAssociationModal}
                                associateDeal={this.associateDeal}
                                associateInitiative={this.associateInitiative}
                                renderActivityHelperMsg={renderActivityHelperMsg}
                                totalDeals={totalDeals}
                            />
                        :''}

                        {changeAssociationTypeModal ?
                            <ChangeAssociationTypeModal
                                block={block}
                                error={error}
                                modal={changeAssociationTypeModal}
                                selectedAssociationTypeId={selectedDealRelationTypeId}
                                toggleModal={this.toggleChangeAssociationTypeModal}
                                saveAssociation={(e, relationType) => this.saveAssociation(e, relationType)}
                            />
                        :''}
                        
                        {confirmActionModal ?
                            <ConfirmActionModal
                                modal={confirmActionModal}
                                toggleModal={() => this.toggleConfirmActionModal()}
                                actionFunction={(e) => selectedAction ? this.updateActionStatus(e, selectedAction.id, changeToStatus) : this.changeDealStatus(e, changeToStatus)}
                                text={selectedAction ?
                                    changeToStatus === 'OnGoing' ?
                                        <FormattedMessage id="SalesDeal.ConfirmOnGoingText" values={{ action: selectedAction.title }} />
                                        :
                                        changeToStatus === 'Finished' ?
                                            <FormattedMessage id="SalesDeal.ConfirmFinishedText" values={{ action: selectedAction.title }} />
                                            :
                                            <FormattedMessage id="SalesDeal.ConfirmDeleteText" values={{ action: selectedAction.title }} />
                                    :
                                    isLead && changeToStatus === 'WaitingClient' ?
                                        <FormattedMessage id={`SalesDeal.ConfirmChangeStatusToOnGoing`} />
                                        :
                                        <FormattedMessage id={`SalesDeal.ConfirmChangeStatusTo${changeToStatus}${changeToStatus === 'Finished' ? (isLead ? 'Lead' : 'Deal') : ''}`} />
                                }
                                block={block}
                                winDealModal={changeToStatus === 'Finished' && (!selectedAction) && (!isLead)}
                                winDealActionFunction={(e) => this.changeDealStatus(e, 'Finished', true)}
                            >
                                {changeToStatus === 'Cancelled' ?
                                    <>
                                        <div className="px-4 mb-3">
                                            <div className="title-sm required">
                                                <FormattedMessage id="SalesDeal.CancellationReason" />
                                            </div>
                                            <SelectCustomType
                                                name="cancelReasonType"
                                                onChangeFunc={(combo) => this.handleChange({ target: { name: 'cancelReasonType', value: combo ?? null } })}
                                                required={true}
                                                value={deal.cancelReasonId}
                                                placeholder=""
                                                isDisabled={false}
                                                isClearable={true}
                                                type={'DealCancelReasonType'}
                                                bigCol={true}
                                            />
                                        </div>
                                        <div className="px-4 mb-4">
                                            <div className="title-sm required">
                                                <FormattedMessage id="generic.Description" />
                                            </div>
                                            <Input
                                                required={true}
                                                type="textarea"
                                                name="statusReason"
                                                placeholder=""
                                                onChange={this.handleChange}
                                                maxLength={100}
                                            />
                                        </div>
                                    </>
                                    : ''}
                            </ConfirmActionModal>
                            : ''}

                        {cancelProposalsModal ?
                            <CancelProposalsModal
                                modal={cancelProposalsModal}
                                block={block}
                                toggleModal={() => this.toggleModal('cancelProposalsModal')}
                                dealProposals={this.state.dealProposals}
                                getDealProposals={this.getDealProposals}
                                updateDealProposal={this.updateDealProposal}
                                getTypesList={this.getTypesList}
                                updatedTypes={updatedTypes}
                            />
                            : ''}

                        {deleteDealAssociationModal ?
                            <ConfirmActionModal
                                modal={deleteDealAssociationModal}
                                toggleModal={this.toggleDeleteDealAssociationModal}
                                actionFunction={() => this.state.deletedRelationIsInitiative ? this.deleteInitiativeAssociation(selectedRelationId) : this.deleteDealAssociation(selectedRelationId)}
                                text={<FormattedMessage id="SalesProcess.DeleteRelationText" />}
                                block={block}
                            />
                            : ''}

                        {confirmChangeToLeadModal ?
                            <ConfirmActionModal
                                modal={confirmChangeToLeadModal}
                                toggleModal={this.toggleConfirmChangeDealToLead}
                                actionFunction={() => this.switchDealToLead()}
                                text={<FormattedMessage id="SalesDeal.SwitchDealToLead" />}
                                block={block}
                            />
                            : ''}
                    </>
                </BlockUi>
            </>
        )
    }
}

export default injectIntl(SalesDealDetail);
