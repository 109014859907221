import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CoolTooltip, FormatAmountNumber, KebabMenu } from '../Base/CommonUIComponents';
import { Row, Col, PopoverBody } from 'reactstrap';
import { CustomFormatDate } from './SalesProcessFunctions';
import CustomToolTip from '../Base/CustomToolTip';
import moment from 'moment';
import ConfirmActionModal from '../Base/ConfirmActionModal';


class SalesDealProposalResume extends Component {

    constructor(props) {
        super(props);
        this.state = {
            proposals: [],
            groups: [],
            proposalToRemoveFromGroupRes: null,
            showRemoveFromGroupResModal: false
        };
    }

    componentDidMount(){
        this.initialCalc(this.props.salesDealProposals);
    }

    componentWillReceiveProps(nextProps) {
        const isDiff = () => {
            if(!this.props.salesDealProposals || !nextProps.salesDealProposals){
                return false;
            }
            
            const gResProps = this.props.salesDealProposals.filter(({groupResExternalId}) => groupResExternalId).reduce((a,b) => {
                const current = a.find(({id}) => id === b.groupResExternalId);
                if(current){
                    current.count++;
                    current.idList.push(b.id);
                    return a;
                }

                return [...a, { id: b.groupResExternalId, count: 1, idList: [b.id] }];
            } ,[]);

            const gResNext = nextProps.salesDealProposals.filter(({groupResExternalId}) => groupResExternalId).reduce((a,b) => {
                const current = a.find(({id}) => id === b.groupResExternalId);
                if(current){
                    current.count++;
                    current.idList.push(b.id);
                    return a;
                }

                return [...a, { id: b.groupResExternalId, count: 1, idList: [b.id] }];
            } ,[]);

            return (
                gResProps.length !== gResNext.length ||
                gResProps.some(({id, count, idList}) => !gResNext.find((n) => n.id === id) || gResNext.find((n) => n.id === id).count !== count || idList.some(n => !gResNext.find(a => a.idList.includes(n))))
            );
        };

        if (nextProps.salesDealProposals?.length !== this.props.salesDealProposals?.length || isDiff() || JSON.stringify(nextProps.salesDealProposals) !== JSON.stringify(this.props.salesDealProposals) ) {
            this.initialCalc(nextProps.salesDealProposals);
        }
    }

    initialCalc = (salesDealProposals) => {
        const withGroup = salesDealProposals.filter(({groupResExternalId}) => groupResExternalId);
        const proposals = salesDealProposals.filter(({type, id}) => (type === "Block" || type === "Event") && !withGroup.some(p => p.id === id));

        const groups = withGroup.reduce((a, b) => {
            let curr = a.find(({groupResExternalId}) => groupResExternalId === b.groupResExternalId);
            if(!curr){
                const newGroup = {
                    externalId: b.externalId,
                    groupResExternalId: b.groupResExternalId,
                    groupResName: b.groupResName,
                    hotelId: b.hotelId,
                    block: null,
                    events: [],
                    total: null,
                    roomTotal: null,
                    eventsTotal: null,
                    isGroupRes: true,
                    checkIn: null,
                    checkOut: null
                };

                if(b.type === "Block"){
                    newGroup.block = b;
                }
                else{
                    newGroup.events.push(b);
                }

                a.push(newGroup);

                curr = a.find(({groupResExternalId}) => groupResExternalId === b.groupResExternalId);
            }
            else{
                if(b.type === "Block" && !curr.block){
                    curr.block = b;
                }
                else{
                    curr.events.push(b);
                }
            }

            curr.roomTotal = curr.block?.totalValue??0;
            curr.eventsTotal = curr.events.reduce((a, b) => a + b.totalValue, 0)??0;
            curr.total = curr.roomTotal + curr.eventsTotal;

            const checkOuts = curr.events.map(({endDate}) => moment(endDate));
            const checkIns = curr.events.map(({startDate}) => moment(startDate));

            if(curr.block){
                checkOuts.push(moment(curr.block.endDate));
                checkIns.push(moment(curr.block.startDate));
            }

            curr.checkIn = moment.min(checkIns) ? moment.min(checkIns).utc(false).format('YYYY-MM-DD') : null;
            curr.checkOut = moment.max(checkOuts) ? moment.max(checkOuts).utc(false).format('YYYY-MM-DD') : null;
            
            return a;
        }, []);
        
        this.setState({ proposals: proposals, groups: groups });
    }

    toggleAndConfirmRemoveFromGroupRes = (proposal = null, isToRemove) => {
        const { showRemoveFromGroupResModal, proposalToRemoveFromGroupRes } = this.state;
    
        if (showRemoveFromGroupResModal && proposalToRemoveFromGroupRes && isToRemove) {
            this.props.createGroupRes(proposalToRemoveFromGroupRes);
        }

        this.setState({ proposalToRemoveFromGroupRes: proposal, showRemoveFromGroupResModal: !showRemoveFromGroupResModal });
    }
    
    render() {
        const { hotelCurrency, intl } = this.props;
        const { proposals, groups, showRemoveFromGroupResModal, proposalToRemoveFromGroupRes } = this.state;
        
        return (
            <>
                <div className='d-flex align-items-center justify-content-between px-3'>
                    <h5 className='m-0'><FormattedMessage id="SalesDeal.ProposalResume" /></h5>
                </div>
                <div className='pt-4 px-3'>
                    {groups ?
                        <div>
                            {groups.map((group, key) => {
                                const hotel = global.hotelList?.find(({ value }) => value === group.hotelId);
                                const rooms = group.block ? group.block.proposalJSON?.groupedCategories : null;
                                
                                return (
                                    <div key={key} className='shadow w-100 mb-3 bg-white p-3'>
                                        <div>
                                            <div className='cursor-pointer text-left' style={{ width: 'fit-content', position: 'relative' }} onClick={_ => this.props.setFilters(group.groupResExternalId, group.groupResName, "GroupReservation")}>
                                                <b className='d-flex align-items-center' style={{ maxWidth: '280px' }}>
                                                    <i id={`groupProposal-${key}`} className={`far fa-object-group mr-2`}></i>
                                                    <span id={`GroupProposalName-${key}`} className='text-truncate'>
                                                        {group.groupResName}
                                                        {this.props.hasFilter(group.groupResExternalId, "GroupReservation") ?
                                                            <FilterDot />
                                                            : ''}
                                                    </span>
                                                </b>
                                                <CoolTooltip placement="top" target={`groupProposal-${key}`}>
                                                    <FormattedMessage id={"SalesProcess.GroupReservation"}/>
                                                </CoolTooltip>
                                                <CoolTooltip placement="top" target={`GroupProposalName-${key}`}>
                                                    {group.groupResName}
                                                </CoolTooltip>
                                            </div>
                                            <div className='cursor-pointer' style={{ width: 'fit-content', position: 'relative', fontSize: '0.8em' }} onClick={_ => this.props.setFilters(group.hotelId, hotel?.label, "Hotel")}>
                                                <div className='text-truncate text-muted'>
                                                    {hotel?.label}
                                                    {this.props.hasFilter(group.hotelId, "Hotel") ?
                                                        <FilterDot />
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <Row className='text-truncate title-sm mt-2 mx-1'>
                                            <Col className="text-left pl-0">
                                                <span id={`check-in-${key}`} >
                                                    <i className="far fa-calendar-alt color-green mr-1" /> {CustomFormatDate(group.checkIn, null, null, intl) ?? '-'}
                                                </span>
                                                <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="right" target={`check-in-${key}`}>
                                                    <PopoverBody> Check-in </PopoverBody>
                                                </CustomToolTip>
                                            </Col>
                                            <Col className="text-right pr-0">
                                                <span id={`check-out-${key}`} >
                                                    <i className="far fa-calendar-alt color-red mr-1" />{CustomFormatDate(group.checkOut, null, null, intl) ?? '-'}
                                                </span>
                                                <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="left" target={`check-out-${key}`}>
                                                    <PopoverBody> Check-out </PopoverBody>
                                                </CustomToolTip>
                                            </Col>
                                        </Row>
                                        <div className='px-2 mt-3'>
                                            {group.block ?
                                                <div className='pb-2'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div className='cursor-pointer text-truncate text-left' style={{ width: 'fit-content', position: 'relative' }} onClick={_ => this.props.setFilters(null, null, "Rooms", group.block.externalId)}>
                                                            <i id={`block-from-group-${key}`} className={`fas fa-cube mr-2`}></i>
                                                            <FormattedMessage id="SalesDeal.Rooms"/>
                                                            {this.props.hasFilter(null, "Rooms", group.block.externalId) ?
                                                                <FilterDot />
                                                            : ''}
                                                            <CoolTooltip placement="top" target={`block-from-group-${key}`}>
                                                                <FormattedMessage id={"SalesProcess.Block"}/>
                                                            </CoolTooltip>
                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            {group.block.statusDesc ?
                                                                <div className='text-truncate mr-2'>
                                                                    <div id={`proposalStatus-${key}`} className={`${group.block.isCancelled ? 'text-danger' : 'text-muted'} text-truncate`} style={{ fontSize: '0.85em' }}>
                                                                        {group.block.statusDesc}
                                                                    </div>
                                                                    <CoolTooltip placement="top" target={`proposalStatus-${key}`}>
                                                                        {group.block.statusDesc}
                                                                    </CoolTooltip>
                                                                </div>
                                                            :''}
                                                            {global.isMobile ? '' :
                                                                group.block.editableByCurrentUser ?
                                                                <KebabMenu
                                                                    direction={'left'}
                                                                    extraFields={[
                                                                        {
                                                                            function: _ => this.props.importGroupRes(group.block.externalId, group.block.hotelId, group.block.id),
                                                                            icon: 'fas fa-sync-alt mr-2',
                                                                            text: 'SalesProcess.UpdateBlock'
                                                                        },
                                                                        {
                                                                            function: _ => this.props.toggleActiveSupraTab(`${group.block.type}s`, group.block),
                                                                            icon: 'fas fa-edit mr-2',
                                                                            text: `SalesProcess.EditBlock`
                                                                        },
                                                                        {
                                                                            function: _ => this.toggleAndConfirmRemoveFromGroupRes(group.block),
                                                                            icon: 'far fa-object-group mr-2',
                                                                            text: 'SalesProcess.RemoveBlock'
                                                                        }
                                                                    ]}
                                                                    deleteFunction={_ => this.props.removeGroupReservation(group.block.id)}
                                                                    deleteText={"SalesDeal.Remove"}
                                                                />
                                                            :
                                                                <KebabMenu
                                                                    direction={'left'}
                                                                    extraFields={[
                                                                        {
                                                                            function: _ => this.props.importGroupRes(group.block.externalId, group.block.hotelId, group.block.id),
                                                                            icon: 'fas fa-sync-alt mr-2',
                                                                            text: 'SalesProcess.UpdateBlock'
                                                                        }
                                                                    ]}
                                                                />
                                                            }
                                                            {/* <FormatAmountNumber currency={hotelCurrency} value={group.roomTotal ?? 0} /> */}
                                                        </div>
                                                    </div>
                                                    {rooms?.length ?
                                                        <div className='px-2 mt-2' style={{ borderTop: '1px solid lightgrey', fontSize: '0.9em' }}>
                                                            {rooms?.map((room, key) =>
                                                                <React.Fragment key={key}>
                                                                    {key ?
                                                                        <hr className='my-1' />
                                                                        : ''}
                                                                    <div className='d-flex align-items-center justify-content-between mt-2'>
                                                                        <div className='d-flex cursor-pointer' style={{ width: 'fit-content', position: 'relative', maxWidth: '80%' }} onClick={_ => this.props.setFilters(room.categoryId, room.categoryCode, "Rooms", group.block.externalId)}>
                                                                            {room.categoryCode}
                                                                            <div style={{ fontSize: '0.9em' }} className='ml-1 text-muted'>
                                                                                {room.totalRooms}
                                                                            </div>
                                                                            {this.props.hasFilter(room.categoryId, "Rooms", group.block.externalId) ?
                                                                                <FilterDot/>
                                                                            :''}
                                                                        </div>
                                                                        <div>
                                                                            <FormatAmountNumber currency={hotelCurrency} value={room.priceTotal ?? 0} />
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                    :''}
                                                </div>
                                            : ''}

                                            {group.events?.length > 0 && group.events?.length ?
                                                group.events.map((event, key) => {
                                                    const eventData = event.proposalJSON?.data?.[0]?.DetailsEvents?.[0] && event.proposalJSON.data[0].DetailsEvents[0];
                                                    
                                                    const eventSpaces = eventData?.Spaces && eventData.Spaces?.reduce((a, b) => {
                                                        let current = a.find(({spaceName}) => spaceName === b.SpaceName);
                    
                                                        if(!current){
                                                            const newSpace = {
                                                                spaceName: b.SpaceName,
                                                                spaceReservationId: b.SpaceReservationId,
                                                                totalPrice: 0
                                                            };
                                                            
                                                            a.push(newSpace);
                                                            current = a.find(({spaceName}) => spaceName === b.SpaceName);
                                                        }
                                                        
                                                        current.totalPrice += b.PriceDetails?.reduce((a, b) => a + b.AmountAfterTax, 0)
                    
                                                        return a;
                                                    }, []);
                                                    
                                                    const equipmentTotal = eventData?.PriceDetailsNonSpace && eventData.PriceDetailsNonSpace?.reduce((a, b) => a + (b.AmountAfterTax??0), 0);

                                                    return (
                                                        eventData ?
                                                            <div key={key} className={`${(group.block || key) ? 'pt-3 mt-2' : ''} pb-2`} style={{ borderTop: (key || group.block) ? '1px solid lightgrey' : '' }}>
                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                    <div className='cursor-pointer text-truncate text-left' style={{ width: 'fit-content', position: 'relative' }} onClick={_ => this.props.setFilters(null, eventData.EventName, "Events", event.externalId)}>
                                                                        <i id={`event-from-group-${key}`} className={`fas fa-theater-masks mr-2`}></i>
                                                                        {eventData.EventName}
                                                                        {this.props.hasFilter(null, "Events", event.externalId) ?
                                                                            <FilterDot right='0' />
                                                                            : ''}

                                                                        <CoolTooltip placement="top" target={`event-from-group-${key}`}>
                                                                            <FormattedMessage id={"SalesProcess.Event"} />
                                                                        </CoolTooltip>
                                                                    </div>
                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                        {event.statusDesc ?
                                                                            <div className='text-truncate mr-2'>
                                                                                <div id={`proposalStatus-${key}`} className={`${event.isCancelled ? 'text-danger' : 'text-muted'} text-truncate`} style={{ fontSize: '0.85em' }}>
                                                                                    {event.statusDesc}
                                                                                </div>
                                                                                <CoolTooltip placement="top" target={`proposalStatus-${key}`}>
                                                                                    {event.statusDesc}
                                                                                </CoolTooltip>
                                                                            </div>
                                                                        :''}
                                                                        {global.isMobile ? '' :
                                                                            event.editableByCurrentUser ?
                                                                            <KebabMenu
                                                                                direction={'left'}
                                                                                extraFields={[
                                                                                    {
                                                                                        function: _ => this.props.importGroupRes(event.externalId, event.hotelId, event.id),
                                                                                        icon: 'fas fa-sync-alt mr-2',
                                                                                        text: 'SalesProcess.UpdateEvent'
                                                                                    },
                                                                                    {
                                                                                        function: _ => this.props.toggleActiveSupraTab(`${event.type}s`, event),
                                                                                        icon: 'fas fa-edit mr-2',
                                                                                        text: `SalesProcess.EditEvent`
                                                                                    },
                                                                                    {
                                                                                        function: _ => this.toggleAndConfirmRemoveFromGroupRes(event),
                                                                                        icon: 'far fa-object-group mr-2',
                                                                                        text: 'SalesProcess.RemoveEvent'
                                                                                    }
                                                                                ]}
                                                                                deleteFunction={_ => this.props.removeGroupReservation(event.id)}
                                                                                deleteText={"SalesDeal.Remove"}
                                                                            />
                                                                        :
                                                                            <KebabMenu
                                                                                direction={'left'}
                                                                                extraFields={[{
                                                                                    function: _ => this.props.importGroupRes(event.externalId, event.hotelId, event.id),
                                                                                    icon: 'fas fa-sync-alt mr-2',
                                                                                    text: 'SalesProcess.UpdateEvent'
                                                                                }]}
                                                                            />
                                                                        }
                                                                        {/* <FormatAmountNumber currency={hotelCurrency} value={group.eventsTotal ?? 0} /> */}
                                                                    </div>
                                                                </div>
                                                                {eventSpaces?.length ?
                                                                    <div className='px-2 mt-2' style={{ borderTop: '1px solid lightgrey', fontSize: '0.9em' }}>
                                                                        {eventSpaces.map((space, key) =>
                                                                            <React.Fragment key={key}>
                                                                                {key ?
                                                                                    <hr className='my-1' />
                                                                                    : ''}
                                                                                <div className='d-flex align-items-center justify-content-between mt-2'>
                                                                                    <div className='d-flex cursor-pointer' style={{ width: 'fit-content', position: 'relative', maxWidth: '80%' }} onClick={_ => this.props.setFilters(space.spaceReservationId, space.spaceName, "Spaces", event.externalId)}>
                                                                                        <div className='text-truncate'>{space.spaceName}</div>
                                                                                        {this.props.hasFilter(space.spaceReservationId, "Spaces", event.externalId) ?
                                                                                            <FilterDot />
                                                                                            : ''}
                                                                                    </div>
                                                                                    <div>
                                                                                        <FormatAmountNumber currency={hotelCurrency} value={space.totalPrice} />
                                                                                    </div>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </div>
                                                                :''}
                                                                {equipmentTotal ?
                                                                    <div className='px-2 text-muted mt-2' style={{ borderTop: '1px solid lightgrey', fontSize: '0.9em' }}>
                                                                        <div className='d-flex align-items-center justify-content-between mt-2'>
                                                                            <div className='d-flex cursor-pointer' style={{ width: 'fit-content', position: 'relative', maxWidth: '80%' }}>
                                                                                <div className='text-truncate'>
                                                                                    <FormattedMessage id="SalesProcess.Equipment"/>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <FormatAmountNumber currency={hotelCurrency} value={equipmentTotal}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                :''}
                                                            </div>
                                                        :''
                                                    )
                                                })
                                            : ''}

                                            <div className='d-flex align-items-center justify-content-between mt-2 pt-2 pb-2' style={{ borderTop: '1px solid lightgrey', fontWeight: 'bold' }}>
                                                <div>
                                                    <FormattedMessage id="SalesDeal.Total" />
                                                </div>
                                                <div>
                                                    <FormatAmountNumber
                                                        currency={hotelCurrency}
                                                        value={group.total ?? 0}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            )}
                        </div>
                    :''}
                    {proposals ?
                        <div className='mt-2'>
                            {proposals.map((proposal, key) => {
                                const hotel = global.hotelList?.find(({ value }) => value === proposal.hotelId);
                                const rooms = proposal.proposalJSON?.groupedCategories;
                                const event = proposal.proposalJSON.data?.[0]?.DetailsEvents?.[0];

                                const existingGroupRes = groups.some(g => g.hotelId === proposal.hotelId);
                                const canEditProposal = proposal.editableByCurrentUser;
                                
                                const eventSpaces = event?.Spaces && event.Spaces?.reduce((a, b) => {
                                    let current = a.find(({spaceName}) => spaceName === b.SpaceName);

                                    if(!current){
                                        const newSpace = {
                                            spaceName: b.SpaceName,
                                            spaceReservationId: b.SpaceReservationId,
                                            totalPrice: 0
                                        };
                                        
                                        a.push(newSpace);
                                        current = a.find(({spaceName}) => spaceName === b.SpaceName);
                                    }

                                    current.totalPrice += b.PriceDetails?.reduce((a, b) => a + b.AmountAfterTax, 0);

                                    return a;
                                }, []);

                                const equipmentTotal = event?.PriceDetailsNonSpace && event.PriceDetailsNonSpace?.reduce((a, b) => a + (b.AmountAfterTax??0), 0);
                                
                                return (
                                    <div key={key} className='shadow w-100 mb-3 bg-white p-3'>
                                        <div className='d-flex justify-content-between align-items-start'>
                                            <div className='mr-2' style={{ maxWidth: '55%' }}>
                                                <div className='cursor-pointer text-left' style={{ position: 'relative' }} onClick={_ => this.props.setFilters(proposal.externalId, proposal.externalId, "Reservation")}>
                                                    <b className='d-flex align-items-center' style={{ maxWidth: '280px' }}>
                                                        <i id={`blockOrEventProposal-${key}`} className={`fas fa-${proposal.type === 'Block' ? 'cube' : 'theater-masks'} mr-2`}></i>
                                                        <span id={`blockOrEventProposalName-${key}`} className='text-truncate'>
                                                            {(proposal.type === 'Block' ? proposal.proposalJSON?.BlockData?.headerData?.[0]?.Code : proposal.proposalJSON?.data?.[0]?.Name) || proposal.externalId}
                                                        </span>
                                                    </b>
                                                    <CoolTooltip placement="top" target={`blockOrEventProposal-${key}`}>
                                                        <FormattedMessage id={proposal.type === 'Block' ? "SalesProcess.Block" : "SalesProcess.Event"} />
                                                    </CoolTooltip>
                                                    <CoolTooltip placement="top" target={`blockOrEventProposalName-${key}`}>
                                                        {(proposal.type === 'Block' ? proposal.proposalJSON?.BlockData?.headerData?.[0]?.Code : proposal.proposalJSON?.data?.[0]?.Name) || proposal.externalId}
                                                    </CoolTooltip>
                                                    {this.props.hasFilter(proposal.externalId, "Reservation") ?
                                                        <FilterDot />
                                                    : ''}
                                                </div>
                                                <div className='cursor-pointer' style={{ width: 'fit-content', position: 'relative', fontSize: '0.8em' }} onClick={_ => this.props.setFilters(proposal.hotelId, hotel?.label, "Hotel")}>
                                                    <div className='text-truncate text-muted'>
                                                        {hotel?.label}
                                                    </div>
                                                    {this.props.hasFilter(proposal.hotelId, "Hotel") ?
                                                        <FilterDot />
                                                    :''}
                                                </div>
                                            </div>
                                            <div style={{ width: '45%' }} className='d-flex align-items-center justify-content-end'>
                                                {proposal.statusDesc ?
                                                    <div className='text-truncate mr-2'>
                                                        <div id={`proposalStatus-${key}`} className={`${proposal.isCancelled ? 'text-danger' : 'text-muted'} text-truncate`} style={{ fontSize: '0.85em' }}>
                                                            {proposal.statusDesc}
                                                        </div>
                                                        <CoolTooltip placement="top" target={`proposalStatus-${key}`}>
                                                            {proposal.statusDesc}
                                                        </CoolTooltip>
                                                    </div>
                                                :''}
                                                {proposal.isOption ?
                                                    <>
                                                        <div className='mr-2' id={`optional-${key}`}>
                                                            <i className="far fa-bell-slash"/>
                                                        </div>
                                                        <CoolTooltip target={`optional-${key}`}>
                                                            <FormattedMessage id="SalesProcess.IsOption"/>
                                                        </CoolTooltip>
                                                    </>
                                                : ''}
                                                {global.isMobile ? '' :
                                                    canEditProposal ?
                                                    <KebabMenu
                                                        direction={'left'}
                                                        extraFields={proposal.type === "Block" || proposal.type === "Event" ?
                                                            [{
                                                                function: _ => this.props.importGroupRes(proposal.externalId, proposal.hotelId, proposal.id),
                                                                icon: 'fas fa-sync-alt mr-2',
                                                                text: proposal.type === "Block" ? `SalesProcess.UpdateBlock` : 'SalesProcess.UpdateEvent'
                                                            },
                                                            {
                                                                function: _ => this.props.toggleActiveSupraTab(`${proposal.type}s`, proposal),
                                                                icon: 'fas fa-edit mr-2',
                                                                text: proposal.type === "Block" ? `SalesProcess.EditBlock` : 'SalesProcess.EditEvent'
                                                            },
                                                            {
                                                                function: _ => this.props.toggleIsOption(proposal.id, !proposal.isOption),
                                                                icon: 'far fa-bell-slash mr-2',
                                                                text: proposal.isOption ? 'SalesProcess.RemoveIsOption' : 'SalesProcess.SetIsOption'
                                                            },
                                                            {
                                                                function: _ => this.props.createGroupRes(proposal),
                                                                icon: 'far fa-object-group mr-2',
                                                                text: existingGroupRes ? 'SalesProcess.AddToGroupRes' : 'SalesProcess.CreateGroupRes'
                                                            }]
                                                            :
                                                            [{
                                                                function: _ => this.props.importGroupRes(proposal.externalId, proposal.hotelId, proposal.id),
                                                                icon: 'fas fa-sync-alt mr-2',
                                                                text: 'SalesDeal.UpdateReservation'
                                                            },
                                                            {
                                                                function: _ => this.props.toggleIsOption(proposal.id, !proposal.isOption),
                                                                icon: 'far fa-bell-slash mr-2',
                                                                text: proposal.isOption ? 'SalesProcess.RemoveIsOption' : 'SalesProcess.SetIsOption'
                                                            },
                                                            {
                                                                function: _ => this.props.createGroupRes(proposal),
                                                                icon: 'far fa-object-group mr-2',
                                                                text: existingGroupRes ? 'SalesProcess.AddToGroupRes' : 'SalesProcess.CreateGroupRes'
                                                            }]
                                                        }
                                                        deleteFunction={_ => this.props.removeGroupReservation(proposal.id)}
                                                        deleteText={"SalesDeal.Remove"}
                                                    />
                                                :
                                                    <KebabMenu
                                                        direction={'left'}
                                                        extraFields={proposal.type === "Block" || proposal.type === "Event" ?
                                                            [{
                                                                function: _ => this.props.importGroupRes(proposal.externalId, proposal.hotelId, proposal.id),
                                                                icon: 'fas fa-sync-alt mr-2',
                                                                text: 'SalesDeal.UpdateReservation'
                                                            },
                                                            {
                                                                function: _ => this.props.toggleIsOption(proposal.id, !proposal.isOption),
                                                                icon: 'far fa-bell-slash mr-2',
                                                                text: proposal.isOption ? 'SalesProcess.RemoveIsOption' : 'SalesProcess.SetIsOption'
                                                            }]
                                                            :
                                                            [{
                                                                function: _ => this.props.importGroupRes(proposal.externalId, proposal.hotelId, proposal.id),
                                                                icon: 'fas fa-sync-alt mr-2',
                                                                text: 'SalesDeal.UpdateReservation'
                                                            },
                                                            {
                                                                function: _ => this.props.toggleIsOption(proposal.id, !proposal.isOption),
                                                                icon: 'far fa-bell-slash mr-2',
                                                                text: proposal.isOption ? 'SalesProcess.RemoveIsOption' : 'SalesProcess.SetIsOption'
                                                            }]
                                                        }
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <Row className='text-truncate title-sm mt-2 mx-1'>
                                            <Col className="text-left pl-0">
                                                <span id={`check-in-${key}`} >
                                                    <i className="far fa-calendar-alt color-green mr-1" /> {proposal.proposalJSON.data[0]?.CheckIn ? CustomFormatDate(proposal.proposalJSON.data[0]?.CheckIn, null, null, intl) : '-'}
                                                </span>
                                                <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="right" target={`check-in-${key}`}>
                                                    <PopoverBody> Check-in </PopoverBody>
                                                </CustomToolTip>
                                            </Col>
                                            <Col className="text-right pr-0">
                                                <span id={`check-out-${key}`} >
                                                    <i className="far fa-calendar-alt color-red mr-1" />{proposal.proposalJSON.data[0]?.CheckOut ? CustomFormatDate(proposal.proposalJSON.data[0]?.CheckOut, null, null, intl) : '-'}
                                                </span>
                                                <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="left" target={`check-out-${key}`}>
                                                    <PopoverBody> Check-out </PopoverBody>
                                                </CustomToolTip>
                                            </Col>
                                        </Row>
                                        {(proposal.numberOfRooms && rooms) || (event && event.Spaces?.length) ?
                                            <div className='px-2 mt-3'>
                                                {proposal.numberOfRooms && rooms ?
                                                    rooms?.map((room, key) =>
                                                        <React.Fragment key={key}>
                                                            {key ?
                                                                <hr className='my-1' />
                                                                : ''}
                                                            <div className='d-flex align-items-center justify-content-between mt-2'>
                                                                <div className='d-flex cursor-pointer' style={{ width: 'fit-content', position: 'relative', maxWidth: '80%' }} onClick={_ => this.props.setFilters(room.categoryId, room.categoryCode, "Rooms", proposal.externalId)}>
                                                                    {room.categoryCode}
                                                                    <div style={{ fontSize: '0.9em' }} className='ml-1 text-muted'>
                                                                        {room.totalRooms}
                                                                    </div>
                                                                    {this.props.hasFilter(room.categoryId, "Rooms", proposal.externalId) ?
                                                                        <FilterDot />
                                                                        : ''}
                                                                </div>
                                                                <div>
                                                                    <FormatAmountNumber currency={hotelCurrency} value={room.priceTotal ?? 0} />
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                : ''}
                                                {event && eventSpaces?.length ?
                                                    <>
                                                        {eventSpaces.map((space, key) =>
                                                            <React.Fragment key={key}>
                                                                {key ?
                                                                    <hr className='my-1' />
                                                                : ''}
                                                                <div className='d-flex align-items-center justify-content-between mt-2'>
                                                                    <div className='d-flex cursor-pointer' style={{ width: 'fit-content', position: 'relative', maxWidth: '80%' }} onClick={_ => this.props.setFilters(space.spaceReservationId, space.spaceName, "Spaces", proposal.externalId)}>
                                                                        <div className='text-truncate'>{space.spaceName}</div>
                                                                        {this.props.hasFilter(space.spaceReservationId, "Spaces", proposal.externalId) ?
                                                                            <FilterDot />
                                                                            : ''}
                                                                    </div>
                                                                    <div>
                                                                        <FormatAmountNumber currency={hotelCurrency} value={space.totalPrice}/>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        )}

                                                        {equipmentTotal ?
                                                            <div className='mt-2 text-muted' style={{ borderTop: '1px solid lightgrey' }}>
                                                                <div className='d-flex align-items-center justify-content-between mt-2'>
                                                                    <div className='d-flex cursor-pointer' style={{ width: 'fit-content', position: 'relative', maxWidth: '80%' }}>
                                                                        <div className='text-truncate'>
                                                                            <FormattedMessage id="SalesProcess.Equipment"/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <FormatAmountNumber currency={hotelCurrency} value={equipmentTotal}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        :''}
                                                    </>
                                                :''}

                                                {(proposal.numberOfRooms && rooms?.length > 1) || (event && event.Spaces?.length > 1) ?
                                                    <div className='d-flex align-items-center justify-content-between mt-2 pt-2 pb-2' style={{ borderTop: '1px solid lightgrey', fontWeight: 'bold' }}>
                                                        <div>
                                                            <FormattedMessage id="SalesDeal.Total" />
                                                        </div>
                                                        <div>
                                                            <FormatAmountNumber
                                                                currency={hotelCurrency}
                                                                value={proposal.totalValue ?? 0}
                                                            />
                                                        </div>
                                                    </div>
                                                : ''}
                                            </div>
                                        :''}
                                    </div>
                                )
                            }
                            )}
                        </div>
                    :''}
                </div>

                {showRemoveFromGroupResModal && (
                    <ConfirmActionModal
                        modal={showRemoveFromGroupResModal}
                        toggleModal={this.toggleAndConfirmRemoveFromGroupRes}
                        actionFunction={() => this.toggleAndConfirmRemoveFromGroupRes(null, true)}
                        text={<FormattedMessage id="SalesProcess.ConfirmRemoveFromGroupResText" />}
                    />
                )}
            </>
        );
    }
}

export default injectIntl(SalesDealProposalResume);



const FilterDot = ({ right }) => {
    return <div style={{
        width: '5px',
        height: '5px',
        background: '#0665ff',
        borderRadius: '100%',
        position: 'absolute',
        top: '0',
        right: right || '-7px'
    }} />
}