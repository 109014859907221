import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Badge, Col, Row, Modal, ModalBody } from 'reactstrap';
import { getAPI } from "../Base/API";
import { StyledCard, BlankCard, CommonHelper } from "../Base/CommonUIComponents";
import { CustomTable } from '../Base/CustomTable';
import CustomSelect from '../Base/CustomSelect';
import moment from "moment";
import { DateRangePicker } from "react-dates";

export class TaskResultDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            modal: false,
            runList: [],
            endDate: moment(),
            startDate: moment().subtract(7, 'days'),
            topicId: null,
            templateId: null,
            templateList: [],
            topicList: [],
            pageIndex: 0,
            pageSize: 10,
            totalItems: 0
        };
    }

    componentDidMount() {
        this.updateStateFilters();
    }

    updateStateFilters = () => {
        let params = new URLSearchParams(window.location.search);

        if (!params.has('startDate')) params.append('startDate', moment().subtract(7, 'days').format("YYYY-MM-DD"))
        if (!params.has('endDate')) params.append('endDate', moment().format("YYYY-MM-DD"))
        if (!params.has('page')) params.append('page', 1)
        if (!params.has('pageSize')) params.append('pageSize', 10)

        this.props.history.push({ search: `?${params}` });


        let startDate = params.get('startDate');
        let endDate = params.get('endDate');
        let pageSize = params.get('pageSize');
        let pageIndex = params.get('page') - 1;
        let topicId = params.get('topic');
        let templateId = params.get('template');

        this.setState({ pageSize, pageIndex, startDate, endDate, topicId, templateId },
            () => this.getRuns(false));
    }

    getComboData = () => {
        this.getTopics();
        this.getTemplates();
    }

    getTopics = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var topics = [];

                data.response && data.response.map(el => {
                    topics.push({ value: el.id, label: el.code });
                });

                this.setState({ topicList: topics });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/DataQuality/v1/DataQualityTopic`);
    }

    getTemplates = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var templates = [];

                data.response && data.response.forEach(el => {
                    el.dataQualityTemplates && el.dataQualityTemplates.forEach(template => {
                        templates.push({ value: template.id, label: template.name });
                    })
                });
                this.setState({ templateList: templates });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/DataQuality/v1/DataQualityTemplate`);
    }

    getRuns(updateUrlParams) {
        this.setState({ block: true });
        var params = this.updateAndGetUrlParams(updateUrlParams)

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ runList: data.response, totalItems: data.totalItems });
            }
            this.setState({ block: false }, () => this.getComboData());
        }, `/api/gms/Dashboard/DataQualityRunReport` + params);
    }

    updateAndGetUrlParams = (updateUrlParams) => {
        const { pageSize, pageIndex, startDate, endDate, topicId, templateId } = this.state;


        if (updateUrlParams) {
            let url = `?page=${pageIndex + 1}&pageSize=${pageSize}`;

            if (startDate) url += `&startDate=${moment(this.state.startDate).format("YYYY-MM-DD")}`;
            if (endDate) url += `&endDate=${moment(this.state.endDate).format("YYYY-MM-DD")}`;
            if (topicId) url += `&topic=${topicId}`;
            if (templateId) url += `&template=${templateId}`;

            this.props.history.push({ search: url });
        }

        var params = `?pageSize=${pageSize}&pageIndex=${pageIndex}`;
      
        if (startDate) params += `&fromDate=${moment(this.state.startDate).format("YYYY-MM-DD")}`;
        if (endDate) params += `&toDate=${moment(this.state.endDate).format("YYYY-MM-DD")}`;
        if (topicId) params += `&topicId=${topicId}`;
        if (templateId) params += `&templateId=${templateId}`;

        return params;
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page - 1,
            pageSize: sizePerPage,
        }, () => { this.getRuns(true) });
    }

    search = () => {
        this.setState({
            pageIndex: 0
        }, () => this.getRuns(true));
    }

    toggleModal = (data) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            modalData: data
        }));
    }

    render() {
        const { block, error, runList, currentPage, resultsPerPage, templateList, topicList, topicId, templateId } = this.state;

        const columns = [
            {
                dataField: "topicCode",
                text: this.props.intl.formatMessage({
                    id: "TaskResultDashboard.code"
                }),
                sort: true
            },
            {
                dataField: "templateName",
                text: this.props.intl.formatMessage({
                    id: "TaskResultDashboard.template"
                }),
                sort: true
            },
            {
                dataField: "numRecordsAnalyzed",
                text: this.props.intl.formatMessage({
                    id: "TaskResultDashboard.numRecordsAnalyzed"
                }),
                sort: true
            },
            {
                dataField: "numRecordsWithIssues",
                text: this.props.intl.formatMessage({
                    id: "TaskResultDashboard.numRecordsWithIssues"
                }),
                sort: true
            },
            {
                dataField: "runDate",
                text: this.props.intl.formatMessage({
                    id: "TaskResultDashboard.runDate"
                }),
                sort: true
            },
            {
                dataField: "duration",
                text: this.props.intl.formatMessage({
                    id: "TaskResultDashboard.duration"
                }),
                sort: true,
            },
            {
                dataField: "success",
                text: this.props.intl.formatMessage({
                    id: "generic.status"
                }),
                sort: true,
                formatter: (cell) => <div className="ml-4"> {cell === true ? <Badge color="success"><i className="fas fa-check"></i></Badge> : <Badge color="danger"><i className="fas fa-exclamation-triangle"></i></Badge>} </div>,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        e.stopPropagation();
                        if (row.success === false) {
                            this.toggleModal(row.exception);
                        }
                    },
                }
            }
        ];

        const selectRow = {
            mode: "radio",
            hideSelectColumn: true,
            bgColor: "#f8f9fa",
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                if (row.numRecordsWithIssues && row.numRecordsWithIssues > 0) {
                    this.props.history.push({
                        pathname: "/TaskResultDetail/" + row.runId,
                        search: "?page=1&pageSize=10",
                        rule: row
                    })
                }
            }
        };

        return (
            <StyledCard block={block} error={error} >
                <Row>
                    <Col>
                        <h5><i className="fas fa-tasks mr-2"></i> <FormattedMessage id="TaskResultDashboard.title" /></h5>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <CustomSelect
                            options={topicList}
                            placeholder={<FormattedMessage id="TaskResultDashboard.SelectTopic" />}
                            onChange={this.handleChangeSelect.bind(this, 'topicId')}
                            value={topicId ? topicList.find(t => t.value == topicId) : ''}
                            isClearable
                            isSearchable
                        />
                    </Col>
                    <Col>
                        <CustomSelect
                            options={templateList}
                            placeholder={<FormattedMessage id="TaskResultDashboard.SelectTemplate" />}
                            onChange={this.handleChangeSelect.bind(this, 'templateId')}
                            value={templateId ? templateList.find(t => t.value == templateId) : ''}
                            isClearable
                            isSearchable
                        />

                    </Col>
                    <Col>
                        <DateRangePicker
                            startDate={this.state.startDate ? moment(this.state.startDate) : ''}
                            startDateId="your_unique_start_date_id_announcement"
                            isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment()}
                            endDate={this.state.endDate ? moment(this.state.endDate) : ''}
                            endDateId="your_unique_end_date_id_announcement"
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate, endDate: endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                            small={true}
                            required
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                        />
                    </Col>
                    <Col className="text-right">
                        <CommonHelper help={<FormattedMessage id="TaskResult.Help" />} id={'TaskResult'} lang={this.props.intl.locale} />
                        <Button className="btn btn-sm btn-host" onClick={this.search}>
                            <i className="icon-icon-search"></i>
                        </Button>
                    </Col>

                </Row>
                <Row>
                    <Col className="h-100">

                        <CustomTable
                            keyField={'runId'}
                            data={runList ? runList : []}
                            columns={columns}
                            page={this.state.pageIndex + 1}
                            sizePerPage={this.state.pageSize}
                            totalSize={this.state.totalItems}
                            onTableChange={this.handleTableChange}
                            shadow={false}
                            search={false}
                            selectRow={selectRow}
                            remote={true}
                            showTotal={true}
                            rowStyle={{ cursor: 'pointer' }}
                            hideSizePerPage={false}
                        />
                    </Col>
                </Row>

                <Modal isOpen={this.state.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                    <Button onClick={() => this.toggleModal(null)} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        <BlankCard block={block} error={error}>
                            <Row className="mb-3">
                                <Col>
                                    <h5><FormattedMessage id="TaskResultDashboard.exception" /></h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.state.modalData}
                                </Col>
                            </Row>
                        </BlankCard>
                    </ModalBody>
                </Modal>
            </StyledCard>
        );
    }
}
export default injectIntl(TaskResultDashboard)
