import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Row, Label, FormGroup, Modal, ModalBody } from 'reactstrap';
import { BlankCard } from '../Base/CommonUIComponents';
import CustomSelect from '../Base/CustomSelect';
import { getGoContactActions } from '../Base/ReferenceDataFunctions';
import { deleteAPI, postAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';

export class GoContactImportModal extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        const reportTypes = getGoContactActions()
            .map(act => {
                const reportType = {
                    value: act.ownerType,
                    label: act.ownerType,
                    dataTypes: act.dataTypes
                };
                return reportType;
            })
            .slice(0, 2);

        this.state = {
            block: false,
            importBatch: this.props.importBatch ? this.props.importBatch : { id: 0, hotelGroupApiConnectionId: this.props.apiConnection },
            reportTypes: reportTypes
        };
    }

    removeImport = () => {
        const { toggle, removeOnTable } = this.props;
        const { importBatch } = this.state;

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                this.setState({ error: errorMessage, block: false });
                handleNotification(data, <FormattedMessage id="GoContactImport.RemoveImportSaved" />, <FormattedMessage id="generic.success" />);
                removeOnTable(importBatch);
                toggle();
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/GoContact/v1/ImportBatchRun/${importBatch.id}`);
    }

    saveImport = () => {
        if (!this.form.current.reportValidity()) {
            return;
        }

        const { toggle, updateTable } = this.props;
        const { importBatch } = this.state;

        if (!importBatch.hotelGroupApiConnectionId) {
            const dataError = {
                "errors": [
                    {
                        "code": "GMSGCO9999",
                        "message": <FormattedMessage id="GoContactImport.WithoutApiConnection" />
                    }
                ]
            };
            handleNotification(dataError);
            return;
        }

        this.setState({ block: true });

        const request = {
            request: importBatch
        };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.data) {
                    this.setState({ error: errorMessage, block: false });
                    handleNotification(data, <FormattedMessage id="GoContactImport.ImportSaved" />, <FormattedMessage id="generic.success" />);
                    updateTable(data.data[0]);
                    toggle();
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/GoContact/v1/ImportBatchRun`, request);
    }

    handleSelect = (name, combo) => {
        const { importBatch } = this.state;

        importBatch[name] = combo ? combo.value : null;

        this.setState({ importBatch });
    }

    handleReportTypeSelect = (name, combo) => {
        const { importBatch } = this.state;

        importBatch[name] = combo ? combo.value : null;
        importBatch.goContactTemplateReportId = null;
        importBatch.dataType = null;

        this.setState({ importBatch });
    }

    render() {
        const { isOpen, toggle, templates } = this.props;
        const { block, error, importBatch, reportTypes } = this.state;

        const templatesList = templates.flatMap(tmp => tmp.templates);

        return (
            <Modal isOpen={isOpen} fade={false} size={"lg"} style={{ minWidth: 0 }}>
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <form ref={this.form}>
                            <Row className="mb-3">
                                <Col>
                                    <h5>{importBatch.id ? <FormattedMessage id="GoContactImport.EditImport" /> : <FormattedMessage id="GoContactImport.CreateImport" />}</h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="mr-3 btn btn-sm btn-host" onClick={this.removeImport}>
                                        <i className="fa fa-trash-alt" />
                                    </Button>
                                    <Button className="btn btn-sm btn-host" onClick={this.saveImport}>
                                        <i className="fa fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col>
                                    <FormGroup row>
                                        <Label sm={3}> <FormattedMessage id="GoContactImport.ReportType" /></Label>
                                        <Col sm={9} >
                                            <CustomSelect
                                                options={reportTypes}
                                                required
                                                isClearable
                                                isSearchable
                                                onChange={this.handleReportTypeSelect.bind(this, 'reportType')}
                                                value={reportTypes.find(c => c.value === importBatch.reportType)}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col>
                                    <FormGroup row>
                                        <Label sm={3}> <FormattedMessage id="GoContactImport.Template" /></Label>
                                        <Col sm={9} >
                                            <CustomSelect
                                                options={templates.find(tmp => tmp.reportType === importBatch.reportType)?.templates}
                                                required
                                                isClearable
                                                isSearchable
                                                placeholder={""}
                                                onChange={this.handleSelect.bind(this, 'goContactTemplateReportId')}
                                                value={importBatch.goContactTemplateReportId !== null && templatesList.find(tmp => tmp.value === importBatch.goContactTemplateReportId)}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col>
                                    <FormGroup row>
                                        <Label sm={3}> <FormattedMessage id="GoContactImport.DataType" /></Label>
                                        <Col sm={9} >
                                            <CustomSelect
                                                options={reportTypes.find(rt => rt.value === importBatch.reportType)?.dataTypes}
                                                required
                                                isClearable
                                                isSearchable
                                                placeholder={""}
                                                onChange={this.handleSelect.bind(this, 'dataType')}
                                                value={importBatch.dataType !== null && reportTypes.filter(rt => rt.value === importBatch.reportType).flatMap(rt => rt.dataTypes).find(dt => dt.value === importBatch.dataType)}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}