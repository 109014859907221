//#region validate vCard text
export const isValidVCardText = (vCardText) => {
    var lines = vCardText.replace(/\n\s{1}/g, '').split(/\r\n(?=\S)|\r(?=\S)|\n(?=\S)/);

    if (lines[0].toString().toUpperCase() === "BEGIN:VCARD" && lines[lines.length - 1].toUpperCase().includes("END:VCARD")) {
        return true;
    }
    else {
        return false;
    }
}
//#endregion


//#region parse vCard text to json

export const vCardToJson = (vCardText) => {
    var json = {};

    // replace escaped new lines and split if a character is directly after a newline
    var lines = vCardText.replace(/\n\s{1}/g, '').split(/\r\n(?=\S)|\r(?=\S)|\n(?=\S)/);

    if (lines?.length > 0) {
        json = parseVCardText(lines);
    }

    return json
}


const parseVCardText = (lines) => {
    var json = {};

    lines.forEach(line => {
        // sometimes lines are prefixed by "item" keyword like "item1.ADR;type=WORK:....."
        if (line.substring(0, 4) === "item") {
            line = line.match(/item\d\.(.*)/)[1];
        }


        var pairs = line.split(':'),
            fieldName = pairs[0],
            fieldTypeInfo,
            fieldValue = pairs.slice(1).join(':');


        //additional info
        if (fieldName.indexOf(';') >= 0 && line.indexOf(';') < line.indexOf(':')) {
            var typeInfo = fieldName.split(';');
            fieldName = typeInfo[0];
            fieldTypeInfo = typeInfo.slice(1).map(function (type) {
                let info = type.split('=');

                return { name: info[0]?.toLowerCase(), value: info[1]?.replace(/"(.*)"/, '$1') };
            });
        }


        //handle field
        var fieldFunction = getFieldFunction[fieldName.toUpperCase()];

        if (fieldFunction) {
            const jsonFieldName = fieldPropertyMapping[fieldName.toUpperCase()] || fieldName;

            fieldFunction(json, fieldValue, jsonFieldName, fieldTypeInfo);
        }
    })

    return json;
}


const getFieldFunction = {
    "BEGIN": null,
    "VERSION": null,
    "N": structured(['lastName', 'firstName', 'additionalName', 'prefix', 'suffix']),
    "TITLE": singleLine,
    "TEL": typedLine,
    "EMAIL": typedLine,
    "ADR": addressLine,
    "NOTE": singleLine,
    "NICKNAME": commaSeparatedLine,
    "BDAY": dateLine,
    "URL": singleLine,
    "CATEGORIES": commaSeparatedLine,
    "FN": singleLine,
    "ORG": singleLine,
    "GENDER": singleLine,
    "LANG": singleLine,
    "END": null
};

var fieldPropertyMapping = {
    "TITLE": "title",
    "TEL": "contacts",
    "FN": "fullName",
    "N": "name",
    "EMAIL": "emails",
    "CATEGORIES": "categories",
    "ADR": "addresses",
    "URL": "url",
    "NOTE": "notes",
    "ORG": "organization",
    "BDAY": "birthday",
    "GENDER": "gender",
    "LANG": "language"
};


function singleLine(json, fieldValue, fieldName) {
    // convert escaped new lines to real new lines.
    fieldValue = fieldValue.replace('\\n', '\n');

    // append value if previously specified
    if (json[fieldName]) {
        json[fieldName] += '\n' + fieldValue;
    } else {
        json[fieldName] = fieldValue;
    }
}

function typedLine(json, fieldValue, fieldName, typeInfo, valueFormatter) {
    var isMain = false;

    if (typeInfo) {
        // strip type info and find out is that preferred value
        typeInfo = typeInfo.filter(function (type) {
            isMain = isMain || type.name.toUpperCase() === 'PREF';
            return type.name !== 'PREF';
        });

        typeInfo = typeInfo.reduce(function (p, c) {
            p[c.name] = c.value;
            return p;
        }, {});

    }

    json[fieldName] = json[fieldName] || [];

    json[fieldName].push({
        isMain: isMain,
        valueInfo: typeInfo,
        value: valueFormatter ? valueFormatter(fieldValue) : fieldValue
    });

}

function addressLine(json, fieldValue, fieldName, typeInfo) {
    const isMain = typeInfo?.some(el => el.name.toUpperCase() === 'PREF');

    typedLine(json, fieldValue, fieldName, typeInfo, function (value) {
        var names = value.split(';');

        return {
            // ADR field sequence
            isMain,
            postOfficeBox: names[0],
            number: names[1],
            street: names[2] || '',
            city: names[3] || '',
            region: names[4] || '',
            postalCode: names[5] || '',
            country: names[6] || ''
        };

    });
}

function commaSeparatedLine(json, fieldValue, fieldName) {
    json[fieldName] = fieldValue.split(',');
}

function dateLine(json, fieldValue, fieldName) {

    // if value is in "19531015T231000Z" format strip time field and use date value.
    fieldValue = fieldValue.length === 16 ? fieldValue.substr(0, 8) : fieldValue;

    var dateValue;

    if (fieldValue.length === 8) { // "19960415" format ?
        dateValue = new Date(fieldValue.substr(0, 4), fieldValue.substr(4, 2), fieldValue.substr(6, 2));
    } else {
        // last chance to try as date.
        dateValue = new Date(fieldValue);
    }

    if (!dateValue || isNaN(dateValue.getDate())) {
        dateValue = null;
        json.error('invalid date format ' + fieldValue);
    }

    json[fieldName] = dateValue && dateValue.toJSON(); // always return the ISO date format
}

function structured(fields) {
    return function (json, fieldValue, fieldName) {
        var values = fieldValue.split(';');

        json[fieldName] = fields.reduce(function (p, c, i) {
            p[c] = values[i] || '';
            return p;
        }, {});

    }

}

//#endregion