import React, { Component, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Card, Col, Row, Collapse, Badge, Button } from 'reactstrap';
import { getMasterTemplateFileType } from '../SalesProcess/SalesProcessFunctions';
import { DefaultPage } from '../Base/CommonUIComponents';
import moment from 'moment';
import { getAPIBlob } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import BlockUi from 'react-block-ui';


class TicketDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            ticketDetails: this.props.selectedTicket
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedTicket) {
            if (prevProps.selectedTicket?.ticket_id !== this.props.selectedTicket.ticket_id) {
                const ticketDetails = { ...this.props.selectedTicket };

                ticketDetails?.episodes && ticketDetails.episodes.forEach(el => {
                    el.isMessageOpen = false;
                    el.isAttachmentsOpen = false;
                });

                const chatWindow = document.getElementById("messageWindow");

                if (chatWindow) {
                    chatWindow.scrollTo(0, 0);
                }

                this.setState({ ticketDetails });
            }
        }
        else if (!this.props.selectedTicket && prevProps.selectedTicket) {
            this.setState({ ticketDetails: null });
        }
    }

    toggleCollapse = (name, index) => {
        const { ticketDetails } = this.state;

        ticketDetails.episodes[index][name] = !ticketDetails.episodes[index][name];

        this.setState({ ticketDetails });
    }


    render() {
        const { ticketDetails } = this.state;
        const { goContactMsgStatus, canGoBack, goBackFunction } = this.props;

        return (
            ticketDetails ?
                <Card body className="border-0 h-100 shadow" style={{ borderRadius: '5px' }}>
                    <div>
                        <Row className="align-items-center">
                            <Col>
                                <h5>
                                    {canGoBack ? <i className="fas fa-chevron-left mr-3 pointer" onClick={goBackFunction} /> : ''}
                                    <b className="color-host mr-2">#{ticketDetails.ticket_id}</b>
                                    {ticketDetails.subject}
                                </h5>
                            </Col>
                            <Col className="text-right col-2">
                                <Badge color="primary">{ticketDetails.status}</Badge>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <div className="title-sm"><FormattedMessage id="GoContactTicket.Contact"/></div>
                                {ticketDetails.contact_info?.contact || '-'}
                            </Col>
                            <Col>
                                <div className="title-sm"><FormattedMessage id="GoContactTicket.Email" /></div>
                                {ticketDetails.contact_info?.email || '-'}
                            </Col>
                            <Col>
                                <div className="title-sm"><FormattedMessage id="GoContactTicket.Phone" /></div>
                                {ticketDetails.contact_info?.first_phone || '-'}
                            </Col>
                            <Col>
                                <div className="title-sm"><FormattedMessage id="GoContactTicket.Source" /></div>
                                {ticketDetails.source_type || '-'}
                            </Col>
                        </Row>
                    </div>
                    <div className="mt-3">
                        <Row className="align-items-end">
                            <Col>
                                <b> <FormattedMessage id="Tickets.Messages" /></b>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn btn-sm btn-host"  >
                                    <i className="fa fa-envelope-open-text" />
                                </Button>
                                <Button className="btn btn-sm btn-host ml-2"  >
                                    <i className="fa fa-reply" />
                                </Button>
                            </Col>
                        </Row>

                        <hr />

                        <div className="verticalScroll" style={{ height: '60vh', overflowX: 'hidden' }} id="messageWindow">
                            {ticketDetails?.episodes?.length > 0 ?
                                <div className="p-3 h-100" style={{ backgroundColor: '#f7f7f7' }}>
                                    {ticketDetails?.episodes.map((episode, index) => {
                                        return (
                                            <div key={index}>
                                                {episode.type?.toUpperCase() === 'OUTBOUND' || episode.type?.toUpperCase() === 'INBOUND' ?
                                                    <TicketMessage
                                                        episode={episode}
                                                        goContactMsgStatus={goContactMsgStatus}
                                                        toggleCollapse={(name) => this.toggleCollapse(name, index)}
                                                    />
                                                    :
                                                    <TicketChanges
                                                        episode={episode}
                                                        goContactMsgStatus={goContactMsgStatus}
                                                    />
                                                }
                                                <div className="text-center text-muted"> | </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                <DefaultPage text="Tickets.NoMessagesAvailable" />}
                        </div>
                    </div>
                </Card>
                :
                <DefaultPage text="Tickets.SelectATicket" icon="fas fa-headset" />
        );
    }
}
export default injectIntl(TicketDetails);


const TicketMessage = ({ episode, goContactMsgStatus, toggleCollapse }) => {
    const [block, setBlock] = useState(false);

    const isOutbound = episode.type.toUpperCase() === 'OUTBOUND';
    const status = goContactMsgStatus.find(el => el.value.toUpperCase() === episode.status);

    const downloadFile = (attach) => {
        setBlock(true);

        getAPIBlob(result => {
            const { data, error, headers } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                setBlock(false);
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    setBlock(false);
                    return;
                }
                else {
                    const blob = new Blob([data], { type: headers["content-type"] });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');

                    a.href = url;
                    a.download = attach.filename;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                }

                setBlock(false);
                return;
            }
            else
                setBlock(false);
        }, `/api/gms/GoContact/v1/tickets/attachment/${attach.uuid}/download`)
    }

    return (
        <div className="my-3">
            <div className={"d-flex speechBubbleRow"} style={{ justifyContent: (isOutbound ? 'end' : 'start') }}>
                <div className={"speechBubble shadow " + (isOutbound ? 'right-point' : 'left-point')} style={{ width: '95%' }}>
                    <div className="px-2 pb-2 mb-1 border-bottom">
                        <Row className="mt-2 align-items-center">
                            <Col>
                                <Row>
                                    <Col className='text-left'>
                                        <div>
                                            {episode.from?.map((from, key) => <b key={key}> {from[1]}</b>)}

                                            {episode.ticket_tags?.map((tag, key) => <Badge className="ml-2 px-2 py-1" color="primary" key={key}> {tag}</Badge>)}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className={"col-2 text-right"}>
                                <Badge style={{ fontSize: 'smaller' }} className={(status ? status.badgeColor : 'badge-primary') + ' p-2'} >
                                    <b>{episode.status}</b>
                                </Badge>
                            </Col>
                        </Row>
                        <Row>
                            <Col>

                                <div className="title-sm mt-1">
                                    {episode.user_name}
                                </div>
                            </Col>
                            {episode.episode_subject &&
                                <Col className="text-center title-sm">
                                    <span>
                                        {episode.episode_subject}
                                    </span>
                                </Col>
                            }
                            <Col>
                                <div className="text-right title-sm mt-2">
                                    {episode.episode_create_date ? moment(episode.episode_create_date).format("YYYY-MM-DD HH:mm") : ''}
                                </div>
                            </Col>
                        </Row>
                    </div>


                    <div className="px-2 mt-2">
                        <Row>
                            <Col>
                                <div className="title-sm"> To </div>
                                {episode.sent_to?.map((to, key) => <div key={key}> {to[1]}</div>)}
                            </Col>
                            {episode.cc?.length > 0 &&
                                <Col>
                                    <div className="title-sm"> CC </div>
                                    {episode.cc?.map((cc, key) => <div key={key}> {cc[1]}</div>)}
                                </Col>
                            }
                        </Row>
                    </div>

                    <div className="px-3 mt-4">
                        <Row className="border-bottom py-2 pointer" style={{ backgroundColor: '#f3f3f3' }} onClick={_ => toggleCollapse('isMessageOpen')}>
                            <Col>
                                <i className="far fa-comment mr-2" />  <FormattedMessage id="Tickets.Message" />
                            </Col>
                            <Col className="text-right">
                                <i className="fas fa-chevron-down" />
                            </Col>
                        </Row>

                        {episode.isMessageOpen &&
                            <Collapse isOpen={episode.isMessageOpen}>
                            <div className="py-3">
                                {episode.close_comments ?
                                    <div dangerouslySetInnerHTML={{ __html: episode.close_comments }} />
                                    : episode.html_part ?
                                        <div dangerouslySetInnerHTML={{ __html: episode.html_part }} />
                                        :
                                        <div>{episode.text_part}</div>
                                }
                            </div>
                            </Collapse>
                        }
                    </div>

                    {episode.attachments?.length > 0 ?
                        <BlockUi tag="div" blocking={block}>
                            <div className="px-3 mt-3">
                                <Row className="border-bottom py-2 pointer" style={{ backgroundColor: '#f3f3f3' }} onClick={_ => toggleCollapse('isAttachmentsOpen')}>
                                    <Col>
                                        <i className="fas fa-paperclip mr-2" /> <FormattedMessage id="Tickets.Attachments" />
                                    </Col>
                                    <Col className="text-right">
                                        <i className="fas fa-chevron-down" />
                                    </Col>
                                </Row>

                                {episode.isAttachmentsOpen &&
                                    <Collapse isOpen={episode.isAttachmentsOpen}>
                                    <div className="py-3">
                                        <Row>
                                            {episode.attachments.map((file, key) => {
                                                const fileType = getMasterTemplateFileType().find(el => file.mimetype.includes(el.value.toLowerCase()) || file.filename.includes(el.value.toLowerCase()));

                                                return (
                                                    <Col className="d-flex align-items-center col-4 my-2" key={key}>
                                                        <div className="p-3 overbglight pointer d-flex justify-content-between w-100 align-items-center" onClick={_ => downloadFile(file)}>
                                                            <div className="d-flex align-items-center text-truncate">
                                                                <i className={`${fileType?.icon || 'far fa-file'} ml-2 mr-4`} style={{ fontSize: '25px' }} />

                                                                <div className="text-truncate" title={file.filename}>
                                                                    {file.filename}
                                                                </div>
                                                            </div>
                                                            <div className="text-right text-muted ml-2">
                                                                <i className="fas fa-download" />
                                                            </div>
                                                        </div>

                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </div>
                                    </Collapse>
                                }
                            </div>
                        </BlockUi>
                    : ''}
                </div>
            </div>
        </div>
    )
}

const TicketChanges = ({ episode, goContactMsgStatus }) => {
    const isSetAgent = episode.type?.toUpperCase() === "SETAGENT";
    const status = goContactMsgStatus.find(el => el.value.toUpperCase() === episode.status);
    
    return (
        <div className="my-3 w-50" style={{ margin: 'auto' }}>
            <Card className="border-0 shadow py-3" body>
                <Row className="align-items-center">
                    <Col className="col-1">
                        <Badge className={status?.badgeColor + " p-2"} style={{ fontSize: 'large' }}>
                            <i className={(isSetAgent ? 'fas fa-user-tag' : status?.icon ?? 'fas fa-random')} />
                        </Badge>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <b>{episode.text_part}</b>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="title-sm">
                                {episode.user_name}
                            </Col>
                            <Col className="title-sm text-right col-3 pl-0">
                                {episode.episode_create_date ? moment(episode.episode_create_date).format("YYYY-MM-DD HH:mm") : ''}
                            </Col>
                        </Row>

                        {episode.description &&
                            <Row>
                                <Col className="title-sm">
                                    "{episode.description}"
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </Card>
        </div>
    )
}