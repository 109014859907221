import moment from 'moment-timezone';
import React, { Component } from 'react'
import { KebabMenu } from '../../Base/CommonUIComponents';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export default class LoyaltyRule extends Component {

    constructor(props) {
        super(props);
    }

    getValueOptions = (type) => {
        return type === "SalesGroup" ? this.props.SalesGroupOptions :
        type === "PriceListCode" ? this.props.PriceListCodeList :
        type === "SubSegmentCodeCode" ? this.props.SubSegmentCodeCodesList :
        type === "SegmentCode" ? this.props.SegmentCodesList : 
        type === "DistChannelCode" ? this.props.DistributionChannelsList : this.props.ChannelList;
    }

    render() {
        const { toggleRuleModal, toggleRemoveRuleModal, rule, index, subTypeOptions, config, type, loyaltyCards } = this.props;
        
        return (
            <div className="mb-4 py-2 pl-3 mx-4" style={{ backgroundColor: '#f8f9fac9' }}>
                <Row>
                    <Col className="col-2">
                        <div className='title-sm mb-1'>
                            <FormattedMessage id="ConfigCardPoints.pointsType"/>
                        </div>
                    </Col>
                    <Col className="col-2">
                        <div className='title-sm mb-1'>
                            <FormattedMessage id="ConfigCardPoints.chargeType"/>
                        </div>
                    </Col>
                    <Col className='col-1'>
                        <div className='title-sm mb-1'>
                            <FormattedMessage id="ConfigCardPoints.Multiplier"/>
                        </div>
                    </Col>
                    <Col className='col-2'>
                        {rule.subType !== "All" && rule.codes && rule.codes.length > 0 ?
                            <div className='title-sm mb-1'>
                                <FormattedMessage id="ConfigCardPoints.Code"/>
                                <b className='ml-1'>{rule.codes.length}</b>
                            </div>
                        :''}
                    </Col>
                    <Col className="col-2">
                        {rule.filterType ?
                            <div className='title-sm mb-1'>
                                <FormattedMessage id={`ConfigCardPoints.${rule.filterType}`}/>
                            </div>
                        :''}
                    </Col>
                    <Col className='col-2'>
                        {rule.beginDate || rule.endDate ?
                            <div className="d-flex align-items-end">
                                {rule.beginDate ?
                                    <div className='title-sm mb-1'>
                                        <FormattedMessage id="ConfigCardPoints.beginDate"/>
                                    </div>
                                :''}
                                {rule.endDate && rule.beginDate ?
                                    <div style={{ visibility: 'hidden' }} className="mx-2">-</div>
                                :''}
                                {rule.endDate ?
                                    <div className='title-sm mb-1'>
                                        <FormattedMessage id="ConfigCardPoints.endDate"/>
                                    </div>
                                :''}
                            </div>
                        :
                            <div className='title-sm mb-1'>
                                <FormattedMessage id="ConfigCardPoints.Date"/>
                            </div>
                        }
                    </Col>
                </Row>
                <Row className="align-items-center" style={{ minHeight: '40px' }}>
                    <Col className="col-2">
                        <div>
                            <b>{type.find(({value}) => value === rule.type)?.label}</b>
                        </div>
                    </Col>
                    <Col className="col-2">
                        <div>
                            <b>{subTypeOptions.find(({value}) => value === rule.subType)?.label || <FormattedMessage id="ConfigCardPoints.All"/>}</b>
                        </div>
                    </Col>
                    <Col className='col-1 veryCoolScrollBar' style={{ maxHeight: '40px', overflow: 'auto' }}>
                        {rule.multiplier ?
                            <div>
                                <span className='mr-2'>
                                    <b><FormattedMessage id="ConfigCardPoints.All"/></b>
                                </span>
                                <span>{rule.multiplier}%
                                </span>
                            </div>
                        : !rule.loyaltyCardLevelRule || rule.loyaltyCardLevelRule.length === 0 ?
                          <b>-</b>
                        :''}
                        {rule.loyaltyCardLevelRule && rule.loyaltyCardLevelRule.length > 0 ?
                            rule.loyaltyCardLevelRule?.map((level, key) => {
                                const current = loyaltyCards.find(({ id }) => id === level.loyaltyCardLevelConfigId);
                                if(!current) return;
                                return (
                                    <div key={key}>
                                        <span className="mr-2">
                                            <b>{current.code}</b>
                                        </span>
                                        <span>{level.multiplier}%</span>
                                    </div>
                                )}
                            )
                        :''}
                    </Col>
                    <Col className='col-2'>
                        {rule.subType !== "All" && rule.codes && rule.codes.length > 0 ?
                            <div style={{ maxHeight: '40px', overflow: 'auto' }} className="veryCoolScrollBar">
                                {rule.codes.map((c, key) => 
                                    <div key={key}>
                                        <b>{c}</b>
                                    </div>
                                )}
                            </div>
                        :''}
                    </Col>
                    <Col className="col-2">
                        {rule.filterValue && rule.filterValue.length > 0 ?
                            <div style={{ maxHeight: '40px', overflow: 'auto' }} className="veryCoolScrollBar">
                                {rule.filterValue.map((c, key) => 
                                    <div key={key}>
                                        <b>{this.getValueOptions(rule.filterType)?.find(o => o.value === c)?.label}</b>
                                    </div>
                                )}
                            </div>
                        :''}
                    </Col>
                    <Col className='col-2'>
                        {rule.beginDate || rule.endDate ?
                            <div className="d-flex align-items-end">
                                {rule.beginDate ?
                                    <div>
                                        <div>
                                            <b>{moment(rule.beginDate).format('YYYY-MM-DD')}</b>
                                        </div>
                                    </div>
                                :''}
                                {rule.beginDate && rule.endDate ?
                                    <div className="mx-2">-</div>
                                :''}
                                {rule.endDate ?
                                    <div>
                                        <div>
                                            <b>{moment(rule.endDate).format('YYYY-MM-DD')}</b>
                                        </div>
                                    </div>
                                :''}
                            </div>
                        :
                            <div>
                                <FormattedMessage id="ConfigCardPoints.Always"/>
                            </div>
                        }
                    </Col>

                    <Col className="text-right col-1">
                        <div className="pr-4">
                            <KebabMenu
                                editFunction={_ => toggleRuleModal(config.id, rule.id)}
                                deleteFunction={_ => toggleRemoveRuleModal(rule.id)}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
