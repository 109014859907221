import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Card, Form, Button, Collapse } from 'reactstrap';
import { handleNotification } from "../../Base/Notification";
import { CoolTooltip, DefaultPage, SelectHotelGroup, StyledCard } from "../../Base/CommonUIComponents";
import { CheckAuthorization } from '../../Base/Authorization';
import { getAPI } from "../../Base/API";
import moment from 'moment';
import { getProcessIssuesTypes } from '../../Base/ReferenceDataFunctions';
import CustomSelect from '../../Base/CustomSelect';
import { DateRangePicker } from 'react-dates';
import { Pagination, ResultsPerPage, TotalResults } from '../../Base/PaginationComponents';

class ProcessIssues extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            processIssues: [],
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0,
            startDate: moment().subtract(7, 'days'),
            endDate: moment(),
            processIssueTypes: getProcessIssuesTypes(this.props.intl),
            openCollapses: [],
        };
    }

    componentDidMount() {
        this.getProcessIssues();
    }

    getProcessIssues = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true });

        const { startDate, endDate, type, hotelGroupId } = this.state;

        var params = "";
        if (startDate) params += `&fromDate=${moment(startDate).format("YYYY-MM-DD")}`;
        if (endDate) params += `&toDate=${moment(endDate).format("YYYY-MM-DD")}`;
        if (type) params += `&type=${type}`;
        if (hotelGroupId) params += `&hotelGroupId=${hotelGroupId}`;

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    processIssues: data.response,
                    totalItems: data.totalItems,
                    error: [],
                    block: false
                })
            }
            else this.setState({ block: false });
        }, `/api/gms/Hotel/v1/processIssues?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}`+ params)
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }


    doSearch = (e) => {
        e.preventDefault();

        this.setState({
            pageIndex: 0
        }, this.getProcessIssues);
    }
    
    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, this.getProcessIssues);
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0 }, this.getProcessIssues);
        }
    }

    copyErrorDetail = (errorDetail) => {
        if(!errorDetail || !navigator?.clipboard) return;

        navigator.clipboard.writeText(errorDetail);
        handleNotification({}, <FormattedMessage id="ProcessIssues.CopiedToClipboard" />, '', 'info');
    }

    openCollapse = (key) => {
        this.setState(({ openCollapses }) => ({ openCollapses: openCollapses.includes(key) ? openCollapses.filter(x => x !== key) : [...openCollapses, key] }));
    };

    render() {
        const { block, error, processIssues, processIssueTypes, startDate, endDate, type, pageIndex, pageSize, totalItems, openCollapses } = this.state;
        
        return (
            <StyledCard block={block} error={error} icon="fas fa-bug" title={"NavMenu.ProcessIssues"}>
                <Form onSubmit={this.doSearch}>
                    <Row className="mt-3 mb-4 align-items-center">
                        {CheckAuthorization("admin:view") &&
                            <Col sm={3}>
                                <SelectHotelGroup
                                    name="hotelGroupId"
                                    icon="icon-icon-hotel"
                                    onChangeFunc={this.handleChangeSelect}
                                    value={this.state.hotelGroupId || ''}
                                    placeholder={this.props.intl.formatMessage({ id: "generic.HotelGroup" })}
                                />
                            </Col>
                        }
                        <Col sm={3}>
                            <CustomSelect
                                icon={"fas fa-bug"}
                                options={processIssueTypes}
                                isClearable
                                value={type ? processIssueTypes.find(el => el.value === type) : ''}
                                isSearchable
                                placeholder={<FormattedMessage id="generic.Type" />}
                                onChange={(combo) => this.handleChangeSelect('type', combo)}
                            />
                        </Col>
                        <Col>
                            <DateRangePicker
                                startDate={startDate ? moment(startDate) : null}
                                startDateId="startDate"
                                isOutsideRange={day => day > moment()}
                                endDate={endDate ? moment(endDate) : null}
                                endDateId="endDate"
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate ? moment(startDate) : null, endDate: endDate ? moment(endDate) : null })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                showClearDates={true}
                                required={true}
                            />
                        </Col>

                        <Col className="text-right">
                            <Button className="btn-sm btn-host" type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                {processIssues && processIssues.length > 0 ?
                    <div>
                        <Card className=" shadow p-2 py-2 border-0 mb-2 text-muted" >
                            <Row className="px-2 align-items-center">
                                {CheckAuthorization("admin:view") &&
                                    <Col sm={2}> <FormattedMessage id="generic.HotelGroup" /> </Col>
                                }
                                <Col> <FormattedMessage id="generic.Hotel" /> </Col>
                                <Col> <FormattedMessage id="ProcessIssues.Date" /> </Col>
                                <Col> <FormattedMessage id="ProcessIssues.Type" /></Col>
                                <Col> <FormattedMessage id="ProcessIssues.EntityType" /> </Col>
                                <Col className='col-2'> <FormattedMessage id="ProcessIssues.EntityId" /> </Col>
                                <Col> <FormattedMessage id="ProcessIssues.Error" /> </Col>
                                <Col className='text-right text-muted pointer' />
                            </Row>
                        </Card>

                        {processIssues.map((issue, key) =>
                            <Card className=" shadow p-2 py-2 border-0 mb-2 overbglight" key={key}>
                                <Row className="px-2 align-items-center">
                                    {CheckAuthorization("admin:view") &&
                                        <Col sm={2}> {issue.hotelGroup ?? '-'} </Col>
                                    }
                                    <Col> {global.hotelList && issue.hotelId ? global.hotelList.find(h => h.value === issue.hotelId) ?? '-' : '-'} </Col>
                                    <Col> {issue.createdAt ? moment(issue.createdAt)?.format("YYYY-MM-DD HH:MM") || '-' : '-'} </Col>
                                    <Col>{issue.type ? <FormattedMessage id={`ProcessIssues.${issue.type}`} /> : '-' }</Col>
                                    <Col>{issue.entityType ? <FormattedMessage id={`ProcessIssues.${issue.entityType}`} /> : '-'}</Col>
                                    <Col className='col-2'> {issue.entityId} </Col>
                                    <Col> {issue.errorCode} </Col>
                                    <Col className='text-right text-muted pointer' onClick={() => this.openCollapse(key)}><i className={`fas fa-chevron-${openCollapses?.includes(key) ? 'up' : 'down'}`} /></Col>
                                </Row>
                                
                                <Collapse isOpen={openCollapses?.includes(key)}>
                                    <hr className='mb-1' />
                                    <Row className='px-2'>
                                        <Col className='title-sm col-12 text-muted'><FormattedMessage id="ProcessIssues.ErrorDetail" /></Col>
                                        <Col className='col-12'>
                                                <div> 
                                                    <span id={`errorDetail${key}`} className="pointer" onClick={() => this.copyErrorDetail(issue.errorDetail)}>
                                                        {issue.errorDetail ?? '-'} 
                                                    </span>
                                                    <CoolTooltip placement="bottom" target={`errorDetail${key}`}>
                                                        {issue.errorDetail}
                                                    </CoolTooltip>
                                                </div>
                                        </Col>
                                    </Row>
                                </Collapse>
                            </Card>
                        )}
                        
                        <Row className="my-4">
                            <Col>
                                <ResultsPerPage
                                    changeSizePerPage={this.changePageSize}
                                    pageSize={pageSize}
                                />

                                <span className="ml-2">
                                    <TotalResults
                                        end={pageIndex * pageSize + processIssues.length}
                                        start={pageIndex * pageSize + 1}
                                        total={totalItems}
                                    />
                                </span>
                            </Col>

                            <Col className="text-right">
                                <Pagination
                                    isPrevDisabled={pageIndex === 0}
                                    isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                    currentPage={pageIndex + 1}
                                    handlePrevButton={() => this.handlePagination(-1)}
                                    handleNextButton={() => this.handlePagination(1)}
                                />
                            </Col>
                        </Row>
                    </div>
                : <DefaultPage icon="fas fa-bug" text="ProcessIssues.ThereAreNoProcessIssues" /> }
            </StyledCard>
        );
    }
}
export default injectIntl(ProcessIssues)
