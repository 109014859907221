import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Collapse, Input, Label, Row } from 'reactstrap';
import CustomSelect from '../../../Base/CustomSelect';
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditHtmlModal } from './EditHtmlModal';
import { getYesNoOptions, setCountryLabelsDE, setCountryLabelsEN, setCountryLabelsES, setCountryLabelsFR, setCountryLabelsPT } from '../../../Base/ReferenceDataFunctions';
import countryList from 'react-select-country-list';
import { CoolTooltip } from '../../../Base/CommonUIComponents';

class FormContent extends Component {

    constructor(props) {
        super(props);

        const countries = countryList().getData();
        const countryChoices = countries.map(cnt => {
            const option = {
                value: cnt.value,
                text: {
                    default: cnt.label
                }
            };
            return option;
        });

        setCountryLabelsDE(countryList);
        countryChoices.forEach((cnt, idx) => cnt.text["de"] = countries[idx].label);
        setCountryLabelsFR(countryList);
        countryChoices.forEach((cnt, idx) => cnt.text["fr"] = countries[idx].label);
        setCountryLabelsES(countryList);
        countryChoices.forEach((cnt, idx) => cnt.text["es"] = countries[idx].label);

        if (localStorage.getItem("locale") === 'pt') {
            setCountryLabelsEN(countryList);
            countryChoices.forEach((cnt, idx) => cnt.text["en-gb"] = countries[idx].label);
            setCountryLabelsPT(countryList);
        }
        else {
            setCountryLabelsPT(countryList);
            countryChoices.forEach((cnt, idx) => cnt.text["pt"] = countries[idx].label);
            setCountryLabelsEN(countryList);
        }

        this.state = {
            headerContentCollapse: true,
            questionsCollapse: true,
            marketingConsentUrl: '',
            headerContent: [
                { name: 'header', value: null },
                { name: 'title', value: null, onFocusFunc: this.props.displayTitle },
                { name: 'description', value: null, onFocusFunc: this.handleDiscription },
                { name: 'completeText', value: null },
                { name: 'completedHtml', value: null },
            ],
            questions: [
                {
                    collapse: false,
                    title: {
                        "default": this.props.intl.formatMessage({ id: 'FormBuilder.FirstName' }),
                        "pt": "Primeiro Nome",
                        "en-gb": "First Name",
                        "es": "Nombre",
                        "fr": "Pr�nom",
                        "de": "Vorname"
                    },
                    placeholder: '',
                    isRequired: false,
                    type: "text",
                    name: "FirstName",
                    autocomplete: "given-name",
                    requiredErrorText: "Response required",
                    startWithNewLine: true
                },
                {
                    collapse: false,
                    title: {
                        "default": this.props.intl.formatMessage({ id: 'FormBuilder.LastName' }),
                        "pt": "�ltimo Nome",
                        "en-gb": "Last Name",
                        "es": "Apellido",
                        "fr": "Nom",
                        "de": "Nachname"
                    },
                    placeholder: '',
                    isRequired: false,
                    type: "text",
                    name: "LastName",
                    autocomplete: "family-name",
                    requiredErrorText: "Response required",
                    startWithNewLine: true
                },
                {
                    collapse: false,
                    title: {
                        "default": this.props.intl.formatMessage({ id: 'FormBuilder.Email' }),
                        "pt": "E-mail",
                        "en-gb": "E-mail",
                        "es": "Correo electr�nico",
                        "fr": "E-mail",
                        "de": "E-mail"
                    },
                    placeholder: '',
                    isRequired: true,
                    mandatory: true,
                    type: "text",
                    name: "Email",
                    inputType: "email",
                    autocomplete: "email",
                    requiredErrorText: "Response required",
                    startWithNewLine: true
                },
                {
                    collapse: false,
                    title: {
                        "default": this.props.intl.formatMessage({ id: 'FormBuilder.Mobile' }),
                        "pt": this.props.intl.formatMessage({ id: 'FormBuilder.MobilePT' }),
                        "en-gb": "Mobile",
                        "es": this.props.intl.formatMessage({ id: 'FormBuilder.MobileES' }),
                        "fr": "Mobile",
                        "de": "Mobil"
                    },
                    placeholder: '',
                    isRequired: false,
                    selected: false,
                    type: "mobile-country-code",
                    name: "Mobile",
                    inputType: "tel",
                    autocomplete: "tel",
                    requiredErrorText: "Response required",
                    startWithNewLine: true,
                    validators: [
                        { "type": "numeric" }
                    ]
                },
                {
                    collapse: false,
                    title: {
                        "default": this.props.intl.formatMessage({ id: 'FormBuilder.BirthDate' }),
                        "pt": "Data de Nascimento",
                        "en-gb": "Birth Date",
                        "es": "Fecha de Nacimiento",
                        "fr": "Date de Naissance",
                        "de": "Geburtsdatum"
                    },
                    placeholder: '',
                    isRequired: false,
                    type: "text",
                    name: "BirthDate",
                    inputType: "date",
                    autocomplete: "birthDate",
                    requiredErrorText: "Response required",
                    startWithNewLine: true,
                    "min": "1924-01-01",
                    "maxValueExpression": "today()"
                },
                {
                    collapse: false,
                    title: {
                        "default": this.props.intl.formatMessage({ id: 'FormBuilder.Gender' }),
                        "pt": this.props.intl.formatMessage({ id: 'FormBuilder.GenderPT' }),
                        "en-gb": "Gender",
                        "es": this.props.intl.formatMessage({ id: 'FormBuilder.GenderES' }),
                        "fr": "Genre",
                        "de": "Geschlecht"
                    },
                    isRequired: false,
                    type: "radiogroup",
                    typeOptions: [{ value: "radiogroup", label: "Radio" }, { value: "dropdown", label: "Dropdown" }],
                    choices: [
                        {
                            value: 'F',
                            text: this.props.intl.formatMessage({ id: 'ReferenceDataFunctions.Female' })
                        },
                        {
                            value: 'M',
                            text: this.props.intl.formatMessage({ id: 'ReferenceDataFunctions.Male' })
                        },
                        {
                            value: 'O',
                            text: this.props.intl.formatMessage({ id: 'ReferenceDataFunctions.Other' })
                        }
                    ],
                    name: "Gender",
                    selected: false,
                    requiredErrorText: "Response required",
                    startWithNewLine: true
                },
                {
                    collapse: false,
                    title: {
                        "default": this.props.intl.formatMessage({ id: 'FormBuilder.Nationality' }),
                        "pt": "Nacionalidade",
                        "en-gb": "Nationality",
                        "es": "Nacionalidad",
                        "fr": this.props.intl.formatMessage({ id: 'FormBuilder.NationalityFR' }),
                        "de": this.props.intl.formatMessage({ id: 'FormBuilder.NationalityDE' })
                    },
                    placeholder: this.props.intl.formatMessage({ id: 'FormBuilder.SelectNationality' }),
                    isRequired: false,
                    selected: false,
                    type: "dropdown",
                    name: "Nationality",
                    requiredErrorText: "Response required",
                    startWithNewLine: true,
                    choices: countryChoices
                },
                {
                    collapse: false,
                    title: {
                        "default": this.props.intl.formatMessage({ id: 'FormBuilder.VAT' }),
                        "pt": "NIF",
                        "en-gb": "VAT",
                        "es": "NIF",
                        "fr": "FNI",
                        "de": "NIF"
                    },
                    placeholder: '',
                    isRequired: false,
                    selected: false,
                    type: "text",
                    name: "VAT",
                    requiredErrorText: "Response required",
                    startWithNewLine: true
                },
                {
                    collapse: false,
                    title: {
                        "default": this.props.intl.formatMessage({ id: 'FormBuilder.Mailings' }),
                        "pt": "Consentimento de Envios de Email",
                        "en-gb": "Mailings Consent",
                        "es": this.props.intl.formatMessage({ id: 'FormBuilder.MailingsES' }),
                        "fr": "Consentement par Courriel",
                        "de": "E-Mail-Zustimmung"
                    },
                    isRequired: false,
                    type: "boolean",
                    typeOptions: [{ value: "boolean", label: "Boolean" }, { value: "checkbox", label: "Checkbox" }],
                    name: "Mailings",
                    selected: false,
                    requiredErrorText: "Response required",
                    marketingConsentUrl: '',
                    startWithNewLine: true
                },
                {
                    collapse: false,
                    title: {
                        "default": this.props.intl.formatMessage({ id: 'FormBuilder.GenericConsent' }),
                        "pt": this.props.intl.formatMessage({ id: 'FormBuilder.GenericConsentPT' }),
                        "en-gb": "Generic Consent",
                        "es": this.props.intl.formatMessage({ id: 'FormBuilder.GenericConsentES' }),
                        "fr": this.props.intl.formatMessage({ id: 'FormBuilder.GenericConsentFR' }),
                        "de": "Allgemeine Zustimmung"
                    },
                    isRequired: false,
                    type: "boolean",
                    typeOptions: [{ value: "boolean", label: "Boolean" }, { value: "checkbox", label: "Checkbox" }],
                    name: "General",
                    selected: false,
                    requiredErrorText: "Response required",
                    marketingConsentUrl: '',
                    startWithNewLine: true
                },
                {
                    collapse: false,
                    title: {
                        "default": this.props.intl.formatMessage({ id: 'FormBuilder.Comment' }),
                        "pt": this.props.intl.formatMessage({ id: 'FormBuilder.CommentPT' }),
                        "en-gb": "Comment",
                        "es": "Comentario",
                        "fr": "Commentaire",
                        "de": "Kommentar"
                    },
                    isRequired: false,
                    type: "comment",
                    name: "Comment",
                    placeholder: '',
                    selected: false,
                    requiredErrorText: "Response required",
                    validators: [{
                        type: "text",
                        "maxLength": 500
                    }],
                    startWithNewLine: true
                },
                {
                    collapse: false,
                    type: "html",
                    name: "HtmlText",
                    selected: false,
                    html: {}
                }
            ],
            editorStates: ['default']
                .map(lng => {
                    const language = {
                        country: lng,
                        editorState: null
                    };
                    return language;
                })
                .concat(this.props.comboLanguagesOptions.map(lng => {
                    const language = {
                        country: lng.value,
                        editorState: null
                    };
                    return language;
                })),
            editHtmlModal: false
        }
    }

    getEditorState = (text) => {
        const blocksFromHtml = htmlToDraft(text);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        return EditorState.createWithContent(contentState);
    }

    componentDidMount() {
        const { formQuestions, survey } = this.props;
        const { questions, headerContent, editorStates } = this.state;
        var marketingConsentUrl = '';

        if (formQuestions) {
            const supportedQuestions = questions.map(q => q.name);
            formQuestions.forEach(q => {
                if(supportedQuestions.includes(q.name)){
                    const current = questions.find(({name}) => name === q.name);
                    
                    current.selected = true;
                    current.isRequired = q.isRequired;
                    current.title = q.title;
                    current.placeholder = q.placeholder;
                    current.description = q.description;
                    current.labelTrue = q.labelTrue;
                    current.labelFalse = q.labelFalse;
                    current.requiredErrorText = q.requiredErrorText;
                    current.type = q.type;
                    current.choices = q.choices;
                    current.startWithNewLine = q.startWithNewLine;
                    current.hideNumber = q.hideNumber;

                    if (current && (current.name === 'Mailings' || current.name === 'General')){
                        current.marketingConsentUrl = q.marketingConsentUrl;
                        marketingConsentUrl = q.marketingConsentUrl;
                    }

                    if (current && current.html) {
                        Object
                            .keys(q.html)
                            .forEach((country, idx) => {
                                const editorState = editorStates.find(edt => edt.country === country);
                                if (editorState) {
                                    editorState.editorState = this.getEditorState(Object.values(q.html)[idx]);
                                }
                            })
                        current.html = q.html;
                    }
                }
                else{
                    q.name = q.name;
                    questions.push(q);
                }
            });
        }

        if(survey){
            if(survey.title)
                headerContent[0].value = survey.title;
            if(survey.surveyTitle)
                headerContent[1].value = survey.surveyTitle;
            if(survey.description)
                headerContent[2].value = survey.description;
            if(survey.completeText)
                headerContent[3].value = survey.completeText;
            if(survey.completedHtml)
                headerContent[4].value = survey.completedHtml;        
        }

        this.setState({ questions, headerContent, marketingConsentUrl });
    }

    toggleCollapse = (index) => {
        const { questions } = this.state;
        questions[index].collapse = !questions[index].collapse;
        this.setState({ questions });
    }

    toggleHeaderContentCollapse = () => {
        const { headerContentCollapse } = this.state;
        this.setState({ headerContentCollapse: !headerContentCollapse });
    }
    
    toggleQuestionsCollapse = () => {
        const { questionsCollapse } = this.state;
        this.setState({ questionsCollapse: !questionsCollapse });
    }

    toggleBtnCollapse = () => {
        const { completeBtnCollapse } = this.state;
        this.setState({ completeBtnCollapse: !completeBtnCollapse });
    }
    
    handleHeaderContentChange = (e) => {
        const { headerContent } = this.state;
        const { activeCountryTab } = this.props;
        const { name, value } = e.target;
        const index = headerContent.findIndex(c => c.name === name);

        if(headerContent[index].name === 'header'){
            headerContent[index].value = value;
            this.setState({ headerContent }, () => this.props.updateRawTitleDescComplete(name, value));
        }else{

            if(headerContent[index].value === undefined || headerContent[index].value === null || typeof headerContent[index].value === 'string'){
                headerContent[index].value = {};
            }

            if (activeCountryTab === 'default') {
                if (index === 2) {
                    headerContent[index].value[activeCountryTab] = value === '' || value === undefined || value === null ? ' ' : value;
                }
                else {
                    headerContent[index].value[activeCountryTab] = value;
                }
            } else {
                if (index === 2 && (headerContent[index].value['default'] === null || headerContent[index].value['default'] === '' || headerContent[index].value['default'] === undefined)) {
                    headerContent[index].value['default'] = ' '
                } 
                headerContent[index].value[activeCountryTab] = value;
            }

            this.setState({ headerContent }, () => this.props.updateRawTitleDescComplete(name, headerContent[index].value));
        }
    }

    handleCheck = (e, k) => {
        const { questions } = this.state;
        questions[k].selected = e.target.checked;
        this.setState({ questions }, () => this.props.handleQuestionChange(questions[k], k));
    }

    handleQuestionChange = (e, k, choiceIndex) => {
        const { questions } = this.state;
        const { activeCountryTab } = this.props;
        let { name, value } = e.target;
        var isValidUrl = false;

        const isConsentField = questions[k].name === 'Mailings' || questions[k].name === 'General';

        if (name === 'type') {
            questions[k][name] = value;

            if (value === 'checkbox') {
                questions[k].choices = [
                    {
                        value: isConsentField ? questions[k].name : 'Item 1', //Campo usado para o css dos consentimentos
                        text: questions[k].title || {}
                    }];

                delete questions[k].labelFalse;
                delete questions[k].labelTrue;
                questions[k].title = ' ';
            }
            else if (value === 'boolean') {
                questions[k].title = questions[k].choices[0].text;

                delete questions[k].choices;
            }
        }
        else if (name === 'htmlText') {
            questions[k].html = value;
        }
        else {
            if (this.state.marketingConsentUrl && typeof this.state.marketingConsentUrl === 'string') {
                isValidUrl = this.props.isValidUrl(this.state.marketingConsentUrl);
            } else if (this.state.marketingConsentUrl && this.state.marketingConsentUrl[activeCountryTab]) {
                isValidUrl = this.props.isValidUrl(this.state.marketingConsentUrl[activeCountryTab]);
            }
            
            if (questions[k][name] === undefined || questions[k][name] === null || typeof questions[k][name] === 'string') {
                questions[k][name] = {};
            }

            if (questions && questions[k] && isConsentField && (name === 'title' || name === 'description' || name === 'choices') && value) {
                if (this.state.marketingConsentUrl && typeof this.state.marketingConsentUrl !== 'string') {
                    if (this.state.marketingConsentUrl[activeCountryTab] && this.state.marketingConsentUrl[activeCountryTab] !== '' && isValidUrl) {
                        value += ` <a href="${this.state.marketingConsentUrl[activeCountryTab] ?? this.state.marketingConsentUrl['default']}" target="_blank" ${name === 'description' ? 'class="descriptionURL"' : ''}><i class="fas fa-info-circle infoIcon"></i></a>`;
                    }
                } else if (this.state.marketingConsentUrl) {
                    if (this.state.marketingConsentUrl !== '' && isValidUrl) {
                        value += ` <a href="${this.state.marketingConsentUrl}" target="_blank" ${name === 'description' ? 'class="descriptionURL"' : ''}><i class="fas fa-info-circle infoIcon"></i></a>`;
                    }
                }
            }

            if ((name === 'labelTrue' || name === 'labelFalse') && value === '') {
                delete questions[k][name][activeCountryTab];
            } else
                if (name === 'choices') {
                    if(questions[k].choices[choiceIndex].text === undefined || questions[k].choices[choiceIndex].text === null || typeof questions[k].choices[choiceIndex].text === 'string'){
                        questions[k].choices[choiceIndex].text = {};
                    }
                    questions[k].choices[choiceIndex].text[activeCountryTab] = value;
                }
                else {
                    questions[k][name][activeCountryTab] = value;
            }
        }

        this.setState({ questions }, () => this.props.handleQuestionChange(questions[k], k));
    }

    handleQuestionCheck = (e, k) => {
        const { questions } = this.state;
        questions[k].isRequired = e.target.checked;
        this.setState({ questions }, () => this.props.handleQuestionChange(questions[k], k));
    }

    handleHideQuestionNumber = (e, k) => {
        const { questions } = this.state;
        questions[k].hideNumber = e.target.checked;
        this.setState({ questions }, () => this.props.handleQuestionChange(questions[k], k));
    }

    handleStartWithNewLine = (e, k) => {
        const { questions } = this.state;
        questions[k].startWithNewLine = e.target.checked;
        this.setState({ questions }, () => this.props.handleQuestionChange(questions[k], k));
    }

    getHeaderContent = (obj) => {
        const { activeCountryTab } = this.props;
        let value = null;
        if(obj.name === 'header'){
            value = obj.value;
        }else if(obj.value){
            value = obj.value[activeCountryTab];
        }

        return value ?? '';
    }

    getQuestionsContent = (obj, type) => {
        const { activeCountryTab } = this.props;
        const isConsent = obj.name === 'Mailings' || obj.name === 'General';

        let value = null;

        if(!obj[type] || typeof obj[type] === 'string'){
            value = obj[type];
        }else if(obj[type]){
            value = obj[type][activeCountryTab];
        }

        if ((type === 'title' || type === 'description' || type === 'text') && obj && isConsent && value){
            const parts = value.split(' <a href=');
            if(parts && parts.length > 0){
                return parts[0];
            }
        }

        return value ?? '';
    }

    handleMarketingConsentUrlChange = (e, k) => {
        const { questions } = this.state;
        const { activeCountryTab } = this.props;        

        const isUrlValid = this.props.isValidUrl(e.target.value);
        const marketingUrl = document.getElementById(`marketingConsentUrlField${k}`);

        if(marketingUrl){
            marketingUrl.setCustomValidity("");
            if(!isUrlValid && e.target.value !== "") {
                marketingUrl.setCustomValidity(this.props.intl.formatMessage({ id: "FormBuilder.MarketingConsentError" }));
                marketingUrl.reportValidity();
            }
        }


        if(questions[k]['marketingConsentUrl'] === undefined || questions[k]['marketingConsentUrl'] === null || typeof questions[k]['marketingConsentUrl'] === 'string'){
            questions[k]['marketingConsentUrl'] = {};
        }

        questions[k]['marketingConsentUrl'][activeCountryTab] = e.target.value;


        if (questions[k].type === 'checkbox') {
            const choice = questions[k].choices[0];

            if (choice.text === undefined || choice.text === null || typeof choice.text === 'string') {
                choice.text = { [activeCountryTab]: choice.text ?? '' };
            }
            
            if (choice.text[activeCountryTab] || choice.text[activeCountryTab] === '') {
                if (!choice.text[activeCountryTab].includes('<a href=') && isUrlValid) {
                    choice.text[activeCountryTab] += ` <a href="${e.target.value}" target="_blank"><i class="fas fa-info-circle infoIcon"></i></a>`;
                }
                else {
                    const parts = choice.text[activeCountryTab].split(' <a href=');

                    if (parts && parts.length > 1) {
                        if (!(e.target.value) || e.target.value === '') {
                            choice.text[activeCountryTab] = parts[0];
                        } else {
                            if (isUrlValid) {
                                choice.text[activeCountryTab] = parts[0] + ` <a href="${e.target.value}" target="_blank"><i class="fas fa-info-circle infoIcon"></i></a>`;
                            } else {
                                choice.text[activeCountryTab] = parts[0];
                            }
                        }
                    } else {
                        if (isUrlValid) {
                            choice.text[activeCountryTab] = ` <a href="${e.target.value}" target="_blank"><i class="fas fa-info-circle infoIcon"></i></a>`;
                        }
                    }
                }
            }

            questions[k].choices[0] = choice;
        }
        else {
            const title = questions[k]['title'];
            const description = questions[k]['description'];

            if (questions[k]['title'] === undefined || questions[k]['title'] === null || typeof questions[k]['title'] === 'string') {
                questions[k]['title'] = {};
            }
            if (questions[k]['description'] === undefined || questions[k]['description'] === null || typeof questions[k]['description'] === 'string') {
                questions[k]['description'] = {};
            }

            if (!title || typeof title === 'string') {
                questions[k]['title'][activeCountryTab] = title ?? '';
            } else {
                questions[k]['title'][activeCountryTab] = title[activeCountryTab];
            }
            if (!description || typeof description === 'string') {
                questions[k]['description'][activeCountryTab] = description ?? '';
            } else {
                questions[k]['description'][activeCountryTab] = description[activeCountryTab];
            }

            if (questions[k]['title'][activeCountryTab] || questions[k]['title'][activeCountryTab] === '') {
                if (!questions[k]['title'][activeCountryTab].includes('<a href=') && isUrlValid) {
                    questions[k]['title'][activeCountryTab] += ` <a href="${e.target.value}" target="_blank"><i class="fas fa-info-circle infoIcon"></i></a>`;
                } else {
                    const parts = questions[k]['title'][activeCountryTab].split(' <a href=');
                    if (parts && parts.length > 1) {
                        if (!(e.target.value) || e.target.value === '') {
                            questions[k]['title'][activeCountryTab] = parts[0];
                        } else {
                            if (isUrlValid) {
                                questions[k]['title'][activeCountryTab] = parts[0] + ` <a href="${e.target.value}" target="_blank"><i class="fas fa-info-circle infoIcon"></i></a>`;
                            } else {
                                questions[k]['title'][activeCountryTab] = parts[0];
                            }
                        }
                    } else {
                        if (isUrlValid) {
                            questions[k]['title'][activeCountryTab] = ` <a href="${e.target.value}" target="_blank"><i class="fas fa-info-circle infoIcon"></i></a>`;
                        }
                    }
                }
            }

            if (questions[k]['description'][activeCountryTab] || questions[k]['description'][activeCountryTab] === '') {
                if (!questions[k]['description'][activeCountryTab].includes('<a href=') && isUrlValid) {
                    questions[k]['description'][activeCountryTab] += ` <a href="${e.target.value}" target="_blank" class="descriptionURL"><i class="fas fa-info-circle infoIcon"></i></a>`;
                } else {
                    const parts = questions[k]['description'][activeCountryTab].split(' <a href=');
                    if (parts && parts.length > 1) {
                        if (!(e.target.value) || e.target.value === '') {
                            questions[k]['description'][activeCountryTab] = parts[0];
                        } else {
                            if (isUrlValid) {
                                questions[k]['description'][activeCountryTab] = parts[0] + ` <a href="${e.target.value}" target="_blank" class="descriptionURL"><i class="fas fa-info-circle infoIcon"></i></a>`;
                            } else {
                                questions[k]['description'][activeCountryTab] = parts[0];
                            }
                        }
                    } else {
                        if (isUrlValid) {
                            questions[k]['description'][activeCountryTab] = ` <a href="${e.target.value}" target="_blank" class="descriptionURL"><i class="fas fa-info-circle infoIcon"></i></a>`;
                        }
                    }
                }
            }
        }

        this.setState({ questions, marketingConsentUrl: questions[k]['marketingConsentUrl'] }, () => this.props.checkInvalidUrl(questions[k]));
    }

    onEditorStateChange = (newEditorState, k) => {
        const { activeCountryTab } = this.props;
        const { editorStates } = this.state;
        const editorStateIdx = editorStates.findIndex(stt => stt.country === activeCountryTab);
        if (editorStateIdx >= 0) {
            editorStates[editorStateIdx].editorState = newEditorState;
            this.setState({ editorStates });
        }
    }

    onBlurEditor = (evt, k) => {
        if (evt) {
            const { activeCountryTab, handleQuestionChange } = this.props;
            const { editorStates, questions } = this.state;

            const editorState = editorStates.find(edt => edt.country === activeCountryTab);
            if (editorState && editorState.editorState.getCurrentContent()) {
                questions[k].html[activeCountryTab] = draftToHtml(convertToRaw(editorState.editorState.getCurrentContent()));

                this.setState({ questions }, () => handleQuestionChange(questions[k], k));
            }
        }
    }

    toggleModal = () => {
        this.setState(prevState => ({ editHtmlModal: !prevState.editHtmlModal }));
    }

    handleDiscription = () => {
        const { activeCountryTab } = this.props;
        const { headerContent } = this.state;

        if (!headerContent[1].value || !headerContent[1].value.default) {
            headerContent[1].value = {
                name: 'title',
                value: {
                    [activeCountryTab]: '_'
                }
            };
            this.setState({ headerContent }, () => {
                this.props.updateRawTitleDescComplete('title', headerContent[1].value);
                this.props.hideTitle();
            })
        }
    }    

    render() {
        const { headerContentCollapse, headerContent, questions, questionsCollapse, editorStates, editHtmlModal } = this.state;
        const { activeCountryTab, comboLanguagesOptions, intl, handleChangeAutoConsentType, survey, autoConsentTypes } = this.props;

        return (
            <div>
                <div className='pb-2'>
                    <Row className="pointer align-items-center">
                        <Col className='col-8' onClick={this.toggleHeaderContentCollapse}>
                            <i className={"fas mr-3" + (headerContentCollapse ? " fa-angle-down" : " fa-angle-right")} />
                            <b><FormattedMessage id="FormBuilder.HeaderContent"/></b>
                        </Col>
                        { activeCountryTab !== 'default' &&
                            <Col className='col-4 mt-1 '>
                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                    <CustomSelect
                                        className="w-100"
                                        isSearchable={false}
                                        options={comboLanguagesOptions}
                                        onChange={(combo) => this.props.changeSelectLanguages(combo)}
                                        value={comboLanguagesOptions.find(l => l.value === activeCountryTab) ?? null}
                                        placeholder={intl.formatMessage({ id: "FormBuilder.SelectLanguage" })}
                                        required
                                    />
                                </div>
                            </Col>
                        }
                    </Row>
                    <Collapse isOpen={headerContentCollapse}>
                        
                        {headerContent.map((c, k) =>
                            <div key={k} className='mt-3'>
                                <Label className='title-sm'><FormattedMessage id={`FormBuilder.${c.name}`}/></Label>
                                <Input 
                                    type="text"
                                    value={this.getHeaderContent(c)}
                                    name={c.name}
                                    onChange={this.handleHeaderContentChange}
                                    placeholder={''}
                                    disabled={c.name === 'header' && activeCountryTab !== 'default'}
                                    onFocus={c.onFocusFunc}
                                />
                            </div>
                        )}
                        <div className='mt-3'>
                            <Label className='title-sm'><FormattedMessage id="PNFormDetails.AutoConsent" /></Label>
                            <CustomSelect
                                id="anonymous"
                                options={autoConsentTypes}
                                required={true}
                                isSearchable={false}
                                value={autoConsentTypes.find(el => el.value === survey.autoConsentType) || ''}
                                onChange={e => handleChangeAutoConsentType('autoConsentType', e)}
                                placeholder={''}
                            />
                        </div>
                    </Collapse>
                </div>
                <hr/>
                <div className='py-2'>
                    <div className="pointer" onClick={this.toggleQuestionsCollapse}>
                        <i className={"fas mr-3" + (questionsCollapse ? " fa-angle-down" : " fa-angle-right")}/>
                        <b><FormattedMessage id="FormBuilder.Questions"/></b>
                    </div>
                    <Collapse isOpen={questionsCollapse}>
                        <div className='mt-4 shadow'>
                            {questions.map((q, k) => {
                                const isConsentField = q.name === 'Mailings' || questions[k].name === 'General';

                                return <div key={k}>
                                    {k ? <hr className='m-0' /> : ''}
                                    <div className='py-2 px-2'>
                                        <div className={"d-flex px-2 py-2 pointer"} onClick={_ => this.toggleCollapse(k)}>
                                            <div>
                                                <span>
                                                    <i className={"fas mr-3" + (q.collapse ? " fa-angle-down" : " fa-angle-right")} />
                                                    <FormattedMessage id={"FormBuilder." + q.name} />
                                                </span>
                                            </div>
                                            {q.name !== 'Email' ?
                                                <div>
                                                    <Input
                                                        onClick={e => e.stopPropagation()}
                                                        type="checkbox"
                                                        checked={q.selected}
                                                        id={k + "-Field-" + q.name}
                                                        className='ml-3'
                                                        onChange={e => this.handleCheck(e, k)}
                                                        disabled={activeCountryTab !== 'default'}
                                                    />
                                                </div>
                                                : ''}
                                            {isConsentField ?
                                                <div id={`consentsQuestion-${k}`} className="btn-sm btn-link ml-auto">
                                                    <i className="text-secondary fas fa-question-circle" />
                                                    <CoolTooltip target={`consentsQuestion-${k}`} placement="bottom">
                                                        <FormattedMessage id="PNForms.ValidityConsents" />
                                                    </CoolTooltip>
                                                </div>
                                                : ''}
                                        </div>
                                        <Collapse isOpen={q.collapse}>
                                            <div className='px-4'>
                                                {
                                                    q.type === 'html' ?
                                                        <div>
                                                            {
                                                                editHtmlModal ?
                                                                    <EditHtmlModal
                                                                        modal={editHtmlModal}
                                                                        toggle={this.toggleModal}
                                                                        editorState={editorStates.find(stt => stt.country === activeCountryTab)?.editorState}
                                                                        onEditorStateChange={this.onEditorStateChange}
                                                                        onBlurEditor={this.onBlurEditor}
                                                                        k={k}
                                                                    />
                                                                    :
                                                                    <div />
                                                            }
                                                            <Row>
                                                                <Col>
                                                                    <span>
                                                                        <FormattedMessage id="FormBuilder.EditHtml" />
                                                                        <Button onClick={this.toggleModal} className="btn-host btn-sm ml-2">
                                                                            <i className="fas fa-edit" />
                                                                        </Button>
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        :
                                                        <div>
                                                            {q.typeOptions?.length > 0 ?
                                                                <div className='mb-3'>
                                                                    <Label className='title-sm'><FormattedMessage id={`FormBuilder.Type`} /></Label>
                                                                    <CustomSelect
                                                                        className="w-100"
                                                                        isSearchable={false}
                                                                        options={q.typeOptions}
                                                                        onChange={(combo) => this.handleQuestionChange({ target: { name: 'type', value: combo ? combo.value : null } }, k)}
                                                                        value={q.typeOptions.find(l => l.value === q.type) ?? null}
                                                                        placeholder=""
                                                                        required
                                                                    />
                                                                </div>
                                                                : ''}

                                                            {q.type === 'checkbox' ?
                                                                <>
                                                                    {q.choices.map((choice, key) =>
                                                                        <div key={key}>
                                                                            <Label className='title-sm'><FormattedMessage id={`FormBuilder.ChoiceLabel`} /></Label>
                                                                            <Input
                                                                                className="test"
                                                                                type="text"
                                                                                value={this.getQuestionsContent({ ...choice, name: q.name }, 'text')}
                                                                                name={'choices'}
                                                                                onChange={e => this.handleQuestionChange(e, k, key)}
                                                                                placeholder={''}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </>
                                                                :
                                                                q.type === 'radiogroup' || q.type === 'dropdown' ?
                                                                    <>
                                                                        <div>
                                                                            <Label className='title-sm'><FormattedMessage id={`FormBuilder.Label`} /></Label>
                                                                            <Input
                                                                                type="text"
                                                                                value={this.getQuestionsContent(q, 'title')}
                                                                                name={'title'}
                                                                                onChange={e => this.handleQuestionChange(e, k)}
                                                                                placeholder={''}
                                                                            />
                                                                        </div>
                                                                        <div className='mt-3'>
                                                                            <Label className='title-sm'><FormattedMessage id={`FormBuilder.Description`} /></Label>
                                                                            <Input
                                                                                type="text"
                                                                                value={this.getQuestionsContent(q, 'description')}
                                                                                name={'description'}
                                                                                onChange={e => this.handleQuestionChange(e, k)}
                                                                                placeholder={''}
                                                                                maxLength="500"
                                                                            />
                                                                        </div>
                                                                        {q.name !== questions[6].name && q.choices && q.choices.map((choice, key) =>
                                                                            <div key={key} className='mt-3'>
                                                                                <Label className='title-sm'><FormattedMessage id={`FormBuilder.ChoiceLabel`} /></Label>
                                                                                <Input
                                                                                    className="test"
                                                                                    type="text"
                                                                                    value={this.getQuestionsContent({ ...choice, name: q.name }, 'text')}
                                                                                    name={'choices'}
                                                                                    onChange={e => this.handleQuestionChange(e, k, key)}
                                                                                    placeholder={''}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div>
                                                                            <Label className='title-sm'><FormattedMessage id={`FormBuilder.Label`} /></Label>
                                                                            <Input
                                                                                type="text"
                                                                                value={this.getQuestionsContent(q, 'title')}
                                                                                name={'title'}
                                                                                onChange={e => this.handleQuestionChange(e, k)}
                                                                                placeholder={''}
                                                                            />
                                                                        </div>
                                                                        <div className='mt-3'>
                                                                            <Label className='title-sm'><FormattedMessage id={`FormBuilder.Description`} /></Label>
                                                                            <Input
                                                                                type="text"
                                                                                value={this.getQuestionsContent(q, 'description')}
                                                                                name={'description'}
                                                                                onChange={e => this.handleQuestionChange(e, k)}
                                                                                placeholder={''}
                                                                                maxLength="500"
                                                                            />
                                                                        </div>
                                                                    </>
                                                            }
                                                            {q.type ?
                                                                q.type === "boolean" ?
                                                                    <>
                                                                        <div className='mt-3'>
                                                                            <Label className='title-sm'><FormattedMessage id={`FormBuilder.labelTrue`} /></Label>
                                                                            <Input
                                                                                type="text"
                                                                                value={this.getQuestionsContent(q, 'labelTrue')}
                                                                                name={'labelTrue'}
                                                                                onChange={e => this.handleQuestionChange(e, k)}
                                                                                placeholder={''}
                                                                            />
                                                                        </div>
                                                                        <div className='mt-3'>
                                                                            <Label className='title-sm'><FormattedMessage id={`FormBuilder.labelFalse`} /></Label>
                                                                            <Input
                                                                                type="text"
                                                                                value={this.getQuestionsContent(q, 'labelFalse')}
                                                                                name={'labelFalse'}
                                                                                onChange={e => this.handleQuestionChange(e, k)}
                                                                                placeholder={''}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                    : q.type !== "checkbox" &&
                                                                    <div className='mt-3'>
                                                                        <Label className='title-sm'><FormattedMessage id={`FormBuilder.Placeholder`} /></Label>
                                                                        <Input
                                                                            type="text"
                                                                            value={this.getQuestionsContent(q, 'placeholder')}
                                                                            name={'placeholder'}
                                                                            onChange={e => this.handleQuestionChange(e, k)}
                                                                            placeholder={''}
                                                                        />
                                                                    </div>
                                                                : ''}

                                                            <div className='mt-3'>
                                                                <Label className='title-sm'><FormattedMessage id={`FormBuilder.requiredErrorText`} /></Label>
                                                                <Input
                                                                    type="text"
                                                                    value={this.getQuestionsContent(q, 'requiredErrorText')}
                                                                    name={'requiredErrorText'}
                                                                    onChange={e => this.handleQuestionChange(e, k)}
                                                                    placeholder={''}
                                                                />
                                                            </div>
                                                            {isConsentField ?
                                                                <div className='mt-3'>
                                                                    <Label className='title-sm'><FormattedMessage id={`FormBuilder.MarketingConsentLink`} /></Label>
                                                                    <Input
                                                                        type="text"
                                                                        value={this.getQuestionsContent(q, 'marketingConsentUrl')}
                                                                        name={'MarketingLink'}
                                                                        onChange={e => this.handleMarketingConsentUrlChange(e, k)}
                                                                        placeholder={''}
                                                                        disabled={!this.getQuestionsContent(q, 'title') && !(q.choices && this.getQuestionsContent({ ...q.choices[0], name: q.name }, 'text'))}
                                                                        id={`marketingConsentUrlField${k}`}
                                                                    />
                                                                </div>
                                                                : ''}
                                                            <div className='mt-3'>
                                                                <Label className='title-sm'><FormattedMessage id={`FormBuilder.HideQuestionNumber`} /></Label>
                                                                <Input
                                                                    type="checkbox"
                                                                    checked={q.hideNumber}
                                                                    className='ml-3'
                                                                    onChange={e => this.handleHideQuestionNumber(e, k)}
                                                                    disabled={activeCountryTab !== 'default'}
                                                                />
                                                            </div>
                                                            <div className='mt-3'>
                                                                <Label className='title-sm'><FormattedMessage id={`FormBuilder.StartWithNewLine`} /></Label>
                                                                <Input
                                                                    type="checkbox"
                                                                    checked={q.startWithNewLine}
                                                                    className='ml-3'
                                                                    onChange={e => this.handleStartWithNewLine(e, k)}
                                                                    disabled={activeCountryTab !== 'default'}
                                                                />
                                                            </div>
                                                            <div className='mt-3'>
                                                                <Label className='title-sm'><FormattedMessage id={`FormBuilder.Required`} /></Label>
                                                                <Input
                                                                    type="checkbox"
                                                                    checked={q.isRequired}
                                                                    className='ml-3'
                                                                    onChange={e => this.handleQuestionCheck(e, k)}
                                                                    disabled={q.mandatory || activeCountryTab !== 'default'}
                                                                />
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                            }
                            )}
                        </div>
                    </Collapse>
                </div>
            </div>
        )
    }
}

export default injectIntl(FormContent);