
import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Row, Label, Input, UncontrolledTooltip, Collapse } from 'reactstrap';
import { getAPI, postAPI } from "../Base/API";
import { StyledCard } from "../Base/CommonUIComponents";
import { handleNotification } from '../Base/Notification';
import { getRegularExpressionType } from "../Base/ReferenceDataFunctions";
import moment from 'moment';
import CustomSelect from '../Base/CustomSelect';

export class RegExDetail extends Component {

    constructor(props) {
        super(props);
        this.form= React.createRef();
        this.saveRule = this.saveRule.bind(this);
        this.state = {
            block: true,
            rule: {},
            textSample: null, 
            matchText: false,
            highlightText: null,
            transformText: null,
            hotel: null,
            maxLength: 4000,
            charCount: 0,
            templatesUsingRule: [],
            hotelGroupList: [],
            showRuleUsageTable: false,
            called: false
        };
    }

    componentDidMount() {
        const { location, match } = this.props;

        if (location.rule) {
            this.setState({ rule: location.rule, charCount: location.rule.regularExpression.length }, () => {
                this.getHotelGroups();
            });
        }
        else if (match.params && match.params.id) {
            this.getRule(match.params.id);
        }
        else {
            this.setState({ block: false, rule: {} });
        }
    }

    getRule = (id) => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false, rule: {} });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                this.setState({ error: errorMessage, rule: data.response[0], charCount: data.response[0].regularExpression.length }, () => {
                    this.getHotelGroups();
                });
            }
            else {
                this.setState({ error: errorMessage, block: false, rule: {} });
            }
        }, `/api/gms/DataQuality/v1/regularExpressionRule/${id}`);
    }

    getRuleUsage = (id) => {
        const { rule, called } = this.state;
        if((!rule?.id) || (called)) return;
        
        this.setState({ block: true, called: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false, rule: {} });
                    return;
                }
                if (data?.response?.length > 0) {
                    const ruleUsage = data.response[0];
                    const templatesUsingRule = ruleUsage.templates || [];
                
                    this.setState({ error: errorMessage, block: false, templatesUsingRule, showRuleUsageTable: true });
                }
                else {
                    this.setState({ error: errorMessage, block: false, rule: {} });
                }
            }, `/api/gms/DataQuality/v1/regularExpressionRule/${rule.id}/usage?regexType=${rule?.regularExpressionType}`);
        });
    }

    getHotelGroups = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({  message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data?.response?.length > 0) {
                const hotelGroupList = data.response.map(el => {
                    return { value: el.hotelGroupId, label: el.name }
                });

                this.setState({ hotelGroupList, block: false });
                return;
            }
            this.setState({ block: false });
        }, `/api/gms/Hotel/v1/hotelgroup/list?pageSize=1000`);
    }

    saveRule() {
        if (!this.form.current.reportValidity()) {
            return;
        }

        let payload = { request: this.state.rule };
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const rule = data.response && data.response.length > 0 ? data.response[0] : {};

                handleNotification(data, <FormattedMessage id="RegExDetail.UpdateSucess" />, <FormattedMessage id="generic.success" />);

                this.setState({ rule });
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/gms/DataQuality/v1/regularExpressionRule', payload);
    }

    filterData(event) {
        event.preventDefault();

        try {
            let ignoreRes = event.target.value;
            let applyRes = "";
            let matchText = false;
            let replaceRes = "";

            const { regularExpressionTransform, regularExpression } = this.state.rule;

            if (regularExpressionTransform) {
                let rule = regularExpression;
                let changeTo = regularExpressionTransform;
                var flags = rule.replace(/.*\/([gimy]*)$/, '$1');
                var pattern = rule.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1');
                var regex = new RegExp(pattern, flags);

                if (replaceRes && replaceRes.length > 0) {
                    replaceRes = replaceRes.replace(regex, changeTo);
                }
                else {
                    replaceRes = ignoreRes.replace(regex, changeTo);
                }
            }


            if (regularExpression) {
                let regexString = regularExpression;

                var flags = regexString.replace(/.*\/([gimy]*)$/, '$1');
                var pattern = regexString.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1');
                var regex = new RegExp(pattern, flags);

                applyRes = ignoreRes.replace(regex, (match) => `<b className="color-green" id="match">${match}</b>`);

                let html = new DOMParser().parseFromString(applyRes, "text/html")

                if (html && html.body && html.body.firstChild && html.body.firstChild.id === "match") {
                    matchText = true;
                }
            }

            this.setState({
                textSample: event.target.value,
                applyRes: applyRes,
                matchText,
                replaceRes: replaceRes
            });

        }
        catch (e) {
            console.log(e);
        }

    }

    handleChange(evt) {
        const value = evt.target ? evt.target.value : null;
        const name = evt.target.name;

        this.setState({
            rule: {
                ...this.state.rule,
                [name]: value
            }
        });
    };

    handleChangeRegex(evt) {
        const value = evt.target ? evt.target.value : null;
        const name = evt.target.name;

        this.setState({
            rule: {
                ...this.state.rule,
                [name]: value
            }, charCount: evt.target.value.length
        });
    };

    handleChangeCombo = (name, el) => {
        const value = el ? el.value : null;
        this.setState({
            rule: {
                ...this.state.rule,
                [name]: value
            }
        });
    };

    toggleRuleUssagePopOver = () => {
        const { called } = this.state;
        if(!called){
            this.getRuleUsage();  
        }else{
            this.setState({ showRuleUsageTable: !this.state.showRuleUsageTable });
        } 
    }


    render() {
        const { block, error, rule, charCount, maxLength, templatesUsingRule, hotelGroupList, showRuleUsageTable } = this.state;
        
        return (

            <StyledCard block={block} error={error} icon={'fas fa-list-ol'} title={'RuleDetail.title'}>
                <Row className="mb-1">
                    <Col className="d-flex align-items-center justify-content-end">
                        {!isNaN(rule?.id) ?
                            <>
                                <Button disabled={!rule?.id} className="btn btn-sm btn-host mr-2" onClick={() => this.toggleRuleUssagePopOver()} >
                                    <i className={`fas fa-eye${showRuleUsageTable ? '-slash' : ''}`} ></i>
                                </Button>
                            </>
                        :''}
                        <Button className="btn btn-sm btn-host" onClick={this.saveRule.bind(this)}>
                            <i className="fas fa-save" ></i>
                        </Button>
                    </Col>
                </Row>
                <form ref={this.form}>
                    <Row>
                        <Col>
                            <b>Rule Definiton</b>
                            <hr className="my-1" />
                            <Row className="mb-2">
                                <Col className="col-3"><Label> Name</Label></Col>
                                <Col><Input type="text" name="code" required value={this.state.rule.code} onChange={(evt) => this.handleChange(evt)}> </Input> </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-3"><Label> Description</Label></Col>
                                <Col><Input type="text" name="description" required value={this.state.rule.description} onChange={(evt) => this.handleChange(evt)}> </Input> </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-3"><Label> Type </Label></Col>
                                <Col>
                                    <CustomSelect
                                        options={getRegularExpressionType()}
                                        required
                                        isClearable
                                        value={this.state.rule.regularExpressionType && getRegularExpressionType().find(el => el.value === this.state.rule.regularExpressionType)}
                                        onChange={this.handleChangeCombo.bind(this, 'regularExpressionType')}
                                        isSearchable
                                        placeholder={''}
                                    />
                                </Col>
                            </Row>
                       
                            <Row className="mb-2">
                                <Col className="col-3"><Label> Regular Expression </Label></Col>
                                <Col>
                                    <Input type="textarea" name="regularExpression" maxLength={4000} required value={this.state.rule.regularExpression || ''} onChange={(evt) => this.handleChangeRegex(evt)}>  </Input>
                                    <p className="mt-1" style={{ fontSize: '10px', float: 'right' }}><b>{charCount}/{maxLength}</b></p>
                                </Col>
                            </Row>

                            {this.state.rule.regularExpressionType && this.state.rule.regularExpressionType === 'Transform' ?
                                <Row className="mb-2">
                                    <Col className="col-3"><Label> Transform Regular Expression </Label></Col>
                                    <Col><Input type="textarea" name="regularExpressionTransform" required value={this.state.rule.regularExpressionTransform || ''} onChange={(evt) => this.handleChange(evt)}>  </Input> </Col>
                                </Row>
                            : ''}
                        </Col>

                        <Col>
                            <b> <FormattedMessage id="RuleDetail.RuleValidation" /></b>
                            <hr className="my-1" />
                            <Row className="mb-2">
                                <Col className="col-3"><Label> <FormattedMessage id="RuleDetail.FilledSample" /></Label></Col>
                                <Col><Input type="textarea" rows="2" onChange={(e) => this.filterData(e)}  > </Input> </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-3"><Label> <FormattedMessage id="RuleDetail.RuleAfterIgnore" /></Label></Col>
                                <Col>{this.state.ignoreRes}</Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-3"><Label> <FormattedMessage id="RuleDetail.RuleDetection" /></Label></Col>
                                <Col><div dangerouslySetInnerHTML={{ __html: this.state.applyRes }}></div></Col>
                                <Col className="col-1 text-center">
                                    {this.state.matchText ?
                                        <>
                                            <i className="color-green fas fa-check-circle" id="check-circle" />

                                            <UncontrolledTooltip placement="left" target="check-circle" >
                                                <FormattedMessage id="RuleDetail.ValidText" />
                                            </UncontrolledTooltip>
                                        </>
                                        : <>
                                            <i className="color-light-red fas fa-times-circle" id="times-circle"></i>

                                            <UncontrolledTooltip placement="left" target="times-circle" >
                                                <FormattedMessage id="RuleDetail.RuleNotApplied" />
                                            </UncontrolledTooltip>
                                        </>}
                                </Col>
                            </Row>
                            {rule.regularExpressionTransform &&
                                <Row className="mb-2">
                                    <Col className="col-3"><Label> <FormattedMessage id="RuleDetail.RuleReplacement" /></Label></Col>
                                    <Col>
                                        {this.state.replaceRes}
                                    </Col>
                                </Row>
                            }
                        </Col>
                        <Col className='col-12 p-0'>
                            <Collapse isOpen={showRuleUsageTable}>
                                <Col className='col-12 mt-3'>
                                    <b>Rule Usage</b>
                                    <hr className='mt-1' />
                                    <Row className="mb-2">  
                                        <Col>
                                            {templatesUsingRule?.length > 0 ? (
                                                <table className="table table-bordered table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Description</th>
                                                            {hotelGroupList?.length > 0 ? <th>Hotel Group</th> : ''}
                                                            <th>Last Run</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {templatesUsingRule.map((el, index) => (
                                                            <tr key={index}>
                                                                <td>{el.name || '-'}</td>
                                                                <td>{el.description || '-'}</td>
                                                                {hotelGroupList?.length > 0 ? <td>{hotelGroupList.find(hg => hg.value === el.hotelGroupId)?.label || '-'}</td> : ''}
                                                                <td>{moment(el.lastRun)?.isAfter(moment().subtract(10, 'years')) ? moment(el.lastRun)?.format('DD/MM/YYYY HH:mm') || '-' : '-'}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <Col className='col-12 d-flex align-items-center justify-content-center'>No templates using this rule</Col>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Collapse>
                        </Col>
                    </Row>
                </form>
            </StyledCard>
        );
    }
}
export default injectIntl(RegExDetail)
