import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Badge, Col, Collapse, Row, Button, Card, Input, Modal, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip } from 'reactstrap';
import { getProfileType, getConsentTypeList, getReviewFields } from '../../Base/ReferenceDataFunctions';
import { handleNotification } from '../../Base/Notification';
import countryList from "react-select-country-list";
import CustomSelect from "../../Base/CustomSelect";
import { ErrorAlert } from '../../Base/ErrorAlert';
import Authorization from '../../Base/Authorization';
import { getAPI, postAPI } from '../../Base/API';
import { DateRangePicker } from 'react-dates';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import MapModal from '../../Base/MapModal';
import { ReviewRange, UniqueCampaignsDates } from '../Common/MarketingFunctions';
import { SelectHotel, SelectVipCodes } from '../../Base/CommonUIComponents';
import  CampaignTags  from '../Campaigns/CampaignTags';
import  CampaignExcludedTags  from '../Campaigns/CampaignExcludedTags';
import CampaignTargetModal from './CampaignTargetModal';


class CustomStep3Filters extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            tagTab: 0,
            selectedRegion: [],
            totalItems: 0,
            pageIndex: 0,
            pageSize: 15000,
            profiles: [],
            modalMap: false,
            loyaltyCardsOptions: [],
            reviewIsOpen: false,
            activeTab: '0',
            targetsModal: false
        };
    }

    componentDidMount() {
        this.getTags();
        this.getLevels();
    }

    getTags = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    this.setState({ tagList: data.response });
                }

                this.setState({ block: false });
            }
        }, `/api/gms/Profile/v1/tag/groupDetails?tagType=all`)
    }

    getLevels = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage });

                return;
            }
            if (data) {
                const loyaltyCardsOptions = data.response
                    .sort((a, b) => a.minRoomNightsToLevel + a.minPointsToLevel - b.minRoomNightsToLevel - b.minPointsToLevel)
                    .map(opt => {
                        const loyaltyCardsOption = {
                            value: opt.code,
                            label: opt.description,
                        };
                        return loyaltyCardsOption;
                    });

                this.setState({ loyaltyCardsOptions: loyaltyCardsOptions });
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardLevelConfig`);
    }

    searchProfiles = (e, showProfiles, cb) => {
        e.preventDefault();

        const { campaign, form, setProfiles } = this.props;

        const consentTypeForm = document.getElementById("consentTypeForm");
        consentTypeForm.setCustomValidity("");

        if (!form.current.reportValidity()) {
            return;
        }

        this.setState({ block: true });

        var body = {...this.props.filters};

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                if (error.request && error.request.status === 504) {
                    handleNotification({}, <FormattedMessage id="CustomStep3Filters.CustomMarketingTimeout" />, <FormattedMessage id="CustomStep3Filters.Timeout" />, 'info');
                }
                else {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                }
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else if (data.totalItems !== null) {
                    handleNotification(data, <FormattedMessage id="CustomCampaign.WereFoundResults" values={{ results: data.totalItems }} />, <FormattedMessage id="generic.success" />);
                    this.setState({ profiles: data.response ? data.response : [], error: errorMessage, block: false }, _ => { if (showProfiles) { cb(); } setProfiles(data.totalItems); });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Marketing/v1/CustomMarketing?contactType=${campaign.senderChannel === "SMS" ? "Mobile" : campaign.senderChannel}${campaign.consentType ? `&consentType=${campaign.consentType}` : ``}&showProfiles=${showProfiles}&pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}`, body);
    }

    getSelectedNationalities = () => {
        var options = countryList().getData();
        var nationalities = []

        this.props.filters.nationalities && this.props.filters.nationalities.forEach(c => {
            const nat = options.find(o => o.value === c);

            if (nat) {
                nationalities.push(nat);
            }
        })

        return nationalities;
    }

    handleLoyaltyCard = (value) => {
        const { filters } = this.props;

        if (filters.hasLoyaltyCard === value) {
            value = null;
        }

        this.props.handleLoyaltyCard({ target: { value: value } });
    }

    handleVipCodes = (value) => {
        const { filters } = this.props;

        if (filters.hasVipCodes === value) {
            value = null;
        }

        this.props.handleVipCodes({ target: { value: value } });
    }   
    
    handleConsent = (value) => {
        const { campaign, handleChange } = this.props;

        if (campaign.consentType === value) {
            value = null;
        }

        handleChange({ target: { name: 'consentType', value: value } });
    }   

    renderMonthElement = ({ month, onYearSelect }) => {

        let i;
        let years = [];
        for(i = moment().year(); i >= moment().year() - 122; i--) {
            years.push(<option onClick={(e) => onYearSelect(month, e.target.id)} id={i} key={`year-${i}`}>{i}</option>);
        };

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Row>
                    <Col>
                        {moment(month).locale(this.props.intl.locale).format('MMMM')}
                    </Col>
                    <Col>
                        <select onChange={(e) => onYearSelect(month, e.target.value)} value={month.year()}>
                            {years}
                        </select>
                    </Col>
                </Row>
            </div>
        );
    }

    getCoordinates = () => {
        navigator.geolocation.getCurrentPosition(position => {
            this.props.handleChangeLatLong(position.coords.latitude, position.coords.longitude)
        }, showError);

        function showError(error) {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    handleNotification(error.code, <FormattedMessage id="MapModal.PermissionDenied" />, '', 'info');
                    break;
                case error.POSITION_UNAVAILABLE:
                    handleNotification(error.code, <FormattedMessage id="MapModal.PositionUnvailable" />, '', 'info');
                    break;
                case error.TIMEOUT:
                    handleNotification(error.code, <FormattedMessage id="MapModal.Timeout" />, '', 'info');
                    break;
                case error.UNKNOWN_ERROR:
                    handleNotification(error.code, <FormattedMessage id="MapModal.UnknownError" />, '', 'info');
                    break;
            }
        }
    }

    mapOnClick = (latitude, longitude) => {
        this.props.handleChangeLatLong(latitude, longitude)
    }

    toggleModalMap = () => {
        this.setState(prevstate => ({
            modalMap: !prevstate.modalMap
        }));
    }

    exportProfiles = () => {
        const { profiles } = this.state;

        const columns = `${this.props.intl.formatMessage({ id: "CustomStep3Filters.Id" })},${this.props.intl.formatMessage({ id: "CustomStep3Filters.FullName" })},${this.props.intl.formatMessage({ id: "CustomStep3Filters.Contact" })},${this.props.intl.formatMessage({ id: "CustomStep3Filters.Country" })},${this.props.intl.formatMessage({ id: "CustomStep3Filters.PmsGlobalId" })}`
        const data = profiles.reduce((acc, prf) => `${acc}${prf.id},${prf.fullName},${prf.contact},${prf.country},${prf.pmsGlobalId}\r\n`, `${columns}\r\n`);
        const csvContent = `data:text/csv;charset=utf-8,${data}`;

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "ProfileExport.csv");
        link.click();
    }

    toggleReviewIsOpen = () => {
        this.setState(prevState => ({ reviewIsOpen: !prevState.reviewIsOpen }));
    }

    toggle(id) {
        this.setState({ activeTab: id })
    }

    toggleTargetsModal = () => {
        this.setState(prevState => ({ targetsModal: !prevState.targetsModal }));
    }

    applyCampaignTarget = (params, campaignTargetDetails) => {
        this.setState({ targetsModal: false }, this.props.applyCampaignTarget(params, campaignTargetDetails));
    }

    render() {
        const { filters, handleSelect, handleNationalities, selectType, handleTag, handleTagType, handleExcludedTag, handleDates, handleChangeFilters, campaign, form,
            handleChangeFiltersArray, isDisabledSelectHotelIds, handleOtaEmail, changeTagMin } = this.props;
        const { activeTab, tagList, loyaltyCardsOptions, reviewIsOpen, targetsModal } = this.state;

        const loyaltyCardOptions = [
            { value: true, label: <FormattedMessage id="CampaignDetails.ProfilesWithCard" /> },
            { value: false, label: <FormattedMessage id="CampaignDetails.ProfilesWithoutCard" /> }
        ];

        const vipCodesOptions = [
            { value: true, label: <FormattedMessage id="CampaignDetails.ProfilesWithVipCodes" /> },
            { value: false, label: <FormattedMessage id="CampaignDetails.ProfilesWithoutVipCodes" /> }
        ];

        const otaEmailsOptions = [
            { value: true, label: <FormattedMessage id="CustomCampaign.ProfilesWithOtaEmails" /> },
            { value: false, label: <FormattedMessage id="CustomCampaign.ProfilesWithoutOtaEmails" /> }
        ];

        const distanceTypeOptions = [
            { value: 'GreaterThanOrEqual', label: <FormattedMessage id="ReferenceDataFunctions.GreaterThanOrEqual" /> },
            { value: 'LessThanOrEqual', label: <FormattedMessage id="ReferenceDataFunctions.LessThanOrEqual" /> }
        ];

        return (
            <BlockUi tag="div" blocking={this.state.block}>

                <Modal isOpen={this.state.modalMap} fade={false} className="modal-lg " >
                    <Button onClick={this.toggleModalMap} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody className="modal-lg p-3">
                        <h5 className="mb-3"> <FormattedMessage id="CustomCampaign.SelectAPoint" /> </h5>

                        <MapModal
                            latitude={filters.latitude}
                            longitude={filters.longitude}
                            distance={filters.distance}
                            onClick={this.mapOnClick}
                        />
                    </ModalBody>
                </Modal>

                {targetsModal ?
                    <CampaignTargetModal
                        isOpen={targetsModal}
                        toggle={this.toggleTargetsModal}
                        applyCampaignTarget={this.applyCampaignTarget}
                        senderChannel={campaign.senderChannel}
                    />
                :''}

                <ErrorAlert error={this.state.error} />

                <Row>
                    <Col className="col-8">
                        <Authorization
                            perform="customCampaign:latLongDistance"
                            yes={() => (
                                <>
                                    <Row className="my-2 px-2">
                                        <Col className="px-2">
                                            <b><FormattedMessage id="CustomCampaign.Latitude" /></b>
                                        </Col>
                                        <Col className="px-2">
                                            <b> <FormattedMessage id="CustomCampaign.Longitude" /></b>
                                        </Col>
                                        <Col sm={1} />
                                        <Col className="pr-2 pl-4">
                                            <b> <FormattedMessage id="CustomCampaign.Distance" /></b>
                                        </Col>
                                        <Col className="px-2">
                                            <b> <FormattedMessage id="CustomCampaign.Range" /></b>
                                        </Col>
                                    </Row>
                                    <Row className="px-2" style={{ height: '50px' }}>
                                        <Col className="px-2">
                                            <Input
                                                type="number"
                                                name="latitude"
                                                step="any"
                                                value={filters.latitude || ''}
                                                onChange={handleChangeFilters}
                                            />
                                        </Col>
                                        <Col className="px-2">
                                            <Input
                                                type="number"
                                                name="longitude"
                                                step="any"
                                                value={filters.longitude || ''}
                                                onChange={handleChangeFilters}
                                            />
                                        </Col>
                                        <Col className="col-1 pt-1 px-2">
                                            <Button color=" btn-host btn-sm mx-1" onClick={this.getCoordinates} > <i className="fas fa-map-marker-alt" /> </Button>
                                            <Button color=" btn-host btn-sm" onClick={this.toggleModalMap} > <i className="far fa-map" /> </Button>
                                        </Col>
                                        <Col className="pr-2 pl-4">
                                            <CustomSelect
                                                options={distanceTypeOptions}
                                                isClearable
                                                value={filters.distanceComparisonType ? filters.distanceComparisonType === 'GreaterThanOrEqual' ? distanceTypeOptions[0] : distanceTypeOptions[1] : ''}
                                                isSearchable
                                                placeholder={''}
                                                onChange={(combo) => handleSelect('distanceComparisonType', combo)}
                                            />
                                        </Col>
                                        <Col className="px-2 d-flex">
                                            <Input
                                                type="number"
                                                name="distance"
                                                step="any"
                                                value={filters.distance || ''}
                                                onChange={handleChangeFilters}
                                            /> <span className="pt-2 pl-2" style={{ position: 'absolute', right: '20px' }}> Km </span>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                    <Col className="col-1" />
                    <Col className="col-3 pt-1">
                        <Row className="mb-2">
                            <Col className="col-8">
                                <b>
                                    <FormattedMessage id="CampaignDetails.OtaEmails" />
                                </b>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn btn-host btn-sm mr-1" id="CampaignTargets" onClick={this.toggleTargetsModal}>
                                    <i className="fas fa-hat-wizard" />
                                </Button>
                                <UncontrolledTooltip placement="left" target="CampaignTargets">
                                    <FormattedMessage id="CampaignDetails.CampaignTargets"/>
                                </UncontrolledTooltip>
                                
                                <Button className="btn btn-host btn-sm mr-1" id="Csv" onClick={(e) => this.searchProfiles(e, true, this.exportProfiles)}>
                                    <i className="fas fa-file-csv" />
                                </Button>
                                <UncontrolledTooltip placement="left" target="Csv">
                                    <FormattedMessage id="CampaignDetails.ExportCSV"/>
                                </UncontrolledTooltip>

                                <Button className="btn btn-host btn-sm" id="search" onClick={(e) => this.searchProfiles(e, false)}>
                                    <i className="icon-icon-search" />
                                </Button>
                                <UncontrolledTooltip placement="left" target="search">
                                    <FormattedMessage id="CampaignDetails.Search"/>
                                </UncontrolledTooltip>
                            </Col>
                        </Row>
                        <Row>
                            {otaEmailsOptions.map((option, key) =>
                                <Col key={key} className="pl-1">
                                    <Card className="border-0 shadow pointer" body style={filters.hasOtaEmail === option.value ? { backgroundColor: '#d3eaff' } : {}} onClick={() => handleOtaEmail(filters.hasOtaEmail == option.value ? null : option.value)}>
                                        {option.label}
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>                

                <Row className="my-2 align-items-center">
                    <Col className="col-9">
                        <b>
                            <FormattedMessage id="CampaignDetails.Consent" />
                        </b>
                    </Col>
                    <Col className="col-2">
                        <b>
                            <FormattedMessage id="CampaignDetails.LoyaltyCard" />
                        </b>
                    </Col>
                    <Col className="col-1">
                    </Col>
                </Row>
                <form ref={form}>
                    <Row className="mb-3 px-2">
                        <Col className="col-8">
                            <Row>
                                <input tabIndex={-1} id="consentTypeForm" name="consentTypeForm" autoComplete="off" style={{ opacity: 0, width: "20%", height: 0, position: "absolute" }} />
                                {
                                    getConsentTypeList().map((consent, key) =>
                                        <Col className="col px-2" key={key}>
                                            {
                                                consent.value ?
                                                    <Card className="border-0 shadow pointer pr-2" body style={campaign && campaign.consentType && campaign.consentType === consent.value ? { backgroundColor: '#d3eaff' } : {}} onClick={() => this.handleConsent(consent.value)}>
                                                        {consent.label}
                                                    </Card>
                                                    :
                                                    ''
                                            }
                                        </Col>
                                    )
                                }
                            </Row>
                        </Col>
                        <Col className="col-1" />
                        <Col className="col-3">
                            <Row>
                                { loyaltyCardOptions.map((option, key) =>
                                    <Col className="pl-2" key={key}>
                                        <Card className="border-0 shadow pointer" body style={filters && filters.hasLoyaltyCard === option.value ? { backgroundColor: '#d3eaff' } : {}} onClick={() => this.handleLoyaltyCard(option.value)}>
                                            {option.label}
                                        </Card>
                                    </Col>
                                ) }
                            </Row>
                        </Col>
                    </Row>
                </form>
                <Row className="mb-2">
                    
                </Row>
                <Row className="my-2">
                    <Col sm={9}>
                        <b><FormattedMessage id="CustomCampaign.ProfileType" /></b>
                    </Col>
                    <Col sm={3}>
                        <b><FormattedMessage id="CampaignDetails.Levels" /></b>
                    </Col>
                </Row>
                <Row className="px-2 align-items-center mb-3">
                    {getProfileType(this.props.intl).map((type, key) => {
                        const isSelected = filters.profileType && filters.profileType.some(pt => pt === type.value);

                        return type.value !== 'CRO' &&
                            <Col className="mb-2 px-2" key={key}>
                                <Card className="border-0 shadow pointer py-3 pr-0" body
                                    style={isSelected ? { backgroundColor: '#d3eaff' } : {}}
                                    onClick={() => selectType(type, 'profileType')}
                                >
                                    {type.label}
                                </Card>
                            </Col>
                    })}
                    <Col className="col-1" />
                    <Col className="col-3">
                        <CustomSelect
                            options={loyaltyCardsOptions}
                            value={loyaltyCardsOptions.filter(opt => filters.loyaltyCards?.some(crd => opt.value === crd))}
                            onChange={(combo) => handleChangeFiltersArray('loyaltyCards', combo)}
                            isDisabled={!filters.hasLoyaltyCard}
                            isClearable
                            isMulti
                        />
                    </Col>
                </Row>


                <Row className="my-2">
                    <Col>
                        <div className="d-flex justify-content-between">
                            <div style={{ minWidth: '222px' }}><b><FormattedMessage id="CustomCampaign.StayDate" /></b></div>
                            <div style={{ minWidth: '222px' }}><b><FormattedMessage id="CustomCampaign.NoStayDate" /></b></div>
                            <div style={{ minWidth: '222px' }}><b><FormattedMessage id="CustomCampaign.ReservationDate" /></b></div>
                            <div style={{ minWidth: '222px' }}><b><FormattedMessage id="CustomCampaign.NoReservationDate" /></b></div>
                        </div>
                        <div className="pl-3" style={{ paddingRight: '13px' }}>
                            <UniqueCampaignsDates
                                stayFrom={filters.stayFrom}
                                stayTo={filters.stayTo}
                                bookingFromDate={filters.bookingFromDate}
                                bookingToDate={filters.bookingToDate}
                                noStayFrom={filters.noStayFrom}
                                noStayTo={filters.noStayTo}
                                noBookingFromDate={filters.noBookingFromDate}
                                noBookingToDate={filters.noBookingToDate}
                                handleDates={handleDates}
                                intl={this.props.intl}
                                style={{ minWidth: '222px' }}
                            />
                        </div>
                    </Col>
                    <Col sm={1} />
                    <Col className="col-3">
                        <div><b><FormattedMessage id="CampaignDetails.VipCodes" /></b></div>
                        <Row>
                            {vipCodesOptions.map((option, key) =>
                                <Col className="pl-2" key={key}>
                                    <Card className="border-0 shadow pointer pl-1 pr-1 text-center" body style={filters && filters.hasVipCodes === option.value ? { backgroundColor: '#d3eaff' } : {}} onClick={() => this.handleVipCodes(option.value)}>
                                        {option.label}
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col>
                        <div className="d-flex justify-content-between">
                            <Col className="col-6 pl-0">
                                <b><FormattedMessage id="CampaignDetails.Hotels" /></b>
                            </Col>
                            <Col>
                                <b><FormattedMessage id="CustomCampaign.BirthDate" /></b>
                            </Col>
                            <Col>
                                <b><FormattedMessage id="CustomCampaign.Nationality" /></b>
                            </Col>
                        </div>
                        <Row>
                            <Col className="col-6">
                                <SelectHotel name="hotelIds" isMulti={true} onChangeFunc={handleChangeFiltersArray} isClearable value={filters.hotelIds} isDisabled={isDisabledSelectHotelIds()} />
                            </Col>
                            <Col className="pr-0">
                                <DateRangePicker
                                    startDate={filters.startBirthDate ? moment(filters.startBirthDate) : null}
                                    startDateId="startBirth_date_id"
                                    isOutsideRange={day => false}
                                    endDate={filters.endBirthDate ? moment(filters.endBirthDate) : null}
                                    endDateId="endBirth_date_id"
                                    onDatesChange={({ startDate, endDate }) => handleDates(startDate, endDate, 'startBirthDate', 'endBirthDate')}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    small={true}
                                    numberOfMonths={2}
                                    showDefaultInputIcon={true}
                                    renderMonthElement={props => this.renderMonthElement(props)}
                                    minimumNights={0}
                                />
                            </Col>
                            <Col>
                                <CustomSelect
                                    icon={"fas fa-globe"}
                                    options={countryList().getData()}
                                    required={true}
                                    isClearable
                                    isMulti={true}
                                    value={this.getSelectedNationalities()}
                                    isSearchable
                                    placeholder={''}
                                    onChange={handleNationalities.bind(this)}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-1" />
                    <Col className="col-3">
                        <div className="d-flex justify-content-between">
                            <Col className="pl-0">
                                <b><FormattedMessage id="CampaignDetails.VipCodes" /></b>
                            </Col>
                        </div>
                        <div>
                            <Row className="justify-content-between">
                                <Col>
                                    <SelectVipCodes name="vipCodes" isMulti={true} onChangeFunc={handleChangeFiltersArray} isClearable value={filters.vipCodes} isDisabled={!filters.hasVipCodes} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                {/*<Row className="mt-5">
                    <Col>
                        <b><FormattedMessage id="CampaignDetails.ProfileScore" />  </b>
                    </Col>
                </Row>
                <Row className="align-items-center pl-1">
                    <Col sm={4}>
                        <ReviewRange reviewName="reviewScore" reviewValue={filters.reviewScore} biggerThanValue={filters.reviewScoreBiggerOrEqualThan}
                            handleBiggerThan={() => handleChangeFilters({ target: { value: !filters.reviewScoreBiggerOrEqualThan, name: "reviewScoreBiggerOrEqualThan" } })}
                            handleRangeInput={handleChangeFilters}
                        />
                    </Col>
                </Row>*/}

                <Row className="mt-3">
                    <Col>
                        <b> Tags</b>
                    </Col>
                </Row>
                <Nav tabs className="border-0 mt-3">
                    <NavItem>
                        <NavLink className={activeTab === '0' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => { this.toggle('0'); }}>
                            <b>
                                <FormattedMessage id="CampaignDetails.Included" />
                            </b>
                            {
                                filters.tags && filters.tags.length > 0 ?
                                    <Badge className="ml-2 bg-host float-right">{filters.tags.length}</Badge>
                                    :
                                    ''
                            }
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab === '1' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => { this.toggle('1'); }}>
                            <b>
                                <FormattedMessage id="CampaignDetails.Excluded" />
                            </b>
                            {
                                filters.excludedTags && filters.excludedTags.length > 0 ?
                                    <Badge className="ml-2 bg-host float-right">{filters.excludedTags.length}</Badge>
                                    :
                                    ''
                            }
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="0">
                        <CampaignTags changeTagMin={changeTagMin} handleTag={handleTag} filters={filters} tagList={tagList} handleTagType={handleTagType}  />
                    </TabPane>
                    <TabPane tabId="1">
                        <CampaignExcludedTags handleExcludedTag={handleExcludedTag} filters={filters} tagList={tagList} />
                    </TabPane>
                </TabContent>

                <Row className="mt-5">
                    <Col>
                        <b> Reviews </b>
                    </Col>
                    <Col>
                        <span className="float-right" onClick={this.toggleReviewIsOpen}>
                            <i className={`fas fa-chevron-${reviewIsOpen ? `up` : `down`}`} style={{ fontSize: '1.5em' }} />
                        </span>
                    </Col>
                </Row>
                <Collapse isOpen={reviewIsOpen}>
                    <Row className="align-items-center pl-1 mb-3">
                        {getReviewFields().map((rev, key) => {
                            const biggerThanValue = `${rev.reviewValue}BiggerOrEqualThan`;

                            return <Col sm={4} key={key}>
                                <ReviewRange label={rev.label} reviewName={rev.reviewValue} reviewValue={filters[rev.reviewValue]} biggerThanValue={filters[biggerThanValue]}
                                    handleBiggerThan={() => handleChangeFilters({ target: { value: !filters[biggerThanValue], name: biggerThanValue } })}
                                    handleRangeInput={handleChangeFilters}
                                />
                            </Col>
                        } )}
                    </Row>
                </Collapse>
            </BlockUi>
        );
    }
}
export default injectIntl(CustomStep3Filters)