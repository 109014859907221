import React, { Component } from 'react';
import { Row, Col, Card, Badge, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { DefaultPage } from '../../Base/CommonUIComponents';
import { getGoContactMessageStatus } from '../../Ticket/TicketsFunctions';
import { handleNotification } from '../../Base/Notification';
import TicketDetails from '../../Ticket/TicketDetails';
import { ErrorAlert } from '../../Base/ErrorAlert';
import { getAPI } from '../../Base/API';
import BlockUi from 'react-block-ui';
import { Pagination, ResultsPerPage, TotalResults } from '../../Base/PaginationComponents';


class ProfileTickets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            pageSize: 10,
            pageIndex: 0,
            totalItems1: 0,
            tickets: [],
            selectedTicket: null,
            goContactMsgStatus: getGoContactMessageStatus(this.props.intl)
        }
    }

    componentDidMount() {
        this.getTickets();
    }

    getTickets = (e) => {
        if (e) e.preventDefault();


        this.setState({ block: true }, () => {
            const { pageSize, pageIndex } = this.state;

            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    this.setState({ tickets: data.response, totalItems: data.totalItems, block: false });

                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/GoContact/v1/Tickets/profile/${this.props.profileId}?pageSize=${pageSize}&pageIndex=${pageIndex}`);
        })
    }

    getTicketDetails = (ticketId) => {
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    this.setState({ selectedTicket: data.data?.[0], block: false });

                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/GoContact/v1/Ticket/${ticketId}`);
        })
    }

    clearSelectedTicket = () => {
        this.setState({
            selectedTicket: null
        })
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getTickets());
    }


    render() {
        const { profileId } = this.props;
        const { block, error, tickets, selectedTicket, goContactMsgStatus, pageIndex, pageSize, totalItems } = this.state;

        return (
            <div className="m-2 p-2">
                <BlockUi tag="div" blocking={block}>
                    <ErrorAlert error={error} />
                    <Row className="mb-2">
                        <Col>
                            <h5><i className="fas fa-headset mr-1"> </i><FormattedMessage id="ProfileDetails.Tickets" /></h5>
                        </Col>
                        <Col className="text-right">
                            <Button className="btn-sm btn-host ml-2">
                                <i className="fas fa-plus" />
                            </Button>
                        </Col>
                    </Row>

                    {selectedTicket ?
                        <TicketDetails
                            ticketDetails={selectedTicket}
                            canGoBack={true}
                            goBackFunction={this.clearSelectedTicket}
                            goContactMsgStatus={goContactMsgStatus}
                            //height='67vh'
                        />
                    :
                        tickets?.length > 0 ?
                            <>
                                <Row>
                                    {tickets.map((ticket, index) => {
                                        return (
                                            <Col className="col-4 mb-3" key={index}>
                                                <Card body className="border-0 shadow overbglight py-3 pointer h-100" onClick={_ => this.getTicketDetails(ticket.externalId)}>
                                                    <Row className=" align-items-center">
                                                        <Col className=" text-truncate">
                                                            <span className=" text-truncate w-100" title={ticket.subject}>
                                                                <i className="fas fa-bed mr-2" />
                                                                {ticket.subject}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                    <Row className="py-1 align-items-center">
                                                        <Col className="text-muted">#{ticket.externalId}</Col>
                                                        <Col className="text-right"> <Badge color="primary">{ticket.status}</Badge></Col>
                                                    </Row>
                                                    <Row className="title-sm align-items-center">
                                                        <Col className="">Name</Col>
                                                        <Col className="text-right"> {moment(ticket.updatedAt).fromNow()}</Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        )
                                    })}
                                </Row>

                                {tickets && tickets.length < totalItems && !block ?
                                    <Row className="my-3">
                                        <Col>
                                            <span className="ml-2">
                                                <TotalResults
                                                    end={pageIndex * pageSize + tickets.length}
                                                    start={pageIndex * pageSize + 1}
                                                    total={totalItems}
                                                />
                                            </span>
                                        </Col>

                                        <Col className="text-right">
                                            <Pagination
                                                isPrevDisabled={pageIndex === 0}
                                                isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                                currentPage={pageIndex + 1}
                                                handlePrevButton={() => this.handlePagination(-1)}
                                                handleNextButton={() => this.handlePagination(1)}
                                            />
                                        </Col>
                                    </Row>
                                : ''}
                        </>
                        :
                        <DefaultPage text="ProfileDetails.NoTicketsAvailable" icon="fas fa-headset" />
                    }
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(ProfileTickets);
