import React, { Component } from 'react';
import { Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { SelectHotelByHotelGroup, StyledCard, CommonHelper } from '../Base/CommonUIComponents';
import CustomSelect from '../Base/CustomSelect';
import { handleNotification } from '../Base/Notification';
import { getAPI } from '../Base/API';
import { VoucherCardList } from './VoucherCardList';
import { getVoucherTypesOptions } from './HeyCardFunctions';

export class AvailableCards extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            isPayment: null,
            selectedHotel: null,
            activeTab: '1',
            activeCashConfigs: [],
            activeRebateConfigs: [],
            inactiveCashConfigs: [],
            inactiveRebateConfigs: [],
        }
    }

    componentDidMount() {
        this.getVoucherTypes();
    }

    getVoucherTypes = () => {
        const { selectedHotel, isPayment } = this.state;

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false }));
                    return;
                }
                else {
                    const activeCashConfigs = [], activeRebateConfigs = [], inactiveCashConfigs = [], inactiveRebateConfigs = []
                    data.response && data.response.forEach(config => {
                        if (config.active) {
                            if (config.isPaymentMode) {
                                activeCashConfigs.push(config);
                            }
                            else {
                                activeRebateConfigs.push(config);
                            }
                        }
                        else {
                            if (config.isPaymentMode) {
                                inactiveCashConfigs.push(config);
                            }
                            else {
                                inactiveRebateConfigs.push(config);
                            }
                        }
                    });
                    this.setState({ block: false, activeCashConfigs, activeRebateConfigs, inactiveCashConfigs, inactiveRebateConfigs });
                }
            }
        }, `/api/Voucher/V1/Config?hotelId=${selectedHotel !== null ? selectedHotel : ``}&isPayment=${isPayment !== null ? isPayment : ``}`);
    }
    
    doSearch = () => {
        this.setState({ block: true }, this.getVoucherTypes);
    }

    setComboStatus = (name, combo) => {
        this.setState({ [name]: combo ? combo.value : null });
    }

    toggle(id) {
        this.setState({ activeTab: id });
    }

    render() {
        const { activeTab, block, error, isPayment, selectedHotel, activeCashConfigs, activeRebateConfigs, inactiveCashConfigs, inactiveRebateConfigs, newVoucherModal } = this.state;
        

        return (
            <StyledCard block={block} error={error}>
                <Row className="mb-2">
                    <Col>
                        <h5 className="m-0">
                            <i className="fas fa-gift mr-2"></i>
                            <FormattedMessage id="NavMenu.AvailableCards" />
                        </h5>
                    </Col>
                </Row>

                <Row className="my-3">
                    <Col className="col-2">
                        <SelectHotelByHotelGroup onChangeFunc={this.setComboStatus} value={selectedHotel} name="selectedHotel" placeholder={<FormattedMessage id="generic.Hotel" />} />
                    </Col>
                    <Col className="col-2">
                        <CustomSelect
                            options={getVoucherTypesOptions()}
                            value={getVoucherTypesOptions().filter(el => el.value === isPayment)}
                            placeholder={<FormattedMessage id="AvailableCards.VoucherType" />}
                            onChange={this.setComboStatus.bind(this, 'isPayment')}
                            isClearable
                            isSearchable
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn-host btn-sm mr-2" onClick={this.doSearch}>
                            <i className="fas fa-search" />
                        </Button>
                        <CommonHelper help={<FormattedMessage id="HeyAvailableCards.Help" />} id={'HeyAvailableCards'} />
                    </Col>
                </Row>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={activeTab === '1' ? 'tab-border-host text-host border-0' : 'border-0'} onClick={() => { this.toggle('1'); }}>
                            <FormattedMessage id="generic.active" />
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab === '2' ? 'tab-border-host text-host border-0' : 'border-0'} onClick={() => { this.toggle('2'); }}>
                            <FormattedMessage id="generic.inactive" />
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1" className="border-0">
                        <VoucherCardList cashConfigs={activeCashConfigs} rebateConfigs={activeRebateConfigs} doSearch={this.doSearch} />
                    </TabPane>
                    <TabPane tabId="2" className="border-0">
                        <VoucherCardList cashConfigs={inactiveCashConfigs} rebateConfigs={inactiveRebateConfigs} doSearch={this.doSearch} />
                    </TabPane>
                </TabContent>
            </StyledCard>
        );
    }
}